/* eslint no-eval: 0 */
import React, {Component} from 'react';

import { Dialog, Button, IconButton, Card, CardHeader,Divider, Typography, CardContent } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { DisplayMessage } from 'notifications/displayMessage';
import { PaymentProvider } from 'providers/payment.service';
import 'moment/locale/es';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

export default class PayDialog extends Component {

    constructor(props){
        super(props);
        this.displayMessage = new DisplayMessage();
        this.state = {
            observation: '',
            state: '',
            disabled: false,
            paymentDate: moment(),
            errors:{
                paymentDate: false
            },
            messages: {
                paymentDate: ''
            }
        }
        this.paymentProvider = new PaymentProvider();
    }

    handleClosePay = () => {
        const { handleClose } = this.props;
        handleClose();
    }
    handleChange = event => {
        const {target: {name, value}} = event;
        this.setState({[name]: value});
    }

    handleSave = async() => {
        await this.setState({ disabled: true });
        const { paymentsCart, handleSearch, reloadFields } = this.props;
        const { observation, detNombre,paymentDate } = this.state;
        if(paymentsCart.length > 0 && paymentDate !== null ){
            const trama = [];
            await paymentsCart.forEach(pay => {
                const {pagCodigo} = pay;
                const element = {
                    pagCodigo: pagCodigo,
                    detEstado: 17210,
                    detNombre: detNombre,
                    pagObservacion: observation,
                    pagFechaPago: moment(paymentDate).format('DD/MM/YYYY')
                }
                trama.push(element)
            });
            await this.paymentProvider.updatePayments(trama).then(
                response => {
                    if(response.error.codigo === '0'){
                        this.displayMessage.success('Pagos registrados con éxito');
                        handleSearch();
                        this.handleClosePay();
                        reloadFields();

                    }
                    this.setState({
                        disabled: false,
                        detNombre: detNombre,
                        observation: '',
                        state: '',
                        paymentDate:  moment()
                    });
                    this.cleanErrors();

                }
            ).catch(() => {
                this.setState({
                    disabled: false,
                });
            })
        }else{
            if(paymentsCart.length === 0){
                this.displayMessage.error('Nada que registrar');
            }
            if(paymentDate === null){
                this.showEmpties();
            }
            this.setState({
                disabled: false,
            });
        }
    }
    /** ---------------------------Mostrar Errores */
    showEmpties = () => {
        const {  paymentDate } = this.state;
        this.notifyEmptyNulls('paymentDate',paymentDate);

    }

    /** --------------------------Notificar Vacios o nulos */
    notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                this.setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }
        } 
    }

    /** --------------------------Setear mensajes de errores --- */
    setErrorsMessages = (nameElement, stateElement, message) => {
        const errors = this.state.errors;
        const messages = this.state.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;

        this.setState({
            errors: errors,
            messages:messages
        });
    }

    /** ---------------------------Limpiar errores */
    cleanErrors = () => {
        this.setState({
            errors:{
                paymentDate: false
            },
            messages: {
                paymentDate: ''
            }
        });
    }
    
    /** --------------------------Cambiar datePicker  */
    handleDateChange = (val) => {
        this.setState({
            paymentDate: val
        });
    }
    render() {
        const { open } = this.props;
        const { disabled} = this.state;
        return (
            <Dialog
                fullWidth
                maxWidth={'xs'}
                open={open}
                scroll={'body'}
                //style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
            >
                <Card style={{backgroundColor: '#FAF9F9'}}>
                    <CardHeader 
                        action={<IconButton onClick={this.handleClosePay}
                            size='small'><CloseIcon/></IconButton>}
                        title = {<Typography  gutterBottom variant="h4"><b className='stripe-title'>Registrar</b></Typography>}
                    />
                    <CardContent>
                    <Divider />
                    <DatePicker
                        error={this.state.errors.paymentDate}
                        format="DD/MM/YYYY"
                        fullWidth
                        helperText={this.state.messages.paymentDate}
                        inputVariant='outlined'
                        label="Fecha de pago"
                        name='paymentDate'
                        onChange={this.handleDateChange}
                        required
                        value={this.state.paymentDate}
                    />
                    <br/><br/>

                        <TextField id="outlined-basic" label="Observación" variant="outlined" name='observation' onChange={this.handleChange} rows={4} inputProps={{maxLength: 255}} multiline fullWidth/><br/><br/>
                        
                        <Button className='button-stripe' color='primary' disabled={disabled}  fullWidth startIcon={<SaveIcon />} variant='contained' onClick={this.handleSave}>Guardar</Button>
                    </CardContent>

                </Card>
            </Dialog>
        );
    }
    

     
    
}