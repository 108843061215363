import { DataService } from 'providers/dataService'

export class BeneficiaryDataProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosBeneficiarios( polCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: "VwPolPerBan",
            parametros: {
                polCodigo:polCodigo,
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    dataGenero() {
        let body = {       
            tipoConsulta: 1,
            entidad: "DetalleCatalogo",
            parametros: {
                catCodigo:28,
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    ingresoBeneficiarios( beneficiarios ) {
        let body = {       
            tipoAccion: 1,
            entidad: "PersonaPoliza",
            entidades: beneficiarios
        };
        let endpoint = "/accion";
        return this.dataService.post(endpoint, body, "0101101");
    }

}
