import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Application } from './components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  Paper,
  Input,
  Grid
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './style.css';
import { ReportsProvider } from "providers/reports.service";
import { DisplayMessage } from "notifications/displayMessage";

//import getInitials from 'utils/getInitials';

class Results extends React.Component {

  constructor (props){
    super(props);
    this.state={
      cobertura : 3000,
      cubierto : 1000,    
      disponible : 0,
      openApplication:false,
      setOpenApplication:false,
      page:0,
      setPage:0,
      rowsPerPage:10,
      setRowsPerPage:10,
      reportes:0,
      palabraBuscador:'',
      buscarReporte:[]
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount(){
    const {poliza} = this.props;
    const report = new ReportsProvider();
    
    const displayMessage = new DisplayMessage();

    report.reportesTabla("",poliza)
      .then(
        data => {
          this.setState({reportes:data.bodyOut.data});
        },
        () => {          
          displayMessage.error('Error de comunicación.');

        }

      );
  }

  seleccionarReporte=(reporte)=>{
    this.setState({
      openApplication:true,
      buscarReporte:reporte
      
    }
    );
  }

  modalVer=()=>{
    const id = this.state.buscarReporte.liqCodigo;
    if(!id){
      return null;
    }else{
      return <Application
      //onApply={this.handleApplicationClose}
      close={this.handleApplicationClose}
      open={this.state.openApplication}
      reporte={id}
    />
    }
  }

  handleApplicationOpen = () => {
    this.setState({openApplication:true});
  };

  handleApplicationClose = () => {
    this.setState({openApplication:false,
    buscarReporte:[]
    });
  };

  handleChangePage = (event, page) => {
    this.setState({page:page})
  };

  handleChangeRowsPerPage = event => {
    this.setState({rowsPerPage:event.target.value})
  };

  handleSearchChange = (e) => {
    const {poliza} = this.props;
    this.setState({[e.target.name]:e.target.value});

    const report = new ReportsProvider();
    
    const displayMessage = new DisplayMessage();

    report.reportesTabla(e.target.value,poliza)
      .then(
        data => {
          this.setState({reportes:data.bodyOut.data});
        },
        () => {          
          displayMessage.error('Error de comunicación.');

        }

      );

};

  render(){
  return (
    <Grid>
      {this.modalVer()}
    
      
      <Paper
        className="search"
        elevation={1}
      >
        <SearchIcon className="searchIcon" />
        <Input
          className="searchInput"
          disableUnderline
          placeholder="Buscar"
          onChange={this.handleSearchChange}
          value={this.state.palabraBuscador}
          name="palabraBuscador"
        />
      </Paper><br/>
      
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {/* {this.state.reportes.length} Registros encontrados. Página {this.state.page + 1} de {''}
        {Math.ceil(this.state.reportes.length / this.state.rowsPerPage)} */}
      </Typography>
      {this.state.reportes ? 
      <Card>
        <CardHeader
          title="Reportes"
        />
        <Divider />
        <CardContent className="">
          <PerfectScrollbar>
            <div className="inner">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Requerimiento</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Valor Desembolso</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.reportes ? this.state.reportes.map((reporte,index) =>
                    <TableRow key={index}>
                      <TableCell>
                        {reporte.liqCodigo}
                      </TableCell>
                      <TableCell>{reporte.detDescripcion}</TableCell>
                      <TableCell>
                        {reporte.perFechaRegistro}
                      </TableCell>
                      <TableCell>{reporte.tips}</TableCell>
                      <TableCell>{reporte.plaCoberturaMaxima}</TableCell>
                      <TableCell align="center">
                        <Button
                          color="primary"
                          size="small"
                          onClick={(e)=>this.seleccionarReporte(reporte)}
                          variant="outlined"
                        >
                          Ver
                        </Button>
                      </TableCell>
                    </TableRow>
                  ):<TableRow><TableCell><CircularProgress/></TableCell></TableRow>}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className="">
          <TablePagination
            component="div"
            count={this.state.reportes.length}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            page={this.state.page}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
        {/* <Typography className="information">
            Monto de cobertura: ${this.state.cobertura}
          </Typography>
          <Typography className="information">
            Monto Cubierto: ${this.state.cubierto}
          </Typography>
          <Typography className="information">
            Monto Disponible: ${this.state.disponible}
          </Typography> */}
      </Card>
      : <CircularProgress/>}
      
          
          
    </Grid>
  )};
  
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

Results.defaultProps = {
  customers: []
};

export default Results;
