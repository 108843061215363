import config from 'config/enviroment'
import { DisplayMessage } from 'notifications/displayMessage'
import { StorageService } from 'utils/storage';
export class DataService {

    constructor() {
        this.storage = new StorageService()
        this.displayMessage = new DisplayMessage();
    }

    post(endpoint, body, transId, username = null) {
        let trama = {
            headerIn: this.headerIn(transId, username),
            bodyIn: body
        }
        const requestOptions = {
            method: 'POST',
            headers: this.header(),
            body: JSON.stringify(trama),
        
        };
        let api_endpoint = config.api + endpoint;
        return fetch(api_endpoint, requestOptions).then(this.handleResponse)

    }
    postCors(endpoint, body, transId, username = null) {
        let trama = {
            headerIn: this.headerIn(transId, username),
            bodyIn: body
        }
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: this.headerWithoutJSON(),
            body: JSON.stringify(trama),
        
        };
        let api_endpoint = config.api + endpoint;
        return fetch(api_endpoint, requestOptions).then(this.handleResponse)

    }

    postMultipart(endpoint, body, transId, files, username = null) {
        let trama = {
            headerIn: this.headerIn(transId, username),
            bodyIn: body
        }       
        var formData = new FormData();
        formData.append('0', JSON.stringify(trama));
        if(files.length >0){
            files.map((file, index)=>{
                formData.append(index+1, file);
                return 0;
            });
        }

        const requestOptions = {
            method: 'POST',            
            body: formData
        };
        
      

        let api_endpoint = config.api + endpoint;        

        return fetch(api_endpoint, requestOptions).then(this.handleResponse)
    }

    header() {
        let header = 
        { 'Content-Type': 'application/json', 'Accept': 'application/json' };
        return header;
    }
    headerWithoutJSON() {
        let header = { 
            'Content-Type': 'application/json'
        };
        return header;
    }

    headerIn(transId, username){
        let user = new StorageService().getSession();
        let headerIn = config.headerIn;
        headerIn.tipoTransaccion = transId
        if (user && user.token) {
            headerIn.usuario = user.usuario;
            headerIn.uuid = user.token;
        } else {
            headerIn.usuario = username;
        }

        return headerIn;
    }

    handleResponse(response) {
        if (!response.ok) {
            messageStatusCode(response.clone())
            throw response
        } else {
            let is_blob = false;
            let is_html = false;
            response.headers.forEach(function (val, key) {

                if (key === 'content-disposition')
                    is_blob = true

                if (val.indexOf('text/html') !== -1)
                    is_html = true
            });

            if (is_blob || is_html) {
                return response
            } else {
                messageStatusCode(response.clone());
                return response.json().then(data => data );
               
            }
        }
    }

}

function messageStatusCode(response) {

    let code = response.status
    let error = false;
    let displayMessage = new DisplayMessage()    
    // eslint-disable-next-line default-case
    switch (code) {
        case 200:
            response.json().then(data => {
                if (data.error && data.error.codigo !== '0') {
                    if (data.error.mensaje) {
                        //"ERROR NO CONTROLADO: 
                        if(data.error.mensaje.includes('com.stripe.exception.CardException')){
                            if(data.error.mensaje.includes('Your card has insufficient funds.; code: card_declined')){
                                displayMessage.error('La transacción no se pudo realizar, fondos insuficientes');
                            }else if(data.error.mensaje.includes('This transaction requires authentication.')){
                                displayMessage.error('La transacción no se pudo realizar, su tarjeta requiere autentificación');
                            }else{
                                displayMessage.error(`Error ${data.error.mensaje}`);

                            }

                        }else{
                            if(data.error.codigo !== '1002'){
                                displayMessage.error(data.error.mensaje);
                            }
                            
    
                        }
                    }
                }
            });
            break;
        case 502: error = true; displayMessage.error('Connection refused'); break;
        case 500:
            error = true;
            displayMessage.error('Internal Error Server');
            response.json().then(data => {
                console.error(data)
            });
            break;
        case 405:
            error = true;
            displayMessage.error('Failed to load resource');
            break;
        case 404:
            error = true;
            displayMessage.error('Page not Found');

    }
    return error;
}

