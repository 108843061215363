import { DataService } from 'providers/dataService'
import { DisplayMessage } from 'notifications/displayMessage';

export class DetalleCatalogoProvider {

    constructor() {
        this.dataService = new DataService();
        this.displayMessage = new DisplayMessage();

    }

    /** -------------------------------------Migrados desde financial */
    findAllDetailCatalogLimit10 = () => {
        return this.genericConsultMaxReg('DetalleCatalogo', { detEstado: 1 }, '0101000', 1, 10);

    }
    searchOrCatalogDetail = (value) => {
        return this.GenericOr(1, 'BUSDET', {
            0: value,
            1: value,
            2: value,
        }, '0101010');

    }
    findCatalogDetailByDetCodigo = detCodigo => this.genericConsult('DetalleCatalogo', { detCodigo: detCodigo }, '0101000', 1);

    GenericOr = (consultType, entity, parameters, transaction) => {
        let body = {
            tipoConsulta: consultType,
            entidad: entity,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, transaction);
    }


    genericConsultMaxReg = (entitie, parameters, typeTransaction, typeConsult, maxRegistros) => {
        let body = {
            maximoRegistros: maxRegistros,
            tipoConsulta: typeConsult,
            entidad: entitie,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, typeTransaction).then(
            response => {
                return response.bodyOut.data;
            }
        );
    }

    genericConsult = (entitie, parameters, typeTransaction, typeConsult) => {
        let body = {
            tipoConsulta: typeConsult,
            entidad: entitie,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, typeTransaction).then(
            response => {
                return response.bodyOut.data;
            }
        );
    }

    createCatalog(catalogDetails) {
        let body = {
            'tipoAccion': 1,
            'entidad': 'DetalleCatalogo',
            'entidades': catalogDetails
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    updateCatalog(catalogDetails) {
        let body = {
            'tipoAccion': 2,
            'entidad': 'DetalleCatalogo',
            'entidades': catalogDetails
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }
    /** -------------------------------------------------------------- */

    createDetalleCatalogo = (detalleCatalogo) => {

        const body = {
            'tipoAccion': 1,
            'entidad': 'DetalleCatalogo',
            'entidades': detalleCatalogo
        }

        const resp = this.dataService.post('/accion', body, '0101100').then(
            response => {
                return response;
            }
        )

        return resp;

    }

    countByCodigo = (catCodigo) => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 2,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catCodigo': catCodigo
            }
        }

        const response = dS.post('/consulta', body, '0101000', 'james').then(response => {
            return response.bodyOut.data;
        }


        );

        return response;

    }

    allCatalogos = () => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 1,
            'entidad': 'Catalogo',
            'parametros': {

            }
        }
        //polCodigo

        const response = dS.post('/consulta', body, '0101000').then(response => {
            return response.bodyOut.data;
        }


        );

        return response;
    }
    findLikeName = (tipoConsulta, catNemonico, name, empCodigo) => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': tipoConsulta,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catNemonico': catNemonico,
                'detNombre': `%${name}%`,
                'empCodigo': empCodigo,
                'detEstado': 1


            }
        }

        const response = dS.post('/consulta', body, '0101000', 'james').then(response => {
            return response.bodyOut.data;
        }

        );

        return response;
    }

    findMaxResults = (catCodigo, MaxResults) => {
        const dS = new DataService();

        const body = {
            'maximoRegistros': parseInt(MaxResults),
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catCodigo': catCodigo
            }
        }

        const response = dS.post('/consulta', body, '0101000').then(response => {
            return response.bodyOut.data;
        }


        );

        return response;
    }
    findDetalleCatalogoBydetCodigo = (detCodigo) => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'detCodigo': detCodigo

            }
        }
        //polCodigo

        const response = dS.post('/consulta', body, '0101000').then(response => {
            return response.bodyOut.data;

        }


        );

        return response;

    }
    findDetalleCatalogo = (catCodigo) => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catCodigo': catCodigo

            }
        }
        //polCodigo

        const response = dS.post('/consulta', body, '0101000').then(response => {
            return response.bodyOut.data;

        }


        );

        return response;

    }
    findDetalleCatalogoByNemonico = (catNemonico, detDestino = '') => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catNemonico': catNemonico,
                'detEstado': 1,
                'detDestino': String(detDestino)
            }
        }
        //polCodigo

        const response = dS.post('/consulta', body, '0101000').then(response => {
            return response.bodyOut.data;

        }


        );

        return response;

    }


    findDetalleCatalogoByNemonicoGen = (catNemonico) => {
        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catNemonico': catNemonico,
                'detEstado': 1,
            }
        }
        return this.dataService.post('/consulta', body, '0101000');
    }

    findDetalleCatalogoByNemonicoGen1 = ( catNemonico ) => {        
        const body = {
            'tipoConsulta': 3,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catNemonico': catNemonico,
                'detEstado': 1,            
            }
        }    
        return this.dataService.post('/consulta', body, '0101000');        
    }

    getParamGeneric(entidad, parametros = {}) {
        let body = {
            tipoConsulta: 1,
            entidad: entidad,
            parametros: parametros
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }





}
