import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import {Cancel} from '@material-ui/icons'
import { CircularProgress, Card, CardHeader,  CardActions, Grid, TextField, Button, IconButton, Dialog} from '@material-ui/core';
import { AtentionFormProviders } from 'providers/attentionForm.service';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchAffiliate(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const atention = new AtentionFormProviders();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'RUC', field: 'preRuc' },
        { title: 'Establecimiento', field: 'preNombre' },
        { title: 'Dirección', field: 'preDireccion' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');    
    const [buscarNombre, setBuscarNombre] = useState('');

    const handleBuscarTitular= ()=>{
        setData(0);

        if(buscarTitular === '' && buscarNombre === ''){
            displayMessage.error('Ingrese un campo a buscar')
            setData([])
        }else{
            if(props.detTipoLiq === 'TIPLQRE'){
                atention.consultarPresador(buscarTitular,23537,buscarNombre).then(response => {            
                    if(response.bodyOut.data.length === 0){
                        displayMessage.error('Prestador no encontrado')
                        setData([]);
                    }else{
                        setData(response.bodyOut.data);
                    }              
                },
                () => {          
                    displayMessage.error('Error de comunicación.');        
                }); 
            }else{
                atention.consultarPresador(buscarTitular,null,buscarNombre).then(response => {            
                    if(response.bodyOut.data.length !== 0){
                        const datosSucios = response.bodyOut.data;
                        let arreglo = datosSucios.filter(datosSucios => datosSucios.detTipo !== 23537);
                        if(arreglo.length !== 0){
                            setData(arreglo);
                        }else{
                            displayMessage.error('Prestador no encontrado')
                            setData([])
                        }
                        
                       
                    }
                    else{
                        displayMessage.error('Prestador no encontrado')
                        setData([])
                    }               
                },
                () => {          
                    displayMessage.error('Error de comunicación.');        
                }); 
            }
        }
    }
    const handleChange = (e)=>{
        setBuscarTitular(e.target.value);
    }

    const handleChangeNombre = (e)=>{
        setBuscarNombre(e.target.value);
    }

    const handleLimpiar= ()=>{
        setBuscarTitular('');
        setData([]);
    }

    const handleData = (e) => {
        props.datos(e)
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');        
        setBuscarNombre('');
        setData([]);
    }
    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                        action={<IconButton
                            color="secondary"
                            onClick={handleClose}
                        ><Cancel/></IconButton>}
                        title='Buscar Prestador'
                    />
                <CardActions>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    autoFocus
                                    fullWidth
                                    name="buscarTitular"
                                    onChange={handleChange}
                                    placeholder="Buscar por RUC"
                                    value={buscarTitular}
                                />
            
       
         
                            </Grid>
                           
                            <Grid
        
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    fullWidth
                                    name="buscarNombre"
                                    onChange={handleChangeNombre}
                                    placeholder="Buscar por nombre"
                                    value={buscarNombre}
                                />
          
       
         
                            </Grid>
                            <Grid
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleBuscarTitular}
                                    variant="contained"
                                >Buscar</Button>
                
                  
                            </Grid>
                            <Grid
                 
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleLimpiar}
                                    variant="contained"
                                >Limpiar</Button>
                
                  
                            </Grid>
                        </Grid>
                    </div>
          
                </CardActions>
            
            {data ?
                data.length ?
                        <MaterialTable
                            actions={[
                                {
                                    icon: 'check',
                                    tooltip: 'Seleccionar',
                                    onClick: (event, rowData) => {handleData(rowData)
                                        handleClose()
                                    }
                                }
                            ]}
                            columns={columns}
                            data={data}
                            localization={{
                                body:{
                                    emptyDataSourceMessage:'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination:{
                                    labelRowsSelect:'Filas',
                                    labelDisplayedRows:'{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'
          

                                }
                            }}
                            options={{exportButton: false, search:false, toolbar: false}}
                            title="CIE10"
                        />: ''
                :<CircularProgress/>}
                </Card><br/>
        </Dialog>
    );
}