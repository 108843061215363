import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Search } from './components';

const useStyles = makeStyles( () => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    search: {
        flexGrow: 1,
        maxWidth: 480,
        flexBasis: 480
    }
}));

const SearchBarCustom = props => {
    const { onSearch, onClean, className, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
        >
            <Grid item>
                <Search
                    className={classes.search}
                    onClean={onClean}
                    onSearch={onSearch}
                />
            </Grid>
        </Grid>
    );
};

SearchBarCustom.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func
};

export default SearchBarCustom;
