import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, TextField, Link } from '@material-ui/core';
import 'antd/dist/antd.css';
import './index.css';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { DisplayMessage } from 'notifications/displayMessage';
import { UserProvider  } from 'providers/user.service';
import { useDispatch } from 'react-redux';
import { sessionActions } from 'actions';
import { StorageService } from 'utils/storage';
import { PayDialog } from './components';
import { PaymentProvider } from 'providers/payment.service';
import { YouTube } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.quarterBlack,
    },
    header: {
        width: theme.breakpoints.values.md,
        maxWidth: '100%',
        margin: '0 auto',
        padding: '80px 24px',
        [theme.breakpoints.up('md')]: {
            padding: '20px 24px'
        }
    },
    buttons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    mediaContainer: {
        margin: '0 auto',
        maxWidth: 1600,
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        textAlign: 'center'
    },
    media: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '80%',
        }
    },
    mediaAviso: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    stats: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1)
    },
    statsInner: {
        width: theme.breakpoints.values.md,
        maxWidth: '100%',
        margin: '0 auto'
    }
}));

const schema = {
    code: {
        presence: { allowEmpty: false, message: '^El código es requerido' },
        length: {minimum: 6, message: '^Escriba 6 caracteres' }
    }  
};

let mounted = false;

const HeaderClient = props => {
    const { className, session, ...rest } = props;
    const [open, setOpen] = useState(false);    
    const [openCode, setOpenCode] = useState(false);    
    const [openAcept, setOpenAcept] = useState(true);  
    const [loading, setLoading] = useState(false);
    const [status, setStatus]  = useState(null);
    const [ openAlert, setOpenAlert ] = useState(false);
    const [openAlertAviso, setOpenAlertAviso] = useState(false);  
    const [filePoliza, setFilePoliza] = useState(null);
    const [fileAcept, setFileAcept] = useState(null);
    const [estadoPoliza, setEstadoPoliza] = useState('')
    /** ---------------------------------Poliza */
    const [polizafinded, setPolizaFinded] = useState(null);
    const [openPayDialog, setOpenPayDialog] = useState(false);  

    const [ pendingPayments, setPendingPayments ] = useState([]);
    
    const paymentProvider = new PaymentProvider();

    const [openVideo, setOpenVideo] = useState(false);

    const dispatch = useDispatch();
    let _storageService = new StorageService()
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const displayMessage = new DisplayMessage()

    const classes = useStyles();
    
    const userProvider = new UserProvider();


    const fetchEstado = () => {
        if (session.user.role !== 'CLIENTE'){
            return 0;
        }

        if (session.user.polEstado === 'ESINI' || session.user.polEstado === 'ESTAOTP'){
            setLoading(true);


            userProvider.estadoPoliza(session.user.polCodigo).then(response => { 

                if(response.error.codigo === '0' && response.bodyOut.data.length){
                    const data = response.bodyOut.data;  
        
                    setPolizaFinded(data[0])
                    setStatus(data);
                    setEstadoPoliza(data[0].estadoNemonico);
                    setOpen(data[0].estadoNemonico === 'ESTAOTP' || data[0].estadoNemonico === 'ESINI');
                    
                    if (data[0].estadoNemonico === 'ESTAOTP') {
                        setFilePoliza(data[0].poliza);
                        setFileAcept(data[0].polAceptacion);
                        setOpenAcept(false);
                        setOpenCode(true);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }                  
            },
            () => {          
                displayMessage.error('Error de comunicación.');        
            }
            ); 
        }  
        //getPendingPaymets(session.user.polCodigo)

    };
  
  

    useEffect(() => {
        userProvider.estadoPoliza(session.user.polCodigo).then(response => { 

            if(response.error.codigo === '0' && response.bodyOut.data.length){
                const data = response.bodyOut.data; 
    
                setPolizaFinded(data[0])
                setStatus(data);
                setEstadoPoliza(data[0].estadoNemonico);
                setOpen(data[0].estadoNemonico === 'ESTAOTP' || data[0].estadoNemonico === 'ESINI');
                
                if (data[0].estadoNemonico === 'ESTAOTP') {
                    
                    setFilePoliza(data[0].poliza);
                    setFileAcept(data[0].polAceptacion);
                    setOpenAcept(false);
                    setOpenCode(true);
                }
                setLoading(false);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');        
        }
        ); 
    
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    
        if ((!mounted || !status)) {      
            fetchEstado();    
        }   
    
        return () => {
            mounted = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.values]);

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const handleAcept = async (event, message='Póliza aceptada con éxito.') => {
        event.preventDefault();
        setLoading(true);
        userProvider.aceptarPoliza(session.user.usuario).then(response => {            
            if(response.error.codigo === '0'){
                displayMessage.success(message);
                setOpenCode(true);
                setOpenAcept(false);
                setLoading(false);
                setOpenAlert(true);
                setEstadoPoliza('ESTAOTP');
                const data = response.bodyOut.data;            
                setFilePoliza(data[0].poliza);
                setFileAcept(data[0].aceptacion);
            } else {   
                setLoading(false);
            }                
        },
        () => {          
            displayMessage.error('Error de comunicación.');
            setLoading(false);
        }
        );    
    }

    
    /**--------------------------------------Crear Pago------ */
    /*
    const openDialogCreditCard = () => {
        /** 
        if(polizafinded.formaPagoNemonico === 'PAGTC' && polizafinded.periodicidadNemonico === 'ANUA'){
            paymentProvider.findAllPaymentsByPolCodigo(polizafinded.polCodigo).then(
                response => {
                    if(response.length>0){
                        setPendingPayments(response);
                        setOpenPayDialog(true);

                    }else{
                        sendOTP();
                    }
                }
            )
        }else{
            sendOTP();
        }
    }*/
    const getPendingPaymets = polCodigo => {
        const today = new Date();
        paymentProvider.findPendingPaymentsByPolCodigo(polCodigo).then(
            async response => {
                
                if(response.length>0){
                    const allPendingPayment = await[];
                    await response.forEach(async element => {
                    
                        const dateVenc = await new Date(element.pagFechaVencimiento);
                        
                        if(dateVenc.getTime() < today.getTime()){
                
                            await allPendingPayment.push(element);
                        }
                    });
                    await setPendingPayments(allPendingPayment);
                    if(allPendingPayment.length > 0){
                        setOpenPayDialog(true);

                    }

                }
            }
        )
    }
    
    /**------------------------------------------------------ */
    const sendOTP = () => {
        setLoading(true);
        userProvider.validarOtpPoliza(session.user.usuario, formState.values.code).then(response => {            
            if(response.error.codigo === '0'){
                displayMessage.success('Póliza validada con éxito');
                setLoading(false);
                setOpen(false);
                dispatch(sessionActions.activatePoliza('ESTACT'));
            } else {
                setLoading(false);
            }                
        },
        () => {          
            displayMessage.error('Error de comunicación.');    
            setLoading(false);
        }
        );  
    }
    

    const handleEnviar = async event => {
        event.preventDefault();
        sendOTP();
        //openDialogCreditCard();
        //setOpenPayDialog(true);

        /** 
          
        */
    }
    /** --------------------------------------------Abrir y cerrar modal de pago ---------------------*/
    
    const handleClosePay = () => {
        setOpenPayDialog(false);
    }

    /** ------------------------------------------------------------------------------------- */
    const handleClose = () => {
        setOpenAlert(false);
    };

    const handleResendOtp = (event) => {    
        handleAcept(event, 'Código reenviado con éxito');
    };

    const handleChange = event => {       
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                code: event.target.value
            },
            touched: {
                ...formState.touched,
                code: true
            }
        }));
    };

    useEffect(() => {
        const alert = _storageService.load('alert');
        if (!alert) {
            //setOpenAlertAviso(true);
        }
    },[])
  
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.header}>
                <Typography
                    align="center"
                    gutterBottom
                    variant="h1"
                >
          Bienvenid@ {session.user.usuNombre}  
                </Typography>
                <Typography
                    align="center"
                    component="h2"
                    variant="subtitle1"
                >
          Sistema de medicina prepagada MEDICOMPANIES C.A. 
                </Typography>
                
            </div>
            <div style={{textAlign:'center', marginBlockEnd:20}}>
            <Button onClick={() => setOpenVideo(true)} variant='contained' color='primary' size='small' startIcon={<YouTube color='red'/>}>
            GUÍA DE USUARIO</Button>
            </div>
           
            <div className={classes.mediaContainer}>
                <img
                    alt="Demos"
                    className={classes.media}
                    src="/images/auth1.jpg"
                />
            </div>
            {/* <Typography
        align="center"
        component="h2"
        variant="subtitle1"
      >
          Contamos con los Mejores Aliados
      </Typography>
      <br/>
      <Carousel autoplay>
        <div>
          <img
            alt="img"
            src="/images/logos/img1.png"
            style={{ height: '160px' }}
          />
        </div>
        <div>
          <img
            alt="img"
            src="/images/logos/img2.png"
            style={{ height: '160px' }}
          />
        </div>
        <div>
          <img
            alt="img"
            src="/images/logos/img3.png"
            style={{ height: '160px' }}
          />
        </div>
        <div>
          <img
            alt="img"
            src="/images/logos/img4.png"
            style={{ height: '160px' }}
          />
        </div>
      </Carousel> */}

            {/** ----------------------------------------------------------Dialogo de pago */}
            <PayDialog open={openPayDialog} polizafinded={polizafinded} handleClose={handleClosePay} pendingPayments={ pendingPayments } sendOTP={ sendOTP } session={session}/>
            <Dialog
                aria-describedby="scroll-dialog-description"
                aria-labelledby="scroll-dialog-title"
                fullWidth        
                maxWidth={'md'}
                open={open}
                scroll={'body'}
            >
                <DialogTitle id="max-width-dialog-title">Contrato</DialogTitle>
                <DialogContent>
                    { estadoPoliza === 'ESINI' && 
              <DialogContentText>
                  Bienvenido al seguro de MEDICOMPANIES C.A. A continuación vamos a generar su contrato y autorizar el débito bancario, para continuar haga clic en aceptar.
              </DialogContentText>
                    }
                    { estadoPoliza === 'ESTAOTP' && 
            <>
                <DialogContentText>
                    Favor revisar los términos del contrato.
                </DialogContentText>
                <DialogContentText>
                    {
                        status && 
                        <embed 
                            height="350px" 
                            src={'data:application/pdf;base64,'+filePoliza} 
                            type="application/pdf" 
                            width="100%" 
                        />
                    }
                </DialogContentText>                
                {fileAcept && 
                  <>
                      <DialogContentText>
                        Favor revisar la autorización de débito.
                      </DialogContentText>
                      <DialogContentText>
                          {
                              status && 
                            <embed 
                                height="350px" 
                                src={'data:application/pdf;base64,'+fileAcept} 
                                type="application/pdf" 
                                width="100%" 
                            />
                          }
                      </DialogContentText>
                  </>
                }
            </>
                    }
                    <div style={{textAlign: 'right'}}>
                        {
                            openAcept && 
              <div>
                  <Button 
                      color="primary" 
                      onClick={handleAcept} 
                      variant="contained"
                  >
                  Aceptar
                  </Button>
                  <br/><br/>
              </div>         
                        }
                        {
                            openCode &&               
                <div>
                    <DialogContentText>
                    Al colocar el código y pulsar aceptar,  afirma haber leído los términos y condiciones del presente contrato.
                    </DialogContentText>                  
                    <TextField
                        autoFocus
                        error={hasError('code')}
                        helperText={hasError('code') ? formState.errors.code[0] : null}
                        id="code"
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        label="Código"
                        name="code" 
                        onChange={handleChange}
                        variant="outlined"
                    />                  
                    <br/><br/>
                    <Button 
                        color="primary" 
                        onClick={handleResendOtp}    
                        size="small"
                        variant="outlined"
                    >
                    Reenviar Código
                    </Button>
                  &nbsp;
                    <Button 
                        color="primary" 
                        disabled={!formState.isValid}
                        onClick={handleEnviar}
                        variant="contained"
                    >
                    Aceptar
                    </Button>
                    <br/><br/>
                </div>               
                        }          
                    </div>
                </DialogContent>        
            </Dialog>
           

            <Dialog
                aria-describedby="alert-dialog-description"                   
                aria-labelledby="alert-dialog-title"
                open={loading}
                style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
            > 
                <DialogContent >  
                    <CircularProgress/>
                </DialogContent>                       
            </Dialog>
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                open={openAlert}
            >
                <DialogTitle id="alert-dialog-title">{'Atención'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
            Favor ingresar el código enviado a su correo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        onClick={handleClose}
                    >
            Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                open={openAlertAviso}
                fullWidth        
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">{'Atención'}</DialogTitle>
                <DialogContent>          
                    <img
                        alt="Demos"
                        className={classes.mediaAviso}
                        src="/images/banner.png"
                    />          
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        onClick={() => {
                            setOpenAlertAviso(false);
                            _storageService.save('alert', {alert: true})
                        }}
                    >
            Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            open={openVideo}
            fullWidth        
            maxWidth={'md'}
            >
                <DialogContent>
                <iframe 
                width="100%" 
                height="480" 
                src="https://www.youtube.com/embed/iKm9PGAymHc" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
                </iframe>
                </DialogContent>
                <DialogActions>
                    <Button
                    color='primary'
                    variant='contained'
                    onClick={()=> {setOpenVideo(false)}}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

HeaderClient.propTypes = {
    className: PropTypes.string,
    session: PropTypes.object
};

export default HeaderClient;
