import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';

export class LocationProvider {
    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()
        this.person = null
    }

    findLocationByLocCodigo = (locCodigo) => {
        return this.findLocationsbyParameters(
            {'locCodigo': locCodigo}
        );
    }
    

    findLocationsLikeNombre= (searchWorld)=>{
      
        const body={
            'maximoRegistros': 10,
            'tipoConsulta': 1,
            'entidad': 'Localizacion',
            'parametros': {
                locNombre: `%${searchWorld}%`
            }
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            return response.bodyOut.data;
        } 
          
      
        );

        return response;
    }

    findLocationsbyParameters = (parametros)=>{
      
        const body={
            'tipoConsulta': 1,
            'entidad': 'Localizacion',
            'parametros': parametros
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            return response.bodyOut.data;
        } 
          
      
        );

        return response;
    }

   

}
