import { DataService } from 'providers/dataService'

export class PlanBrokerProvider {

    constructor() {
        this.dataService = new DataService();
    }

    findAllPlanBrokerByPlaNombre = name=> this.genericConsultPlanBroker({plaNombre: name, plbEstado: 1});

    findPlanByPlaCodigo = plaCodigo => this.genericConsultPlanBroker({ 'plaCodigo': plaCodigo });

    genericConsultPlanBroker = parameters => this.genericConsult(1, 'PlanBroker', parameters, '0101000');

    genericConsult = (typeConsult, entitye, parameters,typeTransaction) => {
        const body = {
            tipoConsulta: typeConsult,
            entidad: entitye,
            parametros: parameters
        };
        return this.dataService.post('/consulta', body, typeTransaction).then( response => {
            return response.bodyOut.data;
        }
        );
    }

}
