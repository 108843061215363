 /*eslint-disable no-eval */ 

import React, { Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';


export default class UpdateDialog extends Component {
    constructor(props){
        super(props);
        // eval(props.modalInputs[0])
        //m1=eval(props.modalInputs[0]);
        this.state ={
            selectedElement: null,
            CANTIDAD: 0,
            UNIDAD: 0,
            VALOR: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount= () => {
        const {element} = this.props;
        this.setState({
            selectedElement: element[0],
            CANTIDAD: element[1],
            UNIDAD: element[2],
            VALOR: element[1] * element[2]


        });
    }

    handleInputChange=(evt)=>{
        console.log("[evt.target.name]: evt.target.value");
        console.log(evt.target);
        console.log("----------"+ evt.target.value);
        this.setState({
            [evt.target.name]: evt.target.value
        });
        
    }
    addData = () => {
        const {selectedElement, CANTIDAD, UNIDAD, VALOR} = this.state;
        console.log("ADD DATA METHOD");

        console.log(selectedElement + " - "+ CANTIDAD+" - "+ UNIDAD+" - "+ VALOR);
       
        this.props.addData([
                 selectedElement , CANTIDAD, UNIDAD, VALOR
                ]);

        


        this.props.handleClose();
    }
    

    onTagsChange = (event, values) => {
    
        this.setState({
          selectedElement: values
        }, () => {
          // This will output an array of objects
          // given by Autocompelte options property.
          console.log(this.state.selectedElement);
        });
      }

    render(){
      
        const { show, handleClose, modalInputs, valShow, element} = this.props;
        return(
            <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                {element? 
                eval(valShow) : 
                <LinearProgress />
                }
                
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                
                </DialogContentText>
                
                {
                    modalInputs.map((input, index) =>
                        <div key={index} className='medium-margin-top'>
                            <TextField 
                                autoFocus
                                margin="dense"
                                id="name"
                                name={input}
                                label={input}
                                value = {input!=='VALOR' ?
                                eval(`this.state.${input}`)
                                :
                                (this.state.CANTIDAD * this.state.UNIDAD )}
                                fullWidth
                                variant="outlined"
                                onChange={this.handleInputChange}
                                type='number'
                            />
                        </div>
                    )
                    }

                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                {  this.props.element ? 
                    <Button  onClick={()=>this.props.updateElement(this.props.element, [this.state.selectedElement,
                                this.state.CANTIDAD, this.state.UNIDAD, 
                                this.state.CANTIDAD*this.state.UNIDAD ])} color="primary">
                         Actualizar
                </Button>:
                <CircularProgress  />

                }
                   
                </DialogActions>
            </Dialog>
        );

    }
}