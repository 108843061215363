/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import { ConsultViewProvider } from 'providers/consultView.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const AddBeneficiary = () => {
    const consultViewProvider = new ConsultViewProvider();
    const displayMessage = new DisplayMessage();
    const isCancelled = useRef(false);
    const [data, setData] = useState();

    const classes = useStyles();
    const session = useSelector(state => state.session);

    useEffect(() => {
        isCancelled.current = true;
        if (session.user.polCodigo !== -1) {
            let aux = [];
            consultViewProvider.datosVista(session.user.perCodigo).then(
                user => {
                    if (isCancelled.current === true) {
                        user.bodyOut.data.map(item => {
                            if (item.detNemonico === 'ESTACT') {
                                aux.push(item);
                            }
                        });
                        setData(aux);
                    }

                    if (!user.bodyOut.data.length) {
                        displayMessage.error('No tiene poliza activa.');
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
        } else {
            displayMessage.error('No tiene poliza activa.');
        }

        return () => {
            isCancelled.current = false;
        };
    }, []);

    return (
        <Page className={classes.root} title="Beneficiarios">
            <Header />
            <br></br>

            {data ? (
                <Results
                    className={classes.results}
                    poliza={session.user.polCodigo}
                    numeroBeneficiario={data.plaNumBeneficiarios}
                    estadoPoliza={data.detEstado}
                    data={data}
                />
            ) : (
                session.user.polCodigo !== -1 && <CircularProgress />
            )}
            {session.user.polCodigo === -1 && (
                <Alert severity="warning">
                    <Typography component="h1" variant="subtitle1">
                        <b>No tienes póliza activa.</b>
                    </Typography>
                </Alert>
            )}
        </Page>
    );
};

export default AddBeneficiary;
