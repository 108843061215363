import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class HelpDialog extends Component {

    render(){
        const {text, open, handleClose} = this.props;
        return(

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Ayuda"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                   {text}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    ACEPTAR
                </Button>
               
                </DialogActions>
            </Dialog>
        );
    }
}