import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Grid } from '@material-ui/core';
import {
    ConversationToolbar,
    ConversationMessages
} from './components';
import { MessagesForm } from './../../../MessagesDetails/components';

import { HorizontalMenuFiles } from 'components';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.white
    }
}));


const ConversationDetails = props => {
    
    const { session, className, selectedMessages, getMessage,  ...rest } = props;

    const classes = useStyles();

   


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >

            {selectedMessages? <ConversationToolbar conversation={selectedMessages} />:''}
            <Divider />
            {selectedMessages ? <ConversationMessages
                messages={selectedMessages.estados}
                session={session}
            />:''}
            <Divider />
            {selectedMessages.documentos.length> 0 &&
             <Grid container>
                 <Grid
                     item
                     xs={12}
                     md={12}
                     xl={12}

                 ><HorizontalMenuFiles documents={selectedMessages.documentos}/>
                 </Grid>   
             </Grid> }
            
            


            {selectedMessages && 
            ((parseInt(selectedMessages.estados[selectedMessages.estados.length-1].detEstado) ===17174 && session.user.role==='CLIENTE' ) ||
            (parseInt(selectedMessages.estados[selectedMessages.estados.length-1].detEstado) ===17176 && session.user.role==='LIQUIDADOR' )
            )? 
                <MessagesForm
                    detEstado={selectedMessages.estados[selectedMessages.estados.length-1]} 
                    detTipo={selectedMessages.detTipo} 
                    getMessage={getMessage} 
                    liqCodigo={selectedMessages.liqCodigo} 
                    polCodigo={session.user.polCodigo} 
                    tipoConsulta={session.user.role==='CLIENTE'? 1 : 2} 
                    usuCodigo={session.user.usuCodigo}
                    usuNombre={session.user.usuNombre}
                    usuUsuario={session.user.usuUsuario}
                />
                : ''}

              
               
            {
                /**  <div className='absolute-container'>
                <ScrollMenu
                    alignCenter={true}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    dragging={true}
                    hideArrows={true}
                    translate={0}
                    data={menuItems}
                    selected={selected}
                    onSelect={onSelect}

                />
            </div> */}

        </div>
        
    );
};

ConversationDetails.propTypes = {
    className: PropTypes.string
};

export default ConversationDetails;
