import React, { Component } from "react";
import { GenericUploadForm } from "components";
import { Page } from 'components';

export default class PendingToPayView extends Component {


    render() {
        return (
            <Page
                title="Consolidar Pagos"
            >
                <GenericUploadForm title="Consolidar Pagos" />
            </Page>
        );



    }
}