/* eslint-disable react/no-set-state */
import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Card, Select, CardHeader, CardContent, Divider, MenuItem, Button, Grid,
    TextField,
    Typography,
    CircularProgress,
    OutlinedInput
} from '@material-ui/core';
import './styles.css';
import { AtentionMedicalProviders } from 'providers/atentionMedic.service';
import { RefundFormProvider } from 'providers/refundForm.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { ActualDate } from 'services/ActualDate';
import ModallSearchCie10 from './ModlalSeartchCie10/ModallSearchCie10';
import ModalChangeCount from './ModalChangeAccount/ModalChangeAccount';

import { PersonalProvider } from 'providers/persona.service';
import { Search, Cached, Check } from '@material-ui/icons';

const numeros = new RegExp('^[0-9]+$');
const letra = new RegExp('^[ñíóáéú a-zA-Z ]+$');
export default class FormularioReembolso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            empresa: '',
            nombreTitular: '',
            apellidoTitular: '',
            cedulaTitular: '',
            nombrePacinte: '',
            apellidoPaciente: '',
            cedulaPaciente: '',
            edadPaciente: '',
            parentezco: '',
            direccion: '',
            telefono: '',
            email: '',
            numeroCuenta: '',
            tipoCuenta: '',
            banco: '',
            especialidad: '',
            apellidoMedico: '',
            nombreMedico: '',
            cedulaMedico: '',
            telefonoMedico: '',
            diagnostico: '',
            dataPaciente: [],
            dataIngreso: [],
            tamaCuenta: [],
            motivo: '',
            datoSeleccionado: '',
            polCodigo: 0,
            buscarCie10: '',
            buscarCedula: '',
            detCodigocCie10: '',
            cueCodigo: '',
            open: false,
            openCount: false,
            errorEmpresa: false,
            errorPaciente: false,
            errorMotivo: false,
            errorEspecialidad: false,
            errorApellidoMedico: false,
            errorNombreMedico: false,
            errorCedulaMedico: false,
            errorTelefonoMedico: false,
            errorCie10: false,
            touched: {
                motivo: false,
                especialidad: false,
                apellidoMedico: false,
                nombreMedico: false,
                cedulaMedico: false,
                telefonoMedico: false,
                diagnostico: false,
            },
            errors: {
                required: {
                    motivo: false,
                    especialidad: false,
                    apellidoMedico: false,
                    nombreMedico: false,
                    cedulaMedico: false,
                    telefonoMedico: false,
                    diagnostico: false,
                },
                valid: {
                    motivo: true,
                    especialidad: true,
                    apellidoMedico: true,
                    nombreMedico: true,
                    cedulaMedico: true,
                    telefonoMedico: true,
                    diagnostico: false,
                }
            },
            errorAlert: false,
            liqPerPol: null
        };

        this.pP = new PersonalProvider();
    }

    async componentDidMount() {
        const atention = new AtentionMedicalProviders();
        const displayMessage = new DisplayMessage();

        atention.tipoAtencion()
            .then(
                data => {

                    this.setState({ dataMotivo: data.bodyOut.data });
                },
                () => {
                    displayMessage.error('Error de comunicación.');

                }

            );

        /* Titular */
        const refunFormProvider = new RefundFormProvider();

        refunFormProvider.datosPaciente(this.props.polCodigo)
            .then(
                data => {
                    this.setState({ dataPaciente: data.bodyOut.data });
                },
                () => {
                    displayMessage.error('Error de comunicación paciente.');
                }
            );


        refunFormProvider.datosTitular(21, this.props.polCodigo)
            .then(
                data => {
                    if (data.error.codigo === '0') {
                        this.setState({
                            codTitular: data.bodyOut.data[0].perCodigo,
                            //apellidoTitular:value.perApellidos,
                            nombreTitular: data.bodyOut.data[0].perNombres + ' ' + data.bodyOut.data[0].perApellidos,
                            cedulaTitular: data.bodyOut.data[0].perIdentificacion,
                            numeroCuenta: data.bodyOut.data[0].cueCuenta,
                            tipoCuenta: data.bodyOut.data[0].descripcionCuenta,
                            banco: data.bodyOut.data[0].nombre1,
                            polCodigo: data.bodyOut.data[0].polCodigo,
                        });
                        this.getCodigoCuenta(data.bodyOut.data[0].perCodigo)
                    }
                },
                () => {
                    return false;

                }

            );

        /* Empresa */
        refunFormProvider.datosEmpresa()
            .then(
                data => {
                    this.setState({ dataEmpresa: data.bodyOut.data });
                },
                () => {
                    return false;

                }

            );

    }

    handleChangeSelect = (e) => {
        this.setState({ motivo: e.target.value, errorMotivo: false });
    }

    getCodigoCuenta = (perCodigo) => {
        const refunFormProvider = new RefundFormProvider();

        refunFormProvider.obtenerCuenta(perCodigo)
            .then(
                data => {
                    this.setState({ cueCodigo: data.bodyOut.data[0].cueCodigo });
                },
                () => {

                }
            );
    }


    handleChange = (e) => {
        const { name, value } = e.target;
        const errors = {
            required: { ...this.state.errors.required, [name]: false }
        };
        this.setState({
            [name]: value,
            errors: { ...this.state.errors, ...errors },
            errorEspecialidad: false,
            errorApellidoMedico: false,
            errorNombreMedico: false,
            errorCedulaMedico: false,
            errorTelefonoMedico: false
        });
    }

    Check = (e) => {
        const field = e.target.name;
        this.setState({
            touched: { ...this.state.touched, [field]: true }
        });
        this.validate(e);
    }

    validate(e) {
        const target = e.target;
        const { value, name } = target;

        if (value.length === 0) {
            const errors = {
                required: { ...this.state.errors.required, [name]: true }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
            return;
        }

        if (name === 'cedulaMedico') {
            this.validateCedula(value)
        }
        if (name === 'apellidoMedico') {
            this.validateApellidos(value)
        }
        if (name === 'nombreMedico') {
            this.validateNombres(value)
        }
        if (name === 'telefonoMedico') {
            this.validateTelefono(value);
        }
        if (name === 'diagnostico') {
            this.validateDiagnostico(value);
        }

    }

    validateDiagnostico() {
        const errors = {
            valid: { ...this.state.errors.valid, diagnostico: true }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateCedula() {
        if (this.state.cedulaMedico.length === 10) {
            const cedulaIsValid = numeros.test(this.state.cedulaMedico);
            const errors = {
                valid: { ...this.state.errors.valid, cedulaMedico: cedulaIsValid }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
        } else {
            const errors = {
                valid: { ...this.state.errors.valid, cedulaMedico: false }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
        }
    }

    validateNombres() {
        const letrasIsValid = letra.test(this.state.nombreMedico);
        const errors = {
            valid: { ...this.state.errors.valid, nombreMedico: letrasIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }

    validateApellidos() {
        const letrasIsValid = letra.test(this.state.apellidoMedico);
        const errors = {
            valid: { ...this.state.errors.valid, apellidoMedico: letrasIsValid }
        };

        this.setState({
            errors: { ...this.state.errors, ...errors }
        });
    }




    validateTelefono() {
        if ((this.state.telefonoMedico.length > 0 && this.state.telefonoMedico.length === 10) || this.state.telefonoMedico.length === 7) {
            const edadIsValid = numeros.test(this.state.telefonoMedico);
            const errors = {
                valid: { ...this.state.errors.valid, telefonoMedico: edadIsValid }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
        } else {
            const errors = {
                valid: { ...this.state.errors.valid, telefonoMedico: false }
            };

            this.setState({
                errors: { ...this.state.errors, ...errors }
            });
        }
    }


    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    isFormInvalid() {
        const { errors } = this.state;
        const { required, valid } = errors;
        const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
        const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

        return isSomeFieldInvalid || isSomeFieldRequired;
    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `Campo ${field} `;

        if (required[field]) {
            return `${errorMessage} es obligatorio`;
        }

        if (!valid[field]) {
            return `${errorMessage} no es valido`;
        }
    }

    formatoFecha(string) {
        var f = string.split('-').reverse().join('/');
        return f;
    }

    handleChangeLiq = () => {
        const refunFormProvider = new RefundFormProvider();
        const displayMessage = new DisplayMessage();
        refunFormProvider.editarLiquidacion(this.props.id, this.state.codPaciente)
            .then(
                () => {
                    displayMessage.success('Registro correcto');
                    this.saveCuent();


                },
                () => {
                    displayMessage.error('Error al Registrar');

                }

            );
    }

    saveCuent = () => {
        const refunFormProvider = new RefundFormProvider();
        const displayMessage = new DisplayMessage();
        refunFormProvider.editarCuenta(this.state.cueCodigo, `${this.state.numeroCuenta}`)
            .then(
                () => {
                    window.location.href = `/liquidador/digitalizar/${btoa(this.props.id)}`
                },
                () => {
                    displayMessage.error('Error al Registrar');

                }

            );
    }

    handleOnClick = async () => {
        const displayMessage = new DisplayMessage();
        const { buscarCie10, empresa, cedulaTitular, cedulaPaciente, especialidad, apellidoMedico, motivo,
            nombreMedico, cedulaMedico, telefonoMedico } = this.state;
        if (buscarCie10 === '' || empresa === '' || cedulaTitular === '' || cedulaPaciente === '' ||
            especialidad === '' || apellidoMedico === '' || nombreMedico === '' || cedulaMedico === '' || telefonoMedico === '' || motivo === '') {
            displayMessage.error('Complete los campos obligatorios');
            if (buscarCie10 === '') {
                this.setState({ errorCie10: true })
            }
            if (empresa === '') {
                this.setState({ errorEmpresa: true })
            }
            if (cedulaPaciente === '') {
                this.setState({ errorPaciente: true })
            }
            if (motivo === '') {
                this.setState({ errorMotivo: true })
            }
            if (especialidad === '') {
                this.setState({ errorEspecialidad: true })
            }
            if (apellidoMedico === '') {
                this.setState({ errorApellidoMedico: true })
            }
            if (nombreMedico === '') {
                this.setState({ errorNombreMedico: true })
            }
            if (cedulaMedico === '') {
                this.setState({ errorCedulaMedico: true })
            }
            if (telefonoMedico === '') {
                this.setState({ errorTelefonoMedico: true })
            }
        } else {
            const refunFormProvider = new RefundFormProvider();
            const fecha = ActualDate();
            const fechaset = this.formatoFecha(fecha);


            const datosReembolso = [{
                empCodigo: this.state.empresa,
                liqCodigo: this.props.id,
                detTipo: 17173,
                perTitular: this.state.codTitular,
                perPaciente: this.state.codPaciente,
                polCodigo: this.state.polCodigo,
                cueCodigo: this.state.cueCodigo,
                detProcedimiento: this.state.motivo,
                detCie10: this.state.detCodigocCie10,
                forFecha: fechaset,
                forDetalle: `{nCuenta:${this.state.numeroCuenta}, tipoCuenta:${this.state.tipoCuenta}, banco:${this.state.banco}, nombreMedico:${this.state.nombreMedico}, cedulaMedico:${this.state.cedulaMedico}, telefonoMedico:${this.state.telefonoMedico}, diagnostico:${this.state.diagnostico}, especialidad:${this.state.especialidad}}`
            }];

            if (this.state.tamaCuenta.length !== 0) {
                refunFormProvider.editarCuenta1(this.state.tamaCuenta)
                    .then(
                        () => {

                        },
                        () => {
                            displayMessage.error('Error al Registrar');

                        }

                    );
            }
            await refunFormProvider.ingresoFormularioReembolso(datosReembolso)
                .then(
                    (data) => {
                        if (data.error.codigo === '0') {
                            //displayMessage.success('Registro correcto');
                            this.handleChangeLiq();
                            //this.saveCuent();
                        }

                    },
                    () => {
                        displayMessage.error('Error al Registrar');

                    }

                );
        }
    }

    handleOnChangeEnfermedad = (e) => {
        this.setState({ buscarCie10: e.target.value })
    }

    handleSearchCie10 = () => {
        this.setState({ open: true })
    }

    handleOnChangeEmpresa = (value) => {
        this.setState(value ?
            {
                empresa: value.empCodigo,
                errorEmpresa: false
            } :
            {
                empresa: ''
            })
    }

    handleOnChangePaciente = (value) => {
        this.setState(
            {
                codPaciente: value.perCodigo,
                apellidoPaciente: value.perApellidos,
                nombrePacinte: value.perNombres,
                cedulaPaciente: value.perIdentificacion,
                edadPaciente: value.edadActual,
                parentezco: value.nombre2,
                direccion: value.perDireccion,
                email: value.perMail,
                errorPaciente: false
            })
    }


    handleClose = () => {
        this.setState({ open: false });
    }

    datosModal = (dato1, dato2) => {
        this.setState({ buscarCie10: dato2, detCodigocCie10: dato1, errorCie10: false })
    }

    datosModalCount = (numero, tipo, numTipo, banco, numBanco) => {
        this.setState({
            numeroCuenta: numero,
            tipoCuenta: tipo,
            banco: banco,
            tamaCuenta: [{
                cueCodigo: this.state.cueCodigo,
                cueCuenta: numero,
                detIfi: numBanco,
                detTipoCuenta: numTipo

            }]
        })
    }

    handleChangeCuenta = () => {
        this.setState({ openCount: true })
    }
    handleCloseAccount = () => {
        this.setState({ openCount: false })
    }

    render() {
        return (
            <div>
                <ModallSearchCie10
                    close={this.handleClose}
                    datos={this.datosModal.bind(this)}
                    open={this.state.open}
                />
                {this.state.openCount &&
                    <ModalChangeCount
                        close={this.handleCloseAccount}
                        datos={this.datosModalCount.bind(this)}
                        open={this.state.openCount}
                    />
                }
                <form>
                    <Grid
                        alignItems="flex-end"
                        container
                        justify="space-between"
                        spacing={3}
                    >
                        <Grid>
                            <Typography
                                component="h1"
                                variant="h2"
                            >
                                Formulario Reembolso
                          </Typography>
                            <br />
                        </Grid>

                    </Grid><br />

                    <Grid
                        container
                        spacing={6}
                    >
                        <Grid
                            item
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <TextField
                                error={this.state.errorCie10}
                                fullWidth
                                helperText={this.state.errorCie10 && 'Busque CIE10'}
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="CIE10"
                                name="buscarCie10"
                                onChange={this.handleOnChangeEnfermedad}
                                placeholder="CIE10"
                                required
                                value={this.state.buscarCie10}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                onClick={this.handleSearchCie10}
                                variant="contained"
                                size="small"
                            ><Search />
                              Buscar cie10
                          </Button>
                        </Grid>
                    </Grid><br />
                    <Card>
                        <Card className="cardSpace">
                            <CardHeader
                                title="1. Datos Afiliados"
                            />
                            <Divider />
                        </Card>
                        <Card className="cardSpace">
                            <CardContent>
                                <Grid>
                                    {this.state.dataEmpresa ?
                                        <Autocomplete
                                            getOptionLabel={option => option.empNombreComercial}
                                            id="debug"
                                            loading
                                            onChange={(e, value) => value ? this.handleOnChangeEmpresa(value) : null}
                                            options={this.state.dataEmpresa}
                                            renderInput={params => <TextField
                                                {...params}
                                                error={this.state.errorEmpresa}
                                                fullWidth
                                                helperText={this.state.errorEmpresa && 'Campo obligatorio'}
                                                label="Buscar Empresa"
                                                margin="none"
                                                variant="outlined"
                                                required
                                            />}
                                        /> : <CircularProgress />}
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card className="cardSpace">
                            <CardHeader
                                title="Titular"
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Cédula"
                                            variant="outlined"
                                            name="cedulaTitular"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.cedulaTitular}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Nombres"
                                            name="nombreTitular"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.nombreTitular}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={5}
                                        xs={12}
                                    />

                                </Grid>


                            </CardContent>
                        </Card>

                        <Card className="cardSpace">
                            <CardHeader
                                title="Paciente"
                            />
                            <Divider />
                            {/* Consultar Paciente */}
                            <CardContent>

                                <Autocomplete
                                    getOptionLabel={option => option.perIdentificacion + ' - ' + option.perNombres + ' - ' + option.nombre2}
                                    onChange={(e, value) => value ? this.handleOnChangePaciente(value) : this.setState({
                                        cedulaPaciente: '',
                                        nombrePacinte: '',
                                        apellidoPaciente: '',
                                        edadPaciente: '',
                                        parentezco: '',
                                        direccion: '',
                                        telefono: '',
                                        email: ''
                                    })}
                                    options={this.state.dataPaciente}
                                    renderInput={params => <TextField
                                        {...params}
                                        error={this.state.errorPaciente}
                                        fullWidth
                                        helperText={this.state.errorPaciente && 'Campo obligatorio'}
                                        label="Buscar Paciente"
                                        margin="none"
                                        variant="outlined"
                                        required
                                    />}
                                    style={{ width: 300, paddingBlockEnd: 20 }}
                                />
                            </CardContent>
                            <Divider />
                            <CardContent>

                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Cédula"
                                            name="cedulaPaciente"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.cedulaPaciente}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Nombres"
                                            name="nombrePacinte"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.nombrePacinte}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        md={1}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Edad"
                                            variant="outlined"
                                            name="edadPaciente"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.edadPaciente}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Parentesco"
                                            variant="outlined"
                                            name="parentezco"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.parentezco}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            variant="outlined"
                                            name="direccion"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.direccion}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            variant="outlined"
                                            name="email"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.email}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>


                        <Card className="cardSpace">
                            <CardHeader
                                title="Transferencia a reembolso"
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={2}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="N° Cuenta"
                                            variant="outlined"
                                            type="number"
                                            name="numeroCuenta"
                                            onChange={this.handleChange}
                                            //readOnly
                                            value={this.state.numeroCuenta || ''}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Tipo cuenta"
                                            variant="outlined"
                                            name="tipoCuenta"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={this.state.tipoCuenta || ''}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Banco"
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ width: 350 }}
                                            value={this.state.banco || ''}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={this.handleChangeCuenta}
                                            variant="contained"
                                            size="small"
                                        >
                                            <Cached />Cambiar cuenta
                                  </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Card><br />

                    {/* Antecedentes medicos  */}

                    <Card>
                        <Card className="cardSpace">
                            <CardHeader
                                title="2. Antecedentes médicos e información del médico"
                            />
                            <Divider />
                        </Card>
                        <CardHeader
                            title="Motivo de atención médica"
                        />
                        <Divider />
                        <Card className="cardSpace">
                            <CardContent>
                                <Grid className="">

                                    {this.state.dataMotivo ?
                                        <Select
                                            variant="outlined"
                                            className="select"
                                            error={this.state.errorMotivo}
                                            name="motivo"
                                            onChange={this.handleChangeSelect}
                                            required
                                            style={{ width: 300 }}
                                            value={this.state.motivo}
                                        >
                                            <MenuItem value="">Seleccione</MenuItem>
                                            {this.state.dataMotivo ? this.state.dataMotivo.map((motivo) =>
                                                <MenuItem
                                                    key={motivo.detCodigo}
                                                    value={motivo.detCodigo}
                                                >
                                                    {motivo.detNombre}
                                                </MenuItem>

                                            ) : null}

                                        </Select> : <CircularProgress />}
                                    <p className={this.hasError('Motivo') || this.state.errorMotivo ? 'error-message__visible' : 'error-message'}>
                                        {this.displayError('Motivo')}
                                    </p>
                                </Grid>

                            </CardContent>
                        </Card>
                        <Card className="cardSpace">
                            <Divider />
                            <CardContent>
                                <Grid>
                                    <TextField
                                        fullWidth
                                        label="Especialidad"
                                        variant="outlined"
                                        error={this.hasError('especialidad') || this.state.errorEspecialidad}
                                        name="especialidad"
                                        onBlur={this.Check}
                                        onChange={this.handleChange}
                                        required
                                        value={this.state.especialidad}
                                    />
                                    <p className={this.hasError('especialidad') || this.state.errorEspecialidad ? 'error-message__visible' : 'error-message'}>
                                        {this.displayError('Especialidad')}
                                    </p>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card className="cardSpace">
                            <CardHeader
                                title="Médico"
                            />
                            <Divider />
                            <CardContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Apellidos"
                                            variant="outlined"
                                            error={this.hasError('apellidoMedico') || this.state.errorApellidoMedico}
                                            name="apellidoMedico"
                                            onBlur={this.Check}
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.apellidoMedico}
                                        />
                                        <p className={this.hasError('apellidoMedico') || this.state.errorApellidoMedico ? 'error-message__visible' : 'error-message'}>
                                            {this.displayError('Apellido')}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Nombres"
                                            variant="outlined"
                                            error={this.hasError('nombreMedico') || this.state.errorNombreMedico}
                                            name="nombreMedico"
                                            onBlur={this.Check}
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.nombreMedico}
                                        />
                                        <p className={this.hasError('nombreMedico') || this.state.errorNombreMedico ? 'error-message__visible' : 'error-message'}>
                                            {this.displayError('Nombre')}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Cédula"
                                            variant="outlined"
                                            error={this.hasError('cedulaMedico') || this.state.errorCedulaMedico}
                                            name="cedulaMedico"
                                            onBlur={this.Check}
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.cedulaMedico}
                                        />
                                        <p className={this.hasError('cedulaMedico') || this.state.errorCedulaMedico ? 'error-message__visible' : 'error-message'}>
                                            {this.displayError('Cédula')}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        md={3}
                                        xs={12}
                                    >
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            variant="outlined"
                                            error={this.hasError('telefonoMedico') || this.state.errorTelefonoMedico}
                                            name="telefonoMedico"
                                            onBlur={this.Check}
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.telefonoMedico}
                                        />
                                        <p className={this.hasError('telefonoMedico') || this.state.errorTelefonoMedico ? 'error-message__visible' : 'error-message'}>
                                            {this.displayError('Teléfono')}
                                        </p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Card className="cardSpace">
                            <CardContent>

                                {/* <InputLabel htmlFor="standard-adornment-diagnostico">Diagnóstico Definitivo</InputLabel> */}
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    error={this.hasError('diagnostico')}
                                    label="Diagnostico"
                                    multiline
                                    name="diagnostico"
                                    onBlur={this.Check}
                                    onChange={this.handleChange}
                                    required
                                    value={this.state.diagnostico}
                                />
                                <p className={this.hasError('diagnostico') ? 'error-message__visible' : 'error-message'}>
                                    {this.displayError('Diagnostico')}
                                </p>
                            </CardContent>
                        </Card>
                    </Card>
                    <div className="boton">
                        <Button
                            color="primary"
                            disabled={this.isFormInvalid()}
                            onClick={this.handleOnClick}
                            variant="contained"
                            size="small"
                        ><Check />Digitalizar
                      </Button>

                    </div>
                </form>
            </div>
        );
    }
}
