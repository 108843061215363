import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import {  Add } from '@material-ui/icons'
import {  Card,  Grid, Button } from '@material-ui/core';
import { AppointmentProvider } from 'providers/appointment.service';
import { SearchBasic } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchLeader(props) {
    const classes = useStyles();
    const tableRef = React.useRef();
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();
    const [total, setTotal] = useState(0)
    const [booleano, setBooleano] = useState(false);

    const [columns] = useState([
        { title: 'Ruc', field: 'PRE_RUC' },
        //{ title: 'Razon Social', field: 'PRE_RAZON_SOCIAL' },
        { title: 'Nombre', field: 'PRE_NOMBRE' },
        { title: 'Tipo prestador', field: 'DET_NOMBRE' },
        { title: 'Dirección', field: 'PRE_DIRECCION' },
        { title: 'Ciudad', field: 'LOC_NOMBRE' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');

    const loadData = (query, resolve) => {

        let filt = ''
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PRE_CODIGO',
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }
        filt = orderBy.field

        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'desc'
        }

        appoinment.buscarPrestador(buscarTitular, orderBy.field, direction.orderDirection, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setBooleano(false)
                        setTotal(response.bodyOut.data[0].TOTAL)
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });

                    } else {
                        setBooleano(false)
                        setTotal(0)
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        displayMessage.error('No se encontró resultados')
                    }

                },
                () => {
                    setBooleano(false);
                    setTotal(0)
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );

    }

    const handleBuscarTitular = () => {
        tableRef.current.onSearchChange(buscarTitular);
    }

    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }


    const handleLimpiar = () => {

        tableRef.current.onQueryChange();
        setBooleano(false)
        setBuscarTitular('')
    }




    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <>
            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />
            <Grid
                container
                spacing={2}
            >


                <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: 1, textAlign: 'right', marginBottom: 10 }}
                >

                    <Button
                        component={RouterLink}
                        to={'/administrador/nuevoPrestador'}
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        <Add /> nuevo prestador
                    </Button>
                    {/*  <Tooltip title="Exportar a CSV">
                        <Button
                            style={{ borderRadius: 5, marginRight: 10, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(2); }}
                        >

                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(3); }}
                        >
                            <FaFileExcel size={20} style={{ marginRight: 10 }} /> EXCEL
                        </Button>
                    </Tooltip> */}

                </Grid>
            </Grid>

            <Card>


                <MaterialTable
                    tableRef={tableRef}
                    actions={[
                        {
                            icon: 'save',
                            tooltip: 'Save User',
                            onClick: () => { }
                        }
                    ]}
                    components={{
                        Action: props => (
                            <>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    size="small"
                                    style={{ textTransform: 'none', marginRight: 10 }}
                                    to={`/administrador/editarPrestador/${btoa(props.data.PRE_CODIGO)}`}
                                    variant="contained"
                                >
                                    <VisibilityIcon className={classes.getAppIcon} />
                                Ver
                                </Button>
                            </>
                        ),
                    }}
                    columns={columns}
                    data={booleano === false ? (query =>
                        new Promise((resolve) => {
                            loadData(query, resolve)

                        })) : []}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            search: false,
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'


                        },
                        header: {
                            actions: 'Acción'
                        }
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: false, search: false, toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                        }),
                    }}
                    title="Prestadores"
                />


            </Card><br />
        </>
    );
}