import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RefundFormProvider } from 'providers/refundForm.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {Cancel} from '@material-ui/icons'
import { Card, CardHeader, CardActions, Grid, TextField, Button, IconButton, Dialog} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatInvoice from 'components/NumberFormatMoney';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function ModalChangeAccount(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [dataCombo,setDataCombo]= useState([])
    const refunFormProvider = new RefundFormProvider();
    const displayMessage = new DisplayMessage();
    const [banco,setBanco] = useState('')
    const [tipo,setTipo] = useState('')
    const [numBanco,setNumBanco] = useState(0);
    const [numTipo,setNumTipo] = useState(0);

    const [cuenta, setCuenta] = useState('');

    useEffect(()=>{
        const refunFormProvider = new RefundFormProvider();
        const displayMessage = new DisplayMessage();
        refunFormProvider.comboTipo()
            .then(
                data => {
                    setData(data.bodyOut.data);
                },
                () => {          
                    displayMessage.error('Error de comunicación.');

                }

            );
    }, []);
    const getCiudades = search => {
        refunFormProvider.comboIfi(search)
            .then(
                response => {
                    if(response.error.codigo === '0' && response.bodyOut.data.length){
                        setDataCombo(response.bodyOut.data);                        
                    }                    
                },
                () => {
                    displayMessage.error('Datos no encontrados')
                }
            )
    }

    const handleChange = (e,values)=>{
        if(values){
            setBanco(values.detNombre);
            setNumBanco(values.detCodigo)
        } else {
            setBanco('');
        }
    }

    const handleChangeText = (event) => {                
        let search = event.target.value;
        if (search.length >= 3) {            
            getCiudades(search);
        } else {            
            setDataCombo([]);
        }
    }

    const handleClose = () => {
        props.close();
        setCuenta('');
        //setBuscarApellido('');
        setData([]);
    }
    const handleCloseBank= ()=>{
        setDataCombo([])
    }

    const handleChangeTipo= (e,values) =>{
        if(values){
            setTipo(values.detNombre);
            setNumTipo(values.detCodigo)
        } else {
            setTipo('');
        }
    }

    const handleChangeCuenta = (e) => {
        setCuenta(e.target.value)
    }

    const handleEnviarData = () => {
        if(cuenta === '' || tipo===''||banco ===''){
            displayMessage.error('Complete todos los campos')
        }else{
            props.datos(cuenta,tipo,numTipo,banco,numBanco)
            props.close();
        }
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog
            maxWidth="lg"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                            >
                        <Cancel/>
                    </IconButton>
                    }
                    title="Cambio datos de cuenta"
                />
                <CardActions>
                    <div className={classes.root}>
                        {/*  <Grid
                            container
                            spacing={3}
                        > */}
                        <Grid
                            //className={classes.item}
                            item
                            md={6}
                            sm={4}
                            xs={12}
                        >
            
                            <TextField
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatInvoice
                                }}
                                name="cuenta"
                                onChange={handleChangeCuenta}
                                placeholder="Ingrese número de cuenta"
                                style={{width:350}}
                                value={cuenta}
                                variant="outlined"
                            />
            
       
         
                        </Grid><br/>
                            
                        <Grid
        
                            item
                            md={6}
                            sm={4}
                            xs={12}
                        >
                            <Autocomplete
                                getOptionLabel={(option) => option.detNombre}
                                noOptionsText=""
                                //onBlur={handleBlur}
                                onChange={handleChangeTipo}
                                onClose={handleCloseBank}
                                options={data}                                                                        
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        /*  error={ciudadError}
                                            helperText={ciudadError ? 'Seleccione ciudad' : ''}                                             */
                                        label="Tipo de cuenta" 
                                        margin="normal"  
                                        style={{ marginTop: '0px' }}
                                        variant="outlined"
                                    />
                                )}
                                style={{width:350}}
                            />       
         
                        </Grid><br/>
                        <Grid
        
                            item
                            md={6}
                            sm={4}
                            xs={12}
                        >
           
                            <Autocomplete
                                getOptionLabel={(option) => option.detNombre}
                                noOptionsText=""
                                //onBlur={handleBlur}
                                onChange={handleChange}
                                onClose={handleCloseBank}
                                options={dataCombo}                                                                        
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        /*  error={ciudadError}
                                            helperText={ciudadError ? 'Seleccione ciudad' : ''}                                             */
                                        label="Banco" 
                                        margin="normal"  
                                        onChange={handleChangeText}
                                        placeholder="Buscar..."
                                        style={{ marginTop: '0px' }}
                                        variant="outlined"
                                    />
                                )}
                                style={{width:350}}
                            />
            
       
         
                        </Grid><br />
                        <Grid
                            item
                            sm={2}
                            xs={6}
                        >
               
                            <Button
                                color="primary"
                                onClick={handleEnviarData}
                                variant="contained"
                            >Confirmar</Button>
                
                  
                        </Grid>
                            
                        {/* </Grid> */}
                    </div>
          
                </CardActions>
            
               
            </Card><br/>
        </Dialog>
    );
}