import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';


export class PersonalProvider {

    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()


        this.person = null
    }
    
    

    allPersonsByNombre2 = (nombre2, perIdentificacion)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwPolPerBan',
            'parametros': {
                nombre2: nombre2,
                perIdentificacion:`${perIdentificacion}%`
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
                
        
        });

       

        return response;
    }


    documentsByLiqCodigo = (liqCodigo) => {

        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Documento',
            'parametros': {
                liqCodigo: liqCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
        } 
            
        );


        return response;

    }

    documentsByLiqCodigo2 = (liqCodigo) => {

        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Documento',
            'parametros': {
                liqCodigo: liqCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
        } 
            
        );


        return response;

    }

    validatePolizaPersona= (persona) => {

        const dS = new DataService();
        
        const body={
            'registroInicio':5,
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                perIdentificacion: persona.perIdentificacion,
                detTipoPersona: persona.detTipoIdentificacion
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101011').then( response => {
            
            return response;
        } 
            
        );


        return response;

    }

    LiquidationByliqCodigo= (liqCodigo) => {

        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                liqCodigo: liqCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
        } 
            
        );


        return response;

    }
    LiqperpolByliqCodigo= (liqCodigo) => {

        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwLiqperpol',
            'parametros': {
                liqCodigo: liqCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
        } 
            
        );


        return response;
    }

    allPatientByPolCodigo = (polCodigo)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwPolPerBan',
            'parametros': {
                polCodigo: polCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );

      
        return response;
    }

    personByPerCodigo = (perCodigo)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                perCodigo: perCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );

      
        return response;
    }


    allPersons = ()=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwPolPerBan',
            'parametros': {
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );

        

        return response;
    }

    findAllPersons = ()=>{
        const dS = new DataService();
        
        const body = {
            'maximoRegistros':50,
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perEstado':1
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            return response.bodyOut.data;
        } 
        
        );
        return response;
    }

    findPerson = (identificacion)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perIdentificacion': identificacion
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
          
        } 
            
        
        );


        return response;
       
    }

    findPersonByPerCodigo = perCodigo => {
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perCodigo': perCodigo
            }
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            return response.bodyOut.data;
        } 
        );
        return response;
    }

    buscarTItular(beneficiarioIdentificacion, beneficiarioApellido, beneficiarioNombre) {
        let body = {
            maximoRegistros:5,
            tipoConsulta: 1,
            entidad: 'Persona',
            parametros: {
                perIdentificacion: beneficiarioIdentificacion+'%',
                perApellidos:beneficiarioApellido+'%',
                perNombres:beneficiarioNombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
    createPerson(persons) {
        let body = {       
            'tipoAccion': 1,
            'entidad': 'Persona',
            'entidades': persons
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101113');
    }

    updatePerson(persons) {
        let body = {       
            'tipoAccion': 2,
            'entidad': 'Persona',
            'entidades': persons
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101113');
    }
    

    findPersonLikeName = (identificacion)=>{
        const dS = new DataService();

        const body={
            'tipoConsulta': 1,
            'entidad': 'VwPolPerBan',
            'parametros': {
                'perIdentificacion': `${identificacion}%`
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
        );
        return response;
    }



    findByNField = (parameters)=>{

        const body={
            'maximoRegistros':10,
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': parameters
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
            
        } 
        );
        return response;
    }

    searchOr = (value) => {
        return this.findByNField(
            {
                'perIdentificacion': `%${value}%`,
                'perNombres': `||%${value}%`,
                'perApellidos': `||%${value}%`,
                'perNacionalidad': `||%${value}%`

            }
        );
    }
    searchByIdentificacion = (value) => {
        return this.findByNField(
            {
                'perIdentificacion': `%${value}%`,
            }
        );
    }

    searchByName = (value) => {
        return this.findByNField(
            {
                'perNombres': `%${value}%`
            }
        );
    }
    searchByLastName = (value) => {
        return this.findByNField(
            {
                'perApellidos': `%${value}%`
            }
        );    
    }

    searchByNationality = (value) => {
        return this.findByNField(
            {
                'perNacionalidad': `%${value}%`
            }
        );    
    }

    

    getPerson(){
        return this.person;
    }

    login(username, password) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: username,
                password: password						
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101001', username);
    }

    getUser(){
        return this.storageService.getSession()
    }

    logout() {
        this.storageService.removeSession();
    }

    isAuthenticated() {
        let user = JSON.parse(localStorage.getItem('user'))        
        return user && user.role
    }

}
