import React, { Component } from 'react';
import { TableGeneric, Page,SearchBar, GenericHeader } from "components";

import Grid  from "@material-ui/core/Grid";

import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';

import "./styles.css"

import Button from '@material-ui/core/Button';


import RadioGroup from '@material-ui/core/RadioGroup';

import { ActualDate } from "./../../services/ActualDate";

export default class AccountingReportsView extends Component {

    constructor(props){
        super(props);
        this.state={
            option: "Pagos",
            startDate: ActualDate(),
            finalDate: ActualDate(),
        }

        this.handleRadioChange= this.handleRadioChange.bind(this);
        this.pagosChecked = this.pagosChecked.bind(this);
    }

    pagosChecked=(evt)=>{
        console.log(evt.target)
                console.log("evt");
                console.log("mensaje de prueba");

        console.log(evt)
    }
     handleChange = event => {
         this.setState({
             option: event.target.value
         });
        //setValue(event.target.value);
      };
    
handleRadioChange = (evt) => {

    const elementCobros= document.getElementById('Cobros');
    const elementPagos= document.getElementById('Pagos');

    console.log(evt.target.name);
    if(evt.target.name === "Cobros" && evt.target.checked === true ){
        console.log("eentro cobros chucha ")

        elementPagos.checked=false;
        this.setState(
            {
                option: "Cobros"
            }
        );

    }
    if(evt.target.name === "Pagos" && evt.target.checked === true ){
        console.log("eentro pagos chucha ")

        elementCobros.checked=false;
        this.setState(
            {
                option: "Pagos"
            }
        );

    }
    console.log(evt.target);
    console.log(evt.target.checked)

}



    render(){
        const data =["11201DCDS", "DAVID ALEJANDRO PADILLA SARMIENTO", new Date().toString(), "1719984571",1000.5, "Básico", "100%",
        <Button variant="contained" color="secondary">
  PDF

</Button>];
        const columna = ["REQUERIMIENTO","NOMBRE","FECHA DE REPORTE","CEDULA", "VALOR A PAGAR","PLAN","COBERTURA"];
        return(
            <Page
                title="Reportes"
                className="medium-margin"
            >
                <GenericHeader title="REPORTES" />
                <div  className="margin-bottom">
          
                <Grid container 
                    spacing={3}
                    >
                                            <Grid item xs>

        <RadioGroup value={this.state.option} onChange={this.handleChange} row>

                    
                    <FormControlLabel
                        value="Pagos"
                        control={<Radio color="primary" />}
                        label="Pagos"
                        labelPlacement="end"
                        />
                                    
                    
                    <FormControlLabel
                        value="Cobros"
                        control={<Radio color="primary" />}
                        label="Cobros"
                        labelPlacement="end"
                        />
        </RadioGroup>
                    </Grid>


                    <Grid item xs>
                        <TextField type="date" id="outlined-basic" label="Desde" value={this.state.startDate} variant="outlined" />
                    </Grid>
                    <Grid item xs>
                        <TextField type="date" id="outlined-basic" label="Hasta" value={this.state.finalDate} variant="outlined" />
                    </Grid>

                </Grid>
                </div>
                        <SearchBar />
                        <TableGeneric 
                            data={data} 
                            columna={columna}
                        ></TableGeneric>

            </Page>
        );


    }
}