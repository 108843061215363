import React, {Component} from 'react';
import  StripeScriptLoader  from  'react-stripe-script-loader';

import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Dialog, Button, Grid, IconButton, Card, CardHeader,Divider, CardContent, Typography } from '@material-ui/core';
import { StripeServicesProvider } from 'providers/stripe.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';const createOptions = () => {
    return {
        style: {
            base: {
                
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
                fontWeight:500
            },
            invalid: {
                color: '#c23d4b',
            },
        },
    };
};

class _SplitFieldsForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            errorMessage: '',
            disabledPay: false,
        };
        this.stripeServicesProvider = new StripeServicesProvider();
        this.displayMessage = new DisplayMessage();
    }

  handleChange = ({error}) => {
      if (error) {
          this.setState({errorMessage: error.message});
      }else{
          this.setState({errorMessage: ''});

      }
  };

  /** ----------------------Para crear tarjeta----- */
  handleSubmit2 = async(evt) => {
      evt.preventDefault();
      const { errorMessage } = this.state;

      if(this.props.stripe){
        
          if(errorMessage.trim() === ''){
              const {paymentsCart, stripe, handleClosePay, getAllPayments, ReloadFields, elements} = this.props;
              const cardElement = elements.getElement('cardNumber');
              const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
              });
              if(error){
                  this.displayMessage.error(`Error al intentar crear el método de pago: ${error}`);

              }else{
                  const paymentMethodId = paymentMethod.id;
              }
              console.log('cardElement: ', paymentMethod)
            
          } 
      }else{
          this.displayMessage.warn(errorMessage);
      }
      
  }
    


  /** --------------------------------------------- */
  handleSubmit = async(evt) => {
      evt.preventDefault();
      const { errorMessage } = this.state;

      if(this.props.stripe){
          
          if(errorMessage.trim() === ''){
              const {paymentsCart, stripe, handleClosePay, getAllPayments, ReloadFields} = this.props;
              this.setState({
                  disabledPay:true
              });
              if (this.props.stripe) {

                  let paymentsCorrect = await 0;
                 
                  await paymentsCart.forEach((pay, index) => {
                      stripe.createToken().then(
                          async({token}) => {
                              let pendingPayments = await [];
                              const element = {'pagCodigo': pay.pagCodigo,'stripeToken':token.id}
                              pendingPayments.push(element);
                  
                 
      
                              await this.stripeServicesProvider.createPay(pendingPayments).then(
                                  
                                  response => {
                                      if(response.error.codigo === '0'){
                                          paymentsCorrect = paymentsCorrect + 1;
                                          if((index + 1) === paymentsCart.length){
                                              if(paymentsCorrect === paymentsCart.length){
                                                  this.displayMessage.success('Pagos procesados correctamente');
                                                  handleClosePay();
                                              }
                                              getAllPayments();
                                              ReloadFields();
                                          }
                                      }
                              
                                  }
                              )
                              this.setState({
                                  disabledPay:false
                              });
                          }
      
                      ).catch(
                          e => {
                              this.displayMessage.error(`Error: ${e}`)
                              this.setState({
                                  disabledPay:false
                              });                          }
                      )
                  })
     
              } 
          }else{
              this.displayMessage.warn(errorMessage);
          }
        
      }else{

        this.displayMessage.warn("Error, stripe no disponible");

      }
      
  }

  
  render() {
      return (
          <form onSubmit={this.handleSubmit.bind(this)} >
              <Grid container
                  justify="center"
                  spacing={2}>
                  <img
                      alt="Logo"
                      src="/images/logos/logo7.png"
                      width={170}
                      height={60}

                  />
              </Grid>
              <br />
              <Grid container spacing={1}>
                  <Grid item xs={12}>
                      <CardNumberElement
                          {...createOptions()}
                          onChange={this.handleChange}
                          placeholder='Número de tarjeta'
                          showIcon={true}
                      />
                  </Grid>
                  <Grid item xs={6}>
                      <CardExpiryElement
                          {...createOptions()}
                          onChange={this.handleChange}

                          placeholder='MM / AA'
                      />
                  </Grid>
                  <Grid item xs={6}>
                      <CardCVCElement 
                          {...createOptions()} 
                          onChange={this.handleChange}

                          placeholder='CVC' />

                  </Grid>
                  <Grid item xs={12}>
                      {this.state.errorMessage.trim() !== '' && 
                      <Alert severity="error">
                          <strong>{this.state.errorMessage}</strong>
                      </Alert>}
                      
                      
                  </Grid>
                  <Grid item xs={12}>
                      <Button className='button-stripe' color='primary' disabled={this.state.disabledPay} fullWidth startIcon={<CreditCardIcon />} type="submit" variant='contained'>Pagar ${parseFloat(this.props.totalPay).toFixed(2)}</Button>
                  </Grid>
                  <Grid item xs={12}>
                      
                  </Grid>
              </Grid>
              <br/>
              <Grid container
                  justify="center"
                  spacing={2}>
                  <LockOutlinedIcon fontSize='small' /> <Typography gutterBottom variant="body1">{' Pago 100% seguro'}</Typography>

              </Grid>
              
          </form>
      );
  }
}

const stripePublickey = 'pk_test_51HRpHhJSiwKtwzlBS3XsYRduyafbdGcan5M121FEtP0QhMcJBbOxH0iPweNJFRpU7Qq6liqYT53KorBfCvT0Jevl00xIsyLKyM';
const SplitFieldsForm = injectStripe(_SplitFieldsForm);

export default class PayDialog extends Component {

    handleClosePay = () => { 
        const { handleClose } = this.props;
        handleClose(); 
    }

    render() {
        const { open } = this.props
        return (
            <Dialog
                fullWidth        
                maxWidth={'xs'}
                open={open}
                scroll={'body'}
                style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
            >
                <Card style={{backgroundColor: '#FAF9F9'}}>
                    <CardHeader 
                        action={<IconButton onClick={this.handleClosePay}
                            size='small'><CloseIcon/></IconButton>}
                        title = {<Typography  gutterBottom variant="h4"><b className='stripe-title'>Pagar</b></Typography>}
                    />
                    <Divider /> 
                    <CardContent>
                        < StripeScriptLoader 
                            loader = " Cargando ... "
                            script = ' https://js.stripe.com/v3/ '
                            uniqueId = ' myUniqueId '
                        >
                            <StripeProvider apiKey={stripePublickey}>
                                <Elements>
                                    <SplitFieldsForm ReloadFields={this.props.ReloadFields} getAllPayments={this.props.getAllPayments} handleClosePay={this.handleClosePay} handleResult={this.props.handleResult} paymentsCart={this.props.paymentsCart} totalPay={this.props.totalPay}/>
                                </Elements>
                            </StripeProvider>
                        </StripeScriptLoader>
                    </CardContent>
                </Card>
                
                
            </Dialog>
      
        );
    }
}