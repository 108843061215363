import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Checkbox,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import  ModalAddObservacion from '../ModalAddObservacion';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    wrapper: {
        textAlign:'right',
        position: 'relative',
        marginRight: 20,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -4,
        marginLeft: -12,
    },
}));

const PrestacionesItems = props => {
    const { data, actionParam, liqCode, session,  className, ...rest } = props;
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();
    const [dataCombo, setDataCombo] = React.useState([]);
    const [eventSelected, setEventSelected] = React.useState(true);
    const prestaciones = new DocumentDigitalizeProvider();
    const [guardando, setGuardando] = React.useState(false);
    const router = useRouter();
    const [openConfirmation ,setOpenConfirmation] = React.useState(false)
    const [disableSave, setDisableSave] = React.useState(false);
    const [openObs, setOpenObs] = React.useState(false);
    const [resetData, setResetData] = React.useState(true);
    const [filaActiva, setFilaActiva] = React.useState(true);
    const [filaActivaObs, setFilaActivaObs] = React.useState(true);
    const [enviarContabilidad, setEnviarContabilidad] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [dataLiq, setDataLiq] = React.useState(null);

    const classes = useStyles();

    useEffect(() => {
        consultaLiquidacion()
    }, [liqCode]);

    const consultaLiquidacion = () => {
        liquidationProvider.LiquidationByLiqCodigo(liqCode).then(
            (response) => {
                if((response.length)!== 0){
                    setDataLiq(response[0]);
                    setLoading(false);
                }else{                    
                    setLoading(false);
                }
            }
        )
    };

    const handleChange = (event, values) => {              
        if(event.target.id){
            var res = event.target.id.split('-');            
            let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            let valorTarifado = data[parseInt(res[1])].detalle[parseInt(res[2])].talCantidad * (values != null ? values.tarAuxiliar : 0);
            let valorRegistrado = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorRegistrado;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = valorTarifado;
            if (valorRegistrado <= valorTarifado) {
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorRegistrado; 
            } else {
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorTarifado; 
            }            
            data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = (values != null ? values.tarCodigo : null); 
            data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = values;
            data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux;
            data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux
            
            data[parseInt(res[1])].detalle[parseInt(res[2])].error = values != null ? false : true; 
            
        }
        
    }
    
    const handleClose = () => {
        setDataCombo([]);
    }

    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const handleBlur = (event) => {
        if (event.target.value === ''){
            var res = event.target.id.split('-');
            let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = 0; 
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 0; 
            data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = null;
            data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = null
            data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux;
            data[parseInt(res[1])].detalle[parseInt(res[2])].error = true;
            data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux
            
            setEventSelected(!eventSelected);
        }
    }
    

    const handleChangeText = (event) => {                
        let search = event.target.value;
        if (search.length >= 3) {
            var res = event.target.id.split('-');
            getTarifarioItems(search, res[3]);
        } else {
            setDataCombo([]);
        }
    }

    const handleChangeCheck = (event, values) => {        
        if(event.target.id){
            var res = event.target.id.split('-');
            data[parseInt(res[1])].detalle[parseInt(res[2])].estado = values;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talEstado = values ? 2 : 3;
            if (!data[parseInt(res[1])].detalle[parseInt(res[2])].talEstado){
                data[parseInt(res[1])].detalle[parseInt(res[2])].error = false
            }

            if (values){
                data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            } else {
                data[parseInt(res[1])].delValorObjetado -= data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data.totalObjetado -= data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 0;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = 0;
                data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = null;
                data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = null;
            }
            setEventSelected(!eventSelected);            
        }
    }   
    
    const handleChangeObs = (fila, valor) => {                
        var res = fila.split('-');
        data[parseInt(res[1])].detalle[parseInt(res[2])].tarObservacion = valor            
        setEventSelected(!eventSelected);
    }
    
    const handleSave= async ( confirmar = false, auditar )=> {
        /*let dataEnviar = [];        
        let banError = false;
        console.log(data);
        data.map((item, index ) => {
            dataEnviar[index]={
                delCodigo: item.delCodigo, 
                delValorObjetado: item.delValorObjetado,
                detalle: []
            };
            item.detalle.map((item1, index1) => {
                if (item1.tarCodigo == null && item1.estado ){
                    data[index].detalle[index1].error = true;
                    banError = true;
                } else {
                    data[index].detalle[index1].error = false;
                }             
                dataEnviar[index].detalle[index1] = { 
                    talCodigo: item1.talCodigo,
                    talEstado: item1.talEstado,
                    talValorObjetado: item1.talValorObjetado,
                    talValorTarifado: item1.talValorTarifado, 
                    tarCodigo: item1.tarCodigo,
                    tarObservacion: item1.tarObservacion || '',
                };
                return [];
            })
            return [];
        });     
                
        if (banError){
            displayMessage.error('Complete la información.');
            setEventSelected(!eventSelected); 
        } else {*/

        if (!confirmar) {
            setOpenConfirmation(true);
            setEnviarContabilidad(auditar);
        } else {
            setDisableSave(true);
            setGuardando(true);
            liquidationProvider.requestMove(liqCode, 1, session.user.usuCodigo).then(response2 => {
                if(response2.error.codigo === '0'){
                    displayMessage.success('Datos actualizados correctamente.');
                    router.history.push('/liquidador/liquidaciones/verificar');
                } else {
                    setGuardando(false); 
                    setDisableSave(false);
                    setOpenConfirmation(false);    
                }
            },() => {          
                displayMessage.error('Error de comunicación.');       
                setGuardando(false); 
                setDisableSave(false);
                setOpenConfirmation(false);
            });
            /* prestaciones.ingresoDetalleLiquidacion(2, dataEnviar).then(response => {            
                if(response.error.codigo === '0'){
                    if (enviarContabilidad) {
                        liquidationProvider.requestMove(liqCode, 1, session.user.usuCodigo).then(response2 => {
                            if(response2.error.codigo === '0'){
                                displayMessage.success('Datos actualizados correctamente.');
                                router.history.push('/liquidador/liquidaciones/verificar');
                            }
                        },() => {          
                            displayMessage.error('Error de comunicación.');       
                            setGuardando(false); 
                            setDisableSave(false);
                            setOpenConfirmation(false);
                        }); 
                    } else {
                        displayMessage.success('Datos actualizados correctamente.');
                        setGuardando(false);
                        setDisableSave(false);
                        setOpenConfirmation(false);
                        router.history.push(`/auditor/auditoria/${btoa(liqCode) }`);
                    }
                } else {
                    setGuardando(false);
                    setDisableSave(false);
                    setOpenConfirmation(false);
                }                  
            },
            () => {          
                displayMessage.error('Error de comunicación.');       
                setGuardando(false); 
                setDisableSave(false);
                setOpenConfirmation(false);
            }); */ 
        }            
        //}        
        
    }

    const getTarifarioItems= ( search, tipo ) => {
        liquidationProvider.tarifarioItems(search, tipo).then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setDataCombo(response.bodyOut.data);
            }              
        },
        () => {          
            displayMessage.error('Error de comunicación.');        
        }); 
    };


    const downloadFile = () => {
        setLoading(true);
       
        liquidationProvider.LiquidationByLiqCodigo(liqCode).then(
            async(response) => {
                if(typeof(response[0].liqReporte)!=='undefined' && response[0].liqReporte!==null){
                    var path=`data:application/octet-stream;base64,${response[0].liqReporte}`;
                    var link = await document.createElement('a'); 
                    await link.setAttribute('href',path);
                    await link.setAttribute('download', 'LIQUIDACION_' + response[0].liqNemonico + '.pdf');
                    await link.setAttribute('target','_blank');
                    await document.body.appendChild(link)
                    await link.click();                
                    await document.body.removeChild(link)
                    setLoading(false);
                }else{
                    displayMessage.error('Ha ocurrido un error, es probable que el archivo no se encuentre en la base.');
                    setLoading(false);
                }
            }
        )
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader title="Prestaciones" />
            <Divider />
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Sel.</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '30%'}}><b>Prestación</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '8%'}}><b>Cant.</b></TableCell>
																	  <TableCell style={{ borderRight: '1px solid #eeeeee', width: '8%'}}><b>Cant. Aut.</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '10%'}}><b>Valor registrado</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '30%'}}><b>Tarifario</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Valor tarifario</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Valor auditado</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Total</b></TableCell>
                                </TableRow>
                            </TableHead>
                            {dataLiq && <TableBody>
                                
                                {data.map((item, index) => (
                                    <>
                                        <TableRow 
                                            key={'det'+item.delCodigo+index} 
                                            style={{ borderTop: '1px solid black'}}
                                        >
                                            <TableCell colSpan={9}>
                                                <b><i>{item.delServicio}</i></b>
                                            </TableCell>
                                            
                                        </TableRow>
                                        {item.detalle.map((item1, index1) => (
                                            <TableRow 
                                                key={'tar'+item.delCodigo+item1.talCodigo} 
                                                style={{    backgroundColor: 'rgba(0, 0, 0, 0.02)'}}
                                            >
                                                <TableCell 
                                                    padding="checkbox" 
                                                    style={{ textAlign: 'center', borderRight: '1px solid #eeeeee'}}
                                                >
                                                    <Checkbox
                                                        disabled
                                                        color="primary"
                                                        defaultChecked
                                                        id={'check-'+index+'-'+index1}
                                                        checked={data[index].detalle[index1].estado}
                                                        onChange={handleChangeCheck}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ borderRight: '1px solid #eeeeee'}}>
                                                    {item1.talDescripcion}
                                                    <br />
                                                    {data[index].detalle[index1].tarObservacion && (

                                                        <Button
                                                            color="primary"
                                                            key={'tar'+item.delCodigo+item1.talCodigo} 
                                                            onClick={() => {setOpenObs(true); setResetData(!resetData); setFilaActivaObs(data[index].detalle[index1].tarObservacion); setFilaActiva('obs-'+index+'-'+index1);}}
                                                            style={{ marginTop: 10}}
                                                            variant="contained"
                                                        >
                                                            Ver observación
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    {item1.talCantidad}
                                                </TableCell>
																							  <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    {item1.talCantidadAutorizada}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    {parseFloat(item1.talValorRegistrado).toFixed(2)}
                                                </TableCell>
                                                <TableCell style={{ borderRight: '1px solid #eeeeee'}}>
                                                    <div style={{ width: '100%' }}>
                                                        {data[index].detalle[index1].dataTarifario ? data[index].detalle[index1].dataTarifario.tarNombre+' '+(data[index].detalle[index1].dataTarifario.tarFormaFarma ? data[index].detalle[index1].dataTarifario.tarFormaFarma : '')+' '+(data[index].detalle[index1].dataTarifario.tarConcentracion ? data[index].detalle[index1].dataTarifario.tarConcentracion : '') : ''}
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    { parseFloat(data[index].detalle[index1].talValorTarifado).toFixed(2) }
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    { parseFloat(data[index].detalle[index1].talValorObjetado).toFixed(2) }
                                                </TableCell>
                                                {(index1 === 0) && 
                                                    <TableCell 
                                                        rowSpan={item.detalle.length} 
                                                        style={{textAlign: 'right'}}
                                                    >
                                                        {parseFloat(data[index].delValorObjetado).toFixed(2)}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                                <TableRow 
                                    key={'totalRegistrado'} 
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total registrado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(data.totalRegistrado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total objetado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(data.totalRegistrado - data.totalObjetado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow> 
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total auditado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(data.totalObjetado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total deducible</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(dataLiq.liqDeducible).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total copago</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(dataLiq.liqCopago).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total a pagar</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(dataLiq.liqTotalLiquidado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>}
                        </Table>
                    </div>
                </PerfectScrollbar>
                <br />  
                <div className={classes.wrapper}>                    
                    <Button
                        className={classes.submitButton}
                        color="primary"
                        onClick={() => {
                            downloadFile();
                        }}
                        variant="contained"
                    >
                        Descargar Liquidación
                    </Button>   
                    &nbsp;&nbsp;
                    {actionParam !== 'pagadas' && 
                        <Button
                            className={classes.submitButton}
                            color="primary"
                            onClick={() => {handleSave(false, true)}}
                            variant="contained"
                        >
                            {actionParam === 'verificacion' ? 'enviar a contabilidad' : 'pagar' }
                        </Button> 
                    }
                    {guardando && 
                        <CircularProgress 
                            className={classes.buttonProgress} 
                            size={24} 
                        />
                    }
                </div>
                <Dialog
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="alert-dialog-title"
                    onClose={handleCloseConfirmation}
                    open={openConfirmation}
                >
                    <DialogTitle id="alert-dialog-title">{'Enviar'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <b>{actionParam === 'verificacion' ? '¿Está seguro de enviar la liquidación a contabilidad?' : '¿Está seguro de pagar la liquidación?' }</b>    
                            <br />
                            Por favor validar que la información se encuentre correcta.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            color="primary"
                            onClick={handleCloseConfirmation}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            autoFocus
                            color="primary"
                            disabled={disableSave}
                            onClick={()=>handleSave(true, true)}
                        >
                            Sí
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
            <ModalAddObservacion
                filaActiva={filaActiva}
                filaActivaObs={filaActivaObs}
                onClose={(tarObservacion, filaActiva) => {setOpenObs(false); setResetData(!resetData); handleChangeObs(filaActiva, tarObservacion)}}
                open={openObs}     
                resetData={resetData}               
            />
        </Card>
    );
};

PrestacionesItems.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired
};

export default PrestacionesItems;
