/* eslint no-eval: 0 */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { EmpresaProvider } from 'providers/empresa.service';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { PlanProvider } from 'providers/plan.service';
import { PlanBrokerProvider } from 'providers/planBroker.service';
import { QueriesProvider } from 'providers/queries.service';
import Checkbox from '@material-ui/core/Checkbox';
import AddBoxIcon from '@material-ui/icons/AddBox';

import Grid from '@material-ui/core/Grid';
import { ModalSearch } from 'components';
import { GenericHeader } from 'components';
import { CardContent, Card } from '@material-ui/core';
import { PolizaProvider } from 'providers/poliza.service';
import { validateOnlyNumbers, validateOnlyLetters, validateWithoutEspecialCharacters } from './../../../../services/validation';
import { DisplayMessage } from 'notifications/displayMessage';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PersonIcon from '@material-ui/icons/Person';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import validateCedula from 'utils/validateCedula';
import { KeyboardReturn } from '@material-ui/icons';

const styles = {
    wrapper: {
        margin: '1.5rem',
        position: 'relative',
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}

export default class FormCreatePolicy extends Component {


    constructor(props) {
        super(props);
        this.state = {
            allCompanies: [],
            allModalities: [],
            allPlans: [],
            allPeriodicities: [],
            allMethodPayment: [],
            allTypeAccounts: [],
            allSucursalsIfi: [],
            allIFI: [],
            allPromos: [],
            allTypeCI: [],
            tipoPlan: [],
            estado: 40,
            person: null,
            openPerson: false,
            openSearch: false,
            affiliate: '',
            company: null,
            detIFI: null,
            detSucursalIFI: null,
            typePlan: null,
            plan: null,
            modality: null,
            broker: '',
            promo: null,
            periodicity: null,
            accountOwner: '',
            methodPayment: null,
            typeAccount: 0,
            accountOwnerDNI: '',
            accountNumber: '',
            disabledSave: false,
            typeIdentification: 0,
            errors: {
                estado: false,
                person: false,
                affiliate: false,
                company: false,
                detIFI: false,
                detSucursalIFI: false,
                typePlan: false,
                plan: false,
                modality: false,
                broker: false,
                promo: false,
                periodicity: false,
                accountOwner: false,
                methodPayment: false,
                typeAccount: false,
                accountOwnerDNI: false,
                accountNumber: false,
                typeIdentification: false,
            },
            messages: {
                estado: '',
                person: '',
                affiliate: '',
                company: '',
                detIFI: '',
                detSucursalIFI: '',
                typePlan: '',
                plan: '',
                modality: '',
                broker: '',
                promo: '',
                periodicity: '',
                accountOwner: '',
                methodPayment: '',
                typeAccount: '',
                accountOwnerDNI: '',
                accountNumber: '',
                typeIdentification: '',
            }

        }

        this.empresaProvider = new EmpresaProvider();
        this.detalleCatalogoProvider = new DetalleCatalogoProvider();
        this.planProvider = new PlanProvider();
        this.planBrokerProvider = new PlanBrokerProvider();
        this.polizaProvider = new PolizaProvider();
        this.setPerson = this.setPerson.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.displayMessage = new DisplayMessage();
        this.queriesProvider = new QueriesProvider();
        
    }

    
    

    componentDidMount = () => {
        
        this.getAllCompanies();
        this.getAllDetCatalogo('IDE', 'allTypeCI')/** Tipo de Identificacion*/
        this.getAllDetCatalogo('TIPM', 'allModalities')/** SETEAR allMODALIDADES */
        this.getAllDetCatalogo('PERIO', 'allPeriodicities')/** SETEAR allPERIOCIDAD */
        this.getAllDetCatalogo('FORPAGP', 'allMethodPayment')/** SETEAR metodos de pago */
        this.getAllDetCatalogo('SUCUR', 'allSucursalsIfi')/** SETEAR sucursal ifi */
        this.getAllDetCatalogo('IFI', 'allIFI')/** SETEAR allIFI */
        this.getAllDetCatalogo('TIPCT', 'allTypeAccounts')/** SETEAR allIFI */
        
    }

    /** ----------------------------------------Traer todas las empresas (cambiado query, empresas en plan broker) */
    getAllCompanies = () => {
        this.queriesProvider.findCompaniesPlanBroker().then(
            response => {
                this.setState({
                    allCompanies: response.bodyOut.data
                });
            }
        );

    }


    /** ----------------------------------------Traer todos los Planes */
    getAllPlanes = async (nombre) => {
        await this.changeOneState('allPlans', []);
        await this.planBrokerProvider.findAllPlanBrokerByPlaNombre(nombre).then(
            response => {
                this.setState({
                    allPlans: response
                })
            }
        );

    }

    /** ------------------------------------Categorias */
    getCategoryPlans = (empCodigo) => {
        this.planProvider.findCategories(empCodigo).then(
            response => {

                this.changeOneState('tipoPlan', response ? response : []);
            }
        );
    }

    /** ----------------------------------------Traer todas lOS Detalle Catalogo por catCodigo*/
    getAllDetCatalogo = (catNemonico, nameData, detDestino = '') => {
        this.detalleCatalogoProvider.findDetalleCatalogoByNemonico(catNemonico, detDestino).then(
            response => {
                this.setState({
                    [nameData]: response
                });
            }
        );
    }

    /** ----------------------------------------Abrir y cerrar buscador de personas creado por Anderson*/
    handleOpenSearch = () => {
        this.setState({ openSearch: true })
    }

    handleCloseSearch = () => {
        this.setState({
            openSearch: false
        })
    }
    /** ----------------------------------------Persona Encontrada */
    setPerson = (dato1) => {
        this.setState({
            person: dato1,
            affiliate: `${dato1.perNombres} ${dato1.perApellidos}`,
        });
      
    }
    /** -----------------------------------------cambio en campo de texto */
    handleInputChange = (event,) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'accountNumber':
                if (validateOnlyNumbers(value)) {
                    this.changeOneState(name, value);
                }
                break;
            case 'accountOwnerDNI':
                if (validateWithoutEspecialCharacters(value)) {
                    this.changeOneState(name, value);
                }
                break;
            case 'accountOwner':
                if (validateOnlyLetters(value)) {
                    this.changeOneState(name, value);
                }
                break;
            case 'broker':
                if (validateOnlyLetters(value)) {
                    this.changeOneState(name, value);
                }
                break;

            default:
                this.changeOneState(name, value);
                break;
        }
    }


    /** -------------------------------------------------------Notificar Todosp */
    showEmpties = () => {
        const { company,
            typeAccount,
            detIFI, detSucursalIFI, person, plan, modality, broker, periodicity,
            accountOwner,
            methodPayment,
        } = this.state;
        this.notifyEmptyNulls('company', company);
        this.notifyEmptyNulls('typeAccount', typeAccount);
        this.notifyEmptyNulls('plan', plan);
        this.notifyEmptyNulls('broker', broker);
        this.notifyEmptyNulls('modality', modality);
        this.notifyEmptyNulls('periodicity', periodicity);
        this.notifyEmptyNulls('detSucursalIFI', detSucursalIFI);
        this.notifyEmptyNulls('methodPayment', methodPayment);
        this.notifyEmptyNulls('person', person);
        this.notifyEmptyNulls('detIFI', detIFI);
        //this.notifyEmptyNulls('promo',promo);
        this.notifyEmptyNulls('accountOwner', accountOwner);
        //this.notifyEmptyNulls('accountOwnerDNI',accountOwnerDNI);
        //this.notifyEmptyNulls('accountNumber',accountNumber);

    }
    /**  ------------------------------------------------------NOtificar NUlos*/

    notifyEmptyNulls = (nameElement, value) => {
        if (typeof (value) === 'object') {
            if (value === null) {
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            } else {
                this.setErrorsMessages(nameElement, false, '');
            }

        } else if (typeof (value) === 'string') {
            if (value.trim() === '') {
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            } else {
                this.setErrorsMessages(nameElement, false, '');

            }
        } else if (Number.isInteger(value)) {
            if (value === 0) {
                this.setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            } else {
                this.setErrorsMessages(nameElement, false, '');
            }
        }
    }

    setErrorsMessages = (nameElement, stateElement, message) => {

        const errors = this.state.errors;

        const messages = this.state.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`);

        this.setState({
            errors: errors,
            messages: messages
        });
    }

    /**  ------------------------------------------------------Limpiar Todo */
    cleanAll = () => {
        this.setState({
            estado: 40,
            person: null,
            openPerson: false,
            openSearch: false,
            affiliate: '',
            company: null,
            detIFI: null,
            detSucursalIFI: null,
            typePlan: null,
            plan: null,
            modality: null,
            broker: '',
            promo: null,
            periodicity: null,
            accountOwner: '',
            methodPayment: null,
            typeAccount: 0,
            accountOwnerDNI: '',
            accountNumber: '',
            typeIdentification: 0,

        })
    }
    /** --------------------------------------- onChange para Autocompletes */
    onChangePlan = (event, values) => { this.changeOneState('plan', values); }
    onChangeModality = (event, values) => { this.changeOneState('modality', values); }
    onChangePeriodicity = (event, values) => { this.changeOneState('periodicity', values); }
    onChangeIfi = (event, values) => { this.changeOneState('detIFI', values); }
    onChangeSucursalIfi = (event, values) => { this.changeOneState('detSucursalIFI', values); }
    onChangeMethodPayment = (event, values) => { this.changeOneState('methodPayment', values); }
    onChangeCompany = (event, values) => {
        if (values !== null) {
            this.changeOneState('company', values);
            this.getCategoryPlans(values.empCodigo);
            this.changeOneState('promo', null);
            this.getAllDetCatalogo('PROMO', 'allPromos', values.empCodigo);

        }
    }
    onChangePromo = (event, values) => { this.changeOneState('promo', values); }
    onChangeTypePlan = (event, values) => {
        if (values !== null) {
            this.changeOneState('typePlan', values);
            this.getAllPlanes(values.plaNombre);
        }
    }

    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState, value) => {
        this.setState({
            [nameState]: value
        })
    }
    
    /* checkCuentaBnacaria = ()=>{
        this.polizaProvider.validateBankAccount(this.state.person.perCodigo).then(
            data=>{
                
                if(data.error.codigo==='0'){
                    
                    if(data.bodyOut.data[0].existencias===0){
                        return false
                    }else{
                        return true
                    }
                }else{
                    this.displayMessage.error(`Error: ${data.error.mensaje}`)
                }
                
            }
        ) 
    } */

    /** --------------------------------------- Crear Poliza */
    createPoliza = async () => {
        await this.changeOneState('disabledSave', true);
        const { company, typeAccount, detIFI, detSucursalIFI, person, plan, modality, broker, promo, periodicity, accountOwner, methodPayment, accountOwnerDNI, accountNumber, typeIdentification } = this.state;
        if (typeIdentification === 3 || typeIdentification === 4) {
            const dni = typeIdentification === 3 ? accountOwnerDNI : accountOwnerDNI.trim().substr(0, 10);
            if (!validateCedula(dni)) {
                this.changeOneState('disabledSave', false);
                this.setErrorsMessages('accountOwnerDNI', true, 'Cédula o RUC incorrecto.');
                return false;
            } else {
                this.setErrorsMessages('accountOwnerDNI', false, '');
            }
        }
        if (company !== null && plan !== null && broker.trim() !== '' && modality !== null && periodicity !== null && detSucursalIFI !== null && methodPayment !== null && person !== null && detIFI !== null && accountOwner.trim() !== '' && typeAccount !== 0 && typeAccount !== 0) {
            const trama = {
                polBroker: broker,
                detModalidad: modality.detCodigo,
                detPeriodicidad: periodicity.detCodigo,
                detSucursalIfi: detSucursalIFI.detCodigo,
                plaCodigo: plan.plaCodigo,
                detFormaPago: methodPayment.detCodigo,
                empCodigo: company.empCodigo,
                perCedulaTitular: person.perIdentificacion,
                detTipoIdentificacion: person.detTipoIdentificacion,
                detIfi: detIFI.detCodigo,
                detTipoCuenta: typeAccount,
                polDebito: accountOwner,
                detPromocion: promo ? promo.detCodigo : -1,
                cedulaDebito: accountOwnerDNI,
                cuentaDebito: accountNumber,
            }
            const respuesta=await this.polizaProvider.validateBankAccount(this.state.person.perCodigo)
           
            if(respuesta.bodyOut.data[0].existencias>0){
                
                this.polizaProvider.createPolizas([trama]).then(
                    response => {
                        if (response.error.codigo === '0') {
                            ///window.location.reload();
                            this.displayMessage.success('Póliza creada con exito');
                            window.location.reload()
                            //this.cleanAll();
    
                        } else {
                            this.displayMessage.error(`Error: ${response.error.mensaje}`)
    
                        }
                        this.changeOneState('disabledSave', false);
    
                    }
                ).catch(
                    () => {
                        this.changeOneState('disabledSave', false);
                    }
                )
            }else{
                this.displayMessage.error('Agrege una cuenta bancaria al afiliado');
                this.changeOneState('disabledSave', false);
            }
            
            
        } else {
            this.changeOneState('disabledSave', false);

            this.displayMessage.warn('Ingrese todos los campos');
            this.showEmpties();
        }

        //return trama;
    }

    /** --------------------------------------------------Validaciones */

    render() {
        return (
            <div>
                <ModalSearch
                    close={this.handleCloseSearch}
                    datos={this.setPerson}
                    open={this.state.openSearch}
                    validatePoliza={true}

                />
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        md={4}
                        xs={12}
                    >
                        <GenericHeader title='Crear póliza' /><br />
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                    ></Grid>
                    <Grid
                        item
                        md={2}
                        xs={12}
                    >
                        <Button
                            component={RouterLink}
                            to={`/administrador/poliza`}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            <KeyboardReturn />  Regresar
                        </Button>
                    </Grid>
                </Grid>


                <Card>

                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Alert severity="warning">
                                    <Typography
                                        component="h6"
                                    >
                                        <b>* Campos obligatorios.</b>
                                    </Typography>
                                </Alert>
                            </Grid>
                        </Grid><br />
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Autocomplete
                                    id="company"
                                    options={this.state.allCompanies}
                                    fullWidth
                                    getOptionLabel={(option) => option.empNombreComercial}
                                    onChange={this.onChangeCompany}
                                    renderInput={(params) => <TextField error={this.state.errors.company} helperText={this.state.messages.company} {...params} label="Empresa" variant="outlined" required />}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField value={this.state.broker} error={this.state.errors.broker} helperText={this.state.messages.broker} name='broker' label="Asesor / Broker" variant="outlined" onChange={this.handleInputChange} required fullWidth />
                            </Grid>
                        </Grid>                        
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Autocomplete
                                    id="Tipo de Plan"
                                    options={this.state.tipoPlan}
                                    fullWidth
                                    getOptionLabel={(option) => option.plaNombre}
                                    onChange={this.onChangeTypePlan}

                                    renderInput={(params) => <TextField {...params} error={this.state.errors.typePlan} helperText={this.state.messages.typePlan} label="Tipo de plan" variant="outlined" required />}
                                />
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="plan"
                                    options={this.state.allPlans}
                                    fullWidth
                                    getOptionLabel={(option) => option.plaProducto}
                                    onChange={this.onChangePlan}

                                    renderInput={(params) => <TextField {...params} error={this.state.errors.plan} helperText={this.state.messages.plan} label="Plan" variant="outlined" required />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Autocomplete
                                    id="modality"
                                    options={this.state.allModalities}
                                    fullWidth
                                    getOptionLabel={(option) => option.detNombre}
                                    onChange={this.onChangeModality}
                                    renderInput={(params) => <TextField {...params} error={this.state.errors.modality} helperText={this.state.messages.modality} label="Modalidad" variant="outlined" required />}
                                />
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="promo"
                                    options={this.state.allPromos}
                                    fullWidth
                                    onChange={this.onChangePromo}
                                    getOptionLabel={(option) => option.detNombre}
                                    renderInput={(params) => <TextField {...params} error={this.state.errors.promo} helperText={this.state.messages.promo} label="Promoción" variant="outlined" />}
                                />
                            </Grid>
                        </Grid>


                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button variant="contained" color="primary" onClick={this.handleOpenSearch} startIcon={<PersonIcon />} fullWidth>
                                    {this.state.person ?
                                        'Cambiar Afiliado'
                                        : 'Seleccionar Afiliado *'
                                    }
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <p className={this.state.messages.person.trim() === '' ? '' : 'red-text'}>
                                    {this.state.person ?
                                        `${this.state.affiliate}`
                                        : 'Seleccione un afiliado'
                                    }
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>


                            <Grid item xs>
                                <Autocomplete
                                    id="periodicity"
                                    options={this.state.allPeriodicities}
                                    fullWidth
                                    getOptionLabel={(option) => option.detNombre}
                                    onChange={this.onChangePeriodicity}
                                    renderInput={(params) => <TextField {...params} error={this.state.errors.periodicity} helperText={this.state.messages.periodicity} label="Periodicidad" variant="outlined" required />}
                                />
                            </Grid>

                            <Grid item xs>
                                <Autocomplete
                                    id="methodPayment"
                                    options={this.state.allMethodPayment}
                                    fullWidth
                                    getOptionLabel={(option) => option.detNombre}
                                    onChange={this.onChangeMethodPayment}
                                    renderInput={(params) => <TextField {...params} error={this.state.errors.methodPayment} helperText={this.state.messages.methodPayment} label="Forma de pago" variant="outlined" required />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Autocomplete
                                    id="detIfi"
                                    options={this.state.allIFI}
                                    fullWidth
                                    onChange={this.onChangeIfi}
                                    getOptionLabel={(option) => option.detNombre}
                                    renderInput={(params) => <TextField error={this.state.errors.detIFI} helperText={this.state.messages.detIFI} {...params} label="Institución financiera" variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="detSucursalIFI"
                                    options={this.state.allSucursalsIfi}
                                    fullWidth
                                    getOptionLabel={(option) => option.detNombre}
                                    onChange={this.onChangeSucursalIfi}

                                    renderInput={(params) => <TextField {...params} error={this.state.errors.detSucursalIFI} helperText={this.state.messages.detSucursalIFI} label="Sucursal institución financiera" variant="outlined" required />}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <FormControl fullWidth variant='outlined' error={this.state.errors.typeAccount} >
                                    <InputLabel id="typeAccountLabel"  >Tipo de cuenta</InputLabel>
                                    <Select
                                        id='typeAccount'
                                        label='Tipo de cuenta'
                                        labelId="typeAccountLabel"
                                        name='typeAccount'
                                        onChange={this.handleInputChange}
                                        value={this.state.typeAccount}
                                        required
                                    >
                                        <MenuItem value={0}>Seleccione</MenuItem>

                                        {
                                            this.state.allTypeAccounts && this.state.allTypeAccounts.map(
                                                (typeAccount) => <MenuItem key={typeAccount.detCodigo} value={typeAccount.detCodigo}>{typeAccount.detNombre}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs>
                                <TextField value={this.state.accountNumber} name='accountNumber' label="Número de cuenta" variant="outlined" onChange={this.handleInputChange} error={this.state.errors.accountNumber} helperText={this.state.messages.accountNumber} fullWidth />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <FormControl error={this.state.errors.typeIdentification} fullWidth variant='outlined' >
                                    <InputLabel id="typeIdentificationLabel" >Tipo de identificación del propietario de la cuenta</InputLabel>
                                    <Select
                                        id='typeIdentification'
                                        label='Tipo de identificación del propietario de la cuenta'
                                        labelId="typeIdentificationLabel"
                                        name='typeIdentification'
                                        onChange={this.handleInputChange}
                                        value={this.state.typeIdentification}
                                    >
                                        <MenuItem value={0}>Seleccione</MenuItem>

                                        {
                                            this.state.allTypeCI && this.state.allTypeCI.map(
                                                typeCI => <MenuItem key={typeCI.detCodigo} value={typeCI.detCodigo}>{typeCI.detNombre}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <TextField value={this.state.accountOwnerDNI} name='accountOwnerDNI' label="Identificación del propietario de la cuenta" onChange={this.handleInputChange} variant="outlined" error={this.state.errors.accountOwnerDNI} helperText={this.state.messages.accountOwnerDNI} fullWidth />
                            </Grid>

                        </Grid>


                        <Grid container spacing={3}>

                            <Grid item xs>
                                <TextField value={this.state.accountOwner} name='accountOwner' label="Propietario de la cuenta" error={this.state.errors.accountOwner} helperText={this.state.messages.accountOwner} onChange={this.handleInputChange} variant="outlined" required fullWidth />
                            </Grid>

                        </Grid>
                        
                    </CardContent>
                    <div style={styles.wrapper}>
                        <Button variant="contained" color="primary" onClick={this.createPoliza} startIcon={<DoneIcon />} disabled={this.state.disabledSave} fullWidth>
                            Crear Póliza
                        </Button>
                        {this.state.disabledSave && <CircularProgress size={24} style={styles.buttonProgress} />}
                    </div>
                </Card>

            </div>

        )
    }
}