import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    header: {
        paddingLeft: theme.spacing(2)

    }
}));

const Queries = () => {
    const classes = useStyles();


    return (
        <Page
            className={classes.root}
            title="Consultas"
        >
            <Header className={classes.header} />

            <Results
                className={classes.results}
            />

        </Page>
    );
};

export default Queries;