import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';

export class Uploadfiles {
    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()
        this.person = null
    }
    
    testquemado(files,detTipo) {
        let filesNames = '';
        let filesDate = '';
        let pagCodigo='';

        files.map(file => {
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            filesDate= file.lastModifiedDate;
            pagCodigo=file.pagCodigo;
            return 0;
        });


        let body = {
            tipoAccion: 1,
            entidad: 'Documento',
            entidades: [
                {
                    docNombre: filesNames,
                    docFecha: filesDate,
                    detTipo: detTipo,
                    docEstado:1
                }
            ]
        };

        let endpoint = '/multipart/documento';
        return this.dataService.postMultipart(endpoint, body, '0101119', files);
    }

    testquemadoCPN(files,detTipo) {
        let filesNames = '';
        let filesDate = '';
        let pagCodigo='';

        files.map(file => {
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            filesDate= file.lastModifiedDate;
            pagCodigo=file.pagCodigo;
            return 0;
        });


        let body = {
            tipoAccion: 1,
            entidad: 'Documento',
            entidades: [
                {
                    docNombre: filesNames,
                    docFecha: filesDate,
                    detTipo: detTipo,
                    docEstado:1
                }
            ]
        };

        let endpoint = '/multipart/documento';
        return this.dataService.postMultipart(endpoint, body, '0101118', files);
    }


    findDetNemonico = ( dettNemonico ) => {        
        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'detNemonico': dettNemonico,
                'detEstado': 1,            
            }
        }    
        return this.dataService.post('/consulta', body, '0101000');        
    }
    

}
