import React from 'react';
import { Results } from './components';
import { Page, GenericHeader } from 'components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const PlanView = () => {
    const classes = useStyles();

    return (  
        <Page 
            className={classes.root}
            title = 'Planes'>
            <GenericHeader title={'Planes'} />
            <Results />
        </Page>
    );
}

export default PlanView;