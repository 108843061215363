/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    TextField,
    Button,
    colors,
    FormControl,
    InputLabel,
    Select,
    FormHelperText
} from '@material-ui/core';
import NumberFormatPhone from 'components/NumberFormatPhone';
import NumberFormatRuc from 'components/NumberFormatRuc';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { LiquidationProvider } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    formControl: {        
        width: '100%',
    },
    container: {
        marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const CustomerEdit = props => {
    const { open, onClose, className, ...rest } = props;

    const classes = useStyles();

    const [formState, setFormState] = useState({ });

    const prestaciones = new DocumentDigitalizeProvider();
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();
    const [ruc, setRuc] = useState('');
    const [telefono1,setTelefono1] = useState('');    
    const [ciudad,setCiudad] = useState ('')
    const [rucError, setRucError] = useState(false);
    const [nombreError, setNombreError] = useState(false);
    const [direccionError, setDireccionError] = useState(false);
    const [telefonoError, setTelefonoError] = useState(false);
    const [ciudadError, setCiudadError] = useState(false);
    const [dataCombo, setDataCombo] = useState([]);
    const [dataComboPrestador, setDataComboPrestador] = useState(null);
    const [tipoPrestador, setTipoPrestador] = useState(''); 
    const [tipoPrestadorError, setTipoPrestadorError] = useState(false);

    useEffect(() => {
        getTipoPrestador();
    }, []);

    const getTipoPrestador = () => {
        liquidationProvider.findDetalleCatalogo('TIPPRES').then(response => {
            if(response.error.codigo === '0') {
                if(props.detTipoLiq === 'TIPLQRE') {
                    setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico === 'TEXTER'));
                } else {
                    setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico !== 'TEXTER'));
                }                
            }
        },
        () => {
            displayMessage.error('Error de conexión')
        })
    }

    if (!open) {
        return null;
    }

    const getCiudades = search => {
        prestaciones.datosCiudadesBusqueda(search)
            .then(
                response => {
                    if(response.error.codigo === '0' && response.bodyOut.data.length){
                        setDataCombo(response.bodyOut.data);                        
                    }                    
                },
                () => {
                    displayMessage.error('Datos no encontrados')
                }
            )
    }
   
    const handleFieldChange = event => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.name]: event.target.value
        }));
        setNombreError(false)
        setDireccionError(false)
        setCiudadError(false)
    };

    const handleRucChange = (e) => {
        setRuc(e.target.value)
        setRucError(false)
    }

    const handlePhone1Change = (e) => {
        setTelefono1(e.target.value)
        setTelefonoError(false)
    }

    /* const handleCiudadChange = (e) => {
        setCiudad(e.target.value)
        setCiudadError(false)
    } */

    const handleChangeText = (event) => {                
        let search = event.target.value;
        if (search.length >= 3) {            
            getCiudades(search);
        } else {            
            setDataCombo([]);
        }
    }

    const handleClose = () => {
        setDataCombo([]);
    }

    const handleChangeTipoPrestador = (event) => {
        setTipoPrestador(event.target.value);
        if (event.target.value === '') {
            setTipoPrestadorError(true);
        }
    }

    const handleBlur = (event) => {
        if (event.target.value === ''){
            setCiudad('');
        }
    }

    const handleChange = (event, values) => {      
        if(values){
            setCiudad(values.locCodigo);
        } else {
            setCiudad('');
        }
        
    }
            

    const handleSaveLender = () => {
        if(tipoPrestador === '' || ciudad === '' || ruc === '' || formState.nombres === undefined || formState.nombres === '' || formState.direcciones === undefined ||
        formState.direcciones === '' || telefono1 === ''){
            if(tipoPrestador === ''){
                setTipoPrestadorError(true);
            }

            if(ruc === '' || ruc.length < 13){
                setRucError(true)
            }
            if(formState.nombres === undefined || formState.nombres === ''){
                setNombreError(true)
            }
            if(telefono1 === '' || telefono1.length < 10){
                setTelefonoError(true)
            }
            if(formState.direcciones === undefined || formState.direcciones === ''){
                setDireccionError(true)
            }
            if(ciudad === ''){
                setCiudadError(true)
            }
            displayMessage.error('Complete los campos')
        }else{
            const tramaPrestador = [{
                detTipo:tipoPrestador,
                locCodigo:ciudad,
                preRuc:ruc,
                preNombre:formState.nombres,
                preDireccion:formState.direcciones,
                preDinamico: JSON.stringify([{telefono1:telefono1, telefono2: formState.telefono22}])
            }]
            prestaciones.ingresoPrestador(tramaPrestador)
                .then(
                    data => {
                        if(data.error.codigo === '0') {
                            displayMessage.success('Prestador registrado.')
                            setRuc('')
                            setTelefono1('')
                            onClose()                        
                        }
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');

                    }

                );
        }
    }

    return (
        <Modal
            onClose={onClose}
            open={open}
        >
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <form>
                    <CardContent>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                        >
              Agregar Prestador
                        </Typography>
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    autoFocus
                                    error={rucError}
                                    fullWidth
                                    helperText={rucError && 'Ingrese Ruc'}
                                    InputProps={{
                                        inputComponent: NumberFormatRuc
                                    }}
                                    label="Ingrese Ruc"
                                    name="ruc"
                                    onChange={handleRucChange}
                                    required
                                    value={ruc}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={nombreError}
                                    fullWidth
                                    helperText={nombreError && 'Ingrese Nombre'}
                                    label="Nombre"
                                    name="nombres"
                                    onChange={handleFieldChange}
                                    required
                                    value={formState.nombre}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <FormControl 
                                    className={classes.formControl} 
                                    error={tipoPrestadorError}
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="outlined-age-native-simple">Tipo de prestador</InputLabel>
                                    <Select
                                        label="Tipo de prestador"
                                        native
                                        onChange={handleChangeTipoPrestador}
                                        value={tipoPrestador}
                                    >
                                        <option 
                                            aria-label="None"
                                            value="" 
                                        />
                                        { dataComboPrestador.map(item => (
                                            <option 
                                                key={item.detCodigo}
                                                value={item.detCodigo}
                                            >
                                                {item.detNombre}
                                            </option>
                                        ))
                                            
                                        }
                                        
                                    </Select>
                                    {tipoPrestadorError && <FormHelperText>Seleccione tipo de prestador</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    error={direccionError}
                                    fullWidth
                                    helperText={direccionError && 'Ingrese Dirección'}
                                    label="Direccion"
                                    name="direcciones"
                                    onChange={handleFieldChange}
                                    required
                                    value={formState.direccion}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Autocomplete
                                    getOptionLabel={(option) => option.locNombre}
                                    noOptionsText=""
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onClose={handleClose}
                                    options={dataCombo}                                                                        
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params} 
                                            error={ciudadError}
                                            helperText={ciudadError ? 'Seleccione ciudad' : ''}                                            
                                            label="Ciudad" 
                                            margin="normal"  
                                            onChange={handleChangeText}
                                            placeholder="Buscar..."
                                            style={{ marginTop: '0px' }}
                                            variant="outlined"
                                        />
                                    )}                                                            
                                />
                                {/* <TextField
                                    fullWidth
                                    error={ciudadError}
                                    helperText={ciudadError && 'Seleccione ciudad'}
                                    label="Seleccione ciudad"
                                    name="ciudades"
                                    onChange={handleCiudadChange}
                                    required
                                    select
                                    // eslint-disable-next-line react/jsx-sort-props
                                    SelectProps={{ native: true }}
                                    value={ciudad}
                                    variant="outlined"
                                >
                                    <option value=""> </option>
                                    {ciudades.map(state => (
                                        <option
                                            key={state.detCodigo}
                                            value={state.detCodigo}
                                        >
                                            {state.detNombre}
                                        </option>
                                    ))}
                                </TextField> */}
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    error={telefonoError}
                                    helperText={telefonoError && 'Ingrese télefono'}
                                    InputProps={{
                                        inputComponent: NumberFormatPhone
                                    }}
                                    label="Teléfono 1"
                                    name="telefono11"
                                    onChange={handlePhone1Change}
                                    required
                                    value={telefono1}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Teléfono 2"
                                    name="telefono22"
                                    onChange={handleFieldChange}
                                    value={formState.telefono2}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item />
                           
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
              Cerrar
                        </Button>
                        <Button
                            className={classes.saveButton}
                            onClick={handleSaveLender}
                            variant="contained"
                        >
              Guardar
                        </Button>
                    </CardActions>
                </form>
            </Card>
        </Modal>
    );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
    className: PropTypes.string,
    customer: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};

CustomerEdit.defaultProps = {
    open: false,
    onClose: () => {}
};

export default CustomerEdit;
