import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';


export class PolizaProvider {

    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()


        this.person = null
    }
    

    findByPolCodigo = (polCodigo)=>{
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Poliza',
            'parametros': {
                'polCodigo': polCodigo,
               
            }
        }
        return this.dataService.post('/consulta', body, '0101000').then( response => {    
            return response.bodyOut.data;  
        } 
        );
    }



    getDocumentPDF = (polCodigo)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Poliza',
            'parametros': {
                'polCodigo': polCodigo
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
        } 
            
        
        );

        return response;
    }

    getDocumentPDFList = (polCodigo) => {
        let body = {       
            'tipoConsulta': 1,
            'entidad': 'Poliza',
            'parametros': {
                'polCodigo': polCodigo
               
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    allPersons = ()=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwPolPerBan',
            'parametros': {
               
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );

       

        return response;
    }

    findPerson = (identificacion)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perIdentificacion': identificacion
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, null, 'james').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );

        

        return response;
       
    }

    createPolizas = ( Polizas) => {
        
        const body = {
            'tipoAccion': 1,
            'entidad': 'Poliza',
            'entidades': Polizas
        }
        
        const resp = this.dataService.post('/accion',body,'0101112').then(
            response => {
                return response;
            }
        )

        return resp;

    }

    validateBankAccount = (perCodigo)=>{
        
        const body={
            'maximoRegistros':5,
            'tipoConsulta': 2,
            'entidad': 'CuentaBancaria',
            'parametros': {
                "perCodigo":perCodigo,
                "cueDebito":1
            }
        }

        return this.dataService.post('/consulta', body, '0101000').then( response => {

            return response;

        } 
        );
    }

    

    updatePolizas = ( Polizas) => {
        
        const body = {
            'tipoAccion': 2,
            'entidad': 'Poliza',
            'entidades': Polizas
        }
        
        const resp = this.dataService.post('/accion',body,'0101112').then(
            response => {
                return response;
            }
        )
        return resp;

    }


    updatePolicy = ( entities) => {
        
        const body = {
            'tipoAccion': 2,
            'entidad': 'Poliza',
            'entidades': entities
        }
        
        const resp = this.dataService.post('/accion',body,'0101112').then(
            response => {
                return response;
            }
        )

        return resp;

    }
    findPersonPolicyByPolCodigo = (polCodigo) => {
        return this.genericConsult({'polCodigo':polCodigo,'polEstado':1});
    }

    findAllPolizas = () => {
        return this.genericConsult({'detTipoPersona': 21});
    }




    findByPolContrato =(polContrato) => {

        return this.genericConsultMaxReg(10, {'polContrato': `%${polContrato}%`})
    }

    findByPerIdentificacion =(perIdentificacion) => {
        return this.genericConsultMaxReg(10, {'perIdentificacion': `%${perIdentificacion}%`})
    }
    findByPerNombres =(perNombres) => {
        return this.genericConsultMaxReg(10, {'perNombres': `%${perNombres}%`})
    }
    findByPerApellidos =(perApellidos) => {
        return this.genericConsultMaxReg(10, {'perApellidos': `%${perApellidos}%`})
    }

    searchOr = (value) => {
        return this.genericConsult({
            'polContrato': `%${value}%`,
            'perIdentificacion': `||%${value}%`,
            'perNombres': `||%${value}%`,
            'perApellidos': `||%${value}%`

        });
    }

    genericConsult = (parametros)=>{
        
        const body={
            'maximoRegistros':50,
            'tipoConsulta': 1,
            'entidad': 'PersonaPoliza',
            'parametros': parametros
        }

        return this.dataService.post('/consulta', body, '0101000').then( response => {

            return response.bodyOut.data;

        } 
        );
    }

    genericConsultMaxReg = (maximoRegistros,parametros)=>{
        
        const body={
            'maximoRegistros': maximoRegistros,
            'tipoConsulta': 1,
            'entidad': 'PersonaPoliza',
            'parametros': parametros
        }

        return this.dataService.post('/consulta', body, '0101000').then( response => {

            return response.bodyOut.data;

        } 
        );
    }

    


    getPerson(){
        return this.person;
    }

    login(username, password) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: username,
                password: password						
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101001', username);
    }

    getUser(){
        return this.storageService.getSession()
    }

    logout() {
        this.storageService.removeSession();
    }

    isAuthenticated() {
        let user = JSON.parse(localStorage.getItem('user'))        
        return user && user.role
    }

    getPoliza(polCodigo) {
        let body = {     
            tipoConsulta: 1,
            entidad: 'TBPOL',
            parametros: {
                '0':polCodigo,                
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

}
