import React, { useState, useEffect } from 'react';
import { Button, Grid, Chip } from '@material-ui/core';
//import './common.css';
import { PaymentProvider } from 'providers/payment.service';

import MaterialTable from 'material-table';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PayDialog from '../PayDialog';
import { DisplayMessage } from 'notifications/displayMessage';
import { PolizaProvider } from 'providers/poliza.service';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import AddBoxIcon from '@material-ui/icons/AddBox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: orange[400],
        
    },
    redChip: {
        backgroundColor: red[400],

    }
}));


const PayList = ({ session }) => {
    const classes = useStyles();

    const displayMessage = new DisplayMessage;
    const paymentProvider = new PaymentProvider();
    const polizaProvider = new PolizaProvider();

    const [payments,setPayments] = useState([]);
    const [paymentsCart,setPaymentsCart] = useState([]);

    const [totalPay,setTotalPay] = useState(0);
    const [openPayDialog,setOpenPayDialog] = useState(false);
    const [polizaUser,setPolizaUser] = useState(null);

    let recorrer = 0;
    const addToCart = async rowData => {
        const paymentsCartAux = await paymentsCart;
        await paymentsCartAux.push(rowData);
        let totalAux = await totalPay;
        totalAux = await(totalAux + rowData.pagMonto);
        await setTotalPay(totalAux);
        await setPaymentsCart(paymentsCartAux);
    }

    const deleteToCart = async rowData => {
        const paymentsCartAux = await paymentsCart;
        let totalAux = await totalPay;

        const PaymentFinded = await paymentsCartAux.find(payment => payment.pagCodigo === rowData.pagCodigo);
        let i =  await paymentsCartAux.indexOf(PaymentFinded);

        if(i !== -1){
            await paymentsCartAux.splice(i,1);
            totalAux = await(totalAux - rowData.pagMonto);
            await setTotalPay(totalAux);
            await setPaymentsCart(paymentsCartAux);
        }
        
    }

    const handleChange = async(event, rowData) => {
        const isChecked = event.target.checked;

        if(isChecked){
            await addToCart(rowData);
            
        }else{
            await deleteToCart(rowData);
        }
    }

    const getAllPayments = () => {
        paymentProvider.findAllPaymentsByPolCodigoOrder(session.user.polCodigo).then(
            response => {
                setPayments(response);
            }
        )
    }

    const getPolizaByPolCodigo = () => {
        polizaProvider.findByPolCodigo(session.user.polCodigo).then(
            response => {
                setPolizaUser(response[0]);
            }
        )
    }
    
    const columns = [
        { title: 'Fecha de vencimiento', field: 'pagFechaVencimiento' },
        { title: 'Monto', field: 'pagMonto' },
        { title: 'Estado', field: 'detNombre' },
        {
            field: 'pagMonto',
            title: 'Agregar al pago',
            render: rowData => rowData.detNemonico === 'PAG1'  ? 
                polizaUser ? 
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checkedIcon={<AddBoxIcon />}
                                    checked={paymentsCart.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ? false : true}
                                    color="primary"
                                    name={`pago_${rowData.pagCodigo}`}
                                    onChange={(event) => handleChange(event, rowData)}
                                />
                            }
                            label={paymentsCart.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ? 
                                <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Seleccionar</div> : <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Seleccionado</div>}
                        />
                    : <CircularProgress />
                : rowData.detNemonico === 'PAG' && <Chip
                    icon={<DoneIcon />}
                    label="Pagado"
                    color="secondary" 
                    
                />
                                
            
        }
    ];

    const componentDidMount = () => {
        getPolizaByPolCodigo();
        getAllPayments();
    }

    useEffect(componentDidMount, []);
    /** ------------------------------------Para dialogo de pagos ---------- */
    const handleClose = () => {
        setOpenPayDialog(false);
    }

    const handleOpen = () => {
        if(paymentsCart.length > 0){
            setOpenPayDialog(true);
        }else{
            displayMessage.warn('Nada que pagar')
        }
    }
    
    const ReloadFields = () => {
        setPaymentsCart([]);
        setTotalPay(0);
    }

    return (
        <div>
            <PayDialog session={session} open={openPayDialog} handleClose={handleClose} totalPay={totalPay} paymentsCart={paymentsCart} ReloadFields={ReloadFields} getAllPayments={getAllPayments}/>
            <MaterialTable
                
                columns={columns}
                /** 
                 * actions={[
                    {
                        icon: 'create',
                        tooltip: 'Actualizar',
                        onClick: (event, rowData) => this.setOpenPersonUpdateModal(true, rowData)
                    }
                ]}
                components={{
                    Action: props => (
                        props.data.detNemonico === 'PAG1' &&
                        <FormControlLabel
                            control={
                                
                            }
                            label=""
                        />

                    ),
                }}
                */
                

                data={payments}

                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'

                    },
                    toolbar: {
                        nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acción'
                    },
                    body: {

                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter',

                        },

                    }
                }}

                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                        width: 100,
                        textAlign: 'center'
                    },
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    toolbar: false,
                    pageSize: 12,
                    showTitle: false,
                    rowStyle: () => {
                        recorrer = recorrer + 1;
                        return ({
                            backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                        })
                    },
                }}


                title={'Persona'}
            />
            <br/>
            <Grid container
                justify="flex-end"
                spacing={2}>
                <Typography variant="h4" gutterBottom>
                    <b>Total: </b>{`$ ${totalPay}`}
                </Typography>
            </Grid>
            <br/>
            <Grid container
                justify="flex-end"
                spacing={2}>
                <Button color="secondary" startIcon={<CreditCardIcon/>} variant="contained" onClick={handleOpen}>
                Pagar
                </Button>
            </Grid>
        </div>
    );
};


export default PayList;
