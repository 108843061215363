import React, {Component} from 'react';
import { FilesDropzone, Page } from 'components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {GenericHeader} from 'components';


import { makeStyles } from '@material-ui/core/styles';


export default class GenericUploadForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            errorTipo: false,
            errorArchivos: false,
            files: []
        }
    }
    handleClickOpen = (files) => {        
    
        let error = false;
    
        this.setState({
          errorTipo: false,
          errorArchivos: false
        });
    
        if (!this.state.form){
          this.setState({
            errorTipo: true
          });
          error = true;
        }
    
        if(!files.length){
          this.setState({
            errorArchivos: true
          });
          error = true;
        }
    
        if (error){
          return;
        }    
    
        this.setState({
          open:true,
          files: files
        });
      };
      
    handleClickOpen = () => {
        this.setState({
            open:true
        })
      };
    
       handleClose = () => {
            this.setState({
                open:false
            })
        };

        handleChange = ()=>{}

        useStyles = makeStyles(theme => ({
          margin: {
            margin: theme.spacing(1),
          },
        }));
    render(){
          
        const { title } = this.props;
        return(
            <Page 
            title={ title }
            className ='container-90'
             >

            <div className='container-90'>
            <GenericHeader title={ title } />
                
                <FilesDropzone
                  callback={this.handleClickOpen.bind(this)}
                ></FilesDropzone>

               <div className='right'>




               <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Enviar"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Archivo consolidado numero de registros pagados 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        Si, Enviar
                    </Button>
                    </DialogActions>
                </Dialog>



                
               </div>
             
            </div>

             




            




            </Page>
        );
    }

} 