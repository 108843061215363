import React from 'react';
import { Page,  GenericHeader } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Form } from './components';
import { IconButton, Typography, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    },
    color:{
        color: 'white'
    }
}));

const CatalogDetailAdd = props => {
    const classes = useStyles();
    const  { session } = props;

    return (  
        <Page 
            title = 'Catalogos'>
            <div className={classes.root}>

                <GenericHeader title='Agregar Catálogo' />
                <br/>
                <Form session={session}/>
                <br/>
            </div>

            <Box
                bgcolor="primary.main"
                border={0}   
                color="info.contrastText"   
            >
                <IconButton
                    href="/administrador/detalle-catalogo"
                >
                    <ArrowBackIcon
                        className={classes.color}
                    />
                    <Typography
                        className={classes.color}
                    > REGRESAR A CATALOGOS
                    </Typography>
                </IconButton>
            </Box>
        </Page>
    );
}
 
const mapStateToProps = state =>({ session: state.session});
export default connect(mapStateToProps,null)(withStyles(useStyles)(CatalogDetailAdd));
