import React, { Component } from 'react';
import { Page } from 'components';
import { GenericUploadForm } from 'components';
export default class ConsolidateChargesView extends Component {
  

    render(){
        return(
            <Page>
                <GenericUploadForm
                    title='Consolidar Cobros'
                >

                </GenericUploadForm>
                
            </Page>
        );
    }

}