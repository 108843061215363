import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, CardContent, Grid, TextField, Button,  CardActions } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import { EmpresaProvider } from 'providers/empresa.service';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { PlanProvider } from 'providers/plan.service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {  validateOnlyFloatNumbers, validateOnlyNumbers } from 'services/validation';
import MaterialTable, { MTableEditRow } from 'material-table';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    }
}));



export default function Form(props) {
    const tableRef = React.useRef();

    const initialState = {
        company: null,
        typePlan: '',
        modality: '',
        deductible: '',
        tariff: '',
        name: '',
        contract: '',
        annualValue: '',
        monthlyValue: '',
        tax: '',
        byDiscount: '',
        childrenAge: '',
        holderAgeMin: '',
        holderAge: '',
        numberBeneficiaries: '',
        product: '',
        maximumCoverage: '',
        deductibleValue: '',
        acessCode: '',
        typeCode: '',
        extCide:'',
        errors: {
            company: false,
            typePlan: false,
            modality: false,
            deductible: false,
            tariff: false,
            name: false,
            contract: false,
            annualValue: false,
            monthlyValue: false,
            tax: false,
            byDiscount: false,
            childrenAge: false,
            holderAge: false,
            holderAgeMin: false,
            numberBeneficiaries: false,
            product: false,
            maximumCoverage: false,
            deductibleValue: false,
            acessCode: false,
            typeCode: false,
            extCide:false,
        },
        messages: {
            company: '',
            typePlan: '',
            modality: '',
            deductible: '',
            tariff: '',
            name: '',
            contract: '',
            annualValue: '',
            monthlyValue: '',
            tax: '',
            byDiscount: '',
            childrenAge: '',
            holderAge: '',
            holderAgeMin: '',
            numberBeneficiaries: '',
            product: '',
            maximumCoverage: '',
            deductibleValue: '',
            acessCode: '',
            typeCode: '',
            extCide:'',
        }

    }

    const initialStateTable = {
        detTipo: null,
        detPrestacion: '',
        detTipoModalidad:'',
        detPais: '',
        copCopago:'',
        copTope:'',
        copTipoTarifario: '',
        errors: {
            detTipo: false,
            detPrestacion: false,
            detTipoModalidad:false,
            detPais: false,
            copCopago:false,
            copTope:false,
            copTipoTarifario: false,
            deductible: false,
            deductibleValue: false
        },
        messages: {
            detTipo: '',
            detPrestacion: '',
            detTipoModalidad:'',
            detPais: '',
            copCopago:'',
            copTope:'',
            copTipoTarifario: '',
            deductible: '',
            deductibleValue: '',
        }

    }
    const [stateTable] = useState(initialStateTable);
    const [allPlanCoverage, setAllPlanCoverage] = useState([]);
    const [allBenefit, setAllBenefit] = useState([]);
    const [allModalityType, setAllModalityType] = useState([]);
    const [allCountries, setAllCountries] = useState([]);

    const initialArrays = {
        allCompanies: [],
        allTypePlans:[],
        allModalities: [],
        allTypeDeductibles: [],
        allTariffs: []
    }
    const [stateSelect, setStateSelect] = useState(initialState);
    const [stateSelectArray, setStateSelectArray]= useState(initialArrays)
    const [allCompanies, setAllCompanies]= useState([]);
    const [allTypePlans, setAllTypePlans]= useState([]);
    const [allModalities, setAllModalities]= useState([])
    const [allDeductibles, setAllDeductibles]= useState([])
    const [allTariffs, setAllTariffs]= useState([])
    const [allTypeTariff, setAllTypeTariff] = useState([{'tipoCodigo': 1, value: 'TARIFARIO NACIONAL'},
        {'tipoCodigo': 2, value: 'TARIFARIO MEDICINA'} ]);


    const empresaProvider = new EmpresaProvider();
    const detalleCatalogoProvider = new DetalleCatalogoProvider();
    const planProvider = new PlanProvider();
    const displayMessage = new DisplayMessage();
    const [data, setData] = useState([]);
    const [visualDataRemoved, setVisualDataRemoved] = useState([]);

    const [newAdd, setNew] = useState(false);

    const regexpReplaceAllComa = /,/gi;

    const getCompanyByEmpCodigo = (empCodigo, plan) => {
        empresaProvider.findCompanyByEmpCodigo(empCodigo).then(
            response => {
                const company = response[0];
                setFields(plan, company);
            }
        )
    }

    const setFields = (plan, company) => {
        setStateSelect({ ...stateSelect,
            company: company,
            typePlan: plan.detTipo,
            modality: plan.detModalidad,
            deductible: plan.detDeducible,
            tariff: plan.detTarifario,
            name: plan.plaNombre,
            contract: plan.plaRutaContrato.replace('/data/wmp/plan/', '').replace('/data/wmp/poliza/',''),
            annualValue: plan.plaValorAnual ?`${plan.plaValorAnual}` : '',
            monthlyValue: plan.plaValorMensual ?`${plan.plaValorMensual}`: '',
            tax: plan.plaImpuesto ? `${plan.plaImpuesto}`: '',
            byDiscount: plan.plaPorDescuento ? `${plan.plaPorDescuento}`: '',
            childrenAge: plan.plaEdadHijos ? `${plan.plaEdadHijos}`: '',
            holderAge: plan.plaEdadTitular ?`${plan.plaEdadTitular}` : '',
            holderAgeMin: plan.plaEdadminTitular ?`${plan.plaEdadminTitular}` : '',
            numberBeneficiaries: plan.plaNumBeneficiarios ? `${plan.plaNumBeneficiarios}` : '' ,
            product: plan.plaProducto,
            maximumCoverage: plan.plaCoberturaMaxima ? `${plan.plaCoberturaMaxima}`: '',
            deductibleValue: plan.plaValorDeducible ? `${plan.plaValorDeducible}`: '',
            acessCode: plan.plaCodAcess,
            typeCode: plan.plaTipo,
            extCide: plan.plaCodExt,});
    }
    const getPlanByPlaCodigo = () => {
        setNew(true);
        planProvider.findPlanByPlaCodigo(parseInt(atob(props.id))).then(
            async response => {
                const plan = await response[0];
                const filterCoberturasPlan = await plan.coberturasPlan.filter(cP => cP.copEstado >= 0);
                await filterCoberturasPlan.forEach(element => {
                    const finded = allTypeTariff.find( i => i.tipoCodigo === element.copTipoTarifario);
                    element.nombreTipoTarifario = finded ? finded.value: filterCoberturasPlan.copTipoTarifario;
                    element.tipoCopago =element.copCopago.toString().trim() !== '' ? element.copCopago<1 ? element.copCopago === 0 ? 'Valor' : 'Porcentaje': 'Valor': '';
                    element.copCopago =element.copCopago.toString().trim() !== '' ? element.copCopago<1 ? element.copCopago === 0 ? element.copCopago : parseFloat(element.copCopago)*100 : element.copCopago: '';

                });
                await stabishData(plan, filterCoberturasPlan);

            }
        )
    }

    const stabishData = (plan, filterCoberturasPlan) => {
        getCompanyByEmpCodigo(plan.empCodigo, plan);
        setData(filterCoberturasPlan);
    }

    /** ----------------------------Clases para estilos ---------- */
    const classes = useStyles();
    const [disabledButton, setDisabledButton] = React.useState(false);

    const getAllData = () => {
        getAllCompanies();
        getAllTypePlans();
        getAllModalities();      
        getAllDeductibles();
        getAllTariffs();
        getPlanByPlaCodigo();
        getAllPlanCoverage();
        getAllBenefit();
        getAllModalityType();
        getAllCountries();
    }

    useEffect(getAllData, []);

    const refreshTable = () => {
        setNew(false);
    }
    useEffect(refreshTable, [newAdd]);

    const getAllPlanCoverage = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('PRSAN').then(
            response => {
                setAllPlanCoverage(response);
            }
        )
    }

    const getAllBenefit = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('CANPA').then(
            response => {
                setAllBenefit(response);
            }
        )
    }
  
    const getAllModalityType = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('TIPM').then(
            response => {
                setAllModalityType(response);
            }
        )
    }

    const getAllCountries = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('PAIS').then(
            response => {
                setAllCountries(response);
            }
        )
    }

    const _remove = async rowData => {
        const dataAux = await data;
        var i = await dataAux.indexOf(rowData);
        if(typeof(dataAux[i].copCodigo) === 'undefined'){
            await dataAux.splice( i, 1 );
            await setData(dataAux);


        }else{
            const auxRemoved = await visualDataRemoved;
            dataAux[i].copEstado = await -1;
            await auxRemoved.push(dataAux[i])
            await setVisualDataRemoved(auxRemoved)

            await dataAux.splice( i, 1 );
            await setData(dataAux);

        }

        setNew(true)

    }



    const getDatos = async datos => {
        const allCob = await data;
        await allCob.unshift(datos);

        await setData(allCob);

    }

    const getAllCompanies = () => {
        empresaProvider.findCompanyByTipoNemonico('EMPASE').then(
            response => {
                setAllCompanies(response);
                setStateSelectArray({ ...stateSelectArray, allCompanies: response });  
            }
        )
    }
    const getAllTypePlans = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('TIPP').then(
            response => {
                setAllTypePlans(response);
            }
        )
    }

    const getAllModalities = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('TIPM').then(
            response => {
                setAllModalities(response);
            }
        )
    }

    const getAllDeductibles = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('TDE').then(
            response => {
                setAllDeductibles(response);
            }
        )
    }

    const getAllTariffs = () => {
        detalleCatalogoProvider.findDetalleCatalogoByNemonico('TTAR').then(
            response => {
                setAllTariffs(response);
            }
        )
    }
    
    const showEmpties = () => {
        const { company, typePlan, modality, deductible, tariff, name, annualValue, monthlyValue } = stateSelect; 
        notifyEmptyNulls('company',company);
        notifyEmptyNulls('typePlan',typePlan);
        notifyEmptyNulls('modality',modality);
        notifyEmptyNulls('deductible',deductible);
        notifyEmptyNulls('tariff',tariff);
        notifyEmptyNulls('name',name);
        notifyEmptyNulls('annualValue',annualValue);
        notifyEmptyNulls('monthlyValue',monthlyValue);
    }

    /**  ------------------------------------------------------NOtificar NUlos*/

    const notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                setErrorsMessages(nameElement, false, '');
            }
        }
    }

    const setErrorsMessages = async(nameElement, stateElement, message) => {
        const errors = await stateSelect.errors;
        const messages = await stateSelect.messages;
        await eval(`errors.${nameElement}  = ${stateElement}`);
        await eval(`messages.${nameElement} = '${message}'`) ;
        setStateSelect({ ...stateSelect, errors: errors, messages: messages });  

    }

    const handleSave = async() => {
        await setDisabledButton(true);

        const {company, typePlan, modality, deductible, tariff, name, contract
            ,annualValue,monthlyValue, tax, byDiscount, childrenAge, holderAge,
            numberBeneficiaries, product, maximumCoverage, deductibleValue,
            acessCode, typeCode,extCide, holderAgeMin} = stateSelect;
        if(company === null || typePlan === '' || modality === '' || deductible === '' || tariff === '' || name.trim() === '' || annualValue.trim() === '' || monthlyValue.trim() === ''){
            displayMessage.error('Se han detectado campos vacios');
            await showEmpties();
            await setDisabledButton(false);

        }else{
            let otheData = await data;
            let dataAux = await [];
            const cobertAux = await otheData.concat(visualDataRemoved);

            await cobertAux.forEach(
                async coberturaPlan => {
                    const cobPlan = await {
                        detTipo: coberturaPlan.detTipo,
                        detPrestacion: coberturaPlan.detPrestacion,
                        detTipoModalidad: coberturaPlan.detTipoModalidad,
                        detPais: coberturaPlan.detPais,
                        copCopago: coberturaPlan.tipoCopago === 'Valor'? parseInt(coberturaPlan.copCopago) : parseInt(coberturaPlan.copCopago)/100,
                        copTope: coberturaPlan.copTope,
                        copTipoTarifario: coberturaPlan.copTipoTarifario,
                        copEstado: coberturaPlan.copEstado,
                        detTipoDeducible: coberturaPlan.detTipoDeducible,
                        copValorDeducible: coberturaPlan.copValorDeducible
                    }
                    if(await coberturaPlan.copCodigo){
                        cobPlan.copCodigo =  await coberturaPlan.copCodigo;
                    }
                    await dataAux.push(cobPlan);

                }
            )
            const trama = await[{
                plaCodigo: parseInt(atob(props.id)),
                empCodigo: company.empCodigo,
                detTipo: typePlan,
                detModalidad: modality,
                detDeducible: deductible,
                detTarifario: tariff,
                plaNombre: name,
                plaRutaContrato: contract.trim() === '' ? contract.trim() : `/data/wmp/plan/${contract}`,
                plaValorAnual: annualValue.trim() === '' ? null : parseFloat(annualValue.replace(regexpReplaceAllComa, '.')),
                plaValorMensual: monthlyValue.trim() === '' ? null : parseFloat(monthlyValue.replace(regexpReplaceAllComa, '.')),
                plaImpuesto: tax.trim() === '' ? null : parseFloat(tax.replace(regexpReplaceAllComa, '.')),
                plaPorDescuento: byDiscount.trim() === '' ? null : parseFloat(byDiscount.replace(regexpReplaceAllComa, '.')),
                plaEdadHijos: childrenAge.trim() ===  '' ? null : parseInt(childrenAge),
                plaEdadminTitular: holderAgeMin.trim() === '' ? null : parseInt(holderAgeMin),
                plaEdadTitular: holderAge.trim() === '' ? null : parseInt(holderAge),
                plaNumBeneficiarios: numberBeneficiaries.trim() === '' ? null : parseInt(numberBeneficiaries),
                plaProducto: product,
                plaCoberturaMaxima: maximumCoverage.trim() === '' ? null : parseFloat(maximumCoverage.replace(regexpReplaceAllComa, '.')),
                plaValorDeducible: deductibleValue.trim() === '' ? null : parseFloat(deductibleValue.replace(regexpReplaceAllComa, '.')),
                plaCodAcess: acessCode,
                plaTipo: typeCode,
                plaCodExt: extCide,
                coberturasPlan: await dataAux,
            }];
            await planProvider.updatePlan(trama).then(
                async response => {
                    if(response.error.codigo === '0'){
                        displayMessage.success(`Plan ${name} actualizado con éxito`);
                        //await restartState();
                        await getPlanByPlaCodigo();
                    }
                    setDisabledButton(false);

                }
            ).catch(
                () => {
                    setData(otheData)
                    setDisabledButton(false);
                }
            )
        }

    }


    const handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'copCopago':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });
                }
                break;
            case 'copTope':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });
                }
                break;
            case 'copTipoTarifario':
                if(validateOnlyNumbers(value) && value.length <= 4){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            case 'annualValue':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            
            case 'monthlyValue':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            
            case 'tax':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break; 
            case 'byDiscount':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break; 
            case 'childrenAge':
                if(validateOnlyNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;  
            case 'holderAge':
                if(validateOnlyNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            case 'holderAgeMin':
                if(validateOnlyNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            case 'numberBeneficiaries':
                if(validateOnlyNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;  
            case 'maximumCoverage':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value }); 
 
                }
                break;
            case 'deductibleValue':
                if(validateOnlyFloatNumbers(value)){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }
                break;
            case 'acessCode':
                if(value.length <= 50){
                    setStateSelect({ ...stateSelect, [name]: value });  
                }else{
                    displayMessage.info('Máximo 50 caracteres')
                }
                break;
            case 'typeCode':
                if(value.length <= 20){
                    setStateSelect({ ...stateSelect, [name]: value });
                }else{
                    displayMessage.info('Máximo 20 caracteres')
                }
                break;
            case 'extCide':
                if(value.length <= 20){
                    setStateSelect({ ...stateSelect, [name]: value });
                }else{
                    displayMessage.info('Máximo 20 caracteres')
                }
                break;
            default:
                setStateSelect({ ...stateSelect, [name]: value });
                break;
        }
    }
    /** --------------------------Elegir compania */
    const handleCompanyChange = (event, newValue) => {
        setStateSelect({ ...stateSelect, company: newValue });
    }

    const columns = [
        { title: 'Tipo', field: 'tipoDescripcion', editComponent: props => <Autocomplete
            id="detTipo"
            value={props.value || null}
            onChange={(e,value) => props.onChange(value)}
            options={allPlanCoverage}
            getOptionLabel={(option) => option.detDescripcion}
            fullWidth
            renderInput={(params) => <TextField {...params} label='Tipo' required fullWidth error={stateTable.errors.detTipo} helperText={stateTable.messages.detTipo} />}
        /> },
        { title: 'Prestación', field: 'prestacionNombre', editComponent: props => 
            <Autocomplete
                id="detPrestacion"
                value={props.value || null}
                onChange={(e,value) => props.onChange(value)}
                options={allBenefit}
                getOptionLabel={(option) => option.detNombre}
                fullWidth
                renderInput={(params) => <TextField {...params} label='Prestación' required fullWidth error={stateTable.errors.detPrestacion} helperText={stateTable.messages.detPrestacion} />}
            /> },
        { title: 'Modalidad', field: 'modalidadNombre', editComponent: props =>
            <Autocomplete
                id="detTipoModalidad"
                value={props.value || null}
                onChange={(e,value) => props.onChange(value)}
                options={allModalityType}
                getOptionLabel={(option) => option.detNombre}
                fullWidth
                renderInput={(params) => <TextField {...params} label='Tipo de modalidad' required fullWidth error={stateTable.errors.detTipoModalidad} helperText={stateTable.messages.detTipoModalidad} />}
            />},
        { title: 'Pais', field: 'paisNombre', editComponent: props =>
            <Autocomplete
                id="detPais"
                value={props.value || null}
                onChange={(e,value) => props.onChange(value)}
                options={allCountries}
                getOptionLabel={(option) => option.detNombre}
                fullWidth
                renderInput={(params) => <TextField {...params} label='País' required fullWidth error={stateTable.errors.detPais} helperText={stateTable.messages.detPais} />}
            />},
        {title: 'Deducible', field: 'tipoDeducibleNombre', editComponent: props =>
        <Autocomplete
                id="detTipoDeducible"
                value={props.value || null}
                onChange={(e,value) => props.onChange(value)}
                options={allDeductibles}
                getOptionLabel={(option) => option.detNombre}
                fullWidth
                renderInput={(params) => <TextField {...params} label='Deducible' fullWidth error={stateTable.errors.deductible} helperText={stateTable.messages.deductible} required/>}
            />
        },
        {title: 'Valor deducible', field: 'copValorDeducible', editComponent: props =>
        <TextField value={props.value || ''} onChange={e => {if(validateOnlyFloatNumbers(e.target.value)){ props.onChange(e.target.value.toString())}else{displayMessage.warn('Solo números enteros') }}} error={stateTable.errors.deductibleValue} helperText={stateTable.messages.deductibleValue} name='deductibleValue' label="Valor deducible" required fullWidth />
        },
        {title: 'Tipo de copago', field: 'tipoCopago', editComponent: props =>
            <FormControl  fullWidth>
                <InputLabel id="tipoCopagoLabel">Tipo de copago</InputLabel>
                <Select
                    labelId="tipoCopagoLabel"
                    id="tipoCopago"
                    name='tipoCopago'
                    value={props.value || 0}
                    onChange={e => props.onChange(e.target.value)}
                    label="Tipo de copago"
                    fullWidth
                >
                    <MenuItem value={0}>
                    Valor
                    </MenuItem>
                    <MenuItem value={1}>
                    Porcentaje
                    </MenuItem>
                </Select>
            </FormControl>
        },
        { title: 'Copago', field: 'copCopago', editComponent: props =>
            <TextField value={props.value || ''} onChange={e => {if(validateOnlyNumbers(e.target.value.toString()) && (parseInt(e.target.value)<=100 || e.target.value.trim()=== '' )){ props.onChange(e.target.value.toString())}else{displayMessage.warn('Solo números enteros del 1 al 100') }}} error={stateTable.errors.copCopago} helperText={stateTable.messages.copCopago} name='copCopago' label="Copago"  fullWidth />
        },
        { title: 'Tope', field: 'copTope', editComponent: props => <TextField value={props.value || ''} onChange={e => {if(validateOnlyFloatNumbers(e.target.value)){ props.onChange(e.target.value.toString())}}  } error={stateTable.errors.copTope} helperText={stateTable.messages.copTope} name='copTope' label="Tope" fullWidth required />},
        { title: 'Tipo de tarifario', field: 'nombreTipoTarifario', editComponent: props =>
            <Autocomplete
                id="copTipoTarifario"
                value={props.value || null}
                onChange={(e,value) => props.onChange(value)}
                options={allTypeTariff}
                getOptionLabel={(option) => option.value}
                fullWidth
                renderInput={(params) => <TextField {...params} label='Tipo de tarifario' fullWidth error={stateTable.errors.copTipoTarifario} helperText={stateTable.messages.copTipoTarifario} />}
            />
        },
    ]


    const handleAdd = async(rowData) => {
        await setNew(true);

        const { tipoDescripcion, prestacionNombre, modalidadNombre, copTope, paisNombre, copCopago, nombreTipoTarifario, tipoCopago, tipoDeducibleNombre, copValorDeducible } = rowData;
        if(tipoDescripcion === undefined || prestacionNombre === undefined || modalidadNombre === undefined || copTope === undefined || tipoDeducibleNombre === undefined || copValorDeducible === undefined){
            displayMessage.error('Se han detectado campos vacios');
            await setNew(true);


        }else{
            if(prestacionNombre === '' || modalidadNombre === '' || copTope === '' || copValorDeducible === '' ){
                displayMessage.error('Se han detectado campos vacios');
                await setNew(true);

            }else{
                try {
                    const nuevo = await {
                        detTipo: tipoDescripcion.detCodigo,
                        tipoDescripcion: tipoDescripcion.detDescripcion,
                        detPrestacion: prestacionNombre.detCodigo,
                        prestacionNombre: prestacionNombre.detNombre,
                        detTipoModalidad: modalidadNombre.detCodigo,
                        modalidadNombre: modalidadNombre.detNombre,
                        detPais: paisNombre ? paisNombre.detCodigo : null,
                        paisNombre: paisNombre ? paisNombre.detNombre : '',
                        detTipoDeducible: tipoDeducibleNombre.detCodigo,
                        tipoDeducibleNombre: tipoDeducibleNombre.detNombre,
                        tipoCopago: tipoCopago === 0 ? 'Valor': copCopago ? parseInt(copCopago.toString()) === 0 ? 'Valor':'Porcentaje': 'Valor',
                        copCopago: copCopago ?  copCopago.toString().trim() === '' ? 0 :  parseInt(copCopago.toString()) : 0,
                        copTope: copTope ? parseFloat(copTope.toString().replace(regexpReplaceAllComa, '.')): null,
                        copValorDeducible: copValorDeducible ? parseFloat(copValorDeducible.toString().replace(regexpReplaceAllComa, '.')) :null,
                        copTipoTarifario: nombreTipoTarifario ? nombreTipoTarifario.tipoCodigo : null,
                        nombreTipoTarifario: nombreTipoTarifario ? nombreTipoTarifario.value : '',
                        copEstado: 1,
                    }

                    await getDatos(nuevo);
                    await setNew(true);

                } catch (error) {
                    displayMessage.error(`Error de procesamiento: ${error}`);
                    await setNew(true);

                }
            }
            // await setGuardar(false);

        }
    }
    const openAdd = () => {
        const materialTable = tableRef.current;
        materialTable.dataManager.changeRowEditing();
        materialTable.setState({
            ...materialTable.dataManager.getRenderState(),
            showAddRow: true,
        });
    }
    const cancelAdd = () => {
        const materialTable = tableRef.current;
        materialTable.dataManager.changeRowEditing();
        materialTable.setState({
            ...materialTable.dataManager.getRenderState(),
            showAddRow: false,
        });
    }

    const cancelEdit = (rowData) => {
        try {
            NamestoStringRowData(rowData);

            const materialTable = tableRef.current;

            materialTable.setState({
                ...materialTable.dataManager.getRenderState(),
                lastEditingRow: undefined,
            });
            rowData.tableData.editing = undefined;
        } catch (error) {
            displayMessage.error(`Error al cancelar la edición: ${error}`);
        }
    }
    const NamestoStringRowData = rowData => {
        rowData.tipoDescripcion = rowData.tipoDescripcion ? rowData.tipoDescripcion.detDescripcion : null;
        rowData.tipoDeducibleNombre = rowData.tipoDeducibleNombre ? rowData.tipoDeducibleNombre.detNombre : null;
        rowData.prestacionNombre = rowData.prestacionNombre ? rowData.prestacionNombre.detNombre : null;
        rowData.modalidadNombre = rowData.modalidadNombre ? rowData.modalidadNombre.detNombre : null ;
        rowData.paisNombre = rowData.paisNombre ? rowData.paisNombre.detNombre : null;
        rowData.nombreTipoTarifario = rowData.nombreTipoTarifario ? rowData.nombreTipoTarifario.value : null;
        rowData.tipoCopago =  rowData.tipoCopago === 0 ? 'Valor' : 'Porcentaje';
    }
    const cancelDelete = rowData => {
        const materialTable = tableRef.current;
        NamestoStringRowData(rowData);
        materialTable.setState({
            ...materialTable.dataManager.getRenderState(),
            lastEditingRow: undefined,
        });
        rowData.tableData.editing = undefined;
    }
    const onClickSave = async(mode, newData, oldData) => {
        switch (mode) {
            case 'add':
                handleAdd(newData);
                cancelAdd();
                break;
            case 'update':
                handleEdit(newData, oldData);
                break;
            case 'delete':
                _remove(oldData);
                cancelDelete(oldData);
                break;
            default:
                displayMessage.error('Modo no detectado');
                break;
        }
    }
    const handleEdit = async(newData, oldData) => {
        const { tipoDescripcion, prestacionNombre, modalidadNombre, copTope, paisNombre, copCopago, nombreTipoTarifario, tipoCopago,  tipoDeducibleNombre, copValorDeducible} = newData;
        if(tipoDescripcion === undefined || prestacionNombre === undefined || modalidadNombre === undefined|| copTope === undefined || tipoDeducibleNombre === undefined || copValorDeducible === undefined){
            displayMessage.error('Se han detectado campos vacios');
            await setNew(true);

        }else{
            if(prestacionNombre === '' || modalidadNombre === '' || copTope === '' || copValorDeducible === '' ){
                displayMessage.error('Se han detectado campos vacios');
                await setNew(true);

            }else{
                try {
                    const nuevo = await {
                        detTipo: tipoDescripcion.detCodigo,
                        tipoDescripcion: tipoDescripcion.detDescripcion,
                        detPrestacion: prestacionNombre.detCodigo,
                        prestacionNombre: prestacionNombre.detNombre,
                        detTipoModalidad: modalidadNombre.detCodigo,
                        modalidadNombre: modalidadNombre.detNombre,
                        detPais: paisNombre ? paisNombre.detCodigo : null,
                        paisNombre: paisNombre ? paisNombre.detNombre : '',
                        detTipoDeducible: tipoDeducibleNombre.detCodigo,
                        tipoDeducibleNombre: tipoDeducibleNombre.detNombre,
                        tipoCopago: tipoCopago === 0 ? 'Valor': copCopago ? parseInt(copCopago.toString()) === 0 ? 'Valor':'Porcentaje': 'Valor',
                        copCopago: copCopago ?  copCopago.toString().trim() === '' ? 0 :  parseInt(copCopago.toString()) : 0,
                        copTope: copTope ? parseFloat(copTope.toString().replace(regexpReplaceAllComa, '.')): null,
                        copValorDeducible: copValorDeducible ? parseFloat(copValorDeducible.toString().replace(regexpReplaceAllComa, '.')) : null,
                        copTipoTarifario: nombreTipoTarifario ? nombreTipoTarifario.tipoCodigo : null,
                        nombreTipoTarifario: nombreTipoTarifario ? nombreTipoTarifario.value : '',
                        copEstado: 1,
                    }
                    if(await oldData.copCodigo){
                        nuevo.copCodigo = await oldData.copCodigo
                    }
                    const auxData = await data;
                    const index = await auxData.indexOf(oldData);
                    auxData[index] = await nuevo;
                    await setData(auxData)
                    await setNew(true);

                } catch (error) {
                    displayMessage.error(`Error de procesamiento: ${error}`)
                    await setNew(true);

                }
            }
            // await setGuardar(false);

        }
    }
    const onClickCancel = (mode, rowData) => {
        switch (mode) {
            case 'add':
                cancelAdd();
                break;
            case 'update':
                cancelEdit(rowData);
                break;
            case 'delete':
                cancelDelete(rowData);
                break;
            default:
                displayMessage.error('Modo no detectado');
                break;
        }
    }

    const setFieldsTable = async rowData => {
        rowData.tipoDescripcion = await findTypeInArray(rowData.detTipo);
        rowData.tipoDeducibleNombre = await findDeductibleTypeInArray(rowData.detTipoDeducible);
        rowData.prestacionNombre = await findBenefitInArray(rowData.detPrestacion);
        rowData.modalidadNombre = await findModalityInArray(rowData.detTipoModalidad);
        rowData.paisNombre = await findCountryInArray(rowData.detPais);
        rowData.nombreTipoTarifario = await findTypeTariffInArray(rowData.copTipoTarifario);
        rowData.tipoCopago = await rowData.tipoCopago === 'Valor' ? 0 : 1;
    }
    const findTypeInArray = async detTipo => await allPlanCoverage.find(element => element.detCodigo === detTipo);

    const findDeductibleTypeInArray = async detTipoDeducible => await allDeductibles.find(element => element.detCodigo === detTipoDeducible);

    const findBenefitInArray = async detPrestacion => await allBenefit.find(element => element.detCodigo === detPrestacion);

    const findModalityInArray = async detTipoModalidad =>  await allModalities.find(element => element.detCodigo === detTipoModalidad);

    const findCountryInArray = async detPais =>  await allCountries.find(element => element.detCodigo === detPais);

    const findTypeTariffInArray = async copTipoTarifario =>  await allTypeTariff.find(element => element.tipoCodigo === copTipoTarifario);

    const setFormTable = async(event, rowData) => {
        await setFieldsTable(rowData);
        const materialTable = tableRef.current;
        rowData.tableData.editing='update';

        materialTable.dataManager.changeRowEditing();
        materialTable.setState({
            ...materialTable.dataManager.getRenderState(),
            lastEditingRow: rowData,
        });

    }

    const openDelete = async(event, rowData) => {
        await setFieldsTable(rowData);
        const materialTable = tableRef.current;
        rowData.tableData.editing='delete';
        materialTable.dataManager.changeRowEditing();
        materialTable.setState({
            ...materialTable.dataManager.getRenderState(),
            lastEditingRow: rowData,
        });

    }

    let recorrer = 0;

    return (
        <div>
            <br/>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <Autocomplete
                                getOptionLabel={(option) => option.empNombreComercial}
                                id="company"
                                onChange={handleCompanyChange}
                                options={allCompanies}
                                renderInput={(params) => <TextField {...params} label='Empresa' variant="outlined" error={stateSelect.errors.company} helperText={stateSelect.messages.company} />}
                                value={stateSelect.company}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl error={stateSelect.errors.typePlan} fullWidth required variant="outlined">
                                <InputLabel id="typePlanLabel">Tipo</InputLabel>
                                <Select
                                    fullWidth
                                    id="typePlan"
                                    label="Tipo"
                                    labelId="typePlanLabel"
                                    name='typePlan'
                                    onChange={handleInputChange}
                                    value={stateSelect.typePlan}
                                >
                                    <MenuItem value="">
                                        <p> </p>
                                    </MenuItem>
                                    { allTypePlans.map(
                                        typePlan => <MenuItem key={typePlan.detCodigo} value={typePlan.detCodigo}>{typePlan.detNombre}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl error={stateSelect.errors.modality} fullWidth required variant="outlined">
                                <InputLabel id="ModalityLabel">Modalidad</InputLabel>
                                <Select
                                    fullWidth
                                    id="modality"
                                    label="Modalidad"
                                    labelId="ModalityLabel"
                                    name='modality'
                                    onChange={handleInputChange}
                                    value={stateSelect.modality}
                                >
                                    <MenuItem value="">
                                        <p> </p>
                                    </MenuItem>
                                    { allModalities.map(
                                        modality => <MenuItem key={modality.detCodigo} value={modality.detCodigo}>{modality.detNombre}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl error={stateSelect.errors.deductible} fullWidth required variant="outlined">
                                <InputLabel id="DeductibleLabel">Deducible</InputLabel>
                                <Select
                                    fullWidth
                                    id="deductible"
                                    label="Deducible"
                                    labelId="DeductibleLabel"
                                    name='deductible'
                                    onChange={handleInputChange}
                                    value={stateSelect.deductible}
                                >
                                    <MenuItem value="">
                                        <p> </p>
                                    </MenuItem>
                                    { allDeductibles.map(
                                        deductible => <MenuItem key={deductible.detCodigo} value={deductible.detCodigo}>{deductible.detNombre}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl error={stateSelect.errors.tariff} fullWidth required variant="outlined">
                                <InputLabel id="TariffsLabel">Tarifario</InputLabel>
                                <Select
                                    fullWidth
                                    id="tariff"
                                    label="Tarifario"
                                    labelId="TariffsLabel"
                                    name='tariff'
                                    onChange={handleInputChange}
                                    value={stateSelect.tariff}
                                >
                                    <MenuItem value="">
                                        <p> </p>
                                    </MenuItem>
                                    { allTariffs.map(
                                        tariff => <MenuItem key={tariff.detCodigo} value={tariff.detCodigo}>{tariff.detNombre}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.name} fullWidth helperText={stateSelect.messages.name} label="Nombre" name='name' onChange={handleInputChange} required value={stateSelect.name} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.contract} fullWidth helperText={stateSelect.messages.contract} label="Nombre del archivo de contrato" name='contract' onChange={handleInputChange} value={stateSelect.contract}  variant="outlined"/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.annualValue} fullWidth helperText={stateSelect.messages.annualValue} label="Valor anual" name='annualValue' onChange={handleInputChange} value={stateSelect.annualValue} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.monthlyValue} fullWidth helperText={stateSelect.messages.monthlyValue} label="Valor mensual" name='monthlyValue' onChange={handleInputChange} value={stateSelect.monthlyValue} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.tax} fullWidth helperText={stateSelect.messages.tax} label="Impuesto" name='tax' onChange={handleInputChange} value={stateSelect.tax} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.byDiscount} fullWidth helperText={stateSelect.messages.byDiscount} label="Porcentaje de descuento" name='byDiscount' onChange={handleInputChange} value={stateSelect.byDiscount} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.childrenAge} fullWidth helperText={stateSelect.messages.childrenAge} label="Edad de los hijos" name='childrenAge' onChange={handleInputChange} value={stateSelect.childrenAge} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={stateSelect.holderAgeMin} error={stateSelect.errors.holderAgeMin} helperText={stateSelect.messages.holderAgeMin} name='holderAgeMin' label="Edad mínima del titular" variant="outlined" onChange={handleInputChange} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.holderAge} fullWidth helperText={stateSelect.messages.holderAge} label="Edad máxima del titular" name='holderAge' onChange={handleInputChange} value={stateSelect.holderAge} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.numberBeneficiaries} fullWidth helperText={stateSelect.messages.numberBeneficiaries} label="Número de beneficiarios" name='numberBeneficiaries' onChange={handleInputChange} value={stateSelect.numberBeneficiaries} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.product} fullWidth helperText={stateSelect.messages.product} label="Descripción del producto" name='product' onChange={handleInputChange} value={stateSelect.product} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.maximumCoverage} fullWidth helperText={stateSelect.messages.maximumCoverage} label="Cobertura máxima" name='maximumCoverage' onChange={handleInputChange} value={stateSelect.maximumCoverage} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.deductibleValue} fullWidth helperText={stateSelect.messages.deductibleValue} label="Valor deducible" name='deductibleValue' onChange={handleInputChange} value={stateSelect.deductibleValue} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.acessCode} fullWidth helperText={stateSelect.messages.acessCode} label="Código Acess" name='acessCode' onChange={handleInputChange} value={stateSelect.acessCode} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.typeCode} fullWidth helperText={stateSelect.messages.typeCode} label="Código de tipo de plan" name='typeCode' onChange={handleInputChange} value={stateSelect.typeCode} variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField error={stateSelect.errors.extCide} fullWidth helperText={stateSelect.messages.extCide} label="Código externo" name='extCide' onChange={handleInputChange} value={stateSelect.extCide} variant="outlined" />
                        </Grid>
                    </Grid>

                </CardContent>
                {/** ----------------------------------------------------------------------------------------- */}

                <MaterialTable
                    components={{
                        EditRow: props => {
                            return ( <MTableEditRow
                                {...props}
                                onEditingCanceled={(mode, rowData) => {
                                    onClickCancel(mode, rowData);
                                }}
                                onEditingApproved={(mode, newData, oldData) => {
                                    onClickSave(mode, newData, oldData);
                                }}
                            />)
                        }
                    }}
                    columns={columns}
                    data={data}
                    tableRef={tableRef}
                    style={{margin: '2em'}}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Editar',
                            onClick: (event, rowData) => setFormTable(event, rowData)
                        },
                        {
                            icon: 'add',
                            tooltip: 'Agregar',
                            isFreeAction: true,
                            onClick: () => openAdd()
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Eliminar',
                            onClick: (event, rowData) => openDelete(event, rowData)
                        }
                    ]
                    }
                    localization={{
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'

                        },
                        toolbar: {
                            nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                            searchPlaceholder: 'Buscar'
                        },
                        header: {
                            actions: 'Acción'
                        },
                        body: {
                            editRow: {
                                deleteText: '¿Estás seguro de que deseas eliminar este registro?'
                            },
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter',

                            },

                        }
                    }}

                    options={{
                        addRowPosition: 'first',
                        search: true,
                        actionsColumnIndex: -1,
                        actionsCellStyle: {
                            width: 100,
                            textAlign: 'center'
                        },
                        headerStyle: {
                            fontWeight: 'bold'
                        },
                        toolbar: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20, 50, { label: 'Todas', value: data.length }],

                        showTitle: false,
                        rowStyle: () => {
                            recorrer = recorrer + 1;
                            return ({
                                backgroundColor: recorrer % 2 ? '#EEE' : '#FFF',
                            })
                        },
                    }}


                    title={'Persona'}
                />

                <CardActions>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                    >
                        <div className={classes.wrapper}>

                            <Button
                                color="primary"
                                disabled={disabledButton}
                                onClick={handleSave}
                                startIcon={<DoneIcon/>}
                                variant="contained"
                            >guardar
                            </Button>
                            {
                                disabledButton === true &&
          <CircularProgress className={classes.buttonProgress} size={24} /> 

                            }
                        </div>

                    </Grid>
                </CardActions>
            </Card>
        </div>
    );
    
}