import React,{Fragment,useEffect,useState} from 'react'
import MaterialTable from 'material-table';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { PlanProvider } from 'providers/plan.service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SearchBasic } from 'components';
import { QueriesProvider } from 'providers/queries.service';
import {  Card, Tooltip } from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { DisplayMessage } from 'notifications/displayMessage';

export default function Results(){
    const tableRef = React.useRef();
    const [searchBar, setSearchBar] = useState('');
    const queriesProvider = new QueriesProvider();
    const planProvider = new PlanProvider();
    const displayMessage = new DisplayMessage();
    const [booleano, setBooleano] = useState(false);
    const [count, setCount] = useState(false);

    const initialState = {
        columns: [
            { title: 'Nombre', field: 'plaNombre' },
            { title: 'Producto', field: 'plaProducto' },
            { title: 'Cobertura máxima', field: 'plaCoberturaMaxima' },
            { title: 'Deducible', field: 'plaValorDeducible' },
            { title: 'Beneficiarios', field: 'plaNumBeneficiarios' },

        ],
        data:[]
    }
    const [stateSelect, setStateSelect] = useState(initialState);
    const [columns] = useState([
        { title: 'Nombre', field: 'plaNombre' },
        { title: 'Producto', field: 'plaProducto' },
        { title: 'Cobertura máxima', field: 'plaCoberturaMaxima' },
        { title: 'Deducible', field: 'plaValorDeducible' },
        { title: 'Beneficiarios', field: 'plaNumBeneficiarios' },

    ])

    const handleClean = () => {
        tableRef.current.onQueryChange();
        setBooleano(false)
        setSearchBar('');
    }

    const handleSearchPlan = () => {
        tableRef.current.onSearchChange(searchBar);

    }


    let recorrer = 0;
    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'searchBar':
                setSearchBar(value);
                break;
            default:
                break;
        }
    }

    const formFrontToBack = [
        { back: 'PLA_NOMBRE', front: 'plaNombre' },
        { back: 'PLA_PRODUCTO', front: 'plaProducto' },
        { back: 'PLA_COBERTURA_MAXIMA', front: 'plaCoberturaMaxima' },
        { back: 'PLA_VALOR_DEDUCIBLE', front: 'plaValorDeducible' },
        { back: 'PLA_NUM_BENEFICIARIOS', front: 'plaNumBeneficiarios' },

    ]
    const loadData =  (query, resolve) => {
        let filterBack = 'PLA_CODIGO';
        if (query.orderBy) {
            const backFounded =  formFrontToBack.find(element => element.front === query.orderBy.field);
            filterBack =  typeof (backFounded) !== 'undefined' ? backFounded.back : 'PLA_CODIGO';
        }
        const orderBy =  {
            field:  filterBack,
            orderDirection:  query.orderBy ? query.orderDirection : 'asc'
        }

        queriesProvider.searchOrPlan(searchBar, orderBy, query.pageSize * (query.page), query.pageSize).then(
            response => {
                if (response.error.codigo === '0' && response.bodyOut.data.length) {
                    setCount(response.bodyOut.data[0].TOTAL);
                    setBooleano(false);
                    resolve({
                        data: response.bodyOut.data,
                        page: query.page,
                        totalCount: response.bodyOut.data[0].TOTAL
                    });
                } else {
                    setBooleano(false);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    setCount(0);
                    displayMessage.error('No se encontró resultados')
                }

            }
        ).catch(
            () => {
                setBooleano(false);
                setCount(0);
                resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
            }
        );
    }
    const dataReportsExport = (typeResult) => {
        const orderBy = {
            field: 'PLA_CODIGO',
            orderDirection:  'asc'
        }
        queriesProvider.searchOrPlanExport(typeResult ,searchBar, orderBy, 0, count).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
        () => {
            //displayMessage.error('Error de comunicación.');
    
        });
    }

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].BUSCADOR_PLAN}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }
    return (
      
        <Fragment>
            <br/>
            <SearchBasic
                cleanSearchBar={handleClean}
                complementaryMessage="Buscar"
                searchBar={searchBar}
                handleInputChange={handleChange}
                handleSearch={handleSearchPlan}
            />
            <br />
            <Grid
                alignItems="center"
                container
                direction="row"
                justify="flex-end"
            >
                
                <Button
                    color="primary"
                    href="/plan/agregar"
                    variant="contained"  
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                >
                    Nuevo Plan
                </Button>
            </Grid>
            <br/>
            <Grid
                item
                xs={12}
                style={{ paddingLeft: 1, textAlign: 'right', marginBottom:10}}
            >
                <Tooltip title="Exportar a CSV">
                    <Button
                        style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => { dataReportsExport(2); }}
                    >

                        <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                    </Button>
                </Tooltip>
                <Tooltip title="Exportar a EXCEL">
                    <Button
                        style={{ borderRadius: 5,  width: 100}}
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => { dataReportsExport(3); }}
                    >
                        <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
                    </Button>
                </Tooltip>
            </Grid>
            <Grid >
            <Card>
            <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    localization = {{
                        pagination: {
                            labelRowsSelect: 'filas',
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} Fila(s) seleccionada(s)',
                            searchPlaceholder: 'Buscar'

                        },
                        header: {
                            actions: 'Acción'
                        },
                        body: {
                            editRow: {
                                deleteText: '¿Estás segura de eliminar este registro?'
                            },
                            emptyDataSourceMessage: 'No hay registros que mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },
                        }

                    }}
                    data={booleano === false ? 
                    (query => new Promise(
                        resolve => {
                            loadData(query, resolve);
                        }
                    )): []}
                    actions={[
                        {
                            icon: 'save',
                            tooltip: 'Save User',
                            onClick: () => {}
                        }
                    ]}
                    components={{
                        Action: (props) => (
                            <Button
                                color="primary"
                                size="small"
                                style={{textTransform: 'none'}}
                                variant="contained"
                                href={`/plan/modificar/${btoa(props.data.plaCodigo)}`}                
                                startIcon={<VisibilityIcon />}
                            >
                    Ver
                            </Button>
                        ),
                    }}
                    options={{
                        search: false,
                        paging: true,
                        exportButton: false,
                        toolbar: false,
                        actionsColumnIndex: -1,
                        emptyRowsWhenPaging: false,

                        pageSize: 10,
                        pageSizeOptions: [5, 10, 50],
                        rowStyle: () => {
                            recorrer = recorrer + 1;
                            return ({
                                backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                            })
                        },
                    }}
                    
                    /* editable={{
           
            onRowUpdate:editar ,
            onRowDelete:eliminar,
          }} */
                    title=" "
                />
            </Card>
                
            </Grid>

        </Fragment>
    );

}
    