/* eslint no-eval: 0 */

import React, { Component } from 'react';

import {Page, HelpDialog} from 'components';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { ActualDate } from './../../../../../services/ActualDate';


import { Card,
         CardHeader,
         CardContent,
         Divider,
         Button,
        } from '@material-ui/core';


import { PersonAutocomplete } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {EmpresaProvider} from './../../../../../providers/empresa.service';

import { PolizaProvider } from './../../../../../providers/poliza.service';

import { DisplayMessage } from 'notifications/displayMessage';

import { PersonalProvider } from 'providers/persona.service';
import { GenericHeaderFoms } from "components";
import './styles.css';
import Typography from '@material-ui/core/Typography';
import { FormularioProvider } from 'providers/formularios.service';

export default class DisaffiliationView extends Component {
    constructor(props){
        super(props);
            this.decryptedData =typeof(props.match.params.id) === 'undefined' ? 0: atob(props.match.params.id);
            this.state = {
                idRequirement: this.decryptedData,
                titular:null,
                identification: '',
                name: '',
                lastname: '',
                direction: '',
                mail: '',
                dateSelected: ActualDate(),
                AllTitulars: null, 
                AllCompanies:null,
                company: null,
                polCodigo: null,
                poliza:null,
                polContrato: '',
                payMethod: '',
                reasonDisaffiliation: '',
                executive: '',
                lastnameExecutive: '',
                nameExecutive:'',
                disabled: false,
                errorText:{
                    titular: '',
                        company: '',
                        polContrato: '',
                        payMethod: '',
                        reasonDisaffiliation: '',
                        executive: '',
                        lastnameExecutive: '',
                        nameExecutive: '',
                },
                errors: {
                    valid: {
                        titular: false,
                        company: false,
                        polContrato: false,
                        payMethod: false,
                        reasonDisaffiliation: false,
                        executive: false,
                        lastnameExecutive: false,
                        nameExecutive: false,
                    }
                },
                touched:{
                    titular: false,
                    company: false,
                    polContrato: false,
                    payMethod: false,
                    reasonDisaffiliation: false,
                    executive: false,
                    lastnameExecutive: false,
                    nameExecutive: false,
                },
                titularError: false,
                payMethodError: false,
                reasonDisaffiliationError: false,
                executiveError: false,
                lastnameExecutiveError: false,
                nameExecutiveError: false,
                userRequirement: null,
                openHelp: false

            }
        
        this.displayMessage = new DisplayMessage();

        this.pP=new PersonalProvider();
        this.fP = new FormularioProvider();


        this.handleInputChange=this.handleInputChange.bind(this);
        //this.findByIdentification();
    }

    componentDidMount = () => {
        this.findByIdentification();
        this.findAllCompanyes();
        this.getUserRequirement();
    }

    handleOpenHelp = () => {
        this.setState({
            openHelp: true
        });
    }

    handleCloseHelp = () => {
        this.setState( {
            openHelp: false
        } );
    }
    getUserRequirement = () => {
        if(this.state.idRequirement>0){
            this.pP.LiquidationByliqCodigo(this.state.idRequirement).then(
              (response) => {
                try {
                  this.setState({
                    userRequirement: response[0]
                  });
                }catch(error){
                  console.error(error)
      
                }
              }
        
            );
      
          }
      
    }


    

    setCompany = async(value) => {
        await this.setState(
           {    
                company: value

        }
        );

       // this.state.titular=value;
    }

    setTitular = async(value) => {
        await this.setState(
           {    
                titular: value,
                identification: value.perIdentificacion,
                name: value.perNombres,
                lastname: value.perApellidos,
                mail:value.perMail,
                direction: value.perDireccion,
                polCodigo: value.polCodigo,

        }
        );

        await this.getContrato(value.polCodigo)

       // this.state.titular=value;
    }

    getContrato = (polCodigo) => {
        const pP = new PolizaProvider();
        pP.findByPolCodigo(polCodigo).then(resp => {
            

            this.setState({
                poliza: resp[0],
                polContrato: resp[0].polContrato
            })

        } )
    }
    
    
    findAllCompanyes = () => {
        const eP = new EmpresaProvider();
        eP.allCompanies().then( response => {
            this.setState({
                AllCompanies: response
            });
        } )
    }

    findByIdentification = () => {

        const dP = new PersonalProvider();
        
        dP.allPersonsByNombre2("TITULAR").then((response)=> {
            
            try {
                this.setState({
                    AllTitulars: response
                });
            } catch (error) {
                this.setState({
                    name: '',
                    lastname: '',
                    mail: ''
                });
                
            }
        });
       

    }

    formatDate = (dateString) => {

        //dd/MM/yyyy HH:mm:ss
        var dateCad=dateString.split("-")


        const dateConverted=`${dateCad[2]}/${dateCad[1]}/${dateCad[0]}`;
     
        return dateConverted;
    }
    resetFields = () => {
        this.setState({
            idRequirement: 0,
                titular:null,
                identification: '',
                name: '',
                lastname: '',
                direction: '',
                mail: '',
                dateSelected: ActualDate(),
              
                company: null,
                polCodigo: null,
                poliza:null,
                polContrato: '',
                payMethod: '',
                reasonDisaffiliation: '',
                executive: '',
                lastnameExecutive: '',
                nameExecutive:'',
                titularError: false,
                payMethodError: false,
                reasonDisaffiliationError: false,
                executiveError: false,
                lastnameExecutiveError: false,
                nameExecutiveError: false,
                userRequirement: null,
                openHelp: false
        });
    }

    showEmpty=async()=>{
        
        /**
         *  titular: false,
                    company: false,
                    polContrato: false,
                    payMethod: false,
                    reasonDisaffiliation: false,
                    executive: false,
                    lastnameExecutive: false,
                    nameExecutive: false,
         * 
         */

        //await this.isTouched('titular');
       // await this.isTouched('company');
        await this.isTouched('polContrato');
        await this.isTouched('payMethod');
        await this.isTouched('reasonDisaffiliation');
        await this.isTouched('executive');
        await this.isTouched('lastnameExecutive');
        await this.isTouched('nameExecutive');


       // await this.validateNull({ name: 'titular', value: this.state.titular });
        //await this.validateNull({ name: 'company', value: this.state.company });
        await this.validateNull({ name: 'polContrato', value: this.state.polContrato });
        await this.validateNull({ name: 'payMethod', value: this.state.payMethod });
        await this.validateNull({ name: 'reasonDisaffiliation', value: this.state.reasonDisaffiliation });
        await this.validateNull({ name: 'executive', value: this.state.executive });
        await this.validateNull({ name: 'lastnameExecutive', value: this.state.lastnameExecutive });
        await this.validateNull({ name: 'nameExecutive', value: this.state.nameExecutive });
    


    }
    Digitalize = () =>  {
        const disMessage = new DisplayMessage();
        if( this.state.company !== null 
            && this.state.titular !== null 
            && this.state.payMethod!=='' 
            && this.state.reasonDisaffiliation !=='' 
            && this.state.executive!==''
            &&this.state.lastnameExecutive!=='' 
            && this.state.nameExecutive!=='' ){
                const toSave ={
                    //"forCodigo":1,
                    "empCodigo": this.state.company.empCodigo,
                    "detTipo": 17241,
                    "perTitular": this.state.titular.perCodigo,
                    "polCodigo": this.state.titular.polCodigo,
                    "cueCodigo": this.state.titular.detTipoCuenta,
                   
                    //"plaCodigo":null,
                    //"preCodigo": null,
                     "forFecha": this.formatDate(ActualDate()),
                    "forDetalle":`{solicitudDesafiliacion: ${`Yo, ${this.state.titular? this.state.titular.perNombres:''} con CI: ${this.state.titular? this.state.titular.perIdentificacion:''}`+
                    `Titular del Contrato Nº ${this.state.polContrato} perteneciente al Contrato de Asistencia Medica tomado en ${this.state.dateSelected} `+
                    `bajo la modalidad de pago ${this.state.payMethod}, solicito se realice la exclusión del Seguro por motivos de ${this.state.reasonDisaffiliation}.`+ 
                    `Una vez anulado el Contrato, no tendré derecho a atenciones médicas ambulatorias ni hospitalarias, ni poder enviar reembolsos con fechas `+
                    `posteriores a la culminación del Contrato. Para constancia de lo antes expuesto, acepto las condiciones y firmo al pie de este documento como validación de la cancelación del Seguro.`+
                        `\nNOTA: Adjunto copia de Cedula a color para validación de Identidad del Titular del Contrato.`}, ejecutivo:${this.state.executive}`+
                        `,nombreEjecutivo: ${this.state.nameExecutive},apellidoEjecutivo: ${this.state.lastnameExecutive} }`
     
                }
                 this.fP.createLiquidation(toSave).then(
                     response => { 
                         if( response.error.codigo === "0" ){
                             this.displayMessage.success("El elemento ha sido almacenado con exito");
                             this.resetFields();
                         }else{
                             this.displayMessage.error("Algo ha salido mal, verifica si los datos enviados son correctos, contactate con soporte de ser necesario");
                         }
                      }
                 ).catch(
                     error => {
                         
                         this.displayMessage.error("Ha ocurrido un error"+error);
      
                     }
                 );
     
               
        }else{
            this.showEmpty();
            disMessage.error("Existen Campos vacios")

        }
    }

    validateNull = (element) => {
        
        if (element.value.length === 0 || element.value === 0) {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: `Este campo es requerido`
            }
            const errors = {
                valid: { ...this.state.errors.valid, [element.name]: true },
            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        } else {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: ''
            }
            const errors = {
                valid: { ...this.state.errors.valid, [element.name]: false },


            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        }

    }

    isTouched = (name) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true }
        });
    }

    handleInputChange= async(event)=> {
        const name=event.target.name;
        const value =  event.target.value;
        await this.setState({
            [name]: value,
             
        });
        
        await this.isTouched(name)
       
        await this.validateNull({name: name, value: value});
        //await this.enDisButton();

        //this.findByIdentification(event.target.value);


    }

    hasError = (field) => {
        return (
            (this.state.errors.valid[field]) &&
            this.state.touched[field]);
    }

    visualValidation = () => {
        var Elements = document.forms["disaffiliationForm"].elements;
           
        for(var i =0; i<Elements.length ; i++){
            if((Elements[i].type==='text' || 
            Elements[i].type ==='number' ||
            Elements[i].type ==='date')
               
            ){                   
                if(Elements[i].value ===''){
                    this.setState({
                    [`${Elements[i].name}Error`]: true
                    });
                }else{
                    this.setState({
                       [`${Elements[i].name}Error`]: false
                    });
                }
                
            }
        }
    }

    render(){
        return(

                <Page>
                    <div className='absolute-container'>
                { 
                    this.state.userRequirement ? 
                    <GenericHeaderFoms
                    requirement={`REQ-${this.state.userRequirement.liqCodigo}`}
                    name={this.state.userRequirement.usuNombre}
                ></GenericHeaderFoms>:""
                }

                   <form id='disaffiliationForm'>

                   
                    <Card className='spacing-bottom'>
                    <Card >
                            <CardHeader
                            
                                title="1. Datos Afiliados"
                                color='red'
                                
                            />     
                    <Divider /> 
                    </Card> 
                    <Card >
                        <CardContent>

                        {    this.state.AllCompanies ? 
                                <PersonAutocomplete
                                        label = "Contratante Principal Empresa"

                                        setPerson = {this.setCompany}
                                        selectedElement = { this.state.company }
                                        data={this.state.AllCompanies}
                                        fieldName="option.empNombreComercial"
                                >
                                    </PersonAutocomplete>
                                    : <CircularProgress />

                                } 

                            
                        </CardContent>
                    </Card>




            <Card >
                <CardHeader 
                title="Titular"
                />
                    <Divider></Divider>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item  xs>

                            {    this.state.AllTitulars ? 
                            
                                <PersonAutocomplete
                                        label = "Cedula"
                                        
                                        setPerson = {this.setTitular}
                                        selectedElement = { this.state.titular }
                                        data={this.state.AllTitulars}
                                        fieldName="option.perIdentificacion"
                                        fieldName2="option.perNombres"

                                >
                                    </PersonAutocomplete>
                                    : <CircularProgress />

                                } 
                            

                            </Grid>
                            
                            <Grid item  xs>
                                <TextField value={this.state.name} name='name' id="standard-basic" label="Nombres" fullWidth />

                                    

                            </Grid>

                            

                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs>
                                 
                            <TextField id="standard-basic" label="Direccion" name="direction" value={this.state.direction} fullWidth />
                            </Grid>
                            <Grid item xs>
                                 
                            <TextField value={this.state.mail} name="mail" id="standard-basic" label="Correo Electronico" fullWidth />
                            </Grid>
                 
                        </Grid>

                    </CardContent>      
            </Card>

           


     


        </Card>
                    


        <Card >
                            <CardHeader
                            
                                title="2. Solicitud de desafiliacion"
                                color='red'
                                
                            />     
                    <Divider /> 

                        <CardContent>
                           
                    
                                <Grid container spacing={3} >
                                    <Grid item xs>
                                        <TextField id="Cliente" value={this.state.name} label="Nombre del Cliente" fullWidth /> 
                                    </Grid>
                                    <Grid item xs>
                                        <TextField id="Identificacion" label="Identificacion" value={this.state.identification} fullWidth /> 
                                    </Grid>
                                    <Grid item xs>
                                        <TextField id="contrato" label="Nº Contrano" value={this.state.polContrato } error={this.hasError('polContrato')} helperText={this.hasError('polContrato') ? this.state.errorText.polContrato : ''} fullWidth /> 

                                    </Grid>
                               
                                    
                                </Grid >
                                <Grid container spacing={3}>
                                <Grid item xs>
                                    <TextField id="standard-basic" label="Fecha" type='date' value={this.state.dateSelected} name='dateSelected' onChange={this.handleInputChange} fullWidth /> 

                                </Grid>
                                <Grid item xs>
                                    <TextField id="modalidadPago" label="Modalidad de Pago" name='payMethod' value={this.state.payMethod} onChange={this.handleInputChange}
                                        error={this.hasError('payMethod')} helperText={this.hasError('payMethod') ? this.state.errorText.payMethod : ''}                                    
                                        fullWidth />

                                </Grid>
                                </Grid>
                                <Grid container  >

                                    <Grid item xs>
                                        <TextField id="motivos" label="motivos"  name='reasonDisaffiliation' value={this.state.reasonDisaffiliation} onChange={this.handleInputChange}
                                        error={this.hasError('reasonDisaffiliation')} helperText={this.hasError('reasonDisaffiliation') ? this.state.errorText.reasonDisaffiliation : ''} 
                                        fullWidth/>

                                    </Grid>
                                </Grid>

                                <div className='medium-space-top'>
                                    <Typography  align='justify' variant="subtitle2" display="block" gutterBottom>
                                        {`Yo, 
                                        ${this.state.titular? this.state.titular.perNombres:''}
                                        
                                        con CI: 
                                        ${this.state.titular? this.state.titular.perIdentificacion:''}
                                        Titular del Contrato Nº 

                                        ${this.state.polContrato} 
                                        perteneciente al Contrato de Asistencia Medica 
                                        tomado en 
                                        ${this.state.dateSelected} 
                                        bajo la modalidad de pago 
                                        ${this.state.payMethod}, 
                                        solicito se realice la exclusión del Seguro por motivos 
                                        de
                                        ${this.state.reasonDisaffiliation}. 
                                        Una vez anulado el Contrato, no tendré derecho a atenciones médicas ambulatorias ni hospitalarias, ni poder enviar reembolsos con fechas posteriores a la culminación del Contrato.                                                                                                                                                             
                                        Para constancia de lo antes expuesto, acepto las condiciones y firmo al pie de este documento como validación de la cancelación del Seguro.`
                                            }
                                    </Typography>
                                    
                                    <Typography align='justify' variant="subtitle2" display="block" gutterBottom>
                                         NOTA: Adjunto copia de Cedula a color para validación de Identidad del Titular del Contrato.
                                        
                                    </Typography>
                                </div>
                                

                        </CardContent>




        </Card>
        <Card >
                <CardHeader 
                title="2. Recepcion de la Compañia"
                />
                    <Divider></Divider>
                    <CardContent>
                        <Grid container spacing={3}>
                            
                            <Grid item xs>
                                <TextField  name='executive' id="standard-basic" label="Ejecutivo" value={this.state.executive} onChange={this.handleInputChange}
                                    error={this.hasError('executive')} helperText={this.hasError('executive') ? this.state.errorText.executive : ''}
                                fullWidth />

                                       
                            </Grid>
                            <Grid item xs>
                                <TextField name='lastnameExecutive' id="standard-basic" label="Apellido" value={this.state.lastnameExecutive} onChange={this.handleInputChange}
                                    error={this.hasError('lastnameExecutive')} helperText={this.hasError('lastnameExecutive') ? this.state.errorText.lastnameExecutive : ''}
                                    fullWidth />

                                       
                            </Grid>
                            <Grid item  xs>
                                <TextField name='nameExecutive' id="standard-basic" label="Nombres" value={this.state.nameExecutive} onChange={this.handleInputChange}
                                    error={this.hasError('nameExecutive')} helperText={this.hasError('nameExecutive') ? this.state.errorText.nameExecutive : ''}
                                fullWidth />

                                    

                            </Grid>

                            

                        </Grid>

                       

                    </CardContent>      
            </Card>

           

                    <div className='right'>
                    
                            <Button 
                                variant="contained" 
                                color="primary" onClick={this.Digitalize} disabled={this.state.disabled} >Digitalizar
                            </Button>
                    </div>
                    </form>
                    </div>
                    <HelpDialog
                        text={`Por favor llene todos los campos, 
                        una vez todos los campos este llenos, el boton de digitalizar estara activo
                        `}
                        open={this.state.openHelp}
                        handleClose={this.handleCloseHelp}
                    
                    ></HelpDialog>
                </Page>
            );
    }
}