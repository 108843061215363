import { DataService } from 'providers/dataService'

export class DocumentoProvider {

    constructor() {
        this.dataService = new DataService();
    }

    findFile = ( docCodigo ) => {

        const body = {
            'tipoConsulta': 1,
            'entidad': 'Documento',
            'parametros': {
                'docCodigo': docCodigo
            }
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
         
 
        } 
            
        
        );

        return response;
        
    }

    findFile2 = ( liqCodigo ) => {

        const body = {
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                'liqCodigo': liqCodigo
            }
        }

        const response =  this.dataService.post('/consulta', body, '0101121').then( response => {
            
            return response.bodyOut.data;
         
 
        } 
            
        
        );

        return response;
        
    }

}