import { DataService } from 'providers/dataService'

export class QueriesProvider {

    constructor() {
        this.dataService = new DataService();
    }

    reportesAfiliados(value) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPOPB',
            parametros: {
                0: value,
                1: value,
                2: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

        /** ------------------------------------Pagos por cedula de la persona ----------------- */
    searchPaymentsByIdentification = identification => this.GenericOr(1, 'BUSPAG',{0: identification}, '0101010');
    HistoryPayments = identification => this.GenericOr(1, 'HISPAG',{0: identification}, '0101010');
    searchPaymentsByIdentification2 = identification => this.GenericOr(1, 'BUSCNP',{0: identification}, '0101010');
    

    searchOrCatalogDetail = (value) => {
        return this.GenericOr(1, 'BUSDET',{
            0:value,
            1:value,
            2:value,
        }, '0101010');

    }
    /** ------------------------------------Empresas en plan Broker ----------------- */
    findCompaniesPlanBroker = () => this.GenericOr(1, 'EMPPLB', {},'0101010')

    GenericOr = (consultType, entity, parameters, transaction) => {
        let body = {     
            tipoConsulta: consultType,
            entidad: entity,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, transaction);
    }

    reportesLiqAud(cedula, liqNemonico) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                perIdentificacion: cedula + '%',
                liqNemonico: '%' + liqNemonico + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    ///Mensajes Liquidacion

    message(liqCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'EstadoLiquidacion',
            parametros: {
                liqCodigo: liqCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    reporteTitularDependientes(value) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWRTP',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExportTitulares(typeExport, value) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWRTP',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    reporteTitularDependientesCancelados(value) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWRTPC',
            parametros: {
                0: value,
                1: value,
                2: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
    dataReportsExportTitularDependientesCancelados(typeExport, value) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWRTPC',
            parametros: {
                0: value,
                1: value,
                2: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }


    reportePagosAfiliados(value, filter, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPAAF',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: value,
                7: value,
                8: filter,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExportPagos(typeExport, value, filter, order, numero, inicio) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWPAAF',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: value,
                7: value,
                8: filter,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    eliminarTitularDependientes(pepCodigo) {
        let body = {
            tipoAccion: 2,
            entidad: 'PersonaPoliza',
            entidades: [{
                pepCodigo: pepCodigo,
                pepEstado: 0
            }]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    reporteProduccionConta(anio, mes) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPFN',
            parametros: {
                0: anio,
                1: mes
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    reportePagos(anio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPAG',
            parametros: {
                0: anio + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    paginacion2(filters, orderBy, skip, take) {
        let params = {}
        let count = 0;
        filters.map((item) => {
            params = {
                ...params,
                [count]: item
            }
            count++;
        });
        filters.map((item) => {
            params = {
                ...params,
                [count]: item
            }
            count++;
        });

        params = {
            ...params,
            [count + 1]: orderBy.field,
            [count + 2]: orderBy.orderDirection,
            [count + 3]: take,
            [count + 4]: skip,
        }

        let body = {
            tipoConsulta: 1,
            entidad: 'LIQPAG',
            parametros: params
        };


        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }


    searchOr(value) {
        let params = {
            0: value,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
        }

       

        let body = {
            tipoConsulta: 1,
            entidad: 'VWSPE',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    searchOrPlanExport(typeExport, value, orderBy, skip, take) {
        let params = {
            0: value,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
            8: value,
            9: value,

        }

        params = {
            ...params,
            10: orderBy.field,
            11: orderBy.orderDirection,
            12: take,
            13: skip,
        }

        let body = {
            tipoConsulta: typeExport,
            entidad: 'BUSPLA',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    searchOrPlan(value, orderBy, skip, take) {
        let params = {
            0: value,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
            8: value,
            9: value,

        }

        params = {
            ...params,
            10: orderBy.field,
            11: orderBy.orderDirection,
            12: take,
            13: skip,
        }

        let body = {
            tipoConsulta: 1,
            entidad: 'BUSPLA',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
    dataProductionExport(typeExport, anio, mes) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWPFN',
            parametros: {
                0: anio,
                1: mes
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    searchOrPoliza(empCodigo, value) {
        let params = {
            0: empCodigo,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
            8: value,
            9: value,
            10: value,
            11: value,
            12: value,
            13: empCodigo,
            14: value,
            15: value,
            16: value,
            17: value,
            18: value,
            19: value,
            20: value,
            21: value,
            22: value,
            23: value,
            24: value,
            25: value,

        }
        let body = {
            tipoConsulta: 1,
            entidad: 'VWSPOL',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExportPerson(typeExport, value, field, orderDirection, skip, take) {
        let params = {
            0: value,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
        }

        params = {
            ...params,
            8: field,
            9: orderDirection,
            10: take,
            11: skip,
        }

        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWSPE',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }


    dataReportsExportPoliza(empCodigo, typeExport, value, field, orderDirection, skip, take) {
        let params = {
            0: empCodigo,
            1: value,
            2: value,
            3: value,
            4: value,
            5: value,
            6: value,
            7: value,
            8: value,
            9: value,
            10: value,
            11: value,
            12: value,
            13: empCodigo,
            14: value,
            15: value,
            16: value,
            17: value,
            18: value,
            19: value,
            20: value,
            21: value,
            22: value,
            23: value,
            24: value,
            25: value,

        }

        params = {
            ...params,
            26: field,
            27: orderDirection,
            28: take,
            29: skip,
        }


        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWSPOL',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    searchPhone(value) {
        let params = {
            0: value,
        }
        let body = {
            tipoConsulta: 1,
            entidad: 'TELDET',
            parametros: params
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }



    reporteAcess(filtro, orden, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWRAC',
            parametros: {
                '0': filtro,
                '1': orden,
                '2': numero,
                '3': inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExport(typeExport, filtro, orden, numero, inicio) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWRAC',
            parametros: {
                '0': filtro,
                '1': orden,
                '2': numero,
                '3': inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    buscarHonorarioMed(plaCodigo,value) {
        let body = {
            tipoConsulta: 1,
            entidad: 'HONMED',
            parametros: {
                0: plaCodigo,
                1: value,
                2: value,
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    agedamientoDocuentos(detTipo, files, descripcion) {
        let filesNames = '';

        files.map(file => {
            console.log(file.name)
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            return 0;
        });

        let body = {
            tipoAccion: 1,
            entidad: 'Documento',
            entidades: [
                {
                    detTipo: detTipo,
                    docDescripcion: descripcion,
                    docNombre: filesNames
                }
            ]
        };

        let endpoint = '/multipart/documento';
        return this.dataService.postMultipart(endpoint, body, '0101122', files);
    }

    detalleCatalogo(catNemonico) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                detNemonico:catNemonico,
                detEstado:1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

}