import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Input,Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    search: {
        flexGrow: 1,
        height: 42,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.icon
    },
    searchInput: {
        flexGrow: 1
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const Search = props => {
    const { onSearch, onClean } = props;

    const classes = useStyles();

    

    const [valueSearch, setValueSearch] = React.useState('');
    const [valueSearch1, setValueSearch1] = React.useState('');
    const [valueSearch2, setValueSearch2    ] = React.useState('');

    const handleSearchChange = event => {
        setValueSearch(event.target.value);
    }

    const handleSearchChange1 = event => {
        setValueSearch1(event.target.value);
    }

    const handleSearchChange2 = event => {
        setValueSearch2(event.target.value);
    }

    const handleClick = () => {
        onSearch(valueSearch,valueSearch1,valueSearch2)
    }

    const handleClean = () => {
        setValueSearch('');
        setValueSearch1('');
        setValueSearch2('');
        onClean();  
    }

    return (
       
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                md={4}
                xs={12}
            >
                <Paper
                    className={classes.search}
                    elevation={1}
                >
                
                    <Input
                        className={classes.searchInput}
                        disableUnderline
                        onChange={handleSearchChange}
                        placeholder="Buscar por código"
                        value={valueSearch}
                    />
                   
                
                </Paper>
            </Grid>
            <Grid
                item
                md={4}
                xs={12}
            >
                <Paper
                    className={classes.search}
                    elevation={1}
                >
                
                    <Input
                        className={classes.searchInput}
                        disableUnderline
                        onChange={handleSearchChange1}
                        placeholder="Buscar por cédula"
                        value={valueSearch1}
                    />
                   
                
                </Paper>
            </Grid>
            <Grid
                item
                md={4}
                xs={12}
            >
                <Paper
                    className={classes.search}
                    elevation={1}
                >
                
                    <Input
                        className={classes.searchInput}
                        disableUnderline
                        onChange={handleSearchChange2}
                        placeholder="Buscar por nombre"
                        value={valueSearch2}
                    />
                   
                
                </Paper>
            </Grid>
                  
            <Grid
                item
                md={4}
                style={{padding: 0}}
                xs={12}
            >
                <Button
                    className={classes.searchButton}
                    onClick={handleClick}
                    size="large"
                    variant="contained"
                >
                    Buscar
                </Button>
                <Button
                    className={classes.searchButton}
                    color="primary"
                    onClick={handleClean}
                    size="large"
                    variant="contained"
                >
                    Limpiar
                </Button>                
            </Grid>
            
            
        </Grid>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func
};

export default Search;
