/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Chat from '@material-ui/icons/Chat';
import { Link as RouterLink } from 'react-router-dom';
import { ReportsProvider } from 'providers/reports.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { SearchBarCustom } from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Results = props => {

    const tableRef = React.useRef();
    const { className, data, ...rest } = props;
    const [valueSearch, setvalueSearch] = useState('')
    const [valueSearch1, setvalueSearch1] = useState('')
    const [valueSearch2, setvalueSearch2] = useState('')
    const classes = useStyles();
    const displayMessage = new DisplayMessage();
    const reportsProvider = new ReportsProvider();
    const [booleano, setBooleano] = useState(false);



    const loadData = (query, resolve) => {
        reportsProvider
            .paginacion('%', valueSearch, valueSearch1, valueSearch2, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    let dataClear = response.bodyOut.data;
                    if (response.bodyOut.data !== null) {
                        setBooleano(false)
                        dataClear.map((item) => {
                            if (item.existencia === 0) {
                                item.existencia = "Si"
                            } else {
                                item.existencia = "No"
                            }
                        })

                        reportsProvider
                            .dataTotal('%', valueSearch, valueSearch1, valueSearch2
                            )
                            .then(
                                response1 => {
                                    if (
                                        response.error.codigo === '0' &&
                                        response.bodyOut.data.length
                                    ) {

                                        resolve({
                                            data: response.bodyOut.data,
                                            page: query.page,
                                            totalCount: response1.bodyOut.data[0].count
                                        });
                                    } else {

                                    }
                                },
                                () => {
                                    displayMessage.error('Error de comunicación.');
                                }
                            );
                    } else {
                        setBooleano(true)
                    }

                },
                () => {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );
    }

    const handleSearch = (valueSearch, valueSearch2, valueSearch3) => {
        if (valueSearch !== '' || valueSearch2 !== '' || valueSearch3 !== '') {
            tableRef.current.onSearchChange(valueSearch);
            tableRef.current.onSearchChange(valueSearch2);
            tableRef.current.onSearchChange(valueSearch3);
            setvalueSearch(valueSearch)
            setvalueSearch1(valueSearch2)
            setvalueSearch2(valueSearch3)
        } else {
            displayMessage.error("Ingrese un campo a buscar")
        }
        //setBooleano(true)


    };

    const handleClean = () => {
        tableRef.current.onQueryChange();
        setBooleano(false)
        setvalueSearch('');
        setvalueSearch1('');
        setvalueSearch2('')
    };


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <SearchBarCustom onClean={handleClean} onSearch={handleSearch} />
            <br />
            <Card>
                <CardHeader
                    title="Liquidaciones"
                />
                <Divider />
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <MaterialTable
                                tableRef={tableRef}
                                actions={[
                                    {
                                        icon: 'save',
                                        tooltip: 'Save User',
                                        onClick: () => { }
                                    }
                                ]}
                                columns={[
                                    { title: 'Código', field: 'liqNemonico', width: 100 },
                                    { title: 'Nombres', field: 'usuNombre' },
                                    { title: 'Cédula', field: 'perIdentificacion', width: 100 },
                                    { title: 'Estado', field: 'detNombre', width: 100 },
                                    { title: 'Preexistencia', field: 'existencia', width: 100 },
                                    { title: 'Fecha de registro', field: 'liqFechaRegistro', width: 300 },
                                    { title: 'Fecha último mensaje', field: 'fechaultimamsg', width: 300 },
                                ]}
                                components={{
                                    Action: props => (
                                        <>
                                            <Button
                                                color="primary"
                                                component={RouterLink}
                                                size="small"
                                                style={{ textTransform: 'none', marginRight: 10 }}
                                                to={`/liquidador/digitalizarVer/${btoa(props.data.liqCodigo)}`}
                                                variant="contained"
                                            >
                                                <VisibilityIcon className={classes.getAppIcon} />
                                            Ver
                                            </Button>
                                            <Button
                                                color="primary"
                                                component={RouterLink}
                                                //onClick = {handleOpen(props.data.liqCodigo)}
                                                size="small"
                                                style={{ textTransform: 'none' }}
                                                to={`/liquidador/historial/${btoa(props.data.liqCodigo)}`}
                                                variant="contained"
                                            >
                                                <Chat className={classes.getAppIcon} />
                                         Mensajes
                                            </Button>
                                        </>
                                    ),
                                }}
                                data={booleano === false ? (query =>
                                    new Promise((resolve, reject) => {
                                        loadData(query, resolve)

                                    })) : []}
                                localization={{
                                    header: {
                                        actions: 'Acción'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'Filas',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'
                                    }
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    actionsCellStyle: {
                                        width: 100,
                                        textAlign: 'center'
                                    },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    search: false,
                                    toolbar: false
                                }}
                            />
                        </div>
                    </PerfectScrollbar>
                </CardContent>
            </Card>
        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string
};

export default Results;