import React, { Component } from 'react';
import { Page } from 'components';
import { Results } from './components';
import { DisplayMessage } from 'notifications/displayMessage';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export default class CreatePolicyView extends Component {
    constructor(props){
        super(props);
        this.displayMessage = new DisplayMessage();
        this.state = {hasError: false};
    }
    componentDidCatch = (error) => {
        this.setState({hasError: true});
        this.displayMessage.error(`Error: ${error}`)
    }

    render(){
        const { hasError } = this.state;
        if(hasError){
            return(
                <Alert severity="error">
                    <Typography
                        component="h6"
                    >
                        <b>Error de comunicación y/o datos</b>
                    </Typography>
                </Alert>
            )
        }
        return(
            <Page title='Crear póliza'>
                <div className='absolute-container'>
                    <Results/>
                </div>
            </Page>
        )

    }

}