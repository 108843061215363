import React from 'react';
import { useSelector } from 'react-redux';
import { Page, GenericHeader } from 'components';
import { Result } from './components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
})); 
const RecordPayment = () => {
    
    const session = useSelector(state => state.session);  
    const classes = useStyles();  

    return (
        <Page title='Historial de Pagos' className={classes.root}>
            <GenericHeader title='Historial de Pagos'/>
            <br/>
            <Result session={session} />
        </Page>
    );
};

export default RecordPayment;
