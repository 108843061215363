import React, { Component } from 'react';
import { PolizaProvider } from 'providers/poliza.service';
import { QueriesProvider } from 'providers/queries.service';
import { Link as RouterLink } from 'react-router-dom';
import { PlanProvider } from 'providers/plan.service';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { FormUpdatePolicy } from '../';
import { validateOnlyLetters } from '../../../../services/validation';
import { SearchBasic } from 'components';
import { DisplayMessage } from 'notifications/displayMessage';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Grid, Tooltip, FormControl, InputLabel, Select, MenuItem, Card, CardContent } from '@material-ui/core';
import { Add, GetApp } from '@material-ui/icons';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { PlanBrokerProvider } from 'providers/planBroker.service';

const styles = () => ({
});

class PolicyList extends Component {
    constructor(props) {
        super(props)
        const { session } = props;
        this.state = {
            polisSearch:[],
            columns: [
                { title: 'Póliza', field: 'polContrato' },
                { title: 'Identificación', field: 'perIdentificacion' },
                { title: 'Nombres', field: 'perNombres' },
                { title: 'Apellidos', field: 'perApellidos' },
                { title: 'Fecha de registro', field: 'polFechaRegistro' },
                { title: 'Fecha de cambio', field: 'polFechaCambio' },


            ],
            formFrontToBack: [
                { back: 'POL_CONTRATO', front: 'polContrato' },
                { back: 'PER_IDENTIFICACION', front: 'perIdentificacion' },
                { back: 'PER_NOMBRES', front: 'perNombres' },
                { back: 'PER_APELLIDOS', front: 'perApellidos' },
                { back: 'POL_FECHA_REGISTRO', front: 'polFechaRegistro' },
                { back: 'POL_FECHA_CANCELA', front: 'polFechaCancela' },
                { back: 'POL_FECHA_INICIO', front: 'polFechaInicio' },
                { back: 'POL_FECHA_FIN', front: 'polFechaFin' },
                { back: 'POL_FECHA_CAMBIO', front: 'polFechaCambio' },
                { back: 'PLA_NOMBRE', front: 'plaNombre' },
                { back: 'PLA_PRODUCTO', front: 'plaProducto' },
                { back: 'DET_NOMBRE', front: 'detNombre' },


            ],
            allStatesPlans: [],
            allPolicyes: [],
            typePlan: '',
            allCompanies: [],
            tipoPlan: [],
            allPlans: [],
            plan: null,
            polCodigo: null,
            polEstado: 1,
            polContrato: '',
            polDescripcion: '',
            openUpdateModal: false,
            searchBar: '',
            statePlan: 0,
            disabled: {
                polEstado: false,
                statePlan: false,
                polDescripcion: false,
                typePlan: false,
                plan: false,
                buttonSave: false
            },
            count: 0,
            booleano: false,
            total: 0, /**---------------tabla */
            session: session,
            empCodigo: 2 /** ----------empCodigo por defecto para el select */
        }
        this.tableRef = React.createRef();

        this.planProvider = new PlanProvider();
        this.planBrokerProvider = new PlanBrokerProvider();
        this.polizaProvider = new PolizaProvider();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.queriesProvider = new QueriesProvider();
        this.displayMessage = new DisplayMessage();
        this.detalleCatalogoProvider = new DetalleCatalogoProvider();

    }

    handleChangeEmpresa = e => {
        console.log(e.target.value)
        this.changeOneState('empCodigo',e.target.value);
        //this.tableRef.current.onQueryChange();
        
    }
    currentChange = () => {
        this.tableRef.current.onQueryChange();
    }
    getAllCompanies = () => {
        this.queriesProvider.findCompaniesPlanBroker().then(
            response => {
                    this.changeOneState("allCompanies", response.bodyOut.data);
            },
        );

    }
    componentDidMount = () => {
        this.getAllCompanies();
        /* this.tableRef.current.onQueryChange(); */
        this.getAllDetCatalogo('ESTPOL', 'allStatesPlans');/** Estados de la poliza */

    }

    /** ----------------------------------------Traer todas lOS Detalle Catalogo por catCodigo*/
    getAllDetCatalogo = (catNemonico, nameData) => {
        this.detalleCatalogoProvider.findDetalleCatalogoByNemonico(catNemonico).then(
            response => {
                this.changeOneState(nameData, response)
            }
        );
    }

    /** -----------------------------------En futuro debe mejorar el buscador */
    searchAlgorithm = (value) => {
        this.setState({ searchBar: value });
    }
    /** ------------------------------------ Utilizar el buscador */
    handleSearch = () => {
        const { searchBar } = this.state;
      /*   this.tableRef.current.onSearchChange(searchBar); */
        this.handleSearchPolicy(searchBar);

    }
    handleSearchPolicy =async (search) =>{
        const { searchBar, formFrontToBack, polisSearch } = this.state;

        await this.queriesProvider.searchOrPoliza(this.state.empCodigo,search.toUpperCase()).then(
            response => {
                if (response.error.codigo === '0' && response.bodyOut.data.length) {
                    this.setState({ booleano: false, polisSearch: response.bodyOut.data });
                } else {
                    this.setState({ booleano: false });
                    this.displayMessage.error('No se encontró resultados')
                   
                }

            }
        ).catch(
            () => {
                this.setState({ booleano: false });
                   
            }
        );
    }

    concatUniquePolicyes = (nameState, newArray,) => {
        let arrayElements = this.state.allPolicyes;
        arrayElements = arrayElements.concat(newArray);
        let uniqueElements = [...new Set(arrayElements)];
        this.changeOneState(nameState, uniqueElements);
    }

    /** ----------------------------------- Limpiar el buscador */
    cleanSearchBar = () => {
        this.changeOneState('searchBar', '');
        this.changeOneState('booleano', false);
        this.changeOneState('polisSearch', []);

       /*  this.tableRef.current.onQueryChange(); */
    }
    /** ------------------------------------Categorias */
    getCategoryPlans = (empCodigo) => {
        this.planProvider.findCategories(empCodigo).then(
            response => {
                this.changeOneState('tipoPlan', response);
            }
        );
    }

    /** ----------------------------------------------Cambiar Plan*/
    onChangePlan = (event, values) => { this.changeOneState('plan', values); }


    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState, value) => {
        this.setState({
            [nameState]: value
        })
    }

    /**-----------------------------------------------Cargar datos en el modal */
    setData = (polCodigo) => {
        this.polizaProvider.findByPolCodigo(polCodigo).then(
            (policyes) => {
                const policy = policyes[0];
                if (policy.detEstado === 23531) {
                    this.disableAllFields();
                } else {
                    this.enableAllFields();
                }
                this.setState(
                    {
                        statePlan: policy.detEstado,
                        polDescripcion: policy.polDescripcion,
                        polCodigo: policy.polCodigo,
                        polEstado: policy.polEstado,
                        polContrato: policy.polContrato
                    }
                )
            }
        )
    }


    getPlanByPlaCodigo = (plaCodigo) => {
        this.planBrokerProvider.findPlanByPlaCodigo(plaCodigo).then(
            response => {
                const plan = response[0];
                this.setState({
                    plan: plan,
                    typePlan: plan.plaNombre

                });
                this.getAllPlanes(plan.plaNombre);
            }
        );
    }
    /** ---------------------------------- Clean FIelds modal */
    cleanFields = () => {
        this.setState(
            {
                polContrato: '',
                polDescripcion: '',
                plan: null,
                typePlan: '',
                polCodigo: null,
                statePlan: 0,
                polEstado: 1,
                disabledForm: false,

            }
        )
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {

            case 'broker':
                if (validateOnlyLetters(value)) {
                    this.changeOneState(name, value);
                }
                break;
            case 'typePlan':
                this.getAllPlanes(value);
                this.changeOneState(name, value);
                this.changeOneState('plan', null)
                break
            case 'statePlan':
                if (value === 23531) {

                    this.changeOneState(name, value);
                    this.changeOneState('polEstado', 0);
                } else {
                    this.changeOneState(name, value);
                }
                break
            case 'polEstado':
                if (this.state.statePlan === 23531) {
                    if (value === 1) {
                        this.displayMessage.warn('No es posible activar una póliza cancelada');
                    } else {
                        this.changeOneState('polEstado', value);
                    }
                } else {
                    this.changeOneState(name, value);
                }
                break
            default:
                this.changeOneState(name, value);
                break;
        }
    }

    /** ----------------------------------------Traer todos los Planes */
    getAllPlanes = async (nombre) => {
        await this.changeOneState('allPlans', []);
        this.planBrokerProvider.findAllPlanBrokerByPlaNombre(nombre).then(
            response => {
                this.setState({
                    allPlans: response
                })
            }
        );

    }

    disableAllFields = () => {
        const disabledAll = {
            polEstado: true,
            statePlan: true,
            polDescripcion: true,
            typePlan: true,
            plan: true,
            buttonSave: true
        }
        this.changeOneState('disabled', disabledAll);

    }
    enableAllFields = () => {
        const enabledAll = {
            polEstado: false,
            statePlan: false,
            polDescripcion: false,
            typePlan: false,
            plan: false,
            buttonSave: false
        }
        this.changeOneState('disabled', enabledAll);

    }
    setOpenUpdateModal = (state, data) => {
        if (state === true) {
            this.setData(data.polCodigo);
            this.getCategoryPlans(data.empCodigo);
            this.getPlanByPlaCodigo(data.plaCodigo);

        }
        if (state === false) {
            this.changeOneState('polCodigo', 0);
        }
        this.changeOneState('openUpdateModal', state);

    }

    /** -------------------------------------- contador de todas las polizas */
    handleCount = count => {
        this.setState({ count: count })
    }
    /** -----------------------------------Traer todas las personas */
    getAllPolicy = async () => {
        this.searchAlgorithm('');
    }

    /** ----------------------------------traer datos para la tabla */
    /* loadData = async (query, resolve) => {
        const { searchBar, formFrontToBack } = this.state;
        let filterBack = 'POL_CONTRATO';
        if (query.orderBy) {
            const backFounded = await formFrontToBack.find(element => element.front === query.orderBy.field);
            filterBack = await typeof (backFounded) !== 'undefined' ? backFounded.back : 'PER_CODIGO';
        }
        const orderBy = await {
            field: await filterBack,
            orderDirection: await query.orderBy ? query.orderDirection : 'asc'
        }

        await this.queriesProvider.searchOrPoliza(this.state.empCodigo,searchBar.toUpperCase(), orderBy, query.pageSize * (query.page), query.pageSize).then(
            response => {
                if (response.error.codigo === '0' && response.bodyOut.data.length) {
                    this.handleCount(response.bodyOut.data[0].TOTAL);
                    this.setState({ booleano: false });
                    resolve({
                        data: response.bodyOut.data,
                        page: query.page,
                        totalCount: response.bodyOut.data[0].TOTAL
                    });
                } else {
                    this.setState({ booleano: false });
                    this.handleCount(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                }

            }
        ).catch(
            () => {
                this.setState({ booleano: false });
                    this.handleCount(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
            }
        );
    } */

    dataReportsExport = (typeResult) => {

        this.queriesProvider.dataReportsExportPoliza(this.state.empCodigo ,typeResult, this.state.searchBar, 'POL_CONTRATO', 'asc', 0, this.state.count).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                this.downloadFile(response.bodyOut.data, typeResult);
            }
        },
        () => {
            //displayMessage.error('Error de comunicación.');

        });
    }

    downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].BUSCADOR_POLIZA}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    render() {
        const { allStatesPlans, openUpdateModal, polCodigo, polEstado, polDescripcion, polContrato, plan, tipoPlan, allPlans, typePlan, statePlan, booleano } = this.state;
        let recorrer = 0;
        return (
            <div>
                <FormUpdatePolicy
                    statePlan={statePlan}
                    allStatesPlans={allStatesPlans}
                    setOpenUpdateModal={this.setOpenUpdateModal}
                    open={openUpdateModal}
                    polCodigo={polCodigo}
                    polEstado={polEstado}
                    polDescripcion={polDescripcion}
                    polContrato={polContrato}
                    handleInputChange={this.handleInputChange}
                    cleanFields={this.cleanFields}
                    getAllPolicy={this.getAllPolicy}
                    plan={plan}
                    allTypePlans={tipoPlan}
                    allPlans={allPlans}
                    typePlan={typePlan}
                    onChangePlan={this.onChangePlan}
                    disabled={this.state.disabled}
                    enableAllFields={this.enableAllFields}
                    disableAllFields={this.disableAllFields}
                    userrole={this.state.session.user.role}
                    currentChange={this.currentChange}
                />
                <Grid
                    container
                    spacing={2}
                >

                    <Grid
                        item md={3} sm={12} xs={12} style={{ marginRight: 185 }}
                    >
                        <SearchBasic
                            searchBar={this.state.searchBar}
                            cleanSearchBar={this.cleanSearchBar}
                            handleInputChange={this.handleInputChange}
                            complementaryMessage='Buscar'
                            handleSearch={this.handleSearch}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth>
                        <InputLabel id='empresaLabel'>
                            Empresa
                        </InputLabel>
                        <Select
                            style={{
                                backgroundColor: 'white'
                            }}
                            labelId='empresaLabel'
                            name="empresa"
                            label='Empresa'
                            onChange={e => this.handleChangeEmpresa(e)}
                            value={this.state.empCodigo}>
                            <MenuItem value={'%'}>
                                <em>Seleccione...</em>
                            </MenuItem>
                            {this.state.allCompanies.map(item => (
                                <MenuItem
                                    key={'empresa' + item.empCodigo}
                                    value={item.empCodigo}>
                                    {item.empRazonSocial}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid
                    item md sm={12} xs={12}
                >
                    <Grid container justify="flex-end">
                            <Button
                                component={RouterLink}
                                to={'/administrador/agregar-poliza'}
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{marginRight: 10}}
                            >
                                <Add />  CREAR PÓLIZA
                            </Button>
                            <Tooltip title="Exportar a CSV">
                                <Button
                                    style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => { this.dataReportsExport(2); }}
                                >
                                    <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                                </Button>
                            </Tooltip>
                            <Tooltip title="Exportar a EXCEL">
                                <Button
                                    style={{ borderRadius: 5,  width: 100}}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => { this.dataReportsExport(3); }}
                                >
                                    <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
                                </Button>
                            </Tooltip>

                    </Grid>
                </Grid>
                </Grid>


                <br />

                <Card>
                <MaterialTable
                    tableRef={this.tableRef}

                    columns={[
                { title: 'Póliza', field: 'polContrato' },
                { title: 'Identificación', field: 'perIdentificacion' },
                { title: 'Nombres', field: 'perNombres' },
                { title: 'Apellidos', field: 'perApellidos' },
                { title: 'Fecha de registro', field: 'polFechaRegistro' },
                { title: 'Fecha de cancelaciòn', field: 'polFechaCancela' },
                { title: 'Fecha de cambio', field: 'polFechaCambio' },
                { title: 'Fecha de inicio', field: 'polFechaInicio' },
                { title: 'Fecha fin', field: 'polFechaFin' },
                { title: 'Plan', field: 'plaNombre' },
                { title: 'Producto', field: 'plaProducto' },
                { title: 'Estado', field: 'detNombre' },



            ]}
                    data={this.state.polisSearch}
                    title="Beneficiarios"
                    localization={{
                        pagination: {
                            labelRowsSelect: 'filas',
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                            searchPlaceholder: 'Buscar'

                        },
                        header: {
                            actions: 'Acción'
                        },
                        body: {
                            editRow: {
                                deleteText: '¿Estás seguro de eliminar este registro?'
                            },
                            emptyDataSourceMessage: 'No hay registros que mostrar',
                            filterRow: {
                                filterTooltip: 'Filter'
                            },

                        }
                    }}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Actualizar',
                            onClick: (event, rowData) => this.setOpenUpdateModal(true, rowData)
                        }
                    ]}

                    components={{
                        Action: props => (
                            <Button color="primary" style={{ textTransform: 'none', marginRight: 10 }} onClick={(event) => props.action.onClick(event, props.data)} size="small" variant="contained">
                                <VisibilityIcon />{this.state.session.user.role === 'ADMIN' ? 'Ver' : 'Ver'}
                            </Button>

                        ),
                    }}


                    options={{
                        search: false,
                        paging: true,
                        emptyRowsWhenPaging: false,
                        actionsCellStyle: {
                            width: 100,
                            textAlign: 'center'
                        },
                        headerStyle: {
                            fontWeight: 'bold'
                        },
                        toolbar: false,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 50],
                        rowStyle: () => {
                            recorrer = recorrer + 1;
                            return ({
                                backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                            })
                        },
                    }}

                />
                </Card>
               
            </div>
        )

    }


}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps, null)(withStyles(styles)(PolicyList));