/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    TextField,
    Button,
    colors,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    FormHelperText
} from '@material-ui/core';
import validate from 'validate.js';
import NumberFormatMoney from 'components/NumberFormatMoney';
import { DisplayMessage } from 'notifications/displayMessage';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';


const schema = {
    tarNombre: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },
    detTipo: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },
    tarAuxiliar: {
        presence: { allowEmpty: false, message: '^Requerido' }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    container: {
        marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const ModalAddTarifario = props => {
    const { open, resetData, onClose, className, ...rest } = props;
    const classes = useStyles();
    const [eventSelected, setEventSelected] = React.useState(true);
    const prestaciones = new DocumentDigitalizeProvider();
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const displayMessage = new DisplayMessage();

    useEffect(() => {
        setFormState({
            isValid: false,
            values: {},
            touched: {},
            errors: {}
        });
    }, [resetData]);

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}            
        }));        
    }, [formState.values]);

    const handleChange = event => {
        if(event.target.name !== 'tarAuxiliar'){
            event.persist();
        }        
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    if (!open) {
        return null;
    }        
            
    const handleSaveTarifario = () => {
        if(!formState.isValid){        
            const errors = validate(formState.values, schema);
            setFormState(formState => ({
                ...formState,
                isValid: errors ? false : true,
                errors: errors || {},
                touched: errors || {}
            }));  
            setEventSelected(!eventSelected);     
            displayMessage.error('Complete los campos');
        }else{
            let data = formState.values;
            data = { ...data,  tarEstado: 1 };
            prestaciones.ingresoTarifario(data)
                .then(
                    data => {
                        if(data.error.codigo === '0') {
                            displayMessage.success('Tarifario registrado.')                            
                            onClose()                        
                        }
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');

                    }

                );
        }
    }

    return (
        <Modal
            onClose={onClose}
            open={open}
        >
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <form>
                    <CardContent>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                        >
                            Agregar Tarifario
                        </Typography>
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid
                                item                                
                                xs={12}
                            >
                                <TextField
                                    error={hasError('tarNombre')}
                                    fullWidth                   
                                    helperText={hasError('tarNombre') ? formState.errors.tarNombre[0] : null}                 
                                    label="Nombre"
                                    name="tarNombre"
                                    onChange={handleChange}                                    
                                    value={formState.values.tarNombre || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <FormControl 
                                    error={hasError('detTipo')}
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                >
                                    <InputLabel id="demo-simple-select-outlined-label">Tipo tarifario</InputLabel>
                                    <Select
                                        label="Tipo tarifario"
                                        labelId="demo-simple-select-outlined-label"
                                        name="detTipo"
                                        onChange={handleChange}
                                        value={formState.values.detTipo || ''}                                    
                                    >
                                        <MenuItem value={''}>
                                            <em>Seleccione...</em>
                                        </MenuItem>
                                        <MenuItem value={23541}>TARIFARIO MEDICINA</MenuItem>
                                        <MenuItem value={31}>TARIFARIO NACIONAL</MenuItem>                                        
                                    </Select>
                                    {hasError('detTipo') ? (<FormHelperText>{formState.errors.detTipo[0]}</FormHelperText>): null}
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField   
                                    InputProps={{
                                        inputComponent: NumberFormatMoney,
                                        inputProps: { 
                                            style: { textAlign: 'right'},
                                            decimalScale: 2,
                                        }
                                    }}                                 
                                    error={hasError('tarAuxiliar')}
                                    fullWidth
                                    helperText={hasError('tarAuxiliar') ? formState.errors.tarAuxiliar[0] : null}
                                    label="Valor"
                                    name="tarAuxiliar"                 
                                    onChange={handleChange}
                                    value={formState.values.tarAuxiliar || ''}
                                    variant="outlined"                                
                                />
                            </Grid>                                                                                                            
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            onClick={onClose}
                            variant="contained"
                        >
                            Cerrar
                        </Button>
                        <Button
                            className={classes.saveButton}
                            onClick={() => handleSaveTarifario()}
                            variant="contained"
                        >
                            Guardar
                        </Button>
                    </CardActions>
                </form>
            </Card>
        </Modal>
    );
};

export default ModalAddTarifario;
