import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { DatePicker } from '@material-ui/pickers';
import 'moment/locale/es';
import { CircularProgress, Card, CardActions, Grid, Button } from '@material-ui/core';
import moment from 'moment';
//import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

moment.locale('es')
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));



export default function SearchAffiliate() {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [selectedDate, handleDateChange] = useState(moment());
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Cédula', field: 'CEDULA' },
        { title: 'Nombre', field: 'NOMBRE' },
        { title: 'Tipo cuenta', field: 'TIPOCUENTA' },
        { title: 'Número cuenta', field: 'NUMEROCUENTA' },
        { title: 'Valor', field: 'VALOR' },
        { title: 'Banco', field: 'BANCO' },
        { title: 'RUC', field: 'RUC_COBRA' },
        { title: 'Observaciones', field: 'OBSERVACIONES' },
        { title: 'Base impuesta imprenta', field: 'BASEIMPOSIBLE_IMPRENTA' },
        { title: 'Base impuesta IVA', field: 'BASEIMPOSIBLE_IVA' },
        { title: 'Fecha nacimiento', field: 'PAG_FECHA_VENCIMIENTO' },
        { title: 'Estado', field: 'DET_NOMBRE' }

    ]);
    const [buscarMes, setBuscarMes] = useState(moment());

    const handleBuscarTitular = () => {
        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM');
        } else {
            subCadena = buscarMes.format('MM');
        }
        setData(0);
        if (selectedDate === '' && buscarMes === '') {
            displayMessage.error('Seleccione un año o mes.');
        } else {
            const fecha = selectedDate.format('YYYY') + `-${subCadena}`
            queries.reportePagos(fecha)
                .then(
                    user => {

                        if (user.error.codigo === '0') {
                            setData(user.bodyOut.data);
                        }
                        else {
                            setData([])
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');

                    }
                );
        }
    }

    const handleLimpiar = () => {
        handleDateChange(moment());
        setBuscarMes(moment());
        setData([]);
    }

    /*  const dataReportsExport = (typeResult) => {
         const displayMessage = new DisplayMessage();
         let subCadena = '';
         if(buscarMes.format('MM').substring(0, 1) === '0'){
             subCadena = buscarMes.format('MM').substring(1, 2);
         }else{
             subCadena = buscarMes.format('MM');
         }
         if(selectedDate=== '' && buscarMes === ''){
             displayMessage.error('Seleccione un año o mes.');
         }else{
             queries.dataProductionExport(typeResult,selectedDate.format('YYYY'),`${subCadena}`).then(response => {   
                 if(response.error.codigo === '0' && response.bodyOut.data.length){
                     downloadFile(response.bodyOut.data, typeResult);
                 }                  
             },
     
             () => {          
                 displayMessage.error('Error de comunicación.');  
             });  
         } 
     };
     
 
     const downloadFile = (data, typeFile) => {
         typeFile = typeFile === 2 ? 'csv' : 'xls';
         var path=`data:application/octet-stream;base64,${data[0].PROD_FNC}`;              
         var link = document.createElement('a'); 
         link.setAttribute('href',path);
         link.setAttribute('download',`Reporte_Produccion.${typeFile}`);
         link.setAttribute('target','_blank');
         document.body.appendChild(link)
         link.click();        
         document.body.removeChild(link)
     } */

    return (
        <div>
            <Card>
                <CardActions>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={2}
                                sm={2}
                                xs={6}
                            >
                                <DatePicker
                                    inputVariant="outlined"
                                    label="Seleccione año"
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    variant="inline"
                                    views={['year']}
                                />

                            </Grid>
                            <Grid
                                //className={classes.item}
                                item
                                md={2}
                                sm={2}
                                xs={6}
                            >
                                <DatePicker
                                    format="MMMM"
                                    inputVariant="outlined"
                                    label="Seleccione mes"
                                    onChange={setBuscarMes}
                                    value={buscarMes}
                                    views={['month']}
                                />
                            </Grid>
                            <Grid
                                item
                                sm={2}
                                xs={6}
                            >

                                <Button
                                    color="primary"
                                    onClick={handleBuscarTitular}
                                    variant="contained"
                                >Buscar</Button>


                            </Grid>
                            <Grid

                                item
                                sm={2}
                                xs={6}
                            >

                                <Button
                                    color="primary"
                                    onClick={handleLimpiar}
                                    variant="contained"
                                >Limpiar</Button>


                            </Grid>
                        </Grid>
                    </div>

                </CardActions>
            </Card><br />
            {data ?
                data.length ?
                    <Card>
                        <MaterialTable
                            /* actions={[
                                {
                                    icon: () => <FaFileCsv />,
                                    tooltip: 'Exportar a CSV',
                                    isFreeAction: true,
                                    onClick: () => {dataReportsExport(2);}
                                },
                                {
                                    icon: () => <FaFileExcel />,
                                    tooltip: 'Exportar a XLS',
                                    isFreeAction: true,
                                    onClick: () => {dataReportsExport(3);}
                                }
                            ]} */
                            columns={columns}
                            data={data}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination: {
                                    labelRowsSelect: 'Filas',
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'


                                }
                            }}
                            options={{ exportButton: false, search: false, toolbar: false }}
                            title="Producción"
                        />
                    </Card> : ''
                : <CircularProgress />}
        </div>
    );
}