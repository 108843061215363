import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button
} from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';
import { useSelector } from 'react-redux';
import { DocumentoProvider } from 'providers/documento.service';
import { DisplayMessage } from 'notifications/displayMessage';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
    },
    inner: {
       
        
    },
    container: {
        maxHeight: 200,
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

const LiquidationDocuments = props => {
    const { documents, liqCode,className, ...rest } = props;
    const [loading, setLoading] = useState({})
    const [arregloArchivo, setArregloArchivo] = useState([]);
    const classes = useStyles();
    const session = useSelector(state => state.session);
    const [download, setDownload] = useState(false)

    const downloadFile = (fileId, index) => {
        const documentoProvider = new DocumentoProvider();
        const displayMessage = new DisplayMessage();
        setLoading(loading => ({
            ...loading,
            [index]: true
        }));
       
        documentoProvider.findFile(fileId).then(
            async(response) => {
                if(typeof(response[0].documento)!=='undefined' && response[0].documento!==null){
                    var path=`data:application/octet-stream;base64,${response[0].documento}`;
                    var link = await document.createElement('a'); 
                    await link.setAttribute('href',path);
                    await link.setAttribute('download',response[0].docNombre);
                    await link.setAttribute('target','_blank');
                    await document.body.appendChild(link)
                    await link.click();                
                    await document.body.removeChild(link)
                 
                    setLoading(loading => ({
                        ...loading,
                        [index]: false
                    }));
                }else{
                    displayMessage.error('Ha ocurrido un error, es probable que el archivo no se encuentre en la base.')
                  
                    setLoading(loading => ({
                        ...loading,
                        [index]: false
                    }));
                }
            }
        )
    }

    const downloadFile2 = (liqCode) => {
        const documentoProvider = new DocumentoProvider();
        const displayMessage = new DisplayMessage();
       setDownload(true);
        documentoProvider.findFile2(liqCode).then(
            async(response) => {
          
                if(typeof(response[0].documento)!=='undefined' && response[0].documento!==null){
                    var path=`data:application/octet-stream;base64,${response[0].documento}`;
                    var link = await document.createElement('a'); 
                    await link.setAttribute('href',path);
                    await link.setAttribute('download','Archivos.rar');
                    await link.setAttribute('target','_blank');
                    await document.body.appendChild(link)
                    await link.click();                
                    await document.body.removeChild(link)
                    setDownload(false)
                    
                }else{
                    displayMessage.error('Ha ocurrido un error, es probable que el archivo no se encuentre en la base.')
                    setDownload(false)
                    
                }
            
            }
        )
    }
  
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader title="Documentos" />
            <Divider />
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <TableContainer className={classes.container}>
                           
                            <Table stickyHeader>
                                
                                <TableHead>
                                    <TableRow>
                                        
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Acción</TableCell>
                                        <TableCell>
                                            {(session.user.role==='AUDITOR' || session.user.role==='LIQUIDADOR') && 
                                            
                                            !download ?
                                            <Button 
                                                color="primary" 
                                                onClick={()=>downloadFile2(liqCode)} 
                                                variant="contained"
                                            >
                                                <GetAppIcon className={classes.getAppIcon} />
                                                    Descargar Todo
                                            </Button>:<CircularProgress/> 
                                            }
                                        </TableCell> 
                                    </TableRow>
                                    
                                </TableHead>
                                <TableBody>
                                    {documents.map((item, index) => (
                                        <TableRow 
                                            hover 
                                            key={item.docCodigo}
                                        >
                                            <TableCell>
                                                {item.docNombre}
                                            </TableCell>
                                            <TableCell>
                                                {!loading[index] && (
                                                    <Button 
                                                        color="primary" 
                                                        onClick={()=>downloadFile(item.docCodigo, index)}
                                                        variant="contained"
                                                    >
                                                        <GetAppIcon className={classes.getAppIcon} />
                                                        Descargar 
                                                    </Button> 
                                                )}
                                                {loading[index] && (
                                                    <CircularProgress />
                                                )}
                                            </TableCell>
                                           <TableCell></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                   
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
};

LiquidationDocuments.propTypes = {
    className: PropTypes.string,
    documents: PropTypes.any.isRequired
};

export default LiquidationDocuments;
