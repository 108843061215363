import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    toolbar: {
        '& > * + *': {
            marginLeft: theme.spacing(1)
        }
    }
}));

const Header = props => {
    const { liquidation, className, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-end"
                container
                justify="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                      Liquidaciones por verificar
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
                      Liquidación #{liquidation.liqNemonico}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    liquidation: PropTypes.any.isRequired
};

export default Header;
