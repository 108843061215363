import { DataService } from 'providers/dataService';
import { StorageService } from 'utils/storage';

export class NewUserProvider {
    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService();
    }

    saveUser(data) {
        let body = {
            tipoAccion: 1,
            entidad: 'Usuario',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101116');
    }
    saveRolesUser(data) {
        let body = {
            tipoAccion: 1,
            entidad: 'RolUsuario',
            entidades: data
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    consultaUsuario(usuCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                usuCodigo: usuCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    consultaRoles() {
        let body = {
            tipoConsulta: 1,
            entidad: 'Rol',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
    editUser(data) {
        let body = {
            tipoAccion: 2,
            entidad: 'Usuario',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    editUserMultipart(data) {
        let body = {
            tipoAccion: 2,
            entidad: 'Usuario',
            entidades: [data]
        };
        let endpoint = '/multipart';
        return this.dataService.postMultipart(endpoint, body, '0101008', []);
    }

    editRoles(data) {
        let body = {
            tipoAccion: 2,
            entidad: 'RolUsuario',
            entidades: data
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }
    getRolesUser(data) {
        let body = {
            tipoConsulta: 1,
            entidad: 'RolUsuario',
            parametros: {
                usuCodigo: data
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
}
