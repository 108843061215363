/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardHeader,
    Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

const Results = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >            
            <Card>
                <CardHeader
                    title="Liquidaciones"
                />
                <Divider />
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <MaterialTable
                                actions={[
                                    {
                                        icon: 'save',
                                        tooltip: 'Save User',
                                        onClick: () => {}
                                    }
                                ]}
                                columns={[
                                    { title: 'Código', field: 'liqNemonico', width: 100 },
                                    { title: 'Nombres', field: 'usuNombre' },
                                    { title: 'Cédula', field: 'perIdentificacion', width: 100},
                                    { title: 'Fecha', field: 'liqFechaRegistro', width: 300},                                   
                                ]}
                                components={{
                                    Action: props => (
                                        <Button
                                            color="primary"                                            
                                            component={RouterLink}
                                            size="small"
                                            style={{textTransform: 'none'}}
                                            to={`/auditor/auditoria/${btoa(props.data.liqCodigo) }`}                                                         
                                            variant="contained"
                                        >
                                            <VisibilityIcon className={classes.getAppIcon}/>
                                            Ver
                                        </Button>
                                    ),
                                }}
                                data={data}
                                localization={{
                                    header: {
                                        actions: 'Acción'
                                    },
                                    body:{
                                        emptyDataSourceMessage:'No hay datos que mostrar'
                                    },
                                    pagination:{
                                        labelRowsSelect:'Filas',
                                        labelDisplayedRows:'{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'
                                    }
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    actionsCellStyle: {
                                        width: 100,
                                        textAlign: 'center'
                                    },
                                    headerStyle:{
                                        fontWeight: 'bold'
                                    },
                                    search:false, 
                                    toolbar: false
                                }}
                            />
                        </div>
                    </PerfectScrollbar>
                </CardContent>
            </Card>
        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired
};

export default Results;