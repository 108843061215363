import { DataService } from 'providers/dataService'

export class RefundFormProvider {

    constructor() {
        this.dataService = new DataService();
    }

    comboEnfermedades(catCodigo,detNemonico, detDescripcion, detNombre) {
        let body = {
            maximoRegistros:5,
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: catCodigo,
                detNemonico: detNemonico+'%',
                detDescripcion:detDescripcion+'%',
                detNombre:detNombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    comboIfi(nombre) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catNemonico: 'IFI',
                detNombre:'%'+nombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    comboTipo() {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catNemonico: 'TIPCT'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosAfiliado(perIdentificacion) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                perIdentificacion: perIdentificacion + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    datosReembolso(perIdentificacion) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Persona',
            parametros: {
                perIdentificacion: perIdentificacion + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosEmpresa() {
        let body = {
            tipoConsulta: 1,
            entidad: 'Empresa',
            parametros: {
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosTitular( tipo,polCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                'detTipoPersona': tipo,
                polCodigo: polCodigo,
                
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosPaciente( polCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                polCodigo: polCodigo,
                
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    ingresoFormularioReembolso( objeto ) {
        let body = {       
            tipoAccion: 1,
            entidad: 'Formulario',
            entidades: objeto
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    editarLiquidacion( liqCodigo, perBeneficiario) {
        let body = {       
            tipoAccion: 2,
            entidad: 'Liquidacion',
            entidades: [{
                liqCodigo: liqCodigo,
                perBeneficiario:perBeneficiario
            }]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    
    editarLiquidacion1(files,liqCodigo,perBeneficiario) {

        const filesNames = [];

        files.map(file => {
            filesNames.push({docNombre: file.name});
            return 0;
        });        

        let body = {        
            tipoConsulta: 1,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: {
                    liqCodigo: liqCodigo,
                    perBeneficiario:perBeneficiario
                }                
            }
        };        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', files);
    }  

    buscarTItular(beneficiarioIdentificacion, beneficiarioApellido, beneficiarioNombre) {
        let body = {
            maximoRegistros:5,
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                perIdentificacion: beneficiarioIdentificacion+'%',
                perApellidos:beneficiarioApellido+'%',
                perNombres:beneficiarioNombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
    

    obtenerCuenta(perCodigo) {
        let body = {
            maximoRegistros:5,
            tipoConsulta: 1,
            entidad: 'CuentaBancaria',
            parametros: {
                perCodigo: perCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    editarCuenta(cueCodigo, cueCuenta) {
        let body = {
            tipoAccion: 2,
            entidad: 'CuentaBancaria',
            entidades:[ {
                cueCodigo: cueCodigo,
                cueCuenta: cueCuenta
            }]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }
    
    editarCuenta1(entidad) {
        let body = {
            tipoAccion: 2,
            entidad: 'CuentaBancaria',
            entidades:entidad
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }


}
