import React, { Component } from 'react';
import { CardContent, CardHeader, IconButton, Card, Button, Divider, Dialog, Grid, TextField, DialogActions } from '@material-ui/core';
import {Cancel} from '@material-ui/icons'

import Autocomplete from '@material-ui/lab/Autocomplete';

import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { PersonalProvider } from 'providers/persona.service';
import { TelefonoProvider } from 'providers/telefono.service';
import { LocationProvider } from 'providers/location.service';
import { DatePicker } from '@material-ui/pickers';
import { PaymentProvider } from 'providers/payment.service';
import 'moment/locale/es';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { DisplayMessage } from 'notifications/displayMessage';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { validateOnlyLetters, validateOnlyNumbers } from './../../../services/validation';
import validateCedula from 'utils/validateCedula';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const paymentProvider = new PaymentProvider();
const styles = {
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}

export default class PersonModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            personId: 0,
            allTypeCI:[],
            allGenders: [],
            allProvincies: [],
            allTypePhone: [],
            identification: '',
            typeIdentification: 0,
            inputSearchLocation:'',
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            typePhoneSelected: '',
            phoneNumber: '',
            observationPhone: '',
            phoneNumbers: [],
            openAddState: false,
            visibleAdd: false,
            disabledSaveButton: false,
            allBlackList: [],
            siniestroso: 0,
            errors: {
                identification: false,
                typeIdentification: false,
                gender: false,
                location: false,
                name: false,
                lastname: false,
                nacionality: false,
                direction: false,
                mail: false,
                birthdate: false,
                typePhoneSelected: false,
                phoneNumber: false,
                observationPhone: false,
                phoneNumbers: false,
                siniestroso: false,
            },
            messages: {
                identification: '',
                typeIdentification: '',
                gender: '',
                location: '',
                name: '',
                lastname: '',
                nacionality: '',
                direction: '',
                mail: '',
                birthdate: '',
                typePhoneSelected: '',
                phoneNumber: '',
                observationPhone: '',
                phoneNumbers: '',
                siniestroso: '',
            }, 
            
            
        }
        this.locationProvider = new LocationProvider();
        this.detalleCatalogoProvider = new DetalleCatalogoProvider();
        this.personalProvider = new PersonalProvider();
        this.telefonoProvider = new TelefonoProvider();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.displayMessage = new DisplayMessage();
        
    }
    componentDidMount = () => {
        this.getAllDetCatalogo('IDE','allTypeCI'); /** Tipo de Identificacion */
        this.getAllDetCatalogo('GENER','allGenders');/** Tipo de Generos */
        this.getAllDetCatalogo('TIPTEL','allTypePhone');/** Tipo de Generos */
        this.getAllNemonico();/** Tipo de siniestros */
        //console.log(this.state.allBlackList)
        this.getAllProvincies('');
    }
    
    showEmpties = () => {
        const {identification,typeIdentification, location, gender, name, lastname, nacionality, direction, mail, birthdate} = this.state;
      
        this.notifyEmptyNulls('identification',identification);
        this.notifyEmptyNulls('typeIdentification',typeIdentification);
        this.notifyEmptyNulls('gender',gender);
        this.notifyEmptyNulls('name',name);
        this.notifyEmptyNulls('lastname',lastname);
        this.notifyEmptyNulls('nacionality',nacionality);
        this.notifyEmptyNulls('direction',direction);
        this.notifyEmptyNulls('mail',mail);
        this.notifyEmptyNulls('birthdate',birthdate);
        this.notifyEmptyNulls('location',location);
    }
    /** ---------------------------------- hover button */
    onMouseOverAdd = () => {
        this.setState({visibleAdd: true})
    }
    onMouseOutAdd = () => {
        this.setState({visibleAdd: false})
    }
    /** ----------------------------------------------------Limpiar Errores */
    cleanErrors = () => {
        this.setState({
            errors: {
                identification: false,
                typeIdentification: false,
                gender: false,
                location: false,
                name: false,
                lastname: false,
                nacionality: false,
                direction: false,
                mail: false,
                birthdate: false,
                typePhoneSelected: false,
                phoneNumber: false,
                observationPhone: false,
                phoneNumbers: false,
                siniestroso: false,
            },
            messages: {
                identification: '',
                typeIdentification: '',
                gender: '',
                location: '',
                name: '',
                lastname: '',
                nacionality: '',
                direction: '',
                mail: '',
                birthdate: '',
                typePhoneSelected: '',
                phoneNumber: '',
                observationPhone: '',
                phoneNumbers: '',
                siniestroso: '',
            }
        });
    }
    /**  ------------------------------------------------------NOtificar NUlos*/

    notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                this.setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }
        } 
    }
    
    setErrorsMessages = (nameElement, stateElement, message) => {
        //const errors = { required: { ...this.state.errors.required, [name]:false} };
        
        const errors = this.state.errors;
        //{ ...this.state.errors, [nameElement]:true};

        const messages = this.state.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;

        //{ ...this.state.messages, [nameElement]:'* Este campo no puede estar vacio'};
        // console.log(errors);

        this.setState({
            errors: errors,
            messages:messages
        });
    }

    /** ----------------------------------------Traer todas lOS Detalle Catalogo por catCodigo*/
    getAllDetCatalogo = (catNemonico, nameData) => {
        this.detalleCatalogoProvider.findDetalleCatalogoByNemonico(catNemonico).then(
            response => {
                this.changeOneState(nameData, response)
            }
        );
    }

    getAllNemonico = () => {
        this.detalleCatalogoProvider.findDetalleCatalogoByNemonico('BKLIST').then(
            response => {
                this.changeOneState('allBlackList', response)
                //console.log(response)
                const busqueNoSiniestroso= response.filter(item=>item.detNemonico==='NOBK')
                //console.log(busqueNoSiniestroso)
                this.changeOneState('siniestroso', busqueNoSiniestroso[0].detCodigo)
            }
        );
    }

    /** ----------------------------------------Traer las ubicaciones (Provincias)*/
    getAllProvincies = (name) => {
        this.locationProvider.findLocationsLikeNombre(name).then(
            response => {
                this.changeOneState('allProvincies', response)
            }
        );
       
    }

    /** ----------------------------------------Abrir y cerrar el modal*/
    handleClickOpen = () => {
        const { setOpenPersonModal } = this.props;
        setOpenPersonModal(true);
    };
    
    handleClose = () => {
        const { setOpenPersonModal } = this.props;
        setOpenPersonModal(false);    
        this.cleanErrors();
    
    };

    /**------------------------------------------OnChange autocomplete */
    onChangeElement = (event, values) => {
        /** Ninguna de las id deben tener option */
        this.changeOneState('location', values);

    }
    /**-----------------------------------------------handleInputChange (Tecleo en Textfield) */
    handleInputChange = (event, ) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'identification':
                if(value.length < 16){
                    this.changeOneState(name, value);
                }
                break;
            case 'name':
                if(validateOnlyLetters(value)){
                    this.changeOneState(name, value);
                }
                break;
            case 'lastname':
                if(validateOnlyLetters(value)){
                    this.changeOneState(name, value);
                }
                break;
            case 'inputSearchLocation':
                if(value.length > 2){
                    this.getAllProvincies(value);
                }
                break;
            case 'phoneNumber':
                if(validateOnlyNumbers(value)){
                    this.changeOneState(name, value);
                }
                break;
            default:
                this.changeOneState(name, value);
                break;
        }
    }
    /** -----------------------------------------------Cambiar Fechas */
    handleDateChange = ( val ) =>{
        this.changeOneState('birthdate', val);
    }
    /** -----------------------------------------------Cambiar siniestro */
    handleSiniestroChange = ( val ) =>{
        this.changeOneState('siniestroso', val);
        //console.log('que hace')
    }
    
    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState,value) => {
        this.setState({
            [nameState]: value
        })
    }

    /** -----------------------------------------------Limpiar campos */
    cleanFields = () => {
        this.setState({
            identification: '',
            typeIdentification: 0,
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            siniestroso: '',
        });
    }
    /** --------------------------------------------Guardar Telefono en Array */

    savePhone = async() => {
        const {typePhoneSelected, phoneNumber, observationPhone, phoneNumbers} = this.state;

        const arrayNumbers = phoneNumbers;
        if(typePhoneSelected !== 0 && typePhoneSelected !== '' && phoneNumber!==''){
            await arrayNumbers.push({
                detTipo: typePhoneSelected.detCodigo,
                detNombreTipo: typePhoneSelected.detNombre,
                telNumero: phoneNumber,
                telObservacion: observationPhone,
            })
            
            await this.setState({
                phoneNumbers: arrayNumbers,
                typePhoneSelected: '',
                phoneNumber: '',
                observationPhone: ''
            });
        }
    }
    /** --------------------------------------------ELIMINAR DEL TELEFONO */
    deletePhone = async(element) => {
        

        const { phoneNumbers } = this.state;
        const data = phoneNumbers;
        var i = data.indexOf(element);
        await data.splice( i, 1 );
        await this.setState({
            phoneNumbers: data
        });
        
    }

    /** ------------------------------------------Guardar telefonos en la base de datps */
    
    createPhones = async(personId) => {
        let phones = [];

        await this.state.phoneNumbers.forEach(
            (phone) => {
                const tel = {
                    detTipo: phone.detTipo,
                    perCodigo: personId,
                    telNumero: phone.telNumero,
                    telObservacion: phone.telObservacion,
                    telEstado: 1
                }
                phones.push(tel);
            }
        )
        
        return await phones;
        
        
    }


    /** -----------------------------------------------Crear Persona */
    createPerson = async() => {
        await this.setState({disabledSaveButton: true});
        const {identification,typeIdentification, gender, location, name, lastname, nacionality, direction, mail, birthdate, siniestroso} = this.state;
        //console.log(siniestroso)
        if (typeIdentification === 3 || typeIdentification === 4){            
            if(!validateCedula(identification.trim().substr(0, 10))){
                this.displayMessage.error('Cédula o RUC incorrecto.');
                await this.setState({disabledSaveButton: false});

                return false;
            }
        }
        if(identification.trim() !== '' && typeIdentification !== 0 && gender !== 0 && location!==null && name.trim() !== '' && lastname.trim() !== '' && nacionality.trim() !== '' && direction.trim() !=='' && mail.trim() !=='' && birthdate!==null){
            const Person = await {
                detTipoIdentificacion: typeIdentification,
                perIdentificacion: identification,
                perApellidos: lastname,
                perNombres: name,
                perFechaNacimiento: birthdate.format('DD/MM/YYYY'),
                perDireccion: direction,
                perNacionalidad: nacionality,
                perMail: mail,
                detGenero: gender,
                locCodigo: location.locCodigo,
                perEstado: 1,
                detEstadoPoliza: siniestroso,
                telefono:  await this.createPhones()
            }
            await this.personalProvider.createPerson([Person]).then( 
                async response => {
                    if(response.error.codigo === '0'){
                        this.getPerson(response.bodyOut.resultados[0].perIdentificacion);
                       
                        this.displayMessage.success(`Persona ${Person.perNombres} ${Person.perApellidos} creada con éxito`);
                        await this.handleClose();

                        this.cleanFields();
                        this.cleanErrors();

                    }
                    await this.setState({disabledSaveButton: false});
                }
            )
              
        }else{
            await this.showEmpties();
            await this.setState({disabledSaveButton: false});

            this.displayMessage.warn('Por favor ingrese todos los campos')
        
        }
    }
        
    /** ---------------------------------------------------Verificar error en TextField */
    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `Campo ${field} `;
  
        if (required[field]) {
            return `${errorMessage} es obligatorio`;
        }
  
        if (!valid[field]) {
            return `${errorMessage} no es valido`;
        }
    }

    /** --------------------------------------------------BuscarPersona */
    getPerson = (identification) => {
        this.personalProvider.findPerson(identification).then(
            response => {
                this.props.setPerson(response[0]);
            }
        )
    }

    /** --------------------------------------------------Cerrar Agregar telefono*/
    closeAdd = () => {
        this.setState(
            {openAddState: false}
        )
    } 
    openAdd = () => {
        this.setState(
            {openAddState: true}
        )
    }
    render(){
     
        //console.log(this.props.envio)

        return(
            <Dialog
                maxWidth='md'
                open={this.props.open}
                scroll={'body'}
                fullWidth
            >
                <Card>
                    <CardHeader 
                        action={<IconButton
                            color="secondary"
                            onClick={this.handleClose}
                        ><Cancel/></IconButton>}
                        title = "Crear persona"/>
                    <Divider /> 
                    
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Alert severity="warning">
                                    <Typography
                                        component="h6"
                                    >
                                        <b>* Campos obligatorios.</b>
                                    </Typography>                      
                                </Alert>                            
                            </Grid>
                        </Grid><br/>  
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <TextField error={this.state.errors.identification} fullWidth helperText={this.state.messages.identification} label="Identificación" name='identification' onChange={this.handleInputChange} value={this.state.identification} variant="outlined" required/>
                            </Grid>
                            <Grid item xs>
                                <FormControl error={this.state.errors.typeIdentification} fullWidth variant='outlined' required>
                                    <InputLabel id="typeIdentificationLabel" >Tipo de identificación</InputLabel>
                                    <Select
                                        id='typeIdentification'
                                        label='Tipo de identificación'
                                        labelId="typeIdentificationLabel"
                                        name='typeIdentification'
                                        onChange={this.handleInputChange}
                                        value={this.state.typeIdentification}
                                    >
                                        <MenuItem  value={0}>Seleccione</MenuItem> 

                                        {
                                            this.state.allTypeCI && this.state.allTypeCI.map(
                                                (typeCI)=>  <MenuItem key={typeCI.detCodigo} value={typeCI.detCodigo}>{typeCI.detNombre}</MenuItem> )
                                        }
                                    </Select>
                                </FormControl>
                             
                            </Grid>
                        </Grid>
                        
                       

                        <Grid container spacing={3}>
                            <Grid item xs>
                                <TextField error={this.state.errors.name} fullWidth helperText={this.state.messages.name} label="Nombres" name='name' onChange={this.handleInputChange} value={this.state.name} variant="outlined" required/>

                            </Grid>
                            <Grid item xs>
                                <TextField error={this.state.errors.lastname} fullWidth helperText={this.state.messages.lastname} label="Apellidos" name='lastname' onChange={this.handleInputChange} value={this.state.lastname} variant="outlined" required/>

                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            
                            <Grid item xs>
                                <FormControl error={this.state.errors.gender} fullWidth variant='outlined' required >
                                    <InputLabel id="genderLabel" >Género</InputLabel>
                                    <Select
                                        id='gender'
                                        label='Género'
                                        labelId="genderLabel"
                                        name='gender'
                                        onChange={this.handleInputChange}
                                        value={this.state.gender}
                                        
                                    >
                                        <MenuItem  value={0}>SELECCIONE</MenuItem> 
                                        {
                                            this.state.allGenders && this.state.allGenders.map(
                                                (gender)=>  <MenuItem key={gender.detCodigo} value={gender.detCodigo}>{gender.detNombre}</MenuItem> )
                                                
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <DatePicker
                                    error={this.state.errors.birthdate}
                                    format="DD/MM/YYYY"
                                    fullWidth
                                    helperText={this.state.messages.birthdate}
                                    inputVariant='outlined'
                                    //views={['year']}
                                    label="Fecha de nacimiento:"
                                    name='birthdate'
                                    onChange={this.handleDateChange} 
                                    value={this.state.birthdate}
                                    required
                                />

                            </Grid>
                        </Grid>
                       
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <TextField error={this.state.errors.nacionality} fullWidth helperText={this.state.messages.nacionality} label="Nacionalidad" name='nacionality' onChange={this.handleInputChange} value={this.state.nacionality} variant="outlined" required/>
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    fullWidth
                                    getOptionLabel={(option) => option.locNombre}
                                    id="location"
                                    onChange={this.onChangeElement}
                                    options={this.state.allProvincies}
                                    renderInput={(params) => <TextField error={this.state.errors.location} helperText={this.state.messages.location} name='inputSearchLocation' onChange={this.handleInputChange} value={this.state.inputSearchLocation} {...params} label="Ubicación" variant="outlined" required/>}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>   
                            <Grid item xs>
                                <TextField error={this.state.errors.direction} fullWidth helperText={this.state.messages.direction} label="Dirección" name='direction' onChange={this.handleInputChange} value={this.state.direction} variant="outlined" required/>
                            </Grid>
                            <Grid item xs>
                                <TextField error={this.state.errors.mail} fullWidth helperText={this.state.messages.mail} label="Correo electrónico" name='mail' onChange={this.handleInputChange} value={this.state.mail} variant="outlined" required/>

                            </Grid>
                        </Grid>
                        <br></br>
                        <FormControl error={this.state.errors.siniestroso} fullWidth variant='outlined' >
                                    <InputLabel id="siniestrosoLabel" >Tipo de Persona</InputLabel>
                                    <Select
                                        id='siniestroso'
                                        label='Siniestroso'
                                        labelId="siniestrosoLabel"
                                        name='siniestroso'
                                        onChange={this.handleInputChange}
                                        value={this.state.siniestroso}
                                        
                                    >
                                        <MenuItem  value={this.props.envio} >Seleccione</MenuItem> 
                                        {
                                            //console.log(this.props.envio),
                                            this.state.allBlackList && this.state.allBlackList.map(
                                                (siniestroso)=>  <MenuItem key={siniestroso.detCodigo} value={siniestroso.detCodigo}>{siniestroso.detNombre}</MenuItem> )
                                                
                                        }
                                    </Select>
                                </FormControl>
                        
                        
                        
                        
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title='Teléfono(s)'/>
                    <CardContent>
                        <TableContainer >
                            <Table>
                                <TableHead style={{backgroundColor: 'white', fontWeight:'bold'}}>
                                    <TableRow >
                                        <TableCell ><b>Número</b></TableCell>
                                        <TableCell ><b>Tipo de número</b></TableCell>
                                        <TableCell ><b>Observación</b></TableCell>
                                        <TableCell>
                                            {this.state.openAddState ? <b>Acción</b> : <Button onClick={this.openAdd} ><AddIcon /></Button> }  
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.phoneNumbers.map((row, index) => (
                                        <TableRow key={index} style ={ (index) % 2? { background : '#EEE' }:{ background : '#FFF' }}>
                                            <TableCell component="th" scope="row">{row.telNumero}</TableCell>
                                            <TableCell component="th" scope="row">{row.detNombreTipo}</TableCell>
                                            <TableCell >{row.telObservacion}</TableCell>
                                            <TableCell ><IconButton onClick={() => this.deletePhone(row)} size='small'><DeleteIcon/></IconButton></TableCell>

                                        </TableRow>
                                    ))}
                                    {this.state.openAddState &&
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <TextField error={this.state.errors.phoneNumber} helperText={this.state.messages.phoneNumber} label="Número telefónico" name='phoneNumber' onChange={this.handleInputChange} value={this.state.phoneNumber} required fullWidth/>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <FormControl fullWidth required>
                                                <InputLabel id="typePhone" >Tipo</InputLabel>
                                                <Select
                                                    id='typePhoneSelected'
                                                    label='Tipo de identificación'
                                                    labelId="typePhone"
                                                    name='typePhoneSelected'
                                                    onChange={this.handleInputChange}
                                                    value={this.state.typePhoneSelected}
                                                    
                                                >
                                                    {
                                                        this.state.allTypePhone && this.state.allTypePhone.map(
                                                            (typePhone)=>  <MenuItem key={typePhone.detCodigo} value={typePhone}>{typePhone.detNombre}</MenuItem> )
                                                    }
                                                </Select>
                                            </FormControl> 
                                        </TableCell>
                                        <TableCell >                                
                                            <TextField label="Observación:" name='observationPhone' onChange={this.handleInputChange} value={this.state.observationPhone} fullWidth />
                                        </TableCell>
                                        <TableCell >
                                            <Button 
                                                autoFocus
                                                onClick={this.savePhone}>
                                                <DoneIcon />
                                            </Button>
                                            <Button 
                                                autoFocus
                                                onClick={this.closeAdd}>
                                                <CloseIcon />
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                            
                            
                                    }
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                 
                                    
                    </CardContent>
                    
                            
                </Card>
                
                <DialogActions>
                    <Button color="primary" onClick={this.handleClose}>
                        Cancelar
                    </Button>
                    <div style={styles.wrapper}>
                        <Button 
                            autoFocus 
                            color="primary" 
                            disabled={this.state.disabledSaveButton}
                            onClick={this.createPerson}>
                                Guardar
                        </Button>
                        { this.state.disabledSaveButton && <CircularProgress size={24} style={styles.buttonProgress} />}
                    </div>

                </DialogActions>
            </Dialog>
        )
    }
}
