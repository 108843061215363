/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, TextField, Card, CardContent, Button, CardActions } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneIcon from '@material-ui/icons/Done';
import { CatalogProvider } from 'providers/catalog.service';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { DisplayMessage } from 'notifications/displayMessage';
import AddIcon from '@material-ui/icons/Add';
import CatalogAdd from 'views/CatalogAdd';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme)=> ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    rightNoRadio:{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

    },
    leftNoRadio:{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: '100%'
    }

}))
const Form = props => {
    const displayMessages = new DisplayMessage();
    const catalogProvider = new CatalogProvider();
    const detalleCatalogoProvider = new DetalleCatalogoProvider();

    const [disabledButton, setDisabledButton] = React.useState(false);
    const classes = useStyles();

    const initialState = {
        catCode: null,
        nemonic: '',
        type: '',
        name: '',
        origin: '',
        destination:'',
        description:'',
        errors: {
            catCode: false,
            nemonic: false,
            type: false,
            name: false,
            origin: false,
            destination:false,
            description: false,
        },
        messages: {
            catCode: '',
            nemonic: '',
            type: '',
            name: '',
            origin: '',
            destination:'',
            description:'',
        },
        allCatalogs: [],
    }

    const [stateSelect, setStateSelect] = useState(initialState);
 
    const [openAddCatalog, setOpenAddCatalog] = useState(false);

    const componentDidMount = () => {
        getAllCatalogs();
    }

    useEffect(
        componentDidMount
        ,[])

    const getAllCatalogs = () => {
        catalogProvider.findAllCatalogs().then(
            response => {
                setStateSelect({ ...stateSelect, allCatalogs: response });
            }
        )
    }

  

    const setErrorsMessages = (nameElement, stateElement, message) => {
        
        const errors = stateSelect.errors;
        const messages = stateSelect.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;
        setStateSelect({ ...stateSelect, errors: errors, messages: messages });
    }


    const notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                setErrorsMessages(nameElement, false, '');
            }
        }
    }


    const showEmpties = () => {
        const {catCode, name, type,nemonic } = stateSelect;
        notifyEmptyNulls('catCode',catCode);
        notifyEmptyNulls('name',name);
        notifyEmptyNulls('nemonic',nemonic);
        notifyEmptyNulls('type',type);

    }


    const restartFields = () => {
        setStateSelect({ ...stateSelect, 
            catCode: null,
            nemonic: '',
            type: '',
            name: '',
            origin: '',
            destination:'',
            description:'',
            errors: {
                catCode: false,
                nemonic: false,
                type: false,
                name: false,
                origin: false,
                destination:false,
                description: false,
            },
            messages: {
                catCode: '',
                nemonic: '',
                type: '',
                name: '',
                origin: '',
                destination:'',
                description:'',
            },
        });  
    }

    const handleSave = async () => {
        setDisabledButton(true);

        const {catCode, name, nemonic, type, origin, destination, description } = stateSelect;
        if( catCode !== null && name.trim() !== '' && nemonic.trim() !== '' && type.trim() !== '' ){

            const trama = {
                catCodigo: catCode.catCodigo,
                detNemonico: nemonic,
                detTipo: type,
                detNombre: name,
                detOrigen: origin.trim() !== '' ? origin : null,
                detDestino: destination.trim() !== '' ? destination : null,
                detDescripcion: description,
                detEstado: 1
        
            }
            detalleCatalogoProvider.createCatalog([trama]).then(
                response => {
                    if(response.error.codigo === '0'){
                        displayMessages.success(`Catalogo ${name} agregado con éxito`);
                        restartFields();
                    }
                    setDisabledButton(false);

                }


            )
      
      
        }else{
            setDisabledButton(false);
            await showEmpties();
            await displayMessages.warn('Por favor rellene todos los campos correctamente');
        }
    
    }

    const handleCloseCatalog = () => {
        setOpenAddCatalog(false);
    }
    const handleOpenCatalog = () => {
        setOpenAddCatalog(true);
    }


    const handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'nemonic':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  
                
                break;
            case 'type':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  

                
                break;
            case 'name':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  
                
                break;
            case 'description':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  
                
                break;
               
            default:
                setStateSelect({ ...stateSelect, [name]: value });  
                break;
        }
    }

  

    const onChangeCatalog = async(event, values) => {
        setStateSelect({ ...stateSelect, catCode: values });  
    }

   
    return (  
        <div>
            <CatalogAdd close={handleCloseCatalog} open={openAddCatalog} getAllCatalogs={getAllCatalogs} setStateSelect={setStateSelect} stateSelect={stateSelect} />
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="name" name='name' value={stateSelect.name} error={stateSelect.errors.name} helperText={stateSelect.messages.name} onChange={handleInputChange} label="Nombre" variant="outlined" inputProps={{maxLength: 50,}} fullWidth />
                        </Grid>
            
                        <Grid item xs={6}>
                            <Grid container spacing={0}>
                                <Grid item xl={11} xs={11} md={10.9} sm={10}>
                                    <Autocomplete
                                        classes={{inputRoot: classes.rightNoRadio}}
                                        id="Categoría"                               
                                        options={stateSelect.allCatalogs}
                                        getOptionLabel={(option) => option.catNombre}
                                        onChange={onChangeCatalog}
                                        value={stateSelect.catCode}
                                        renderInput={(params) => <TextField {...params}  error={stateSelect.errors.catCode} helperText={stateSelect.messages.catCode} label="Categoría" variant="outlined"  fullWidth />}
                                    /> 
                                </Grid>
                                <Grid item xl={1} xs={1} md={1.1} sm={2}>
                                    <Button className={classes.leftNoRadio} aria-label="Crear categoría" color="primary" variant='contained' onClick={handleOpenCatalog} fullWidth >
                                        <AddIcon />
                                    </Button>     
                                </Grid>
                
                            </Grid>
                    
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="nemonic" name='nemonic'  value={stateSelect.nemonic} error={stateSelect.errors.nemonic} helperText={stateSelect.messages.nemonic} onChange={handleInputChange} label="Código" variant="outlined" inputProps={{maxLength: 7,}} fullWidth  />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="type" name='type' value={stateSelect.type} error={stateSelect.errors.type} helperText={stateSelect.messages.type}  onChange={handleInputChange} label="Tipo" variant="outlined" inputProps={{maxLength: 20,}} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField  id="origin" name='origin' value={stateSelect.origin} error={stateSelect.errors.origin} helperText={stateSelect.messages.origin}  onChange={handleInputChange} label="Orígen" variant="outlined" fullWidth inputProps={{maxLength: 255,}} />
     
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="destination" name='destination' value={stateSelect.destination} error={stateSelect.errors.destination} helperText={stateSelect.messages.destination}  onChange={handleInputChange} inputProps={{maxLength: 255,}} label="Destino" variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="description" name='description' value={stateSelect.description} error={stateSelect.errors.description} helperText={stateSelect.messages.description} onChange={handleInputChange} inputProps={{maxLength: 511,}} label="Descripción" variant="outlined" fullWidth  />
                        </Grid>
          
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                    >
                        <div className={classes.wrapper}>

                            <Button
                                color="primary"
                                onClick={handleSave}
                                variant="contained"
                                disabled={disabledButton}
                                startIcon={<DoneIcon/>}
                            >agregar
                            </Button>
                            {
                                disabledButton === true &&
          <CircularProgress size={24} className={classes.buttonProgress} /> 

                            }
                        </div>

                    </Grid>
                </CardActions>
            </Card>
   
        </div>
    );
}
 
 
export default Form;