import React from 'react';
import  PerfectScrollbar  from 'react-perfect-scrollbar';
import Paper from '@material-ui/core/Paper';
import { Card,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    TableContainer,
} from '@material-ui/core';
import './styles.css';
import { ModalAddBeneficiary } from './../../components';
import { BeneficiaryDataProvider } from 'providers/beneficiaryData.service';
import { DisplayMessage } from 'notifications/displayMessage';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

export default class RegistroBeneficiarios extends React.Component {
  _isMounted = false;
  constructor(props){
      super(props);
    
      this.state={
          empresa: '',
          nombreAfiliado: '',
          apellidoAfiliado: '',
          cedulaAfiliado: '',
          fechaNacimiento: '',
          direccion: '',
          telefono: '',
          email: '',
          cuenta: '',
          tipoCuenta: '',
          banco: '',
          planContratado: '',
          openApplication: false,
          setOpenApplication: false,
          datosPrueba: [],
          datosEdit: [],
          error: false,
          data:0
      }

      this.handleChange=this.handleChange.bind(this);
  }
  
  handleChange(e){
      this.setState({ [e.target.name]: e.target.value });
    
  }

  

 handleApplicationOpen = () => {
     const displayMessage = new DisplayMessage();
     const tamArreglo= this.state.data.length;
     const {numeroBeneficiario} = this.props;
     
     if(tamArreglo >= numeroBeneficiario){
         displayMessage.error('No puede agregar más beneficiarios, ya alcanzó el número de beneficiarios permitidos');
     }else{ 
         /*  if(estadoPoliza === 43){ */
    
         this.setState({setOpenApplication: true})
         /*  }else{
             displayMessage.error('Primero debe aceptar la Poliza');
             return <Redirect to="/home"/>

         } */
     }
 }

 handleApplicationClose = () => {
     this.setState({setOpenApplication:false,
     })
 };

  getRespons=(result)=>{
      this.setState({data : result})
  }

  handdleEliminar = (datos) => {
      const eliminar= this.state.datosPrueba;
      eliminar.forEach(function(b,index,object) {
          if(b.ci === datos){
              object.splice(index,1); 
          }
        
      });
      this.setState({datosPrueba:eliminar})
                  
  }

  componentDidMount(){
      this._isMounted=true;
      const {poliza}=this.props;
      const beneficiary = new BeneficiaryDataProvider();

      beneficiary.datosBeneficiarios(poliza)
          .then(
              data => {
                  if(this._isMounted){
                      this.setState({data:data.bodyOut.data});
                  }
              },
              () => {          
                  return false;

              }

          );

  }

  componentWillUnmount(){
      this._isMounted=false;
  }

  render(){
      const {poliza} = this.props;
      /* if(estadoPoliza === 43){ */
      return (
          <div >       
              <form>
                  <div className="right">
                      <Button
                          color="primary"
                          onClick={this.handleApplicationOpen}
                          variant="contained"
                          startIcon={<AddCircleOutlineIcon  />}
                      >Nuevo beneficiario</Button>
                  </div>
                  <Card >
                      <PerfectScrollbar>
                          {this.state.data ?  
                              <TableContainer component={Paper} aria-label="simple table">
                                  <Table size="medium">
                                      <TableHead style={{backgroundColor: 'white', fontWeight:'bold'}}>
                                          <TableRow>
                                              <TableCell component='th'>
                                                  <b>Cédula</b> 
                                              </TableCell>
                                              <TableCell component='th'>
                                                  <b>Nombres</b>
                                              </TableCell>
                                              <TableCell component='th'>
                                                  <b>Apellidos</b>
                                              </TableCell>
                                              <TableCell component='th'>
                                                  <b>Edad</b>   
                                              </TableCell>
                                              <TableCell component='th'>
                                                  <b>Parentesco</b>    
                                              </TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                 
                                          {this.state.data.map((beneficiarios,index)=>

                                              beneficiarios.detTipoPersona !== 21 && <TableRow key={index} style ={ (index) % 2? { background : "#EEE" }:{ background : "#FFF" }}>
                                                  <TableCell>
                                                      {beneficiarios.perIdentificacion}
                                                  </TableCell>
                                                  <TableCell>
                                                      {beneficiarios.perNombres}
                                                  </TableCell>
                                                  <TableCell>
                                                      {beneficiarios.perApellidos}
                                                  </TableCell>
                                                  <TableCell>
                                                      {beneficiarios.edadActual}
                                                  </TableCell>
                                                  <TableCell>
                                                      {beneficiarios.nombre2}
                                                  </TableCell>
                                              </TableRow>
                                          )}
                  
                                      </TableBody>
                                  </Table>
                                 
                              </TableContainer>
                              :<CircularProgress />}
                      </PerfectScrollbar>
                  </Card>
              </form>
              
              <ModalAddBeneficiary
                  callback={this.getRespons.bind(this)}
                  close={this.handleApplicationClose}
                  onApply={this.handleApplicationClose}
                  open={this.state.setOpenApplication}
                  data={this.state.data}
                  poliza={poliza}
              />
          </div>
      );
      /* }else{
          return <Redirect to="/home"/>
      } */
  }
}
