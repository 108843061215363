import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card, CardActions, Grid, TextField, Button } from '@material-ui/core';
import { PolizaProvider } from 'providers/poliza.service';
import GetAppIcon from '@material-ui/icons/GetApp';
import { SearchBasic } from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

export default function SearchAffiliate() {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();
    const polizaProvider = new PolizaProvider();

    const [columns] = useState([
        { title: 'Nombre', field: 'perNombres' },
        { title: 'Apellidos', field: 'perApellidos' },
        { title: 'Cédula', field: 'perIdentificacion' },
        { title: 'Edad', field: 'edadActual' },
        { title: 'Monto cobertura', field: 'pepMontoCobertura' },
        { title: 'Plan', field: 'plaProducto' },
        { title: 'Preexistencia', field: 'POL_PREEXISTENCIAS' },
        { title: 'Estado plan', field: 'polDescripcion' },
        { title: 'Tipo', field: 'tipo' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');

    const [loading, setLoading] = useState(false);

    const handleBuscarTitular = () => {
        setData(0);

        if (buscarTitular !== '') {
            queries.reportesAfiliados(buscarTitular)
                .then(
                    user => {
                        if (user.error.codigo === "0") {
                            const consulta = user.bodyOut.data;
                            let dataNueva = consulta.filter(consulta => (consulta.polEstadoPoliza !== 23531))
                            if (dataNueva.length > 0) {
                                setData(dataNueva);
                            }
                            else {
                                displayMessage.error('No se encontro datos.');
                                setData([])
                            }
                        } else {
                            setData([])
                            //displayMessage.error('No se encontro datos.');
                        }

                    },
                    () => {
                        displayMessage.error('Error de comunicación.');

                    }
                );
        } else {
            displayMessage.error('Ingrese un valor a buscar.');
            setData([])
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setData([]);
    }

    const downloadFile = (polCodigo) => {
        setLoading(true);
       
        polizaProvider.getDocumentPDFList(polCodigo).then(
            async(response) => {                
                if(typeof(response.bodyOut.data[0].poliza)!=='undefined' && response.bodyOut.data[0].poliza!==null){
                    var path=`data:application/octet-stream;base64,${response.bodyOut.data[0].poliza}`;
                    var link = await document.createElement('a'); 
                    await link.setAttribute('href',path);
                    await link.setAttribute('download', response.bodyOut.data[0].polContrato);
                    await link.setAttribute('target','_blank');
                    await document.body.appendChild(link)
                    await link.click();                
                    await document.body.removeChild(link)
                    setLoading(false);
                }else{
                    displayMessage.error('No tiene contrato generado.');
                    setLoading(false);
                }
            }
        )
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <div>
            <br />
            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />
            {data ?
                data.length ?
                    <Card>
                        <MaterialTable
                            actions={[
                                {
                                    icon: 'save',
                                    tooltip: 'Save User',
                                    onClick: () => { }
                                }
                            ]}
                            columns={columns}
                            components={{
                                Action: props => (
                                    <Button
                                        color="primary"
                                        size="small"
                                        style={{ textTransform: 'none' }}
                                        onClick={() => {
                                            downloadFile(props.data.POL_CODIGO);
                                        }}
                                        variant="contained"
                                    >
                                        <GetAppIcon className={classes.getAppIcon} />
                                        Contrato
                                    </Button> 
                                )
                            }}
                            data={data}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination: {
                                    labelRowsSelect: 'Filas',
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'


                                }
                            }}
                            options={{ exportButton: false, search: false, toolbar: false, actionsColumnIndex: -1,
                                actionsCellStyle: {
                                    width: 100,
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },}}
                            title="Afiliados"
                        />
                    </Card> : ''
                : <CircularProgress />}
        </div>
    );
}