import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
  CardContent,
  CardActions
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },

  inner: {
    minWidth: 700
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },

}));


const TableForm = props => {
  const { className,columna, data, actions,handleOnClick, ...rest } = props;

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <Divider />
         <CardContent className={classes.content}>
         <PerfectScrollbar>
         <div className={classes.inner}>
        <Table>
          <TableHead>
          <TableRow>
            {columna.map((nombre, index)=>
              
              <TableCell key={index}>
                {nombre.title}
              </TableCell>
              
            )}
            </TableRow>
          </TableHead>
          <TableBody>                  
          {data.map((datos, index) =>
              <TableRow key={index}>
                {
                  columna.map((campo) => 
                    <TableCell key={campo.field}>
                      {datos[campo.field]}
                    </TableCell>                    
                  )

                }  
                { actions ?
                  <TableCell key={"actions"+index}>
                      {
                        actions.map((action)=> action.button)
                      }
                  </TableCell>:""
                }            
              </TableRow>
            )
          }
          </TableBody>
        </Table>
        </div>
        </PerfectScrollbar>
        </CardContent>
         <CardActions className={classes.actions}>
         <TablePagination
           component="div"
           count={data.length}
           onChangePage={handleChangePage}
           onChangeRowsPerPage={handleChangeRowsPerPage}
           page={page}
           rowsPerPage={rowsPerPage}
           rowsPerPageOptions={[5, 10, 25]}
         />
       </CardActions>
       </Card>
  
       </div>
  );
};

TableForm.propTypes = {
  className: PropTypes.string
};

export default TableForm;