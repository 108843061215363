import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
export const liquidationsOptions = [
    {
        id: 1,
        name: "Liquidaciones",
        Pending:  20,
        icon: AssignmentTurnedInIcon ,
        link: "/liquidador/pendientePagar"

    },
    {
        id: 2,
        name: "Auditorias",
        Pending:  12,
        icon: VerifiedUserIcon,
        link: "/liquidador/pendientePagar"


    },
    {
        id: 3,
        name: "Otros",
        Pending:  21,

        icon:  LoyaltyIcon,
        link: "/liquidador/pendientePagar"

    }

]

export const notificationsOptions = [
    {
        id: 1,
        name: "Liquidaciones por pagar",
        Pending:  13,
        icon: CreditCardIcon,
        link: "/contador/pendiente-pagar"
    },
    {
        id: 2,
        name: "Liquidaciones por cobrar",
        Pending:  899,
        icon: MonetizationOnIcon,
        link: "/contador/consolidar-cobros"

    },
    

]

export const liquidatorOptions = [
    {
        id: 1,
        name: "Liquidaciones",
        Pending:  13,
        icon: CreditCardIcon,
        link: "/liquidador/liquidaciones/digitalizar"
    },
    {
        id: 2,
        name: "Auditorias",
        Pending:  9,
        icon: MonetizationOnIcon,
        link: "/contador/consolidar-cobros"

    },
    {
        id: 2,
        name: "Otros",
        Pending:  17,
        icon: MonetizationOnIcon,
        link: "/contador/consolidar-cobros"

    }

]