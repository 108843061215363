/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Tooltip, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SearchBasic } from 'components';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    actions: {
        padding: theme.spacing(1),
    },
    search: {
        flexGrow: 1,
        height: 52,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    }
}));



export default function SearchAffiliate() {

    const [data, setData] = useState([]);
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();
    const session = useSelector(state => state.session);
    const [open, setOpen] = useState(false);
    const [dataRow, setDataRow] = useState(null);

    const [columns] = useState([
        { title: 'Cédula', field: 'CEDULA', width: 100 },
        { title: 'Nombre', field: 'NOMBRES' },
        { title: 'Apellidos', field: 'APELLIDOS' },
        { title: 'Fecha Registro', field: 'FECHA', width: 200 },
        { title: 'Monto', field: 'MONTO', width: 200 },
        { title: 'Parentesco', field: 'PARENTESCO' },
        { title: 'pepcodigo', field: 'PEPCODIGO', hidden: true },
        { title: 'Nemonico', field: 'NEMONICO', hidden: true }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');

    const handleBuscarTitular = () => {
        setData(0);
        if (buscarTitular === '') {
            displayMessage.error('Ingrese un valor a buscar.');
            setData([]);
        } else {
            queries.reporteTitularDependientes(buscarTitular)
                .then(
                    user => {
                        if (user.error.codigo === '0') {
                            setData(user.bodyOut.data);
                        }else if(user.error.codigo === '1002'){
                            displayMessage.error('No se encontró resultados')
                            setData([]);
                        }
                         else {
                            setData([]);
                            displayMessage.error('No se encontró resultados')
                            
                        }


                    },
                    () => {
                        displayMessage.error('Error de comunicación.');

                    }
                );
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setData([]);
    }

    const handleDelete = () => {
        queries.eliminarTitularDependientes(dataRow.PEPCODIGO)
            .then(
                user => {
                    if (user.error.codigo === '0') {
                        displayMessage.success('Dependiente eliminado con éxito');
                        handleBuscarTitular();
                        setDataRow(null);
                        setOpen(false);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    }

    const dataReportsExport = (typeResult) => {

        queries.dataReportsExportTitulares(typeResult, buscarTitular).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
        () => {
            displayMessage.error('Error de comunicación.');
        });

    };

    const downloadFile = (data, typeFile) => {

        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].TITULAR_DEPENDIENTE}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    return (
        <div>
            <br />
            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />


            {
                data ?
                    data.length ?
                        <>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingLeft: 1, textAlign: 'right', marginBottom: 10 }}
                                >
                                    <Tooltip title="Exportar a CSV">
                                        <Button
                                            style={{ borderRadius: 5, marginRight: 10, width: 100 }}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => { dataReportsExport(2); }}
                                        >

                                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Exportar a EXCEL">
                                        <Button
                                            style={{ borderRadius: 5, width: 100 }}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => { dataReportsExport(3); }}
                                        >
                                            <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
                                        </Button>
                                    </Tooltip>

                                </Grid>
                            </Grid>
                            <Card>

                                <MaterialTable
                                    actions={session.user.role === 'ADMIN' ? [
                                        {
                                            icon: 'save',
                                            onClick: (event, rowData) => { setDataRow(rowData); setOpen(true); }
                                        }
                                    ]:[]}
                                    columns={columns}
                                    components={session.user.role === 'ADMIN' ? {
                                        Action: props => (
                                            props.data.NEMONICO !== 'PTITU' ?
                                                <Button
                                                    color="primary"
                                                    onClick={(event) => props.action.onClick(event, props.data)}
                                                    size="small"
                                                    style={{ textTransform: 'none' }}
                                                    variant="contained"
                                                >
                                                    Eliminar
                                                </Button> :
                                                ''
                                        ),
                                    } : {}}
                                    data={data}
                                    localization={{
                                        header: {
                                            actions: 'Acción'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'No hay datos que mostrar'
                                        },
                                        toolbar: {
                                            search: false,
                                            exportName: 'Exportar a CSV',
                                            exportAriaLabel: 'Exportar',
                                            exportTitle: 'Exportar'
                                        },
                                        pagination: {
                                            labelRowsSelect: 'Filas',
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsPerPage: 'filas'


                                        }
                                    }}
                                    options={{
                                        exportButton: false,
                                        search: false,
                                        toolbar: false,
                                        actionsColumnIndex: -1,
                                        actionsCellStyle: {
                                            width: 100,
                                            textAlign: 'center',
                                            padding: 10
                                        },
                                        headerStyle: {
                                            fontWeight: 'bold'
                                        },
                                        rowStyle: rowData => ({
                                            backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                                        }),
                                    }}
                                    title="Afiliados"
                                />
                            </Card> </> : ''
                    : <CircularProgress />
            }
            <Dialog
                aria-labelledby="form-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown
                onClose={() => { setOpen(false); setData(null); }}
                open={open}
            >
                <DialogTitle id="form-dialog-title">Eliminar dependiente</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de eliminar el dependiente?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => { setOpen(false); setDataRow(null); }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => handleDelete()}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}