import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DocumentoProvider } from 'providers/documento.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {  Paper } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemIcon from '@material-ui/core/ListItemIcon';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            aria-labelledby={`scrollable-force-tab-${index}`}
            component="div"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 150,
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
      },
      gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
      },
      title: {
        color: theme.palette.primary.light,
      },
      scroll:{
        position: 'relative',
        overflow: 'auto',
        maxHeight: '100%'
    },
      titleBar: {
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      },
}));

export default function HorizontalMenuFiles(props) {
    const classes = useStyles();
    const flexContainer = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
      };
    const downloadFile = (fileId) => {
        const documentoProvider = new DocumentoProvider();
        const displayMessage = new DisplayMessage();
       
        documentoProvider.findFile(fileId).then(

            async(response) => {
                if(typeof(response[0].documento)!=='undefined' && response[0].documento!==null){
                    console.log("Documento: ", response[0].documento)
                    var path=`data:application/octet-stream;base64,${response[0].documento}`;
            
                    var link = await document.createElement('a'); 
                    await link.setAttribute('href',path);
                    await link.setAttribute('download',response[0].docNombre);
                    await link.setAttribute('target','_blank');

                    await document.body.appendChild(link)

                    await link.click();
 
                    await document.body.removeChild(link)


                }else{
                    displayMessage.error('Ha ocurrido un error, es probable que el archivo no se encuentre en la base')
                }
            }
        )
    }
    const [value, setValue] = React.useState(0);


    return (
        <div className={classes.root}>
        
        <List component="nav" aria-label="main mailbox folders" className={classes.scroll}>
        {
                        props.documents && props.documents.map( (doc,i)=>
                        <ListItem button >
                            <ListItemIcon>
                                <InsertDriveFileIcon />
                            </ListItemIcon>
                            <ListItemText primary={doc.docNombre} onClick={()=>downloadFile(doc.docCodigo)}  />
                        </ListItem>

                        )
                    }
    
      </List>

        </div>
    );
}