import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const SearchAfiliado = () => {
  const classes = useStyles();

  useEffect(() => {
    //let mounted = true;

    /*const fetchCustomers = () => {
      axios.get('/api/management/customers').then(response => {
        if (mouqueries
    fetchCustomers();*/

    return () => {
      //mounted = false;
    };
  }, []);

  //const handleFilter = () => {};


  return (
    <Page
      className={classes.root}
      title="Consultas"
    >
      <Header />
      
        <Results
          className={classes.results}
        />

    </Page>
  );
};

export default SearchAfiliado;