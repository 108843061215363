/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { ProductionReportProvider } from 'providers/productionReport.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    Card,
    Grid,
    Button,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { SearchBasic } from 'components';

export default function SearchAffiliate() {
    const tableRef = React.useRef();
    const displayMessage = new DisplayMessage();
    const reporte = new ProductionReportProvider();
    const [buscarDato, setBuscarDato] = useState('');
    const [booleano, setBooleano] = useState(false);
    const [total, setTotal] = useState(0);

    const [empresa, setEmpresa] = useState([]);
    const [empCodigo, setEmpCodigo] = useState('%');

    useEffect(() => {
        reporte.datosEmpresa().then(
            response => {
                if (response.error.codigo === '0') {
                    setEmpresa(response.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
            }
        );
    }, []);

    const loadData = (query, resolve) => {
        let filt = '';
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PER_IDENTIFICACION'
        };
        filt = orderBy.field;
        if (orderBy.field === 'CEDULA') {
            filt = 'PER_IDENTIFICACION';
        }
        if (orderBy.field === 'NOMBRES') {
            filt = 'PER_NOMBRES';
        }
        if (orderBy.field === 'APELLIDOS') {
            filt = 'PER_APELLIDOS';
        }
        if (orderBy.field === 'FECHA_NACIMIENTO') {
            filt = 'PER_FECHA_NACIMIENTO';
        }
        if (orderBy.field === 'TELEFONOS') {
            filt = 'NUMEROS_CONTACTO';
        }
        if (orderBy.field === 'CORREO') {
            filt = 'PER_MAIL';
        }
        if (orderBy.field === 'GENERO') {
            filt = 'DET_NOMBRE_0';
        }
        if (orderBy.field === 'PLAN') {
            filt = 'PLA_NOMBRE';
        }
        if (orderBy.field === 'FECHA REGISTRO') {
            filt = 'POL_FECHA_REGISTRO';
        }
        if (orderBy.field === 'VALOR MENSUAL') {
            filt = 'PLA_VALOR_MENSUAL';
        }
        if (orderBy.field === 'VALOR ANUAL') {
            filt = 'PLA_VALOR_ANUAL';
        }
        if (orderBy.field === 'COBERTURA') {
            filt = 'PEP_MONTO_COBERTURA';
        }
        if (orderBy.field === 'PERIODICIDAD') {
            filt = 'DET_NOMBRE_1';
        }
        if (orderBy.field === 'ESTADO') {
            filt = 'DET_NOMBRE';
        }
        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };

        reporte
            .reportProduccion(
                buscarDato,
                '%',
                '%',
                empCodigo,
                filt,
                direction.orderDirection,
                query.pageSize,
                query.pageSize * query.page
            )
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setTotal(response.bodyOut.data[0].TOTAL);
                        setBooleano(false);
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(true);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        setTotal(0);
                        displayMessage.error('No se encontró resultados')
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const dataReportsExport = typeResult => {
        reporte
            .dataReportsExport(
                typeResult,
                buscarDato,
                '',
                '',
                empCodigo,
                'PER_IDENTIFICACION',
                'asc',
                total,
                0
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        downloadFile(response.bodyOut.data, typeResult);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_PRODUCCION}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [columns] = useState([
        { title: 'Cedula', field: 'CEDULA' },
        { title: 'Nombre', field: 'NOMBRES' },
        { title: 'Apellidos', field: 'APELLIDOS' },
        { title: 'Fecha de Nacimiento', field: 'FECHA_NACIMIENTO' },
        { title: 'Telefonos', field: 'TELEFONOS' },
        { title: 'Correo', field: 'CORREO' },
        { title: 'Género', field: 'GENERO' },
        { title: 'Plan', field: 'PLAN' },
        { title: 'Fecha Registro', field: 'FECHA REGISTRO' },
        { title: 'Valor Mensual', field: 'VALOR MENSUAL' },
        { title: 'Valor Anual', field: 'VALOR ANUAL' },
        { title: 'Valor Débito', field: 'VALOR_DEBITO' },
        { title: 'Cobertura', field: 'COBERTURA' },
        { title: 'Periodicidad', field: 'PERIODICIDAD' },
        { title: 'Estado', field: 'ESTADO' },
        { title: 'Sucursal', field: 'SUCURSAL' },
        { title: 'Origen', field: 'ORIGEN' },
        { title: 'Broker', field: 'POL_BROKER' },
        { title: 'Forma de pago', field: 'FORMA_DE_PAGO' },
        { title: 'Cuenta', field: 'CUE_CUENTA' },
        { title: 'Descripción cuenta', field: 'DESCRIPCION_CUENTA' },
        { title: 'Cédula débito', field: 'CUE_IDENTIFICACION' }
    ]);

    const handleBuscarTitular = () => {
        if (buscarDato === '') {
            displayMessage.error('Ingrese un dato a buscar.');
        } else {
            tableRef.current.onSearchChange(buscarDato);
        }
    };
    const handleChange = e => {
        setBuscarDato(e.target.value);
    };

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        setBuscarDato('');
    };

    const handleChangeEmpresa = e => {
        tableRef.current.onQueryChange();
        setEmpCodigo(e.target.value);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={4} sm={12} xs={12} style={{ marginRight: 100 }}>
                    <SearchBasic
                        searchBar={buscarDato}
                        cleanSearchBar={handleLimpiar}
                        handleInputChange={handleChange}
                        complementaryMessage="Buscar"
                        handleSearch={handleBuscarTitular}
                    />
                </Grid>
                <Grid
                    //className={classes.item}
                    item
                    md={4}
                    xs={12}>
                    <FormControl
                        style={{
                            width: '100%'
                        }}
                        variant="outlined">
                        <InputLabel htmlFor="age-native-simple" shrink>
                            Empresa
                        </InputLabel>
                        <Select
                            displayEmpty
                            name="empresa"
                            onChange={e => handleChangeEmpresa(e)}
                            value={empCodigo}>
                            <MenuItem value={'%'}>
                                <em>Seleccione...</em>
                            </MenuItem>
                            {empresa.map(item => (
                                <MenuItem
                                    key={'empresa' + item.empCodigo}
                                    value={item.empCodigo}>
                                    {item.empRazonSocial}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{
                                borderRadius: 5,
                                marginRight: 10,
                                width: 100
                            }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(2);
                            }}>
                            <FaFileCsv size={20} style={{ marginRight: 10 }} />{' '}
                            CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a XLS">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(3);
                            }}>
                            <FaFileExcel
                                size={20}
                                style={{ marginRight: 10 }}
                            />
                            EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Card>
                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={
                        booleano === false
                            ? query =>
                                  new Promise(resolve => {
                                      loadData(query, resolve);
                                  })
                            : []
                    }
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            searchPlaceholder: 'Buscar',
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'
                        }
                    }}
                    options={{
                        exportButton: false,
                        paging: true,
                        toolbar: false,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        emptyRowsWhenPaging: false,
                        search: false,
                        rowStyle: rowData => ({
                            backgroundColor:
                                rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
                        })
                    }}
                    title="Afiliados"
                />
            </Card>
        </div>
    );
}
