/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { ProductionReportProvider } from 'providers/productionReport.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    CircularProgress,
    Card,
    CardActions,
    Grid,
    Button,
    TextField,
    Paper,
    Input,
    InputBase,
    Tooltip,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { DatePicker } from '@material-ui/pickers';
import 'moment/locale/es';
import moment from 'moment';
import { order } from 'mock/orderMock';
import { makeStyles } from '@material-ui/styles';
import { Search, Clear } from '@material-ui/icons';

moment.locale('es');

const useStyles = makeStyles(theme => ({
    root: {
        /* display: 'flex',
        alignItems: 'center' */
        adding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    search: {
        flexGrow: 1,
        height: 52,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    }
}));

export default function SearchAffiliate() {
    const classes = useStyles();
    const tableRef = React.useRef();
    const [selectedDate, handleDateChange] = useState(moment());
    const displayMessage = new DisplayMessage();
    const reporte = new ProductionReportProvider();
    const [cedula, setCedula] = useState('');
    const [buscarMes, setBuscarMes] = useState(moment());
    const [booleano, setBooleano] = useState(false);
    const [columns] = useState([
        { title: 'Cedula', field: 'CEDULA' },
        { title: 'Nombre', field: 'NOMBRES' },
        { title: 'Apellidos', field: 'APELLIDOS' },
        { title: 'Fecha de Nacimiento', field: 'FECHA_NACIMIENTO' },
        { title: 'Telefonos', field: 'TELEFONOS' },
        { title: 'Correo', field: 'CORREO' },
        { title: 'Género', field: 'GENERO' },
        { title: 'Plan', field: 'PLAN' },
        { title: 'Fecha Registro', field: 'FECHA REGISTRO' },
        { title: 'Valor Mensual', field: 'VALOR MENSUAL' },
        { title: 'Valor Anual', field: 'VALOR ANUAL' },
        { title: 'Valor Débito', field: 'VALOR_DEBITO' },
        { title: 'Cobertura', field: 'COBERTURA' },
        { title: 'Periodicidad', field: 'PERIODICIDAD' },
        { title: 'Estado', field: 'ESTADO' },
        { title: 'Sucursal', field: 'SUCURSAL' },
        { title: 'Origen', field: 'ORIGEN' },
        { title: 'Broker', field: 'POL_BROKER' },
        { title: 'Forma de pago', field: 'FORMA_DE_PAGO' },
        { title: 'Cuenta', field: 'CUE_CUENTA' },
        { title: 'Descripción cuenta', field: 'DESCRIPCION_CUENTA' },
        { title: 'Cédula débito', field: 'CUE_IDENTIFICACION' }
    ]);
    const [total, setTotal] = useState(0);
    const [empresa, setEmpresa] = useState([]);
    const [empCodigo, setEmpCodigo] = useState('%');

    useEffect(() => {
        reporte.datosEmpresa().then(
            response => {
                if (response.error.codigo === '0') {
                    setEmpresa(response.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
            }
        );
    }, []);

    const loadData = (query, resolve) => {
        let filt = '';
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PER_IDENTIFICACION'
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        filt = orderBy.field;
        if (orderBy.field === 'CEDULA') {
            filt = 'PER_IDENTIFICACION';
        }
        if (orderBy.field === 'NOMBRES') {
            filt = 'PER_NOMBRES';
        }
        if (orderBy.field === 'APELLIDOS') {
            filt = 'PER_APELLIDOS';
        }
        if (orderBy.field === 'FECHA_NACIMIENTO') {
            filt = 'PER_FECHA_NACIMIENTO';
        }
        if (orderBy.field === 'TELEFONOS') {
            filt = 'NUMEROS_CONTACTO';
        }
        if (orderBy.field === 'CORREO') {
            filt = 'PER_MAIL';
        }
        if (orderBy.field === 'GENERO') {
            filt = 'DET_NOMBRE_0';
        }
        if (orderBy.field === 'PLAN') {
            filt = 'PLA_NOMBRE';
        }
        if (orderBy.field === 'FECHA REGISTRO') {
            filt = 'POL_FECHA_REGISTRO';
        }
        if (orderBy.field === 'VALOR MENSUAL') {
            filt = 'PLA_VALOR_MENSUAL';
        }
        if (orderBy.field === 'VALOR ANUAL') {
            filt = 'PLA_VALOR_ANUAL';
        }
        if (orderBy.field === 'COBERTURA') {
            filt = 'PEP_MONTO_COBERTURA';
        }
        if (orderBy.field === 'PERIODICIDAD') {
            filt = 'DET_NOMBRE_1';
        }
        if (orderBy.field === 'ESTADO') {
            filt = 'DET_NOMBRE';
        }
        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };

        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }
        reporte
            .reportProduccion(
                cedula,
                selectedDate.format('YYYY'),
                subCadena,
                empCodigo,
                filt,
                direction.orderDirection,
                query.pageSize,
                query.pageSize * query.page
            )
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setBooleano(false);
                        setTotal(response.bodyOut.data[0].TOTAL);
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false);
                        setTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        displayMessage.error('No se encontró resultados')
                    }
                },
                () => {
                    setBooleano(false);
                    setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const handleBuscarTitular = () => {
        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }
        tableRef.current.onSearchChange(cedula);
        tableRef.current.onSearchChange(subCadena);
        tableRef.current.onSearchChange(selectedDate.format('YYYY'));
    };

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        handleDateChange(moment());
        setBuscarMes(moment());
        setCedula('');
        setEmpCodigo('%');
    };

    const dataReportsExport = typeResult => {
        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }
        if (selectedDate === '' && buscarMes === '') {
            displayMessage.error('Seleccione un año o mes.');
        } else {
            reporte
                .dataReportsExport(
                    typeResult,
                    cedula,
                    selectedDate.format('YYYY'),
                    `${subCadena}`,
                    empCodigo,
                    'PER_IDENTIFICACION',
                    'asc',
                    total,
                    0
                )
                .then(
                    response => {
                        if (
                            response.error.codigo === '0' &&
                            response.bodyOut.data.length
                        ) {
                            downloadFile(response.bodyOut.data, typeResult);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                    }
                );
        }
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_PRODUCCION}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChangeCedula = e => {
        setCedula(e.target.value);
    };

    const onPressEnter = event => {
        if (event.key === 'Enter') {
            handleBuscarTitular();
        }
    };

    const handleChangeEmpresa = e => {
        console.log(e.target.value);
        setEmpCodigo(e.target.value);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Paper className={classes.search} elevation={1}>
                        <InputBase
                            className={classes.input}
                            name={'searchBar'}
                            placeholder={`Buscar`}
                            value={cedula}
                            onChange={handleChangeCedula}
                            onKeyPress={onPressEnter}
                        />

                        {/*  <Input
                                        className={classes.searchInput}
                                        disableUnderline
                                        onChange={handleChangeCedula}
                                        placeholder="Buscar por cédula"
                                        value={cedula}
                                    /> */}
                    </Paper>
                </Grid>

                <Grid
                    //className={classes.item}
                    item
                    md={2}
                    xs={12}>
                    <DatePicker
                        format="MMMM"
                        inputVariant="outlined"
                        label="Seleccione mes"
                        onChange={setBuscarMes}
                        value={buscarMes}
                        views={['month']}
                    />
                </Grid>

                <Grid
                    //className={classes.item}
                    item
                    md={2}
                    xs={12}>
                    <DatePicker
                        inputVariant="outlined"
                        label="Seleccione año"
                        onChange={handleDateChange}
                        value={selectedDate}
                        variant="inline"
                        views={['year']}
                    />
                </Grid>
                <Grid
                    //className={classes.item}
                    item
                    md={4}
                    xs={12}>
                    <FormControl
                        style={{
                            width: '100%'
                        }}
                        variant="outlined">
                        <InputLabel htmlFor="age-native-simple" shrink>
                            Empresa
                        </InputLabel>
                        <Select
                            displayEmpty
                            name="empresa"
                            onChange={e => handleChangeEmpresa(e)}
                            value={empCodigo}>
                            <MenuItem value={'%'}>
                                <em>Seleccione...</em>
                            </MenuItem>
                            {empresa.map(item => (
                                <MenuItem
                                    key={'empresa' + item.empCodigo}
                                    value={item.empCodigo}>
                                    {item.empRazonSocial}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ padding: 0 }} sm={4} xs={12}>
                    <Button
                        className={classes.searchButton}
                        //color="primary"
                        onClick={handleBuscarTitular}
                        variant="contained"
                        size="small">
                        <Search />
                        Buscar
                    </Button>
                    <Button
                        className={classes.searchButton}
                        color="primary"
                        onClick={handleLimpiar}
                        variant="contained"
                        size="small">
                        <Clear />
                        Limpiar
                    </Button>
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{
                                borderRadius: 5,
                                marginRight: 10,
                                width: 100
                            }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(2);
                            }}>
                            <FaFileCsv size={20} style={{ marginRight: 10 }} />{' '}
                            CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(3);
                            }}>
                            <FaFileExcel
                                size={20}
                                style={{ marginRight: 10 }}
                            />{' '}
                            EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <Card>
                <MaterialTable
                    tableRef={tableRef}
                    actions={[
                        {
                            icon: () => <FaFileCsv />,
                            tooltip: 'Exportar a CSV',
                            isFreeAction: true,
                            onClick: () => {
                                dataReportsExport(2);
                            }
                        },
                        {
                            icon: () => <FaFileExcel />,
                            tooltip: 'Exportar a XLS',
                            isFreeAction: true,
                            onClick: () => {
                                dataReportsExport(3);
                            }
                        }
                    ]}
                    columns={columns}
                    data={
                        booleano === false
                            ? query =>
                                  new Promise(resolve => {
                                      loadData(query, resolve);
                                  })
                            : []
                    }
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            searchPlaceholder: 'Buscar',
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'
                        }
                    }}
                    options={{
                        exportButton: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        emptyRowsWhenPaging: false,
                        search: false,
                        toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor:
                                rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
                        })
                    }}
                    title="Afiliados"
                />
            </Card>
        </div>
    );
}
