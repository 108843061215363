/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { ManagerReportProvider } from 'providers/managerreports.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card, Grid, Tooltip, Button } from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

export default function SearchAffiliate() {

    const [data, setData] = useState([]);
    const manager = new ManagerReportProvider();
    const displayMessage = new DisplayMessage();

    useEffect(() => {
        manager.dataReports()
            .then(
                user => {
                    const arreggloDatos = [];
                    for (const mont of user.bodyOut.data) {
                        const dataConDecimales = {
                            plaNombre: mont.plaNombre, plaProducto: mont.plaProducto, plaValorAnual: mont.plaValorAnual.toFixed(2), sumaTotalPolizas: mont.sumaTotalPolizas.toFixed(2),
                            numeroPolizas: mont.numeroPolizas, valorMensualPoliza: mont.valorMensualPoliza.toFixed(2), valorMensual: mont.valorMensual.toFixed(2), periodicidad: mont.periodicidad
                        }
                        arreggloDatos.push(dataConDecimales)
                    }
                    setData(arreggloDatos);
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    }, []);

    const dataReportsExport = (typeResult) => {
        manager.dataReportsExport(typeResult).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_PRIMAS}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    const [columns] = useState([
        { title: 'Nombre', field: 'plaNombre' },
        { title: 'Descripcion', field: 'plaProducto' },
        { title: 'Valor anual', field: 'plaValorAnual' },
        { title: 'Total', field: 'sumaTotalPolizas' },
        { title: 'N° Polizas', field: 'numeroPolizas' },
        { title: 'Valor Mensual por poliza', field: 'valorMensualPoliza' },
        { title: 'Valor Mensual', field: 'valorMensual' },
        { title: 'Periodicidad', field: 'periodicidad' }
    ]);

    return (
        <div>
            {data ?
                <>

                    <Grid
                        container
                        spacing={2}
                    >                        
                        <Grid
                            item                            
                            xs={12}
                            style={{ paddingLeft: 1, textAlign: 'right', marginBottom:10}}
                        >
                            <Tooltip title="Exportar a CSV">
                                <Button
                                    style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => { dataReportsExport(2); }}
                                >

                                    <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                                </Button>
                            </Tooltip>
                            <Tooltip title="Exportar a EXCEL">
                                <Button
                                    style={{ borderRadius: 5,  width: 100}}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => { dataReportsExport(3); }}
                                >
                                    <FaFileExcel size={20} style={{ marginRight: 10 }} /> EXCEL
                                </Button>
                            </Tooltip>

                        </Grid>                        
                    </Grid>
                    <Card>
                        <MaterialTable

                            columns={columns}
                            data={data}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    searchPlaceholder: 'Buscar',
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination: {
                                    labelRowsSelect: 'Filas',
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'
                                }
                            }}
                            options={{
                                exportButton: false,
                                paging: true,
                                toolbar: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 50, 100],
                                emptyRowsWhenPaging: false,
                                rowStyle: rowData => ({
                                    backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                                }),
                            }}
                            title="Reportes"
                        />
                    </Card></>
                : <CircularProgress />}
        </div>
    );
}

