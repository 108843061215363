import React, { Component } from 'react';
import { GenericList } from 'components';
import { FaRegFolder } from 'react-icons/fa';

import { Page } from 'components';
import { DetalleCatalogoProvider } from './../../providers/detalleCatalogo.service';
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import { HelpDialog } from 'components';
import { HelpContent } from './components';

import './styles.css';
export default class FormsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allForms: null,
            searchWord: '',
            openHelpDownload: false
        }
    }
    componentDidMount = () => {
        this.findAllFormularios(this.state.searchWord);
    }

    handleCloseDownload = () => {
        this.setState({
            openHelpDownload: false
        });
    }

    handleOpenDownload = () => {
        this.setState({
            openHelpDownload: true
        });
    }


    handleSearchChange = (evt) => {
        this.setState({
            searchWord: evt.target.value
        });
        this.findAllFormularios(evt.target.value);


    }


    findAllFormularios = (v) => {
        const dCP = new DetalleCatalogoProvider();

        dCP.findLikeName(3, 'FORM', v).then(
            response => {
                this.setState({
                    allForms: response
                });
            }

        )

    }

    titles =  [

        {
            id: 1,
            name: 'Nombre'
        },
        {
            id: 2,
            name: <b>
                    Descargar
                <IconButton aria-label="Ayuda" onClick={this.handleOpenDownload} >
                    <HelpIcon />
                </IconButton>

            </b>
        }



    ]
    

    render() {

        return (

            <Page
                title="Formularios"
            >
                <div className='total-container' >
                    {
                        this.state.allForms ?
                            <GenericList
                                elements={this.state.allForms}
                                titles={this.titles}
                                formTitle='Formularios'
                                formIcon={<FaRegFolder />}
                                valSearch={this.state.searchWord}
                                handleSearchChange={this.handleSearchChange}
                                elementIcon={<FaRegFolder />}

                            >

                            </GenericList> :
                            <CircularProgress />

                    }


                </div>

                <HelpDialog
                    text={<HelpContent />}
                    open={this.state.openHelpDownload}
                    handleClose={this.handleCloseDownload}
                ></HelpDialog>

            </Page>

        );
    }


}