import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import 'moment/locale/es';
import { CircularProgress, Grid, Tooltip, Button } from '@material-ui/core';
import moment from 'moment';

moment.locale('es')



export default function SearchAffiliate() {

    const [data, setData] = useState(0);
    const queries = new QueriesProvider();
    const [booleano, setBooleano] = useState(false);
    const [total, setTotal] = useState(0);
    const displayMessage = new DisplayMessage();
    //const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'RUC', field: 'RUC' },
        { title: 'Compañia', field: 'COMPAÑIA' },
        { title: 'Documento Titular', field: 'NUMERO_DOCUMENTO_TITULAR' },
        { title: 'Documento Titular 2', field: 'NUMERO_DOCUMENTO_TITULAR_0' },
        { title: 'Nombre', field: 'PER_NOMBRES' },
        { title: 'Apellidos', field: 'PER_APELLIDOS' },
        { title: 'Incio Poliza', field: 'POL_FECHA_INICIO' },
        { title: 'Fin Poliza', field: 'POL_FECHA_FIN' },
        { title: 'Plan', field: 'PLA_NOMBRE' },
        { title: 'Monto', field: 'PEP_MONTO_COBERTURA' }

    ]);

    const loadData = (query, resolve) => {
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'RUC',
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }
        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }



        queries.reporteAcess(orderBy.field, direction.orderDirection, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setTotal(response.bodyOut.data[0].TOTAL)
                        setBooleano(false)
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(true)
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        setTotal(0);
                    }

                },
                () => {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );
    }


    const dataReportsExport = (typeResult) => {

        queries.dataReportsExport(typeResult, "RUC", "asc", total, 0).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };


    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_ACESS}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte_Access.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    return (
        <div>

            <Grid
                container
                spacing={2}
            >                
                <Grid
                    item                    
                    xs={12}
                    style={{ paddingLeft: 1, textAlign: 'right', marginBottom:10}}
                >
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(2); }}
                        >
                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5,  width: 100}}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(3); }}
                        >
                            <FaFileExcel size={20} style={{ marginRight: 10 }} /> EXCEL
                        </Button>
                    </Tooltip>

                </Grid>                
            </Grid>

            <MaterialTable

                columns={columns}
                data={booleano === false ? (query =>
                    new Promise((resolve) => {
                        loadData(query, resolve)

                    })) : []}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No hay datos que mostrar'
                    },
                    toolbar: {
                        search: false,
                        exportName: 'Exportar a CSV',
                        exportAriaLabel: 'Exportar',
                        exportTitle: 'Exportar'
                    },
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'


                    }
                }}
                options={{
                    exportButton: false, search: false, toolbar: false,

                    rowStyle: rowData => ({
                        backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                    }),
                }}
                title="Access"
            />
        </div>
    );
}