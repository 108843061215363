import React, { Component } from 'react';
import {Page} from 'components';
import TextField from '@material-ui/core/TextField';
import { CardContent, CardHeader, Card, Button, Divider } from '@material-ui/core';
import { DisplayMessage } from 'notifications/displayMessage';
import { PlanProvider } from './../../providers/plan.service';

export default class AddPlanView extends Component{

    constructor(props){
        super(props);
        this.state={
            name:'',
            maximumCoverage:0,
            deductibleValue: 0,
            anualValue:0,
            monthlyValue:0,
            discountRate:0,
            childrenAge:0,
            HolderAge:0,
            beneficiariesNumber:0,

            AllCategories: null,
            category: null,
            description: '',
            nemonic: '',
            type: '',
            origin: '',
            destination: '',
            disabled: true,
           
            
        }
        this.displayMessage = new DisplayMessage();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.pP = new PlanProvider();
    }

    componentDidMount= ()=> {
        // this.findAllDetalleCatalogo();
    }

    setCategory = async( value ) => {
        console.log('SetCategory value')
        console.log(value)
        await this.setState({
            category: value,
            type: value ? value.catGrupo: ''
        });

        await this.enDisButton();

    }

    handleInputChange = async(evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        await this.setState({
            [name]: value
        });


        await this.enDisButton();

    }

    AddPlan = async() => {
        if(
            this.state.name!=='' 
        && this.state.description !==''
        ){

        
            const plan = [];
            await plan.push(
                {
                    'plaNombre': this.state.name,
                    'plaTipo': this.state.type,
                    'plaCoberturaMaxima': parseFloat(this.state.maximumCoverage) ,
                    'plaValorDeducible': parseFloat(this.state.deductibleValue),
                    'plaRutaContrato': '/data/wmp/poliza/PLATINUM-signed.pdf',
                    'plaValorAnual': parseFloat(this.state.anualValue),
                    'plaValorMensual': parseFloat(this.state.monthlyValue),
                    'plaPorDescuento': parseFloat(this.state.discountRate),
                    'plaEdadHijos': parseInt(this.state.childrenAge),
                    'plaEdadTitular': parseInt(this.state.HolderAge),
                    'plaNumBeneficiarios': parseInt(this.state.beneficiariesNumber),
                    'plaProducto': this.state.description,
                    'plaEstado': 1,
                    'detTipo': 20,
                    'detModalidad':25,
                    'detDeducible': 30,
                    'detTarifario': 31
                }
            )
            await console.log('plan-guardar')

            await console.log(plan)
            await this.pP.createPlan(plan).then(
                (res) => {
                    console.log('intentando guardar plan');
                    console.log(res.bodyOut.resultados);
                    console.log('------Largo');
                    if(res.bodyOut.resultados.length > 0 ){
                        this.displayMessage.success('Agregado con exito');
                
                    }

                    console.log(res.bodyOut.resultados.length);

                }
            ).catch(
                (err)=> {
                    this.displayMessage.error(err);


                }
            );
            this.setState({
                category: null,
                name:'',
                description: '',
                nemonic: '',
                type: '',
                origin: '',
                destination: ''
            });

        }else {
            this.displayMessage.error('Existen campos vacios');
        }
    }

    enDisButton = () => {
        if(
            this.state.name!=='' 
        && this.state.description !==''
       
        ){
            this.setState({disabled: false})
        }else {
            this.setState({disabled: true})

        }
    }
    /** 
    findAllDetalleCatalogo=()=> {
        this.dCP.allCatalogos().then(
            (resp) => {
                this.setState({
                    AllCategories: resp
                });
            }
        );
    }*/

    render(){
        return(
            <Page
                title='Crear Plan'
            >
                <div className='medium-space'>
                    <Card>
                        <CardHeader title = "Crear Plan"
                        >

                        </CardHeader>
                        <Divider /> 

                        <CardContent>
                            <div className='medium-space'>

                                <TextField 
                                    id="name" 
                                    label="Nombre" 
                                    variant="outlined" 
                                    name='name' 
                                    value={this.state.name} 
                                    onChange={this.handleInputChange} 
                                    fullWidth 
                                    required/>
                            </div>
                            <br/>
                            
                            <div className='medium-space'>

                                <TextField id="description" label="Descripción" variant="outlined" name='description' value={this.state.description} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="outlined-basic" label="Tipo Ej(BASI-TITP)" variant="outlined" name='type' value={this.state.type} onChange={this.handleInputChange} required fullWidth />
                                
                            </div>
   
                            <br/>
                            <div className='medium-space'>

                                <TextField id="maximumCoverage" type='number' label="Cobertura maxima" variant="outlined" name='maximumCoverage' value={this.state.maximumCoverage} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="deductibleValue" type='number' label="Valor Deducible" variant="outlined" name='deductibleValue' value={this.state.deductibleValue} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="anualValue" type='number' label="Valor Anual" variant="outlined" name='anualValue' value={this.state.anualValue} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="monthlyValue" type='number' label="Valor Mensual" variant="outlined" name='monthlyValue' value={this.state.monthlyValue} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="discountRate" type='number' label="Porcentaje de Descuento" variant="outlined" name='discountRate' value={this.state.discountRate} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="childrenAge" type='number' label="Edad Hijos" variant="outlined" name='childrenAge' value={this.state.childrenAge} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                            <div className='medium-space'>

                                <TextField id="HolderAge" type='number' label="Edad del Titular" variant="outlined" name='HolderAge' value={this.state.HolderAge} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>

                            <div className='medium-space'>
                                <TextField id="beneficiariesNumber" type='number' label="Numero de Beneficiarios" variant="outlined" name='beneficiariesNumber' value={this.state.beneficiariesNumber} onChange={this.handleInputChange} required fullWidth />
                            </div>

                            <br/>
                           

                           

                            
                            
                           



                        </CardContent>



                    </Card>



                </div>
                <div className='medium-space '>
                    <Button  variant="contained" color="primary"
                        disabled={this.state.disabled}
                        onClick={this.AddPlan} fullWidth>Guardar</Button>
                </div>
   
            </Page>
        );
    }
}