/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import { PersonalProvider } from 'providers/persona.service';
import GenericHeaderForms from 'components/GenericHeaderFoms';



const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const DataUpdateForm = (props) => {
    const classes = useStyles();
    const pP = new PersonalProvider();
    const [ idRequirement, setIdRequirement] = useState(0);
    const [ userRequirement, setUserRequirement] = useState(null);
    useEffect(() => {
        console.log("ejecuto el useEffect");

    
        const promise = new Promise((resolve, reject) => {
            const val = atob(props.match.params.id)
            console.log("-----------setIdRequirement");
            console.log(val);
            setTimeout(
                ()=> val >0
                    ? resolve(val)
                    : reject(0)
                ,100
            );
        }
  

        );
        promise.then(
            res => {
                setIdRequirement(res);
                return res;
            }
        ).catch(
            err=> {
                setIdRequirement(err);
                return null
            }

        ).then(
            resp => {
        
                getUserRequirement(resp)

            }
      
        )
    /**const promise = new Promise(
      (resolve, reject) => {
        ()=> setIdRequirement(atob(props.match.params.id))
        ? resolve(idRequirement)
        : reject(new Error("No se ha reconocido ningun id"))
        ,100  
      }
    ).then(
      (resp) => {
        getUserRequirement();

      }

    );
   

     return () => {
        };*/
    }, []);
 


    // const [ idRequirement, setIdRequirement] = useState(0);

  
  
    const getUserRequirement = (resp) => {
        console.log("desde el getUserRequirement idRequirement")
        console.log(resp)

        console.log(idRequirement)
        if(resp > 0){
    
            pP.LiquidationByliqCodigo(resp).then(
                (response) => {
                    try {
                        console.log("desde el getUserRequirement response[o]")

                        console.log(response[0])
                        setUserRequirement(response[0]);
                    }catch(error){
                        console.error(error)
  
                    }
                }
    
            );
  
        }
  
    }

    //const [count, setCount] = useState(0);

  

    //const handleFilter = () => {};


    return (
        <Page
            className={classes.root}
            title="Actualización Datos"
        >
            <Header/>

            {
        
                userRequirement ? 
                    <GenericHeaderForms
                        requirement={`REQ-${userRequirement.liqCodigo}`}
                        name={userRequirement.usuNombre}
                    >
                    </GenericHeaderForms>: ""
            }
            <br></br>
            <Results
                className={classes.results}
            />
        </Page>
    );
};

export default DataUpdateForm;