import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Add, Visibility } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {}
}));

const Header = props => {
    const { view, setView, className, ...rest } = props;

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <Grid
                alignItems="flex-end"
                container
                justify="space-between"
                spacing={3}>
                <Grid item>
                    {/* <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
            Sistema de medicina prepagada Medicompanies S.A. antes Caridel S.A.
                    </Typography> */}
                    <Typography component="h1" variant="h3">
                        Reporte Producción Finanzas
                    </Typography>
                </Grid>
                {/* <Grid item>
                    { view === 0  ? (
                        <Button
                            color="primary"
                            onClick={()=> setView(1)}
                            size="small"
                            startIcon={
                                <Visibility/>
                            }
                            variant="contained"
                        >
                            Ver usuarios
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            onClick={()=> setView(0)}
                            size="small"
                            startIcon={
                                <Add/>
                            }
                            variant="contained"
                        >
                            Nuevo Usuario
                        </Button>
                    )

                    }
                   
                </Grid> */}
            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string
};

export default Header;
