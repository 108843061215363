/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, CircularProgress, Button } from '@material-ui/core';
import CardLiquidator from './CardLiquidator';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    dates: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    startDateButton: {
        marginRight: theme.spacing(1)
    },
    endDateButton: {
        marginLeft: theme.spacing(1)
    },
    calendarTodayIcon: {
        marginRight: theme.spacing(1)
    },
    container: {
        marginTop: theme.spacing(3)
    }
}));

const HeaderLiquidator = props => {
    const { className, session, ...rest } = props;
    const classes = useStyles();
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();

    const [liquidationsCount, setLiquidationsCount] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        let mounted = true;

        const fetchLiquidations = () => {
            setLoading(true);
            liquidationProvider.liquidacionCountByEstado('ESTLI').then(response => {            
                if(response.error.codigo === '0' && response.bodyOut.data.length){
                    if (mounted) {
                        setLiquidationsCount(response.bodyOut.data[0].existencias);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }                  
            },
            () => {          
                displayMessage.error('Error de comunicación.');        
            }); 
        };

        fetchLiquidations();

        return () => {
            mounted = false;
        };
    }, []);

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Typography
                component="h2"
                gutterBottom
                variant="overline"
            >
                Inicio
            </Typography>
            <Typography
                component="h1"
                gutterBottom
                variant="h3"
            >
                Bienvenid@, {session.user.usuNombre}
            </Typography>
            <Grid
                className={classes.container}
                container
                spacing={3}
            >
                <Grid
                    container
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >                 
                    {!loading ? 
                        <Button 
                            component={RouterLink} 
                            style={{ justifyContent: 'flex-start', width: '100%'}}
                            to={'/liquidador/liquidaciones/digitalizar'}
                        >
                            <CardLiquidator 
                                count={liquidationsCount} 
                                style={{ width: '100%' }}
                            />
                        </Button>                        
                        : <CircularProgress />
                    }
                </Grid>  
            </Grid>
        </div>    
    );  
};

HeaderLiquidator.propTypes = {
    className: PropTypes.string,
    session: PropTypes.object
};

export default HeaderLiquidator;
