import { DataService } from 'providers/dataService';

export class WalletProvider {
    constructor() {
        this.dataService = new DataService();
    }

    consultaCartera(fecha, value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'CARTE',
            parametros: {
                0: fecha,
                1: value,
                2: value,
                3: value,
                4: value,
                5: fecha,
                6: value,
                7: value,
                8: value,
                9: value,
                10: field,
                11: order,
                12: numero,
                13: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExport(typeExport, fecha, value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'CARTE',
            parametros: {
                0: fecha,
                1: value,
                2: value,
                3: value,
                4: value,
                5: fecha,
                6: value,
                7: value,
                8: value,
                9: value,
                10: field,
                11: order,
                12: numero,
                13: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
}
