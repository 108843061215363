import { DataService } from 'providers/dataService'

export class LeaderProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosPrestador = ( preCodigo ) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Prestador',
            parametros: {
                preCodigo: preCodigo
            }
        }        
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

}
