import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import { CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { LeaderProvider } from 'providers/leader.service';
import { DisplayMessage } from 'notifications/displayMessage';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const AddLeaderEdit = (props) => {
    const leaderProvider = new LeaderProvider();
    const displayMessage = new DisplayMessage();

    const classes = useStyles();
    const [preCodigo] = useState(atob(props.match.params.id));
    const [datosPrestador, setDatosPrestador] = useState(0);
    useEffect(() => {
        prestador()
    }, [preCodigo])

    const prestador = () => {
        leaderProvider.datosPrestador(preCodigo)
            .then(
                data => {
                    if (data.error.codigo !== '0') {
                        setDatosPrestador([])
                    } else {
                        setDatosPrestador(data.bodyOut.data[0])
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación')
                }

            );
    }

    return (
        <Page
            className={classes.root}
            title="Editar prestador"
        >
            <Header />
            <br />
        {datosPrestador? 
            <Results
                className={classes.results}
                datosPrestador={datosPrestador}
            />
        :<CircularProgress/>}

        </Page>
    );
};

export default AddLeaderEdit;