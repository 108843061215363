import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { RefundFormProvider } from 'providers/refundForm.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Cancel, Search, Clear } from '@material-ui/icons'
import { CircularProgress, Card, CardHeader, CardActions, Grid, TextField, Button, IconButton, Dialog } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchAffiliate(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const refunFormProvider = new RefundFormProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Codigo', field: 'detNemonico' },
        { title: 'Categoria', field: 'detNombre' },
        { title: 'Descripción', field: 'detDescripcion' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');
    const [buscarApellido, setBuscarApellido] = useState('');
    const [buscarNombre, setBuscarNombre] = useState('');

    const handleBuscarTitular = () => {
        setData(0);

        if (buscarTitular === '' && buscarNombre === '' && buscarApellido === '') {
            displayMessage.error('Ingrese un código a buscar')
            setData([])
        } else {
            refunFormProvider.comboEnfermedades('6', buscarTitular, buscarNombre, buscarApellido)
                .then(
                    data => {
                        if (data.bodyOut.data.length === 0) {
                            displayMessage.error('CIE10 no encontrado.');
                            setData([])
                        } else {
                            displayMessage.success('CIE10 encontrado')
                            setData(data.bodyOut.data)
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación')
                    }

                );
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleChangeNombre = (e) => {
        setBuscarNombre(e.target.value);
    }

    const handleChangeApellido = (e) => {
        setBuscarApellido(e.target.value);
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    const handleData = (e) => {
        props.datos(e.detCodigo, e.detDescripcion)
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                    ><Cancel /></IconButton>}
                    title='Enfermedades CIE10'
                />
                <CardActions>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >

                                <TextField
                                    fullWidth
                                    name="buscarTitular"
                                    onChange={handleChange}
                                    placeholder="Buscar por código"
                                    value={buscarTitular}
                                />



                            </Grid>
                            <Grid

                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >

                                <TextField
                                    fullWidth
                                    name="buscarApellido"
                                    onChange={handleChangeApellido}
                                    placeholder="Buscar por categoria"
                                    value={buscarApellido}
                                />



                            </Grid>
                            <Grid

                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >

                                <TextField
                                    fullWidth
                                    name="buscarNombre"
                                    onChange={handleChangeNombre}
                                    placeholder="Buscar por descripción"
                                    value={buscarNombre}
                                />



                            </Grid>
                            <Grid
                                item
                                sm={2}
                                xs={6}
                            >

                                <Button
                                    color="primary"
                                    onClick={handleBuscarTitular}
                                    variant="contained"
                                    size="small"
                                ><Search />Buscar</Button>


                            </Grid>
                            <Grid

                                item
                                sm={2}
                                xs={6}
                            >

                                <Button
                                    color="primary"
                                    onClick={handleLimpiar}
                                    variant="contained"
                                    size="small"
                                ><Clear />Limpiar</Button>


                            </Grid>
                        </Grid>
                    </div>

                </CardActions>

                {data ?
                    data.length ?
                        <MaterialTable
                            actions={[
                                {
                                    icon: 'check',
                                    tooltip: 'Seleccionar',
                                    onClick: (event, rowData) => {
                                        handleData(rowData)
                                        handleClose()
                                    }
                                }
                            ]}
                            columns={columns}
                            data={data}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination: {
                                    labelRowsSelect: 'Filas',
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'


                                }
                            }}
                            options={{ exportButton: false, search: false, toolbar: false }}
                            title="CIE10"
                        /> : ''
                    : <CircularProgress />}
            </Card><br />
        </Dialog>
    );
}