/* eslint no-eval: 0 */
import React, { Component } from 'react';
import { CardContent, CardHeader, IconButton, Card, Button, Divider, Dialog, Grid, TextField, DialogActions } from '@material-ui/core';
import { Cancel } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { PersonalProvider } from 'providers/persona.service';
import { TelefonoProvider } from 'providers/telefono.service';
import { LocationProvider } from 'providers/location.service';
import { DatePicker } from '@material-ui/pickers';
import 'moment/locale/es';
import moment from 'moment';
import { DisplayMessage } from 'notifications/displayMessage';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { validateOnlyLetters } from './../../../../services/validation';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import {  DebitoAcceptModal} from './components';

const styles = {
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    backdrop: {
        color: '#fff'
    }
}

class PersonUpdateModal extends Component {

    constructor(props){
        super(props);
        const { session } = props;
        this.state = {
            session: session,
            inputSearchLocation: '',
            personId: 0,
            allTypeCI:[],
            allGenders: [],
            allBlackList: [],
            allProvincies: [],
            allTypePhone: [],
            allTypeAccounts: [],
            allIFI: [],
            identification: '',
            typeIdentification: 0,
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            typePhoneSelected: 23584,
            phoneNumber: '',
            observationPhone: '',
            phoneNumbers: [],
            openAddState: false,
            openAddAccount: false,
            disabledSaveButton: false,
            isDebit: false,
            openAcceptDebit: false,
            siniestroso: 0,
            errors: {
                identification: false,
                typeIdentification: false,
                gender: false,
                location: false,
                name: false,
                lastname: false,
                nacionality: false,
                direction: false,
                mail: false,
                birthdate: false,
                typePhoneSelected: false,
                phoneNumber: false,
                observationPhone: false,
                phoneNumbers: false,
                accountNumber: false,
                accountOwnerIdentification: false,
                accountOwnerName: false,
                siniestroso: false,

            },
            messages: {
                identification: '',
                typeIdentification: '',
                gender: '',
                location: '',
                name: '',
                lastname: '',
                nacionality: '',
                direction: '',
                mail: '',
                birthdate: '',
                typePhoneSelected: '',
                phoneNumber: '',
                observationPhone: '',
                phoneNumbers: '',
                accountNumber: '',
                accountOwnerIdentification: '',
                accountOwnerName: '',
                siniestroso: '',

            }
            
        }

        this.locationProvider = new LocationProvider();
        this.detalleCatalogoProvider = new DetalleCatalogoProvider();
        this.personalProvider = new PersonalProvider();
        this.telefonoProvider = new TelefonoProvider();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.displayMessage = new DisplayMessage();
        
    }
    componentDidMount = () => {
        
        this.getAllDetCatalogo('IDE','allTypeCI'); /** Tipo de Identificacion */
        this.getAllDetCatalogo('GENER','allGenders');/** Tipo de Generos */
        this.getAllDetCatalogo('TIPTEL','allTypePhone');/** Tipo de Generos */
        this.getAllProvincies('');
        this.getAllDetCatalogo('IFI','allIFI')/** SETEAR allIFI */        
        this.getAllDetCatalogo('BKLIST','allBlackList');/** Tipo de siniestros */
        this.getAllDetCatalogo('TIPCT','allTypeAccounts')/** SETEAR allIFI */        

    }
    
    /** -------------------------------------Aceptacion de cuenta de debito */
    handleAcept = () => {
        const {accountNumber, ifi, typeAccount, bankAccounts, saveBanksAccountsArray, accountOwnerIdentification, accountOwnerName } = this.props;
        const { isDebit } = this.state;
        const arrayNumbers = bankAccounts;
        const foundedAccount = arrayNumbers.find(element => element.cueDebito === 1 && element.cueEstado === 1);

        this.setState({openAcceptDebit: false});
        var i =  arrayNumbers.indexOf(foundedAccount);
        arrayNumbers[i].cueDebito = 0;
                            
        arrayNumbers.push({
            cueCuenta: accountNumber,
            detIfi: ifi.detCodigo,
            detNombreIfi: ifi.detNombre,
            detTipo: typeAccount.detCodigo,
            detNombreTipoCuenta: typeAccount.detNombre,
            cueIdentificacion: accountOwnerIdentification,
            cueNombreDebito:accountOwnerName,
            cueDebito: isDebit ? 1 : 0,
            cueEstado: 1
        })
        saveBanksAccountsArray(arrayNumbers);

    }

    handleReject = () => {
        this.setState({openAcceptDebit: false});
    }
    /**---------------------------------------Abrir debito modal aceptar */
    handleOpenDebitoAcceptModal = () => {
        this.setState({openAcceptDebit: true});
    }
    cleanErrors = () => {
        this.setState({
            errors: {
                identification: false,
                typeIdentification: false,
                gender: false,
                location: false,
                name: false,
                lastname: false,
                nacionality: false,
                direction: false,
                mail: false,
                birthdate: false,
                typePhoneSelected: false,
                phoneNumber: false,
                observationPhone: false,
                phoneNumbers: false,
                siniestroso: false,
            },
            messages: {
                identification: '',
                typeIdentification: '',
                gender: '',
                location: '',
                name: '',
                lastname: '',
                nacionality: '',
                direction: '',
                mail: '',
                birthdate: '',
                typePhoneSelected: '',
                phoneNumber: '',
                observationPhone: '',
                phoneNumbers: '',
                siniestroso: '',
            }
        })
    }
    

    showEmpties = () => {
        const {identification,typeIdentification, location, gender, name, lastname, nacionality, direction, mail, birthdate, siniestroso} = this.props;
      
        this.notifyEmptyNulls('identification',identification);
        this.notifyEmptyNulls('typeIdentification',typeIdentification);
        this.notifyEmptyNulls('gender',gender);
        this.notifyEmptyNulls('name',name);
        this.notifyEmptyNulls('lastname',lastname);
        this.notifyEmptyNulls('nacionality',nacionality);
        this.notifyEmptyNulls('direction',direction);
        this.notifyEmptyNulls('mail',mail);
        this.notifyEmptyNulls('birthdate',birthdate);
        this.notifyEmptyNulls('location',location);
        this.notifyEmptyNulls('siniestroso',siniestroso);

    }
    /**  ------------------------------------------------------NOtificar NUlos*/

    notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                this.setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                this.setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                this.setErrorsMessages(nameElement, false, '');
            }
        } 
    }
    
    setErrorsMessages = (nameElement, stateElement, message) => {
        
        const errors = this.state.errors;
        const messages = this.state.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;

        this.setState({
            errors: errors,
            messages:messages
        });
    }

    /** ----------------------------------------Traer todas lOS Detalle Catalogo por catCodigo*/
    getAllDetCatalogo = (catNemonico, nameData) => {
        this.detalleCatalogoProvider.findDetalleCatalogoByNemonico(catNemonico).then(
            response => {
                this.changeOneState(nameData, response)
            }
        );
    }

    /** ----------------------------------------Traer las ubicaciones (Provincias)*/
    getAllProvincies = (name) => {
        this.locationProvider.findLocationsLikeNombre(name).then(
            response => {
                this.changeOneState('allProvincies', response)
            }
        );
       
    }

    /** ----------------------------------------Abrir y cerrar el modal*/
    handleClickOpen = () => {
        const { setOpenPersonModal, person } = this.props;
        setOpenPersonModal(true, person);
    };
    
    handleClose = () => {
        const { setOpenPersonModal } = this.props;
        setOpenPersonModal(false, null);  
        this.cleanErrors();  
        this.setState({openAddState: false, openAddAccount: false});
    };

    /**------------------------------------------OnChange autocomplete */
    onChangeElement = (event, values) => {
        /** Ninguna de las id deben tener option */
        this.changeOneState('location', values);

    }
    /**-----------------------------------------------handleInputChange (Tecleo en Textfield) */
    handleInputChange = (event ) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'identification':
                if(value.length < 16){
                    this.changeOneState(name, value);
                }
                break;
            case 'name':
                if(validateOnlyLetters(value)){
                    this.changeOneState(name, value);
                }
                break;
            case 'lastname':
                if(validateOnlyLetters(value)){
                    this.changeOneState(name, value);
                }
                break;
            case 'inputSearchLocation':
                if(value.length > 2){
                    this.getAllProvincies(value);
                }
                break;
                
            default:
                this.changeOneState(name, value);
                break;
        }
    }
    /** ----------------------------------------------cambiar el debito */
    handleChangeIsDebit = event => {
        this.setState({ isDebit: event.target.checked} )
    };
    /** -----------------------------------------------Cambiar Fechas */
    handleDateChange = ( val ) =>{
        this.changeOneState('birthdate', val);
    }
    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState,value) => {
        this.setState({
            [nameState]: value
        })
    }

    /** -----------------------------------------------Limpiar campos */
    cleanFields = () => {
        this.setState({
            identification: '',
            typeIdentification: 0,
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            siniestroso: 0,
        });
    }
    /** --------------------------------------------Guardar Telefono en Array */
    savePhone = async() => {
        const {typePhoneSelected, phoneNumber, observationPhone, personNumbers, savePhonesArray } = this.props;

        const arrayNumbers = personNumbers;
        if(typePhoneSelected !== 0 && phoneNumber!==''){
            const founded = await arrayNumbers.find(element => element.telNumero === phoneNumber.trim() && element.telEstado === 1 );
            if(typeof(founded) !== 'undefined' && typeof(founded.telEstado) !== 'undefined'){
                if(founded.telEstado === 0){
                    await arrayNumbers.push({
                        detTipo: typePhoneSelected.detCodigo,
                        detNombreTipo: typePhoneSelected.detNombre,
                        telNumero: phoneNumber,
                        telObservacion: observationPhone,
                        telEstado: 1
                    })
                }else{
                    this.displayMessage.error(`El número ${phoneNumber} ya existe`);
                }
            }else{
                await arrayNumbers.push({
                    detTipo: typePhoneSelected.detCodigo,
                    detNombreTipo: typePhoneSelected.detNombre,
                    telNumero: phoneNumber,
                    telObservacion: observationPhone,
                    telEstado: 1
                })
            }
        
            /** metodo traido desde props */
            savePhonesArray(arrayNumbers);
        }else{
            this.displayMessage.warn('Ingrese el número telefónico y el tipo de teléfono')

        }
    }
    /** --------------------------------------------Guardar en array de cuentas */
    saveArrayAccounts = async() => {

    }
    /** --------------------------------------------Guardar cuenta en Array */
    saveAccount = async() => {
        const {accountNumber, ifi, typeAccount, bankAccounts, saveBanksAccountsArray, accountOwnerIdentification, accountOwnerName } = this.props;
        const { isDebit } = this.state;
        const arrayNumbers = bankAccounts;
        if(accountNumber.trim() !== '' && ifi!== 0 && typeAccount !== 0 && accountOwnerIdentification.trim() !== '' && accountOwnerName.trim() !== ''){
            const founded = await arrayNumbers.find(element => element.cueCuenta === accountNumber.trim() && element.cueEstado === 1 );
            if(typeof(founded) !== 'undefined'){
                this.displayMessage.error(`la cuenta ${accountNumber} ya existe`);
            }else{
                const foundedAccount = arrayNumbers.find(element => element.cueDebito === 1 && element.cueEstado === 1);
                if(foundedAccount === undefined){

                    await arrayNumbers.push({
                        cueCuenta: accountNumber,
                        detIfi: ifi.detCodigo,
                        detNombreIfi: ifi.detNombre,
                        detTipo: typeAccount.detCodigo,
                        detNombreTipoCuenta: typeAccount.detNombre,
                        cueIdentificacion: accountOwnerIdentification,
                        cueNombreDebito:accountOwnerName,
                        cueDebito: isDebit ? 1 : 0,
                        cueEstado: 1
                    })
                    saveBanksAccountsArray(arrayNumbers);
                    this.setState({isDebit: false})
                }else{
                    if(isDebit){
                        await this.handleOpenDebitoAcceptModal();
                    }else{
                        await arrayNumbers.push({
                            cueCuenta: accountNumber,
                            detIfi: ifi.detCodigo,
                            detNombreIfi: ifi.detNombre,
                            detTipo: typeAccount.detCodigo,
                            detNombreTipoCuenta: typeAccount.detNombre,
                            cueIdentificacion: accountOwnerIdentification,
                            cueNombreDebito:accountOwnerName,
                            cueDebito: isDebit ? 1 : 0,
                            cueEstado: 1
                        })
                        saveBanksAccountsArray(arrayNumbers);
                        this.setState({isDebit: false})
                    }
                }
            } 
        }else{
            this.displayMessage.warn('Todos los campos son obligatorios')

        }
    }
    /** --------------------------------------------ELIMINAR DEL TELEFONO */
    deletePhone = async(element, event) => {

        const { personNumbers, setPhoneNumbers } = this.props;
        const data = personNumbers;
        var i = data.indexOf(element);
       
        if(typeof(data[i].telCodigo)==='undefined'){
            await data.splice( i, 1 );
            await setPhoneNumbers(data);

        }else{
            data[i].telEstado = 0;
            await setPhoneNumbers(data);
        
        }
        this.closeAdd()

    }

    /** --------------------------------------------Eliminar cuenta del array */
    deleteAccount = async(element, event) => {
        const { bankAccounts, setBankNumbers } = this.props;
        const data = bankAccounts;
        var i = data.indexOf(element);
       
        if(typeof(data[i].cueCodigo)==='undefined'){
            await data.splice( i, 1 );
            await setBankNumbers(data);

        }else{
            data[i].cueEstado = 0;
            await setBankNumbers(data);

        }
        this.closeAddAccount()


    }
    /** ------------------------------------------Actualiar telefonos por codigo de persona */
    
    returnNumbersToSend = async() => {
        let phones = [];
        const { personNumbers } = this.props;
        
        await personNumbers.forEach(
            (phone) => {
                const tel = {
                    detTipo: phone.detTipo,
                    telNumero: phone.telNumero,
                    telObservacion: phone.telObservacion,
                    telEstado: phone.telEstado
                }
                if(phone.telCodigo){
                    tel.telCodigo =  phone.telCodigo;
                }
                phones.push(tel);
            }
        )

        return phones;
        
        
    }

   

    /** -------------------------------- */
    returnAccountsToSend = async() => {
        let accounts = [];
        const { bankAccounts } = this.props;
        await bankAccounts.forEach(
            acc => {
                const tel = {
                    cueCuenta: acc.cueCuenta,
                    detIfi: acc.detIfi,
                    detTipoCuenta: acc.detTipoCuenta ? acc.detTipoCuenta : acc.detTipo,
                    cueDebito: acc.cueDebito,
                    cueIdentificacion: acc.cueIdentificacion,
                    cueNombreDebito: acc.cueNombreDebito,
                    //empCodigo: this.state.session.user.empCodigo,
                    cueEstado: acc.cueEstado
                }
                if(acc.cueCodigo){
                    tel.cueCodigo =  acc.cueCodigo;
                }

                accounts.push(tel);
            }
        ) 

        return accounts;
        
    }

    /** -----------------------------------------------Actualizar Persona  */
    updatePerson = async() => {
        await this.setState({disabledSaveButton: true});

        const {identification,typeIdentification, gender, location, name, lastname, nacionality, direction, mail, birthdate, perCodigo,  siniestroso} = await this.props;
        const allNumbers = await this.returnNumbersToSend();
        const allAccounts = await this.returnAccountsToSend();

        if(identification.trim() !== '' && typeIdentification !== 0 && gender !== 0 && location!==null && name.trim() !== '' && lastname.trim() !== '' && nacionality.trim() !== '' && direction.trim() !=='' && mail.trim() !=='' && birthdate!==null && siniestroso!==0){
            const Person = {
                perCodigo: perCodigo,
                detTipoIdentificacion: typeIdentification,
                perIdentificacion: identification,
                perApellidos: lastname,
                perNombres: name,
                perFechaNacimiento:  moment(birthdate).format('DD/MM/YYYY'),
                perDireccion: direction,
                perNacionalidad: nacionality,
                perMail: mail,
                detGenero: gender,
                locCodigo: location.locCodigo,
                telefono: allNumbers,
                cuentasBancarias: allAccounts,
                detEstadoPoliza: siniestroso,
            }
            this.personalProvider.updatePerson([Person]).then(
                async response => {
                    if(response.error.codigo === '0') {
                        //this.updatePhones(perCodigo);
                        this.displayMessage.success(`Persona ${name} ${lastname} actualizada con éxito`);
                        await this.handleClose();
                        
                        this.props.getAllPersons();
                        this.props.cleanFields();
                        this.cleanErrors();
                    }else{
                        this.displayMessage.error(`Ha ocurrido un error: ${response.error.mensaje}`);
                    }
                    await this.setState({disabledSaveButton: false});

                }
            )
        }else{
            await this.showEmpties();
            await this.setState({disabledSaveButton: false});
            await this.displayMessage.warn('Por favor rellene todos los campos')
        }
        
    }
    
    /** ---------------------------------------------------Verificar error en TextField */
    hasError(field) {
        return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
    }

    displayError(field) {
        const { required, valid } = this.state.errors;
        const errorMessage = `Campo ${field} `;
  
        if (required[field]) {
            return `${errorMessage} es obligatorio`;
        }
  
        if (!valid[field]) {
            return `${errorMessage} no es valido`;
        }
    }

    /** --------------------------------------------------BuscarPersona */
    getPerson = (identification) => {
        return this.personalProvider.findPerson(identification).then(
            response => {                
                return response[0].perCodigo;
            }
        )
    }

    /** --------------------------------------------------Cerrar Agregar telefono*/
    closeAdd = () => {
        this.setState(
            {openAddState: false}
        )
    } 
    openAdd = () => {
        this.setState(
            {openAddState: true}
        )
    }
    /** --------------------------------------------------Cerrar Agregar Cuentas */
    
    closeAddAccount = () => {
        this.setState(
            {openAddAccount: false}
        )
    } 
    openAddAccount = () => {
        this.setState(
            {openAddAccount: true}
        )
    }
    
    render(){
        const {openAcceptDebit} = this.state;
        return(
            <div>
                
                <DebitoAcceptModal handleAccept={this.handleAcept} handleReject={this.handleReject} open={openAcceptDebit} />
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={this.props.open}
                    scroll={'body'}
                >
                    
                    <Card >
                        <CardHeader 
                            action={<IconButton
                                color="secondary"
                                onClick={this.handleClose}
                            ><Cancel/></IconButton>}
                            title = "Actualizar información del cliente"/>
                        <Divider /> 
                        {this.props.person ? 
                            <CardContent>
                                
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Alert severity="warning">
                                            <Typography
                                                component="h6"
                                            >
                                                <b>* Campos obligatorios.</b>
                                            </Typography>                      
                                        </Alert>                            
                                    </Grid>
                                </Grid><br/>  
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <FormControl error={this.state.errors.typeIdentification} fullWidth required variant='outlined'>
                                            <InputLabel id="typeIdentificationLabel" >Tipo de identificación</InputLabel>
                                            <Select
                                                id='typeIdentification'
                                                label='Tipo de Identificación'
                                                labelId="typeIdentificationLabel"
                                                name='typeIdentification'
                                                onChange={this.props.handleInputChange}
                                                value={this.props.typeIdentification}
                                            >
                                                <MenuItem  value={0}>Seleccione</MenuItem> 

                                                {
                                                    this.state.allTypeCI && this.state.allTypeCI.map(
                                                        (typeCI)=>  <MenuItem key={typeCI.detCodigo} value={typeCI.detCodigo}>{typeCI.detNombre}</MenuItem> )
                                                }
                                            </Select>
                                        </FormControl>
                  
                                    </Grid>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.identification} fullWidth helperText={this.state.messages.identification} label="Identificación" name='identification' onChange={this.props.handleInputChange} required value={this.props.identification} variant="outlined"/>
                                    </Grid>
                                
                                </Grid>
                                <Grid container spacing={3}>
                 
                                    <Grid item xs>
                                        <FormControl error={this.state.errors.gender} fullWidth required variant='outlined'  >
                                            <InputLabel id="genderLabel" >Género</InputLabel>
                                            <Select
                                                id='gender'
                                                label='Género'
                                                labelId="genderLabel"
                                                name='gender'
                                                onChange={this.props.handleInputChange}
                                                value={this.props.gender}
                                            >
                                                <MenuItem  value={0}>Seleccione</MenuItem> 
                                                {
                                                    this.state.allGenders && this.state.allGenders.map(
                                                        (gender)=>  <MenuItem key={gender.detCodigo} value={gender.detCodigo}>{gender.detNombre}</MenuItem> )
                                     
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs>
                                        <DatePicker
                                            error={this.state.errors.birthdate}
                                            format="DD/MM/YYYY"
                                            fullWidth
                                            helperText={this.state.messages.birthdate}
                                            inputVariant='outlined'
                                            //views={['year']}
                                            label="Fecha de nacimiento"
                                            name='birthdate'
                                            onChange={this.props.handleDateChange} 
                                            required
                                            value={this.props.birthdate}
                                        />

                                    </Grid>
                                </Grid>
                            

                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.name} fullWidth helperText={this.state.messages.name} label="Nombres" name='name' onChange={this.props.handleInputChange} required value={this.props.name} variant="outlined"/>

                                    </Grid>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.lastname} fullWidth helperText={this.state.messages.lastname} label="Apellidos" name='lastname' onChange={this.props.handleInputChange} required value={this.props.lastname} variant="outlined"/>

                                    </Grid>
                                </Grid>
                           
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.nacionality} fullWidth helperText={this.state.messages.nacionality} label="Nacionalidad" name='nacionality' onChange={this.props.handleInputChange} required value={this.props.nacionality} variant="outlined"/>

                                    </Grid>
                                    <Grid item xs >
                                        <Autocomplete
                                            fullWidth
                                            getOptionLabel={(option) => option.locNombre}
                                            id="location"
                                            onChange={this.props.onChangeElement}
                                            options={this.state.allProvincies}
                                            renderInput={(params) => <TextField error={this.state.errors.location} name='inputSearchLocation' value={this.state.inputSearchLocation} onChange={this.handleInputChange} helperText={this.state.messages.location} {...params} label="Ubicación" variant="outlined" required/>}
                                            value={this.props.location}
                                        />
                                    </Grid>
                                
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.direction} fullWidth helperText={this.state.messages.direction} label="Dirección" name='direction' onChange={this.props.handleInputChange} required  value={this.props.direction} variant="outlined"/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField error={this.state.errors.mail} fullWidth helperText={this.state.messages.mail} label="Correo electrónico" name='mail' onChange={this.props.handleInputChange} required value={this.props.mail} variant="outlined"/>

                                    </Grid>
                                </Grid>
                                <br></br>
                                {/*
                                 <FormControl error={this.state.errors.gender} fullWidth required variant='outlined'  >
                                            <InputLabel id="genderLabel" >Género</InputLabel>
                                            <Select
                                                id='gender'
                                                label='Género'
                                                labelId="genderLabel"
                                                name='gender'
                                                onChange={this.props.handleInputChange}
                                                value={this.props.gender}
                                            >
                                                <MenuItem  value={0}>Seleccione</MenuItem> 
                                                {
                                                    this.state.allGenders && this.state.allGenders.map(
                                                        (gender)=>  <MenuItem key={gender.detCodigo} value={gender.detCodigo}>{gender.detNombre}</MenuItem> )                                     
                                                }
                                            </Select>
                                        </FormControl>
                                */}
                              
                                <FormControl error={this.state.errors.siniestroso} fullWidth variant='outlined' required >
                                    <InputLabel id="siniestrosoLabel" >Tipo de persona</InputLabel>
                                    <Select
                                        id='siniestroso'
                                        label='Siniestroso'
                                        labelId="siniestrosoLabel"
                                        name='siniestroso'
                                        onChange={this.props.handleInputChange}
                                        value={this.props.siniestroso}
                                        
                                    >
                                        <MenuItem  value={0}>Seleccione</MenuItem> 
                                        {
                                            this.state.allBlackList && this.state.allBlackList.map(
                                                (n_siniestroso)=>  <MenuItem key={n_siniestroso.detCodigo} value={n_siniestroso.detCodigo}>{n_siniestroso.detNombre}</MenuItem> )
                                                
                                        }
                                    </Select>
                                </FormControl>
                            
             
                            </CardContent>
                            :<LinearProgress />
                        }
                    </Card>

                    <Card>
                        <CardHeader title='Teléfono(s)'/>
                        <CardContent>
                            <Table>
                                <TableHead style={{backgroundColor: 'white'}}>
                                    <TableRow>
                                        <TableCell ><b>Número</b></TableCell>
                                        
                                        <TableCell ><b>Tipo de número</b></TableCell>
                                        <TableCell ><b>Observación</b></TableCell>
                                        <TableCell >
                                            {this.state.openAddState ? <b>Acción</b> : <Button onClick={this.openAdd}><AddIcon /></Button> }  
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.personNumbers.map((row, index) => (
                                        typeof(row.telEstado) !== 'undefined' ?
                                            row.telEstado !== 0 &&<TableRow key={index} >
                                                <TableCell >{row.telNumero}</TableCell>
                                                <TableCell >{row.detNombreTipo}</TableCell>
                                                <TableCell >{row.telObservacion}</TableCell>
                                                <TableCell ><Button onClick={(evt) => this.deletePhone(row, evt) }><DeleteIcon/></Button></TableCell>
                                            </TableRow>
                                            : <TableRow key={index} >
                                                <TableCell>{row.telNumero}</TableCell>
                                                <TableCell>{row.detNombreTipo}</TableCell>
                                                <TableCell >{row.telObservacion}</TableCell>
                                                <TableCell ><Button onClick={(evt) => this.deletePhone(row, evt) }><DeleteIcon/></Button></TableCell>
                                            </TableRow>
                                    ))}
                                    {this.state.openAddState &&
                                    <TableRow>
                                        <TableCell >
                                            <TextField error={this.state.errors.phoneNumber} fullWidth helperText={this.state.messages.phoneNumber} label="Número telefónico" name='phoneNumber' onChange={this.props.handleInputChange} required value={this.props.phoneNumber}/>
                                        </TableCell>
                                        <TableCell >
                                            <FormControl fullWidth required >
                                                <InputLabel id="typePhone" >Tipo</InputLabel>
                                                <Select
                                                    id='typePhoneSelected'
                                                    label='Tipo de identificación'
                                                    labelId="typePhone"
                                                    name='typePhoneSelected'
                                                    onChange={this.props.handleInputChange}
                                                    value={this.props.typePhoneSelected}
                                                >

                                                    {
                                                        this.state.allTypePhone && this.state.allTypePhone.map(
                                                            typePhone =>  <MenuItem key={typePhone.detCodigo} value={typePhone}>{typePhone.detNombre}</MenuItem> )
                                                    }
                                                </Select>
                                            </FormControl>   
                                        </TableCell>
                                        <TableCell >                                
                                            <TextField fullWidth label="Observación:" name='observationPhone' onChange={this.props.handleInputChange} value={this.props.observationPhone} />
                                        </TableCell>
                                        <TableCell >
                                            <Button 
                                                autoFocus
                                                onClick={this.savePhone}>
                                                <DoneIcon />
                                            </Button>
                                            <Button 
                                                autoFocus
                                                onClick={this.closeAdd}>
                                                <CloseIcon />
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                        
                                    }
                                    
                                </TableBody>
                            </Table>
                 
                        </CardContent>

                    
                 
                    </Card>
                    <Card>
                        <CardHeader title='Cuenta(s) bancaria(s)'/>
                        <CardContent>
                            <Table>
                                <TableHead style={{backgroundColor: 'white'}}>
                                    <TableRow>
                                        <TableCell ><b>Cuenta</b></TableCell>
                                        <TableCell ><b>Identificación del propietario</b></TableCell>
                                        <TableCell ><b>Nombre del propietario</b></TableCell>
                                        <TableCell ><b>Institución financiera </b></TableCell>
                                        <TableCell ><b>Tipo de cuenta </b></TableCell>
                                        <TableCell ><b>Debitar</b></TableCell>

                                        <TableCell >
                                            {this.state.openAddAccount ? <b>Acción</b> : <Button onClick={this.openAddAccount}><AddIcon /></Button> }  
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.bankAccounts.map((row, index) => (
                                        typeof(row.cueEstado) !== 'undefined' ?
                                            (row.cueEstado !== 0 && row.cueEstado !== null) &&<TableRow key={index} >
                                                <TableCell>{row.cueCuenta}</TableCell>
                                                <TableCell>{row.cueIdentificacion}</TableCell>
                                                <TableCell>{row.cueNombreDebito}</TableCell>
                                                <TableCell>{row.detNombreIfi}</TableCell>
                                                <TableCell>{row.detNombreTipoCuenta}</TableCell>
                                                <TableCell>{row.cueDebito === 1 ? <CheckCircleIcon fontSize='small'/> : <CancelIcon fontSize='small'/> }</TableCell>

                                                <TableCell ><Button onClick={(evt) => this.deleteAccount(row, evt)}><DeleteIcon/></Button></TableCell>
                                            </TableRow>
                                            : <TableRow key={index} >
                                                <TableCell>{row.cueCuenta}</TableCell>
                                                <TableCell>{row.cueIdentificacion}</TableCell>
                                                <TableCell>{row.cueNombreDebito}</TableCell>
                                                <TableCell>{row.detNombreIfi}</TableCell>
                                                <TableCell>{row.detNombreTipoCuenta}</TableCell>
                                                <TableCell>{row.cueDebito === 1 ? <CheckCircleIcon fontSize='small'/> : <CancelIcon fontSize='small'/> }</TableCell>
                                                <TableCell ><Button onClick={(evt) => this.deleteAccount(row, evt)}><DeleteIcon/></Button></TableCell>
                                            </TableRow>
                                    ))}
                                    {this.state.openAddAccount &&
                                    <TableRow>
                                        <TableCell>
                                            <TextField error={this.state.errors.accountNumber} fullWidth helperText={this.state.messages.accountNumber} label="Número de cuenta" name='accountNumber' onChange={this.props.handleInputChange} required value={this.props.accountNumber}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField error={this.state.errors.accountOwnerIdentification} fullWidth helperText={this.state.messages.accountOwnerIdentification} label="Identificación del propietario" name='accountOwnerIdentification' onChange={this.props.handleInputChange} required value={this.props.accountOwnerIdentification}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField error={this.state.errors.accountOwnerName} fullWidth helperText={this.state.messages.accountOwnerName} label="Nombre del propietario" name='accountOwnerName' onChange={this.props.handleInputChange} required value={this.props.accountOwnerName}/>
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                fullWidth
                                                getOptionLabel={option => option.detNombre}
                                                id="ifi"
                                                onChange={this.props.onChangeIfi}
                                                options={this.state.allIFI}
                                                renderInput={(params) => <TextField onChange={this.handleInputChange} helperText={this.state.messages.location} {...params} label="Institución financiera" required/>}
                                                value={this.props.ifi}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <FormControl fullWidth required >
                                                <InputLabel id="typeAccountLabel" >Tipo de cuenta</InputLabel>
                                                <Select
                                                    id='typeAccount'
                                                    label='Tipo de cuenta'
                                                    labelId="typeAccountLabel"
                                                    name='typeAccount'
                                                    onChange={this.props.handleInputChange}
                                                    value={this.props.typeAccount}
                                                >

                                                    {
                                                        this.state.allTypeAccounts && this.state.allTypeAccounts.map(
                                                            typeAccount =>  <MenuItem key={typeAccount.detCodigo} value={typeAccount}>{typeAccount.detNombre}</MenuItem> )
                                                    }
                                                </Select>
                                            </FormControl>   
                                        </TableCell>
                                        <TableCell >                                
                                            <Checkbox
                                                checked={this.state.isDebit}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                onChange={this.handleChangeIsDebit}
                                            />
                                        </TableCell>
                                        <TableCell >
                                            <Button 
                                                autoFocus
                                                onClick={this.saveAccount}>
                                                <DoneIcon />
                                            </Button>
                                            <Button 
                                                autoFocus
                                                onClick={this.closeAddAccount}>
                                                <CloseIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    }
                                    
                                </TableBody>
                            </Table>
                 
                        </CardContent>
                    </Card>                    
                
                    <DialogActions>
                        <Button color="primary" onClick={this.handleClose}>
                        Cancelar
                        </Button>
                        <div style={styles.wrapper}>
                            <Button 
                                autoFocus 
                                color="primary" 
                                disabled = { this.props.perCodigo ? this.state.disabledSaveButton : true }
                                onClick={this.updatePerson}>
                                Guardar
                            </Button>
                            { this.state.disabledSaveButton && <CircularProgress size={24} style={styles.buttonProgress} />}
                    

                        </div>
                        
                    </DialogActions>
                </Dialog>
            </div>
            
        )
    }
}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps, null)(PersonUpdateModal);