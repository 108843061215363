import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    Grid,
    IconButton,
    TextField
} from '@material-ui/core';
import { FilesDropzoneAgended } from 'components';
import { Alert } from '@material-ui/lab';
import { Cancel } from '@material-ui/icons';

export default function ModalUploadFiles(props) {
    const { open, close } = props;
    const [errorTipo, setErrorTipo] = useState(false);
    const [errorArchivos, setErrorArchivos] = useState(false);
    const [errorArchivosSize, setErrorArchivosSize] = useState(false);
    const [files, setFiles] = useState([]);
    const [descripcion, setDescripcion] = useState('');

    const handleChange = e => {
        setDescripcion(e.target.value);
    };

    const handleClickOpen = files => {
        let error = false;
        setErrorTipo(false);
        setErrorArchivos(false);

        if (!files.length) {
            setErrorArchivos(true);
            error = true;
        }
        let totalSize = 0;
        files.map(file => {
            totalSize += file.size;
            return 0;
        });

        const fileSize = totalSize / 1024 / 1024;

        if (fileSize > 100) {
            setErrorArchivosSize(true);
            error = true;
        }

        if (error) {
            return;
        }
        setFiles(files);
        console.log(files);
        props.dataFiles(files);
        props.dataDescripcion(descripcion);
    };
    return (
        <Dialog fullWidth maxWidth="md" open={open} scroll={'body'}>
            <Card>
                <CardHeader
                    action={
                        <IconButton color="secondary" onClick={close}>
                            <Cancel />
                        </IconButton>
                    }
                    title="Subir archivos"
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                label="Descripción"
                                variant="outlined"
                                name="descripcion"
                                value={descripcion || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <FilesDropzoneAgended
                        callback={handleClickOpen.bind(
                            this
                        )}></FilesDropzoneAgended>
                    {errorArchivos && (
                        <Alert severity="error">
                            Seleccione archivos para la solicitud!
                        </Alert>
                    )}
                    {errorArchivosSize && (
                        <Alert severity="error">
                            Los archivos superan los 100 MB permitidos
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </Dialog>
    );
}
