import React, { useState,useEffect } from 'react'
import { Card, CardContent, CardHeader, Dialog, Divider, IconButton, Button } from '@material-ui/core'
import { FilesDropzoneAgended } from 'components';
import { Alert } from '@material-ui/lab';
import { Cancel } from '@material-ui/icons';
import { Uploadfiles } from 'providers/uploadfiles.service';
import { DisplayMessage } from 'notifications/displayMessage';

export default function ModalUploadFiles(props) {
    const { open, close, pagos } = props;
    const [errorTipo, setErrorTipo] = useState(false)
    const [errorArchivos, setErrorArchivos] = useState(false)
    const [errorArchivosSize, setErrorArchivosSize] = useState(false)
    const [files, setFiles] = useState([])
    const [errorMensaje, setErrorMensaje] = useState(false)
    const [msg, setMsg] = useState([])
    const [detnemonico, setdetnemonico] = useState([])
    const uploadfiles = new Uploadfiles();
    const displayMessage = new DisplayMessage();


    useEffect(() => {
        uploadfiles.findDetNemonico('HABIL').then(
            data=>{
                
                if(data.error.codigo==='0'){
                    setdetnemonico(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
       
    }, [])
    
    const getfiles = (files) => {
        uploadfiles.testquemadoCPN(files,detnemonico).then(response => {
            if (response.error.codigo === '0') {
                pagos(response.bodyOut.data)
                setMsg(response.bodyOut.data)
                displayMessage.success('Pagos Registrados')
                setErrorMensaje(false)
                close()

            }
            if (response.error.codigo !== '0') {
                if (response.error.mensaje) {
                    displayMessage.error('Error al registrar pagos')
                    
                    setErrorMensaje(response.error.mensaje)
                  
                    //alert(response.error.mensaje)

                }
            }
        },
            () => {
                displayMessage.error('Error de conexión')
            })
    }
    const handleClickOpen = (files) => {

        let error = false;
        setErrorTipo(false)
        setErrorArchivos(false)
        setErrorMensaje(false)
        setErrorArchivosSize(false)



        if (!files.length) {
            setErrorArchivos(true)
            error = true;
        }
        let totalSize = 0
        files.map(file => {
            totalSize += file.size;
            return 0;
        })

        const fileSize = ((totalSize / 1024) / 1024);

        if (fileSize > 100) {
            setErrorArchivosSize(true)
            error = true;
        }

        if (error) {
            return;
        }
        setFiles(files)
        getfiles(files)
        
        setErrorMensaje(false)

    };

    const handleclose=()=>{
        close();setErrorArchivos(false);setErrorMensaje(false);
    }

    return (
        
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={()=>handleclose()}
                    ><Cancel /></IconButton>}
                    title='Subir archivos'
                />
                <Divider />
                
                <Divider />
                <CardContent>
                    <FilesDropzoneAgended callback={handleClickOpen.bind(this)}></FilesDropzoneAgended>
                    {errorArchivos && <Alert severity="error">Seleccione archivos para la solicitud!</Alert>}
                    {errorArchivosSize && <Alert severity="error">Los archivos superan los 100 MB permitidos</Alert>}
                    {errorMensaje &&<Alert severity="error">{errorMensaje}</Alert>}
                </CardContent>
                
            </Card>
        </Dialog>
    )
}
