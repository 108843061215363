import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    active: {
        boxShadow: `inset 4px 0px 0px ${theme.palette.primary.main}`,
        backgroundColor: colors.grey[50]
    },
    avatar: {
        height: 40,
        width: 40
    },
    details: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    unread: {
        marginTop: 2,
        padding: 2,
        height: 18,
        minWidth: 18
    }
}));

const ConversationListItem = props => {
    const { active, conversation, className, ...rest } = props;

    const classes = useStyles();
   // const lastMessage = conversation.messages[conversation.messages.length - 1];

    return (
        <ListItem
            {...rest}
            button
            className={clsx(
                {
                    [classes.active]: active
                },
                className
            )}
            component={RouterLink}
            to={`/mensajes/${conversation.liqCodigo}`}
        >
            <ListItemAvatar>
                <Avatar
                    alt="Person"
                    className={classes.avatar}
                >{`${conversation.usuNombre.substr(0,1)}`}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={conversation.liqNemonico}
                primaryTypographyProps={{
                    noWrap: true,
                    variant: 'h6'
                }}
                secondary={conversation.eslMensaje}
                secondaryTypographyProps={{
                    noWrap: true,
                    variant: 'body1'
                }}
            />
            
        </ListItem>
    );
};

ConversationListItem.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    conversation: PropTypes.object.isRequired
};

export default ConversationListItem;
