import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { TableGenericforForm } from 'components';
import { Button } from '@material-ui/core';
import './styles.css';
import { GenericHeader } from 'components';
import GenericBasicDialog from './GenericBasicDialog';

import { DeleteDialog } from './DeleteDialog';


export default class GenericBasicFormDetail extends Component {


    constructor(props) {
        super(props);


        this.state = {
            show: false,
            selectedDate: this.ActualDate(),
            showRemove: false,
            selectedElement: null,
            showUpdate: false,
            total: 0
        }

    }

    
    ActualDate = () => {
        const now = new Date();

        const day = ('0' + now.getDate()).slice(-2);
        const month = ('0' + (now.getMonth() + 1)).slice(-2);

        const today = now.getFullYear() + '-' + (month) + '-' + (day);
        return today;
    }

    calcTotalValue = () => {
        var acum = 0;
        this.props.data.map((val) => {
            if(val.talEstado === 1){
                acum = parseFloat(acum) + parseFloat(val.talValorRegistrado)
                
            }
            return null;
        });

        this.props.setTotalValue(acum);
    }
    /** Para el Date Picker */

    removeElement = (element) => {
        //dataArray.
        console.log(this.props.data)
        var i = this.props.data.indexOf(element);
        console.log(i)

        if (i !== -1 && (element.delCodigo === 0 || element.delCodigo === undefined )) {
            this.props.data.splice(i, 1);
            /* i.map((e)=>{
                const datosTalonario = {
                    delCodigo:e.delCodigo,
                    delEstado:0,
                    talDescripcion:e.talDescripcion,
                    talCantidad:e.talCantidad,
                    talValorRegistrado:parseFloat(e.talValorRegistrado)
                }
                this.props.data(datosTalonario)
            } */
            
           
        } else if(i !== -1 && element.delCodigo !== 0){
            this.props.data[i] = {
                delCodigo:element.delCodigo,
                talCodigo:element.talCodigo,
                talEstado:0,
                talDescripcion:element.talDescripcion,
                talCantidad:element.talCantidad,
                talValorRegistrado:parseFloat(element.talValorRegistrado)
            }
        }
        this.handleCloseRemove()
        this.calcTotalValue();
        // this.props.enDisButton();

        // this.handleCloseRemove();
    }

    /** */
    updateElement = (element, change) => {
        var i = this.props.data.indexOf(element);
        if (i !== -1) {
            this.props.data[i] = change;
        }
        this.handleCloseUpdate();
        this.calcTotalValue();
        // this.props.enDisButton();

    }
    handleDateChange = (e) => {
        // this.state.selectedDate = date;
        this.setState({
            selectedDate: e.target.value
        });
    }


    handleClickOpen = () => {
        this.setState({
            show: true
        });
    }


    handleClose = () => {
        this.setState({
            show: false
        })
    }


    handleOpenRemove = (element) => {
        this.setState({
            showRemove: true,
            selectedElement: element

        });
    }

    handleCloseRemove = () => {
        this.setState({
            showRemove: false
        })
    }

    handleOpenUpdate = (element) => {
        this.setState({
            showUpdate: true,
            selectedElement: element


        });
    }

    handleCloseUpdate = () => {
        this.setState({
            showUpdate: false
        })
    }

    /* <Button
            color="primary"
            key={1}
            variant="contained"
        > Editar </Button>, */
    actions = [
        
        <Button
            color="secondary"
            key={1}
            variant="contained"
        > Eliminar </Button>
    ];

    isRequired = value => {
        return (!value && 'Este campo es requerido')
    }

    render() {
        const { advice, labels, btnAgregarText, data, columna,
            dataSelect, modalInputs, SelectDatafield,
            addData, addService, dataOfObject, idOfObject,
            totalValue

        } = this.props;
        
       
        return (
            <div className="medium-margin">
                <GenericHeader
                    title={advice}
                />

                <form>

                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs
                        >
                            <TextField
                                id="totalValue"   
                                inputProps={{ style: { textAlign: 'right' }}}                              
                                InputProps={{
                                    readOnly: true,
                                }}
                                label={labels[0]}
                                name="totalValue" 
                                onChange={this.props.handleInputChange} 
                                value={parseFloat(totalValue).toFixed(2)}
                                variant="outlined" 

                                //error={()=>hasError('totalValue')} 
                                //helperText={()=>hasError.bind('totalValue') ? errorsText.totalValue:'' }

                            />
                        </Grid>
                      
                    </Grid>
                    <div className="right">
                        <Button                        
                            color="primary"                        
                            onClick={this.handleClickOpen}
                            variant="contained"
                        >{`Agregar ${btnAgregarText}`}</Button>
                    </div>

                    {//--------------- Agregar dialog -----------
                    }
                    <GenericBasicDialog
                        addData={addData}
                        addService={addService}
                        calcTotalValue={this.calcTotalValue}
                        dataSelect={dataSelect}
                        handleClose={this.handleClose}
                        modalInputs={modalInputs}
                        modalTitle={btnAgregarText}
                        SelectDatafield={SelectDatafield}
                        show={this.state.show}
                    />
                    {//show, handleClose, element, removeElement
                        this.state.selectedElement ?
                            <DeleteDialog

                                element={this.state.selectedElement}
                                handleClose={this.handleCloseRemove}
                                removeElement={this.removeElement}
                                show={this.state.showRemove}
                            /> : ''
                    }
                    {/*  {
                        this.state.selectedElement ?
                            <UpdateDialog
                                element={this.state.selectedElement}
                                handleClose={this.handleCloseUpdate}
                                modalInputs={modalInputs}
                                show={this.state.showUpdate}
                                updateElement={this.updateElement}
                                valShow={valShow}
                            /> : ''
                    } */}

                    <TableGenericforForm
                        actions={this.actions}
                        columna={columna}
                        data={data}
                        dataOfObject={dataOfObject}
                        dataPrevia={this.props.dataPrevia}
                        dataSelect={dataSelect}
                        handleClose={this.handleClose}
                        handleOpenRemove={this.handleOpenRemove}
                        handleOpenUpdate={this.handleOpenUpdate}
                        idOfObject={idOfObject}
                        showMedicines={this.state.show}


                    />

                </form>
            </div>
        );
    }
} 
