import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from '@material-ui/core';

import { Label, TableEditBar } from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    filterButton: {
        marginRight: theme.spacing(2)
    },
    content: {
        padding: 0
    },
    inner: {
        minWidth: 1150
    },
    actions: {
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end'
    }
}));

const Results = props => {
    const { className, orders, ...rest } = props;

    const classes = useStyles();

    const [selectedOrders] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value);
    };    

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >    
            <Card>        
                <Divider />
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table>
                                <TableHead>
                                    <TableRow>                    
                                        <TableCell>Código</TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell >Acción</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders.map((order,i) => (
                                        <TableRow
                                            key={i}
                                            //selected={selectedOrders.indexOf(order.liqCodigo) !== -1}
                                        >                      
                                            <TableCell>
                                                {`${order.liqNemonico}`}
                                                <Typography variant="body2">
                                                    {order.eslFechaInicio}
                                                </Typography>
                                            </TableCell>                      
                                            
                                            <TableCell>
                                                <Label
                                                    // color={paymentStatusColors[order.payment.status]}
                                                    variant="outlined"
                                                >
                                                    {order.detNombre}
                                                </Label>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    to={`/cliente/mensajes/${order.liqCodigo}`}
                                                    variant="outlined"
                                                >
                                                        Ver
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </PerfectScrollbar>
                </CardContent>
                <CardActions className={classes.actions}>
                    <TablePagination
                        component="div"
                        count={orders.length}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </CardActions>
            </Card>
            <TableEditBar selected={selectedOrders} />
        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    orders: PropTypes.array.isRequired
};

Results.defaultProps = {
    orders: []
};

export default Results;
