/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
    Dialog,
    CardContent,
    FormControl,
    Button,
    Typography,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    CircularProgress,
    TextField
} from '@material-ui/core';
import './styles.css';
import { BeneficiaryDataProvider } from 'providers/beneficiaryData.service';
import { RelationShipProvider } from 'providers/relationship.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { ActualDate } from 'services/ActualDate';
import moment from 'moment';
import {    
    KeyboardDatePicker
} from '@material-ui/pickers';
import validateCedula from 'utils/validateCedula';
import DoneIcon from '@material-ui/icons/Done';

const EMAIL_REGEX = new RegExp('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$');
const numeros = new RegExp('^[0-9]+$');
const letra = new RegExp('^[ñíóáéú a-zA-Z ]+$');
const styles = {
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}

class Application extends React.Component {
  _isMounted = false;
  constructor(props){
      super(props);
      this.state={
          open: false,
          onClose: false,
          ci:'',
          apellidos:'',
          nombres:'',
          edad:moment(ActualDate()).diff(moment(ActualDate()), 'years'),
          fecha:moment(ActualDate()),
          genero:'',
          parentesco:'', 
          tipoIdentificacion:'',     
          dataParentesco:[],
          dataParentesco1:[],
          dataTipoIdentificacion:[],
          datosEditar: [],
          datosIngreso: [],
          alert: false,
          isDisable:true,
          errorApellido:false,
          direccion:'',
          correo:'',
          percodigo:'',
          cedulaError:false,
          disabledSaveButton: false,
          touched:{
              ci:false,
              correo:false,
              apellidos:false,
              nombres:false,
              edad:false,
              fecha:false,
              direccion:false,
              genero:false,
              parentesco:false,
              tipoIdentificacion: false,

          },
          errors: {
              required: {
                  ci: false,
                  apellidos:false,
                  nombres:false,
                  correo: false,
                  edad:false,
                  fecha:false,
                  direccion:false,
                  genero:false,
                  parentesco:false,
                  tipoIdentificacion: false,

              },
              valid: {
                  ci: true,
                  nombres:true,
                  apellidos: true,
                  correo: false,
                  edad:true,
                  fecha:true,
                  direccion:true,
                  genero:true,
                  parentesco:true,
                  tipoIdentificacion: true,

              }
          }
      }
      this.displayMessage = new DisplayMessage();

      this.handleChange = this.handleChange.bind(this);
      this.handleChangeSelect = this.handleChangeSelect.bind(this);
      this.handleChangeSelectGenero = this.handleChangeSelectGenero.bind(this);
      this.Check = this.Check.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  handleClose = () => {
      this.setState({open:false});
  };

  handleOpen = () => {
      this.setState({open:true});
  };

  componentDidMount(){
      this._isMounted  = true;
      const relationship = new RelationShipProvider();
      const beneficiaryGenero = new BeneficiaryDataProvider();
      const displayMessage = new DisplayMessage();
      /* Datos Parentesco */
      relationship.datosParentesco()
          .then(
              data => {
                  if(this._isMounted){
                      this.setState({dataParentesco:data.bodyOut.data});
                  }
              },
              () => {          
                  return false;

              }

          );
      /* Datos Genero */

      beneficiaryGenero.dataGenero()
          .then(
              data => {
                  if(this._isMounted){
                      this.setState({dataGenero:data.bodyOut.data});
                  }
              },
              () => {          
                  displayMessage.error('Error de comunicación.');

              }

          );

      /* Datos Parentesco */
      relationship.tipoIdentificacion()
          .then(
              data => {
                  if(this._isMounted){
                      this.setState({dataTipoIdentificacion: data.bodyOut.data.filter(data => data.detNemonico === 'CED' || data.detNemonico === 'PAS')});
                  }
              },
              () => {          
                  return false;

              }

          );

  }
  
  componentWillUnmount(){
      this._isMounted = false;
  }


  onClose=()=>{
      this.props.close && this.props.close();
      this.setState({
          ci:'',
          apellidos:'',
          nombres:'',
          edad:moment(ActualDate()).diff(moment(ActualDate()), 'years'),
          fecha:moment(ActualDate()),
          genero:'',
          parentesco:'',
          direccion:'',
          correo:'',
          tipoIdentificacion:'',
          datosIngreso:[],
          touched:{
              ci:false,
              correo:false,
              apellidos:false,
              nombres:false,
              edad:false,
              fecha:false,
              direccion:false,
              genero:false,
              parentesco:false,
              tipoIdentificacion:false,

          },
          errors: {
              required: {
                  ci: false,
                  apellidos:false,
                  nombres:false,
                  correo: false,
                  edad:false,
                  fecha:false,
                  direccion:false,
                  genero:false,
                  parentesco:false,
                  tipoIdentificacion:false,

              },
              valid: {
                  ci: true,
                  nombres:true,
                  apellidos: true,
                  correo: false,
                  edad:true,
                  fecha:true,
                  direccion:true,
                  genero:true,
                  parentesco:true,
                  tipoIdentificacion:true,

              }
          }
      }); 
  }


  handleChange=(e)=> {
      const { name, value } = e.target; 
      const errors = {
          required: { ...this.state.errors.required, [name]:false}
      };
      this.setState({
          [name]:value,
          errors: { ...this.state.errors, ...errors}
      });
            
    
       
  }

  formatoFecha(string){
      var f = string.split('-').reverse().join('/');
      return f;
  }

  Check=(e)=>{
      const field = e.target.name;
      this.setState({
          touched: { ...this.state.touched, [field]:true }
      });
      this.validate(e);
  }

  validate(e){
      const target = e.target;
      const { value, name } = target;

      if(value.length === 0) {
          const errors = {
              required: { ...this.state.errors.required, [name]:true }
          };

          this.setState({
              errors: { ...this.state.errors, ...errors }
          });
          return;
      }

      if(name === 'ci') {
          this.verificarCedula(this.state.tipoIdentificacion)
      } 
      if(name === 'apellidos') {
          this.validateApellidos(value)
      } 
      if(name === 'nombres') {
          this.validateNombres(value)
      } 
      if(name === 'correo') {
          this.validateEmail(value);
      }
      if(name === 'edad') {
          this.validateEdad(value);
      }
  }

  verificarCedula(tipoIdentificacion) {
      let cedulaIsValid;
      if (tipoIdentificacion === 3){    
          cedulaIsValid = numeros.test(this.state.ci);
          if (cedulaIsValid) {
              cedulaIsValid = validateCedula(this.state.ci);        
              if (!cedulaIsValid){
                  const displayMessage = new DisplayMessage();
                  displayMessage.error('Cédula no válida');
              }
          }
      } else {
          cedulaIsValid = true;
      }

      const errors = {
          valid: { ...this.state.errors.valid, ci: cedulaIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateNombres() {
      const letrasIsValid = letra.test(this.state.nombres);
      const errors = {
          valid: { ...this.state.errors.valid, nombres: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateApellidos() {
      const letrasIsValid = letra.test(this.state.apellidos);
      const errors = {
          valid: { ...this.state.errors.valid, apellidos: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }
  validateEdad() {
      const edadIsValid = numeros.test(this.state.edad);
      const errors = {
          valid: { ...this.state.errors.valid, edad: edadIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }


  validateEmail() {
      const emailIsValid = EMAIL_REGEX.test(this.state.correo);
      const errors = {
          valid: { ...this.state.errors.valid, correo: emailIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  hasError(field) {
      return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
  }

  isFormInvalid() {
      const { errors } = this.state;
      const { required, valid } = errors;
      const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
      const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

      return isSomeFieldInvalid || isSomeFieldRequired;
  }

  displayError(field) {
      const { required, valid } = this.state.errors;
      const errorMessage = `Campo ${field} `;

      if (required[field]) {
          return `${errorMessage} es obligatorio`;
      }

      if (!valid[field]) {
          return `${errorMessage} no es válido`;
      }
  }

  handleChangeSelect=(e)=>{

      this.setState({parentesco: e.target.value})
  }
  handleChangeSelectTipo=(e)=>{
      const errors = {
          valid: { ...this.state.errors.valid, tipoIdentificacion: e.target.value === '' ? false : true }
      };
    
      this.setState({
          tipoIdentificacion: e.target.value,
          touched: { ...this.state.touched, tipoIdentificacion:true },
          errors: { ...this.state.errors, ...errors }
      })
      this.verificarCedula(e.target.value);
  }

  handleChangeSelectGenero=(e)=>{
      this.setState({genero:e.target.value});
  }
  
  async handleSubmit(e) {
      this.setState({disabledSaveButton: true});
      e.preventDefault();
      const displayMessage = new DisplayMessage();
      const { ci,apellidos,nombres,fecha,direccion,genero,parentesco} = this.state;
      if(ci === '' || apellidos === '' || nombres===''||fecha==='' || fecha === null || !fecha.isValid() || direccion==='' || genero==='' || parentesco === ''){
          displayMessage.error('Todos los campos son obligatorios.');
          this.setState({disabledSaveButton: false});
      }else{
          const { poliza } = this.props;
          const allData = this.props.data;

          //const fechaset = this.formatoFecha(this.state.fecha);
          switch (this.state.parentesco) {
              case 22:
                  this.findSons(allData,poliza);
                  break;
              case 23:
                  this.findRelationship(allData, 'CONYUGE', poliza);
                  
                  break;
              case 28:
                  this.findRelationship(allData, 'MADRE', poliza);
                  break;
              case 29:
                  this.findRelationship(allData, 'PADRE', poliza);
                  break;
          
              default:
                  await this.executeInsert(poliza);

                  break;
          }
      }
  }

  /** */
  findRelationship = async(allData, relationship, poliza) => {
      var relation = allData.find( dependiente => dependiente.nombre2 === relationship )

      if(typeof(relation) === 'undefined'  ){
          await this.executeInsert(poliza);
      }else{
          this.setState({disabledSaveButton: false});

          this.displayMessage.error(`Usted ya posee agregado un beneficiario con el parentesco: ${relationship}`);
      }
  }
  /** -----------------------Buscar por mayor cantidd */
  findSons = async(data, poliza) => {
      const description = data[0].plaProducto;
      let NumberSons = 0;
      await data.forEach(element => {
          if(element.nombre2 === 'HIJO'){
              NumberSons = NumberSons + 1;
          }
      });
      if(description.includes('hasta 4 hijos') ){
          if(NumberSons < 4){
              await this.executeInsert(poliza);
          }else{
              await this.displayMessage.error(`El número de máximo de hijos es 4: \n${description}`);
          }
      }else if(description.includes('hasta 3 hijos')){
          if(NumberSons < 3){
              await this.executeInsert(poliza);
          }else{
              await this.displayMessage.error(`El número de máximo de hijos es 3: \n${description}`);
          }
      }
      else{
          await this.executeInsert(poliza);
      }

  }

  executeInsert = async(poliza) => {
      const newData =  {
          detTipoIdentificacion: this.state.tipoIdentificacion,
          perIdentificacion:this.state.ci,
          perApellidos:this.state.apellidos,
          perNombres:this.state.nombres,
          perFechaNacimiento:this.state.fecha.format('DD/MM/YYYY'),
          perDireccion:this.state.direccion,
          perNacionalidad:'Ecuatoriana',
          perMail:this.state.correo,
          perGenero:this.state.genero,
          pepObservacion:'Datos cargados desde la App',
          detTipoPersona: this.state.parentesco,
          polCodigo: poliza
      }; 

      const data = this.state.datosIngreso;
      
      const beneficiary = new BeneficiaryDataProvider();
      const displayMessage = new DisplayMessage();
      await beneficiary.ingresoBeneficiarios([newData])
          .then(
              response => {
                  if(response.error.codigo === '0'){
                      displayMessage.success('Registro correcto');              
                      this.props.close && this.props.close();
                      this.restartState();
                      data.push(newData);
                      this.setState({ data })
                  }
                  this.setState({disabledSaveButton: false});

              }
              
          ).catch(
              ()=> {          
                  this.setState({disabledSaveButton: false});
              }
          ); 
   
      await  beneficiary.datosBeneficiarios(poliza)
          .then(
              data => {
                  this.setState({dataParentesco1:data.bodyOut.data});
              },
              () => {          
                  return false;
              }

          );

      await this.props.callback(this.state.dataParentesco1);

 
  }

  restartState = () => {
      this.setState({
          tipoIdentificacion:'',
          ci:'',
          apellidos:'',
          nombres:'',
          edad:moment(ActualDate()).diff(moment(ActualDate()), 'years'),
          fecha:moment(ActualDate()),
          genero:'',
          parentesco:'',
          direccion:'',
          correo:'',
          datosIngreso:[],
          touched:{
              ci:false,
              correo:false,
              apellidos:false,
              nombres:false,
              edad:false,
              fecha:false,
              direccion:false,
              genero:false,
              parentesco:false,
              tipoIdentificacion:false,

          },
          errors: {
              required: {
                  ci: false,
                  apellidos:false,
                  nombres:false,
                  correo: false,
                  edad:false,
                  fecha:false,
                  direccion:false,
                  genero:false,
                  parentesco:false,
                  tipoIdentificacion:false,

              },
              valid: {
                  ci: true,
                  nombres:true,
                  apellidos: true,
                  correo: false,
                  edad:true,
                  fecha:true,
                  direccion:true,
                  genero:true,
                  parentesco:true,
                  tipoIdentificacion:true,

              }
          }
      }); 
  }

  handleDate = (e) => {
      const fechaHoy = moment(ActualDate())
      const edad = fechaHoy.diff(moment(e), 'years')
      this.setState({fecha:moment(e)})
      if(isNaN(edad)){
          this.setState({edad:''})
      }else{
          this.setState({edad:edad})
      }
  }

  render(){
      return (
          <div>
      
     
              <Dialog
                  open={this.props.open}
                  scroll={'body'}
                  maxWidth='xs'
                  fullWidth
              >
      
                  <div>
                      <Grid className="header">
                          <Grid className="title-modal">
                              <Typography
                                  component="h6"
                                  variant="h6"
                              > Agregar Beneficiario </Typography>
                          </Grid>
                          <Grid className="closeButton">
                              {this.onClose ? (
          
                                  <IconButton
                                      aria-label="close"
                                      onClick={this.onClose}
                                  >
                                      <CloseIcon />
                                  </IconButton>
                              ) : null}
                          </Grid>
                      </Grid>
                      <Grid>  
                          <CardContent>
                              <form >

                                  <Grid container spacing={3}>
                                      <Grid item xs={6}>
                                          <FormControl variant="outlined" fullWidth required>
                                              <InputLabel id='TipoIdentificacionLabel' >Tipo de identificación</InputLabel>
                                              {this.state.dataTipoIdentificacion ? 
                                                  <Select
                                                      className="select" 
                                                      name="tipoIdentificacion"
                                                      //onBlur={this.Check} 
                                                      label='Tipo de identificación'
                                                      labelId='TipoIdentificacionLabel'
                                                      onChange={this.handleChangeSelectTipo}               
                                                      value={this.state.tipoIdentificacion}
                                                      fullWidth
                                                  >
                                                      <MenuItem value="">SELECCIONE</MenuItem>
                                                      {this.state.dataTipoIdentificacion.map( item => 
                                                          <MenuItem
                                                              key={item.detCodigo}
                                                              value={item.detCodigo}
                                                          >
                                                              {item.detNombre}
                                                          </MenuItem>   
                                                      )}
                          
                                                  </Select>:<CircularProgress/>
                                              }            
                                              <p className={this.hasError('tipoIdentificacion') ? 'error-message__visible' : 'error-message'}>
                                                  {this.displayError('Tipo identificación')}
                                              </p>      
                                              {/* <p className={this.hasError('genero') ? 'error-message__visible' : 'error-message'}></p>
                        {this.displayError('genero')} */}
                                          </FormControl>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <TextField
                                              label='Identificación'
                                              error={this.hasError('ci')}     
                                              inputProps={{ maxLength: 10 }}
                                              name="ci"
                                              onBlur={this.Check}
                                              onChange={this.handleChange.bind(this)}
                                              value={this.state.ci} 
                                              variant="outlined" 
                                              fullWidth
                                              required
                                          />
                                      </Grid>
                                      <Grid item xs={6}>
                                          <TextField 
                                              label='Apellidos'
                                              error={this.hasError('apellidos')}
                                              name="apellidos"
                                              onBlur={this.Check}
                                              onChange={this.handleChange}   
                                              value={this.state.apellidos}
                                              variant="outlined" 
                                              fullWidth
                                              required
                                          />
                                      </Grid>
                                      <Grid item xs={6}>
                                          <TextField 
                                              label='Nombres'
                                              error={this.hasError('nombres')}
                                              name="nombres"
                                              onBlur={this.Check}
                                              onChange={this.handleChange}  
                                              value={this.state.nombres}
                                              variant="outlined"
                                              fullWidth
                                              required
                                          />
                                      
                                      </Grid>
                                      
                                      <Grid item xs={6}>
                                          <KeyboardDatePicker
                                              format="DD/MM/YYYY"
                                              invalidDateMessage="Formato invalido"
                                              KeyboardButtonProps={{
                                                  'aria-label': 'change date',
                                              }}
                                              label="Fecha de Nacimiento"
                                              name="fecha"
                                              onChange={this.handleDate}
                                              inputVariant='outlined'
                                              value={this.state.fecha}
                                              required
                                              fullWidth

                                          />
                                      </Grid>
                                      
                                      <Grid item xs={6}>
                                          <FormControl variant="outlined" required fullWidth>
                                              <InputLabel id='generoLabel' >Género</InputLabel>
                                              <Select 
                                                  labelId='generoLabel'
                                                  onChange={this.handleChangeSelectGenero}
                                                  fullWidth         
                                                  value={this.state.genero}
                                                  label='Género'
                                              >
                                                  <MenuItem value="">SELECCIONE</MenuItem>
                                                  {this.state.dataGenero ? this.state.dataGenero.map((genero,index) =>
                                                      <MenuItem
                                                          key={index}
                                                          value={genero.detCodigo}
                                                      >
                                                          {genero.detNombre}
                                                      </MenuItem>
                                                  ):null}
                                              </Select>
                                              {/* <p className={this.hasError('genero') ? 'error-message__visible' : 'error-message'}></p>
                        {this.displayError('genero')} */}
                                          </FormControl>
                                      </Grid>
                                      <Grid item xs={6}>
                                          <FormControl variant="outlined" required fullWidth>
                                              <InputLabel id='oarentescoLabel' >Parentesco</InputLabel>
                                              {this.state.dataParentesco ? 
                                                  <Select
                                                      name="parentesco"
                                                      labelId='oarentescoLabel'
                                                      fullWidth
                                                      //onBlur={this.Check} 
                                                      onChange={this.handleChangeSelect}               
                                                      value={this.state.parentesco}
                                                      label='Parentesco'
                                                  >
                                                      <MenuItem value="">SELECCIONE</MenuItem>
                                                      {this.state.dataParentesco.map((parentesco) => parentesco.detNombre !== 'TITULAR' &&
                                                    <MenuItem
                                                        key={parentesco.detCodigo}
                                                        value={parentesco.detCodigo}
                                                    >
                                                        {parentesco.detNombre}
                                                    </MenuItem>   
                                                      )}
                          
                                                  </Select>:<CircularProgress/>}
                                              {/* <p className={this.hasError('parentesco') ? 'error-message__visible' : 'error-message'}></p>
                        {this.displayError('parentesco')} */}
                                          </FormControl>
                                      </Grid>
                                      
                                      <Grid item xs={6}>
                                          <TextField
                                              label='Correo Electrónico'
                                              error={this.hasError('correo')}
                                              name="correo"
                                              onBlur={this.Check}
                                              onChange={this.handleChange}
                                              value={this.state.correo}
                                              variant="outlined" 
                                              required
                                              fullWidth
                                          />
                                      </Grid>
                                      <Grid item xs={12}>
                                          <TextField
                                              label='Dirección'
                                              error={this.hasError('direccion')}
                                              name="direccion"
                                              onBlur={this.Check}
                                              onChange={this.handleChange}
                                              value={this.state.direccion}
                                              variant="outlined" 
                                              required
                                              fullWidth
                                          />
                                      </Grid>
                                      
                                  </Grid>
                                  <br/>
                                  <Grid container   justify="flex-end"> 
                                      <FormControl>
                                          <div style={styles.wrapper}>
                                              <Button 
                                                  color="primary" 
                                                  disabled={this.isFormInvalid() ? this.isFormInvalid() : this.state.disabledSaveButton } 
                                                  onClick={this.handleSubmit} 
                                                  variant="contained"
                                                  startIcon={<DoneIcon/>}
                                              >Agregar</Button>
                                              { this.state.disabledSaveButton && <CircularProgress size={24} style={styles.buttonProgress} />}
                                          </div>
                                      </FormControl>
                                  </Grid>
                              
                                  {/* </PerfectScrollbar> */}
                              </form>

                          </CardContent>
                      </Grid>
                  </div>
              </Dialog>
          </div>
      )
  }
}


Application.propTypes = {
    //author: PropTypes.object.isRequired,
    className: PropTypes.string,
    onApply: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

export default Application;
