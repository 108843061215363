import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, Paper, Avatar, Typography, Select, MenuItem } from '@material-ui/core';
import { Hidden } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import { sessionActions } from 'actions';
import getInitials from 'utils/getInitials';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const session = useSelector(state => state.session);  
  const dispatch = useDispatch();
  const roles = session.user.roles;
  const [rol, setRol] = useState(session.user.role);

  useEffect(() => {
    setRol(session.user.role);
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const handleChange = event => {
    setRol(event.target.value);
    dispatch(sessionActions.changeSession(event.target.value)); 
    router.history.push('/');
  };

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Persona"
          className={classes.avatar}
          src={session.user.usuUrlImagen}         
        >
          {getInitials(session.user.usuNombre)}
        </Avatar>
        <Typography
          className={classes.name}
          variant="h6"
        >
          <center>
          {session.user.usuNombre}
          </center>
        </Typography>
        <Typography
          className={classes.name}
          display="inline"
          variant="h6"
        >
          <b>Perfil: &nbsp;</b>
          {
            roles.length > 1 ? 
            <Select display="inline" style={{ width: '150px', background: 'white' }} value={rol}  onChange={handleChange}>          
              {
                roles.map((item) => 
                  <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                )
              }
            </Select>: roles[0][1]
          }
        </Typography>        
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map((list, index) => (
          <Navigation
            component="div"
            key={list.title+index}
            pages={list.pages}
            title={list.title}
            role={session.user.role}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <PerfectScrollbar>
            {navbarContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          <PerfectScrollbar>
            {navbarContent}
          </PerfectScrollbar>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
