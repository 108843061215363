/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { useSelector } from 'react-redux';
import { LiquidationProvider } from './../../providers/liquidation.service';
import clsx from 'clsx';

import {
    ConversationList,
    ConversationDetails,
    ConversationPlaceholder} from './components';
    

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        overflow: 'hidden',
        '@media (max-width: 863px)': {
            '& $conversationList, & $conversationDetails': {
                flexBasis: '100%',
                width: '100%',
                maxWidth: 'none',
                flexShrink: '0',
                transform: 'translateX(0)'
            }
        }
    },
    searchInput: {
        flexGrow: 1
    },
    openConversion: {
        '@media (max-width: 863px)': {
            '& $conversationList, & $conversationDetails': {
                transform: 'translateX(-100%)'
            }
        }
    },
    conversationList: {
        width: 300,
        paddingBottom: '70px',
        flexBasis: 300,
        flexShrink: 0,
        '@media (min-width: 864px)': {
            borderRight: `1px solid ${theme.palette.divider}`
        }
    },
    conversationDetails: {
        flexGrow: 1
    },
    conversationPlaceholder: {
        flexGrow: 1
    }
}));
  


const OrderManagementList = (props) => {
    const classes = useStyles();
    const [searchWord, setSearchWord] = useState('');
    const [originalMessages, setOriginalMessages] = useState([]);

    const [messages, setMessages] = useState(null);
    const [allLiquidation, setAllLiquidation] = useState(null);    
    const liqCode = props.match.params.id;


    const session = useSelector(state => state.session);      

    const searchLiq = async(search)=>{
        
        const lP = new LiquidationProvider();

        await lP.LiquidationByPolCodigoLiqNemonico(session.user.polCodigo, search, session.user.role==='LIQUIDADOR' ? 1: 0).then(
            (response)=> {
                if(response.length === 0) {
                    setMessages(null);
                }else{
                    setOriginalMessages(response);
                    setMessages(response);
                }
            }
        ).catch(
            error=> {console.error(error)}
        )
    }

    const searchMessages = async(val)=>{
        
        let ArrayReturn=[];
        await originalMessages.forEach( ( msn )=>{
            if(msn.liqNemonico.includes(val)){
                ArrayReturn.push(msn);
            }

        } );
        await setMessages(ArrayReturn);
    }    

    const getMessage=(id)=>{

        if(id !== null && typeof(id)!=='undefined' && id !== 'inicio'){
            const lP = new LiquidationProvider();
            lP.dataByLiqCodigo(id).then(
                (response)=> {                    
                    setAllLiquidation(response[0]);
                }
            ).catch(
                error=> {
                    console.error(error)
                }
            )
        }
        
    }
    const handleSearchChange = async(event) => {
       
        const value = event.target.value;

        await setSearchWord(value);
        
        await searchMessages(value);

        //await searchLiq(value.length>=2 ? value: '');


    }
    

    useEffect(() => {
        let mounted = true;
        //getMessage(props.match.params.id ? props.match.params.id: null);
        const fetchOrders = () => {      
            if (mounted) {
                if(searchWord===''){
                    searchLiq('');
                    
                }
            }      
        };

        fetchOrders();

        return () => {
            mounted = false;
        };
    }, []);    

    useEffect(() => {
        if (props.match.params.id) { 
            if(props.match.params.id !== 'inicio'){
                getMessage(props.match.params.id)                                                
            }
        }
    }, [liqCode])

    
    
   
    return (
        
        <Page
            className={clsx({
                [classes.root]: true,
                [classes.openConversion]: allLiquidation
            })}
            title="Mensajes"
        >
            {
                <ConversationList
                    className={classes.conversationList}
                    conversations={messages}
                    handleSearchChange={handleSearchChange}
                    valSearch={searchWord}
                /> 
            }
            {allLiquidation ? 
                <ConversationDetails
                    className={classes.conversationDetails}
                    //id={parseInt(selectedConversation.liqCodigo)}                    
                    getMessage={getMessage}
                    selectedMessages={allLiquidation}
                    session={session}
                /> 
                : 
                <ConversationPlaceholder className={classes.conversationPlaceholder} />
            }
           
        </Page>
    );
};

export default OrderManagementList;