import { DataService } from 'providers/dataService'

export class ManagerReportProvider {

    constructor() {
        this.dataService = new DataService();
    }

    dataReports(  ) {
        let body = {  
            tipoConsulta: 1,
            entidad: "VwRepprimaPlan",
            parametros: {
                						
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    dataReportsExport(typeExport) {
        let body = {  
            tipoConsulta: typeExport,
            entidad: 'VWRPP',
            parametros: {}       
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
}