import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { PersonalProvider } from 'providers/persona.service';
import { PaymentProvider } from 'providers/payment.service';

import { DisplayMessage } from 'notifications/displayMessage';
import {Cancel} from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add';
import PersonModal from './PersonModal';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { CircularProgress, Card, CardHeader,  CardActions, Grid, TextField, Button, IconButton, Dialog} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchAffiliate(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const personaProvider = new PersonalProvider();
    const displayMessage = new DisplayMessage();
    const paymentProvider = new PaymentProvider();
    const [codigo,setCodigo]=useState('');
    
    useEffect(() => {
        paymentProvider.findDetNemonico('NOBK').then(
            data=>{
                
                if(data.error.codigo==='0'){
                    setCodigo(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
       
    }, [])


    const [columns] = useState([
        { title: 'Cedula', field: 'perIdentificacion' },
        { title: 'Apellido', field: 'perApellidos' },
        { title: 'Nombre', field: 'perNombres' }
    ]);


    const [buscarTitular, setBuscarTitular] = useState('');
    const [buscarApellido, setBuscarApellido] = useState('');
    const [buscarNombre, setBuscarNombre] = useState('');
    /** -------------------------- para abrir el modal de Crear Persona */
    const [openPersonModal, setOpenPersonModal] = useState(false);

    const handleBuscarTitular= () => {
        setData(0);

        if(buscarTitular === '' && buscarNombre === '' && buscarApellido === ''){
            displayMessage.error('Ingrese un campo a buscar')
            setData([])
        }else{
            
            personaProvider.buscarTItular(buscarTitular,buscarApellido,buscarNombre)
                .then(
                    data => {
                        if(data.bodyOut.data.length === 0){
                            displayMessage.error('Afiliado no encontrado.');
                            setData([])
                        }else{
                            setData(data.bodyOut.data)
                        }
                    },
                    () => {          
                        displayMessage.error('Error de comunicación')

                    }

                );
        }
    }

    
    const handleChange = (e)=>{
        setBuscarTitular(e.target.value);
    }

    const handleChangeNombre = (e)=>{
        setBuscarNombre(e.target.value);
    }

    const handleChangeApellido = (e)=>{
        setBuscarApellido(e.target.value);
    }

    const handleLimpiar= ()=>{
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    const handleData = (e) => {
        if(props.validatePoliza ===true){
            personaProvider.validatePolizaPersona(e).then(
                response => {
                    if(response.error.codigo === '0'){
                        props.datos(e);
                        /* props.codigo(e); */
                        handleClose();
                    }
                }
            )
        }else{
            props.datos(e);
            props.codigo(e);
            handleClose();

        }
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    const openCreatePerson = () => {
        setOpenPersonModal(true);
        handleClose();
        
    
    }
    

   

    return (
        <div>
            <PersonModal setOpenPersonModal = {setOpenPersonModal} open={openPersonModal} setPerson = {props.datos} 
            envio={codigo} />
            <Dialog
                maxWidth="md"
                open={props.open}
                scroll={'body'}
            >
                <Card>
                    <CardHeader
                        action={<IconButton
                            color="secondary"
                            onClick={handleClose}
                        ><Cancel/></IconButton>}
                        title='Buscar Afiliado'
                    />
                    <CardActions>
                        <div className={classes.root}>
                        
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                //className={classes.item}
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                >
            
                                    <TextField
                                        fullWidth
                                        name="buscarTitular"
                                        onChange={handleChange}
                                        placeholder="Buscar por cédula"
                                        value={buscarTitular}
                                    />
            
       
         
                                </Grid>
                                <Grid
        
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                >
           
                                    <TextField
                                        fullWidth
                                        name="buscarApellido"
                                        onChange={handleChangeApellido}
                                        placeholder="Buscar por apellido"
                                        value={buscarApellido}
                                    />
            
       
         
                                </Grid>
                                <Grid
        
                                    item
                                    md={4}
                                    sm={4}
                                    xs={12}
                                >
            
                                    <TextField
                                        fullWidth
                                        name="buscarNombre"
                                        onChange={handleChangeNombre}
                                        placeholder="Buscar por nombre"
                                        value={buscarNombre}
                                    />
          
       
         
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                    xs={6}
                                >
               
                                    <Button
                                        color="primary"
                                        onClick={handleBuscarTitular}
                                        variant="contained"
                                        startIcon={<SearchIcon/>}
                                    >Buscar</Button>
                                
                  
                                </Grid>
                                <Grid
                 
                                    item
                                    sm={2}
                                    xs={6}
                                >
               
                                    <Button
                                        color="primary"
                                        onClick={handleLimpiar}
                                        variant="contained"
                                        startIcon={<ClearAllIcon/>}
                                    >Limpiar</Button>
                                </Grid>
                                <Grid
                 
                                    item
                                    sm={2}
                                    xs={6}
                                >
               
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={openCreatePerson}
                                    >Crear                              
                                    </Button>
                                </Grid>

                           
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Alert severity="warning">
                                        <Typography
                                            component="h6"
                                        >
                                            <b>Solo es necesario crear al afiliado en caso de que no se encuentre registrado.</b>
                                        </Typography>                      
                                    </Alert>                            
                                </Grid>
                            </Grid><br/> 
                        </div>
          
                    </CardActions>
            
                    {data ?
                        data.length ?
                            <MaterialTable
                                actions={[
                                    {
                                        icon: 'check',
                                        tooltip: 'Seleccionar',
                                        onClick: (event, rowData) => {handleData(rowData)
                                        }
                                    }
                                ]}
                                columns={columns}
                                data={data}
                                localization={{
                                    body:{
                                        emptyDataSourceMessage:'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        search: false,
                                        exportName: 'Exportar a CSV',
                                        exportAriaLabel: 'Exportar',
                                        exportTitle: 'Exportar'
                                    },
                                    pagination:{
                                        labelRowsSelect:'Filas',
                                        labelDisplayedRows:'{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'
          

                                    }
                                }}
                                options={{exportButton: false, search:false, toolbar: false}}
                                title="CIE10"
                            />: ''
                        :<CircularProgress/>}
                </Card><br/>
            </Dialog>
        </div>
        
    );
}