import React, { Component } from 'react';

import { Page } from 'components';



import { SearchBar } from 'components';
import { connect } from "react-redux";

//import { QuestionsClient,QuestionsPrestadores, QuestionsAccountant} from './Questions';

import { GenericHeader } from "components";
import './styles.css';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';

class HelpView extends Component {

    constructor(props) {
        super(props);
        const { session } = props;
        this.state = {
            showAnswer: null,
            session: session,
            questions: null
        }
        this.dCP = new DetalleCatalogoProvider();



    }
    componentDidMount = () => {
        this.findAllQuestions();
    }

    findAllQuestions = () => {

        this.dCP.findDetalleCatalogo(33).then((questions) => {
            this.setState({
                questions: questions
            });
        })
    }


    changeDisplay = (nameDiv) => {
        const div = document.getElementById(nameDiv);
        if (div.style.display === 'none') {
            div.style.display = 'block';

        } else {
            div.style.display = 'none';

        }
    }





    render() {
        /** let Questions =  [] ;
           if (this.state.session.user.role ==="CLIENTE" ){
                Questions = QuestionsClient;
    
           }
           if(this.state.session.user.role ==="PRESTADOR"){
            Questions = QuestionsPrestadores;
    
           }
           if(this.state.session.user.role ==="CONTADOR"){
            Questions = QuestionsAccountant
        } */
        return (
            <Page
                title='Ayuda'
            >

                <div className='general-container'>

                    <GenericHeader
                        title='Preguntas Frecuentes'
                    />
                    <div className="top-margin">
                        <SearchBar></SearchBar>
                        Estamos trabajando, pronto estará disponible
                {
                            /** 
                            this.state.questions ? 
                            this.state.questions.map((question) => 
                                <Card className='space-top' key={question.id}>
                                    {
                                      
                                    }
                                    <div className="mediun-margin">
                                        <Button className='inline' onClick = {()=>this.changeDisplay(`reply_${question.id}`)} >></Button>
                                        <Typography className='inline' variant="h5" component="h2">
                                            { question.detNombre }
                                        </Typography>
                                    </div>
                                    <div className='left-margin' id={`reply_${question.id}`} >
                                    <hr/>
                                        <div className="mediun-margin">
                                            <Typography  >
                                                { question.detOrigen }
                                            </Typography>
            
                                        </div>
                                        
            
                                    </div>
                                    
                                    
                                </Card>
                                    
                                
                                )
                                :
                                <LinearProgress />
                                */
                        }
                        <label>

                        </label>
                    </div>
                </div>
            </Page>
        );
    }
}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps, null)(HelpView);