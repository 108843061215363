import { DataService } from 'providers/dataService';

export class ReportsProvider {
    constructor() {
        this.dataService = new DataService();
    }

    reportesTabla(detNemonico) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                detNemonico: detNemonico
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    reportesTablaAudit(estado, codigo, cedula, nombre) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                liqNemonico: '%' + codigo + '%',
                detNemonico: estado,
                usuNombre: '%' + nombre + '%',
                perIdentificacion: cedula + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    reportesModal(catCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                liqNemonico: catCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    buscador(liqNemonico, estado) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                liqNemonico: '%' + liqNemonico + '%',
                detNemonico: estado
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    reportesTabla1(catCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: catCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    consultaAfiliados() {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    paginacion(estado, codigo, cedula, nombre, valor, desde) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PAGIN',
            parametros: {
                0: '%' + codigo + '%',
                1: estado,
                2: '%' + nombre + '%',
                3: cedula + '%',
                4: valor,
                5: desde
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    paginacion2(filters, orderBy, skip, take, entidad) {
        let params = {};
        let count = 0;
        filters.map(item => {
            params = {
                ...params,
                [count]: item
            };
            count++;
        });
        filters.map(item => {
            params = {
                ...params,
                [count]: item
            };
            count++;
        });

        params = {
            ...params,
            [count + 1]: orderBy.field,
            [count + 2]: orderBy.orderDirection,
            [count + 3]: take,
            [count + 4]: skip
        };

        let body = {
            tipoConsulta: 1,
            entidad: entidad,
            parametros: params
        };

        console.log(body);

        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExportLiquidacion(
        typeExport,
        filters,
        orderBy,
        skip,
        take,
        entidad
    ) {
        let params = {};
        let count = 0;
        filters.map(item => {
            params = {
                ...params,
                [count]: item
            };
            count++;
        });
        filters.map(item => {
            params = {
                ...params,
                [count]: item
            };
            count++;
        });

        params = {
            ...params,
            [count + 1]: orderBy.field,
            [count + 2]: orderBy.orderDirection,
            [count + 3]: take,
            [count + 4]: skip
        };

        let body = {
            tipoConsulta: typeExport,
            entidad: entidad,
            parametros: params
        };

        console.log(body);

        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataTotal(estado, codigo, cedula, nombre) {
        let body = {
            tipoConsulta: 1,
            entidad: 'COUNTP',
            parametros: {
                0: '%' + codigo + '%',
                1: estado,
                2: '%' + nombre + '%',
                3: cedula + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    /*   datosEmpresa() {
        let body = {
            tipoConsulta: 1,
            entidad: 'Empresa',
            parametros: {
                tipoNemonico: 'COMERC'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    } */

    datosEmpresa() {
        let body = {
            tipoConsulta: 1,
            entidad: 'EMPPLB',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
}
