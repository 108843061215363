import React, { Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';


export default class DeleteDialog extends Component {
    constructor(props){
        super(props);
        // eval(props.modalInputs[0])
        //m1=eval(props.modalInputs[0]);
        this.state ={
            selectedElement: '',
            CANTIDAD: 0,
            UNIDAD: 0,
            VALOR: 0.0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange=(evt)=>{
        console.log("[evt.target.name]: evt.target.value");
        console.log(evt.target);
        console.log("----------"+ evt.target.value);
        this.setState({
            [evt.target.name]: evt.target.value
        });
        
    }
    addData = () => {
        const {selectedElement, CANTIDAD, UNIDAD, VALOR} = this.state;
        console.log("ADD DATA METHOD");

        console.log(selectedElement + " - "+ CANTIDAD+" - "+ UNIDAD+" - "+ VALOR);
       
        this.props.addData([
                 selectedElement , CANTIDAD, UNIDAD, VALOR
                ]);

        


        this.props.handleClose();
    }

    onTagsChange = (event, values) => {
    
        this.setState({
          selectedElement: values
        }, () => {
          // This will output an array of objects
          // given by Autocompelte options property.
          console.log(this.state.selectedElement);
        });
      }

    render(){
      
        const { show, handleClose} = this.props;

        return(
            <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
                <DialogContent>
                <DialogContentText>
                
                </DialogContentText>
                
                <Typography>
                    ¿Estas seguro de que deseas eliminar el elemento?
                </Typography>
                
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                {  this.props.element ? 
                    <Button  onClick={()=>this.props.removeElement(this.props.element)} color="secondary">
                         Eliminar
                </Button>:
                <CircularProgress  />

                }
                   
                </DialogActions>
            </Dialog>
        );

    }
}