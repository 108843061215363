/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { 
    Grid,
    TextField,
    Card,     
    CardContent,    
    IconButton,    
    Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatInvoice from 'components/NumberFormatInvoice';
import { DatePicker } from '@material-ui/pickers';
import {Cancel} from '@material-ui/icons'
import Farmacia from './Pharmacy';
import ModallSearch from './ModlalSearch/ModlalSearch';
import { AtentionFormProviders } from 'providers/attentionForm.service';
//import NumberFormatMoney from 'components/NumberFormatMoney';


const FormGenericDigitalize = props => {

    const initialValues = {
        startDate: moment()
    };
    const initialValues1 = {
        delMedico:props.dataPrevia.delMedico
    };

    const atention = new AtentionFormProviders();
    const [prestacion, setPrestacion] = useState(props.data);
    const [state] = useState({...initialValues1});
    const [open,setOpen] = useState(false);
    const [ruc,setRuc] = useState('');
    const [establecimiento,setEstablecimiento] = useState('');
    const [preCodigo,setPreCodigo] = useState(0);
    const [talonario,setTalonario] = useState([])
    const [calendarTrigger, setCalendarTrigger] = useState(null);
    const [values, setValues] = useState({startDate: moment()});    
    const [monto,setMonto] = useState(0);    
    const [claveAcceso,setClaveAcceso] = useState('');    
    const [factura,setFactura] = useState('');
    const [error,setError] = useState({});
    //const [copago,setCopago] = useState(); 
    const [rucError, setRucError] = useState();
    const [facturaError, setFacturaError] = useState(false);
    const [medicoError,setMedicoError] = useState(false);
    //const [copagoError,setCopagoError] = useState(false);
    const [detalleError,setDetalleError] = useState(false);
    const [buscador, setBuscador] = useState(false);    
    const [delCodigo,setDelCodigo] = useState(0);
    const [ser,setSer] = useState('');
    const [estado,setEstado] = useState(1);
    let archivo = null;
  
    useEffect(() => {
        enviaDatos(prestacion)
    }, [prestacion,talonario,preCodigo,values.startDate,monto,factura,delCodigo,estado]);

    useEffect(() => {
        setRucError(props.errorRuc)
        setFacturaError(props.errorFactura)
        setMedicoError(props.errorMedico)
        //setCopagoError(props.errorCopago)
        setDetalleError(props.errorMonto)
        setDetalleError(props.errorValor)
    }, [props.errorRuc,props.errorFactura,props.errorMedico,props.errorCopago,props.errorMonto,props.errorValor]);

    useEffect(() => {
        console.log(props.dataPrevia)
        console.log(props.data)
        if(Object.entries(props.dataPrevia).length !==0){
            const fecha=(props.dataPrevia.delFecha.substring(0,10))
            console.log(fecha);
            if (fecha !== '') {
                setValues({startDate:moment(fecha)})
            } else {
                setValues({startDate:moment()})
            }
            
            setPreCodigo(props.dataPrevia.preCodigo);
            setFactura(props.dataPrevia.delFactura);
            setPrestacion({...prestacion,delFacAutorizacion:props.dataPrevia.delFacAutorizacion,delFacClaveAcceso:props.dataPrevia.delFacClaveAcceso,delMedico:props.dataPrevia.delMedico,detTipo:props.dataPrevia.detTipo,copCodigo:props.dataPrevia.copCodigo,delServicio:props.dataPrevia.delServicio})
            setDelCodigo(props.dataPrevia.delCodigo);
            //setState({...state,delMedico:props.dataPrevia.delMedico})
            setClaveAcceso(props.dataPrevia.delFacClaveAcceso);
            setMonto(props.dataPrevia.delValorRegistrado);
            setTalonario(props.dataPrevia.detalle);
            setSer(props.dataPrevia.delServicio)
            setEstado(props.dataPrevia.delEstado)
            if (props.dataPrevia.preCodigo){
                atention.consultarPresadorGuardado(props.dataPrevia.preCodigo).then(response => {            
                    if(response.error.codigo === '0' && response.bodyOut.data.length){
                        setRuc(response.bodyOut.data[0].preRuc);
                        setEstablecimiento(response.bodyOut.data[0].preNombre);
                    }              
                },
                () => {          
                //displayMessage.error('Error de comunicación.');        
                }); 
            }
        }
    }, []);


    const handleSubmitXml = (event) => {
        event.preventDefault();
        console.log(event.target.id);
        
        const file = archivo.files[0];
    
        const reader = new FileReader();
    
        reader.readAsText(file);
    
        reader.onloadend = evt => {
            const readerData = evt.target.result;
        
            const parser = new DOMParser();
            const xml = parser.parseFromString(readerData, 'text/xml');
            const data = new XMLSerializer().serializeToString(xml.documentElement);
            let posicion = data.indexOf('<claveAcceso>');
            let posicion1 = data.indexOf('</claveAcceso>');
            if (posicion > 0){
                let claveAcceso = data.substring(posicion+13, posicion1 );                
                setPrestacion({...prestacion, delFacClaveAcceso: claveAcceso});
                setClaveAcceso(claveAcceso);                
                /* console.log(data);
                console.log(claveAcceso);            
                console.log(posicion);
                console.log(posicion1); */
            } else {
                alert('Archivo no válido');
            }
        };
    }


    const handleChange=(e)=>{ 
        setMedicoError(false)        
        setPrestacion({...prestacion, [e.target.name]:e.target.value});
    }

    const handleChangeAutorizacion=(e)=>{ 
        setPrestacion({...prestacion, [e.target.name]:e.target.value});
    }

    const enviaDatos = (arregloDatos) => {        
        props.datos(props.indice, arregloDatos,preCodigo,talonario,moment(values.startDate).format('DD/MM/YYYY'),monto,factura,delCodigo,estado)
    }
    
    const handleChangePrestacion = (event, values) => {     
        setSer('')
        if(values){
            setPrestacion({...prestacion, detTipo:values.detTipo, copCodigo: values.copCodigo, delServicio: values.tipoDescripcion});
        } else {
            setPrestacion({...prestacion, detTipo: null, copCodigo: null, delServicio: ''});
        }        
    }
    /* const handleChangeText = (event) => {
        let search = event.target.value;
        if (search.length >= 3) {
            getPrestadorItems(search);
        } else {
            setDataCombo([]);
        }
    } */

    /* const getPrestadorItems= ( search ) => {
        if(props.detTipoLiq === 'TIPLQRE'){
            atention.consultarPresador(search,23537).then(response => {            
                if(response.error.codigo === '0' && response.bodyOut.data.length){
                    setDataCombo(response.bodyOut.data);
                }              
            },
            () => {          
                displayMessage.error('Error de comunicación.');        
            }); 
        }else{
            atention.consultarPresador(search,null).then(response => {            
                if(response.error.codigo === '0' && response.bodyOut.data.length){
                    const datosSucios = response.bodyOut.data;
                    let arreglo = datosSucios.filter(datosSucios => datosSucios.detTipo !== 23537);
                    setDataCombo(arreglo);
                   
                }              
            },
            () => {          
                displayMessage.error('Error de comunicación.');        
            }); 
        }
    }; */
 
    const _remove = () =>{
        if(props.onRemove)
            props.onRemove();
    }

    const handleOpen=()=>{
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDatosDetalles =(datos)=>{
        setOpen(false)
        setTalonario(datos)
        
    }

    const handleCalendarOpen = trigger => {
        setCalendarTrigger(trigger);
    };

    const handleCalendarChange = () => {
        setValues()
    };

    const handleCalendarAccept = date => {
        setValues(values => ({
            ...values,
            [calendarTrigger]: date
        }));
    };
  
    const handleCalendarClose = () => {
        setCalendarTrigger(false);
    };
    

    const calendarOpen = Boolean(calendarTrigger);
    calendarTrigger === 'startDate'
        ? moment()
        : moment(values.startDate).add(1, 'day');
    const calendarValue = values[calendarTrigger];

    const handleChangePrestador= (value) => {
        setPreCodigo(value.preCodigo)
        setRuc(value.preRuc)
        setEstablecimiento(value.preNombre)
        setRucError(false)
    }   
    
    /* const handleSetPrestador = () => {
        setPreCodigo(0)
        setRuc('')
        setEstablecimiento('')
        setRucError(true)
    } */

    const handleTotalValue = (total) => {
        setMonto(total)
        setDetalleError(false)
    }         

    const handleChangeFactura = (e) => {
        if(e.target.value.length <= 0)setError({...error,factura:true});
        setFactura(e.target.value)
        setFacturaError(false)
        
    }

    /*  const handleChangeCopago = (e) => {
        setCopagoError(false)
        setCopago(e.target.value)
    } */

    const handleOpenBuscador = () => {
        setBuscador(true)
    }

    const handleCloseBuscador = () => {
        setBuscador(false)
    }

    return(
        <Grid>
            <ModallSearch
                close={handleCloseBuscador}
                datos={handleChangePrestador.bind(this)}
                detTipoLiq={props.detTipoLiq}
                open={buscador}
            />
            
            <Card elevation={2}>                                                            
                <CardContent>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                        //className={classes.item}
                            item                        
                            xs={11}
                        >
                            <Autocomplete                            
                                getOptionLabel={option => option.tipoDescripcion}                            
                                onChange={handleChangePrestacion}
                                options={props.datosPrestaciones}
                                renderInput={params => 
                                    <TextField
                                        {...params}
                                        error={props.errorPrestacion}
                                        helperText={props.errorPrestacion && 'Seleccione una prestación'}
                                        label={ser!== '' ? ser : "Prestación"}
                                        margin="none"
                                        required
                                    />
                                }
                                //value={prestacionCombo}
                            />
                        </Grid>
                        <Grid
                            item
                            style={{ textAlign: 'right' }}                        
                            xs={1}
                        >
                            <IconButton
                                color="secondary"
                                onClick={_remove.bind(this)}
                                style={{ color: 'red'}}
                            ><Cancel/></IconButton> 
                        </Grid>                                           
                        <Grid
                            //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color = "primary"
                                onClick={handleOpenBuscador}
                                variant = "contained"
                            >
                                Buscar Prestador
                            </Button>
                        </Grid>
                        <Grid
                            //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                error={facturaError}
                                fullWidth
                                helperText={facturaError && 'Ingrese N° de factura'}
                                InputProps={{
                                    inputComponent: NumberFormatInvoice
                                }}
                                label="N° Factura"
                                name="factura"
                                onChange={handleChangeFactura}    
                                value={factura} 
                            />
                        
                        </Grid>                     
                        <Grid
                            //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                onClick={handleOpen}
                                variant="contained"
                            >
                                Agregar Detalle
                            </Button>
                        </Grid> 
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                error={rucError}
                                fullWidth
                                helperText={rucError && 'Busque un prestador'}
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Ruc"
                                name="rucDoctor"
                                onChange={handleChange}    
                                value={ruc}                        
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"                                
                                onClick={() => {
                                    archivo.click()
                                }}
                                variant="contained"
                            >
                                CARGAR XML
                            </Button>                                                     
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >                            
                            <TextField
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Clave de acceso (Opcional)"
                                name="delFacClaveAcceso"                                
                                value={claveAcceso}                                                  
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField                            
                                fullWidth
                                label="Autorización (Opcional)"                                
                                name="delFacAutorizacion"
                                onChange={handleChangeAutorizacion}      
                                value={prestacion.delFacAutorizacion}                                                  
                            />                            
                        </Grid>                                               
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                //className={classes.dateField}
                                label="Fecha de emisión"
                                name="startDate"
                                onClick={() => handleCalendarOpen('startDate')}
                                value={moment(values.startDate).format('DD/MM/YYYY')}
                            />   
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >                           
                            <TextField
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Derivado de que establecimiento"
                                name="establecimiento"
                                onChange={handleChange}
                                value={establecimiento} 
                            />
                            
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                error={medicoError}
                                fullWidth
                                helperText={medicoError && 'Ingrese el doctor'}
                                label="Doctor responsable"
                                name="delMedico"
                                onChange={handleChange}
                                value={prestacion.delMedico}
                            />
                        </Grid>
                        {/* <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                error={copagoError}
                                fullWidth
                                helperText={copagoError && 'Ingrese copago'}
                                InputProps={{
                                    inputComponent: NumberFormatMoney,
                                    inputProps: { 
                                        style: { textAlign: 'right'},
                                        decimalScale: 2, 
                                                                        
                                    }
                                }}
                                label="Copago"
                                name="copago"
                                onChange={handleChangeCopago}
                                value={copago}
                            />                            
                        </Grid> */}
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                error={detalleError}
                                fullWidth
                                helperText={detalleError && 'Agregue detalle'}
                                label="Monto utilizado"
                                name="monto"
                                onChange={handleChange}
                                value={monto}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card><br/>
            {open &&
            <Farmacia
                close={handleClose}
                dataTalonario = {talonario}
                datosDetalles={handleDatosDetalles}
                open={open}
                totalValue={handleTotalValue}
            />
            }
            <DatePicker
                //minDate={calendarMinDate}
                onAccept={handleCalendarAccept}
                onChange={handleCalendarChange}
                onClose={handleCalendarClose}
                open={calendarOpen}
                style={{ display: 'none' }} // Temporal fix to hide the input element
                value={calendarValue}
                variant="dialog"
            />
       
            <input                
                id={'file-'+props.indice}
                onChange={handleSubmitXml}
                ref={input => {
                    archivo = input
                }}
                style={{display:'none'}}   
                type="file"
            />
        </Grid>
    );
}

export default FormGenericDigitalize;