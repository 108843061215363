import React, { Component } from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ButtonRef extends Component{

    constructor(props){
        super(props);
        if(this.props.idRequirement === 0){
            this.state={
                ref: props.idOperation,
                ruta: null,
                liqCodigo: 0,
                pathForms: '/liquidador/nueva-digitalizacion'

            } 
        }else{
            this.state={
                ref: props.idOperation,
                ruta: null,
                liqCodigo: this.props.idRequirement,
                pathForms: `/liquidador/digitalizar/${btoa(this.props.idRequirement)}`
            } 
        
        }
            
            
            
            
        
        
        

    }
    componentDidMount = ()=>{
       
        
        switch (this.state.ref) {
            case 1:
                this.setState({
                    ruta: `${ this.state.pathForms }/rembolso`
                });
                break;
            case 4:
                this.setState({
                    ruta: `${ this.state.pathForms }/farmacia`
                });
                break;
            case 5:
                this.setState({
                    ruta: `${ this.state.pathForms }/servicios-medicos`
                });

                break;
            case 6:
                this.setState({
                    ruta: `${ this.state.pathForms }/autorizacion-hospitalaria`
                });
    
                break;
            case 7:
                this.setState({
                    ruta: `${ this.state.pathForms }/actualizacion`
                });
                break;
            case 8:
                this.setState({
                    ruta: `${ this.state.pathForms }/rembolso`
                });
                break;

            case 9:
                this.setState({
                    ruta: `${ this.state.pathForms }/desafiliacion`
                });
                break;

            default:
                break;
        }
    }

    
    render(){
        return(
            <div>
                {
                    this.state.ruta ? <Button
                        variant="contained"
                        href={this.state.ruta}
                        endIcon={<ArrowForwardIosIcon />}
                    >
                    DIGITALIZAR
                    </Button>: 
                        <CircularProgress />

                }
                
 
                

            </div>
        );
    }
}