import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { FilesDropzoneAgended } from 'components'
import React, { useState, useEffect } from 'react'
import { PlanProvider } from 'providers/plan.service';
import { DisplayMessage } from 'notifications/displayMessage';


export default function Results() {

    const queries = new PlanProvider();
    const displayMessage = new DisplayMessage();

  const [errorTipo, setErrorTipo] = useState(false);
    const [errorArchivos, setErrorArchivos] = useState(false);
    const [errorArchivosSize, setErrorArchivosSize] = useState(false);
    const [files, setFiles] = useState([]);
    const [planes, setPlanes] = useState([]);
    const [plaCodigo, setPlaCodigo] = useState('')
    const [tipoPlan,setTipoPlan] = useState('')
    const [selecPlanes, setSelectPlanes] = useState([])

    const [empresas, setEmpresas] = useState(null);
    const [empCodigo, setEmpCodigo] = useState('')

    const [openBack, setOpenBack] = useState(false)

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')



    useEffect(() => {
        

        queries.empresa()
        .then(
            async response => {
                if (response.error.codigo === '0') {
                    setEmpresas(response.bodyOut.data)
                } else {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
                //setDesactivar(false);
            }
        );
    }, [])
    
    

  const handleClickOpen = files => {
    let error = false;
    setErrorTipo(false);
    setErrorArchivos(false);

    if (!files.length) {
        setErrorArchivos(true);
        error = true;
    }
    let totalSize = 0;
    files.map(file => {
        totalSize += file.size;
        return 0;
    });

    const fileSize = totalSize / 1024 / 1024;

    if (fileSize > 100) {
        setErrorArchivosSize(true);
        error = true;
    }

    if (error) {
        return;
    }
    setFiles(files);
    handleSave(files)
    //console.log(files);
    /* props.dataFiles(files);
    props.dataDescripcion(descripcion); */
};

const handleSave =(file) => {
    if(plaCodigo === '' || empCodigo === ''){
        displayMessage.error('Seleccione plan')
    }else{
  setOpenBack(true)
  queries.createPolicyMasi(
                    file,
                    plaCodigo,
                    empCodigo                    
                )
                .then(
                    async response => {
                        if (response.error.codigo === '0') {
                            //setDesactivar(false);

                                displayMessage.success(
                                    'Archivo subido correctamente'
                                );
                                window.location.reload()
                        } else {
                            setOpenBack(false)
                            setOpenError(true)
                            setMsgError(response.error.mensaje)
                            displayMessage.error(
                                'Error al subir el archivo'
                            );
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                        //setDesactivar(false);
                        setOpenBack(false)
                    }
                );
            }
   }

   const handleChange = (e) =>{
       setTipoPlan(e.target.value)
       setSelectPlanes([])
       setPlaCodigo('')
       queries.planes(e.target.value)
        .then(
            async response => {
                
                if (response.error.codigo === '0') {
                    setSelectPlanes(response.bodyOut.data)
                } else {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
                //setDesactivar(false);
            }
        );
   }

   const handleChangeEmpresa = (e) =>{
       setPlanes([])
       setPlaCodigo('')
       setTipoPlan('')
       setSelectPlanes([])
    setEmpCodigo(e.target.value)

    queries.typePlan(e.target.value)
        .then(
            async response => {
                
                if (response.error.codigo === '0') {
                    setPlanes(response.bodyOut.data)
                } else {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
                //setDesactivar(false);
            }
        );
}

const handleChangePlan = (e) => {
    setPlaCodigo(e.target.value)
}


  return (
    <div>
        {empresas ?
        <>
      <Card>
        <CardHeader title='Subir documento'/>
        <Divider/>
        <CardContent>
            <Grid
            container spacing={2}
            >
                 <Grid
                 item
                 xs={6}
                >
 <FormControl variant="filled" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Empresa</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={empCodigo}
          onChange={handleChangeEmpresa}
        >
            {empresas.map(item =>
          <MenuItem value={item.empCodigo} key={item.empCodigo}>
            {item.empRazonSocial}
          </MenuItem>
          )}
        </Select>
      </FormControl>
                </Grid>
                <Grid 
                item
                xs={6}/>
                {planes.length >0 &&
                <Grid
                 item
                 xs={6}
                >
        <FormControl variant="filled" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Tipo plan</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={tipoPlan}
          onChange={handleChange}
        >
            {planes.map((item,index) =>
          <MenuItem value={item.plaNombre} key={index}> 
            {item.plaNombre}
          </MenuItem>
          )}
        </Select>
      </FormControl>
                </Grid>
                }
                {selecPlanes.length>0 &&
                <Grid
                 item
                 xs={6}
                >
 <FormControl variant="filled" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Plan</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={plaCodigo}
          onChange={handleChangePlan}
        >
            {selecPlanes.map((item,index) =>
          <MenuItem value={item.plaCodigo} key={index}> 
            {item.plaProducto}
          </MenuItem>
          )}
        </Select>
      </FormControl>
                </Grid>
               }
            </Grid>
       <br/>
        <FilesDropzoneAgended callback={handleClickOpen.bind(this)}></FilesDropzoneAgended> 
        {errorArchivos && (
                        <Alert severity="error">
                            Seleccione archivos para la solicitud!
                        </Alert>
                    )}
                    {errorArchivosSize && (
                        <Alert severity="error">
                            Los archivos superan los 100 MB permitidos
                        </Alert>
                    )} 
        </CardContent>
      </Card>
      <Dialog
       aria-describedby="alert-dialog-description"
       aria-labelledby="alert-dialog-title"
       open={openBack}
       style={{
           opacity: 1,
           transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
       }}
      >
          <DialogContent>
        <CircularProgress color="inherit" />
          </DialogContent>
      </Dialog>

      <Dialog
       aria-describedby="alert-dialog-description"
       aria-labelledby="alert-dialog-title"
       open={openError}
       style={{
           opacity: 1,
           transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
       }}
      >
          <DialogContent>
        {msgError}
          </DialogContent>
          <DialogActions>
              <Button
              variant='outlined'
              color='primary'
              onClick={()=>setOpenError(false)}
              >
                  Cerrar
              </Button>
          </DialogActions>
      </Dialog>
      </>
     :<CircularProgress/>}
    </div>
  )
}
