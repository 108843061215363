import React, { Component } from 'react';
import { PersonalProvider } from 'providers/persona.service';
import { QueriesProvider } from 'providers/queries.service';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { TelefonoProvider } from 'providers/telefono.service';
import { LocationProvider } from 'providers/location.service';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import PersonUpdateModal from '../PersonUpdate';
import 'moment/locale/es';
import moment from 'moment';
import { validateOnlyLetters, validateOnlyNumbers } from '../../../../services/validation';
import { SearchBasic } from 'components';
import { DisplayMessage } from 'notifications/displayMessage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { Grid, Tooltip } from '@material-ui/core';

export default class PersonList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchPerson : [],
            openUpdateModal: false,
            columns: [
                { title: 'Identificación', field: 'perIdentificacion' },
                { title: 'Nombres', field: 'perNombres' },
                { title: 'Apellidos', field: 'perApellidos' },
                { title: 'Nacionalidad', field: 'perNacionalidad' },
                { title: 'Siniestroso', field: 'detNombre' },
            ],

            formFrontToBack: [
                { back: 'PER_IDENTIFICACION', front: 'perIdentificacion' },
                { back: 'PER_NOMBRES', front: 'perNombres' },
                { back: 'PER_APELLIDOS', front: 'perApellidos' },
                { back: 'PER_NACIONALIDAD', front: 'perNacionalidad' },
                { back: 'DET_NOMBRE', front: 'detEstadoPodetliza' },
            ],
            allPersons: [],
            identification: '',
            deletedPhones: [],
            perCodigo: 0,
            typeIdentification: 0,
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            typePhoneSelected: '',
            phoneNumber: '',
            observationPhone: '',
            personSelected: null,
            personNumbers: [],
            bankAccounts: [],
            searchBar: '',
            booleano: false,
            accountNumber: '',
            accountOwnerIdentification: '',
            accountOwnerName:'',
            ifi: 0,
            typeAccount: 0,
            siniestroso: '',
            total: 0, /**---------------tabla */
        }

        this.tableRef = React.createRef();
        this.detalleCatalogoProvider = new DetalleCatalogoProvider()
        this.personalProvider = new PersonalProvider();
        this.telefonoProvider = new TelefonoProvider();
        this.locationProvider = new LocationProvider();
        this.queriesProvider = new QueriesProvider();
        /** Metodos para actualizar */
        this.displayMessage = new DisplayMessage();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    /* componentDidMount = () => {
        this.tableRef.current.onQueryChange();
    } */
    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState, value) => {
        this.setState({
            [nameState]: value
        })
    }

    /** -----------------------------------Traer todas las personas */
    getAllPersons = async () => {
        await this.personalProvider.findAllPersons().then(

            async response => {
                await this.setState({ allPersons: response });
            }
        );
    }


    // ------------------------------------Cambiar el estado del modal   


    setOpenPersonUpdateModal = async(state, person) => {

        if (state === true) {
            await this.setData(person);
            await this.getLocation(person.locCodigo);
            await this.changeOneState('personSelected',person);
        }
        if (state === false) {
            this.changeOneState('personSelected', null);
            this.cleanFields();

        }
        await this.changeOneState('openUpdateModal', state);

    }

    /** ------------------------------------ Buscar NUmeros Telefonicos */
    getAllPhoneNumbers = (perCodigo) => {
        this.queriesProvider.searchPhone(perCodigo).then(
            response => {

                this.changeOneState('personNumbers', response.bodyOut.data);

            }
        )

    }

    /** ------------------------------------ Buscar Localizacion por codigo de persona */

    getLocation = personId => {
        this.locationProvider.findLocationByLocCodigo(personId).then(
            response => {
                this.changeOneState('location', response[0])

            }
        );

    }
    /** ==================================================================== UPDATE =========================*/
    /**-----------------------------------------------handleInputChange (Tecleo en Textfield) */
    handleInputChange = (event,) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'accountNumber':
                if(validateOnlyNumbers(value) ){
                    this.changeOneState(name, value);
                }
                break;
            case 'accountOwnerIdentification':
                if(validateOnlyNumbers(value) ){
                    this.changeOneState(name, value);
                }
                break;
            case 'phoneNumber':
                if(validateOnlyNumbers(value)){
                    this.changeOneState(name, value);
                }
                break;
            case 'identification':
                if (value.length < 16) {
                    this.changeOneState(name, value);
                }
                break;
            case 'name':
                if (validateOnlyLetters(value)) {
                    this.changeOneState(name, value);
                }
                break;
            case 'lastname':
                if (validateOnlyLetters(value)) {
                    this.changeOneState(name, value);
                }
                break;

            case 'searchBar':
                this.changeOneState(name, value);
                break;

            default:
                this.changeOneState(name, value);
                break;
        }
    }

    handleSearch = () => {
        const { searchBar } = this.state;
        this.handleSearchPerson(searchBar);


    }

    searchAlgorithm = (value) => {
        this.setState({ searchBar: value });
    }

    handleSearchPerson = (search) =>{
        const { searchBar, formFrontToBack, searchPerson } = this.state;
        

         this.queriesProvider.searchOr(search).then(
            response => {
                if (response.error.codigo === '0' && response.bodyOut.data.length) {
                    this.setState({ booleano: false, searchPerson: response.bodyOut.data });
                    
                } else {
                    this.setState({ booleano: false });
                
                }

            }
        ).catch(() => {
            this.setState({ booleano: false });
        });

    }

    concatUniquePolicyes = (nameState, newArray,) => {
        let arrayElements = this.state.allPersons;
        arrayElements = arrayElements.concat(newArray);
        let uniqueElements = [...new Set(arrayElements)];
        this.changeOneState(nameState, uniqueElements);
    }

    /** -------------------------Cambiar datos del autocomplete */
    onChangeElement = async (event, values) => {
        /** Ninguna de las id deben tener option */
        this.changeOneState('location', values);

    }

    /** -------------------------Cambiar datos del autocomplete de ifis */
    onChangeIfi = (event, values) => {
        this.changeOneState('ifi', values);

    }


    /** --------------------------Cambiar datePicker  */
    handleDateChange = (val) => {
        this.changeOneState('birthdate', val);
    }
    /** ---------------------------Cambiar Fecha de retorno */
    changeFormatDate = async (dateMySQL) => {
        const myDate = await dateMySQL.split('T')
        const onlyDate = await myDate[0].split('-');

        return await `${onlyDate[2]}/${onlyDate[1]}/${onlyDate[0]}`;
    }
    /** --------------------------Setear los campos al comenzar */
    setData = async person => {
        this.personalProvider.findPersonByPerCodigo(person.perCodigo).then(
            async response => {
                const getPerson = response[0];
                const birthdateDatabase = await getPerson.perFechaNacimiento.substring(0,10);
                const dateFormat = await moment(birthdateDatabase, 'YYYY-MM-DD')
                await this.setState({
                    perCodigo: getPerson.perCodigo,
                    birthdate: dateFormat,
                    direction: getPerson.perDireccion,
                    gender: getPerson.detGenero,
                    identification: getPerson.perIdentificacion,
                    lastname: getPerson.perApellidos,
                    mail: getPerson.perMail,
                    nacionality: getPerson.perNacionalidad,
                    name: getPerson.perNombres,
                    observationPhone: '',
                    phoneNumber: '',
                    typeIdentification: getPerson.detTipoIdentificacion,
                    personNumbers: getPerson.telefono,
                    bankAccounts: getPerson.cuentasBancarias,
                    accountOwnerIdentification: getPerson.perIdentificacion,
                    accountOwnerName: `${getPerson.perNombres} ${getPerson.perApellidos}`,
                    siniestroso: getPerson.detEstadoPoliza,
                });
            }
        )

    }

    /** ------------------------------Guardar Telefonos 1947-04-12 03:28:36.0 */
    savePhonesArray = arrayNumbers => {
        this.setState({
            personNumbers: arrayNumbers,
            typePhoneSelected: '',
            phoneNumber: '',
            observationPhone: ''
        });
    }

    /** ----------------------------Guardar Numeros de cuentas bancarias */
    saveBanksAccountsArray = (array) => {
        const {identification, name, lastname } = this.state;
        this.setState({
            bankAccounts: array,
            typeAccount: 0,
            ifi: 0,
            accountNumber: '',
            accountOwnerIdentification: identification,
            accountOwnerName: `${name} ${lastname}`,


        });
    }


    /** -------------------------------Eliminar Telefono */
    deletePhone = data => {
        this.setState({ personNumbers: data });
    }

    /**  -------------------------------Setear el de los datos eliminados*/
    setDeletedElements = data => {
        this.changeOneState('deletedPhones', data);
    }

    /**------------------------------- se usa para setear una vez se elimina desde el modal */
    deleteAccount = data => {
        this.setState({
            bankAccounts: data
        });
    }
    /** --------------------------------Limpiar Campos */
    /** -----------------------------------------------Limpiar campos */
    cleanFields = () => {
        this.setState({
            identification: '',
            perCodigo: 0,
            typeIdentification: 0,
            gender: 0,
            location: null,
            name: '',
            lastname: '',
            nacionality: '',
            direction: '',
            mail: '',
            birthdate: moment(),
            personNumbers: [],
            deletedPhones: [],
            bankAccounts:[],
        });

    }

    cleanSearchBar = () => {
      /*   this.tableRef.current.onQueryChange();
        this.changeOneState('searchBar', ''); */
        this.changeOneState('searchBar', '');
        this.changeOneState('booleano', false);
        this.changeOneState('searchPerson', []);
    }
    handleCount = count => {
        this.setState({ count: count })
    }
    /* loadData =  (query, resolve) => {
        const { searchBar, formFrontToBack } = this.state;
        let filterBack = 'PER_CODIGO';
        if (query.orderBy) {
            const backFounded =  formFrontToBack.find(element => element.front === query.orderBy.field);
            filterBack =  typeof (backFounded) !== 'undefined' ? backFounded.back : 'PER_CODIGO';
        }
        const orderBy =  {
            field:  filterBack,
            orderDirection:  query.orderBy ? query.orderDirection : 'asc'
        }

         this.queriesProvider.searchOr(searchBar, orderBy, query.pageSize * (query.page), query.pageSize).then(
            response => {
                if (response.error.codigo === '0' && response.bodyOut.data.length) {
                    this.handleCount(response.bodyOut.data[0].TOTAL);
                    this.setState({ booleano: false });
                    resolve({
                        data: response.bodyOut.data,
                        page: query.page,
                        totalCount: response.bodyOut.data[0].TOTAL
                    });
                } else {
                    this.setState({ booleano: false });
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    this.handleCount(0);

                }

            }
        ).catch(() => {
            this.setState({ booleano: false });
            this.handleCount(0);
            resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
        });
    } */
    setPhoneNumbers = data => {
        this.setState({
            personNumbers: data
        });
    }
    setBankNumbers = data => {
        this.setState({
            bankAccounts: data
        });
    }

    //// ---------------------------Descargar reporte de persona

    dataReportsExport = (typeResult) => {

        this.queriesProvider.dataReportsExportPerson(typeResult, this.state.searchBar, 'PER_CODIGO', 'asc', 0, this.state.count).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                this.downloadFile(response.bodyOut.data, typeResult);
            }
        },
        () => {});
    }

    downloadFile = (data, typeFile) => {

        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].BUSCADOR_PERSONA}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    render() {
        let recorrer = 0;
        return (
            <div>
                <PersonUpdateModal 
                    accountNumber = {this.state.accountNumber} 
                    bankAccounts = {this.state.bankAccounts} 
                    birthdate = {this.state.birthdate} 
                    cleanFields = {this.cleanFields}
                    deletePhone = {this.deletePhone}
                    deletedPhones = {this.state.deletedPhones}
                    direction = {this.state.direction}
                    gender = {this.state.gender}
                    getAllPersons = {this.getAllPersons}
                    handleDateChange = {this.handleDateChange}
                    handleInputChange = {this.handleInputChange}
                    identification={this.state.identification}
                    ifi = {this.state.ifi}
                    lastname = {this.state.lastname} 
                    location = {this.state.location}
                    mail = {this.state.mail}
                    nacionality = {this.state.nacionality}
                    name = {this.state.name}
                    observationPhone = {this.state.observationPhone}
                    onChangeElement = {this.onChangeElement}
                    onChangeIfi = {this.onChangeIfi} 
                    open={this.state.openUpdateModal}
                    perCodigo = {this.state.perCodigo}
                    person={this.state.personSelected}
                    personNumbers={this.state.personNumbers}
                    phoneNumber = {this.state.phoneNumber}
                    saveBanksAccountsArray = {this.saveBanksAccountsArray}
                    savePhonesArray = {this.savePhonesArray}
                    setBankNumbers = {this.setBankNumbers}
                    setDeletedElements = {this.setDeletedElements}
                    setOpenPersonModal={this.setOpenPersonUpdateModal}
                    setPhoneNumbers = {this.setPhoneNumbers}
                    typeAccount = {this.state.typeAccount}
                    typeIdentification= {this.state.typeIdentification}
                    typePhoneSelected = {this.state.typePhoneSelected}
                    accountOwnerIdentification = {this.state.accountOwnerIdentification}
                    accountOwnerName = {this.state.accountOwnerName}
                    siniestroso = {this.state.siniestroso}
                />

                <SearchBasic
                    cleanSearchBar={this.cleanSearchBar}
                    complementaryMessage="Buscar"
                    handleInputChange={this.handleInputChange}
                    handleSearch={this.handleSearch}
                    searchBar={this.state.searchBar}
                />
                <br />
                <Grid
                    container
                    spacing={2}
                    justify="flex-end"

                >
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { this.dataReportsExport(2); }}
                        >

                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5,  width: 100}}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { this.dataReportsExport(3); }}
                        >
                            <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
                <br/>
                <MaterialTable
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Actualizar',
                            onClick: (event, rowData) => this.setOpenPersonUpdateModal(true, rowData)
                        }
                    ]}
                    columns={this.state.columns}
                    components={{
                        Action: props => (
                            <Button
                                color="primary"
                                onClick={(event) => props.action.onClick(event, props.data)}
                                size="small"
                                style={{ textTransform: 'none', marginRight: 10 }}
                                variant="contained"
                            >
                                <VisibilityIcon />Ver
                            </Button>

                        ),
                    }}

                    data={this.state.searchPerson}
                    localization={{
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'

                        },
                        toolbar: {
                            nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                            searchPlaceholder: 'Buscar'
                        },
                        header: {
                            actions: 'Acción'
                        },
                        body: {

                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filter',

                            },

                        }
                    }}

                    options={{
                        search: false,
                        actionsColumnIndex: -1,
                        paging: true,
                        actionsCellStyle: {
                            width: 100,
                            textAlign: 'center'
                        },
                        headerStyle: {
                            fontWeight: 'bold'
                        },
                        toolbar: false,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 50],

                        rowStyle: () => {
                            recorrer = recorrer + 1;
                            return ({
                                backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                            })
                        },
                    }}


                    tableRef={this.tableRef}
                    title={'Persona'}
                />
            </div>
        )
    }
}