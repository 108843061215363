/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import CardAccounting from './CardAccounting';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    dates: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    startDateButton: {
        marginRight: theme.spacing(1)
    },
    endDateButton: {
        marginLeft: theme.spacing(1)
    },
    calendarTodayIcon: {
        marginRight: theme.spacing(1)
    },
    container: {
        marginTop: theme.spacing(3)
    }
}));

const HeaderAccounting = props => {
    const { className, session, ...rest } = props;
    const classes = useStyles();
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();

    const [liquidationsPendingCount, setLiquidationsPendingCount] = useState([]);
    const [liquidationsPayCount, setLiquidationsPayCount] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchLiquidationsPendding();
        fetchLiquidationsPay();
    }, []);

    const fetchLiquidationsPendding = () => {
        setLoading(true);
        liquidationProvider.liquidacionCountByEstado('ESTLIC').then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setLiquidationsPendingCount(response.bodyOut.data[0].existencias);
                setLoading(false);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');        
        }); 
    };

    const fetchLiquidationsPay = () => {
        setLoading(true);
        liquidationProvider.liquidacionCountByEstado('ESTPAG').then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setLiquidationsPayCount(response.bodyOut.data[0].existencias);
                setLoading(false);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');        
        }); 
    };



    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Typography
                component="h2"
                gutterBottom
                variant="overline"
            >
                Inicio
            </Typography>
            <Typography
                component="h1"
                gutterBottom
                variant="h3"
            >
                Bienvenid@, {session.user.usuNombre}
            </Typography>
            <Grid
                className={classes.container}
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >
                    {!loading ?
                        <Button 
                            component={RouterLink} 
                            style={{ justifyContent: 'flex-start', width: '100%'}}
                            to={'/contador/liquidaciones/porpagar'}
                        >                            
                            <CardAccounting 
                                count={liquidationsPendingCount} 
                                style={{ width: '100%' }}
                                title={'Liquidaciones pendientes'}
                                typeCard={'pending'}
                            />
                        </Button>      
                        : <CircularProgress />
                    }
                </Grid>
                <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                >
                    {!loading ? 
                        <Button 
                            component={RouterLink} 
                            style={{ justifyContent: 'flex-start', width: '100%'}}
                            to={'/contador/liquidaciones/pagadas'}
                        >                            
                            <CardAccounting 
                                count={liquidationsPayCount} 
                                style={{ width: '100%' }}
                                title={'Liquidaciones pagadas'}
                                typeCard={'pay'}
                            />
                        </Button>
                        : <CircularProgress />
                    }
                </Grid> 
            </Grid>
        </div>    
    );  
};

HeaderAccounting.propTypes = {
    className: PropTypes.string,
    session: PropTypes.object
};

export default HeaderAccounting;
