import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inner: {
        textAlign: 'center',        
    },
    image: {
        maxWidth: 400,
        opacity: 0.1,
    },
    title: {
        margin: theme.spacing(4, 0, 1, 0)
    }
}));

const ConversationPlaceholder = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.inner}>
                <img
                    alt="Select conversation"
                    className={classes.image}
                    src="/images/logos/logo5.png"
                />
                <Typography
                    className={classes.title}
                    variant="h4"
                >
                Selecciona una Liquidación
                </Typography>
                <Typography variant="subtitle1">
          Escribe un mensaje, tambien puedes enviar archivos
                </Typography>
            </div>
        </div>
    );
};

ConversationPlaceholder.propTypes = {
    className: PropTypes.string
};

export default ConversationPlaceholder;
