import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';

import DashboardLayout from './layouts/Dashboard';
import PresentationView from './views/Presentation';
import ReportsView from 'views/Reports';

/** Nuestras Vistas */
import DocumentsView from './views/Documents';
import FormsView from './views/Forms';

import RequestView from './views/Requests';

import HelpView from './views/Help';
/**
 * Vista Prestadores
 */
import QueriesView from './views/Queries';

import LiquidationsViews from './views/Liquidations';
import MessagesListView from 'views/MessagesList';
import MessagesDetailsView from 'views/MessagesDetails';
import DigitizeView from './views/Digitize';
import PharmacyView from './views/Digitize/ViewDigitalize/Forms/Pharmacy';
import MedicalServicesView from './views/Digitize/ViewDigitalize/Forms/MedicalServices';
import AuditorViews from './views/Auditor';
import LiquidatorViews from './views/Liquidator';
import LiquidationList from './views/LiquidationList';
import RefundFormView from './views/RefundForm';
import DataUpdateFormView from './views/DataUpdateForm';
import DashboardAnalyticsView from './views/DashboardAnalytics';

import CallCenterView from './views/CallCenter';
import PendingPaymentView from './views/PendingPayment';

import HospitalPreauthorizationView from './views/Digitize/ViewDigitalize/Forms/HospitalPreauthorization';
import DisaffiliationView from './views/Digitize/ViewDigitalize/Forms/Disaffiliation';

import PendingToPayView from './views/PendingToPay';
import ConsolidateChargesView from './views/ConsolidateCharges';

import AccountingReportsView from './views/AccountingReports';

import AddBeneficiaryView from './views/AddBeneficiary';
import AuditDetailsView from './views/AuditDetails';
import AuditDetailsReadView from './views/AuditDetailsRead';
import SearchAfiliadoView from './views/SearchPrestador';

import NewDigitizeView from './views/Digitize/NewDigitalize';
import ManagerReportsView from './views/ManagerReports';
import GraficView from './views/Grafic';

import AttentionFormView from './views/AttentionForm';
import ReportLenderView from './views/ReportLender';
import AddPlanView from './views/AddPlan';
import ProductionReportsView from './views/ProductionReports';
import PendingHolderView from './views/PendingHolder';
import AffiliatePaymentsView from './views/AffiliatePayments';
import PendingHolderCancelView from './views/PendingHolderCancel';
import FinancialProductionView from './views/FinancialProduction';
import CreatePolicyView from './views/CreatePolicy';
import ReportAcessView from './views/ReportAcess';
import ReportLiqAudView from './views/ReportLiqAud';
import PersonView from './views/Person';
import PolicyView from './views/Policy';
import ReportLiquidationView from './views/ReporLiquidation';
import ReportLiquidationMessageView from './views/ReporLiquidationMessage';
import DigitalizeOnlyView from './views/DigitizeViewOnly';
import ProductionReportAdminView from './views/ProductionReportsAdmin';
import PaymentReportView from './views/PaymentsReport';
import PlanView from 'views/Plan';
import PlanAddView from 'views/PlanAdd';
import PlanUpdateView from 'views/PlanUpdate';

import ScheduleAppointmentTableView from './views/ScheduleAppointmentTable';
import ScheduleAppointmentView from './views/ScheduleAppointment';
import ScheduleAppointmentEditView from './views/ScheduleAppointmentEdit/ScheduleAppointmentEdit';
import CardPaymentView from './views/CardPayment';

import CatalogDetailView from './views/CatalogDetail';
import CatalogDetailAddView from './views/CatalogDetailAdd';
import CatalogDetailUpdateView from './views/CatalogDetailUpdate';

import AddLeaderView from './views/AddLeader';
import AddLeaderTableView from './views/AddLeaderTable';
import AddLeaderEditView from './views/AddLeaderEdit';

import RecordPaymentsView from './views/RecordPayments';

import CancelRecord from './views/CancelRecord';

import ReportWalletView from './views/ReportWallet';

import CreateUserView from './views/CreateUser';

import RecordLoadFilesPaymentsView from './views/RecordLoadFilesPayments';

import RecordCPNFilesPaymentsView from './views/RecordCPNFilesPayments';
import DownloadBill from 'views/DownloadBill';

import PaymentsHistory from './views/PaymentsHistory';
import VwFnc from 'views/VwFnc';

import CancelPolicy from 'views/CancelPolicy';
import CreatePolicyMasi from './views/CreatePolicyMasi'

import PendingPaymentAll from 'views/PendingPaymentAll';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/home" />
    },
    {
        path: '/auth',
        component: AuthLayout,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('views/Login'))
            },
            {
                path: '/auth/forgotpassword',
                exact: true,
                component: lazy(() => import('views/ForgotPassword'))
            },
            {
                path: '/auth/changepassword/:token',
                exact: true,
                component: lazy(() => import('views/ChangePassword'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/home',
                exact: true,
                component: PresentationView
            },

            {
                path: '/mensajes',
                exact: true,
                component: lazy(() => import('views/MessagesList'))
            },
            {
                path: '/mensajes/:id',
                exact: true,
                component: lazy(() => import('views/MessagesList'))
            },
            {
                path: '/ayuda',
                exact: true,
                component: HelpView
            },
            {
                path: '/cliente/formularios',
                exact: true,
                component: FormsView
            },
            {
                path: '/cliente/solicitud',
                exact: true,
                component: RequestView
            },
            {
                path: '/cliente/documentos',
                exact: true,
                component: DocumentsView
            },
            {
                path: '/cliente/reportes',
                exact: true,
                component: ReportsView
            },
            {
                path: '/cliente/mensajes',
                exact: true,
                component: MessagesListView
            },
            {
                path: '/cliente/mensajes/:id',
                exact: true,
                component: MessagesDetailsView
            },
            {
                path: '/cliente/mensajes/:id',
                exact: true,
                component: PharmacyView
            },
            {
                path: '/cliente/pago-tarjeta',
                exact: true,
                component: CardPaymentView
            },
            {
                path: '/prestador/consultas',
                exact: true,
                component: QueriesView
            },
            {
                path: '/prestador/titular',
                exact: true,
                component: PendingHolderView
            },
            {
                path: '/pagos/afiliados',
                exact: true,
                component: AffiliatePaymentsView
            },
            {
                path: '/prestador/titular/cancelados',
                exact: true,
                component: PendingHolderCancelView
            },
            {
                path: '/prestador/formulario',
                exact: true,
                component: AttentionFormView
            },
            {
                path: '/prestador/reporte',
                exact: true,
                component: ReportLenderView
            },
            {
                path: '/liquidador/nueva-digitalizacion',
                exact: true,
                component: NewDigitizeView
            },
            {
                path: '/liquidador/nueva-liquidacion',
                exact: true,
                component: RequestView
            },
            {
                path: '/liquidador/nueva-digitalizacion/rembolso',
                exact: true,
                component: RefundFormView
            },
            {
                path: '/liquidador/digitalizar',
                exact: true,
                component: DigitizeView
            },
            {
                path: '/liquidador/formularios/actualizacion',
                exact: true,
                component: DataUpdateFormView
            },
            {
                path: '/liquidador/digitalizar/:id',
                exact: true,
                component: DigitizeView
            },
            {
                path: '/liquidador/digitalizar/:id/farmacia',
                exact: true,
                component: PharmacyView
            },

            {
                path: '/liquidador/digitalizar/:id/autorizacion-hospitalaria',
                exact: true,
                component: HospitalPreauthorizationView
            },
            {
                path: '/liquidador/digitalizar/:id/servicios-medicos',
                exact: true,
                component: MedicalServicesView
            },
            {
                path: '/liquidador/digitalizar/:id/actualizacion',
                exact: true,
                component: DataUpdateFormView
            },
            {
                path: '/liquidador/digitalizar/liquidaciones',
                exact: true,
                component: LiquidationsViews
            },
            {
                path:
                    '/liquidador/nueva-digitalizacion/autorizacion-hospitalaria',
                exact: true,
                component: HospitalPreauthorizationView
            },
            {
                path: '/liquidador/nueva-digitalizacion/farmacia',
                exact: true,
                component: PharmacyView
            },
            {
                path: '/liquidador/nueva-digitalizacion/servicios-medicos',
                exact: true,
                component: MedicalServicesView
            },

            {
                path: '/liquidador/nueva-digitalizacion/actualizacion',
                exact: true,
                component: DataUpdateFormView
            },
            {
                path:
                    '/liquidador/nueva-digitalizacion/autorizacion-hospitalaria',
                exact: true,
                component: HospitalPreauthorizationView
            },
            {
                path: '/liquidador/nueva-digitalizacion/rembolso',
                exact: true,
                component: RefundFormView
            },
            {
                path: '/liquidador/nueva-digitalizacion/desafiliacion',
                exact: true,
                component: DisaffiliationView
            },
            {
                path: '/liquidador/digitalizar/:id/actualizacion',
                exact: true,
                component: DataUpdateFormView
            },
            {
                path: '/liquidador/digitalizar/:id/desafiliacion',
                exact: true,
                component: DisaffiliationView
            },

            {
                path: '/liquidador/liquidaciones',
                exact: true,
                component: LiquidatorViews
            },
            {
                path: '/liquidador/liquidaciones/:option',
                exact: true,
                component: LiquidationList
            },
            {
                path: '/liquidador/reporteLiquidaciones',
                exact: true,
                component: ReportLiquidationView
            },
            {
                path: '/liquidador/historial/:id',
                exact: true,
                component: ReportLiquidationMessageView
            },
            {
                path: '/liquidador/digitalizarVer/:id',
                exact: true,
                component: DigitalizeOnlyView
            },
            {
                path: '/liquidador/digitalizar/:id/rembolso',
                exact: true,
                component: RefundFormView
            },

            {
                path: '/liquidador/auditoria',
                exact: true,
                component: AuditorViews
            },
            {
                path: '/liquidador/mensajes',
                exact: true,
                component: MessagesListView
            },

            {
                path: '/liquidador/reporte',
                exact: true,
                component: ReportLiqAudView
            },
            {
                path: '/auditor/liquidaciones/:option',
                exact: true,
                component: LiquidationList
            },
            {
                path: '/auditor/auditoria/:id',
                exact: true,
                component: AuditDetailsView
            },
            {
                path: '/liquidador/liquidadiones/:action/:id',
                exact: true,
                component: AuditDetailsReadView
            },
            {
                path: '/contador/liquidadiones/:action/:id',
                exact: true,
                component: AuditDetailsReadView
            },
            {
                path: '/auditor/reporte',
                exact: true,
                component: ReportLiqAudView
            },
            {
                path: '/callcenter/consultas',
                exact: true,
                component: CallCenterView
            },
            {
                path: '/gerencia/reportes',
                exact: true,
                component: DashboardAnalyticsView
            },
            {
                path: '/gerencia/grafico',
                exact: true,
                component: GraficView
            },
            {
                path: '/gerencia/datos/reportes',
                exact: true,
                component: ManagerReportsView
            },
            {
                path: '/gerencia/clientes',
                exact: true,
                component: ProductionReportsView
            },
            {
                path: '/gerencia/access',
                exact: true,
                component: ReportAcessView
            },
            {
                path: '/liquidador/notificaciones',
                exact: true,
                component: LiquidationsViews
            },
            {
                path: '/liquidador/formularios/actualizacion',
                exact: true,
                component: DataUpdateFormView
            },
            {
                path: '/liquidador/pendientePagar',
                exact: true,
                component: PendingPaymentView
            },
            {
                path: '/contador/pendiente-pagar',
                exact: true,
                component: PendingPaymentView
            },
            {
                path: '/contador/consolidar-pagos',
                exact: true,
                component: PendingToPayView
            },
            {
                path: '/contador/notificaciones',
                exact: true,
                component: LiquidationsViews
            },

            {
                path: '/contador/consolidar-cobros',
                exact: true,
                component: ConsolidateChargesView
            },
            {
                path: '/contador/reportes',
                exact: true,
                component: AccountingReportsView
            },
            {
                path: '/contador/produccion',
                exact: true,
                component: FinancialProductionView
            },
            {
                path: '/contador/liquidaciones/:option',
                exact: true,
                component: LiquidationList
            },
            {
                path: '/cliente/agregar/beneficiario',
                exact: true,
                component: AddBeneficiaryView
            },
            /*  {
                 path: '/cliente/prestadores',
                 exact: true,
                 component: SearchAfiliadoView
             }, */
            {
                path: '/cliente/descargarFacturas',
                exact: true,
                component: DownloadBill
            },
            {
                path: '/administrador/reporteLiquidaciones',
                exact: true,
                component: PaymentsHistory
            },
            //RecordLoadFilesPayments
            {
                path: '/cargar-datos',
                exact: true,
                component: RecordLoadFilesPaymentsView
            },
            {
                path: '/cargar-datos-CPN',
                exact: true,
                component: RecordCPNFilesPaymentsView
            },
            //RecordPaymentsView
            {
                path: '/administrador/detalle-catalogo',
                exact: true,
                component: CatalogDetailView
            },
            {
                path: '/registrar-pagos',
                exact: true,
                component: RecordPaymentsView
            },
            {
                path: '/afiliados_cancelados',
                exact: true,
                component: CancelRecord
            },
            {
                path: '/administrador/detalle-catalogo/agregar',
                exact: true,
                component: CatalogDetailAddView
            },

            {
                path: '/administrador/detalle-catalogo/modificar/:id',
                exact: true,
                component: CatalogDetailUpdateView
            },
            {
                path: '/administrador/agregar-plan',
                exact: true,
                component: AddPlanView
            },
            {
                path: '/administrador/agregar-poliza',
                exact: true,
                component: CreatePolicyView
            },
            {
                path: '/administrador/persona',
                exact: true,
                component: PersonView
            },
            {
                path: '/administrador/poliza',
                exact: true,
                component: PolicyView
            },
            {
                path: '/administrador/reporte',
                exact: true,
                component: ProductionReportAdminView
            },
            {
                path: '/agendarCitas',
                exact: true,
                component: ScheduleAppointmentTableView
            },

            {
                path: '/nuevaCita',
                exact: true,
                component: ScheduleAppointmentView
            },
            {
                path: '/editarCita/:id',
                exact: true,
                component: ScheduleAppointmentEditView
            },
            {
                path: '/administrador/pagos',
                exact: true,
                component: PaymentReportView
            },
            {
                path: '/administrador/nuevoPrestador',
                exact: true,
                component: AddLeaderView
            },
            {
                path: '/administrador/prestador',
                exact: true,
                component: AddLeaderTableView
            },
            {
                path: '/administrador/editarPrestador/:id',
                exact: true,
                component: AddLeaderEditView
            },
            {
                path: '/plan',
                exact: true,
                component: PlanView
            },
            {
                path: '/plan/agregar',
                exact: true,
                component: PlanAddView
            },
            {
                path: '/plan/modificar/:id',
                exact: true,
                component: PlanUpdateView
            },
            {
                path: '/cartera',
                exact: true,
                component: ReportWalletView
            },
            {
                path: '/nuevo-usuario',
                exact: true,
                component: CreateUserView
            },
            {
                path: '/vista',
                exact: true,
                component: VwFnc
            },
            {
                path: '/administrador/cancelar-polizas',
                exact: true,
                component: CancelPolicy
            },
            {
                path: '/crear-poliza-masiva',
                exact: true,
                component: CreatePolicyMasi
            },
            {
                path: '/contador/pendiente-pagar-todos',
                exact: true,
                component: PendingPaymentAll
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    }
];

export default routes;
