/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page, SearchBarCustom } from 'components';
import { Header, Results } from './components';
import { ReportsProvider } from 'providers/reports.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const PendienteAuditoria = () => {
    const classes = useStyles();
    const [total, setTotal] = useState(0);
    const [liquidation, setLiquidation] = useState(null);
    const displayMessage = new DisplayMessage();
    const reportsProvider = new ReportsProvider();

    useEffect(() => {
        fetchLiquidations();
    }, []);

    const fetchLiquidations = (valueSearch = '',valueSearch1 = '',valueSearch2 = '') => {
        reportsProvider.reportesTablaAudit('ESTLI', valueSearch,valueSearch1,valueSearch2).then(response => {   
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setLiquidation(response.bodyOut.data);
                setTotal(response.bodyOut.data.length);
            } else {                
                setLiquidation([]);
                setTotal(0);
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');  
        });   
    };

    const handleSearch = (valueSearch,valueSearch2,valueSearch3) => { 
        fetchLiquidations(valueSearch,valueSearch2,valueSearch3);
    };

    const handleClean = () => { 
        fetchLiquidations();
    };

    return (
        <Page
            className={classes.root}
            title="Liquidaciones"
        >
            <Header 
                className={classes.color}
                total={total}
            />
            <SearchBarCustom
                onClean={handleClean}
                onSearch={handleSearch}
            />
        
            { liquidation ? 
                <Results
                    className={classes.results}
                    data={liquidation}
                />
                : <CircularProgress className={classes.results}/>
            }
            
        </Page>
    );
};

export default PendienteAuditoria;