import React from 'react';
import PropTypes from 'prop-types';
import HeaderClient from "./HeaderClient";
import HeaderLiquidator from "./HeaderLiquidator";
import HeaderAudit from "./HeaderAudit";
import HeaderAccounting from "./HeaderAccounting";

const Header = props => {
  const { session } = props;
  
  return (    
    <div>
      
      { (session.user.role === 'CLIENTE' || session.user.role === 'PRESTADOR')  && <HeaderClient session={session} />}
      { session.user.role === 'LIQUIDADOR' && <HeaderLiquidator session={session} />}
      { session.user.role === 'AUDITOR' && <HeaderAudit session={session} />}
      { session.user.role === 'CONTADOR' && <HeaderAccounting session={session} />}
    </div>        
  );

};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
