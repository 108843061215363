import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import "./styles.css";
export default class HelpContent extends Component {


    render() {




        return (
            //        const { elements, titles, formTitle, formIcon, elementIcon} = this.props

            <div>
                <Typography>
                    Click en
                </Typography>
                <img
                    src="/images/help/buttonDescargar.png"
                    alt='boton descargar'
                />
                <br />
                <br />
                <Typography>
                    Y revise la parte inferior izquierda de su navegador web

                </Typography>
                <img
                    src="/images/help/mostrarDescarga.png"
                    alt='decumento descargado'

                />
                <br />
                <br />
                <Typography>
                    <div className='red'>
                        * PARA VISUALIZAR SU CONTRATO BUSQUE EL APARTADO DE IMPORTANTE
                   </div>
                </Typography>
            </div>

        );
    }


}