import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import './styles.css';
import  ButtonRef  from './components/ButtonRef';
import { DialogComponent } from './components/Dialog';

export default class GenericTable extends Component{

    constructor(props){
        super(props);
        this.state={
            titles: null,
            data: null,
            document: null,
            open: false,

        }
    }

    handleClose = () => {
        this.selectDocument(null);
        this.setState({
            open: false,
            
        });

    }

    handleOpen = ( document ) => {
        this.selectDocument( document );

        this.setState({
            open: true
        });
    } 

    componentDidMount(){

        this.setState({
            titles: this.props.titles,
            data: this.props.data
        });
    }                   

    classes = this.useStyles;

    createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    selectDocument = ( document )=> {
        this.setState({
            document: document
        });
    }

    render(){
        return(
            <div>
                {this.props.data ? 
                    <TableContainer component={Paper} >
                        <Table 
                            aria-label="simple table" 
                            className="table-size"
                           
                        >
                            <TableHead>
                                <TableRow style={{backgroundColor: 'white'}}>
                                    {
                                        this.props.titles.map(
                                            title =>
                                                <TableCell component='th' key={title.id}><b>{title.name}</b></TableCell>
                                        )
                                    }                                
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.props.data.map((form, index)=>
                                        <TableRow key={index} style ={ (index+1) % 2? { background : "#FFF" }:{ background : "#EEE" }}>
                                            <TableCell>{form.detNombre}</TableCell>
                                            <TableCell>
                                                {typeof(form.archivo) !== 'undefined'? 
                                                    <Button 
                                                        color="primary" 
                                                        download={form.detDestino} 
                                                        href={`data:application/octet-stream;base64,${form.archivo}`}
                                                        target="_blank" 
                                                        variant="contained"
                                                        size='small'
                                                        startIcon={<GetAppIcon />}
                                                    >
                                                        Descargar </Button> 
                                                    :null
                                                }
                                            </TableCell>
                                            {
                                                form.digitalizar &&  
                                                    <TableCell 
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <div className="left">
                                                            {form.digitalizar ===1 ? 
                                                                <ButtonRef 
                                                                    idOperation={form.detCodigo}
                                                                    idRequirement={this.props.idRequirement}
                                                                />
                                                                : form.digitalizar ===2? <DoneIcon />
                                                                    :''
                                                            }
                                                        </div>
                                                    </TableCell>
                                            }
                                                                                           
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>:
                    <CircularProgress />
                }
                
                {
                    this.state.document?  
                        <DialogComponent 
                            file={this.state.document}
                            handleClose={this.handleClose}
                            open={this.state.open}
                        />: ''
                }       
            </div>                    
        );
    }   
}