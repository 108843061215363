export default {
  contained: {
    boxShadow:
    '#49a343',
    backgroundolor: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF' ,
    },
  }
};
