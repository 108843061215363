import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    TextField,
    Divider,
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardActions,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core'; 
import { AtentionFormProviders } from 'providers/attentionForm.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { AmbulatoryCare,Medicines,HomeDoctor,HospitalCare,AmbulatoryEmergency,PhysicalTherapies,Laboratory,DentalCoverage } from './components';


export default class ResultAttentionForm extends React.Component {
  _isMounted=false;
  
  constructor(props){
      super(props);
      this.state={
          datosTipoAtencion:0,
          cedulaAfiliado:'',
          nombresAfiliado:'',
          planAfiliado:'',
          tipoAtencion:'',
          buscarTitular:'',
          polCodigo:'',
          liqCodigo:'',
          liqCodigoMedicina:'',
          liqCodigoMedCasa:'',
          preCodigo:'',
          montoCobertura:'',
          datosAmbulatory:[],
          datosIngresoAmbulatory:[],
          datosIngresoMedicine:[],
          datosIngresoMedCasa:[],
          datosMedicine:[],
          datosMedicoCasa:[],
          datosAtencionHospital:[],
          datosEmergenciaAmbulatoria:[],
          datosTerapiaFisica:[],
          datosLaboratorio:[],
          datosCoberturaDental:[],
          datosIngresoAtenHosp:[],
          datosIngresoEmerAmb:[],
          datosIngresoTerapFisica:[],
          datosIngresoLaboratorio:[],
          datosIngresCoberturaDental:[],
          mostrarAtencionAmbulatoria:false,
          mostrarMedicina: false,
          mostrarMedicoCasa:false,
          mostrarAtencionHospital:false,
          mostrarEmergenciaAmbulatoria:false,
          mostrarTerapiaFisica:false,
          mostrarLaboratorio:false,
          mostrarCoberturaDental:false,
          errorDatos:false,
          rucAA:'',
          diagnosticoAA:'',
          establecmientoAA:'',
          especialidadAA:'',
          open:false
      }
   
  }

  handleClose = () => {
      this.setState({
          open:false
      })
  };

  handleClickOpen = () => {
      const displayMessage = new DisplayMessage();
      if(this.state.buscarTitular === ''){
          displayMessage.error('Busque un Afiliado')
      }else{
          if(this.state.mostrarAtencionAmbulatoria === false && this.state.mostrarMedicina === false && this.state.mostrarCoberturaDental === false && this.state.mostrarAtencionHospital === false &&
        this.state.mostrarEmergenciaAmbulatoria === false && this.state.mostrarMedicoCasa === false && this.state.mostrarTerapiaFisica === false && this.state.mostrarLaboratorio===false){
              displayMessage.error('Seleccione un tipo de atención')
       
          }else{
              this.setState({
                  open:true
              })
          }
      }
  };

  componentDidMount(){
      const atention = new AtentionFormProviders();
      const displayMessage = new DisplayMessage();
      atention.datosTipoAtencion()
          .then(
              user => {
          
                  this.setState({
                      datosTipoAtencion:user.bodyOut.data
                  })
         
              },
              () => {          
                  displayMessage.error('Error de comunicación.');
          
              }
          );
  }

  handleBuscarTitular= ()=>{
      const atention = new AtentionFormProviders();
      const displayMessage = new DisplayMessage();
      const cedula = this.state.buscarTitular;
      if(cedula === ''){
          displayMessage.error('Ingrese una cédula a buscar')
      }else{
  
    
          atention.datosTitular(cedula)
              .then(
                  user => {
                      //console.log(user.bodyOut.data)
                      if(user.bodyOut.data.length !== 0){
                          this.setState({
                              cedulaAfiliado:user.bodyOut.data[0].perIdentificacion,
                              nombresAfiliado:user.bodyOut.data[0].perNombres+' '+user.bodyOut.data[0].perApellidos,
                              planAfiliado:user.bodyOut.data[0].plaNombre,
                              polCodigo:user.bodyOut.data[0].polCodigo,
                              montoCobertura:'$'+user.bodyOut.data[0].pepMontoCobertura,
                          })
                          displayMessage.success('Afiliado encontrado')
                      }else{
                          displayMessage.error('Cedula no encontrada.');
          
                      }
                  },
                  () => {          
                      displayMessage.error('Error de comunicación.');
          
                  }
              );
      }
  }

  getCloseAtencionAmbulatoria=(close)=>{
      this.setState({
          mostrarAtencionAmbulatoria:close,
      })
  }
  getDatosAmbulatoryCare=(data,ruc,diagnostico,establecmiento,especialidad)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosAmbulatory: {...this.state.datosAmbulatory,  [key]:  value}
          })
          this.setState({
              rucAA:ruc,
              diagnosticoAA:diagnostico,
              establecmientoAA:establecmiento,
              especialidadAA:especialidad
          })
      
      }

   
  }

  getCloseMedicina=(close)=>{
      this.setState({
          mostrarMedicina:close,
      
      })
  }

  getDatosMedicina=(data,preCodigo,establecimiento)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosMedicine: {...this.state.datosMedicine,  [key]:  value}
          })
      }
      this.setState({
          preCodigoMedicina:preCodigo,
          establecimientoMedicina:establecimiento
      })
  }

  getCloseMedicoCasa=(close)=>{
      this.setState({
          mostrarMedicoCasa:close,
      
      })
  }

  getDatosMedicoCasa=(data,preCodigo,diagnostico)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosMedicoCasa: {...this.state.datosMedicoCasa,  [key]:  value}
          })
      }
      this.setState({
          preCodigoMedCasa:preCodigo,
          diagnosticoMedCasa:diagnostico
      })
  }

  getCloseAtencionHospital=(close)=>{
      this.setState({
          mostrarAtencionHospital:close,
      
      })
  }

  getDatosAtencionHospital=(data,preCodigo,establecimiento,diagnostico)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosAtencionHospital: {...this.state.datosAtencionHospital,  [key]:  value}
          })
      }
      this.setState({
          preCodigoAtenHosp:preCodigo,
          establecimientoAtenHosp:establecimiento,
          diagnosticoAtenHosp:diagnostico
      })
  }

  getCloseEmergenciaAmbulatoria=(close)=>{
      this.setState({
          mostrarEmergenciaAmbulatoria:close,
      
      })
  }

  getDatosEmergenciaAmbulatoria=(data,preCodigo,establecimiento,diagnostico)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosEmergenciaAmbulatoria: {...this.state.datosEmergenciaAmbulatoria,  [key]:  value}
          })
      }
      this.setState({
          preCodigoEmerAmb:preCodigo,
          esetablecimientoEmerAmb:establecimiento,
          diagnosticoEmerAmb:diagnostico
      })
  }

  getCloseTerapiaFisica=(close)=>{
      this.setState({
          mostrarTerapiaFisica:close,
      
      })
  }

  getDatosTerapiaFisica=(data,preCodigo,establecimiento)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosTerapiaFisica: {...this.state.datosTerapiaFisica,  [key]:  value}
          })
      }
      this.setState({
          preCodigoTerFisica:preCodigo,
          establecimientoTerFisica:establecimiento
      })
  }

  getCloseLaboratorio=(close)=>{
      this.setState({
          mostrarLaboratorio:close,
      
      })
  }

  getDatosLaboratorio=(data,preCodigo,establecimiento)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosLaboratorio: {...this.state.datosLaboratorio,  [key]:  value}
          })
      }
      this.setState({
          preCodigoLaboratorio:preCodigo,
          establecimientoLaboratorio:establecimiento
      })
  }

  getCloseCoberturaDental=(close)=>{
      this.setState({
          mostrarCoberturaDental:close,
      
      })
  }

  getDatosCoberturaDental=(data,preCodigo,establecimiento)=>{
      for (let [key, value] of Object.entries(data)) {
          this.setState({
              datosCoberturaDental: {...this.state.datosCoberturaDental,  [key]:  value}
          })
      }
      this.setState({
          preCodigoCobDental:preCodigo,
          establecimientoCobDental:establecimiento
      })
  }

  handleChange=(e)=>{
      this.setState({
          [e.target.name]:e.target.value
      })
  }
  handleOnChangeTitular=(value)=>{
      this.setState({
          cedulaAfiliado:value.perIdentificacion,
          nombresAfiliado:value.perNombres+' '+value.perApellidos,
          planAfiliado:value.plaNombre
      })
  }

  handleChangeTipoAtencion=(e)=>{
      this.setState({
          tipoAtencion:e.target.value
      });
      if(e.target.value === 23523){
          this.setState({
              mostrarAtencionAmbulatoria:true,
              ver:true,
              tipoAtencioAmbulatoria:e.target.value
          })
      }
      if(e.target.value === 23524){
          this.setState({
              mostrarMedicina:true,
              tipoMedicina:e.target.value
          })
      }
      if(e.target.value === 23525){
          this.setState({
              mostrarMedicoCasa:true,
              tipoMedicoCasa:e.target.value
          })
      }
      if(e.target.value === 23526){
          this.setState({
              mostrarAtencionHospital:true,
              tipoAtencioHospital:e.target.value
          })
      }
      if(e.target.value === 23527){
          this.setState({
              mostrarEmergenciaAmbulatoria:true,
              tipoEmergencia:e.target.value
          })
      }
      if(e.target.value === 23528){
          this.setState({
              mostrarTerapiaFisica:true,
              tipoTerapiaFisica:e.target.value
          })
      }
      if(e.target.value === 23529){
          this.setState({
              mostrarLaboratorio:true,
              tipoAtencioLaboratorio:e.target.value
          })
      }
      if(e.target.value === 23530){
          this.setState({
              mostrarCoberturaDental:true,
              tipoDental:e.target.value
          })
      }
  }


  formatoFecha(string){
      var f = string.split('-').reverse().join('/');
      return f;
  }

  handleSend=async()=>{
      const atention = new AtentionFormProviders();
      const displayMessage = new DisplayMessage();
      if(this.state.buscarTitular === ''){
          displayMessage.error('Busque un Afiliado')
      }else{
          if(this.state.mostrarAtencionAmbulatoria === false && this.state.mostrarMedicina === false && this.state.mostrarCoberturaDental === false && this.state.mostrarAtencionHospital === false &&
        this.state.mostrarEmergenciaAmbulatoria === false && this.state.mostrarMedicoCasa === false && this.state.mostrarTerapiaFisica === false && this.state.mostrarLaboratorio===false){
              displayMessage.error('Seleccione un tipo de atención')
          }else{
              /* ATENCION AMBULATORIA */
              if(this.state.mostrarAtencionAmbulatoria === true ){
                  const {fecha,nombreProfesional,monto} = this.state.datosAmbulatory
                  const agregar = {
                      rucDoctorAtencAmbulatoria:this.state.rucAA,
                      especialidad: this.state.especialidadAA,
                      fecha:fecha,
                      diagnostico:this.state.diagnosticoAA,
                      establecmiento:this.state.establecmientoAA,
                      nombreProfesional:nombreProfesional,
                      monto:monto        
                  }
                  if(agregar.rucDoctorAtencAmbulatoria === ''|| agregar.especialidad === ''  || agregar.fecha === undefined || agregar.fecha === '' || agregar.diagnostico === '' ||
       agregar.monto === undefined ||  agregar.monto === '' || agregar.nombreProfesional === undefined || agregar.nombreProfesional === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigo:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigo,
                          detTipo:this.state.tipoAtencioAmbulatoria,
                          preCodigo:this.state.rucAA,
                          delMedico:nombreProfesional,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'ATENCION AMBULATORIA',
                          delDinamico:`especialidad: ${this.state.especialidadAA}, establecmiento:${this.state.establecmientoAA},diagnostico:${this.state.diagnosticoAA}`
                
                      }

                      const data = this.state.datosIngresoAmbulatory;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Atencion Ambulatoria guardada correctamente')
                                  this.setState({mostrarAtencionAmbulatoria:false,datosAmbulatory:[],open:false})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* MEDICINA */
              if(this.state.mostrarMedicina === true){

                  const {fecha,doctorResponsable,copago,monto} = this.state.datosMedicine
                  const agregar = {
                      preCodigo:this.state.preCodigoMedicina,
                      establecimiento: this.state.establecimientoMedicina,
                      fecha:fecha,
                      doctorResponsable:doctorResponsable,
                      copago:copago,
                      monto:monto        
                  }
                  if(agregar.preCodigo === ''|| agregar.establecimiento === ''  || agregar.fecha === undefined || agregar.fecha === '' || agregar.doctorResponsable === '' ||
      agregar.doctorResponsable === undefined || agregar.monto === undefined ||  agregar.monto === '' || agregar.copago === undefined || agregar.copago === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoMedicina:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoMedicina,
                          detTipo:this.state.tipoMedicina,
                          preCodigo:this.state.preCodigoMedicina,
                          delMedico:doctorResponsable,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'MEDICINAS',
                          delDinamico:`establecmiento:${this.state.establecimientoMedicina}, copago:${copago}`
                
                      }

                      const data = this.state.datosIngresoMedicine;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Medicinas guardadas correctamente')
                                  this.setState({mostrarMedicina:false,open:false,datosMedicine:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* MEDICO CASA */
              if(this.state.mostrarMedicoCasa === true){
                  const {fecha,profesional,copago,monto} = this.state.datosMedicoCasa
                  const agregar = {
                      preCodigo:this.state.preCodigoMedCasa,
                      diagnostico: this.state.diagnosticoMedCasa,
                      fecha:fecha,
                      profesional:profesional,
                      copago:copago,
                      monto:monto        
                  }
                  if(agregar.preCodigo === ''|| agregar.diagnostico === ''  || agregar.fecha === undefined || agregar.fecha === '' || agregar.profesional === '' ||
      agregar.profesional === undefined || agregar.monto === undefined ||  agregar.monto === '' || agregar.copago === undefined || agregar.copago === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoMedCasa:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoMedCasa,
                          detTipo:this.state.tipoMedicoCasa,
                          preCodigo:this.state.preCodigoMedCasa,
                          delMedico:profesional,
                          delFecha:fechaset,
                          delValor:monto, 
                          delDescripcion:'MEDICO A DOMICILIO',
                          delDinamico:`diagnostico:${this.state.diagnosticoMedCasa},copago:${copago} `
                
                      }

                      const data = this.state.datosIngresoMedCasa;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Medico a domicilio guardado correctamente')
                                  this.setState({mostrarMedicoCasa:false,open:false,datosMedicoCasa:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }

              /* ATENCION HOSPITALARIA */
              if(this.state.mostrarAtencionHospital === true){
                  const {fecha,procedimiento,copago,deducible,monto} = this.state.datosAtencionHospital
                  const agregar = {
                      perCodigo:this.state.preCodigoAtenHosp,
                      diagnostico: this.state.diagnosticoAtenHosp,
                      fecha:fecha,
                      procedimiento:procedimiento,
                      establecimiento:this.state.establecimientoAtenHosp,
                      copago:copago,
                      deducible:deducible,
                      monto:monto        
                  }
                  if(agregar.perCodigo === ''|| agregar.diagnostico === ''  || agregar.fecha === undefined || agregar.fecha === '' || agregar.procedimiento === '' ||
      agregar.procedimiento === undefined || agregar.monto === undefined ||  agregar.monto === '' || agregar.copago === undefined || agregar.copago === ''
      || agregar.deducible === undefined || agregar.deducible === '' || agregar.establecimiento === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoAtenHosp:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoAtenHosp,
                          detTipo:this.state.tipoAtencioHospital,
                          preCodigo:this.state.preCodigoAtenHosp,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'ATENCION HOSPITALARIA',
                          delDinamico:` procedimiento: ${procedimiento},diagnostico:${this.state.diagnosticoAtenHosp},establecimiento:${this.state.establecimientoAtenHosp}, copago:${copago}, deducible:${deducible}` 
                
                      }
                      const data = this.state.datosIngresoAtenHosp;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Atencion Hospitalaria guardada correctamente')
                                  this.setState({mostrarAtencionHospital:false,open:false,datosAtencionHospital:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* EMERGENCIA AMBULATORIA */
              if(this.state.mostrarEmergenciaAmbulatoria === true){
                  const {fecha,copago,deducible,monto} = this.state.datosEmergenciaAmbulatoria
                  const agregar = {
                      perCodigo:this.state.preCodigoEmerAmb,
                      diagnostico: this.state.diagnosticoEmerAmb,
                      fecha:fecha,
                      establecimiento:this.state.esetablecimientoEmerAmb,
                      copago:copago,
                      deducible:deducible,
                      monto:monto
                  }
                  if(agregar.perCodigo === ''|| agregar.diagnostico === ''  || agregar.fecha === undefined || agregar.fecha === '' || 
     agregar.monto === undefined ||  agregar.monto === '' || agregar.copago === undefined || agregar.copago === ''
      || agregar.deducible === undefined || agregar.deducible === '' || agregar.establecimiento === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoEmerAmb:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoEmerAmb,
                          detTipo:this.state.tipoEmergencia,
                          preCodigo:this.state.preCodigoEmerAmb,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'EMERGENCIA AMBULATORIA',
                          delDinamico:`diagnostico:${this.state.diagnosticoEmerAmb}, establecmiento:${this.state.esetablecimientoEmerAmb}, copago:${copago}, deducible:${deducible}`
                
                      }

                      const data = this.state.datosIngresoEmerAmb;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Emergencia Ambulatoria guardada correctamente')
                                  this.setState({mostrarEmergenciaAmbulatoria:false,open:false,datosEmergenciaAmbulatoria:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* TERAPIA FISICA */
              if(this.state.mostrarTerapiaFisica === true){
                  const {fecha,motivo,monto} = this.state.datosTerapiaFisica
                  const agregar = {
                      perCodigo:this.state.preCodigoTerFisica,
                      fecha:fecha,
                      establecimiento:this.state.establecimientoTerFisica,
                      motivo:motivo,
                      monto:monto
                  }
                  if(agregar.perCodigo === ''|| agregar.establecimiento === ''  || agregar.fecha === undefined || agregar.fecha === '' || 
     agregar.monto === undefined ||  agregar.monto === '' || agregar.motivo === undefined || agregar.motivo === ''){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoTerFisica:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoTerFisica,
                          detTipo:this.state.tipoTerapiaFisica,
                          preCodigo:this.state.preCodigoTerFisica,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'TERAPIA FISICA',
                          delDinamico:`motivo:${motivo}, establecmiento:${this.state.establecimientoTerFisica}`
                        
                      }

                      const data = this.state.datosIngresoTerapFisica;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Terapia Física guardada correctamente')
                                  this.setState({mostrarTerapiaFisica:false,open:false,datosTerapiaFisica:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* LABORATORIO */
              if(this.state.mostrarLaboratorio === true){
                  const {fecha,motivo,monto,copago} = this.state.datosLaboratorio
                  const agregar = {
                      perCodigo:this.state.preCodigoLaboratorio,
                      fecha:fecha,
                      establecimiento:this.state.establecimientoLaboratorio,
                      motivo:motivo,
                      copago:copago,
                      monto:monto
                  }
                  if(agregar.perCodigo === ''|| agregar.establecimiento === ''  || agregar.fecha === undefined || agregar.fecha === '' || 
     agregar.monto === undefined ||  agregar.monto === '' || agregar.motivo === undefined || agregar.motivo === '' ||
     agregar.copago === '' || agregar.copago === undefined){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoLaboratorio:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoLaboratorio,
                          detTipo:this.state.tipoAtencioLaboratorio,
                          preCodigo:this.state.preCodigoLaboratorio,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'LABORATORIO',
                          delDinamico:`motivo:${motivo},copago:${copago}, establecimiento:${this.state.establecimientoLaboratorio}`
                        
                      }

                      const data = this.state.datosIngresoLaboratorio;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Laboratorio guardado correctamente')
                                  this.setState({mostrarLaboratorio:false,open:false,datosTerapiaFisica:[]})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
              /* COBERTURA DENTAL */
              if(this.state.mostrarCoberturaDental === true){
                  const {fecha,procedimiento,monto,copago} = this.state.datosCoberturaDental
                  const agregar = {
                      perCodigo:this.state.preCodigoCobDental,
                      fecha:fecha,
                      establecimiento:this.state.establecimientoCobDental,
                      procedimiento:procedimiento,
                      copago:copago,
                      monto:monto
                  }
                  if(agregar.perCodigo === ''|| agregar.establecimiento === ''  || agregar.fecha === undefined || agregar.fecha === '' || 
     agregar.monto === undefined ||  agregar.monto === '' || agregar.procedimiento === undefined || agregar.procedimiento === '' ||
     agregar.copago === '' || agregar.copago === undefined){
                      displayMessage.error('Complete todos los campos son obligatorios')
                      this.setState({open:false})
                  }else{
                      const fechaset = this.formatoFecha(fecha);
                      await  atention.crearLiquidacion1([],this.state.polCodigo)
                          .then(
                              user => {        
                                  this.setState({
                                      liqCodigoCobDental:user.bodyOut.data[0].liqCodigo
                                  })
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
            
                              }
                          );
                      const agregarDetalleLiqu = {
                          liqCodigo:this.state.liqCodigoCobDental,
                          detTipo:this.state.tipoDental,
                          preCodigo:this.state.preCodigoCobDental,
                          delFecha:fechaset,
                          delValor:monto,
                          delDescripcion:'COBERTURA DENTAL',
                          delDinamico:`procedimiento:${procedimiento},copago:${copago}, establecimiento:${this.state.establecimientoCobDental}`
                        
                      }

                      const data = this.state.datosIngresCoberturaDental;
                      data.push(agregarDetalleLiqu);
                      this.setState({ data })

                      await  atention.crearDetalleLiquidacion(data)
                          .then(
                              detLiq => {        
                                  displayMessage.success('Cobertura dental guardada correctamente')
                                  this.setState({mostrarCoberturaDental:false,datosCoberturaDental:[],open:false})
                              },
                              () => {          
                                  displayMessage.error('Error de comunicación.');
          
                              }
                          );
                  }
              }
          }
      }
    
    
  }

  render(){
      return (
          <div>
              {this.state.datosTipoAtencion ?
                  <form>
                      <Card>
                          <CardHeader title="Datos Afiliado"/>
                          <Divider/>
                          <CardActions>
                              <Grid
                                  //className={classes.item}
                                  item
                                  md={3}
                                  sm={6}
                                  xs={12}
                              >
       
                                  <TextField
                                      fullWidth
                                      name="buscarTitular"
                                      onChange={this.handleChange}
                                      placeholder="Buscar por cédula"
                                      value={this.state.buscarTitular}
                                  />
                              </Grid>
                              <Grid
                                  //className={classes.item}
                                  item
                                  md={3}
                                  sm={6}
                                  xs={12}
                              >
                                  <Button
                                      color="primary"
                                      onClick={this.handleBuscarTitular}
                                      variant="contained"
                                  >Buscar</Button>
                              </Grid>
                          </CardActions>
                          <CardContent>
                              <Grid
                                  container
                                  spacing={4}
                              >
                                  <Grid
                                      item
                                      md={12}
                                      sm={12}
                                      xs={12}
                                  />
                                  <Grid
                                      //className={classes.item}
                                      item
                                      md={3}
                                      sm={6}
                                      xs={12}
                                  >
                                      <TextField
                                          fullWidth
                                          InputProps={{
                                              readOnly: true,
                                          }}
                                          label="Cédula"
                                          name="cedulaAfiliado"
                                          onChange={this.handleChange}
                                          value={this.state.cedulaAfiliado}
                                      />
                                  </Grid>
                                  <Grid
                                      //className={classes.item}
                                      item
                                      md={3}
                                      sm={6}
                                      xs={12}
                                  >
                                      <TextField
                                          fullWidth
                                          InputProps={{
                                              readOnly: true,
                                          }}
                                          label="Nombres"
                                          name="nombresAfiliado"
                                          onChange={this.handleChange}
                                          value={this.state.nombresAfiliado}
                                      />
                                  </Grid>
                                  <Grid
                                      //className={classes.item}
                                      item
                                      md={3}
                                      sm={6}
                                      xs={12}
                                  >
                                      <TextField
                                          fullWidth
                                          InputProps={{
                                              readOnly: true,
                                          }}
                                          label="Plan"
                                          name="planAfiliado"
                                          onChange={this.handleChange}
                                          value={this.state.planAfiliado}
                                      />
                                  </Grid>
                                  <Grid
                                      //className={classes.item}
                                      item
                                      md={3}
                                      sm={6}
                                      xs={12}
                                  >
                                      <TextField
                                          fullWidth
                                          InputProps={{
                                              readOnly: true,
                                          }}
                                          label="Monto Cobertura"
                                          name="montoCobertura"
                                          onChange={this.handleChange}
                                          value={this.state.montoCobertura}
                                      />
                                  </Grid>
                                  <Grid
                                      item
                                      md={12}
                                      sm={12}
                                      xs={12}
                                  >
                                      <FormControl fullWidth>
                                          <InputLabel >Tipo de Atención</InputLabel>
                                          <Select
                                              name="tipoAtencion"
                                              onChange={this.handleChangeTipoAtencion}
                                              value={this.state.tipoAtencion}
                 
                                          >
                                              <MenuItem value="">SELECCIONE</MenuItem>
                                              {this.state.datosTipoAtencion ? this.state.datosTipoAtencion.map((especialidad,index) =>
                                                  especialidad.detCodigo !== 17185 &&   especialidad.detCodigo !== 17186 &&   especialidad.detCodigo !== 17184 &&
                            especialidad.detCodigo !== 17183 &&
                            <MenuItem
                                key={index}
                                value={especialidad.detCodigo}
                            >
                                {especialidad.detNombre}
                            </MenuItem>
                                              ):null}
                                          </Select>
                                      </FormControl>
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </Card><br/>
                      {this.state.mostrarAtencionAmbulatoria && <AmbulatoryCare
                          close={this.getCloseAtencionAmbulatoria.bind(this)}
                          datos={this.getDatosAmbulatoryCare.bind(this)}
                                                                />}
                      {this.state.mostrarMedicina && <Medicines
                          close={this.getCloseMedicina.bind(this)}
                          datos={this.getDatosMedicina.bind(this)}
                                                     />}
                      {this.state.mostrarMedicoCasa && <HomeDoctor
                          close={this.getCloseMedicoCasa.bind(this)}
                          datos={this.getDatosMedicoCasa.bind(this)}
                                                       />}
                      {this.state.mostrarAtencionHospital && <HospitalCare
                          close={this.getCloseAtencionHospital.bind(this)}
                          datos={this.getDatosAtencionHospital.bind(this)}
                                                             />}
                      {this.state.mostrarEmergenciaAmbulatoria && <AmbulatoryEmergency
                          close={this.getCloseEmergenciaAmbulatoria.bind(this)}
                          datos={this.getDatosEmergenciaAmbulatoria.bind(this)}
                                                                  />}
                      {this.state.mostrarTerapiaFisica && <PhysicalTherapies
                          close={this.getCloseTerapiaFisica.bind(this)}
                          datos={this.getDatosTerapiaFisica.bind(this)}
                                                          />}
                      {this.state.mostrarLaboratorio && <Laboratory
                          close={this.getCloseLaboratorio.bind(this)}
                          datos={this.getDatosLaboratorio.bind(this)}
                                                        />}
                      {this.state.mostrarCoberturaDental && <DentalCoverage
                          close={this.getCloseCoberturaDental.bind(this)}
                          datos={this.getDatosCoberturaDental.bind(this)}
                                                            />}
                      <Button 
                          color="primary"
                          onClick={this.handleClickOpen}
                          variant="contained"
                      >
        Guardar
                      </Button>
        
                  </form>
                  : <CircularProgress/>}

              <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  onClose={this.handleClose}
                  open={this.state.open}
              >
                  <DialogTitle id="alert-dialog-title">{'Enviar'}</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                ¿Guardar Cambios y enviar?
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button
                          color="primary"
                          onClick={this.handleClose}
                      >
                Cancelar
                      </Button>
                      <Button
                          autoFocus
                          color="primary"
                          onClick={this.handleSend}
                      >
                Si, Enviar
                      </Button>
                  </DialogActions>
              </Dialog> 
          </div>
      );
  }
}