/* eslint no-eval: 0 */
import React, { Component } from 'react';

import { Page } from 'components';

import { GenericBasicForm } from 'components';
import { GenericHeaderFoms } from 'components';
import { DetalleCatalogoProvider } from './../../../../../providers/detalleCatalogo.service';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ActualDate } from './../../../../../services/ActualDate';
import { DisplayMessage } from 'notifications/displayMessage';

import { DetalleLiquidacionProvider } from './../../../../../providers/detalleLiquidacion.service';
import { PersonalProvider } from 'providers/persona.service';

import './styles.css';

export default class PharmacyView extends Component {
    data = ['PARACETAMOL', 10, 20, '$20'];
    columna = ['MEDICAMENTO', 'CANTIDAD', 'UNIDAD', 'VALOR']

    constructor(props) {
        super(props);
        this.decryptedData = typeof (props.match.params.id) === 'undefined' ? 0 : atob(props.match.params.id);

        this.state = {
            idRequirement: this.decryptedData,
            showMedicines: false,
            AllMedicines: null,
            AddedMedicines: [],
            name: '',
            ruc: '',
            totalValue: 0,
            date: ActualDate(),
            toSave: [],
            liqCodigo: this.decryptedData,
            disabled: false,
            errorText: {
                name: '',
                ruc: '',
                date: '',
                totalValue: ''
            },
            touched: {
                name: false,
                ruc: false,
                date: false,
                totalValue: false
            },
            errors: {

                required: {
                    name: false,
                    ruc: false,
                    date: false,
                    totalValue: false
                },
                valid: {
                    name: true,
                    ruc: true,
                    date: true,
                    totalValue: true
                }
            },




        }




        this.pP = new PersonalProvider();


        this.handleInputChange = this.handleInputChange.bind(this);


    }

    hasError = (field) => {
        return (
            (this.state.errors.required[field]) &&
            this.state.touched[field]
        );
    }

    componentDidMount = () => {
        this.findAllMedicines();
        this.getUserRequirement();
    }

    getUserRequirement = () => {
        if (this.state.idRequirement > 0) {
            this.pP.LiquidationByliqCodigo(this.state.idRequirement).then(
                (response) => {
                    try {
                        this.setState({
                            userRequirement: response[0]
                        });
                    } catch (error) {
                        console.error(error)

                    }
                }
            );
        }
    }

    findAllMedicines = () => {
        const dCS = new DetalleCatalogoProvider();
        dCS.findDetalleCatalogo(7).then(
            response => {


                this.setState({
                    AllMedicines: response
                });
            }
        );
    }
    AddMedicine = (medicine) => {
        const salvedArray = this.state.AddedMedicines;
        salvedArray.push(medicine);
        const total = 0;
        salvedArray.map((med) => {
            this.total = total + med[4];

            return null
        })
        this.setState({
            AddedMedicines: salvedArray,
            totalValue: total
        });


        this.enDisButton();

    }
    findAllMedicines = () => {
        const dCS = new DetalleCatalogoProvider();
        dCS.findDetalleCatalogo(7).then(
            response => {

                this.setState({
                    AllMedicines: response
                });
            }
        );
    }

    handleClickOpen = () => {
        this.setState({
            showMedicines: true
        });
    }

    handleClose = () => {
        this.setState({
            showMedicines: false
        })
    }

    //17206

    isTouched = (name) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true }
        });

    }

    validateNull = (element) => {

        if (element.value.length === 0 || element.value === 0) {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: 'Este Campo es requerido'
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: true },
                valid: { ...this.state.errors.valid }
            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        } else {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: ''
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: false },
                valid: { ...this.state.errors.valid }


            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        }

    }
    handleInputChange = async (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        await this.setState({
            [name]: value
        });


        await this.isTouched(name);

        await this.validateNull({ name: name, value: value });

        /**   if (eval(`this.state.${name}`) === ''
               || eval(`this.state.${name}`) === 0) {
               await this.setState({
                   [`${name}Error`]: true
               });
           } else {
               await this.setState({
                   [`${name}Error`]: false
               });
           }*/

        //await this.enDisButton()
    }

    enDisButton = () => {
        const { date, name, totalValue, ruc, AddedMedicines } = this.state;
        if (date !== '' && name !== '' && totalValue !== ''
            && ruc !== '' &&
            AddedMedicines.length > 0) {
            this.setState({
                disabled: false
            })
        } else {
            this.setState({
                disabled: true

            });
        }
    }

    showEmpty = async () => {
        await this.isTouched('name');
        await this.isTouched('ruc');
        await this.isTouched('date');
        await this.isTouched('totalValue');

        await this.validateNull({ name: 'name', value: this.state.name })
        await this.validateNull({ name: 'ruc', value: this.state.ruc })

        await this.validateNull({ name: 'date', value: this.state.date })
        await this.validateNull({ name: 'totalValue', value: this.state.totalValue })

    }


    isEmpty = async (field, state) => {
        const errors = {
            ...this.state.errors, [field]: state
        }
        await this.setState({
            errors: errors
        });

    }

    digitalize = async () => {

        const toSaveAux = [];

        const { date, name, totalValue, ruc, AddedMedicines, liqCodigo } = this.state;

        const displayMessage = new DisplayMessage()

        if (date !== '' && name !== '' && totalValue !== ''
            && ruc !== '' && AddedMedicines.length > 0) {

            new Promise((resolve, reject) => {
                AddedMedicines.map((medicine) => {
                    this.setState({
                        disabled: true
                    });
                    const element = {
                        'liqCodigo': liqCodigo,
                        'detTipo': 17206,
                        'delPrestadorRuc': ruc,
                        'delPrestadorNombre': name,
                        'delFecha': this.convertToMySQLDate(date),
                        //no hay valor total, el mijin no le ha puesto no cacho por que

                        'delDescripcion': medicine[0].detDescripcion ? medicine[0].detDescripcion : medicine[0],
                        'delCantidad': medicine[1],
                        'delUnidad': medicine[2],
                        'delValor': medicine[3]
                    }
                    toSaveAux.push(element);
                    return null;
                });



                setTimeout(
                    () => toSaveAux.length > 0
                        ? resolve(toSaveAux)
                        : reject(new Error('error, cantidad de datos intentando guardar ' + toSaveAux.length))
                    , 100
                );




            }).then(
                (response) => {
                    const dLP = new DetalleLiquidacionProvider();
                    dLP.insertDetalleLiquidacion(response).then(

                        (res) => {

                            if (res.error.codigo === '0') {
                                this.enDisButton();
                                displayMessage.success('Digitalizacion correcta');
                                this.resetValues();
                            }
                        }
                    ).catch(
                        (error) => {
                            displayMessage.error('Ha ocurrido un error al intentar almacenar la informacion');
                            this.enDisButton();

                            console.error(error)
                        }
                    );
                }
            ).then(
                () => {
                }
            )




        } else {
            displayMessage.error('Se han detectado campos vacios');
            //thisthis.showEmpty();
            this.showEmpty();

        }
    }



    resetValues = () => {
        this.setState({
            ruc: '',
            name: '',
            date: ActualDate(),
            totalValue: 0,
            AddedMedicines: [],
            disabled: true
        });
    }
    setTotalValue = (val) => {
        this.setState({
            totalValue: val.toFixed(2)
        });
    }
    convertToMySQLDate = (dateString) => {

        //dd/MM/yyyy HH:mm:ss
        var dateCad = dateString.split('-')


        const dateConverted = `${dateCad[2]}/${dateCad[1]}/${dateCad[0]}`;

        return dateConverted;
    }

    render() {




        const columna = ['MEDICAMENTO', 'CANTIDAD', 'UNIDAD', 'VALOR'
        ]


        const Labels = ['NOMBRE FARMACIA', 'RUC', 'VALOR TOTAL', 'FECHA'];
        //        const { advice, labels, btnAgregarText,data,columna } = this.props;

        const modalInputs = ['CANTIDAD', 'UNIDAD', 'VALOR']

        //const {userRequirement, AllMedicines, AddedMedicines, totalValue, name, ruc, date} =this.state;
        return (
            <Page
                title="Farmacia"
            >
                {
                    this.state.userRequirement ?
                        <GenericHeaderFoms
                            requirement={`REQ-${this.state.userRequirement.liqCodigo}`}
                            name={this.state.userRequirement.usuNombre}
                        ></GenericHeaderFoms> : ''
                }


                {
                    this.state.AllMedicines ?

                        <GenericBasicForm
                            advice="GASTOS DE FARMACIA"
                            labels={Labels}
                            btnAgregarText="MEDICAMENTO"
                            data={this.state.AddedMedicines}
                            columna={columna}
                            dataSelect={this.state.AllMedicines}
                            SelectDatafield="dataSelect.detDescripcion"
                            modalInputs={modalInputs}
                            addData={this.AddMedicine}
                            dataOfObject='campo.detDescripcion'
                            idOfObject='campo.detCodigo'
                            valShow='element[0].detDescripcion'
                            totalValue={this.state.totalValue}
                            hasError={this.hasError}
                            errorsText={this.state.errorText}
                            setTotalValue={this.setTotalValue}
                            handleInputChange={this.handleInputChange}
                            name={this.state.name}
                            ruc={this.state.ruc}
                            date={this.state.date}
                            nameError={this.state.nameError}
                            rucError={this.state.rucError}
                            dateError={this.state.dateError}
                            totalValueError={this.state.totalValueError}
                            errors={this.state.errors}
                            //enDisButton={this.enDisButton}

                        >
                        </GenericBasicForm> : <CircularProgress />

                }

                <div className="right">
                    <Button variant="contained" color="primary"
                        onClick={this.digitalize}
                        disabled={this.state.disabled}
                    >Digitalizar</Button>

                </div>

            </Page>

        );
    }

}
