import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Sistema de medicina prepagada MEDICOMPANIES C.A. 
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            Prestadores
          </Typography>
          <br></br>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info" style={{marginBottom: 10, fontSize: '14px'}} elevation={5}>
            <b>
              Toda atención debe ser coordinada por call center al: 1800 456 456 - 096 336 5555 - 02 3801940, no de manera directa.
              No olvides de llevar el formulario de reembolso.
            </b>
          </Alert>
        </Grid>
        <br></br>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
