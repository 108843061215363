export class StorageService {
    userID = ""
    constructor() {
        if (this.getSession())
            this.userID = this.getSession().id
    }
    save(key, data) {
        localStorage.setItem(key, JSON.stringify(data))
    }
    load(key) {
        return JSON.parse(localStorage.getItem(key));
    }
    remove(key) {
        localStorage.removeItem(key);
    }
    saveSession(data) {
        localStorage.setItem("user", JSON.stringify(data))
    }
    getSession() {
        return JSON.parse(localStorage.getItem("user"))
    }
    removeSession() {
        localStorage.removeItem('user');
    }
}