import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import TableUser from './components/Results/components/tableUser/TableUser';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const SupportForm = () =>{

    const classes = useStyles();
    const session = useSelector(state => state.session);  
    const [view, setView] = useState(1);
    return (
        <Page
            className={classes.root}
            title="Usuarios"
        >
            <Header />
            <br />
            { view === 0  ? (
                <Results
                    className={classes.results}
                    poliza={session.user.polCodigo}
                    setView={setView}
                />
            ) :
                (
                    <TableUser setView={setView}/>
                )
            }
            
        </Page>
    )

    

};

export default SupportForm;
