/* eslint no-eval: 0 */

import React, { Component } from 'react';

import { Page } from 'components';

import { GenericBasicForm } from "components";
import './styles.css';


import { GenericHeaderFoms } from "components";
import { TarifarioProvider } from './../../../../../providers/tarifario.service';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ActualDate } from './../../../..7../../../../services/ActualDate';

import Button from '@material-ui/core/Button';

import { DisplayMessage } from 'notifications/displayMessage';

import { DetalleLiquidacionProvider } from './../../../../../providers/detalleLiquidacion.service';

import { PersonalProvider } from 'providers/persona.service';


export default class MedicalServicesView extends Component {
    f1 = ["CITAS MEDICAS", 10, 2, "$20"];
    f2 = ["CITAS MEDICAS", 10, 2, "$20"];
    data = [];

    columna = ["SERVICIOS", "CANTIDAD", "UNIDAD", "VALOR"]

    constructor(props) {
        super(props);
        this.decryptedData = typeof (props.match.params.id) === 'undefined' ? 0 : atob(props.match.params.id);


        this.state = {
            showMedicines: false,
            AllMedicalServices: null,
            AddedServices: [],
            name: '',
            ruc: '',
            totalValue: 0,
            date: ActualDate(),
            idRequirement: this.decryptedData,
            liqCodigo: this.decryptedData,
            disabled: false,
            nameError: false,
            rucError: false,
            dateError: false,
            totalValueError: false,
            errorText: {
                name: '',
                ruc: '',
                date: '',
                totalValue: ''
            },
            touched: {
                name: false,
                ruc: false,
                date: false,
                totalValue: false
            },
            errors: {

                required: {
                    name: false,
                    ruc: false,
                    date: false,
                    totalValue: false
                },
                
            },

        }

        this.pP = new PersonalProvider();

    }

    hasError = (field) => {
        return (
            (this.state.errors.required[field]) &&
            this.state.touched[field]
        )
    }
    validateNull = (element) => {
        
        if (element.value.length === 0 || element.value === 0) {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: 'Este Campo es requerido'
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: true },
                valid: { ...this.state.errors.valid }
            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        } else {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: ''
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: false },
                valid: { ...this.state.errors.valid }


            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        }

    }

    isTouched = (name) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true }
        });
    }

    handleInputChange = async (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        await this.setState({
            [name]: value
        });

        await this.isTouched(name);

        await this.validateNull({ name: name, value: value });

       // await this.enDisButton()
    }

    showEmpty = async () => {
        await this.isTouched('name');
        await this.isTouched('ruc');
        await this.isTouched('date');
        await this.isTouched('totalValue');
        await this.validateNull({ name: 'name', value: this.state.name })
        await this.validateNull({ name: 'ruc', value: this.state.ruc })
        await this.validateNull({ name: 'date', value: this.state.date })
        await this.validateNull({ name: 'totalValue', value: this.state.totalValue })

    }

    enDisButton = () => {
        if (this.state.date !== ''
            && this.state.ruc !== ''
            && this.state.name !== ''
            && this.state.totalValue !== ''
            &&
            this.state.AddedServices.length > 0) {
            this.setState({
                disabled: false
            })
        } else {
            this.setState({
                disabled: true

            });
        }
    }


    handleClickOpen = () => {
        this.setState({
            showMedicines: true
        });
    }
    componentDidMount = () => {
        this.findAllTarifarios();
        this.getUserRequirement();
    }


    findAllTarifarios = () => {
        const tS = new TarifarioProvider();
        tS.findTarifarios(1).then(
            response => {
                this.setState(
                    {
                        AllMedicalServices: response
                    }
                );
            }
        )
    }

    getUserRequirement = () => {
        if (this.state.idRequirement > 0) {
            this.pP.LiquidationByliqCodigo(this.state.idRequirement).then(
                (response) => {
                    try {
                        this.setState({
                            userRequirement: response[0]
                        });
                    } catch (error) {
                        console.error(error)

                    }
                }

            );

        }

    }
    handleClose = () => {
        this.setState({
            showMedicines: false
        })
    }
    addService = async (service) => {
        const salvedArray = this.state.AddedServices;
        salvedArray.push(service);
        await this.setState({
            AddedServices: salvedArray
        });
        await this.enDisButton();
    }

    setTotalValue = (val) => {
        this.setState({
            totalValue: val.toFixed(2)
        });
    }

    convertToMySQLDate = (dateString) => {

        //dd/MM/yyyy HH:mm:ss
        var dateCad = dateString.split("-")


        const dateConverted = `${dateCad[2]}/${dateCad[1]}/${dateCad[0]}`;

        return dateConverted;
    }



    digitalize = async () => {
        const toSaveAux = [];

        const displayMessage = new DisplayMessage();

        if (this.state.date !== ''
            && this.state.ruc !== ''
            && this.state.name !== ''
            && this.state.totalValue !== ''
            &&
            this.state.AddedServices.length > 0) {
            new Promise((resolve, reject) => {
                this.state.AddedServices.map((service) => {
                    this.setState({
                        disabled: true
                    });
                    const saveServcie = {
                        "liqCodigo": this.state.liqCodigo,
                        "detTipo": 17206,
                        "delPrestadorRuc": this.state.ruc,
                        "delPrestadorNombre": this.state.name,
                        "delFecha": this.convertToMySQLDate(this.state.date),
                        "delServicio": service[0].tarNombre ? service[0].tarNombre : service[0],
                        "delCantidad": service[1],
                        "delUnidad": service[2],
                        "delValor": service[3]

                    }
                    toSaveAux.push(saveServcie);

                    return null
                });
                setTimeout(
                    () => toSaveAux.length > 0
                        ? resolve(toSaveAux)
                        : reject(new Error(' error, cantidand de datos intentando guardar:  ' + toSaveAux.length))
                    , 100
                );

            }

            ).then(
                (res) => {
                    const dLP = new DetalleLiquidacionProvider();
                    dLP.insertDetalleLiquidacion(res).then(
                        (resp) => {

                            if (resp.error.codigo === "0") {
                                this.enDisButton();

                                this.setState(
                                    {
                                        ruc: '',
                                        name: '',
                                        date: ActualDate(),
                                        totalValue: 0,
                                        AddedServices: [],
                                        disabled: true
                                    }
                                );
                                displayMessage.success("Digitalizacion correcta");

                            }
                        }
                    ).catch(
                        (error) => {
                            displayMessage.error('Ha ocurrido un error al intentar almacenar la informacion');
                            this.enDisButton();

                            console.error(error)
                        }
                    )
                }
            );

        } else {
            this.showEmpty();
            displayMessage.error('Se han detectado campos vacios');

        }

    }

    render() {
        const columna = ["MEDICAMENTO", "CANTIDAD", "UNIDAD", "VALOR"]

        const Labels = ["NOMBRE DEL PRESTADOR", "RUC", "VALOR TOTAL", "FECHA"];
        //        const { advice, labels, btnAgregarText,data,columna } = this.props;

        const modalInputs = ["CANTIDAD", "UNIDAD", "VALOR"]

        return (

            <Page
                title="Gastos Medicos Digitalizar">
                {
                    this.state.userRequirement ?
                        <GenericHeaderFoms
                            requirement={`REQ-${this.state.userRequirement.liqCodigo}`}
                            name={this.state.userRequirement.usuNombre}
                        ></GenericHeaderFoms> : ""
                }
                {
                    this.state.AllMedicalServices ? 
                    <GenericBasicForm
                        advice="GASTOS DE SERVICIOS MÉDICOS"
                        labels={Labels}
                        btnAgregarText="SERVICIO"
                        data={this.state.AddedServices}
                        columna={columna}
                        dataSelect={this.state.AllMedicalServices}
                        SelectDatafield="dataSelect.tarNombre"
                        modalInputs={modalInputs}
                        addData={this.addService}
                        dataOfObject='campo.tarNombre'
                        idOfObject='campo.tarCodigo'
                        valShow='element[0].tarNombre'
                        totalValue={this.state.totalValue}

                        setTotalValue={this.setTotalValue}
                        name={this.state.name}
                        ruc={this.state.ruc}
                        date={this.state.date}
                        handleInputChange={this.handleInputChange}

                        nameError={this.state.nameError}
                        rucError={this.state.rucError}
                        dateError={this.state.dateError}
                        totalValueError={this.state.totalValueError}

                        enDisButton={this.enDisButton}
                        hasError={this.hasError}
                        errorsText={this.state.errorText}
                    >
                    </GenericBasicForm> : <CircularProgress />
                }
                <div className="right">
                    <Button variant="contained"
                        color="primary"
                        onClick={this.digitalize}
                        disabled={this.state.disabled}
                    >Digitalizar</Button>

                </div>

            </Page>
        );
    }

}