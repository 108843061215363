import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  CardContent,
  Input,
  FormControl,
  Divider,
  Button,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  Grid,
} from '@material-ui/core';
import './styles.css';

import { RelationShipProvider } from "providers/relationship.service";
import { DisplayMessage } from "notifications/displayMessage";

class Application extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: false,
      onClose: false,
      ci:'',
      apellidos:'',
      nombres:'',
      edad:'',
      fecha:'',
      genero:'',
      parentesco:'',
      dataParentesco:[],
      dataParentesco1:[],
      datosEditar: [],
      
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    
  }



  handleClose = () => {
    this.setState({open:false});
  };

  handleOpen = () => {
    this.setState({open:true});
  };

  componentDidMount(){
    const relationship = new RelationShipProvider();
    
    const displayMessage = new DisplayMessage();

    relationship.datosParentesco()
      .then(
        data => {
          this.setState({dataParentesco:data.bodyOut.data});
        },
        () => {          
          displayMessage.error('Error de comunicación.');

        }

      );

  }


  onClose=()=>{
    this.props.close && this.props.close();
  }

 

  handleChange(e){
  
    this.setState({[e.target.name]:e.target.value})
  
  }

  handleEnviar=()=>{
    const newData =  {
      detTipoIdentificacion:4,
      perIdentificacion:this.state.ci,
      perApellidos:this.state.apellidos,
      perNombres:this.state.nombres,
      perFechaNacimiento:this.state.fecha,
      perFechaRegistro: "27/02/2020 10:53:20",
      perDireccion:"Actualizar",
      perNacionalidad:'Ecuatoriana',
      perMail:"prueba@hotmail.es",
      perGenero:this.state.genero,
      perEstado:1
    };
    
    const data = this.state.dataParentesco1;
          data.push(newData);
          this.setState({ data })
 
    this.props.callback(data);
    this.props.close && this.props.close()

    this.setState({
      ci:'',
      apellidos:'',
      nombres:'',
      edad:'',
      fecha:'',
      genero:'',
      parentesco:'',
    })
  }
  handleChangeSelect=(e)=>{
    this.setState({parentesco: e.target.value})
  }

  

 
  render(){
     return (
    
    <Dialog
      open={this.props.open}
      scroll={"body"}
    >
      <div className="content">
        <Grid className="header">
        <Grid className="closeButton1">
          <Typography  component="h1"
            variant="h4"> Agregar Beneficiario </Typography>
          </Grid>
        <Grid className="closeButton">
        {this.onClose ? (
          
        <IconButton aria-label="close" className="" onClick={this.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      </Grid>
          
        </Grid>
        <Divider/>
        <Grid className="">
        
        <CardContent className="">
          {/* <PerfectScrollbar> */}
            <Grid className="campos">
              <form>
                      <FormControl  style={{paddingBlockEnd:0}}>
                      <InputLabel shrink>Cédula</InputLabel>
                        <Input
                        type="text"
                          name="ci"
                          value={this.state.ci}
                          onChange={this.handleChange}
                          style={{width:250}} 
                          variant="outlined"               
                        />
                      </FormControl>
                    <br></br>
                      <FormControl>
                      <InputLabel shrink>Apellidos</InputLabel>
                        <Input
                          name="apellidos"
                          value={this.state.apellidos}
                          onChange={this.handleChange}   
                          style={{width:250}}              
                        />
                      </FormControl>
                      <br></br>
                      <FormControl>
                      <InputLabel shrink >Nombres</InputLabel>
                        <Input
                          name="nombres"
                          value={this.state.nombres}
                          onChange={this.handleChange}  
                          style={{width:250}}               
                        />
                      </FormControl>
                      <br></br>
                      <FormControl>
                      <InputLabel shrink >Edad</InputLabel>
                        <Input
                        type="text"
                          name="edad"
                          value={this.state.edad}
                          onChange={this.handleChange}
                          style={{width:250}}                 
                        />
                      </FormControl>
                      <br></br>
                      <FormControl>
                      <InputLabel shrink>Fecha</InputLabel>
                        <Input
                          type="date"
                          className="select"
                          name="fecha"
                          value={this.state.fecha}
                          onChange={this.handleChange}  
                          style={{width:250}}               
                        />
                      </FormControl>
                      <br></br>
                      
                      <FormControl>
                        <InputLabel shrink >Género</InputLabel>
                        <Input
                          name="email"
                          value={this.state.genero}
                          onChange={this.handleChange}
                          style={{width:250}} 
                        />
                      </FormControl>
                      <br></br>
                      <FormControl>
                      <InputLabel >Parentesco</InputLabel>
                        <Select 
                          className="select" 
                          value={this.state.parentesco}               
                          onChange={this.handleChangeSelect}
                          style={{width:250}} 
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {this.state.dataParentesco ? this.state.dataParentesco.map((parentesco) =>
                          <MenuItem value={parentesco.detNombre} key={parentesco.detCodigo}>
                            {parentesco.detNombre}
                          </MenuItem>
                          
                          ):null}
                          
                        </Select>
                      </FormControl>
                      <Grid> <br></br>
                      <FormControl>
                      <Button variant="contained" color="primary" onClick={this.handleEnviar}>Agregar</Button>
                      </FormControl>
                      </Grid>
              </form>
            </Grid>
          {/* </PerfectScrollbar> */}
          
        </CardContent>
        </Grid>
        </div>
    </Dialog>
    
  )
};
}

Application.propTypes = {
  //author: PropTypes.object.isRequired,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Application;
