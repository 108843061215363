import React, { Component } from "react";
import { Page, TableGeneric, SearchBar, GenericHeader } from "components";
import './styles.css';

export default class CallCenterView extends Component {


    render(){
        const columna = ["CLIENTE", "NUMERO TELEFONICO"];
        const data = ["Javier Norales", "0980722204"]
        return(
            <div className='container-call'>
                <Page >
                    <GenericHeader title='Datos de Nuestros Clientes' />
                    <SearchBar></SearchBar>
                    <TableGeneric data={data} columna={columna}></TableGeneric>
                </Page>


            </div>
            
        );
    }
}