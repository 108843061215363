import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';

export class UserProvider {

    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()
    }

    login(username, password) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: username,
                password: password						
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101001', username);
    }

    estadoPoliza(polCodigo) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Poliza',
            parametros: {
                polCodigo: polCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    aceptarPoliza(usuCodigo) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: usuCodigo             
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101003');
    }

    validarOtpPoliza(usuCodigo, codigo) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: usuCodigo,
                otp: codigo           
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101004');
    }

    forgotPassword(user) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {                
                user: user,
                email:''
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101002');
    }

    changePassword(token,password) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {                
                token: token,
                password:password
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101005');
    }

    getUser(){
        return this.storageService.getSession()
    }

    logout() {
        this.storageService.removeSession();
    }

    isAuthenticated() {
        let user = JSON.parse(localStorage.getItem('user'))        
        return user && user.role
    }

}
