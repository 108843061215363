import {
    EDIT_USER,
    GET_ROLES,
    GET_ROLES_USER,
    GET_USER,
    SELECT_USER,
    EXIT_EDIT_USER,
    LOADING
} from 'context/types';

export default (state, accion) => {
    switch (accion.type) {
        case GET_USER:
            return {
                ...state,
                dataUser: accion.payload
            };
        case GET_ROLES:
            return {
                ...state,
                roles: accion.payload
            };
        case SELECT_USER:
            return {
                ...state,
                selectuser: state.dataUser[0]
            };
        case GET_ROLES_USER:
            let newArray = state.roles.map(e => ({
                ...e,
                checked: false,
                rouEstado: null
            }));
            for (let i = 0; i < accion.payload.length; i++) {
                for (let j = 0; j < state.roles.length; j++) {
                    if (
                        accion.payload[i].rolCodigo === state.roles[j].rolCodigo
                    ) {
                        newArray[j] = {
                            ...state.roles[j],
                            checked:
                                accion.payload[i].rouEstado === 1
                                    ? true
                                    : false,
                            rouEstado: accion.payload[i].rouEstado.toString(),
                            rouCodigo: accion.payload[i].rouCodigo
                        };
                    }
                }
            }
            return {
                ...state,
                rolesByUser: newArray
            };
        case EDIT_USER:
        case EXIT_EDIT_USER:
            return {
                ...state,
                selectuser: null,
                rolesByUser: [],
                loading: false
            };
        case LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
