/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,    
} from '@material-ui/core';
import { ActualDate } from 'services/ActualDate';
import moment from 'moment';
import { PolizaProvider  } from 'providers/poliza.service';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

const LiquidationInfo = props => {
    const { liquidation, mostrarObs, beneficiary, className, ...rest } = props;
    const classes = useStyles();
    const polizaProvider = new PolizaProvider();
    const liquidationProvider = new LiquidationProvider();
    const [poliza, setPoliza] = useState(null);
    const [liquidationTotal, setLiquidationTotal] = useState(null);
    const displayMessage = new DisplayMessage();

    useEffect(() => {
        fetchPoliza();
        getLiquidationTotal();
    }, [])

    const fetchPoliza = () => {        
        polizaProvider.getPoliza(liquidation.polCodigo).then(response => {   
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setPoliza(response.bodyOut.data[0]);                
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');  
        });   
    };

    const getLiquidationTotal = () => {
        liquidationProvider.liquidationByLiqCodigoTotal(liquidation.liqCodigo).then(response => {   
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setLiquidationTotal(response.bodyOut.data[0]);                
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');  
        });   
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader title="Información liquidación" />
            <Divider />
            <CardContent className={classes.content}>
                <Table>
                    <TableBody>
                        <TableRow selected>
                            <TableCell style={{width: '200px'}}><b>Código</b></TableCell>
                            <TableCell>#{liquidation.liqNemonico}</TableCell>
                            <TableCell style={{width: '200px'}}><b>Fecha solicitud</b></TableCell>
                            <TableCell>{liquidation.liqFechaRegistro}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>Titular</b></TableCell>
                            <TableCell>
                                {liquidation.usuNombre}
                            </TableCell>
                            <TableCell style={{width: '200px'}}><b>Plan afiliado</b></TableCell>
                            <TableCell>{liquidation.plaNombre}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>Beneficiario</b></TableCell>
                            <TableCell>
                                {beneficiary.perApellidos} {beneficiary.perNombres}
                            </TableCell>
                            <TableCell style={{width: '200px'}}><b>Días transcurridos</b></TableCell>
                            <TableCell>{moment(ActualDate()).diff(moment(liquidation.liqFechaRegistro.substr(0, 10)), 'days')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><b>Poliza Contrato</b></TableCell>
                            <TableCell>
                                {liquidation.polContrato}
                            </TableCell>
                            <TableCell style={{width: '200px'}}><b>Vigencia</b></TableCell>
                            <TableCell>
                                <b>Inicio:&nbsp;</b>{liquidation.polFechaInicio}<br />
                                <b>Fin:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>{liquidation.polFechaFin}
                            </TableCell>
                        </TableRow>
                        {poliza &&  
                            <TableRow>
                                <TableCell><b>Preexistencia</b></TableCell>
                                <TableCell>
                                    {poliza.POL_PREEXISTENCIAS === 0 ? 'SI' : 'NO' }
                                </TableCell>
                                {liquidationTotal && liquidationTotal.atencionNombre && (
                                    <>
                                        <TableCell><b>Tipo de atención</b></TableCell>
                                        <TableCell>
                                            {liquidationTotal.atencionNombre}
                                        </TableCell>                          
                                    </>
                                )}                                
                            </TableRow>
                        }
                        {mostrarObs && liquidation.liqObservacion && liquidation.liqObservacion.trim() !== '' && 
                            <TableRow>
                                <TableCell><b>Observación del Liquidador</b></TableCell>
                                <TableCell>
                                    {liquidation.liqObservacion}
                                </TableCell>                            
                            </TableRow>
                        }                        
                        {/*
                         <TableRow>
                            <TableCell>Formulario de reembolso</TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    <SearchIcon className={classes.getAppIcon} />
                                    Ver
                                </Button> 
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

LiquidationInfo.propTypes = {
    className: PropTypes.string,
    liquidation: PropTypes.any.isRequired
};

export default LiquidationInfo;
