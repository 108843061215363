import { DataService } from 'providers/dataService'

export class RequestProvider {

    constructor() {
        this.dataService = new DataService();
    }    

    requestCreate(tipo, polCodigo, perBeneficiario, detAtencion = null) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: {
                    detTipo: tipo,
                    polCodigo: polCodigo,
                    perBeneficiario: perBeneficiario,
                    detAtencion,
                }                
            }
        };        
        let endpoint = '/multipart';
        return this.dataService.postMultipart(endpoint, body, '0101111', []);
    }

    requestAddFile(liqCodigo, files) {
       
        const filesNames = [];

        files.map(file => {
            filesNames.push({docNombre: file.name});
            return 0;
        });        

        let body = {        
            tipoConsulta: 7,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: {
                    liqCodigo,
                    documentos: filesNames
                }                
            }
        };
        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', files);
    }

    requestUpdate(files, tipo, liqCodigo,polCodigo, estados,tipoConsulta) {
       
        const filesNames = [];
        
        files.map(file => {
            filesNames.push({docNombre: file.name});
            return 0;
        });        

        const liquidation = files.length>0? {
            liqCodigo: liqCodigo,
            detTipo: tipo,
            polCodigo: polCodigo,
            estados: estados,
            documentos: filesNames
        } : {
            liqCodigo: liqCodigo,
            detTipo: tipo,
            polCodigo: polCodigo,
            estados: estados,
        }; 
        let body = {        
            tipoConsulta: tipoConsulta,
            entidad: 'Liquidacion',
             
            parametros: {  
                Liquidacion: liquidation             
            }
        };   
        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', files);
    
    } 
    
    buscarTItular(beneficiarioIdentificacion, beneficiarioApellido, beneficiarioNombre) {
        let body = {
            maximoRegistros:5,
            tipoConsulta: 1,
            entidad: 'Liquidacion',
            parametros: {
                beneficiarioIdentificacion: beneficiarioIdentificacion+'%',
                beneficiarioApellido:beneficiarioApellido+'%',
                beneficiarioNombre:beneficiarioNombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

}
