import { DataService } from 'providers/dataService'

export class DataUpdateProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosAfiliado(nombre2) {
        let body = {       
            tipoConsulta: 1,
            entidad: "VwPolPerBan",
            parametros: {
                nombre2: nombre2
                
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    datosBeneficiarios( polCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: "VwPolPerBan",
            parametros: {
                polCodigo:polCodigo
                
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    datosEmpresa() {
        let body = {
            tipoConsulta: 1,
            entidad: "Empresa",
            parametros: {
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    datosPlan() {
        let body = {
            tipoConsulta: 1,
            entidad: "DetalleCatalogo",
            parametros: {
                catCodigo: 19
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    datosPlanCodigo(nombre) {
        let body = {
            tipoConsulta: 1,
            entidad: "DetalleCatalogo",
            parametros: {
                catCodigo: 19,
                detNombre:nombre
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    ingresoFormularioActualizar( objeto ) {
        let body = {       
            tipoAccion: 1,
            entidad: "Formulario",
            entidades: objeto
        };
        let endpoint = "/accion";
        return this.dataService.post(endpoint, body, "0101100");
    }

}
