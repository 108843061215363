import React, { Component } from 'react';
import { PersonList } from './components';
import { GenericHeader, Page } from 'components';
import { DisplayMessage } from 'notifications/displayMessage';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
export default class PersonView extends Component {
    constructor(props){
        super(props);
        this.displayMessage = new DisplayMessage(); 
        this.state = { hasError: false };
    }
    componentDidCatch = (error) => {
        this.setState({hasError: true});
        this.displayMessage.error(`Error: ${error}`)
    }
    render(){
        const { hasError } = this.state;
        if(hasError){
            return(
                <Alert severity="error">
                    <Typography
                        component="h6"
                    >
                        <b>Error de comunicación y/o datos</b>
                    </Typography>                      
                </Alert>   
            )
            
        }
        return(
            <Page >
                <div className='absolute-container'>
                    <GenericHeader title='Persona' />
                    <br/>
                    <PersonList />
                </div>
            </Page>  )
            
       
        
    }
}