import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        marginLeft: 2
    },
}));

const GenericHeaderForms = props => {
    const { className, ...rest } = props;

    const classes = useStyles();
    //, requirement, name
    //const requirement = "11201DCDS";

    //const name = "David Alejandro Padilla Sarmiento";

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-end"
                container
                justify="space-between"
                spacing={3}
            >
                <Grid>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h4"
                    >
                        Liquidación: {props.requirement}
                    </Typography><br></br>
                    <Typography
                        component="h1"
                        variant="h5"
                    >
                        {typeof (props.name) !== 'undefined' && `TITULAR: ${props.name}`}
                    </Typography>
                    <br></br>
                    <Typography
                        component="h1"
                        variant="h6"
                    >
                        {props.beneficiary.includes('undefined') === false && `Beneficiario: ${props.beneficiary}`}
                    </Typography>
                    <br></br>
                </Grid>
            </Grid>
        </div>
    );
};

GenericHeaderForms.propTypes = {
    className: PropTypes.string
};

export default GenericHeaderForms;
