const numeros = new RegExp('^[0-9]+$');
const letra = new RegExp('^[ñíóáéú a-zA-Z ]+$');
const withoutEspecialCharacters = new RegExp('^[0-9 ñíóáéú a-zA-Z]+$');
const numerosFlotantes = new RegExp('^[0-9]+.?[0-9]*$');

/**true false */
export const validateBoolean = (errores,name,value) => {
    const errors = {
        valid: { ...errores.valid, [name]: value }
    };
    return errors;    
}

export const validateCedula= (errores, cedula,name) => {
    if(this.state.cedulaMedico.length > 8 && this.state.cedulaMedico.length < 16){
        const cedulaIsValid =  numeros.test(cedula);
        const errors = {
            valid: { ...errores.valid, [name]: cedulaIsValid }
        };

        return errors;
    }else{
        const errors = {
            valid: { ...errores.valid, [name]: false }
        };

        return errors;
    }
}

export const validateNameLastname= (errores, value, name) => {
    const letrasIsValid = letra.test(value);
    const errors = {
        valid: { ...errores.valid, [name]: letrasIsValid }
    };

    return errors;
    /** 
    this.setState({
        errors: { ...this.state.errors, ...errors }
    });*/
}


export const validateOnlyNumbers = (value) => {
    if(value === ''){
        return true
    }else{
        return numeros.test(value);
    }
}
export const validateOnlyFloatNumbers = (value) => {
    if(value === ''){
      return true
    }else{
      return numerosFlotantes.test(value);
    }
  }
export const validateOnlyLetters = (value) => {
    if(value ===''){
        return true
    }else{
        return letra.test(value);

    }
}

export const validateWithoutEspecialCharacters = value => {
    if(value ===''){
        return true
    }else{
        return withoutEspecialCharacters.test(value);

    }
}



export const validateTelefono = (errores, value,name) => {
    if((value.length===10) || value.length===7){
        const edadIsValid = numeros.test(value);
        const errors = {
            valid: { ...errores.valid, [name]: edadIsValid }
        };

        return errors;
    }else{
        const errors = {
            valid: { ...errores.valid, [name]: false }
        };

        return errors;
    }
}
