import { DataService } from 'providers/dataService'

export class GraficProvider {

    constructor() {
        this.dataService = new DataService();
    }

    consultaPlanBasico() {
        let body = {
            tipoConsulta: 2,
            entidad: "VwPolPerBan",
            parametros: {
                plaNombre: "MASIVOS BASICOS CPN",
                detTipoPersona: 21
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    consultaPlanPremiun() {
        let body = {
            tipoConsulta: 2,
            entidad: "VwPolPerBan",
            parametros: {
                plaNombre: "MASIVOS PREMIUN CPN",
                detTipoPersona: 21
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    consultaPlanPlatinum() {
        let body = {
            tipoConsulta: 2,
            entidad: "VwPolPerBan",
            parametros: {
                plaNombre: "MASIVOS PLATINUM CPN",
                detTipoPersona: 21
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    consultaMontoCobertura() {
        let body = {
            tipoConsulta: 1,
            entidad: "VwPolPerBan",
            parametros: {
                detTipoPersona: 21
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    consultaPlan(plaNombre) {
        let body = {
            tipoConsulta: 1,
            entidad: "VwPolPerBan",
            parametros: {
                plaNombre: plaNombre,
                detTipoPersona: 21
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    consultaTipoPlan(plaNombre, value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPPB',
            parametros: {
                0: plaNombre,
                1: value,
                2: value,
                3: value,
                4: plaNombre,
                5: value,
                6: value,
                7: value,
                8: field,
                9: order,
                10: numero,
                11: inicio

            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExport(typeExport, plaNombre, value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWPPB',
            parametros: {
                0: plaNombre,
                1: value,
                2: value,
                3: value,
                4: plaNombre,
                5: value,
                6: value,
                7: value,
                8: field,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    consultaValorPrimas() {
        let body = {
            tipoConsulta: 1,
            entidad: "VwRepprimaPlan",
            parametros: {

            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }
}