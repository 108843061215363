const headerIn = {
    dispositivo: 'WeLaptop',
    canal: null,
    medio: null,
    aplicacion: 'WeMedicalProV1.0',
    tipoTransaccion: null,
    usuario: null,
    uuid: null,
    fechaHora: null,
    idioma: null,
    empresa: null,
    geolocalizacion: null
}
//let url = 'http://test.qsoftec.com:8080/werp-services/servicios';
let url = 'https://api.caridel.net/werp-services/servicios';
//let url = 'http://localhost.localdomain:8080/werp-services/servicios';
/* let url = 'http://bi.qsoftec.com:8080/werp-services/servicios'; */
const config = {
    api: url,
    headerIn: headerIn
}

export default config;
