/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { Grid, TextField, Card, CardContent, Button, CardActions, Checkbox, FormControlLabel } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { DisplayMessage } from 'notifications/displayMessage';
import CircularProgress from '@material-ui/core/CircularProgress';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme)=> ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    }
}))

const Form = (props) => {
    const [disabledButton, setDisabledButton] = React.useState(false);
    const classes = useStyles();

    const displayMessages = new DisplayMessage();
    const detalleCatalogoProvider = new DetalleCatalogoProvider();


    const initialState = {
        id: 0,
        name: '',
        origin: '',
        destination:'',
        description:'',
        state: false,
        errors: {
            name: false,
            origin: false,
            destination:false,
            description:false,
            state: false,
        },
        messages: {
            name: '',
            origin: '',
            destination:'',
            description:'',
            state: '',
        },
    }

    const [stateSelect, setStateSelect] = useState(initialState);
 

    const componentDidMount = () => {
        getCatalogDetailDetCodigo();
    }

    useEffect(
        componentDidMount
        ,[])

    const getCatalogDetailDetCodigo = () => {
        detalleCatalogoProvider.findCatalogDetailByDetCodigo(props.id).then(
            response => {
                const catalogDetail = response[0];
                const { detCodigo, detOrigen, detNombre, detDestino, detEstado, detDescripcion } = catalogDetail
                setStateSelect({ ...stateSelect, 
                    id: detCodigo,
                    name: detNombre, 
                    origin: detOrigen ? detOrigen: '', 
                    destination: detDestino? detDestino: '', 
                    state: detEstado === 1 ? true : false, 
                    description: detDescripcion ? detDescripcion:'' });

            }
        );
    }


  

    const setErrorsMessages = (nameElement, stateElement, message) => {
        
        const errors = stateSelect.errors;
        const messages = stateSelect.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;
        setStateSelect({ ...stateSelect, errors: errors, messages: messages });
    }


    const notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');

            }
        } else if(Number.isInteger(value)){
            if(value === 0){
                setErrorsMessages(nameElement, true, 'Seleccione una opcion');
            }else{
                setErrorsMessages(nameElement, false, '');
            }
        }
    }


    const showEmpties = () => {
        const {name, origin, destination, description } = stateSelect;
        notifyEmptyNulls('name',name);
        notifyEmptyNulls('origin',origin);
        notifyEmptyNulls('destination',destination);
        notifyEmptyNulls('description',description);

    }


    const handleSave = async () => {
        setDisabledButton(true);

        const {id, name, origin, destination, description, state } = stateSelect;
        if( name.trim() !== '' && id !== 0  ){

            const trama = {
                detCodigo: id,
                detNombre: name,
                detOrigen: origin,
                detDestino: destination,
                detDescripcion: description,
                detEstado: state === true ? 1 : 0,
        
            }
            detalleCatalogoProvider.updateCatalog([trama]).then(
                response => {
                    if(response.error.codigo === '0'){
                        displayMessages.success(`Catalogo ${name} actualizado con éxito`);
                        getCatalogDetailDetCodigo();
                    }
                    setDisabledButton(false);

                }

            )
      
      
        }else{
            await setDisabledButton(false);
            await showEmpties();
            await displayMessages.warn('Por favor rellene todos los campos correctamente');
        }
    
    }


    const handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'nemonic':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  
                break;
            case 'type':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase() });  
                break;
     
            default:
                setStateSelect({ ...stateSelect, [name]: value });  
                break;
        }
    }

    const handleChangeSelect = event => {
        setStateSelect({ ...stateSelect, [event.target.name]: event.target.checked });
    };

  

    return (  
        <div>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="name" name='name' value={stateSelect.name} error={stateSelect.errors.name} helperText={stateSelect.messages.name} onChange={handleInputChange} inputProps={{maxLength: 50,}} label="Nombre" variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <GreenCheckbox 
                                        checked={stateSelect.state} 
                                        onChange={handleChangeSelect} 
                                        name='state'
                                    />
                                }
                                label={stateSelect.state === true ? 'Activo' : 'Inactivo'}
                            />            </Grid>
                        <Grid item xs={6}>
                            <TextField id="origin" name='origin' value={stateSelect.origin} error={stateSelect.errors.origin} helperText={stateSelect.messages.origin}  onChange={handleInputChange} inputProps={{maxLength: 255,}} label="Orígen" variant="outlined" fullWidth />
     
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="destination" name='destination' value={stateSelect.destination} error={stateSelect.errors.destination} helperText={stateSelect.messages.destination}  onChange={handleInputChange} inputProps={{maxLength: 255,}} label="Destino" variant="outlined" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="description" name='description' value={stateSelect.description} error={stateSelect.errors.description} helperText={stateSelect.messages.description} onChange={handleInputChange} inputProps={{maxLength: 511,}} label="Descripción" variant="outlined" fullWidth  />
                        </Grid>
          
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                    >
                        <Button
                            color="primary"
                            onClick={handleSave}
                            variant="contained"
                            disabled={disabledButton}
                            startIcon={<DoneIcon/>}
                        >Guardar
                        </Button>
                        {
                            disabledButton === true &&
          <CircularProgress size={24} className={classes.buttonProgress} /> 

                        }
                    </Grid>
                </CardActions>
            </Card>
   
        </div>
    );
}
 
 
export default Form;