import { userConstants } from '_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};
const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.SESSION_LOGIN: {
      return {
        loggingIn: true,
        user: action.user
      };
    }
    case userConstants.SESSION_CHANGE: {
      return {
        loggingIn: true,
        user: action.user
      };
    }
    case userConstants.SESSION_LOGOUT: {
      return {};
    }
    case userConstants.SESSION_FAILURE: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
