import { DataService } from 'providers/dataService'

export class AtentionMedicalProviders {

    constructor() {
        this.dataService = new DataService();
    }

    tipoAtencion( ) {
        let body = {       
            tipoConsulta: 1,
            entidad: "DetalleCatalogo",
            parametros: {
                catCodigo:24,					
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }


}