import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function CustomizedInputBase(props) {
    const classes = useStyles();
    const { searchBar, complementaryMessage, handleInputChange, handleSearch, cleanSearchBar } = props;
    const onPressEnter = (event) => {
        if(event.key === 'Enter' ){
            handleSearch();
        }
    }
    return (
        <Paper  className={classes.root}>
            
            <InputBase
                className={classes.input}
                name={'searchBar'}
                placeholder={`${complementaryMessage}`}
                value={searchBar}
                onChange={handleInputChange}
                onKeyPress={onPressEnter}
                
            />
            <IconButton onClick={cleanSearchBar.bind()} className={classes.iconButton} aria-label="search">
                <ClearIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton  className={classes.iconButton} onClick={handleSearch.bind()} aria-label="directions">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}