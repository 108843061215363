/*eslint-disable no-eval */ 

import React, { Component } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


export default class PersonAutocomplete extends Component {

    onChangeElement = (event, values) => {
        const { setPerson } = this.props;

        setPerson(values);
    }

    render(){
        const { selectedElement,  data,fieldName, label} = this.props;
        return(
            
            <Autocomplete
            
                id="combo-box-demo"
                options={ data }
                getOptionLabel={option => `${eval(fieldName)}${eval(this.props.fieldName2)? ` - ${eval(this.props.fieldName2)}`:''}`}
                value={selectedElement}
                onChange={this.onChangeElement}
            
                renderInput={params => (
                    this.props.variant? <TextField 
                        error={this.props.autoError}
                        helperText={this.props.error ? 'Seleccione una opción': ''}
                        {...params} 
                        label={label} 
                        variant={this.props.variant}
                        fullWidth
                    />:
                        <TextField 
                            {...params} 
                            label={label} 
                            fullWidth
                        />
                )}
            /> 
        );
    }
}
