import { DataService } from 'providers/dataService'


export class TelefonoProvider {

    constructor() {
        this.dataService = new DataService();


    }

    findPhonesByPersonId = ( perCodigo) => {
        return this.genericFindPhone({
            'perCodigo': perCodigo,
            'telEstado': 1
        })
    }

    genericFindPhone = (parameters)=>{
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'Telefono',
            'parametros': parameters
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
                
        
        } 
            
        
        );


        return response;
       
    }

    updatePhones = ( entities) => {
        
        const body = {
            'tipoAccion': 2,
            'entidad': 'Telefono',
            'entidades': entities
        }
        
        const resp = this.dataService.post('/accion',body,'0101100').then(
            
            response => {
                return response;
            }
        )
    
        return resp;
    
    }
    createPhones = ( entities) => {
        
        const body = {
            'tipoAccion': 1,
            'entidad': 'Telefono',
            'entidades': entities
        }
        
        const resp = this.dataService.post('/accion',body,'0101100').then(
            
            response => {
                return response;
            }
        )
    
        return resp;
    
    }
}


