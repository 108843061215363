import React from 'react';
import { Page, GenericHeaderFoms } from 'components';
import { Results } from './components';
import './styles.css';
import { PersonalProvider } from 'providers/persona.service';
import { CircularProgress } from '@material-ui/core';



class RefundForm extends React.Component {
    constructor(props){
        super(props);
        var decryptedData = typeof(props.match.params.id) !=='undefined' ? atob(props.match.params.id) : 0;

        this.state={
            idRequirement: decryptedData,
            data: [],
            datoSeleccionado: '',
            userRequirement: null,
            liqPerPol: null

        }
        
        this.pP= new PersonalProvider();

    
    }
    
  

  componentDidMount = () => {
      this.getUserRequirement();
      this.getLiqperpol(this.state.idRequirement);
  }
  
    getLiqperpol = (liqCodigo) => {
        this.pP.LiqperpolByliqCodigo(liqCodigo).then((res)=>{
            this.setState({
                liqPerPol: res[0]
            });     
        });
    }

  getUserRequirement=()=>{
      if(this.state.idRequirement>0){
          this.pP.LiquidationByliqCodigo(this.state.idRequirement).then(
              (response) => {
                  try {
                      this.setState({
                          userRequirement: response[0]
                      });
                  }catch(error){
                      console.error(error)

                  }
              }
  
          );

      }

  }
  render(){
      return (
          <Page
              className="root"
              title="Formulario"
          >

              {
                  this.state.userRequirement && this.state.liqPerPol ? 
                      <GenericHeaderFoms
                          beneficiary = {`${this.state.userRequirement.beneficiarioNombre} ${this.state.userRequirement.beneficiarioApellido}`}
                          name={this.state.liqPerPol.usuNombre}
                          requirement={`#${this.state.userRequirement.liqNemonico}`}
                      />:''
              } 

              {this.state.userRequirement ?
                  <Results
                      className="results"
                      id={this.state.idRequirement}
                      polCodigo={this.state.userRequirement.polCodigo}
                      seleccionado = {this.state.datoSeleccionado}
                  />:<CircularProgress/>}
          </Page>
      );
  }

}

export default RefundForm;