import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import { Cancel, Search, Clear } from '@material-ui/icons';
import {
    CircularProgress,
    Card,
    CardHeader,
    CardActions,
    Grid,
    TextField,
    Button,
    IconButton,
    Dialog,
    CardContent,
    Divider,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table
} from '@material-ui/core';
import { AppointmentProvider } from 'providers/appointment.service';
import { SearchBasic } from 'components';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    }
}));

export default function ModalStatus(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();

    const handleClose = () => {
        props.close();
    };

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog fullWidth maxWidth="md" open={props.open} scroll={'body'}>
            <Card>
                <CardHeader
                    action={
                        <IconButton color="secondary" onClick={handleClose}>
                            <Cancel />
                        </IconButton>
                    }
                    title="Estados agendamiento"
                />
                <Divider />
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table
                            aria-label="a dense table"
                            //className={classes.table}
                            size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">
                                        Fecha inicio
                                    </TableCell>
                                    <TableCell align="center">
                                        Fecha fin
                                    </TableCell>
                                    <TableCell align="center">Estado</TableCell>
                                    <TableCell align="center">
                                        Usuario
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.estados.map((row, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {row.estadoNombre}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.esaFechaInicio}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.esaFechaFin}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.esaEstado === 1
                                                ? 'En proceso'
                                                : 'Procesado'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.usuNombre}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Dialog>
    );
}
