
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';


export default class DialogComponent extends Component {
   
    render(){
        return(
        <Dialog  
            onClose={this.props.handleClose} 
            aria-labelledby="customized-dialog-title" 
            open={this.props.open}
            maxWidth='lg'
            fullWidth
            
            >
            <DialogTitle id="customized-dialog-title" onClose={this.props.handleClose}>
                {this.props.file ?
                 this.props.file.detNombre: 
                 `CARGANDO`
                 }
            </DialogTitle>
            <DialogContent dividers>
                {
                    console.log("que me esta llegando"+ typeof(this.props.file))
                }
                {
                    console.log(this.props.file)
                }
                

            {
              this.props.file ?  
              <iframe 
              title={this.props.file ?
                this.props.file.detNombre: 
                `CARGANDO`
                }
              type="application/vnd.openxmlformats-officedocument.wordprocessingml.document" src={"data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,"+this.props.file.archivo} width="100%" height="500px" />
                :
              <LinearProgress />
  
            }
            
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={this.props.handleClose} color="primary">
               Cerrar
            </Button>
            </DialogActions>
      </Dialog>
        );
    }
}