import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';


export class CompanyProvider {

    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()


    }
    
    
    findAllCompanies = () => {
        return this.genericConsult('Empresa', {}, '0101000', 1);
    }
    findCompanyByRUC = (empIdentificacion) => {
        return this.genericConsult('Empresa',{empIdentificacion: empIdentificacion},'0101000',1);
    }   

  
    genericConsultMaxReg = (entitie, parameters, typeTransaction, typeConsult, maximoRegistros) => {
        let body = { 
            maximoRegistros: maximoRegistros,
            tipoConsulta: typeConsult,
            entidad: entitie,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, typeTransaction).then(
            response => {
                return response.bodyOut.data;
            }
        );
    }

    genericConsult = (entitie, parameters, typeTransaction, typeConsult) => {
        let body = { 
                
            tipoConsulta: typeConsult,
            entidad: entitie,
            parametros: parameters
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, typeTransaction).then(
            response => {
                return response.bodyOut.data;
            }
        );
    }

    

}
