import React, { useState, useEffect } from 'react';
import { Button, Grid, Card, CardContent } from '@material-ui/core';
//import './common.css';
import { PaymentProvider } from 'providers/payment.service';

import MaterialTable from 'material-table';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PayDialog from '../PayDialog/PayDialog';
import PayDialog2 from '../PayDialog/PayDialog2';
import { DisplayMessage } from 'notifications/displayMessage';
import { PolizaProvider } from 'providers/poliza.service';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange } from '@material-ui/core/colors';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { QueriesProvider } from 'providers/queries.service';
import { SearchBasic } from 'components';
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: orange[400],
    },
    redChip: {
        backgroundColor: red[400],

    }
}));


const PayList = ({ session }) => {
    const classes = useStyles();

    const displayMessage = new DisplayMessage;
    const paymentProvider = new PaymentProvider();
    const polizaProvider = new PolizaProvider();
    const queriesProvider = new QueriesProvider();
    const [payments,setPayments] = useState([]);
    const [paymentsCart,setPaymentsCart] = useState([]);
    const [paymentsCart2,setPaymentsCart2] = useState([]);
    const [totalPay,setTotalPay] = useState(0);
    const [totalPay2,setTotalPay2] = useState(0);
    const [openPayDialog,setOpenPayDialog] = useState(false);
    const [openPayDialog2,setOpenPayDialog2] = useState(false);
    const [polizaUser,setPolizaUser] = useState(null);
    const [searchBar, setSearchBar] = useState('');
    //consulta
    const [codigo,setCodigo]=useState('');//pendiente pago
    const [codigo2,setCodigo2]=useState('');//Pago
    useEffect(() => {
        paymentProvider.findDetNemonico('PAG1').then(
            data=>{
                
                if(data.error.codigo==='0'){
                    setCodigo(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
        
       
    }, []);

    useEffect(() => {
        paymentProvider.findDetNemonico('PAG').then(
            data=>{
                
                if(data.error.codigo==='0'){
                    setCodigo2(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
        
       
    }, []);
    
    let recorrer = 0;
    const addToCart = async rowData => {
        const paymentsCartAux = await paymentsCart;
        await paymentsCartAux.push(rowData);
        let totalAux = await totalPay;
        totalAux = await(totalAux + rowData.pagMonto);
        await setTotalPay(totalAux);
        await setPaymentsCart(paymentsCartAux);
    }
    const addToCart2 = async rowData => {
        const paymentsCartAux2 = await paymentsCart2;
        await paymentsCartAux2.push(rowData);
        let totalAux2 = await totalPay2;
        totalAux2 = await(totalAux2 + rowData.pagMonto);
        await setTotalPay2(totalAux2);
        await setPaymentsCart2(paymentsCartAux2);
    }
    const deleteToCart = async rowData => {
        const paymentsCartAux = await paymentsCart;
        let totalAux = await totalPay;

        const PaymentFinded = await paymentsCartAux.find(payment => payment.pagCodigo === rowData.pagCodigo);
        let i =  await paymentsCartAux.indexOf(PaymentFinded);

        if(i !== -1){
            await paymentsCartAux.splice(i,1);
            totalAux = await(totalAux - rowData.pagMonto);
            await setTotalPay(totalAux);
            await setPaymentsCart(paymentsCartAux);
        }
    }
    const deleteToCart2 = async rowData => {
        const paymentsCartAux2 = await paymentsCart2;
        let totalAux2 = await totalPay2;

        const PaymentFinded2 = await paymentsCartAux2.find(payment => payment.pagCodigo === rowData.pagCodigo);
        let i =  await paymentsCartAux2.indexOf(PaymentFinded2);

        if(i !== -1){
            await paymentsCartAux2.splice(i,1);
            totalAux2 = await(totalAux2 - rowData.pagMonto);
            await setTotalPay2(totalAux2);
            await setPaymentsCart2(paymentsCartAux2);
        }
    }

    const handleChange = async(event, rowData) => {
        const isChecked = event.target.checked;
        if(isChecked){
            await addToCart(rowData);
        }else{
            await deleteToCart(rowData);
        }
    }
    const handleChange2 = async(event, rowData) => {
        const isChecked = event.target.checked;
        if(isChecked){
            await addToCart2(rowData);
        }else{
            await deleteToCart2(rowData);
        }
        
    }

    /** ----------------------------------- Limpiar el buscador */
    const cleanSearchBar = () => {
        setSearchBar('');
    }
    const getAllPayments = () => {
        paymentProvider.findAllPaymentsByPolCodigoOrder(session.user.polCodigo).then(
            response => {
                setPayments(response);
            }
        )
    }

    const getPolizaByPolCodigo = () => {
        polizaProvider.findByPolCodigo(session.user.polCodigo).then(
            response => {
                setPolizaUser(response[0]);
            }
        )
    }

    const columns = [
        { title: 'Monto', field: 'pagMonto' },
        { title: 'Fecha de vencimiento', field: 'pagFechaVencimiento' },
        { title: 'Fecha de pago', field: 'pagFechaPago' },
        { title: 'Estado', field: 'detNombre' },
        { title: 'Observación', field: 'pagObservacion' },
        /* { title: 'Plan', field: 'nombrePlan' },
        { title: 'Vigencia', field: 'vigencia' },
        { title: 'Periocidad', field: 'periocidad' }, */
        {
            field: 'detNemonico',
            title: 'Seleccionar',
            render: rowData => rowData.detNemonico === 'PAG1' ?
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checkedIcon={<AddBoxIcon />}
                                    checked={paymentsCart.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ? false : true}
                                    color="primary"
                                    name={`pago_${rowData.pagCodigo}`}
                                    onChange={(event) => handleChange(event, rowData)}
                                />
                            }
                            label={paymentsCart.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ?
                                <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Seleccionar</div> : <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Seleccionado</div>}
                        />
                        : ''
        },
        
        {
            field: 'detNombre',
            title: 'Reversa',
            render: rowData => rowData.detNemonico === 'PAG'  ?
            <FormControlLabel
                            control={ 
                                <Checkbox
                                    checkedIcon={<AddBoxIcon />}
                                    //value="NO PAGADO"
                                    checked={paymentsCart2.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ? false : true}
                                    color="primary" 
                                    name={`detallecatalogo_${rowData.pagCodigo}`}
                                    onChange={(event) => handleChange2(event, rowData)}
                                    /> 
                            }
                            label={paymentsCart2.find(payment => payment.pagCodigo === rowData.pagCodigo) === undefined ?
                                <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Seleccionar</div> : <div className={new Date() > new Date(rowData.pagFechaVencimiento) && 'red-text'}>Reversar</div>}
                        
                        />
                        : ''
        },

    ];

    const componentDidMount = () => {
        /* Sin numero de cedula x default */
         /* setSearchBar(session.user.perIdentificacion); */
        getPolizaByPolCodigo();
        handleSearch();
    }

    useEffect(componentDidMount, []);
    /** ------------------------------------Para dialogo de pagos ---------- */
    const handleClose = () => {
        setOpenPayDialog(false);
    }
    const handleClose2 = () => {
        setOpenPayDialog2(false);
    }

    const handleOpen = () => {
        if(paymentsCart.length > 0){
            setOpenPayDialog(true);
        }else{
            displayMessage.warn('Nada que registrar')
        }
    }
    const handleOpen2 = () => {
        if(paymentsCart2.length > 0){
            setOpenPayDialog2(true);
        }else{
            displayMessage.warn('Nada que registrar')
        }
    }

    const reloadFields = () => {
        setPaymentsCart([]);
        setTotalPay(0);
    }
    const reloadFields2 = () => {
        setPaymentsCart2([]);
        setTotalPay2(0);
    }

    const handleInputChange = event => {
        const {value}= event.target;
        setSearchBar(value);
    }

    const handleSearch = () => {
        const search = searchBar === '' ? session.user.perIdentificacion: searchBar;
        queriesProvider.searchPaymentsByIdentification(search).then(
            response => {
                if(response.error.codigo === '0'){
                    setPayments(response.bodyOut.data);
                }
            }
        )
    }

    return (
        <div>
            <PayDialog session={session} open={openPayDialog} handleClose={handleClose} totalPay={totalPay} det_nemonico={codigo2}
            paymentsCart={paymentsCart} reloadFields={reloadFields} getAllPayments={getAllPayments} handleSearch={handleSearch} />
            
            <PayDialog2 session={session} open={openPayDialog2} handleClose2={handleClose2} totalPay={totalPay2} det_nemonico={codigo}
            paymentsCart={paymentsCart2} reloadFields={reloadFields2} getAllPayments={getAllPayments} handleSearch={handleSearch} />
            
            <SearchBasic
                searchBar={searchBar}
                cleanSearchBar={cleanSearchBar}
                handleInputChange={handleInputChange}
                complementaryMessage='Cédula'
                handleSearch={handleSearch}
            />
            <br/>
            {
                payments.length > 0 && <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Nombre:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perNombres}
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Apellido:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perApellidos}
                            </Grid>
                        </Grid>
                        <br/><br/>
                        <Grid container spacing={2}>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Identificación:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perIdentificacion}
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Póliza:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].polContrato}
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            }
            <br/>
            <MaterialTable

                columns={columns}
                /**
                 * actions={[
                    {
                        icon: 'create',
                        tooltip: 'Actualizar',
                        onClick: (event, rowData) => this.setOpenPersonUpdateModal(true, rowData)
                    }
                ]}
                components={{
                    Action: props => (
                        props.data.detNemonico === 'PAG1' &&
                        <FormControlLabel
                            control={
                            }
                            label=""
                        />

                    ),
                }}
                */

                data={payments}

                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'

                    },
                    toolbar: {
                        nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acción'
                    },
                    body: {

                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter',

                        },

                    }
                }}

                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                        width: 100,
                        textAlign: 'center'
                    },
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    toolbar: false,
                    pageSize: 12,
                    showTitle: false,
                    rowStyle: () => {
                        recorrer = recorrer + 1;
                        return ({
                            backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                        })
                    },
                }}


                title={'Persona'}
            />
            <br/>
            <br/>
            <Grid container
                justify="flex-end"
                spacing={2}>
                <Button color="secondary" startIcon={<BorderColorIcon/>} variant="contained" onClick={handleOpen}>
                Registrar pagos
                </Button>
                <p>&nbsp;&nbsp;&nbsp;</p>
                <Button color="secondary" startIcon={<SettingsBackupRestoreIcon/>} variant="contained" onClick={handleOpen2}>
                Registrar Reversas
                </Button> 
            </Grid>
        </div>
    );
};


export default PayList;