import React, { useReducer } from 'react';
import UserReducer from './UserReducer';
import { DisplayMessage } from 'notifications/displayMessage';
import { NewUserProvider } from '../../providers/newUser.service';
import UserContext from './UserContext';
import {
    EDIT_USER,
    EXIT_EDIT_USER,
    GET_ROLES,
    GET_ROLES_USER,
    GET_USER,
    LOADING,
    SELECT_USER
} from 'context/types';
const UserState = props => {
    const displayMessage = new DisplayMessage();
    const userService = new NewUserProvider();
    const initialState = {
        dataUser: [],
        roles: [],
        selectuser: null,
        rolesByUser: [],
        loading: false
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const saveUser = async data => {
        console.log('');
        const res = await userService.saveUser(data);
        let saveroles = [];
        if (res.error.codigo === '0') {
            const idUser = res.bodyOut.resultados[0].usuCodigo;
            data.rol.forEach(element => {
                saveroles.push({
                    rolCodigo: element.rolCodigo,
                    usuCodigo: idUser,
                    rouDescripcion: `Usuario ${element.rolNombre}`,
                    rouEstado: 1
                });
            });
            await getUser();
            const resRoles = await userService.saveRolesUser(saveroles);
            if (resRoles.error.codigo === '0') {
                displayMessage.success('Usuario creado con éxito');
            }
        }
    };

    const getUser = async () => {
        /* const res = await userService.consultaUsuario();
        if (res.error.codigo === '0') {
            console.log(res.bodyOut);
            if (res.bodyOut.data[0]) {
                dispatch({
                    type: GET_USER,
                    payload: res.bodyOut.data
                });
            }
        } */
    };

    const getRoles = async () => {
        const res = await userService.consultaRoles();
        if (res.error.codigo === '0') {
            if (res.bodyOut.data[0]) {
                dispatch({
                    type: GET_ROLES,
                    payload: res.bodyOut.data
                });
            }
        }
    };

    const selectUser = async data => {
        const res = await userService.consultaUsuario(data);
        if (res.error.codigo === '0') {
            console.log(res.bodyOut);
            if (res.bodyOut.data[0]) {
                dispatch({
                    type: GET_USER,
                    payload: res.bodyOut.data
                });
            }
        }
        dispatch({
            type: SELECT_USER,
            payload: data
        });
        getRolesUser(data);
    };
    const getRolesUser = async data => {
        const res = await userService.getRolesUser(data);
        if (res.error.codigo === '0') {
            dispatch({
                type: GET_ROLES_USER,
                payload: res.bodyOut.data
            });
        }
    };

    const upadateUser = async data => {
        dispatch({
            type: LOADING
        });
        const newTrama = {
            usuCodigo: data.usuCodigo,
            usuNombre: data.usuNombre,
            usuUsuario: data.usuUsuario,
            usuDescripcion: data.usuDescripcion,
            usuEmail: data.usuEmail,
            usuEstado: data.usuEstado
        };
        const res = await userService.editUser(newTrama);
        if (res.error.codigo === '0') {
            const editRol = data.rol.filter(
                e => e.rouEstado === 1 || e.rouEstado === 0
            );
            const newTramaEditRol = editRol.map(e => ({
                usuCodigo: data.usuCodigo,
                rolCodigo: e.rolCodigo,
                rouDescripcion: 'Usuario Persona',
                rouEstado: e.rouEstado,
                rouCodigo: e.rouCodigo
            }));
            //console.log(newTramaEditRol);
            const resEditRoles = await userService.editRoles(newTramaEditRol);
            if (resEditRoles.error.codigo === '0') {
                let saveroles = [];
                data.createRol.forEach(element => {
                    saveroles.push({
                        rolCodigo: element.rolCodigo,
                        usuCodigo: data.usuCodigo,
                        rouDescripcion: 'Usuario Persona',
                        rouEstado: 1
                    });
                });
                const resRoles = await userService.saveRolesUser(saveroles);
                if (resRoles.error.codigo === '0') {
                    displayMessage.success('Usuario actualizado correctamente');
                    dispatch({
                        type: EDIT_USER
                    });
                }
            }
        }
    };

    const exitEdit = () => {
        dispatch({
            type: EXIT_EDIT_USER
        });
    };
    return (
        <UserContext.Provider
            value={{
                dataUser: state.dataUser,
                roles: state.roles,
                selectuser: state.selectuser,
                rolesByUser: state.rolesByUser,
                loading: state.loading,
                getUser,
                saveUser,
                getRoles,
                selectUser,
                upadateUser,
                exitEdit
            }}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserState;
