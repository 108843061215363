import React, { useState, useEffect } from 'react';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import { QueriesProvider } from 'providers/queries.service';
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';

import { SearchBasic } from 'components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

const Results = () =>{
  
    const detalleCatalogoProvider = new DetalleCatalogoProvider();
    const queriesProvider = new QueriesProvider();
    /** Metodos para actualizar */
    const initialState = {
        columns: [
            { title: 'Nombre', field: 'detNombre' },
            { title: 'Descripción', field: 'detDescripcion' },
            { title: 'Tipo', field: 'detTipo' },
      
      
        ],
        searchBar:'',
        data:[],
        allCatalogDetails: []
    }
    const [stateSelect, setStateSelect] = useState(initialState);
    const componentDidMount = () => {
        getAllCatalogDetails();
    }
    useEffect(componentDidMount, []);

  
   
    /** -----------------------------------Traer todas las personas */
    const getAllCatalogDetails = () => {
        detalleCatalogoProvider.findAllDetailCatalogLimit10().then(
            response => {
                setStateSelect({ ...stateSelect, allCatalogDetails: response });
            }
        )
    }


    /** ==================================================================== UPDATE =========================*/
    /**-----------------------------------------------handleInputChange (Tecleo en Textfield) */
    const handleInputChange = (event, ) => {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'searchBar':
                setStateSelect({ ...stateSelect, [name]: value });  
                break;
    
            default:
                setStateSelect({ ...stateSelect, [name]: value });  
                break;
        }
    }

    const handleSearch = () => {

        searchAlgorithm(stateSelect.searchBar);
    }

    const searchAlgorithm = (value) => {
        queriesProvider.searchOrCatalogDetail(value).then(
            response => {
                setStateSelect({ ...stateSelect, allCatalogDetails: response.bodyOut.data });  
            }
        )
    }


    /** --------------------------------Limpiar Campos */


    const cleanSearchBar = () => {
        setStateSelect({ ...stateSelect, searchBar: '' });  
        getAllCatalogDetails();
    }

    return(
        <div>
            
            <SearchBasic 
                searchBar={stateSelect.searchBar} 
                cleanSearchBar = {cleanSearchBar}
                handleInputChange={handleInputChange}
                complementaryMessage = 'Buscar'
                handleSearch = {handleSearch}
            />
            <Grid
                alignItems="center"
                container
                direction="row"
                justify="flex-end"
            >
                <Button
                    color="primary"
                    href="/administrador/detalle-catalogo/agregar"
                    variant="contained"
                    size='small'
                    startIcon={<AddCircleOutlineIcon  />}
                >Nuevo catálogo
                </Button>
            </Grid>
            <br/>
            <MaterialTable
                title={'Catalogos'}
                columns={stateSelect.columns}
                data={stateSelect.allCatalogDetails}
                localization = {{
                    pagination: {
                        labelRowsSelect: 'filas',
                        labelDisplayedRows: '{from}-{to} de {count}'
                    },
                    toolbar: {
                        nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acción'
                    },
                    body: {
                        
                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter',
                            
                        },
                    
                    }
          
                }}
              
                options={{
                    search: false,
                    actionsColumnIndex: -1
                }}
                actions={[
                    {
                        icon: 'save',
                        tooltip: 'Save User',
                        onClick: () => {}
                    }
                ]}
                components={{
                    Action: (props) => (
                        <Button
                            color="primary"                                            
                            size="small"
                            style={{textTransform: 'none'}}
                            variant="contained"
                            href={`/administrador/detalle-catalogo/modificar/${btoa(props.data.detCodigo)}`}              
                            startIcon={<VisibilityIcon />}
                        >
                  Ver
                        </Button>
                    ),
                }}
                
            />
        </div>
    )

}

export default Results;