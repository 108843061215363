import React, { Component } from 'react';
import { Page } from 'components';
import { forms } from './forms';
//import MessagesForm from '../MessagesDetails/components/MessagesForm';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField} from '@material-ui/core';
import { DetalleCatalogoProvider } from '../../providers/detalleCatalogo.service';
import './styles.css';
import { PersonalProvider } from '../../providers/persona.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
//import BenefitsView from './BenefitsView';
import { withStyles } from '@material-ui/styles';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { Header, LiquidationInfo, LiquidationDocuments } from 'views/AuditDetails/components';
import ModallSearchCie10  from 'views/RefundForm/components/Results/ModlalSeartchCie10/ModallSearchCie10';
import { DisplayMessage } from 'notifications/displayMessage';
import {Typography, Card, CardContent, CardHeader, Divider} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//import { green } from '@material-ui/core/colors';

import { 
    colors, 

} from '@material-ui/core';

const styles = theme => ({        
    deleteButton: {
        color: theme.palette.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    deleteIcon: {
        marginRight: theme.spacing(1)
    },
    root: {
        padding: theme.spacing(3)
    },

});

class DigitizeView extends Component {
    constructor(props) {
        super(props);
        var decryptedData = atob(props.match.params.id);
        
        const  { session } = props;
        
        this.state = {
            forms: null,
            idRequirement: decryptedData,
            showRequirement: false,
            formSelected: null,
            messageList: [],
            data:[],
            allForms: null,
            searchWord: '',
            userRequirement: null,
            documents: [],
            session: session,
            liqPerPol:null,
            beneficiary: null,
            datosComboPrestaciones:0,
            prestaciones:'',
            mostrarForm:false,
            count:0,
            arrayDatos: [],
            detTipoLiq:'',
            open: false,
            loading: false,
            motivo: '',          
            loadingDoc: true,
            existenciaReembolso: 0,
            dataFormulario: null,
            detCie10: null,
            openCie10:false,
            buscarCie10: '',
            errorCie10: false,
            detalleLiquidacion:[]
        }

        this.dCP = new DetalleCatalogoProvider();
        this.pP = new PersonalProvider();
        this.liquidationProvider = new LiquidationProvider();  
        this.prestaciones = new DocumentDigitalizeProvider();
        this.displayMessage = new DisplayMessage(); 
    }

    componentDidMount = () => {
        this.getLiqperpol(this.state.idRequirement);
        //this.getDocuments(this.state.idRequirement);  
        this.getDetalleLiquidacion()      
    }


    ////////VER DETALLELIQUIDACION
    getDetalleLiquidacion = () =>{
        this.liquidationProvider.datosDetalleLiquidacion(this.state.idRequirement)
            .then(
                data => {
                    this.setState({detalleLiquidacion:data.bodyOut.data});
                },
                () => {          
                //displayMessage.error('Error de comunicación.');

                }

            );
    }


    // getDocuments = (liqCode) => {
    //     this.liquidationProvider.liquidacionDocuments(liqCode).then(response => {
                        
    //         if(response.error.codigo === '0' && response.bodyOut.data.length){
    //             this.setState({
    //                 documents: response.bodyOut.data,
    //                 loadingDoc: false
    //             });            
                
    //         } else {
    //             this.setState({
    //                 documents: [],
    //                 loadingDoc: false
    //             }); 
    //         }                  
    //     },
    //     () => {          
    //         this.displayMessage.error('Error de comunicación.');
    //         this.setState({
    //             documents: [],
    //             loadingDoc: false
    //         }); 
    //     }); 
    // };

    getFormulario = (liqCode) => {
        this.prestaciones.datosFormularioReembolso(liqCode).then(response => {
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                this.setState({
                    dataFormulario: response.bodyOut.data[0],
                    detCie10: response.bodyOut.data[0].detCie10,
                    existenciaReembolso: response.bodyOut.data[0].forCodigo
                });                            
            } else {
                this.setState({
                    dataFormulario: null,
                    detCie10: null,
                    existenciaReembolso: 0
                }); 
            }               
        });
    }

    /* 
    arrayDocuments = (docs) => {
        const arr = [];
        return new Promise((resolve, reject) => {
            
            docs.map(doc => {                
                arr.push({
                    detNombre: doc.docNombre.toUpperCase(),
                    detDestino: doc.docNombre,
                    archivo: doc.documento

                })
                return [];
            });

            setTimeout(
                () => arr.length > 0
                    ? resolve(arr)
                    : reject(new Error('No se encuentran datos')),
                2000
            );

        }).then((res) => {
            const toReturn = res;
            return toReturn;
        });        
    } */

    getLiqperpol = (liqCodigo) => {
        this.pP.LiqperpolByliqCodigo(liqCodigo).then((res)=>{
            this.setState({
                liqPerPol: res[0]
            });
            this.getRequirement();
            if(res[0].detNemonicoTipo === 'TIPLQRE'){
                this.getFormulario(liqCodigo);
            } else {
                this.setState({
                    existenciaReembolso: 1
                })
            }           
            return res[0].perBeneficiario;
        }).then((response)=>{
            this.getPersonByPerCodigo(response);
        });
    }

    getPersonByPerCodigo = (perCodigo) => {
        this.pP.personByPerCodigo(perCodigo).then((res)=>{
            this.setState({
                beneficiary: res[0]
            });
        });
    }
    
    getRequirement = () => {
        this.pP.LiquidationByliqCodigo(this.state.idRequirement).then((resp) => {
            this.setState({                
                userRequirement: resp[0],
                detTipoLiq:resp[0].tipoNemonico
            })
            return resp[0].detTipo
        })
        /* .then((res) => {this.pP.documentsByLiqCodigo(this.state.idRequirement).then(
            documents => {
                this.arrayDocuments(documents).then(async(setDocuments) => {
                    this.setState({
                        forms: await forms(res, setDocuments, parseInt(this.state.idRequirement), 17173),
                    })
                    return '';
                }).then(() => {
                    this.findAllForms(this.state.searchWord);
                })
            });
        }); */
    }

    handleSearchChange = (evt) => {
        this.setState({
            searchWord: evt.target.value
        });
        this.findAllForms(evt.target.value);
    }

    findAllForms = (v) => {        
        const ArrayAux = [];
        new Promise((resolve, reject) => {
            this.state.forms.map((element) => {                
                if (element.detNombre.includes(v.toUpperCase()) === true) {
                    ArrayAux.push(element);
                }
                return null;
            });

            setTimeout(
                () => ArrayAux.length > 0
                    ? resolve(ArrayAux)
                    : reject(new Error('No se encuentran datos')),
                100
            )
        }).then((response) => {
            this.setState({
                allForms: response
            });
        }).catch(() => {            
            this.setState({
                allForms: typeof(forms) !== 'function'? forms:[]
            });                      
        });
    }

    handleClickOpenCancel = () => {
        this.setState({ open: true });
    };

    handleCloseCancel = () => {
        this.setState({ open: false });
    };

    handleAceptCancel = () => {        
        this.setState({ loading: true });
        this.liquidationProvider.requestCancel(this.state.idRequirement, this.state.motivo).then(response => {
            if(response.error.codigo === '0'){
                this.setState({ open: false, loading: false });
                this.props.history.push('/liquidador/liquidaciones/reporte');
            } else {
                this.setState({ open: false, loading: false });
            }
        },() => {          
            this.displayMessage.error('Error de comunicación.');                   
            this.setState({ loading: false });
        });
    };

    handleOpen = () => {
        this.setState({
            showRequirement: true
        });
    }

    handleClose = () => {
        this.setState({
            showRequirement: false
        });
    }


    handleOpenCie10 = () => {
        this.setState({
            openCie10: true
        });
    }

    handleCloseCie10 = () => {
        this.setState({
            openCie10: false
        });
    }

    setFormSelected = (id) => {
        this.setState({
            formSelected: id

        });
        this.handleOpen();

    }

    _onMessageWasSent(message) {
        this.setState({
            messageList: [...this.state.messageList, message]
        })
    }


    handleChangeMotivo = (event) => {
        this.setState({ motivo: event.target.value });
    }

    datosModal = (dato1,dato2) => {
        this.setState({buscarCie10:dato2,detCie10:dato1,errorCie10:false})
    }

    render() {
       
        const { classes } = this.props;

        return (
            <Page
                className={classes.root}
                title="Digitalizar"                
            >
                <ModallSearchCie10
                    close={this.handleCloseCie10}
                    datos={this.datosModal.bind(this)}
                    open={this.state.openCie10}
                /> 
                { this.state.userRequirement ?
                    <>
                        <div className="right" >
                            <Typography
                                display="block"
                                gutterBottom
                                variant="overline"
                            >
                                <div className="red-text">                            
                                    <Button
                                        className={classes.deleteButton}
                                        onClick={this.handleClickOpenCancel}
                                        variant="contained"
                                    >
                                        <HighlightOffIcon className={classes.deleteIcon} />
                                        Cancelar liquidación
                                    </Button>
                                </div>
                            </Typography>
                        </div>
                        <Header 
                            liquidation={this.state.userRequirement}
                        /> 
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                            >                               
                                {this.state.beneficiary && this.state.liqPerPol && 
                                    <LiquidationInfo 
                                        beneficiary={this.state.beneficiary} 
                                        liquidation={this.state.liqPerPol} 
                                    />
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >                                
                                {this.state.liqPerPol ? 
                                    <LiquidationDocuments 
                                        documents={this.state.liqPerPol.documentos} 
                                    /> 
                                    : <CircularProgress />}                   
                            </Grid>
                            {/* {  this.state.liqPerPol && this.state.liqPerPol.detNemonico === 'ESTLQC'?
                                <Grid
                                    item
                                    xs={12}
                                > 
                                    <Card>
                                        
                                        <CardHeader title="Digitalizar" />
                                        <Divider />
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={6}                            
                                            >
                                                <Grid                   
                                                    container
                                                    item
                                                    md={3}                                                
                                                >
                                                    <Typography
                                                        display="block"
                                                        gutterBottom
                                                        variant="h5"
                                                    >
                                                        FORMULARIO DE REEMBOLSO
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={3}                                                
                                                >
                                                    
                                                </Grid>  
                                            </Grid>
                                        </CardContent>                                    
                                    </Card>
                                </Grid> : '' 
                            } */}
                            { /* this.state.detTipoLiq !== '' && this.state.detTipoLiq !== 'TIPLQRE'?
                                <Grid
                                    item
                                    xs={12}
                                >                                
                                    <Card>
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={6}                            
                                            >
                                                <Grid                   
                                                    container
                                                    item
                                                    md={6}
                                                    sm={12}
                                                    xs={12}                                
                                                >
                                                    <TextField
                                                        error={this.state.errorCie10}       
                                                        fullWidth  
                                                        helperText = {this.state.errorCie10 && 'Busque CIE10'}                                               
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label="CIE10"
                                                        name="buscarCie10"                            
                                                        placeholder="CIE10"
                                                        required 
                                                        value={this.state.buscarCie10}                           
                                                        variant="outlined"                           
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={3}
                                                    sm={6}       
                                                    xs={12}                         
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleOpenCie10}  
                                                        variant="contained"                                  
                                                    >
                                                        Buscar cie10
                                                    </Button>
                                                </Grid>  
                                            </Grid>
                                        </CardContent>                                    
                                    </Card>
                                </Grid> : ''  */
                            }                                                        
                        </Grid> 
                    </>
                    : <CircularProgress />
                }                
                
                {
                    /* { this.state.allForms ? 
                        <GenericList
                            elementIcon={<FaRegFolder />}
                            elements={this.state.allForms}
                            formIcon={<FaRegFolder />}
                            formTitle="Documentos"
                            handleOpen={this.handleOpen}
                            handleSearchChange={this.handleSearchChange}
                            idRequirement={this.state.idRequirement}
                            setFormSelected={this.setFormSelected.bind()}
                            titles={titles}
                            valSearch={this.state.searchWord}
                        /> : <CircularProgress /> 
                    } */
                }                              
              {/*   
                { this.state.existenciaReembolso ?
                    <BenefitsView
                        data={this.state.detalleLiquidacion}
                        detCie10={this.state.detCie10}
                        detTipoLiq={this.state.detTipoLiq}
                        liquidacion={this.state.idRequirement}
                        usuUsuario={this.state.session ? this.state.session.user.usuCodigo:''}                
                    /> : '' 
                } */}

               {/*  { this.state.userRequirement ?  
                    <MessagesForm 
                        detTipo={this.state.userRequirement.detTipo} 
                        getMessage={()=>{ window.location.href = '/liquidador/liquidaciones' }} 
                        liqCodigo={this.state.userRequirement.liqCodigo} 
                        placeholdermessage={'Escriba mensaje al cliente'} 
                        polCodigo={this.state.userRequirement.polCodigo} 
                        tipoConsulta={2}
                        usuCodigo={this.state.session.user.usuCodigo}
                        usuNombre={this.state.session.user.usuNombre}
                        usuUsuario={this.state.session.user.usuUsuario}
                    /> : ''
                }          */}   
               <Dialog 
                    aria-labelledby="form-dialog-title" 
                    disableBackdropClick 
                    disableEscapeKeyDown
                    onClose={this.handleCloseCancel}
                    open={this.state.open}
                >
                    <DialogTitle id="form-dialog-title">Cancelar liquidación</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro de cancelar la liquidación?
                        </DialogContentText>
                        <TextField
                            autoFocus
                            fullWidth
                            id="name"
                            label="Escriba el motivo"                            
                            margin="dense"
                            onChange={this.handleChangeMotivo}
                            value={this.state.motivo}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            color="primary"
                            onClick={this.handleCloseCancel} 
                        >
                            Cancelar
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.handleAceptCancel} 
                        >
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Page>
        );
    }
}

const mapStateToProps = state =>({ session: state.session});
export default connect(mapStateToProps,null)(withStyles(styles)(DigitizeView));