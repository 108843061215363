import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { WalletProvider } from 'providers/wallet.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import 'moment/locale/es';
import { CircularProgress, Grid, Tooltip, Button } from '@material-ui/core';
import moment from 'moment';
import { SearchBarCustom, SearchBasic } from 'components';
import { DatePicker } from '@material-ui/pickers';

moment.locale('es');

export default function SearchAffiliate() {
    const tableRef = React.useRef();
    const queries = new WalletProvider();
    const [booleano, setBooleano] = useState(false);
    const [total, setTotal] = useState(0);
    const displayMessage = new DisplayMessage();
    const [buscarMes, setBuscarMes] = useState('');
    const [buscarAnio, setBuscarAnio] = useState('');
    const [value, setValue] = useState('');
    //const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Pago fecha vencimiento', field: 'PAG_FECHA_VENCIMIENTO' },
        { title: 'Fecha', field: 'Fecha', filtering: false },
        { title: 'Identificación', field: 'IDENTIFICACION', filtering: false },
        { title: 'Nombres', field: 'NOMBRES', filtering: false },
        { title: 'Cédula titular', field: 'CEDULA_TITULAR', filtering: false },
        { title: 'Valor', field: 'VALOR', filtering: false },
        { title: 'Observaciones', field: 'OBSERVACIONES', filtering: false },
        {
            title: 'Fecha vencimiento',
            field: 'FECHA_VENCIMIENTO',
            filtering: false
        },
        { title: 'Estado', field: 'ESTADO', filtering: false }
    ]);

    const loadData = (query, resolve) => {
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PAG_FECHA_VENCIMIENTO'
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        let fechaReg = '';

        query.filters.map(item => {
            if (item.column.field === 'PAG_FECHA_VENCIMIENTO') {
                fechaReg = item.value;
                setBuscarMes(item.value);
            }
        });
        console.log(fechaReg);
        queries
            .consultaCartera(
                fechaReg,
                value,
                orderBy.field,
                direction.orderDirection,
                query.pageSize,
                query.pageSize * query.page
            )
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setTotal(response.bodyOut.data[0].TOTAL);
                        setBooleano(false);
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        setTotal(0);
                    }
                },
                () => {
                    setBooleano(false);
                    setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const dataReportsExport = typeResult => {
        queries
            .dataReportsExport(
                typeResult,
                buscarMes,
                value,
                'PAG_FECHA_VENCIMIENTO',
                'asc',
                total,
                0
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        downloadFile(response.bodyOut.data, typeResult);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].VW_CARTERA}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte_Access.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChange = e => {
        setValue(e.target.value);
    };

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        setValue('');
    };

    const handleBuscarTitular = () => {
        if (value === '') {
            displayMessage.error('Ingrese un dato a buscar.');
        } else {
            tableRef.current.onSearchChange(value);
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <SearchBasic
                        searchBar={value}
                        cleanSearchBar={handleLimpiar}
                        handleInputChange={handleChange}
                        complementaryMessage="Buscar"
                        handleSearch={handleBuscarTitular}
                    />
                </Grid>

                {/*  <Grid
                    //className={classes.item}
                    item
                    md={2}
                    xs={12}>
                    <DatePicker
                        format="MMMM"
                        inputVariant="outlined"
                        label="Seleccione mes"
                        onChange={handleChangeMes}
                        value={buscarMes || moment()}
                        views={['month']}
                    />
                </Grid>

                <Grid
                    //className={classes.item}
                    item
                    md={2}
                    xs={12}>
                    <DatePicker
                        autoOk
                        inputVariant="outlined"
                        label="Seleccione año"
                        onChange={handleChangeAnio}
                        value={buscarAnio || moment()}
                        variant="inline"
                        views={['year']}
                    />
                </Grid> */}
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{
                                borderRadius: 5,
                                marginRight: 10,
                                width: 100
                            }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(2);
                            }}>
                            <FaFileCsv size={20} style={{ marginRight: 10 }} />{' '}
                            CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(3);
                            }}>
                            <FaFileExcel
                                size={20}
                                style={{ marginRight: 10 }}
                            />{' '}
                            EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={
                    booleano === false
                        ? query =>
                              new Promise(resolve => {
                                  loadData(query, resolve);
                              })
                        : []
                }
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No hay datos que mostrar'
                    },
                    toolbar: {
                        search: false,
                        exportName: 'Exportar a CSV',
                        exportAriaLabel: 'Exportar',
                        exportTitle: 'Exportar'
                    },
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'
                    }
                }}
                options={{
                    filtering: true,

                    exportButton: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 50, 100],
                    emptyRowsWhenPaging: false,
                    search: false,
                    toolbar: false,
                    rowStyle: rowData => ({
                        backgroundColor:
                            rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
                    })
                }}
                title="Access"
            />
        </div>
    );
}
