import React, { Component } from 'react';
import GenericList from './../../components/GenericList';

import { FaRegFile, FaLink } from 'react-icons/fa';

import { Page } from 'components';
import { DetalleCatalogoProvider } from './../../providers/detalleCatalogo.service';
import { connect } from 'react-redux';
import { PolizaProvider } from './../../providers/poliza.service';

import CircularProgress from '@material-ui/core/CircularProgress';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import './styles.css';

import { DialogComponent } from './Dialog';

import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

import { HelpDialog } from 'components';
import { HelpContent } from './components';
import Alert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';


class DocumentsView extends Component {


    constructor(props) {

        super(props);

        const { session } = this.props;

        this.state = {
            allDocuments: [],
            searchWord: '',
            session: session,
            documento: null,
            showContract: false,
            openHelpDownload: false
        }


        this.pS = new PolizaProvider();

        this.handleSearchChange = this.handleSearchChange.bind(this);

    }

    handleCloseDonwload = () => {
        this.setState({
            openHelpDownload: false
        });
    }

    handleOpenDownload = () => {
        this.setState({
            openHelpDownload: true
        });
    }

    componentDidMount = () => {
        this.findAllDocuments(this.state.searchWord);
        if (this.state.session.user.polCodigo !== -1){
            this.getDocumentPoliza();
        }        

    }


    handleOpenDialog = () => {
        this.setState({
            showContract: true
        });
    }

    handleCloseDialog = () => {
        this.setState({
            showContract: false
        });
    }

    getDocumentPoliza = () => {

        this.pS.getDocumentPDF(this.state.session.user.polCodigo).then(
            response => {
                try {


                    const element = {
                        detCodigo: response[0].polCodigo,
                        detNombre: response[0].polObservacion,
                        detOrigen: response[0].poliza

                    }

                    this.setState({
                        documento: element
                    });
                } catch (error) {
                
                    console.error(error)
                }

                //Save.push(element);

            }
        );

    }

    handleSearchChange = (evt) => {
        this.setState({
            searchWord: evt.target.value
        });

        this.findAllDocuments(evt.target.value);
    }

    findAllDocuments = (search) => {
        const dCP = new DetalleCatalogoProvider();
        if( typeof(this.state.session.user.empCodigo) !== 'undefined'  ){
            dCP.findLikeName(3, 'DOCCLI', search, this.state.session.user.empCodigo).then(

                response => {
                    this.setState({
                        allDocuments: response
                    });
                }
            )
        }else{
            this.setState({
                allDocuments: []
            });
        }
        

    }
    render() {


        const titles = [
            {
                id: 1,
                name: 'Nombre'
            },

            {
                id: 2,
                name: <b>

                    Descargar

                    <IconButton aria-label="Ayuda" onClick={this.handleOpenDownload}  >

                        <HelpIcon />

                    </IconButton>

                </b>



            }



        ]



        return (

            <Page
                title="DOCUMENTOS"
            >
                <div className='total-container' >
                    {
                        this.state.allDocuments ?


                            <GenericList
                                elements={this.state.allDocuments}
                                titles={titles}
                                formTitle='Documentos'
                                formIcon={<FaLink />}
                                elementIcon={<FaRegFile />}
                                valSearch={this.state.searchWord}
                                handleSearchChange={this.handleSearchChange}/>

                            : <CircularProgress />

                    }
                    <div className='margin-1'>
                        <Typography>IMPORTANTE</Typography>
                        {
                            this.state.documento ?
                                <TableContainer component={Paper}>
                                    <Table >
                                        <TableHead style={{backgroundColor: 'white'}}>
                                            <TableRow >
                                                <TableCell><b>Contrato</b></TableCell>
                                                <TableCell><b>Descargar</b></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow >
                                                <TableCell >
                                                    Contrato
                                                </TableCell>
                                                <TableCell >
                                                    <Button variant="contained" color="primary"
                                                        onClick={this.handleOpenDialog}
                                                        size='small'
                                                        style={{textTransform: 'none'}}
                                                        startIcon={<VisibilityIcon/>}
                                                    >VER</Button>
                                                    &nbsp;
                                                </TableCell>
                                            </TableRow>




                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                this.state.session.user.polCodigo !== -1 && <CircularProgress />
                        }
                        { this.state.session.user.polCodigo === -1 && 
                            <Alert severity="warning">
                                <Typography
                                    component="h1"
                                    variant="subtitle1"
                                >
                                    <b>No tienes póliza activa.</b>
                                </Typography>                      
                            </Alert>

                        }
                    </div>
                </div>

                {this.state.documento ? <DialogComponent
                    handleClose={this.handleCloseDialog}
                    open={this.state.showContract}
                    detOrigen={this.state.documento.detOrigen}
                ></DialogComponent> : ''}

                <HelpDialog
                    text={<HelpContent></HelpContent>}
                    handleClose={this.handleCloseDonwload}
                    open={this.state.openHelpDownload}
                >

                </HelpDialog >


            </Page>

        );
    }


}

const mapStateToProps = state => ({ session: state.session });
export default connect(mapStateToProps, null)(DocumentsView);