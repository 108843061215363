import React from 'react';
import { 
    Grid,
    TextField,
    Card, 
    CardHeader, 
    CardContent,
    Divider,
    IconButton,
    Button,
    CardActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons'
import { AtentionFormProviders } from 'providers/attentionForm.service';
import { DisplayMessage } from 'notifications/displayMessage';

export default class AmbulatoryCare extends React.Component{
    _isMounted=false
    constructor(props){
        super(props);

        this.state={
            buscar:'',
            buscarcie10:'',
            rucDoctor:'',
            preCodico:'',
            especialidad:'',
            datosEspecialidad:0,
            fecha:'',
            diagnostico:'',
            establecimiento:'',
            nombreProfesional:'',
            monto:'',
        }
        this.handleChange = this.handleChange.bind(this);
        this.enviaDatos = this.enviaDatos.bind(this);
    }

    componentDidMount(){
        this._isMounted=true;
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        atention.comboEspecialidad()
            .then(
                user => { 
                    if(this._isMounted){
                        this.setState({
                            datosEspecialidad:user.bodyOut.data
                        });
                    }       
             
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
              
                }
            );
    }
    componentWillUnmount(){
        this._isMounted=false
    }

    handleClose=()=>{
        this.props.close(false);
    }

    handleChangeSelectEspecialidad=(e)=>{
        this.setState({
            especialidad:e.target.value
        })
    }

    handleChange=(e)=>{
       
        this.setState({
            [e.target.name]:e.target.value
        });
        const arregloDatos={
            [e.target.name]:e.target.value
            
        }

        this.enviaDatos(arregloDatos)
    }

    enviaDatos(arregloDatos){
        this.props.datos(arregloDatos,this.state.preCodico,this.state.diagnostico,this.state.establecimiento,this.state.especialidad)
        
    }

    handleChangeBuscar=(e)=>{
        this.setState({
            buscar:e.target.value
        })
    }

    handleChangeBuscarCie10=(e)=>{
        this.setState({
            buscarcie10:e.target.value
        })
    }

    handleClickRuc=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscar === ''){
            displayMessage.error('Ingrese el RUC para buscar')
        }else{
    
            atention.consultarPresador(this.state.buscar)
                .then(
                    user => {  
                        if(user.bodyOut.data.length === 0){
                            displayMessage.error('RUC no encontrado.');
                        }else{
                            displayMessage.success('RUC encontrado')

                            this.setState({
                                rucDoctor:user.bodyOut.data[0].preRuc,
                                establecimiento:user.bodyOut.data[0].preNombre,
                                preCodico:user.bodyOut.data[0].preCodigo
                            }) 
                        }      
           
             
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
              
                    }
                );
        }
    }

    handleClickCie10=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscarcie10 === ''){
            displayMessage.error('Ingrese CIE10')
        }else{
    
            atention.comboEnfermedades(this.state.buscarcie10)
                .then(
                    user => {  
                        if(user.bodyOut.data.length === 0){
                            displayMessage.error('CIE10 no encontrado.');
                        }else{
                            displayMessage.success('CIE10 encontrado')

                            this.setState({
                                diagnostico:user.bodyOut.data[0].detNombre
                            }) 
                        }      
           
             
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
              
                    }
                );
        }
    }



    render(){
        console.log(this.props.error)
        return(
            <Grid>
                <Card>
                    <CardHeader
                        action={<IconButton
                            color="secondary"
                            onClick={this.handleClose}
                        ><Cancel/></IconButton>}
                        title="Atención Ambulatoria"
                    />
                    <Divider/>
                    <CardActions>
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Buscar ruc"
                                name="buscar"
                                onChange={this.handleChangeBuscar}
                                placeholder="Buscar por RUC"
                                value={this.state.buscar}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                onClick={this.handleClickRuc}
                                variant="contained"
                            >
                               Buscar
                            </Button>
                        </Grid>  
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Buscar CIE10"
                                name="buscarcie10"
                                onChange={this.handleChangeBuscarCie10}
                                placeholder="Buscar CIE10"
                                value={this.state.buscarcie10}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                onClick={this.handleClickCie10}
                                variant="contained"
                            >
                               Buscar
                            </Button>
                        </Grid>  
                    </CardActions>
                    <CardContent>
                        {/* <Grid
                    container
                    spacing={4}
                    >   
                </Grid> */}
                        <Grid
                            container
                            spacing={4}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Ruc Doctor"
                                    name="rucDoctor"
                                    onChange={this.handleChange}   
                                    value={this.state.rucDoctor}                    
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                {this.state.datosEspecialidad ? 
                                    <FormControl fullWidth>
                               
                                        <InputLabel >Especialidad</InputLabel>
                                        <Select 
                                            //style={{width:250}}
                               
                                            onChange={this.handleChangeSelectEspecialidad}               
                                            value={this.state.especialidad}
                                        >
                                            <MenuItem value="">SELECCIONE</MenuItem>
                                            {this.state.datosEspecialidad ? this.state.datosEspecialidad.map((especialidad,index) =>
                                                <MenuItem
                                                    key={index}
                                                    value={especialidad.detCodigo}
                                                >
                                                    {especialidad.detNombre}
                                                </MenuItem>
                                            ):null}
                                        </Select>
                            
                                    </FormControl>:<CircularProgress/>}
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Fecha"
                                    name="fecha"
                                    onChange={this.handleChange}
                                    type="date"
                                    value={this.state.fecha}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Diagnostico"
                                    multiline
                                    name="diagnostico"
                                    onChange={this.handleChange}
                                    value={this.state.diagnostico}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Establecimiento"
                                    name="establecimiento"
                                    onChange={this.handleChange}
                                    value={this.state.establecimiento}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Nombre del profesional"
                                    name="nombreProfesional"
                                    onChange={this.handleChange}
                                    value={this.state.nombreProfesional}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Monto utilizado"
                                    name="monto"
                                    onChange={this.handleChange}
                                    value={this.state.monto}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card><br/>
            </Grid>
        );
    }
}