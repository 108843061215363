/* eslint no-eval: 0 */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {Cancel} from '@material-ui/icons'
import Grid from '@material-ui/core/Grid';

import { CardContent, Card, Dialog, CardHeader, IconButton, DialogActions } from '@material-ui/core';
import { PolizaProvider } from 'providers/poliza.service';
import { DisplayMessage } from 'notifications/displayMessage';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { PlanProvider } from 'providers/plan.service';
import { PlanBrokerProvider } from 'providers/planBroker.service';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ConfirmDialog } from './components';
import Typography from '@material-ui/core/Typography';

export default class FormUpdatePolicy extends Component {

    constructor(props){
        super(props);

        this.state = {
            tipoPlan: [],
            allPlans: [],
            typePlan: '',
            openConfirmacion: false,
            errors: {
                polDescripcion: false,
                polEstado: false,
                typePlan:false,
                plan: false,
                statePlan: false

            },
            messages: {
                polDescripcion: '',
                polEstado: '',
                typePlan:'',
                plan: '',
                statePlan: ''

            },
            userrole: props.userrole

        }

        this.polizaProvider = new PolizaProvider();
        this.displayMessage = new DisplayMessage();
        this.planProvider = new PlanProvider();
        this.planBrokerProvider = new PlanBrokerProvider();

    }

    /** ----------------------------------------Traer todos los Planes */
    getAllPlanes = nombre => {
        this.planBrokerProvider.findAllPlanBrokerByPlaNombre(nombre).then(
            response => {
                this.setState({
                    allPlans: response
                })
            }
        );
    }

   /** -------------------------------------------Acciones para el modal de confirmacion */
   handleOpenConfirm = () => {
       this.changeOneState('openConfirmacion', true);
   }

   handleCloseConfirm = () => {
       this.changeOneState('openConfirmacion', false);
   }

   
    /** ------------------------------------------Cerrar el modal actual*/
    handleClose = () => {
        const { setOpenUpdateModal, cleanFields, enableAllFields } = this.props;
        setOpenUpdateModal(false, null);  
        this.cleanErrors();
        cleanFields();  
        enableAllFields();
    
    };

    
    /** -------------------------------------------------------*/
        cleanErrors = () => {
            this.setState({
                errors: {
                    polDescripcion: false,
                    polEstado: false,
                    typePlan:false,
                    plan: false,
                    statePlan: false
                },
                messages: {
                    polDescripcion: '',
                    polEstado: '',
                    typePlan:'',
                    plan: '',
                    statePlan: ''
                }
            });
        }

        /** -------------------------------------------------------Notificar Todosp */
        showEmpties = () => {
            const { polDescripcion, statePlan, tyoePlan, plan } = this.props;
            this.notifyEmptyNulls('polDescripcion',polDescripcion);
            this.notifyEmptyNulls('statePlan',statePlan);
            this.notifyEmptyNulls('typePlan',tyoePlan);
            this.notifyEmptyNulls('plan',plan);

            
        }
        /**  ------------------------------------------------------NOtificar NUlos*/

        notifyEmptyNulls = (nameElement, value) => {
            if(typeof(value)==='object' ){
                if(value === null){
                    this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
                }else{
                    this.setErrorsMessages(nameElement, false, '');
                }

            } else if(typeof(value)==='string'){
                if(value.trim()===''){
                    this.setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
                }else{
                    this.setErrorsMessages(nameElement, false, '');
 
                }
            } else if (Number.isInteger(value)){
                if(value === 0){
                    this.setErrorsMessages(nameElement, true, 'Seleccione una opción');

                }else{
                    this.setErrorsMessages(nameElement, false, '');

                }
            }  

        }  
        setErrorsMessages = (nameElement, stateElement, message) => {
            
            const errors = this.state.errors;

            const messages = this.state.messages;
            eval(`errors.${nameElement}  = ${stateElement}`);
            eval(`messages.${nameElement} = '${message}'`) ;


            this.setState({
                errors: errors,
                messages:messages
            });
        }


    

    /** ----------------------------------------------Cambiar estado */
    changeOneState = (nameState,value) => {
        this.setState({
            [nameState]: value
        })
    }

    /** --------------------------------------- Crear Poliza */
    updatePoliza = () => {
        const { polDescripcion, polEstado, polCodigo,  plan, statePlan } = this.props;
        if(polCodigo !==null && polEstado !==null && polDescripcion.trim() !== '' && plan !== null && statePlan !== 0  ){

            if(statePlan === 23531 ){
                this.handleOpenConfirm();
            }else{
                this.executeUpdate();
            }
        }else{

            this.displayMessage.warn('Ingrese todos los campos');
            this.showEmpties();
        }
        //return trama;
    }

    /** -------------------------------------------------- Ejecutar actualizacion */
    executeUpdate = () => {
        const {polContrato, polDescripcion, polEstado, polCodigo, cleanFields, getAllPolicy, plan, statePlan, currentChange } = this.props;

        const trama={
            polCodigo: polCodigo,
            polEstado: polEstado,
            polDescripcion: polDescripcion,
            plaCodigo: plan.plaCodigo,
            detEstado: statePlan,
        }
        this.polizaProvider.updatePolicy([trama]).then(
            ( response ) => {
                if(response.error.codigo === '0'){
                    currentChange();
                    this.cleanErrors();
                    this.displayMessage.success(`Poliza ${polContrato} modificada con exito`);
                    this.handleClose();
                    getAllPolicy();

                    cleanFields();
                }else{
                    this.displayMessage.error(`Error: ${response.error.mensaje}`)

                }
            }
        )
    }
    /** --------------------------------------------------Validaciones */
    
    render(){
        return(
            <Dialog
                fullWidth
                open={this.props.open}
                scroll={'body'}
            >
                <Card>
                    <CardHeader 
                        action={<IconButton
                            color="secondary"
                            onClick={this.handleClose}
                        ><Cancel/></IconButton>}
                        title = {this.props.polContrato !== '' ? `Póliza ${this.props.polContrato}` : 'Cargando...' }
                    />
                    <CardContent>
                        {this.props.statePlan === 23531 &&
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Typography variant="caption" display="block" className='red-text' gutterBottom>
                                    * Las pólizas canceladas no pueden ser modificadas
                                </Typography>
                            </Grid>
                        </Grid>
                        }
                        <ConfirmDialog open={this.state.openConfirmacion} handleClose = {this.handleCloseConfirm} executeUpdate = {this.executeUpdate} />   
                        
                        
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <FormControl fullWidth variant='outlined' error={this.state.errors.polEstado} >
                                    <InputLabel id="statePolicyLabel"  >Estado interno de la póliza</InputLabel>
                                    <Select
                                        id='polEstado'
                                        label='Estado interno de la póliza'
                                        labelId="statePolicyLabel"
                                        name='polEstado'
                                        onChange={this.props.handleInputChange}
                                        value={this.props.polEstado}
                                        disabled={this.props.disabled.polEstado || this.props.userrole !== 'ADMIN'}

                                    >
                                        <MenuItem  value={0}>INACTIVO</MenuItem> 
                                        <MenuItem  value={1}>ACTIVO</MenuItem> 

                                    </Select>
                                </FormControl>
                                
                            </Grid>
                            <Grid item xs>
                                <FormControl fullWidth variant='outlined' error={this.state.errors.statePlan} >
                                    <InputLabel id="typePlanLabel">Estado poliza-cliente</InputLabel>
                                    <Select
                                        id='statePlan'
                                        label='Estado poliza-cliente'
                                        labelId="typePlanLabel"
                                        name='statePlan'
                                        onChange={this.props.handleInputChange}
                                        value={this.props.statePlan}
                                        disabled={this.props.disabled.statePlan  || this.props.userrole !== 'ADMIN'}

                                    >
                                        <MenuItem  value={0}>Seleccione</MenuItem> 
                                        {
                                            this.props.allStatesPlans && this.props.allStatesPlans.map(
                                                (state )=>  <MenuItem key={state.detCodigo} value={state.detCodigo}>{state.detNombre}</MenuItem> )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <TextField value={this.props.polDescripcion} name='polDescripcion' label="Descripción" onChange = {this.props.handleInputChange} variant="outlined" error={this.state.errors.polDescripcion} helperText={this.state.messages.polDescripcion} 
                                    disabled={this.props.disabled.polDescripcion  || this.props.userrole !== 'ADMIN'}
                                    fullWidth/>
                        
                            </Grid>
                        </Grid>


                    </CardContent>
                        
                </Card>

                <Card>
                    <CardHeader title='Datos del plan'/>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <FormControl fullWidth variant='outlined' error={this.state.errors.typePlan} >
                                    <InputLabel id="typePlanLabel"  >Tipo de plan</InputLabel>
                                    <Select
                                        id='typePlan'
                                        label='Tipo de plan'
                                        labelId="typePlanLabel"
                                        name='typePlan'
                                        onChange={this.props.handleInputChange}
                                        value={this.props.typePlan}
                                        disabled={this.props.disabled.typePlan  || this.props.userrole !== 'ADMIN'}
                                    >
                                        <MenuItem  value={0}>Seleccione</MenuItem> 
                                        {
                                            this.props.allTypePlans && this.props.allTypePlans.map(
                                                (plan, index)=>  <MenuItem key={index} value={plan.plaNombre}>{plan.plaNombre}</MenuItem> )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Autocomplete
                                    id="plan"
                                    options={this.props.allPlans}
                                    fullWidth
                                    getOptionLabel={(option) => option.plaProducto}
                                    onChange = {this.props.onChangePlan}
                                    value = {this.props.plan}
                                    disabled={this.props.disabled.plan  || this.props.userrole !== 'ADMIN'}
                                    renderInput={(params) => <TextField {...params} error={this.state.errors.plan} helperText={this.state.messages.plan} label="Plan" variant="outlined" fullWidth/>}
                                />
                                
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
                
                <DialogActions>
                    <Button color="primary" onClick={this.handleClose}>
                        Cancelar
                    </Button>
                    <Button 
                        autoFocus 
                        color="primary" 
                        onClick={this.updatePoliza}
                        disabled = {this.props.polCodigo && this.props.userrole === 'ADMIN' ? 
                            this.props.disabled.buttonSave : true}
                    >
                            Guardar
                    </Button>
                </DialogActions>
            </Dialog>

        )
    }
}