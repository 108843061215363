import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { PaymentProvider } from 'providers/payment.service';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange } from '@material-ui/core/colors';
import ModalUpload from './../../ModalUploadFiles/ModalUploadFiles';
import {CloudUpload } from '@material-ui/icons';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import ReplayIcon from '@material-ui/icons/Replay';
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: orange[400],
    },
    redChip: {
        backgroundColor: red[400],
    }
}));
const PayList = ({ session }) => {
    const classes = useStyles();
    const displayMessage = new DisplayMessage;
    const paymentProvider = new PaymentProvider();
    const queriesProvider = new QueriesProvider();
    const [payments,setPayments] = useState([]);
    const [totalPay,setTotalPay] = useState(0);
    const [searchBar, setSearchBar] = useState('');    
    const [openPayDialog,setOpenPayDialog] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [datosPagos, setDatosPagos] = useState([]);
    //SUBIR ARCHIVOS
    const handleOpenSubir = () => {
        setOpenUpload(true)
    }
    const handleSelectAll = () => {
        //setPayments([...datosPagos])
        setDatosPagos([]);
    }
    const handleCloseSubir = () => {
        setOpenUpload(false)
        
    }
//ModalUploadFiles
    const getPagos = (pagos) =>{
        setDatosPagos(pagos)
    }
    const [codigo,setCodigo]=useState('');
    useEffect(() => {
        paymentProvider.findDetNemonico('PAG').then(
            data=>{   
                if(data.error.codigo==='0'){
                    setCodigo(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
    }, [])
    
    let recorrer = 0;


    const getAllPayments = () => {
        paymentProvider.findAllPaymentsByPolCodigoOrder(session.user.polCodigo).then(
            response => {
                setPayments(response);
            }
        )
    }

    const handleSearch = () => {
        const search = searchBar === '' ? session.user.perIdentificacion: searchBar;
        queriesProvider.searchPaymentsByIdentification(search).then(
            response => {
                if(response.error.codigo === '0'){
                    setPayments(response.bodyOut.data);
                }
            }
        )
    }

    const handleClose = () => {
        setOpenPayDialog(false);
    }

    const reloadFields = () => {
        setDatosPagos([]);
        setTotalPay(0);
    }



    

    const columns = [
        { title: 'Fecha de Vencimiento', field: 'pagFechaVencimiento'},
        { title: 'Fecha de pago', field: 'pagFechaPago' },
        { title: 'Monto', field: 'pagMonto' },
        { title: 'Observación', field: 'pagObservacion' },
        { title: 'Estado', field: 'detNombre' },
        { title: 'Póliza', field: 'polContrato' },
        
        

    ];
return (
    <div>
           
    <div>  
         
        <ModalUpload
                open={openUpload}
                close={handleCloseSubir}
                pagos={getPagos}
        />   
            <Grid container spacing={2}>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: 10 }}
                                    onClick={handleOpenSubir}
                                    size="small"
                                ><CloudUpload /> Subir archivos</Button>

                        
                            
                                <p/>
                            <Grid
                                item
                                md={10}
                                xs={12}
                            ></Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: 10 }}
                                    onClick={handleSelectAll}
                                    size="small"
                                ><ReplayIcon /> Limpiar Datos</Button>
                                <p/>
                            </Grid>  
                    
            </Grid>
                                
            <MaterialTable

                columns={columns}
                

                data={datosPagos}

                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'

                    },
                    toolbar: {
                        nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acción'
                    },
                    body: {

                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter',

                        },

                    }
                }}

                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                        width: 100,
                        textAlign: 'center'
                    },
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    toolbar: false,
                    pageSize: 12,
                    showTitle: false,
                    rowStyle: () => {
                        recorrer = recorrer + 1;
                        return ({
                         backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                        })
                    },
                }}


                title={'Persona'}
            />
            
    </div>
    </div>
);    
};
export default PayList;
