import { DataService } from 'providers/dataService'

export class RelationShipProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosParentesco( ) {
        let body = {       
            tipoConsulta: 1,
            entidad: "DetalleCatalogo",
            parametros: {
                catCodigo:12,					
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }

    tipoIdentificacion( ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: 2,
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


}
