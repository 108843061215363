import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const LiquidationList = (props) => {
    const classes = useStyles();
    const [total, setTotal] = useState(0);
    const tipo = props.match.params.option;
    const [params, setParams] = useState({})

    useEffect(() => {
        handleParams(tipo)
    }, [tipo])

    const handleCount = count => {
        setTotal(count);
    }


    const handleParams = (tipoParam) => {
        if (tipoParam == 'digitalizar') {
            setParams({
                tipo: tipoParam,
                status: 'ESTLI',
                header: 'Liquidaciones pendientes'                
            });        
        } else if (tipoParam == 'auditoria') {
            setParams({
                tipo: tipoParam,
                status: 'ESTAU',
                header: 'Liquidaciones en auditoría'                
            });
        } else if (tipoParam == 'verificar') {
            setParams({
                tipo: tipoParam,
                status: 'ESTLQC',                
                header: 'Liquidaciones por verificar'                
            });
        } else if (tipoParam == 'reporte') {
            setParams({
                tipo: tipoParam,
                status: '',                
                header: 'Reporte liquidaciones'                
            });
        } else if (tipoParam == 'porpagar') {
            setParams({
                tipo: tipoParam,
                status: 'ESTLIC',                
                header: 'Liquidaciones pendientes'                
            });
        } else if (tipoParam == 'pagadas') {
            setParams({
                tipo: tipoParam,
                status: 'ESTPAG',                
                header: 'Liquidaciones pagadas'                
            });
        }
    }

    return (
        <Page 
            className={classes.root}
            title={params.header}
        >
            <Header 
                className={classes.color}
                params={params}
                total={total}
            />

            <Results
                className={classes.results}
                onCount={handleCount}
                params={params}
            />            
        </Page>
    );
};

export default LiquidationList;
