import { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class DisplayMessage extends Component {
    constructor(props) {
        super(props);        
        this.position = toast.POSITION.TOP_RIGHT
    }

    notify(message) {
        return toast.notify(message, {
            position: this.position
        });
    }
    error(message) {
        return toast.error(message, {
            position: this.position
        });
    }
    success(message) {
        return toast.success(message, {
            position: this.position
        });
    }
    info(message) {
        return toast.info(message, {
            position: this.position
        });
    }
    warn(message) {
        return toast.warn(message, {
            position: this.position
        });
    }
    clear() {
        return toast.dismiss();
    }


}

 