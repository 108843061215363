/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment /* , Tooltip, IconButton  */,
    TextField
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    Add,
    Archive,
    BarChart,
    Send,
    Visibility,
    VpnKey
} from '@material-ui/icons';
import moment from 'moment';
import UserContext from 'context/Users/UserContext';
import LoadingComponent from 'components/LoadingComponent';
import { NewUserProvider } from 'providers/newUser.service';
import { StayProvider } from 'providers/stay.service';
import { SearchBasic } from 'components';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    }
}));

const Results = props => {
    const tableRef = React.useRef();
    const stay = new StayProvider();
    const { setView, className, params, ...rest } = props;
    const classes = useStyles();
    const displayMessage = new DisplayMessage();
    const user = new NewUserProvider();
    const [booleano, setBooleano] = useState(false);

    const [openContrasenia, setOpenContrasenia] = useState(false);
    const [usuPassword, setUsuPassword] = useState('');
    const [confirmar, setConfirmar] = useState('');
    const [codigoUsu, setCodigoUsu] = useState({});

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmar, setErrorConfirmar] = useState(false);
    const [errorDiferente, setErrorDiferente] = useState(false);

    const [valueSearch, setvalueSearch] = useState([]);
    const [searchUser, setsearchUser] = useState([]);/* const [valueSearch, setvalueSearch] = useState(''); */

    const { dataUser, loading, getUser, getRoles, selectUser } = useContext(
        UserContext
    );

    

    const loadData = (query, resolve) => {
        
        stay.listFnc(
           /*  "%" */
            valueSearch
           
        ).then(
            response => {
                if (
                    response.error.codigo === '0' &&
                    response.bodyOut.data.length
                ) {
                    setBooleano(false);
                    //setTotal(response.bodyOut.data[0].TOTAL);
                    resolve({
                        data: response.bodyOut.data,
                        page: query.page,
                        totalCount: response.bodyOut.data[0].TOTAL
                    });
                } else {
                    setBooleano(false);
                    //setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                }
            },
            () => {
                setBooleano(false);
                //setTotal(0);
                resolve({
                    data: [],
                    page: query.page,
                    totalCount: 0
                });
                displayMessage.error('Error de comunicación.');
            }
        );
    };


    /* INICIO BUSCADOR */
    const handleSearch = () => {
        if (valueSearch !== '') {
            tableRef.current.onSearchChange(valueSearch);
        } else {
            displayMessage.error('Ingrese el texto para buscar');
        }
    };

    const handleClean = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        setvalueSearch([]);
        /* setvalueSearch(''); */
    };

    const handleInputChange = event => {
        setvalueSearch(event.target.value);
    };
    /* FIN BUSCADOR */

    
    return (
        <>
            <div {...rest} className={clsx(classes.root, className)}>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <SearchBasic
                            cleanSearchBar={handleClean}
                            complementaryMessage="Buscar"
                            handleInputChange={handleInputChange}
                            handleSearch={handleSearch}
                            searchBar={valueSearch}
                        />
                    </Grid>
                </Grid>
                <br></br>
                <Card style={{ marginTop: 10 }}>
                    <CardHeader title="Reportes" />
                    <Divider />
                    <MaterialTable
                        columns={[
                            { title: 'Contrato', field: 'polContra' },
                            { title: 'Decripción', field: 'detDesc', width: 300 },
                            {
                                title: 'Nombres',
                                field: 'perNombres',
                                width: 300
                            },
                            { title: 'Apellidos', field: 'perApellidos' },
                            { title: 'Identificación', field: 'perIdent' },
                            { title: 'Fecha Registro', field: 'perFechaRegis' },
                            { title: 'Fecha Inicio', field: 'polFechaIni' },
                            { title: 'Fecha Fin', field: 'polFechaFn' },
                            { title: 'Plan', field: 'plaNombre' },
                            { title: 'Factura', field: 'factura' },
                            { title: 'Facturado', field: 'facturado'},
                            { title: 'Periodicidad', field: 'periodicidad'},
                            { title: 'Fecha Autorización', field: 'fechaAut'},
                            { title: 'Fecha Cancelada', field: 'fechaCan'},
                            { title: 'Estado', field: 'estado' },
                            
                            /* { title: 'Estado', field: 'usuEstado' } */
                        ]}
                        //data={dataUser}

                        data={
                            booleano === false
                                ? query =>
                                      new Promise(resolve => {
                                          loadData(query, resolve);
                                      })
                                : []
                        }
                        localization={{
                            header: {
                                actions: 'Accion'
                            },
                            body: {
                                emptyDataSourceMessage:
                                    'No hay datos que mostrar'
                            },
                            pagination: {
                                labelRowsSelect: 'Filas',
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsPerPage: 'filas'
                            },
                            toolbar: {
                                searchPlaceholder: 'Buscar...'
                            }
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            actionsCellStyle: {
                                /* width: 250, */
                                textAlign: 'center'
                            },
                            headerStyle: {
                                fontWeight: 'bold'
                            },
                            search: false,
                            toolbar: false,
                            pageSize: 10,
                            showTitle: false,
                            rowStyle: rowData => ({
                                backgroundColor:
                                    rowData.tableData.id % 2 === 0
                                        ? '#FFF'
                                        : '#EEE'
                            })
                        }}
                        tableRef={tableRef}
                    />
                    {/* </div> */}
                    {/* </PerfectScrollbar> */}
                    {/* </CardContent> */}
                </Card>
            </div>
            <LoadingComponent open={loading} />
        </>
    );
};

Results.propTypes = {
    className: PropTypes.string
};

export default Results;
