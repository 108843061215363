/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    Card,
    CardActions,
    Grid,
    Button,
    TextField,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Paper,
    TableRow,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import {
    DatePicker,
    KeyboardTimePicker,
    TimePicker
} from '@material-ui/pickers';
import { Link as RouterLink } from 'react-router-dom';
import 'moment/locale/es';
import moment from 'moment';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    Search,
    Clear,
    KeyboardReturn,
    Add,
    Schedule,
    Save,
    ListAlt,
    CloudUpload
} from '@material-ui/icons';
import ModalSearchAfiliate from './../ModlalSeartchAffiliate/ModlalSeartchAffiliate';
import ModalPrestador from './../ModlalSeartchLeader/ModlalSeartchLeader';
import { AppointmentProvider } from 'providers/appointment.service';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import NumberFormatMoney from 'components/NumberFormatMoney';
import ModalStatus from './../ModalStatus/ModalStatus';

import ModalUpload from './../ModalUploadFiles/ModalUploadFiles';
import { forms } from 'views/Digitize/forms';
import { parse } from 'uuid';
moment.locale('es');

export default function SearchAffiliate(props) {
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();
    const [data, setData] = useState([]);
    const session = useSelector(state => state.session);

    const [fechaAprovacion, setFechaAprovacion] = useState(moment());

    const [dataPaciente, setDataPaciente] = useState([]);
    const [activarComboPaciente, setActivarComboPaciente] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [cedulaTitular, setCedulaTitular] = useState('');
    const [nameTitular, setNameTitular] = useState('');
    const [lastNameTitular, setLastNameTitular] = useState('');
    const [polCodigo, setPolCodigo] = useState(null);
    const [preexistencia, setPreexistencia] = useState('');

    const [cedulaPaciente, setCedulaPaciente] = useState('');
    const [namePaciente, setNamePaciente] = useState('');
    const [lastNamePaciente, setLastNamePaciente] = useState('');
    const [edadPaciente, setEdadPaciente] = useState('');
    const [emailPaciente, setEmailPaciente] = useState('');
    const [plan, setPlan] = useState('');
    const [perCodigo, setPerCodigo] = useState(null);
    const [telefono, setTelefono] = useState(null);

    const [errorPaciente, setErrorPaciente] = useState(false);

    const [openModalPrestador, setOpenPrestador] = useState(false);
    const [nombrePrestador, setNombrePrestador] = useState('');
    const [preCodigo, setPreCodigo] = useState(null);

    const [formState, setFormState] = useState({});
    const [selectedDate, handleDateChange] = useState(moment());
    const [fechaCita, setFechaCita] = useState();

    const [tipoAgendamiento, setTipoAgendamiento] = useState([]);
    const [agendamiento, setAgendamiento] = useState('');
    const [codAgendamiento, setCodAgendamiento] = useState('');
    const [activarTitular, setActivarTitular] = useState(false);

    const [ciudades, setCiudades] = useState([]);
    const [ciudad, setCiudad] = useState('');
    const [txtCiudad, setTxtCiudad] = useState('');
    const [errorCiudad, setErrorCiudad] = useState(false);

    const [estados, setEstados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [nombreEspecialidad, setNombreEspecialidad] = useState('');

    const [txtEspecialidad, setTxtEspecialidad] = useState('');

    const [fechaRegistro, setFechaRegistro] = useState();
    const [costo, setCosto] = useState(0);

    const [errorSintomas, setErrorSintomas] = useState(false);
    const [errorEstatus, setErrorEstatus] = useState(false);

    const [desactivar, setDesactivar] = useState(false);

    const [typeUser, setTypeUser] = useState(true);
    const [typeCli, setTypeCli] = useState(false);

    const [montoCobertura, setMontoCobertura] = useState(null);

    const [openStatus, setOpenStatus] = useState(false);

    const [dataDocumento, setDataDocumento] = useState(null);

    const [openUpload, setOpenUpload] = useState(false);
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState('');

    const [tipoDoc, setTipoDoc] = useState(0);
    const [loading, setLoading] = useState(false);

    const [montoFacturado, setMontoFacturado] = useState(0);
    const [montoNoCubierto, setMontoNoCubierto] = useState(0);
    const [montoCubierto, setMontoCubierto] = useState(0);
    const [copago, setCopago] = useState(0);
    const [deducible, setDeducible] = useState(0);

    const [agendado, setAgendado] = useState('');
    const [cancelado, setCancelado] = useState(0);
    const [pendiente, setPendiente] = useState('');

    const [openDialog, setOpenDialog] = useState(false);

    const [descripcionDocumento, setDescripcionDocumento] = useState('');

    const [montoOcupado, setMontoOcupado] = useState(0);

    const [perTitular, setPerTitular] = useState(0);

    const [monstoDisponible, setMontoDisponible] = useState(0);

    const [errorMontoDisponible, setErrorMontoDisponible] = useState(false);

    const [correoPrestador, setCorreoPrestador] = useState(null);

    useEffect(() => {
        if (session.user.role === 'AUDITOR') {
            setTypeUser(false);
        }
        if (session.user.role === 'CLIENTE') {
            setTypeCli(true);
        }
        setAgendamiento(`${props.datos[0].detNemonico}`);
        setCodAgendamiento(props.datos[0].detCodigo);
        //status(`${props.datos[0].detTipo}`)

        if (
            props.datos[0].detTipo !== undefined &&
            props.datos[0].detTipo !== ''
        ) {
            setActivarTitular(true);
            titular();
            pacienteActual();
            especiali(props.datos[0].detNemonico);
            const found = props.datos[0].estado.find(
                element => element.esaEstado === 1
            );

            if (
                props.datos[0].detTipo !== 'CIRPRO' ||
                props.datos[0].detTipo !== 'LABORA'
            ) {
                let notifi = null;
                if (props.datos[0].ageNotificado !== null) {
                    notifi = `${props.datos[0].ageNotificado}`;
                }

                setFormState({
                    ...formState,
                    sintomas: props.datos[0].ageSintomas,
                    notificacion: notifi,
                    nombreMedico: props.datos[0].ageMedico,
                    observacionesAsistencia: props.datos[0].ageObservaciones,
                    nombreEspecialidad: props.datos[0].ageEspecialidad,
                    estatus: `${found.detEstado}`,
                    observacionesAgendamiento: found.esaObservacion,
                    documentos: props.datos[0].ageDocumentos,
                    examenes: props.datos[0].ageExamenes,
                    diagnostico: props.datos[0].ageDiagnostico,
                    indicaciones: props.datos[0].ageIndicaciones,
                    //preexistencia: props.datos[0].agePreexistencia,
                    observacionesMedico: props.datos[0].ageObservaMedico
                });
            }
            setMontoCobertura(props.datos[0].ageCobertura);
            setMontoFacturado(props.datos[0].ageFacturado);
            setMontoNoCubierto(props.datos[0].ageNoCubierto);
            setMontoCubierto(props.datos[0].ageCubierto);
            setCopago(props.datos[0].ageCopago);
            setDeducible(props.datos[0].ageDeducible);
            setNombrePrestador(props.datos[0].preNombre);
            setPreCodigo(props.datos[0].preCodigo);
            setNombreEspecialidad(props.datos[0].ageEspecialidad);
            setEmailPaciente(props.datos[0].ageMailPaciente);
            if (props.datos[0].ageEspecialidad !== null) {
                setTxtEspecialidad(props.datos[0].ageEspecialidad);
            }
            setFechaCita(props.datos[0].ageFechaCita);
            handleDateChange(props.datos[0].ageFechaCita);
            setCiudad(props.datos[0].locCodigo);
            if (props.datos[0].locNombre !== null) {
                setTxtCiudad(props.datos[0].locNombre);
            }
            setFechaAprovacion(props.datos[0].ageFechaConfirma);
            setFechaRegistro(props.datos[0].ageFechaRegistro);
            setPerCodigo(props.datos[0].perBeneficiario);

            setPolCodigo(props.datos[0].polCodigo);
            setCosto(props.datos[0].ageValor);
            setTelefono(props.datos[0].ageTelefono);
        }
        status(`${props.datos[0].detNemonico}`);
    }, []);

    useEffect(() => {
        appoinment.documentosAgendamiento(props.datos[0].ageCodigo).then(
            response => {
                if (response.length > 0) {
                    setDataDocumento(response);
                } else {
                    setDataDocumento([]);
                }
            },
            () => {
                displayMessage.error('Error de conexión');
            }
        );

        appoinment.constultaCatalogoDocumento('HABIL').then(
            data => {
                if (data.error.codigo !== '0') {
                    setTipoDoc(0);
                } else {
                    setTipoDoc(data.bodyOut.data[0].detCodigo);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    }, []);

    useEffect(() => {
        if (
            props.datos[0].detNemonico === 'ODONTO' &&
            props.datos[0].perBeneficiario !== 0
        ) {
            let total = 0;
            let monto = 0;
            appoinment
                .constultaMonto(
                    props.datos[0].detTipo,
                    props.datos[0].perBeneficiario,
                    props.datos[0].polCodigo
                )
                .then(
                    response => {
                        if (response.error.codigo === '0') {
                            response.bodyOut.data.map(item => {
                                monto =
                                    item.topeCobertura -
                                    (item.ageValor + item.liqFacturado);
                            });
                            if (formState.estatus === '23927') {
                                setMontoOcupado((monto + costo).toFixed(2));
                                setMontoDisponible(monto.toFixed(2));
                            } else {
                                setMontoOcupado(monto.toFixed(2));
                                setMontoDisponible((monto - costo).toFixed(2));
                            }
                        } else {
                            //setDataDocumento([]);
                        }
                    },
                    () => {
                        displayMessage.error('Error de conexión');
                    }
                );
        }
    }, []);

    useEffect(() => {
        if (props.datos[0].detNemonico === 'ODONTO') {
            let cos = 0;
            if (costo !== '') {
                cos = parseFloat(costo);
            } else {
                cos = 0;
            }
            let resta = parseFloat(montoOcupado) - cos;
            if (parseFloat(resta) >= 0) {
                setErrorMontoDisponible(false);
                setMontoDisponible(resta);
                setDesactivar(false);
            } else {
                setErrorMontoDisponible(true);
                setMontoDisponible(resta);
                setDesactivar(true);
            }
        }
    }, [costo, montoOcupado]);

    const titular = () => {
        appoinment.datosTitular(21, props.datos[0].polCodigo).then(
            data => {
                if (data.error.codigo !== '0') {
                } else {
                    setPerTitular(data.bodyOut.data[0].perCodigo);
                    setNameTitular(data.bodyOut.data[0].perNombres);
                    setLastNameTitular(data.bodyOut.data[0].perApellidos);
                    setCedulaTitular(data.bodyOut.data[0].perIdentificacion);
                    setPlan(data.bodyOut.data[0].plaNombre);
                    searchBenfi(data.bodyOut.data[0].polCodigo);
                    appoinment
                        .searchTitular(
                            data.bodyOut.data[0].perIdentificacion,
                            'PER_APELLIDOS',
                            'asc',
                            10,
                            0
                        )
                        .then(
                            data => {
                                if (data.error.codigo !== '0') {
                                    //displayMessage.error('Titular no encontrado.');
                                    setData([]);
                                } else {
                                    if (
                                        data.bodyOut.data[0]
                                            .POL_PREEXISTENCIAS === 0
                                    ) {
                                        setPreexistencia('SI');
                                    }
                                    if (
                                        data.bodyOut.data[0]
                                            .POL_PREEXISTENCIAS === 1
                                    ) {
                                        setPreexistencia('NO');
                                    }
                                }
                            },
                            () => {
                                displayMessage.error('Error de comunicación');
                            }
                        );
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const pacienteActual = () => {
        appoinment
            .datosPacienteActual(
                props.datos[0].perBeneficiario,
                props.datos[0].polCodigo
            )
            .then(
                data => {
                    if (data.error.codigo !== '0') {
                    } else {
                        setNamePaciente(data.bodyOut.data[0].perNombres);
                        setLastNamePaciente(data.bodyOut.data[0].perApellidos);
                        setCedulaPaciente(
                            data.bodyOut.data[0].perIdentificacion
                        );
                        setEdadPaciente(data.bodyOut.data[0].edadActual);
                        //setEmailPaciente(data.bodyOut.data[0].perMail);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación');
                }
            );
    };

    useEffect(() => {
        appoinment.constultaCatalogo('TIPAGE', '').then(
            data => {
                if (data.error.codigo !== '0') {
                    setTipoAgendamiento([]);
                } else {
                    setTipoAgendamiento(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );

        //citys()
    }, []);

    const citys = ciudad => {
        appoinment.comboCiudades(ciudad).then(
            data => {
                if (data.error.codigo !== '0') {
                    setCiudades([]);
                } else {
                    //setActivarComboPaciente(false)
                    setCiudades(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const status = agen => {
        appoinment.constultaCatalogo('ESTAGE', '').then(
            data => {
                if (data.error.codigo !== '0') {
                    setEstados([]);
                } else {
                    const agenda = data.bodyOut.data.find(
                        element => element.detNemonico === 'AGENDA'
                    );
                    setAgendado(agenda.detCodigo);
                    const cancel = data.bodyOut.data.find(
                        element => element.detNemonico === 'CANCEL'
                    );
                    setCancelado(cancel.detCodigo);
                    const pendiente = data.bodyOut.data.find(
                        element => element.detNemonico === 'ESTPEN'
                    );
                    setPendiente(pendiente.detCodigo);
                    if (
                        agen === 'CIRPRO' ||
                        agen === 'LABORA' ||
                        agen === 'HOSPIT'
                    ) {
                        if (session.user.role !== 'AUDITOR') {
                            /*  const estadoFiltro = data.bodyOut.data.filter(
                                e => e.detNemonico === 'AUDIT'
                            ); */
                            setEstados(data.bodyOut.data);
                            const found = props.datos[0].estado.find(
                                element => element.esaEstado === 1
                            );
                            let notifi = null;
                            if (props.datos[0].ageNotificado !== null) {
                                notifi = `${props.datos[0].ageNotificado}`;
                            }
                            setFormState({
                                ...formState,
                                //estatus: props.datos[0].detCodigo,
                                sintomas: props.datos[0].ageSintomas,
                                notificacion: notifi,
                                nombreMedico: props.datos[0].ageMedico,
                                observacionesAsistencia:
                                    props.datos[0].ageObservaciones,
                                nombreEspecialidad:
                                    props.datos[0].ageEspecialidad,
                                estatus: `${found.detEstado}`,
                                observacionesAgendamiento: found.esaObservacion,
                                documentos: props.datos[0].ageDocumentos,
                                examenes: props.datos[0].ageExamenes,
                                diagnostico: props.datos[0].ageDiagnostico,
                                indicaciones: props.datos[0].ageIndicaciones,
                                //preexistencia: props.datos[0].agePreexistencia,
                                observacionesMedico:
                                    props.datos[0].ageObservaMedico
                            });
                        } else {
                            const estadoFiltro = data.bodyOut.data.filter(
                                e => e.detNemonico === 'PREAGE'
                            );
                            setEstados(estadoFiltro);
                            const found = props.datos[0].estado.find(
                                element => element.esaEstado === 1
                            );
                            let notifi = null;
                            if (props.datos[0].ageNotificado !== null) {
                                notifi = `${props.datos[0].ageNotificado}`;
                            }
                            setFormState({
                                ...formState,
                                estatus: estadoFiltro[0].detCodigo,
                                sintomas: props.datos[0].ageSintomas,
                                notificacion: notifi,
                                nombreMedico: props.datos[0].ageMedico,
                                observacionesAsistencia:
                                    props.datos[0].ageObservaciones,
                                nombreEspecialidad:
                                    props.datos[0].ageEspecialidad,
                                //estatus: found.detEstado,
                                observacionesAgendamiento: found.esaObservacion,
                                documentos: props.datos[0].ageDocumentos,
                                examenes: props.datos[0].ageExamenes,
                                diagnostico: props.datos[0].ageDiagnostico,
                                indicaciones: props.datos[0].ageIndicaciones,
                                //preexistencia: props.datos[0].agePreexistencia,
                                observacionesMedico:
                                    props.datos[0].ageObservaMedico
                            });
                        }
                    } else {
                        const estadoFiltro = data.bodyOut.data.filter(
                            e => e.detNemonico !== 'AUDIT'
                        );
                        setEstados(estadoFiltro);
                        //setEstados(data.bodyOut.data)
                    }
                    //setActivarComboPaciente(false)
                    //setEstados(data.bodyOut.data)
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const especiali = age => {
        if (age === 'ODONTO') {
            appoinment.constultaCatalogo('ESPAGE', 'ODONTOLOGIA').then(
                data => {
                    if (data.error.codigo !== '0') {
                        setEspecialidades([]);
                    } else {
                        setEspecialidades(data.bodyOut.data);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación');
                }
            );
        } else {
            if (age === 'AMBULA') {
                appoinment.constultaCatalogo('ESPAGE', '').then(
                    data => {
                        if (data.error.codigo !== '0') {
                            setEspecialidades([]);
                        } else {
                            const estadoFiltro = data.bodyOut.data.filter(
                                e =>
                                    e.detNombre !== 'ODONTOLOGIA ENDODONCIA ' &&
                                    e.detNombre !==
                                        'ODONTOLOGIA (PROFILAXIS)' &&
                                    e.detNombre !==
                                        'ODONTOLOGIA (RESINA CARIES SIMPLE)'
                            );
                            setEspecialidades(estadoFiltro);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación');
                    }
                );
            } else {
                appoinment.constultaCatalogo('ESPAGE', '').then(
                    data => {
                        if (data.error.codigo !== '0') {
                            setEspecialidades([]);
                        } else {
                            setEspecialidades(data.bodyOut.data);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación');
                    }
                );
            }
        }
    };

    const searchBenfi = polCodigo => {
        appoinment.datosPaciente(polCodigo).then(
            data => {
                if (data.bodyOut.data.length === 0) {
                    setDataPaciente([]);
                } else {
                    setActivarComboPaciente(false);
                    setDataPaciente(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const handleOnChangePaciente = value => {
        if (value) {
            setErrorPaciente(false);
            setPerCodigo(value.perCodigo);
            setNamePaciente(value.perNombres);
            setLastNamePaciente(value.perApellidos);
            setCedulaPaciente(value.perIdentificacion);
            setEdadPaciente(value.edadActual);
            setEmailPaciente(value.perMail);
        } else {
            setErrorPaciente(true);
            setPerCodigo('');
            setNamePaciente('');
            setLastNamePaciente('');
            setCedulaPaciente('');
            setEdadPaciente('');
            setEmailPaciente('');
        }
    };

    const handleOnChangeEspecialidad = value => {
        if (value) {
            setTxtEspecialidad('');
            setNombreEspecialidad(value.detNombre);
        } else {
            setNombreEspecialidad('');
        }
    };

    const handleCloseEspecialidad = () => {
        setNombreEspecialidad('');
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        setOpenPrestador(false);
        setOpenStatus(false);
    };

    const handleOpenModalPrestador = () => {
        setOpenPrestador(true);
    };

    const getDataAfiliate = data => {
        setCedulaTitular(data.PER_IDENTIFICACION);
        setNameTitular(data.PER_NOMBRES);
        setLastNameTitular(data.PER_APELLIDOS);
        searchBenfi(data.POL_CODIGO);
        setPlan(data.PLA_NOMBRE);
        setPolCodigo(data.POL_CODIGO);
        if (data.POL_PREEXISTENCIAS === 0) {
            setPreexistencia('SI');
        }
        if (data.POL_PREEXISTENCIAS === 1) {
            setPreexistencia('NO');
        }
        //status()
        //especiali()
    };

    const getDataLeader = data => {
        let correo = '';
        if (JSON.parse(data.PRE_DINAMICO).email) {
            JSON.parse(data.PRE_DINAMICO).email.map((item, index) => {
                if (index + 1 === JSON.parse(data.PRE_DINAMICO).email.length) {
                    correo += `${item}`;
                } else {
                    correo += `${item},`;
                }
            });
        }
        setCorreoPrestador(correo);
        setPreCodigo(data.PRE_CODIGO);
        setNombrePrestador(data.PRE_NOMBRE);
    };

    const handleChange = e => {
        if (e.target.name === 'sintomas') {
            setErrorSintomas(false);
        }
        if (e.target.name === 'estatus') {
            setErrorEstatus(false);
        }
        e.persist();
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const handleChangeTipoAgenda = e => {
        setEspecialidades([]);
        let nem = e.target.value;
        status(e.target.value);
        especiali(e.target.value);
        const found = tipoAgendamiento.find(e => e.detNemonico === nem);
        setAgendamiento(e.target.value);
        setCodAgendamiento(found.detCodigo);
        setActivarTitular(true);
        if (e.target.value === 'Selec') {
            setActivarTitular(false);
        }
    };

    const handleChangeCosto = e => {
        setCosto(e.target.value);
    };

    const handleChangeCostoOdonto = e => {
        setCosto(e.target.value);
    };

    const handleChangeTelefono = e => {
        setTelefono(e.target.value);
    };

    const handleClosePaciente = () => {};

    const handleChangeMonto = e => {
        setMontoCobertura(e.target.value);
    };

    const handleChangeMontoFacturado = e => {
        setMontoFacturado(e.target.value);
    };

    const handleChangeMontoNoCubierto = e => {
        setMontoNoCubierto(e.target.value);
    };

    const handleChangeMontoCubierto = e => {
        setMontoCubierto(e.target.value);
    };

    const handleChangeCopago = e => {
        setCopago(e.target.value);
    };

    const handleChangeDeducible = e => {
        setDeducible(e.target.value);
    };

    const handleSave = () => {
        setDesactivar(true);
        if (
            perCodigo === null ||
            perCodigo === undefined ||
            perCodigo === '' ||
            ciudad === undefined ||
            ciudad === '' ||
            ciudad === null ||
            formState.estatus === undefined ||
            formState.estatus === ''
        ) {
            setDesactivar(false);
            displayMessage.error('Complete los campos');
            if (
                perCodigo === null ||
                perCodigo === undefined ||
                perCodigo === ''
            ) {
                setErrorPaciente(true);
            }
            if (ciudad === undefined || ciudad === '' || ciudad === null) {
                setErrorCiudad(true);
            }
            if (formState.estatus === undefined || formState.estatus === '') {
                setErrorEstatus(true);
            }
        } else {
            if (agendamiento === 'AMBULA') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            ageCodigo: props.datos[0].ageCodigo,
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: fechaRegistro,
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: props.datos[0].agePreexistencia,
                            ageObservaMedico: formState.observacionesMedico,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    if (
                        `${agendado}` === formState.estatus ||
                        `${cancelado}` === formState.estatus ||
                        `${pendiente}` === formState.estatus
                    ) {
                        setOpenDialog(true);
                        ingresoAgendamientoSms(trama);
                    } else {
                        ingresoAgendamiento(trama);
                    }
                }
            }

            if (agendamiento === 'HOSPIT') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            ageCodigo: props.datos[0].ageCodigo,
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: fechaRegistro,
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: props.datos[0].agePreexistencia,
                            ageObservaMedico: formState.observacionesMedico,
                            ageFacturado: montoFacturado,
                            ageNoCubierto: montoNoCubierto,
                            ageCubierto: montoCubierto,
                            ageCopago: copago,
                            ageValor: costo,
                            ageDeducible: deducible,
                            ageCobertura: montoCobertura,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    if (
                        `${agendado}` === formState.estatus ||
                        `${cancelado}` === formState.estatus
                    ) {
                        ingresoAgendamientoSms(trama);
                    } else {
                        ingresoAgendamiento(trama);
                    }
                }
            }

            if (agendamiento === 'ODONTO') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            ageCodigo: props.datos[0].ageCodigo,
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: fechaRegistro,
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            ageValor: costo,
                            ageMailPaciente: emailPaciente,
                            agePreexistencia: props.datos[0].agePreexistencia,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    if (
                        `${agendado}` === formState.estatus ||
                        `${cancelado}` === formState.estatus
                    ) {
                        ingresoAgendamientoSms(trama);
                    } else {
                        ingresoAgendamiento(trama);
                    }
                }
            }

            if (agendamiento === 'LABORA') {
                const fecha = `${moment(fechaCita).format(
                    'DD/MM/YYYY'
                )} ${moment(selectedDate).format('HH:mm:ss')}`;
                const trama = [
                    {
                        ageCodigo: props.datos[0].ageCodigo,
                        polCodigo: polCodigo,
                        perBeneficiario: perCodigo,
                        locCodigo: ciudad,
                        preCodigo: preCodigo,
                        usuCodigo: session.user.usuCodigo,
                        detTipo: codAgendamiento,
                        ageFechaRegistro: fechaRegistro,
                        ageFechaConfirma: moment(fechaAprovacion).format(
                            'DD/MM/YYYY'
                        ),
                        ageFechaCita: fecha,
                        //ageSintomas: formState.sintomas,
                        ageTelefono: telefono,
                        ageObservaciones: formState.observacionesAsistencia,
                        ageNotificado: formState.notificacion,
                        //ageEspecialidad: nombreEspecialidad,
                        ageMedico: formState.nombreMedico,
                        //ageValor: costo,
                        ageDocumentos: formState.documentos,
                        //ageAprobacion: "nose",
                        ageExamenes: formState.examenes,
                        ageDiagnostico: formState.diagnostico,
                        agePreexistencia: props.datos[0].agePreexistencia,
                        ageIndicaciones: formState.indicaciones,
                        ageMailPaciente: emailPaciente,
                        estado: [
                            {
                                usuCodigo: session.user.usuCodigo,
                                detEstado: formState.estatus,
                                esaObservacion:
                                    formState.observacionesAgendamiento,
                                ageCodigo: props.datos[0].ageCodigo
                            }
                        ]
                    }
                ];
                if (file === null || file.length === 0) {
                    if (
                        `${agendado}` === formState.estatus ||
                        `${cancelado}` === formState.estatus
                    ) {
                        ingresoAgendamientoSms(trama);
                    } else {
                        ingresoAgendamiento(trama);
                    }
                } else {
                    if (session.user.role === 'CLIENTE') {
                        for (let index = 0; index < file.length; index++) {
                            appoinment
                                .agedamientoDocuentos(
                                    props.datos[0].ageCodigo,
                                    tipoDoc,
                                    [file[index]],
                                    descripcionDocumento
                                )
                                .then(
                                    async response => {
                                        if (response.error.codigo === '0') {
                                            //setDesactivar(false);

                                            if (index === file.length - 1) {
                                                displayMessage.success(
                                                    'Archivo subido correctamente'
                                                );
                                                window.location.href = `/agendarCitas`;
                                            }
                                        } else {
                                            setDesactivar(false);
                                        }
                                    },
                                    () => {
                                        displayMessage.error(
                                            'Error de comunicación.'
                                        );
                                        setDesactivar(false);
                                    }
                                );
                        }
                    } else {
                        ingresoAgendamientoConArchivos(trama);
                    }
                }
            }

            if (agendamiento === 'CIRPRO') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            ageCodigo: props.datos[0].ageCodigo,
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: fechaRegistro,
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: props.datos[0].agePreexistencia,
                            ageCobertura: montoCobertura,
                            ageObservaMedico: formState.observacionesMedico,
                            ageFacturado: montoFacturado,
                            ageNoCubierto: montoNoCubierto,
                            ageCubierto: montoCubierto,
                            ageCopago: copago,
                            ageValor: costo,
                            ageDeducible: deducible,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];

                    if (file === null || file.length === 0) {
                        if (
                            `${agendado}` === formState.estatus ||
                            `${cancelado}` === formState.estatus
                        ) {
                            ingresoAgendamientoSms(trama);
                        } else {
                            ingresoAgendamiento(trama);
                        }
                    } else {
                        if (session.user.role === 'CLIENTE') {
                            for (let index = 0; index < file.length; index++) {
                                appoinment
                                    .agedamientoDocuentos(
                                        props.datos[0].ageCodigo,
                                        tipoDoc,
                                        [file[index]],
                                        descripcionDocumento
                                    )
                                    .then(
                                        async response => {
                                            if (response.error.codigo === '0') {
                                                //setDesactivar(false);

                                                if (index === file.length - 1) {
                                                    displayMessage.success(
                                                        'Archivo subido correctamente'
                                                    );
                                                    window.location.href = `/agendarCitas`;
                                                }
                                            } else {
                                                setDesactivar(false);
                                            }
                                        },
                                        () => {
                                            displayMessage.error(
                                                'Error de comunicación.'
                                            );
                                            setDesactivar(false);
                                        }
                                    );
                            }
                        } else {
                            ingresoAgendamientoConArchivos(trama);
                        }
                    }
                }
            }
        }
    };
    const ingresoAgendamiento = trama => {
        appoinment.ingresoNuevaCita(2, trama).then(
            data => {
                if (data.error.codigo !== '0') {
                    setDesactivar(false);
                    displayMessage.error('No se puedo editar la cita');
                } else {
                    displayMessage.success('Se edito la cita.');
                    window.location.href = `/agendarCitas`;
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const ingresoAgendamientoSms = async trama => {
        await appoinment.ingresoNuevaCita(2, trama).then(
            data => {
                if (data.error.codigo !== '0') {
                    setDesactivar(false);
                    displayMessage.error('No se puedo editar la cita');
                } else {
                    displayMessage.success('Se edito la cita.');
                    setOpenDialog(true);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const handleEnviarMensaje = () => {
        let estadoCita = '';
        let correos = emailPaciente;
        if (`${agendado}` === formState.estatus) {
            estadoCita = 'AGENDADA';
            correos = `${emailPaciente},${correoPrestador}`;
        }
        if (`${cancelado}` === formState.estatus) {
            estadoCita = 'CANCELADA';
        }
        if (`${pendiente}` === formState.estatus) {
            estadoCita = 'PENDIENTE COMUNICACIÓN';
        }
        let contenido = '';
        let sms = '';
        if (agendamiento === 'LABORA') {
            contenido = `<p><b>Cita ${estadoCita}</b></p>
            <p><b>Codigo cita ${props.datos[0].ageNemonico}</b></p>
            <p><b>PRESTADOR</b>:${nombrePrestador}</p>
            <p><b>FECHA</b>:${moment(fechaCita).format('DD/MM/YYYY')}</p>
            <p><b>HORA</b>:${moment(selectedDate).format('HH:mm:ss')}</p>
            <p><b>OBSERVACIONES</b>:${formState.observacionesAsistencia}</p>`;
            sms = `Cita ${estadoCita}
            \nCodigo cita ${props.datos[0].ageNemonico}
            \nPRESTADOR:${nombrePrestador}
            \nFECHA:${moment(fechaCita).format('DD/MM/YYYY')}
            \nHORA:${moment(selectedDate).format('HH:mm:ss')}
            \nOBSERVACIONES:${formState.observacionesAsistencia}`;
        } else {
            contenido = `<p><b>Cita ${estadoCita}</b></p>
            <p><b>Codigo cita ${props.datos[0].ageNemonico}</b></p>
            <p><b>NOMBRE PACIENTE</b>:${namePaciente} ${lastNamePaciente}</p>
            <p><b>ESPECIALIDAD</b>:${nombreEspecialidad}</p>
            <p><b>PRESTADOR</b>:${nombrePrestador}</p>
            <p><b>MEDICO TRATANTE</b>:${formState.nombreMedico}</p>
            <p><b>FECHA</b>:${moment(fechaCita).format('DD/MM/YYYY')}</p>
            <p><b>HORA</b>:${moment(selectedDate).format('HH:mm:ss')}</p>
            <p><b>OBSERVACIONES</b>:${formState.observacionesAsistencia}</p>`;
            sms = `Cita ${estadoCita}
            \nCodigo cita ${props.datos[0].ageNemonico}
            \nNOMBRE PACIENTE:${namePaciente} ${lastNamePaciente}
            \nESPECIALIDAD:${nombreEspecialidad}
            \nPRESTADOR:${nombrePrestador}
            \nMEDICO TRATANTE:${formState.nombreMedico}
            \nFECHA:${moment(fechaCita).format('DD/MM/YYYY')}
            \nHORA:${moment(selectedDate).format('HH:mm:ss')}
            OBSERVACIONES:${formState.observacionesAsistencia}`;
        }

        const mensaje = {
            emaContenido: contenido,
            emaDestinatiario: correos,
            //emaCopia: 'andres.alvarez@hotmail.es',
            emaAsunto: 'Cita médica',
            emaTelefono: `+593${telefono}`,
            /* emaSms: sms, */
            plsmCodigo: 13
        };

        appoinment.envioMensaje(mensaje).then(
            data => {
                if (data.error.codigo !== '0') {
                    setDesactivar(false);
                    displayMessage.error('No se pudo enviar la notificación');
                } else {
                    displayMessage.success('Se envio la notificación.');
                    window.location.href = `/agendarCitas`;
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const ingresoAgendamientoConArchivos = async trama => {
        //setOpenConfirmation(false);
        let ageCodigo = '';
        await appoinment.ingresoNuevaCita(2, trama).then(
            data => {
                if (data.error.codigo !== '0') {
                    displayMessage.error('No se pudo editar la cita');
                } else {
                    ageCodigo = data.bodyOut.resultados[0].ageCodigo;
                    displayMessage.success('Se edito la cita.');
                    //
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
        for (let index = 0; index < file.length; index++) {
            await appoinment
                .agedamientoDocuentos(
                    props.datos[0].ageCodigo,
                    tipoDoc,
                    [file[index]],
                    descripcionDocumento
                )
                .then(
                    async response => {
                        if (response.error.codigo === '0') {
                            //setDesactivar(false);

                            if (index === file.length - 1) {
                                displayMessage.success(
                                    'Archivo subido correctamente'
                                );
                                window.location.href = `/agendarCitas`;
                            }
                        } else {
                            setDesactivar(false);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                        setDesactivar(false);
                    }
                );
        }
    };

    ////CIUDADES
    const handleChangeText = event => {
        let search = event.target.value;
        if (search.length >= 3) {
            citys(search);
        } else {
            setCiudades([]);
        }
    };

    const handleBlur = event => {
        if (event.target.value === '') {
            setCiudad('');
        }
    };

    const handleChangeCiudad = (event, values) => {
        if (values) {
            setCiudad(values.locCodigo);
            setTxtCiudad('');
        } else {
            setCiudad('');
        }
    };

    const handleCloseCiudades = () => {
        setCiudades([]);
    };
    /////

    ///Estaddos
    const handleChangeEstados = () => {
        setOpenStatus(true);
    };
    ///

    //SUBIR ARCHIVOS
    const handleOpenSubir = () => {
        setOpenUpload(true);
    };

    const handleCloseSubir = () => {
        setOpenUpload(false);
    };

    const handleFiles = file => {
        setFile(file);
        file.map(item => {
            setNameFile(item.name);
        });
        setOpenUpload(false);
    };

    const handleFilesDescripcion = descripcion => {
        setDescripcionDocumento(descripcion);
    };

    //

    //Descarga
    const getDocumentosDownload = docCodigo => {
        setLoading(true);
        appoinment.descargarDocumentoAgendamiento(docCodigo).then(
            async response => {
                if (
                    typeof response[0].documento !== 'undefined' &&
                    response[0].documento !== null
                ) {
                    var path = `data:application/octet-stream;base64,${response[0].documento}`;
                    var link = await document.createElement('a');
                    await link.setAttribute('href', path);
                    await link.setAttribute('download', response[0].docNombre);
                    await link.setAttribute('target', '_blank');
                    await document.body.appendChild(link);
                    await link.click();
                    await document.body.removeChild(link);
                    setLoading(false);
                } else {
                    displayMessage.error(
                        'Ha ocurrido un error, es probable que el archivo no se encuentre en la base.'
                    );
                    setLoading(false);
                }
            },
            () => {
                displayMessage.error('Error de conexión');
            }
        );
    };
    //

    const handleChangeEmail = e => {
        setEmailPaciente(e.target.value);
    };

    const handleCloseMensaje = () => {
        window.location.href = `/agendarCitas`;
    };
    return (
        <div>
            <ModalSearchAfiliate
                open={openModal}
                close={handleClose}
                datos={getDataAfiliate}
            />
            <ModalPrestador
                open={openModalPrestador}
                close={handleClose}
                datos={getDataLeader}
            />
            <ModalStatus
                open={openStatus}
                close={handleClose}
                estados={props.datos[0].estado}
            />
            <ModalUpload
                open={openUpload}
                close={handleCloseSubir}
                dataFiles={data => handleFiles(data)}
                dataDescripcion={data => handleFilesDescripcion(data)}
            />
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                open={openDialog}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <br />
                        ¿Enviar notificación?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleCloseMensaje}>
                        no
                    </Button>
                    <Button
                        autoFocus
                        color="primary"
                        onClick={handleEnviarMensaje}>
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Button
                        component={RouterLink}
                        to={'/agendarCitas'}
                        color="primary"
                        variant="contained"
                        size="small">
                        <KeyboardReturn />
                        REgresar
                    </Button>
                </Grid>
            </Grid>

            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                disabled={typeCli}
                                //fullWidth
                                style={{ width: 375 }}
                                select
                                variant="outlined"
                                label="Tipo de agendamiento"
                                name="agendamiento"
                                value={agendamiento}
                                onChange={handleChangeTipoAgenda}
                                SelectProps={{
                                    native: true
                                }}
                                //helperText="Please select your currency"
                            >
                                <option value="Selec">Seleccione.....</option>
                                {tipoAgendamiento.map(option => (
                                    <option
                                        key={option.detNemonico}
                                        value={option.detNemonico}>
                                        {option.detNombre}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ textAlign: 'right' }}>
                            {session.user.role !== 'CLIENTE' && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={handleChangeEstados}>
                                    <ListAlt />
                                    Ver estados
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                {activarTitular && (
                    <>
                        <CardHeader title="Datos titular" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        disabled={typeCli}
                                        onClick={handleOpenModal}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        <Search /> Buscar afiliado
                                    </Button>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaTitular}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        variant="outlined"
                                        value={`${nameTitular} ${lastNameTitular}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Plan"
                                        variant="outlined"
                                        value={plan}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Preexistencia"
                                        variant="outlined"
                                        value={preexistencia}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </>
                )}
                {nameTitular.length > 0 ? (
                    <>
                        <CardHeader title="Datos paciente" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={6} xm={12}>
                                    <Autocomplete
                                        disabled={typeCli}
                                        getOptionLabel={option =>
                                            option.perIdentificacion +
                                            ' - ' +
                                            option.perNombres +
                                            '  ' +
                                            option.perApellidos +
                                            ' - ' +
                                            option.nombre2
                                        }
                                        onChange={(e, value) =>
                                            handleOnChangePaciente(value)
                                        }
                                        onClose={handleClosePaciente}
                                        options={dataPaciente}
                                        //value={null}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorPaciente}
                                                fullWidth
                                                helperText={
                                                    errorPaciente &&
                                                    'Seleccione un paciente'
                                                }
                                                label="Buscar Paciente"
                                                margin="none"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                        //style={{ width: 300, paddingBlockEnd: 20 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} />
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombres"
                                        variant="outlined"
                                        value={`${namePaciente} ${lastNamePaciente}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                {/*  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Apellidos"
                                        variant="outlined"
                                        value={lastNamePaciente}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                </Grid> */}
                                <Grid item md={1} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Edad"
                                        variant="outlined"
                                        value={edadPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Correo"
                                        variant="outlined"
                                        value={emailPaciente}
                                        InputProps={{
                                            readOnly: typeCli
                                        }}
                                        onChange={handleChangeEmail}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        name="telefono"
                                        variant="outlined"
                                        InputProps={{
                                            inputComponent: NumberFormatMoney,
                                            readOnly: typeCli,
                                            inputProps: {
                                                style: { textAlign: 'right' },
                                                decimalScale: 0
                                            }
                                        }}
                                        value={telefono}
                                        onChange={handleChangeTelefono}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        disabled={typeCli}
                                        getOptionLabel={option =>
                                            option.locNombre
                                        }
                                        noOptionsText=""
                                        onBlur={handleBlur}
                                        onChange={handleChangeCiudad}
                                        onClose={handleCloseCiudades}
                                        options={ciudades}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorCiudad}
                                                helperText={
                                                    errorCiudad
                                                        ? 'Seleccione ciudad'
                                                        : ''
                                                }
                                                label={
                                                    txtCiudad.length > 0
                                                        ? txtCiudad
                                                        : 'Ciudad'
                                                }
                                                margin="normal"
                                                onChange={handleChangeText}
                                                placeholder="Buscar..."
                                                style={{ marginTop: '0px' }}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                {agendamiento !== 'LABORA' ? (
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            disabled={typeCli}
                                            multiline
                                            fullWidth
                                            error={errorSintomas}
                                            helperText={
                                                errorSintomas &&
                                                'Ingrese sintomas'
                                            }
                                            label={
                                                agendamiento === 'CIRPRO' ||
                                                agendamiento === 'LABORA'
                                                    ? 'Diagnostico'
                                                    : 'Sintomas'
                                            }
                                            variant="outlined"
                                            name="sintomas"
                                            value={formState.sintomas || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                ) : null}
                                {/* <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Observaciones paciente"
                                        variant="outlined"
                                        name="observacionesPaciente"
                                        value={formState.observacionesPaciente || ''}
                                        onChange={handleChange}


                                    />
                                </Grid> */}
                            </Grid>
                        </CardContent>

                        {agendamiento === 'LABORA' ? (
                            <>
                                <CardHeader title="Datos Auditoria" />
                                <Divider />

                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Examenes autorizados"
                                                variant="outlined"
                                                name="examenes"
                                                value={formState.examenes || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Diagnostico"
                                                variant="outlined"
                                                name="diagnostico"
                                                value={
                                                    formState.diagnostico || ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Indicaciones adicionales"
                                                variant="outlined"
                                                name="indicaciones"
                                                value={
                                                    formState.indicaciones || ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DatePicker
                                                disabled={typeUser}
                                                format="DD/MM/YYYY"
                                                inputVariant="outlined"
                                                label="Fecha de aprobación"
                                                onChange={setFechaAprovacion}
                                                value={fechaAprovacion}
                                                //views={['month']}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        ) : (
                            ''
                        )}
                        {agendamiento === 'CIRPRO' && (
                            <>
                                <CardHeader title="Observaciones médico" />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Observaciones medico"
                                                variant="outlined"
                                                name="observacionesMedico"
                                                value={
                                                    formState.observacionesMedico ||
                                                    ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {/* <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    inputComponent: NumberFormatMoney,
                                                    readOnly: typeCli,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto cobertura"
                                                variant="outlined"
                                                name="montoCobertura"
                                                value={montoCobertura}
                                                onChange={handleChangeMonto}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </CardContent>
                            </>
                        )}
                        {agendamiento === 'HOSPIT' && (
                            <>
                                <CardHeader title="Observaciones médico" />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Observaciones medico"
                                                variant="outlined"
                                                name="observacionesMedico"
                                                value={
                                                    formState.observacionesMedico ||
                                                    ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        )}
                        <CardHeader title="Datos prestador" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        disabled={typeCli}
                                        onClick={handleOpenModalPrestador}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        <Search /> Buscar prestador
                                    </Button>
                                </Grid>
                                <Grid item md={10} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Prestador"
                                        variant="outlined"
                                        value={nombrePrestador || ''}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre médico"
                                        variant="outlined"
                                        name="nombreMedico"
                                        value={formState.nombreMedico || ''}
                                        onChange={handleChange}
                                        InputProps={{
                                            readOnly: typeCli
                                        }}
                                    />
                                </Grid>
                                {agendamiento !== 'LABORA' ? (
                                    <Grid item md={4} xs={12}>
                                        <Autocomplete
                                            disabled={typeCli}
                                            getOptionLabel={option =>
                                                option.detNombre
                                            }
                                            onChange={(e, value) =>
                                                handleOnChangeEspecialidad(
                                                    value
                                                )
                                            }
                                            options={especialidades}
                                            //value={null}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    //error={this.state.errorPaciente}
                                                    fullWidth
                                                    //helperText={this.state.errorPaciente && 'Campo obligatorio'}
                                                    label={
                                                        txtEspecialidad.length >
                                                        0
                                                            ? txtEspecialidad
                                                            : 'Buscar especialidad'
                                                    }
                                                    margin="none"
                                                    variant="outlined"
                                                    //required
                                                />
                                            )}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </CardContent>
                        <CardHeader title="Detalle cita" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    <DatePicker
                                        disabled={typeCli}
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        label="Fecha de la cita"
                                        onChange={setFechaCita}
                                        value={fechaCita}
                                        //views={['month']}
                                    />
                                </Grid>

                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    {agendamiento === 'LABORA' ? (
                                        <KeyboardTimePicker
                                            disabled={typeCli}
                                            margin="dense"
                                            ampm={false}
                                            invalidDateMessage="Hora inválida"
                                            id="time-picker"
                                            label="Hora"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time'
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                        />
                                    ) : (
                                        <KeyboardTimePicker
                                            disabled={typeCli}
                                            margin="dense"
                                            ampm={false}
                                            invalidDateMessage="Hora inválida"
                                            id="time-picker"
                                            label="Hora"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time'
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                        />
                                    )}
                                </Grid>

                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Notificación"
                                        name="notificacion"
                                        value={formState.notificacion || ''}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled={typeCli}
                                        //helperText="Please select your currency"
                                    >
                                        <option value="">Seleccione... </option>
                                        <option value="1">SI </option>
                                        <option value="0">NO </option>
                                    </TextField>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        InputProps={{
                                            readOnly: typeCli
                                        }}
                                        label={
                                            agendamiento === 'LABORA'
                                                ? 'Observaciones'
                                                : 'Observaciones asistencia'
                                        }
                                        variant="outlined"
                                        name="observacionesAsistencia"
                                        value={
                                            formState.observacionesAsistencia ||
                                            ''
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {(agendamiento === 'CIRPRO' ||
                                    agendamiento === 'HOSPIT') && (
                                    <>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto facturado"
                                                variant="outlined"
                                                name="montoFacturado"
                                                value={montoFacturado || ''}
                                                onChange={
                                                    handleChangeMontoFacturado
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto no cubierto"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoNoCubierto || ''}
                                                onChange={
                                                    handleChangeMontoNoCubierto
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto cubierto"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoCubierto || ''}
                                                onChange={
                                                    handleChangeMontoCubierto
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Copago"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={copago || ''}
                                                onChange={handleChangeCopago}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Valor copago"
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                name="costo"
                                                value={costo || ''}
                                                onChange={handleChangeCosto}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Deducible"
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                //name="costo"
                                                value={deducible || ''}
                                                onChange={handleChangeDeducible}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto a pagar MEDI"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoCobertura || ''}
                                                onChange={handleChangeMonto}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {/* {agendamiento === 'LABORA' ? (
                                    <Grid item md={2} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Costo"
                                            InputProps={{
                                                inputComponent: NumberFormatMoney,
                                                readOnly: typeCli,
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'right'
                                                    },
                                                    decimalScale: 2
                                                }
                                            }}
                                            variant="outlined"
                                            name="costo"
                                            value={costo || ''}
                                            onChange={handleChangeCosto}
                                        />
                                    </Grid>
                                ) : (
                                    ''
                                )} */}
                                {agendamiento === 'ODONTO' && (
                                    <>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Costo"
                                                InputProps={{
                                                    inputComponent: NumberFormatMoney,
                                                    readOnly: typeCli,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                name="costo"
                                                value={costo || ''}
                                                onChange={
                                                    handleChangeCostoOdonto
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                error={errorMontoDisponible}
                                                helperText={
                                                    errorMontoDisponible &&
                                                    'Excedió el monto disponible'
                                                }
                                                fullWidth
                                                label="Monto disponible"
                                                InputProps={{
                                                    inputComponent: NumberFormatMoney,
                                                    readOnly: true,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                name="monto"
                                                value={monstoDisponible || 0}
                                                //onChange={handleChangeCosto}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item md={10} xs={12}></Grid>
                                {(agendamiento === 'LABORA' ||
                                    agendamiento === 'CIRPRO') && (
                                    <Grid item md={10} xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: 10 }}
                                            onClick={handleOpenSubir}
                                            size="small">
                                            <CloudUpload /> Subir archivos
                                        </Button>
                                        {file !== null
                                            ? file.map((item, index) => {
                                                  return (
                                                      <Typography key={index}>
                                                          {item.name}
                                                      </Typography>
                                                  );
                                              })
                                            : ''}
                                    </Grid>
                                )}
                                <Grid item md={10} xs={12}>
                                    {dataDocumento ? (
                                        dataDocumento.length > 0 ? (
                                            <TableContainer component={Paper}>
                                                <Table
                                                    aria-label="a dense table"
                                                    //className={classes.table}
                                                    size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">
                                                                Documentos
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Descripción
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Descargar
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {dataDocumento.map(
                                                            row => (
                                                                <TableRow
                                                                    key={
                                                                        row.docCodigo
                                                                    }>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        style={{
                                                                            borderRight:
                                                                                '1px solid #eeeeee',
                                                                            width:
                                                                                '25%'
                                                                        }}>
                                                                        {
                                                                            row.docNombre
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                        style={{
                                                                            borderRight:
                                                                                '1px solid #eeeeee',
                                                                            width:
                                                                                '25%'
                                                                        }}>
                                                                        {
                                                                            row.docDescripcion
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{
                                                                            width:
                                                                                '1%'
                                                                        }}>
                                                                        {!loading && (
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    getDocumentosDownload(
                                                                                        row.docCodigo
                                                                                    )
                                                                                }
                                                                                size="small"
                                                                                variant="contained">
                                                                                <GetAppIcon />
                                                                            </Button>
                                                                        )}
                                                                        {loading && (
                                                                            <CircularProgress />
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            ''
                                        )
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardHeader title="Estado agendamiento" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    //className={classes.item}
                                    item
                                    md={4}
                                    xs={12}>
                                    <TextField
                                        disabled={typeCli}
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Estatus"
                                        name="estatus"
                                        value={formState.estatus}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true
                                        }}
                                        //helperText="Please select your currency"
                                    >
                                        <option value="sel">
                                            Seleccione...
                                        </option>
                                        {estados.map(option => (
                                            <option
                                                key={option.detCodigo}
                                                value={option.detCodigo}>
                                                {option.detNombre}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        disabled={typeCli}
                                        multiline
                                        fullWidth
                                        label={
                                            agendamiento === 'LABORA'
                                                ? 'Observaciones agendador'
                                                : 'Observaciones(Paciente)'
                                        }
                                        variant="outlined"
                                        name="observacionesAgendamiento"
                                        value={
                                            formState.observacionesAgendamiento ||
                                            ''
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        disabled={desactivar}
                                        color="primary"
                                        style={{ marginRight: 10 }}
                                        onClick={handleSave}
                                        size="small">
                                        <Save /> Guardar
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <Schedule /> Agendar
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </CardActions>
                    </>
                ) : (
                    ''
                )}
            </Card>
        </div>
    );
}
