import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { RefundFormProvider } from 'providers/refundForm.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {Cancel} from '@material-ui/icons'
import { CircularProgress, Card, CardHeader,  CardActions, Grid, TextField, Button, IconButton, Dialog} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchAffiliate(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const refunFormProvider = new RefundFormProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Cedula', field: 'perIdentificacion' },
        { title: 'Apellido', field: 'perApellidos' },
        { title: 'Nombre', field: 'perNombres' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');
    const [buscarApellido, setBuscarApellido] = useState('');
    const [buscarNombre, setBuscarNombre] = useState('');

    const handleBuscarTitular= ()=>{
        setData(0);

        if(buscarTitular === '' && buscarNombre === '' && buscarApellido === ''){
            displayMessage.error('Ingrese un campo a buscar')
            setData([])
        }else{
            refunFormProvider.buscarTItular(buscarTitular,buscarApellido,buscarNombre)
                .then(
                    data => {
                        if(data.bodyOut.length === 0){
                            displayMessage.error('Afiliado no encontrado.');
                            setData([])
                        }else{
                            displayMessage.success('Afiliado encontrado')
                            setData(data.bodyOut.data)
                        }
                    },
                    () => {          
                        displayMessage.error('Error de comunicación')

                    }

                );
        }
    }
    const handleChange = (e)=>{
        setBuscarTitular(e.target.value);
    }

    const handleChangeNombre = (e)=>{
        setBuscarNombre(e.target.value);
    }

    const handleChangeApellido = (e)=>{
        setBuscarApellido(e.target.value);
    }

    const handleLimpiar= ()=>{
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    const handleData = (e) => {
        props.datos(e)
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                    ><Cancel/></IconButton>}
                    title='Buscar Afiliado'
                />
                <CardActions>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    fullWidth
                                    name="buscarTitular"
                                    onChange={handleChange}
                                    placeholder="Buscar por cédula"
                                    value={buscarTitular}
                                />
            
       
         
                            </Grid>
                            <Grid
        
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >
           
                                <TextField
                                    fullWidth
                                    name="buscarApellido"
                                    onChange={handleChangeApellido}
                                    placeholder="Buscar por apellido"
                                    value={buscarApellido}
                                />
            
       
         
                            </Grid>
                            <Grid
        
                                item
                                md={4}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    fullWidth
                                    name="buscarNombre"
                                    onChange={handleChangeNombre}
                                    placeholder="Buscar por nombre"
                                    value={buscarNombre}
                                />
          
       
         
                            </Grid>
                            <Grid
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleBuscarTitular}
                                    variant="contained"
                                >Buscar</Button>
                
                  
                            </Grid>
                            <Grid
                 
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleLimpiar}
                                    variant="contained"
                                >Limpiar</Button>
                
                  
                            </Grid>
                        </Grid>
                    </div>
          
                </CardActions>
            
            {data ?
                data.length ?
                        <MaterialTable
                            actions={[
                                {
                                    icon: 'check',
                                    tooltip: 'Seleccionar',
                                    onClick: (event, rowData) => {handleData(rowData)
                                        handleClose()
                                    }
                                }
                            ]}
                            columns={columns}
                            data={data}
                            localization={{
                                body:{
                                    emptyDataSourceMessage:'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination:{
                                    labelRowsSelect:'Filas',
                                    labelDisplayedRows:'{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'
          

                                }
                            }}
                            options={{exportButton: false, search:false, toolbar: false}}
                            title="CIE10"
                        />: ''
                :<CircularProgress/>}
                </Card><br/>
        </Dialog>
    );
}