import { FormularioProvider } from './../../providers/formularios.service';

const CreateDoc=async(code,name,liqCodigo,detTipo)=>{
 

    return {
        detCodigo: code,
        detNombre: name,
        digitalizar: await isDigitalized(liqCodigo,detTipo)
    }   
}

 async function isDigitalized(liqCodigo, detTipo) {
    const fP = new FormularioProvider();
    let toReturn =  0;
    await fP.findForm(liqCodigo, detTipo).then( 
        (res) => {
            

            if(Array.isArray(res) && res.length>0){
                toReturn = 2;
            }else{
                toReturn = 1;
            }
        }).catch(err=>{
        toReturn = 1
    });
    return toReturn;
}
const returnForm = async(detTipo,liqCodigo, detTipoDigitalizado)=> {
    switch (detTipo) {
        case 9:
            return await CreateDoc(1, 'DIGITALIZAR FORMULARIO DE REMBOLSO',liqCodigo, detTipoDigitalizado);
        default:
            return null;


    }
}





export const forms =async(detTipo, docs, liqCodigo, detTipoDigitalizado) =>{
    
    // const documents = [];//docs;
    

    const newDocs = typeof(docs) === 'undefined'? [] :docs;
    if(returnForm(detTipo, liqCodigo, detTipoDigitalizado) !==null){
        newDocs.push(await returnForm(detTipo,liqCodigo, detTipoDigitalizado));
    }
    // documents.concat(getDocs);
    
    return newDocs;
}