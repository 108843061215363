import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CircularProgress,
} from '@material-ui/core';
import './styles.css';

import { ReportsProvider } from "providers/reports.service";
//import { DisplayMessage } from "notifications/displayMessage";

//import getInitials from 'utils/getInitials';



class Application extends React.Component {
  constructor(props){
    super(props);
    this.state={
      detCodigo:'',
      detNombre:'',
      detOrigen:''
    }
  }

  onClose=(e)=>{

    this.props.close && this.props.close(e);
  }

  componentDidMount(){
    const {reporte} = this.props;
    const report = new ReportsProvider();
    
    //const displayMessage = new DisplayMessage();

    report.reportesModal(reporte)
      .then(
        data => {
          this.setState({reportes:data.bodyOut.data});
        }
        

      );
  }
 
  render(){
  return (

    <div className="">
    <Dialog
      maxWidth="xl"
      onClose={this.props.close}
      open={this.props.open}
    >
      <Card>
        <div className="">
        {this.props.close ? (
        <IconButton aria-label="close" className="" onClick={(e)=>this.onClose(e)}>
          <CloseIcon />
        </IconButton>
      ) : null}
        <CardContent className="">
          <PerfectScrollbar>
            <div className="inner">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaccion ID</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Descripcion</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {this.state.reportes ? this.state.reportes.map((busca,index)=>
                    <TableRow key={index}>
                     
                      <TableCell>
                        {busca.liqCodigo}
                      </TableCell>
                      <TableCell>{busca.perFechaRegistro}</TableCell>
                      <TableCell>
                        {busca.plaNombre}
                      </TableCell>
                    </TableRow>
                    ): <TableRow><TableCell><CircularProgress/></TableCell></TableRow>}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        </div>
      </Card>
    </Dialog>
    </div>
  )};
};

Application.propTypes = {
  //author: PropTypes.object.isRequired,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Application;
