import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';


export class EmpresaProvider {

    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()


        this.person = null
    }

    findCompanyByTipoNemonico = tipoNemonico => this.basicQuery(1,{tipoNemonico: tipoNemonico}, '0101000');

    findCompanyByEmpCodigo = empCodigo => this.basicQuery(1,{empCodigo: empCodigo}, '0101000');

    basicQuery = (typeQuery, parameters, typeTransaction) => {

        const body = {
            tipoConsulta: typeQuery,
            entidad: 'Empresa',
            parametros: parameters
        };

        return this.dataService.post('/consulta', body, typeTransaction, 'james').then( response =>  response.bodyOut.data );
    }
    allCompanies = ()=>{
        const dS = new DataService();

        const body = {
            tipoConsulta: 1,
            entidad: 'Empresa',
            parametros: {
            }
        };

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            return response.bodyOut.data;  
        } 
        );
        return response;
    }

    allCompaniesComerc = ()=>{
        const dS = new DataService();
        
        const body = {
            
            tipoConsulta: 1,
            entidad: 'Empresa',
            parametros: {
                'tipoNemonico': 'COMERC'
  
            }
        };

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            return response.bodyOut.data;

        }
        );

        return response;
    }


    findPerson = (identificacion)=>{
        const dS = new DataService();

        const body={
            'tipoConsulta': 1,
            'entidad': 'Persona',
            'parametros': {
                'perIdentificacion': identificacion
            }
        }

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, null, 'james').then( response => {

            return response.bodyOut.data;

        }
        );


        return response;

    }

    getPerson(){
        return this.person;
    }

    login(username, password) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Usuario',
            parametros: {
                user: username,
                password: password
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101001', username);
    }

    getUser(){
        return this.storageService.getSession()
    }

    logout() {
        this.storageService.removeSession();
    }

    isAuthenticated() {
        let user = JSON.parse(localStorage.getItem('user'))
        return user && user.role
    }

}
