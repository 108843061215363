import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';

import gradients from 'utils/gradients';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    details: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    label: {
        marginLeft: theme.spacing(1)
    },
    avatar: {
        backgroundImage: gradients.blue,
        height: 48,
        width: 48
    }
}));

const CardAudit = props => {
    const { className, ...rest } = props;

    const classes = useStyles();


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div>
                <Typography
                    component="h2"
                    gutterBottom
                    variant="overline"
                >
                    <b>Auditorias</b>
                </Typography>
                <div className={classes.details}>
                    <Typography variant="h3">
                        {rest.count}
                    </Typography>          
                </div>
            </div>
            <Avatar className={classes.avatar}>
                <PageviewIcon />
            </Avatar>
        </Card>
    );
};

CardAudit.propTypes = {
    className: PropTypes.string
};

export default CardAudit;
