import HomeIcon from '@material-ui/icons/HomeOutlined';
import React from 'react';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FolderSharedIcon from '@material-ui/icons/FolderSharedOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
//import FindInPageIcon from '@material-ui/icons/FindInPageOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
//import HelpIcon from '@material-ui/icons/HelpOutlined';
import ZoomIn from '@material-ui/icons/ZoomIn';
import FeaturedPlayList from '@material-ui/icons/FeaturedPlayList';
//import MonetizationOn from '@material-ui/icons/MonetizationOn';
import BarChartIcon from '@material-ui/icons/BarChart';
import GroupAdd from '@material-ui/icons/GroupAdd';
import PaymentIcon from '@material-ui/icons/Payment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Receipt from '@material-ui/icons/Receipt';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import BorderColorIcon from '@material-ui/icons/BorderColor';

//import CreditCardIcon from '@material-ui/icons/CreditCard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Assessment from '@material-ui/icons/Assessment';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import Assignment from '@material-ui/icons/Assignment';
import Subtitles from '@material-ui/icons/Subtitles';
import PersonIcon from '@material-ui/icons/Person';
import SmsFailed from '@material-ui/icons/SmsFailed';
import {
    AccountBalanceWallet,
    Add,
    Cancel,
    Backup,
    EventAvailable,
    PersonAdd
} from '@material-ui/icons';
import ClassIcon from '@material-ui/icons/Class';
import NoteIcon from '@material-ui/icons/Note';
import FileCopy from '@material-ui/icons/FileCopy';
import CreditCardIcon from '@material-ui/icons/CreditCard';
export default [
    {
        pages: [
            {
                title: 'Inicio',
                href: '/',
                icon: HomeIcon,
                roles: [
                    'ADMIN',
                    'SUPER_ADMIN',
                    'CLIENTE',
                    'PRESTADOR',
                    'LIQUIDADOR',
                    'AUDITOR',
                    'CONTADOR',
                    'GERENCIAL'
                ]
            },
            {
                title: 'Registro de Beneficiarios',
                href: '/cliente/agregar/beneficiario',
                icon: GroupAdd,
                roles: ['CLIENTE']
            },

            {
                title: 'Formularios',
                href: '/cliente/formularios',
                icon: FolderSharedIcon,
                roles: ['CLIENTE']
            },
            {
                title: 'Ingreso de Solicitud de Reembolso',
                href: '/cliente/solicitud',
                icon: AccountBalanceWalletIcon,
                roles: ['CLIENTE']
            },
            {
                title: 'Documentos',
                href: '/cliente/documentos',
                icon: FileCopyIcon,
                roles: ['CLIENTE']
            },
            {
                title: 'Estado de liquidaciones',
                href: '/mensajes/inicio',
                icon: EmailIcon,
                roles: ['CLIENTE']
            },
            /*  {
                 title: 'Prestadores',
                 href: '/cliente/prestadores',
                 icon: BusinessCenter,
                 roles: ['CLIENTE']
             } , 
             {
                 title: 'Pagos',
                 href: '/cliente/pago-tarjeta',
                 icon: CreditCardIcon,
                 roles: [
                     'CLIENTE'
                 ]
             },

            {
                title: 'Mensajes',
                href: '/mensajes/inicio',
                icon: EmailIcon,
                roles: ['LIQUIDADOR']
            },
             */
            {
                title: 'Consultas',
                href: '/prestador/consultas',
                icon: ZoomIn,
                roles: ['LIQUIDADOR']
            },
            {
                title: 'Titular-Dependientes',
                href: '/prestador/titular',
                icon: PeopleAlt,
                roles: ['LIQUIDADOR', 'ADMIN']
            },
            {
                title: 'Titular-Dependientes cancelados',
                href: '/prestador/titular/cancelados',
                icon: PersonAddDisabledIcon,
                roles: ['CALLCENTER']
            },
            {
                title: 'Liquidaciones',
                href: '/liquidador/liquidaciones/digitalizar',
                icon: PaymentIcon,
                roles: ['LIQUIDADOR']
            },
            {
                title: 'Liquidaciones por verificar',
                href: '/liquidador/liquidaciones/verificar',
                icon: ReceiptOutlinedIcon,
                roles: ['LIQUIDADOR']
            },
            /* {
                title: 'Reporte',
                href: '/liquidador/reporte',
                icon: Assessment,
                roles: [
                    'LIQUIDADOR'
                ]
            }, */
            {
                title: 'Reporte Liquidaciones',
                href: '/liquidador/liquidaciones/reporte',
                icon: SmsFailed,
                roles: ['LIQUIDADOR', 'CPN', 'CALLCENTER']
            },
            {
                title: 'Afiliados',
                href: '/gerencia/clientes',
                icon: PeopleAlt,
                roles: ['LIQUIDADOR', 'AUDITOR']
            },
            {
                title: 'Consultas',
                href: '/prestador/consultas',
                icon: ZoomIn,
                roles: ['AUDITOR']
            },
            {
                title: 'Titular-Dependientes',
                href: '/prestador/titular',
                icon: PeopleAlt,
                roles: ['AUDITOR']
            },
            {
                title: 'Pendientes de Auditoría',
                href: '/auditor/liquidaciones/auditoria',
                icon: FeaturedPlayList,
                roles: ['AUDITOR']
            },
            {
                title: 'Pagos de Afiliados',
                href: '/pagos/afiliados',
                icon: PeopleAlt,
                roles: ['LIQUIDADOR', 'CALLCENTER']
            },
            {
                title: 'Reporte Access',
                href: '/gerencia/access',
                icon: Subtitles,
                roles: ['AUDITOR']
            },
            /* {
                title: 'Reporte',
                href: '/auditor/reporte',
                icon: Assessment,
                roles: [
                    'AUDITOR'
                ]
            },             */
            {
                title: 'Reporte Liquidaciones',
                href: '/liquidador/liquidaciones/reporte',
                icon: SmsFailed,
                roles: ['AUDITOR']
            },
            {
                title: 'Liquidaciones pendientes',
                href: '/contador/liquidaciones/porpagar',
                icon: FileCopyIcon,
                roles: ['CONTADOR']
            },
            {
                title: 'Liquidaciones pagadas',
                href: '/contador/liquidaciones/pagadas',
                icon: LibraryAddCheckIcon,
                roles: ['CONTADOR']
            },
            {
                title: 'Consolidar pagos',
                href: '/contador/consolidar-pagos',
                icon: AccountBalanceWalletIcon,
                roles: ['CONTADOR']
            },
            {
                title: 'Consolidar cobros',
                href: '/contador/consolidar-cobros',
                icon: ThumbUpAltIcon,
                roles: ['CONTADOR']
            },
            {
                title: 'Producción',
                href: '/contador/produccion',
                icon: Assignment,
                roles: ['CONTADOR']
            },
            {
                title: 'Titular-Dependientes',
                href: '/prestador/titular',
                icon: PeopleAlt,
                roles: ['GERENCIAL']
            },
            {
                title: 'Reportes Gráfico',
                href: '/gerencia/grafico',
                icon: BarChartIcon,
                roles: ['GERENCIAL']
            },
            {
                title: 'Tabla Reportes',
                href: '/gerencia/datos/reportes',
                icon: Receipt,
                roles: ['GERENCIAL']
            },
            {
                title: 'Afiliados',
                href: '/gerencia/clientes',
                icon: PeopleAlt,
                roles: ['GERENCIAL']
            },
            {
                title: 'Reporte Access',
                href: '/gerencia/access',
                icon: Subtitles,
                roles: ['GERENCIAL']
            },
            {
                title: 'Consultas',
                href: '/prestador/consultas',
                icon: ZoomIn,
                roles: ['PRESTADOR']
            },
            {
                title: 'Titular-Dependientes',
                href: '/prestador/titular',
                icon: PeopleAlt,
                roles: ['PRESTADOR']
            },
            {
                title: 'Consultas',
                href: '/prestador/consultas',
                icon: ZoomIn,
                roles: ['CALLCENTER']
            },
            {
                title: 'Afiliados',
                href: '/gerencia/clientes',
                icon: PeopleAlt,
                roles: ['CALLCENTER']
            },
            {
                title: 'Titular-Dependientes',
                href: '/prestador/titular',
                icon: PeopleAlt,
                roles: ['CALLCENTER']
            },
            /*  {
                 title: 'Reporte Liquidaciones',
                 href: '/liquidador/reporteLiquidaciones',
                 icon: SmsFailed,
                 roles: [
                     'CALLCENTER',
                 ]
             }, */
            /* {
                title: 'Crear Póliza',
                href: '/administrador/agregar-poliza',
                icon: EventAvailable,
                roles: ['CALLCENTER']
            }, */
            {
                title: 'Agendar cita',
                href: '/agendarCitas',
                icon: AssignmentIcon,
                roles: ['CALLCENTER', 'AUDITOR']
            },
            {
                title: 'Citas medicas',
                href: '/agendarCitas',
                icon: AssignmentIcon,
                roles: ['CLIENTE']
            },
            {
                title: 'Descargar Facturas',
                href: '/cliente/descargarFacturas',
                icon: FileCopyIcon,
                roles: ['CLIENTE']
            },
            {
                title: 'Afiliados',
                href: '/gerencia/clientes',
                icon: PeopleAlt,
                roles: ['ADMIN']
            },
            {
                title: 'Persona',
                href: '/administrador/persona',
                icon: PersonIcon,
                roles: ['ADMIN']
            },
            {
                title: 'Agregar prestadores',
                href: '/administrador/prestador',
                icon: Add,
                roles: ['ADMIN']
            },
            {
                title: 'Catálogos',
                href: '/administrador/detalle-catalogo',
                icon: ClassIcon,
                roles: ['ADMIN']
            },
            {
                title: 'Planes',
                href: '/plan',
                icon: NoteIcon,
                roles: ['ADMIN']
            },
            {
                title: 'Registrar pagos',
                href: '/registrar-pagos',
                icon: BorderColorIcon,
                roles: ['ADMIN', 'COBROS']
            },
            /*
            {
                title: 'Afiliados cancelados',
                href: '/afiliados_cancelados',
                icon: PersonAddDisabledIcon,
                roles: ['COBROS']
            },
            */
            {
                title: 'Usuarios',
                href: '/nuevo-usuario',
                icon: PersonAdd,
                roles: ['ADMIN']
            },
            {
                title: 'Registro de pagos anualizados y tarjetas de Crédito',
                href: '/cargar-datos',
                icon: FileCopy,
                roles: ['COBROS']
            },
            {
                title: 'Debito formato CPN',
                href: '/cargar-datos-CPN',
                icon: FileCopy,
                roles: ['COBROS']
            },
            {
                title: 'Crear pólizas masíva',
                href: '/crear-poliza-masiva',
                icon: Backup,
                roles: ['EMISION']
            },
            {
                title: '-------------------------------------------',
                href: '#',

                roles: ['ADMIN']
            },
            {
                title: 'Póliza',
                href: '/administrador/poliza',
                icon: InsertDriveFileIcon,
                roles: ['ADMIN', 'LIQUIDADOR']
            },
            {
                title: 'Reporte',
                href: '/administrador/reporte',
                icon: Assessment,
                roles: ['ADMIN']
            },
            {
                title: 'Pendientes por cobrar',
                href: '/contador/pendiente-pagar',
                icon: PaymentIcon,
                roles: ['ADMIN']
            },
            {
                title: 'Historial de Pagos',
                href: '/administrador/reporteLiquidaciones',
                icon: PaymentIcon,
                roles: ['ADMIN']
            },
            {
                title: 'Reporte Finanzas',
                href: '/vista',
                icon: FormatAlignJustifyIcon,
                roles: ['COBROS', 'ADMIN']
            },
            {
                title: 'Cancelar Pólizas',
                href: '/administrador/cancelar-polizas',
                icon: Cancel,
                roles: ['ADMIN']
            },
            {
                title: 'Pendientes por cobrar todos',
                href: '/contador/pendiente-pagar-todos',
                icon: PaymentIcon,
                roles: ['ADMIN']
            },
            /* {
                title: 'Reporte Liquidaciones',
                href: '/liquidador/reporteLiquidaciones',
                icon: SmsFailed,
                roles: [
                    'CPN'
                ]
            }, */
        ]
    }
];
