import React, {Component} from 'react';
import { FilesDropzone, Page } from 'components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {GenericHeader} from 'components';
import { DisplayMessage } from 'notifications/displayMessage';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DetalleCatalogoProvider } from './../../providers/detalleCatalogo.service';
//import { PersonAutocomplete } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import {Select, MenuItem, FormHelperText, FormControl, InputLabel} from '@material-ui/core'
import { connect } from 'react-redux';
import { RequestProvider  } from 'providers/request.service';
import { PersonalProvider  } from 'providers/persona.service';
import ModallSearch from './ModlalSearch/ModlalSearch'

import './styles.css';

import { TextField } from '@material-ui/core';
import { Assignment, ThreeSixtySharp, YouTube } from '@material-ui/icons';


class RequestView extends Component {
    constructor(props){
        super(props);
        const  { session } = props;
       
        this.state = {
            allForms: null,
            open: false, 
            openBuscador: false,
            form: null,
            files:[],
            loading: false,
            errorTipo: false,
            errorArchivos: false,
            errorArchivosSize: false,
            session: session,
            person: null,
            SearchPersonWord:'',
            allPersons:[],
            afiliado:'',
            countFiles: 0,
            catalogoAtencion: [],
            catalogoSel: null,
            catalogoSelError: false,
            personError: false,
            openVideo:false,
            openDocument:false,
            doc:''
        } 

        this.pP= new PersonalProvider();
        this.handleSearchPersonChange =this.handleSearchPersonChange.bind(this);     

    } 
    

  componentDidMount = () => {
      this.findAllRequests();
      this.findDetCatalogoAtencion();
      this.findDocuments()
  }

  findDetCatalogoAtencion = () => {
      if (this.state.session.user.role === 'LIQUIDADOR') {
          const displayMessage = new DisplayMessage();
          const detalleCatalogoProvider = new DetalleCatalogoProvider();
          detalleCatalogoProvider.findDetalleCatalogoByNemonicoGen('ATENMED').then((response) => {
              if(response.error.codigo === '0' && response.bodyOut.data.length){
                  this.setState({ catalogoAtencion:  response.bodyOut.data });
              }              
          },
          ()=>{
              displayMessage.error('Error de comunicación.');
          })
      }       
  }

  findAllPersons = ( search ) =>{

      this.pP.findPersonLikeName(search).then( 
          res =>{

              this.setState({allPersons: res})
          }
      );
      
  }
  setPerson = (value) => {
      this.setState({
          person:value
      });
  }

  handleClickOpen = (files) => {        
    
      let error = false;

      this.setState({
          errorTipo: false,
          errorArchivos: false
      });

      /**  if (!this.state.form){
          this.setState({
              errorTipo: true
          });
          error = true;
      }*/

      if (this.state.session.user.role === 'LIQUIDADOR') {
          if (!this.state.catalogoSel) {
              this.setState({ catalogoSelError: true });
              error = true;
          } else {
              this.setState({ catalogoSelError: false });
          }

          if (!this.state.person) {
              this.setState({ personError: true });
              error = true;
          } else {
              this.setState({ personError: false });
          }
      }      
     

      if(!files.length){
          this.setState({
              errorArchivos: true
          });
          error = true;
      }
      let totalSize = 0
      files.map(file =>{
          totalSize+= file.size;
          return 0;
      })

      const fileSize=((totalSize/1024)/1024);
        
      if (fileSize>100){
          this.setState({
              errorArchivosSize: true
          });
          error = true;
      }

      if (error){
          return;
      }    

      this.setState({
          open:true,
          files: files
      });


  };
  
  setForm = (value) =>{
      this.setState({
          form: value
      })
  }

  handleSearchPersonChange = (evt) => {
      const name = evt.target.name;
      const value = evt.target.value;
      this.setState({
          [name]:value
      });

      if(value.length >=5){
          this.findAllPersons(value);
      }
    

  }

  findAllRequests = () =>{
      const dCP = new DetalleCatalogoProvider();
      dCP.findDetalleCatalogo(9).then(
          (response) =>{
              if(typeof(response)!=='undefined'){
                  this.setState({
                      allForms: response
                  })
              }
              
          }      
      )
  }

  findDocuments = () =>{
    const dCP = new DetalleCatalogoProvider();
    dCP.findDetalleCatalogoByNemonicoGen1('DOCESP').then(
        (response) =>{
            if(typeof(response)!=='undefined'){
                this.setState({
                    doc: response.bodyOut.data[0].archivo
                })
            }
            
        }      
    )
}

  handleClose = () => {
      this.setState({
          open:false,
          openBuscador:false
      })
  };

  handleSend = () =>{
      this.setState({
          open:false,
          loading: true      
      });    
      const requestProvider = new RequestProvider();
      const displayMessage = new DisplayMessage();
      //this.state.session.user.role === 'LIQUIDADOR'
      

      const perBeneficiario = this.state.session.user.role === 'LIQUIDADOR'? this.state.person.perCodigo : this.state.session.user.perCodigo;
      const senPolCodigo = this.state.session.user.role === 'LIQUIDADOR'? this.state.person.polCodigo : this.state.session.user.polCodigo;
      const senDetCodigo = this.state.session.user.role === 'LIQUIDADOR'? 23520 : 17173;

      requestProvider.requestCreate(senDetCodigo, senPolCodigo, perBeneficiario, this.state.catalogoSel)
          .then(
              user => {                
                  if (user.error.codigo === '0'){      
                      const liqCodigo = user.bodyOut.data[0].liqCodigo
                      const files = this.state.files
                      files.map(async file => {
                          await requestProvider.requestAddFile(liqCodigo, [file])
                              .then(
                                  async response => {
                                      if (response.error.codigo === '0') { 
                                          this.setState({              
                                              countFiles: this.state.countFiles + 1
                                          });
                                          if(this.state.countFiles === this.state.files.length){
                                              displayMessage.success('Solicitud enviada con éxito.');                                          
                                              if(this.state.session.user.role === 'LIQUIDADOR'){
                                                  this.props.history.push('/liquidador/liquidaciones/digitalizar');
                                              }else{
                                                  this.props.history.push('/home');
                                              }
                                          }                                          
                                      }                                      
                                  },
                                  () => {          
                                      displayMessage.error('Error de comunicación.');    
                                      this.setState({              
                                          loading: false
                                      });            
                                  }
                              );
                          return 0;
                      });                      
                  } else {
                      displayMessage.error('Error de comunicación.');
                      this.setState({              
                          loading: false
                      });
                  }                      
              },
              () => {          
                  displayMessage.error('Error de comunicación.');    
                  this.setState({              
                      loading: false
                  });            
              }
          );   
  }     

  useStyles = makeStyles(theme => ({
      margin: {
          margin: theme.spacing(1),
      },
  }));

  handleOpenSearch = () => {
      this.setState({openBuscador:true})
  }
  datosModal = (dato1) => {
      this.setState({
          person:dato1,
          afiliado: dato1.perNombres+' '+dato1.perApellidos,
      });
  }


  render(){
      return(
          <Page 
              title="Solicitudes"
              className ='container-90'
          >
              <ModallSearch
                  close={this.handleClose}
                  datos={this.datosModal.bind(this)}
                  open={this.state.openBuscador}
              />
              <div className='container-90'>
                  <GenericHeader title='Solicitudes' />
                  <div className='sub-container'>
                      <Grid container spacing={3}> 
                          <Grid item xs={4}>
                              {/** 
                                  <Typography
                                      component="h1"
                                      variant="subtitle1"

                                  >
                                    Tipo de solicitud:
                                  </Typography>
                                */
                              }
                              
                          </Grid>
                          <Grid item xs={6} className='left'>
                              {/** this.state.allForms ?                       
                                  <PersonAutocomplete
                                      error={ this.state.errorTipo }
                                      setPerson = {this.setForm}
                                      selectedElement = { this.state.form }
                                      data = { this.state.allForms }
                                      fieldName = "option.detNombre"
                                      label = "Solicitud" 
                                      variant="outlined"
                                  />:
                                  <CircularProgress/>
                              */ }
                              <center>
                                  <Typography
                                      variant="h3" 
                                      gutterBottom
                                  >
                                        SOLICITUD DE REEMBOLSO
                                  </Typography>
                              </center>
                              

                          </Grid>
                          <Grid item xs={6} className='right'>
                          <Button onClick={() => this.setState({openVideo:true})} variant='contained' color='primary' size='small' startIcon={<YouTube color='red'/>}>
            GUÍA DE USUARIO</Button>
                          </Grid>
                          <Grid item xs={6} className='left'>
                              <Button onClick={() => this.setState({openDocument:true})} variant='contained' color='primary' size='small' startIcon={<Assignment color='red'/>}>
                                  Documentos
                              </Button>
                          </Grid>
                      </Grid>
                  </div>
                  <br/>

                  { 
                  }
                  {
                      this.state.session.user.role === 'LIQUIDADOR'? 
                          <div className='sub-container'>
                              <Grid container>  
                                  <Grid
                                      item
                                      md={4}
                                      xs={12}
                                  >            
                                      <FormControl 
                                            error={this.state.catalogoSelError}
                                            style={{ width: '100%' }}
                                            variant="outlined"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">Tipo de atención</InputLabel>
                                            <Select
                                                label="Tipo de atención"                                            
                                                onChange={(event) => {
                                                    this.setState({ catalogoSel: event.target.value })
                                                }}
                                                value={this.state.catalogoSel || ''}                                    
                                            >
                                                <MenuItem value={''}>
                                                    <em>Seleccione...</em>
                                                </MenuItem>
                                                {this.state.catalogoAtencion.map((item, index) => (
                                                    <MenuItem 
                                                        key={'catalogo' + index}
                                                        value={item.detCodigo}
                                                    >
                                                        {item.detNombre}
                                                    </MenuItem>
                                                ))}                                        
                                            </Select>
                                            {this.state.catalogoSelError ? (<FormHelperText>El tipo de atención es requerido</FormHelperText>): null}                                    
                                      </FormControl>                        
                                  </Grid>      
                                  <Grid
                                      item
                                      md={8}
                                      xs={12}
                                  />                                                                        
                                  <Grid item xs={6}>
                                      <br />
                                      <Button
                                          onClick = {this.handleOpenSearch}
                                          variant = "contained"
                                          color = "primary"
                                      >
                                          Buscar Afiliado
                                      </Button>
                                      {this.state.afiliado !== '' ?
                                          <TextField
                                              label ="Afiliado seleccionado"
                                              style={{marginLeft:30,width:300}}
                                              InputProps={{
                                                  readOnly: true,
                                              }}
                                              name="afiliado"
                                              placeholder="Afiliado"
                                              required
                                              value={this.state.afiliado}
                                              variant="standard"                           
                                          />
                                          :''}
                                      {/* <GenericSearch
                                          setValue={this.setPerson}
                                          selectedElement={this.state.person}
                                          data = {this.state.allPersons}
                                          fieldName = "option.perIdentificacion"
                                          fieldName2 = "option.perNombres"
                                          fieldName3 = "option.perApellidos"
                                          label = "Buscar..."
                                          searchWord={this.state.SearchPersonWord}
                                          handleInputChange={this.handleSearchPersonChange}
                                          nameInput='SearchPersonWord'
                                          methodSearchPerson={this.findAllPersons}
                                          //searchWord, handleInputChange
                                      /> */}
                                      { this.state.personError && <><br /><Alert severity="error">Seleccione un afiliado</Alert></>}
                                  </Grid>
                              </Grid>
                              <br />
                          </div>
                          :''
                         
                  }
                  <Alert severity="warning">
                      <Typography
                          component="h1"
                          variant="subtitle1"
                      >
                          <b>No olvides cargar todos los documentos en una sola liquidación.</b>
                      </Typography>                      
                  </Alert>
                  <br/>
                  { this.state.errorArchivos && <Alert severity="error">Seleccione archivos para la solicitud!</Alert>}
                  { this.state.errorArchivosSize && <Alert severity="error">Los archivos superan los 100 MB permitidos</Alert>} 

                  <FilesDropzone callback={this.handleClickOpen.bind(this)}></FilesDropzone>  

                  
                  <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="alert-dialog-title">{'Enviar'}</DialogTitle>
                      <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                              <b>¿Está seguro de crear una liquidación?</b>    
                              <br />
                              Por favor validar que la información se encuentre completa.
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={this.handleClose} color="primary">
                Cancelar
                          </Button>
                          <Button onClick={this.handleSend.bind(this)} color="primary" autoFocus>
                Si, Enviar
                          </Button>
                      </DialogActions>
                  </Dialog>          

                  <Dialog
                      open={this.state.loading}              
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
                  > 
                      <DialogContent style={{ textAlign: 'center' }}>  
                          <CircularProgress/>
                          <br/>
                          <b>Subiendo archivo(s)</b> ({this.state.countFiles}/{this.state.files.length})                          
                      </DialogContent>                       
                  </Dialog>
                  <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            open={this.state.openVideo}
            fullWidth        
            maxWidth={'md'}
            >
                <DialogContent>
                <iframe 
                width="100%" 
                height="480" 
                src="https://www.youtube.com/embed/iKm9PGAymHc" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
                </iframe>
                </DialogContent>
                <DialogActions>
                    <Button
                    color='primary'
                    variant='contained'
                    onClick={()=> {this.setState({openVideo:false})}}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            open={this.state.openDocument}
            fullWidth        
            maxWidth={'lg'}
            >
                <DialogContent>
                <embed 
                                height="500" 
                                src={'data:application/pdf;base64,'+this.state.doc}  
                                type="application/pdf" 
                                width="100%" 
                            />
                </DialogContent>
                <DialogActions>
                    <Button
                    color='primary'
                    variant='contained'
                    onClick={()=> {this.setState({openDocument:false})}}
                    >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
              </div>
          </Page>
      );
  }

} 

const mapStateToProps = state =>({ session: state.session});
export default connect(mapStateToProps,null) (RequestView);
