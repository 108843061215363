import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, IconButton, Tooltip, Typography, TextField, Grid, Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { RequestProvider } from './../../../../providers/request.service';
import { DisplayMessage } from 'notifications/displayMessage';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

/** 
LQ0000000347 */
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    textArea: {
       
        width: '100%'
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    sendButton: {
        marginRight: theme.spacing(2)
    },
    fileInput: {
        display: 'none'
    }
}));

const MessagesForm = props => {
    const { className, detTipo, getMessage, liqCodigo, polCodigo, tipoConsulta, usuCodigo, usuNombre,  usuUsuario, ...rest } = props;

    const classes = useStyles();

    const fileInputRef = useRef(null);  

    const [value, setValue ] = useState('');
    const [ file, setFile ] = useState([]);

    const [ nameFile, setNameFile ] = useState([]);
    const [ disabledSend, setDisabledSend ] = useState(false);
    const [ effect, setEffect ] = useState(false);

    useEffect(() => {
        setEffect(true);
    },[effect]);

    const handleChange = event => {
        event.persist();
        setValue(event.target.value);
        setEffect(false);

    };

    const sendMessage = async() => {
        await setDisabledSend(true)
        const eL= new RequestProvider();
        const displayMessage = new DisplayMessage();
        const { liqCodigo, usuCodigo, polCodigo, getMessage,  detTipo, tipoConsulta} = props;
        const estadoss = [
            {
                'eslMensaje': value,
                'usuCodigo': parseInt(usuCodigo),
            }];
        const filesToSend = file ? file :[];
        let acumFiles = 0;
        await Array.from(filesToSend).forEach( a => {
            acumFiles = acumFiles + a.size;
        });
        const maximunSize = 100;
        const fileSize = ((acumFiles/1024)/1024);
        if(estadoss[0].eslMensaje.trim()!=='' && fileSize <= maximunSize){

            await eL.requestUpdate(filesToSend, parseInt(detTipo), parseInt(liqCodigo), parseInt( polCodigo), estadoss, tipoConsulta ).then(
                response => {
                    if(response.error.codigo==='0'){
                        displayMessage.success('El mensaje ha sido enviado con exito');
                        getMessage(liqCodigo);
                        setValue('');
                    }else{
                        displayMessage.error(`ERROR: ${response.error.mensaje}`)
                        setDisabledSend(false)
                    }
                }
            ).catch(
                e => {
                    setDisabledSend(false)
                }
            )

        }else{
            if(estadoss[0].eslMensaje.trim() === ''){
                displayMessage.error('El mensaje es obligatorio');
            }
            if(fileSize > maximunSize){
                displayMessage.error(`LOS ARCHIVOS EN TOTAL PESAN ${fileSize.toFixed(2)}MB, TAMAÑO MAXIMO ACEPATO 100 MB`)

            }
            setDisabledSend(false)

        }
        setEffect(false);

    }

    const handleChangeFile = event => {
        const displayMessage= new DisplayMessage();
        event.persist();
        let acumFiles = 0;
        var namesArray = [];
        let filesAdded = [];
        Array.from(event.target.files).forEach(files => {
            filesAdded.push(files);
            namesArray.push(files.name);
        });

        let nameFiles = file === null ? [] : nameFile;
        nameFiles = nameFiles.concat(namesArray)

        let files = file === null ? [] : file;
            files = files.concat(filesAdded);

        Array.from(files).forEach(a => {
            acumFiles = acumFiles + a.size;
        })
        const fileSize=((acumFiles/1024)/1024)
        const maximunSize = 100;
        if(fileSize>maximunSize){
            displayMessage.error(`LOS ARCHIVOS EN TOTAL PESAN ${fileSize.toFixed(2)}MB, TAMAÑO MAXIMO ACEPATO 100 MB`)

        }else{
            setFile(files);
            setNameFile(nameFiles);
        }
        setEffect(false);
    };

    const handleAttach = () => {
        fileInputRef.current.click();
        setEffect(false);

    };

    /** ----------------------------------------Eliminar Archivo ------------------------ */
    const handleDeleteFile = (fileDelete, name) => {
        var i = file.indexOf(fileDelete);
        file.splice(i,1);

        var j = nameFile.indexOf(name);
        nameFile.splice(j,1);
        setEffect(false);
    }

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Paper
                className={classes.paper}
                elevation={1}
            >
                <Grid container>
                    <Grid item xs={9}>
                        <TextField
                            variant="outlined"
                            className={classes.textArea}
                            onChange={handleChange}
                            placeholder={props.placeholdermessage ? props.placeholdermessage: 'Escriba el mensaje'}
                            rows={6}
                            value={value}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Tooltip title="Adjuntar documentos faltantes">
                            <Button color="primary" onClick={handleAttach} startIcon={<AttachFileIcon />}>
                                <Typography variant="caption" gutterBottom>
                                    Adjuntar documentos faltantes
                                </Typography>
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={1}>
                        <div className={classes.wrapper}>
                            <IconButton color={value.length > 0 ? 'primary' : 'default'} onClick={sendMessage} disabled={disabledSend} >
                                <SendIcon />
                            </IconButton>
                            { disabledSend && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        {   Array.from(file).map( f => <Chip label={f.name} onDelete={ () => handleDeleteFile(f, f.name)} color="primary" /> ) }
                    </Grid>
                </Grid>


            </Paper>
            <input
                className={classes.fileInput}
                onChange={handleChangeFile}
                ref={fileInputRef}
                type="file"
                multiple={true}
            />
        </div>
    );
};

MessagesForm.propTypes = {
    className: PropTypes.string
};

export default MessagesForm;
