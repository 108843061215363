import React from 'react';
import { Form } from './components';
import { Page, GenericHeader } from 'components';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Typography, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    },
    color:{
        color: 'white'
    }
}));

const PlanAddView = () => {
    const classes = useStyles();

    return (  
        <Page 
            title = 'Nuevo Plan'>
            <div className={classes.root}>
                <GenericHeader title={'Nuevo Plan'} />
                <Form />
            </div>
            <Box
                bgcolor="primary.main"
                border={0}
                color="info.contrastText"
            >
                <IconButton
                    href="/plan"
                >
                    <ArrowBackIcon
                        className={classes.color}
                    />
                    <Typography
                        className={classes.color}
                    > REGRESAR A PLAN
                    </Typography>
                </IconButton>
            </Box>
        </Page>
    );
}

export default PlanAddView;