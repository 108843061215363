import { DataService } from 'providers/dataService';

export class AppointmentProvider {
    constructor() {
        this.dataService = new DataService();
    }

    comboEnfermedades(catCodigo, detNemonico, detDescripcion, detNombre) {
        let body = {
            maximoRegistros: 5,
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: catCodigo,
                detNemonico: detNemonico + '%',
                detDescripcion: detDescripcion + '%',
                detNombre: detNombre + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    constultaCatalogo(tipo, nombre) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catNemonico: tipo,
                detNombre: nombre + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    constultaCatalogoDocumento(tipo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                detNemonico: tipo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    comboCiudades(ciudad) {
        let body = {
            maximoRegistros: 10,
            tipoConsulta: 1,
            entidad: 'Localizacion',
            parametros: {
                locNombre: ciudad + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosTitular(tipo, polCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                detTipoPersona: tipo,
                polCodigo: polCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosPaciente(polCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                polCodigo: polCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosPacienteActual(perCodigo, polCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                polCodigo: polCodigo,
                perCodigo: perCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosAgendamiento(
        fechaReg,
        ciudad,
        agendamiento,
        estatus,
        cedulaTitular,
        fechaCita,
        value,
        field,
        order,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'AGEND',
            parametros: {
                0: fechaReg,
                1: ciudad,
                2: agendamiento,
                3: estatus,
                4: cedulaTitular,
                5: fechaCita,
                6: value,
                7: value,
                8: value,
                9: value,
                10: value,
                11: value,
                12: value,
                13: value,
                14: value,
                15: fechaReg,
                16: ciudad,
                17: agendamiento,
                18: estatus,
                19: cedulaTitular,
                20: fechaCita,
                21: value,
                22: value,
                23: value,
                24: value,
                25: value,
                26: value,
                27: value,
                28: value,
                29: value,
                30: field,
                31: order,
                32: numero,
                33: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    datosAgendamientoExport(
        typeExport,
        parametro,
        fechaReg,
        ciudad,
        agendamiento,
        estatus,
        cedulaTitular,
        fechaCita,
        value,
        field,
        order,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: typeExport,
            entidad: parametro,
            parametros: {
                0: fechaReg,
                1: ciudad,
                2: agendamiento,
                3: estatus,
                4: cedulaTitular,
                5: fechaCita,
                6: value,
                7: value,
                8: value,
                9: value,
                10: value,
                11: value,
                12: value,
                13: value,
                14: value,
                15: fechaReg,
                16: ciudad,
                17: agendamiento,
                18: estatus,
                19: cedulaTitular,
                20: fechaCita,
                21: value,
                22: value,
                23: value,
                24: value,
                25: value,
                26: value,
                27: value,
                28: value,
                29: value,
                30: field,
                31: order,
                32: numero,
                33: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    datosAgendamientoAuditor(
        fechaReg,
        ciudad,
        agendamiento,
        estatus,
        cedulaTitular,
        fechaCita,
        value,
        field,
        order,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'AGEAU',
            parametros: {
                0: fechaReg,
                1: ciudad,
                2: agendamiento,
                3: estatus,
                4: cedulaTitular,
                5: fechaCita,
                6: value,
                7: value,
                8: value,
                9: value,
                10: value,
                11: value,
                12: value,
                13: value,
                14: value,
                15: fechaReg,
                16: ciudad,
                17: agendamiento,
                18: estatus,
                19: cedulaTitular,
                20: fechaCita,
                21: value,
                22: value,
                23: value,
                24: value,
                25: value,
                26: value,
                27: value,
                28: value,
                29: value,
                30: field,
                31: order,
                32: numero,
                33: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    datosAgendamientoCount(perIdentificacion) {
        let body = {
            tipoConsulta: 2,
            entidad: 'Agendamiento',
            parametros: {
                perIdentificacion: perIdentificacion + '%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosAgendamientoEspecifico(ageCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Agendamiento',
            parametros: {
                ageCodigo: ageCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    buscarPrestador(value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PREST',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: value,
                7: value,
                8: value,
                9: value,
                10: field,
                11: order,
                12: numero,
                13: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    searchTitular(value, field, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'BUSTIT',
            parametros: {
                0: '',
                1: value,
                2: value,
                3: value,
                4: '',
                5: value,
                6: value,
                7: value,
                8: field,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    ingresoNuevaCita(tipo, cita) {
        let body = {
            tipoAccion: tipo,
            entidad: 'Agendamiento',
            entidades: cita
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101114');
    }

    datosAgendamientoExportEcuesta(typeExport) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'AGENC',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    agedamientoDocuentos(ageCodigo, detTipo, files, descripcion) {
        let filesNames = '';
        console.log(files);

        files.map(file => {
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            return 0;
        });

        let body = {
            tipoAccion: 1,
            entidad: 'Documento',
            entidades: [
                {
                    ageCodigo: ageCodigo,
                    detTipo: detTipo,
                    docDescripcion: descripcion,
                    docNombre: filesNames
                }
            ]
        };

        let endpoint = '/multipart/documento';
        return this.dataService.postMultipart(endpoint, body, '0101117', files);
    }

    documentosAgendamiento = ageCodigo => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Documento',
            parametros: {
                ageCodigo: ageCodigo,
                docUrl: '%data%'
            }
        };

        const response = this.dataService
            .post('/consulta', body, '0101000')
            .then(response => {
                return response.bodyOut.data;
            });

        return response;
    };

    descargarDocumentoAgendamiento = docCodigo => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Documento',
            parametros: {
                docCodigo: docCodigo
            }
        };

        const response = this.dataService
            .post('/consulta', body, '0101000')
            .then(response => {
                return response.bodyOut.data;
            });

        return response;
    };

    datosT(perCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PersonaPoliza',
            parametros: {
                perCodigo: perCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    montoCobertura(polCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PersonaPoliza',
            parametros: {
                polCodigo: polCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosAgendamientoCliente(
        fechaReg,
        ciudad,
        agendamiento,
        estatus,
        cedulaTitular,
        fechaCita,
        polCodigo,
        value,
        field,
        order,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'AGECLI',
            parametros: {
                0: fechaReg,
                1: ciudad,
                2: agendamiento,
                3: estatus,
                4: cedulaTitular,
                5: fechaCita,
                6: polCodigo,
                7: value,
                8: value,
                9: value,
                10: value,
                11: value,
                12: value,
                13: value,
                14: value,
                15: fechaReg,
                16: ciudad,
                17: agendamiento,
                18: estatus,
                19: cedulaTitular,
                20: fechaCita,
                21: polCodigo,
                22: value,
                23: value,
                24: value,
                25: value,
                26: value,
                27: value,
                28: value,
                29: value,
                30: field,
                31: order,
                32: numero,
                33: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    envioMensaje(mensaje) {
        let body = {
            tipoAccion: 1,
            entidad: 'Email',
            entidades: [mensaje]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101130');
    }

    constultaMonto(tipo, perBeneficiario, polCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Agendamiento',
            parametros: {
                tipoAgendamiento: tipo,
                perBeneficiario: perBeneficiario,
                polCodigo: polCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101013');
    }
}
