import { DataService } from 'providers/dataService'


export class DetalleLiquidacionProvider {

    constructor() {
        this.dataService = new DataService();


    }
    insertDetalleLiquidacion = ( detalleLiquidacion )=>{
        const dS = new DataService();

        const body = {
            "tipoAccion": 1,
            "entidad": "DetalleLiquidacion",
            "entidades": detalleLiquidacion
        }
       
        const response =  dS.post('/accion', body, '0101100').then( response => {
            
            return response;

        } 
            
        
        );

        return response;
    }
    findDetalleCatalogo = ( catCodigo ) => {
        const dS = new DataService();

        const body = {
            "tipoConsulta": 1,
            "entidad": "DetalleCatalogo",
            "parametros": {
                    "catCodigo": catCodigo
    
            }
        }

        const response =  dS.post('/consulta', body, '0101000', "james").then( response => {
            
            return response.bodyOut.data;
         
 
            } 
            
        
        );

        return response;
        
    }

    

   

}
