import React, { Component } from 'react';
import GenericTable from './GenericTable';
import { FaRegFilePdf} from 'react-icons/fa';
import './styles.css'
import { GenericHeader } from 'components';


export default class GenericList extends Component{
     
    constructor(props){
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
    }

    
    handleSearch = (evt) => {
        //console.log(evt.target);

    };

  
    render(){

       
           
        const { elements, titles, formTitle, elementIcon} = this.props
        

        return(
            <div className="total-container" >

                <GenericHeader title={formTitle} />
                <br/>
                <GenericTable 
                    data={elements} 
                    elementIcon={elementIcon} 
                    handleOpen={this.props.handleOpen} 
                    idRequirement = {this.props.idRequirement}
                    pdfIcon={<FaRegFilePdf />} 
                    titles={titles}
                />
                
            </div>


        );
    }


}