import React from 'react';
import { Dialog, DialogTitle, DialogContentText, DialogContent, Button, DialogActions } from '@material-ui/core';
import './common.css';
import Typography from '@material-ui/core/Typography';

const PayDialog = ({  open,handleClose, pendingPayments }) => {

    return (
        <Dialog
            fullWidth        
            maxWidth={'md'}
            open={open}
            scroll={'body'}
            style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
        >
            <DialogTitle id="max-width-dialog-title">
                <Typography variant="h4" gutterBottom>
                 Hay pagos pendientes
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {`Estimado cliente, usted tiene ${pendingPayments.length} pago(s) pendientes, por favor revisar la bandeja de pagos`}
                </DialogContentText>
                
            </DialogContent> 
            <DialogActions>
                <Button onClick={handleClose} color="primary">
            En otro momento
                </Button>
                <Button href='/cliente/pago-tarjeta' color="primary">
            Aceptar
                </Button>
            </DialogActions>       
        </Dialog>
    );
};


export default PayDialog;
