import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DebitoAcceptModal({ open, handleAccept, handleReject }) {

    return (
        
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleReject}
            open={open}
        >
            <DialogTitle id="alert-dialog-title">{"Aviso"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Ya existe una cuenta usada para debitar, ¿desea utilizar esta cuenta para realizar los débitos?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleReject}>
                        Rechazar
                </Button>
                <Button autoFocus color="primary" onClick={handleAccept}>
                        Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}