import { DataService } from 'providers/dataService'


export class TarifarioProvider {

    constructor() {
        this.dataService = new DataService();


    }

    findTarifarios = ( catCodigo ) => {
        const dS = new DataService();

        const body = {
            "tipoConsulta": 1,
            "entidad": "Tarifario",
            "parametros": {
    
            }
        }

        const response =  dS.post('/consulta', body, '0101000', "james").then( response => {
            
            return response.bodyOut.data;
         

            } 
            
        
        );

        return response;   
    }
}
