import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      format="# # # # # #"
      getInputRef={inputRef}
      isNumericString
      mask="_"
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name: values.name
          }
        });
      }}
      placeholder="_ _ _ _ _ _"
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any,
  onChange: PropTypes.func
};

export default NumberFormatCustom;