/* eslint-disable no-useless-escape */
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add } from '@material-ui/icons';
import { Card,
    CardHeader,
    CardContent,
    Divider,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    TextField,
    CircularProgress,
    Select,
    MenuItem
} from '@material-ui/core';
import './styles.css';
import {ModalAddBeneficiary} from './../../components';
import { DataUpdateProvider } from "providers/dataUpdate.service";
import { DisplayMessage } from "notifications/displayMessage";
import { ActualDate } from "services/ActualDate";


const EMAIL_REGEX = new RegExp("^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$");
const numeros = new RegExp("^[0-9]+$");
const letra = new RegExp("^[ñíóáéú a-zA-Z ]+$");
export default class FormularioActualizacion extends React.Component {
    constructor(props){
        super(props);
    

        this.state={
            empresa: '',
            nombreAfiliado: '',
            apellidoAfiliado: '',
            cedulaAfiliado: '',
            fechaNacimiento: '',
            direccion: '',
            telefono: '',
            email: '',
            cuenta: '',
            tipoCuenta: '',
            banco: '',
            planContratado: '',
            openApplication: false,
            setOpenApplication: false,
            data: [],
            dataBeneficiarios:[],
            verTabla:false,
            estadoCivil:0,
            touched: {
                nombreAfiliado:false,
                cedulaAfiliado: false,
                fechaNacimiento: false,
                direccion: false,
                telefono: false,
                email: false,
                cuenta: false,
                tipoCuenta: false,
                banco: false,

            },
            errors: {
                required: {
                    nombreAfiliado:false,
                    cedulaAfiliado: false,
                    fechaNacimiento: false,
                    direccion: false,
                    telefono: false,
                    email: false,
                    cuenta: false,
                    tipoCuenta: false,
                    banco: false,
                },
                valid: {
                    nombreAfiliado:true,
                    cedulaAfiliado: true,
                    fechaNacimiento: true,
                    direccion: true,
                    telefono: true,
                    email: true,
                    cuenta: true,
                    tipoCuenta: true,
                    banco: true,
                }
            },
        }

        this.handleChange=this.handleChange.bind(this);
        //this.handleKeyDown = this.handleKeyDown.bind(this);
    }
  
    handleChange(e){
        const { name, value } = e.target; 
        const errors = {
            required: { ...this.state.errors.required, [name]:false}
        };
        this.setState({
            [name]:value,
            errors: { ...this.state.errors, ...errors}
        });
    
    };
  Check=(e)=>{
      const field = e.target.name;
      this.setState({
          touched: { ...this.state.touched, [field]:true }
      });
      this.validate(e);
  }

  validate(e){
      const target = e.target;
      const { value, name } = target;

      if(value.length === 0) {
          const errors = {
              required: { ...this.state.errors.required, [name]:true }
          };

          this.setState({
              errors: { ...this.state.errors, ...errors }
          });
          return;
      }

      if(name === 'cedulaAfiliado') {
          this.validateCedula(value)
      } 
      if(name === 'apellidoMedico') {
          this.validateApellidos(value)
      } 
      if(name === 'nombreAfiliado') {
          this.validateNombres(value)
      } 
      if(name === 'telefono') {
          this.validateTelefono(value);
      }
      if(name === 'email') {
          this.validateEmail(value);
      }
      if(name === 'cuenta') {
          this.validateNumeroCuenta(value);
      }
      if(name === 'tipoCuenta') {
          this.validateTipoCuenta(value);
      }
      if(name === 'banco') {
          this.validateBanco(value);
      }

   
  }

  validateCedula() {
      if(this.state.cedulaAfiliado.length ===10){
          const cedulaIsValid = numeros.test(this.state.cedulaAfiliado);
          const errors = {
              valid: { ...this.state.errors.valid, cedulaAfiliado: cedulaIsValid }
          };

          this.setState({
              errors: { ...this.state.errors, ...errors }
          });
      }else{
          const errors = {
              valid: { ...this.state.errors.valid, cedulaAfiliado: false }
          };

          this.setState({
              errors: { ...this.state.errors, ...errors }
          });
      }
  }

  validateNombres() {
      const letrasIsValid = letra.test(this.state.nombreAfiliado);
      const errors = {
          valid: { ...this.state.errors.valid, nombreAfiliado: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateApellidos() {
      const letrasIsValid = letra.test(this.state.apellidoMedico);
      const errors = {
          valid: { ...this.state.errors.valid, apellidoMedico: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateEmail() {
      const emailIsValid = EMAIL_REGEX.test(this.state.email);
      const errors = {
          valid: { ...this.state.errors.valid, email: emailIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateTelefono() {
      const edadIsValid = numeros.test(this.state.telefono);
      const errors = {
          valid: { ...this.state.errors.valid, telefono: edadIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
 
  }

  validateNumeroCuenta() {
      const edadIsValid = numeros.test(this.state.cuenta);
      const errors = {
          valid: { ...this.state.errors.valid, cuenta: edadIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  
  }

  validateTipoCuenta() {
      const letrasIsValid = letra.test(this.state.tipoCuenta);
      const errors = {
          valid: { ...this.state.errors.valid, tipoCuenta: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  validateBanco() {
      const letrasIsValid = letra.test(this.state.banco);
      const errors = {
          valid: { ...this.state.errors.valid, banco: letrasIsValid }
      };

      this.setState({
          errors: { ...this.state.errors, ...errors }
      });
  }

  hasError(field) {
      return (this.state.errors.required[field] || !this.state.errors.valid[field]) && this.state.touched[field];
  }

  isFormInvalid() {
      const { errors } = this.state;
      const { required, valid } = errors;
      const isSomeFieldRequired = Object.keys(required).some(error => required[error]);
      const isSomeFieldInvalid = Object.keys(valid).some(error => !valid[error]);

      return isSomeFieldInvalid || isSomeFieldRequired;
  }

  displayError(field) {
      const { required, valid } = this.state.errors;
      const errorMessage = `Campo ${field} `;

      if (required[field]) {
          return `${errorMessage} es obligatorio`;
      }

      if (!valid[field]) {
          return `${errorMessage} no es valido`;
      }
  }

  formatoFecha(string){
      var f = string.split('-').reverse().join('/');
      return f;
  }

 handleApplicationOpen = () => {
     this.setState({setOpenApplication: true})
 };

 handleApplicationClose = () => {
     this.setState({setOpenApplication:false})
 };

 componentDidMount(){

     const displayMessage = new DisplayMessage();
     const dataUpdate = new DataUpdateProvider();

     /* Combo Empresas */
     dataUpdate.datosEmpresa()
         .then(
             data => {
                 this.setState({dataEmpresa:data.bodyOut.data});
             },
             () => {          
                 displayMessage.error('Error de comunicación.');

             }

         );

     /* Combo Titulares */
    
     dataUpdate.datosAfiliado("TITULAR")
         .then(
             data => {
                 this.setState({dataTitulares:data.bodyOut.data});
             },
             () => {          
                 return false;

             }

         );

     /* Combo Plan */

     dataUpdate.datosPlan()
         .then(
             data => {
                 this.setState({dataPlan:data.bodyOut.data});
             },
             () => {          
                 return false;

             }

         );

 }

  getRespons=(result)=>{
      this.setState({datosPrueba : result})
  }
  handleOnChangeTitular=(value)=>{
      const dataUpdate = new DataUpdateProvider();
      this.setState(
          {
              apellidoAfiliado:value.perApellidos,
              nombreAfiliado:value.perNombres,
              cedulaAfiliado:value.perIdentificacion,
              email:value.perMail,
              fechaNacimiento:value.edadActual,
              direccion:value.perDireccion,
              cuenta:value.cueCuenta,
              tipoCuenta:value.detTipoCuenta,
              banco:value.nombre1,
              polCodigo: value.polCodigo,
              verTabla:true
          });
      

      dataUpdate.datosBeneficiarios(value.polCodigo)
          .then(
              data => {
                  this.setState({dataBeneficiarios:data.bodyOut.data});
              },
              () => {          
                  return false;

              }

          );

  }

  handleOnChangeEmpresa=(value)=>{
      this.setState(
          {
              empresa:value.empCodigo
          });
  }

  handleChangeSelectPlan=(e)=>{
    
      this.setState(
          {
              planContratado:e.target.value
          });
  }

  handleChangeSelectEstado=(e)=>{
      this.setState({estadoCivil: e.target.value})
  }

  handleGuardar=()=>{
      const displayMessage = new DisplayMessage();
      const { empCodigo,nombreAfiliado, cedulaAfiliado, fechaNacimiento, estadoCivil,direccion,telefono,email,cuenta,tipoCuenta,banco,planContratado} =  this.state;
      if(empCodigo === '' || nombreAfiliado === '' || cedulaAfiliado === '' || fechaNacimiento === '' || estadoCivil === '' || direccion === '' || telefono === '' 
    || email === '' || cuenta === '' || tipoCuenta === '' || banco === '' || planContratado === ''){
          displayMessage.error("Complete los campos Obligatorios")
      }else{
          const dataUpdate = new DataUpdateProvider();

          const fecha = ActualDate();
          const fechaset = this.formatoFecha(fecha);

          const datosReembolso = [{        
              "empCodigo":parseInt(this.state.empresa),
              "detTipo":17173,   
              "perTitular":2,
              "polCodigo":this.state.polCodigo,
              "cueCodigo":1,
              "plaCodigo":this.state.planContratado,
              "forFecha":fechaset,
              "forDetalle":`{nombreTitular:${this.state.nombreAfiliado}, cedulaTitular:${this.state.cedulaAfiliado},fechaNacimiento:${this.state.fechaNacimiento},direccion:${this.state.direccion}, telefono:${this.state.telefono}, email:${this.state.email}, numeroCuenta:${this.state.cuenta}, tipCuenta:${this.state.tipoCuenta}, banco:${this.state.banco}}`        
          }];
     

          dataUpdate.ingresoFormularioActualizar(datosReembolso)
              .then(
                  data => {
                      console.log(data)
                      displayMessage.success('Registro correcto');              
                  },
                  () => {          
                      displayMessage.error('Error al Registrar');   

                  }

              ); 

          this.setState({ empresa: '',
              nombreAfiliado: '',
              apellidoAfiliado: '',
              cedulaAfiliado: '',
              fechaNacimiento: '',
              direccion: '',
              telefono: '',
              email: '',
              cuenta: '',
              tipoCuenta: '',
              banco: '',
              planContratado: '',
              openApplication: false,
              setOpenApplication: false,
              data: [],
              dataBeneficiarios:[],
              verTabla:false,
              estadoCivil:0,
              touched: {
                  nombreAfiliado:false,
                  cedulaAfiliado: false,
                  fechaNacimiento: false,
                  direccion: false,
                  telefono: false,
                  email: false,
                  cuenta: false,
                  tipoCuenta: false,
                  banco: false,

              },
              errors: {
                  required: {
                      nombreAfiliado:false,
                      cedulaAfiliado: false,
                      fechaNacimiento: false,
                      direccion: false,
                      telefono: false,
                      email: false,
                      cuenta: false,
                      tipoCuenta: false,
                      banco: false,
                  },
                  valid: {
                      nombreAfiliado:true,
                      cedulaAfiliado: true,
                      fechaNacimiento: true,
                      direccion: true,
                      telefono: true,
                      email: true,
                      cuenta: true,
                      tipoCuenta: true,
                      banco: true,
                  }
              },})
      }
  }

  render(){

      return (
          <div >           
              <form>
                  <Card>
                      <Card className="cardSpace">
                          <CardHeader
                              title="Datos Afiliado"
                          />
                          <Divider/>
                      </Card>
                      <Card className="cardSpace">
                          <CardContent>
                              <Grid>
                                  {this.state.dataEmpresa ? 
                                      <Autocomplete
                                          options={this.state.dataEmpresa}
                                          getOptionLabel={option => option.empNombreComercial }
                                          onChange={(e,value)=>value ? this.handleOnChangeEmpresa(value):null}
                                          //style={{ width: 300, paddingBlockEnd:20 }}
                                          renderInput={params => <TextField {...params} label="Buscar Empresa" margin="none" fullWidth />}
                                      />:<CircularProgress/>}
                              </Grid>
                          </CardContent>
                      </Card>
                      <Card className="cardSpace">
                          <CardHeader
                              title="Titular"
                          />
                          <Divider/>
                          <CardContent>
                              {this.state.dataTitulares ? 
                                  <Autocomplete
                                      //onKeyDown={this.handleKeyDown}
                                      options={this.state.dataTitulares}
                                      getOptionLabel={option => option.perIdentificacion+' - ' + option.perNombres}
                                      onChange={(event, value) => value ? this.handleOnChangeTitular(value):this.setState(
                                          {cedulaAfiliado:'',
                                              nombreAfiliado:'',
                                              apellidoAfiliado:'',
                                              email:'',
                                              fechaNacimiento:'', 
                                              direccion:'',
                                              cuenta:'',
                                              tipoCuenta:'',
                                              banco:'',
                                              planContratado:'',
                                              verTabla:false,
                                              dataBeneficiarios:[]
                                          })
                                      }
                                      style={{ width: 300, paddingBlockEnd:20 }}
                                      renderInput={params => <TextField {...params} label="Buscar Titular" margin="none" fullWidth />}
                                  />:<CircularProgress/>}
                          </CardContent>
                          <Divider/>
                          <CardContent>
                              <Grid className="margin">
                                  <FormControl>
                                      <InputLabel>Nombres</InputLabel>
                                      <Input
                                          onBlur={this.Check}
                                          name="nombreAfiliado"
                                          value={this.state.nombreAfiliado}
                                          className="tamaño"
                                          onChange={this.handleChange}   
                                          //readOnly={true}
                                          style={{width:300}}             
                                          error={this.hasError('nombreAfiliado')}
                                      />
                                      <p  className={this.hasError('nombreAfiliado') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Nombre')}
                                      </p>
                                  </FormControl>
                              </Grid>
                              <Grid className="margin"> 
                                  <FormControl >
                                      <InputLabel>Cédula</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="cedulaAfiliado"
                                          value={this.state.cedulaAfiliado}
                                          onChange={this.handleChange}  
                                          error={this.hasError('cedulaAfiliado')}
                                      />
                                      <p  className={this.hasError('cedulaAfiliado') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Cédula')}
                                      </p>
                                  </FormControl>    
                              </Grid>
                              <Grid> 
               
                                  {/* <InputLabel>Fecha de Nacimiento</InputLabel> */}
                                  <TextField
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      label="Fecha de Nacimiento"
                                      onBlur={this.Check}
                                      type="date"
                                      className="tamaño"
                                      name="fechaNacimiento"
                                      value={this.state.fechaNacimiento}
                                      onChange={this.handleChange}                
                                      error={this.hasError('fechaNacimiento')}
                                  />
                                  <p  className={this.hasError('fechaNacimiento') ? 'error-message__visible' : 'error-message'}>
                                      {this.displayError('Fecha Nacimiento')}
                                  </p>   
                              </Grid>             
                          </CardContent>
                      </Card>
                      {/* Estado Civil */}
                      <Card className="cardSpace">
                          <CardHeader
                              title="Estado Civil"
                          />
                          <Divider/>
                          <CardContent>
                              <Grid className="">
                                  <Select
                                      name="estadoCivil" 
                                      className="select" 
                                      value={this.state.estadoCivil}               
                                      onChange={this.handleChangeSelectEstado}
                                      style={{width:300}}
                                  >
                                      <MenuItem value="0">Seleccione</MenuItem>
                                      <MenuItem value="1">SOLTERO</MenuItem>
                                      <MenuItem value="2">CASADO</MenuItem>
                                      <MenuItem value="3">DIVORCIADO</MenuItem>
                                      <MenuItem value="4">VIUDO</MenuItem>
                          
                          
                                  </Select>  
                              </Grid>             
                          </CardContent>
                      </Card>
                      {/*  */}
                      <Card className="cardSpace">
                          <CardContent>
                              <Grid className="margin">
                                  <FormControl>
                                      <InputLabel>Direccion</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="direccion"
                                          value={this.state.direccion}
                                          onChange={this.handleChange}  
                                          style={{width:300}}              
                                          error={this.hasError('direccion')}
                                      />
                                      <p  className={this.hasError('direccion') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Dirección')}
                                      </p>
                                  </FormControl>
                              </Grid>
                              <Grid className="margin">
                                  <FormControl>
                                      <InputLabel>Teléfono</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="telefono"
                                          value={this.state.telefono}
                                          onChange={this.handleChange}                
                                          error={this.hasError('telefono')}
                                      />
                                      <p  className={this.hasError('telefono') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Teléfono')}
                                      </p>
                                  </FormControl>
                              </Grid>
                              <Grid>
                                  <FormControl>
                                      <InputLabel>Email</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="email"
                                          value={this.state.email}
                                          onChange={this.handleChange}
                                          //readOnly={true}         
                                          style={{width:300}}       
                                          error={this.hasError('email')}
                                      />
                                      <p  className={this.hasError('email') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Email')}
                                      </p>               
                                  </FormControl>
                              </Grid>
                          </CardContent>
                      </Card>
                      {/* Datos Bancarios */}
                      <Card className="cardSpace">
                          <CardHeader
                              title="Restitución de valores"
                          />
                          <Divider/>
                          <CardContent>
                              <Grid className="margin">
                                  <FormControl>
                                      <InputLabel>N° Cuenta</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="cuenta"
                                          value={this.state.cuenta}
                                          onChange={this.handleChange}                
                                          error={this.hasError('cuenta')}
                                      />
                                      <p  className={this.hasError('cuenta') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Cuenta')}
                                      </p>
                                  </FormControl>
                              </Grid>
                              <Grid className="margin">
                                  <FormControl>
                                      <InputLabel>Tipo Cuenta</InputLabel>
                                      <Input
                                          name="tipoCuenta"
                                          onBlur={this.Check}
                                          value={this.state.tipoCuenta}
                                          className="tamaño"
                                          onChange={this.handleChange}                
                                          error={this.hasError('tipoCuenta')}
                                      />
                                      <p  className={this.hasError('tipoCuenta') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Tipo Cuenta')}
                                      </p>
                                  </FormControl>
                              </Grid>
                              <Grid> 
                                  <FormControl >
                                      <InputLabel>Banco</InputLabel>
                                      <Input
                                          className="tamaño"
                                          onBlur={this.Check}
                                          name="banco"
                                          value={this.state.banco}
                                          onChange={this.handleChange}    
                                          style={{width:300}}            
                                          error={this.hasError('banco')}
                                      />
                                      <p  className={this.hasError('banco') ? 'error-message__visible' : 'error-message'}>
                                          {this.displayError('Banco')}
                                      </p>
                                  </FormControl>    
                              </Grid>            
                          </CardContent>
                      </Card>
                      {/* Plan de Contrato */}
                      <Card className="cardSpace">
                          <CardHeader 
                              title="Plan Contratado"
                          />
                          <Divider/>
                          <CardContent>
                              <Grid >
                                  {this.state.dataPlan ? 
                                      <FormControl fullWidth >
               
                                          <InputLabel>Plan Contratado</InputLabel>
                 
                                          <Select
                                              name="planContratado" 
                          
                                              //onBlur={this.Check} 
                                              value={this.state.planContratado}               
                                              onChange={this.handleChangeSelectPlan}
                                              //style={{width:250}} 
                                              //error={this.hasError('parentesco')}
                                          >
                                              <MenuItem value="">SELECCIONE</MenuItem>
                                              {this.state.dataPlan.map((plan,index) =>
                                                  <MenuItem value={plan.detCodigo} key={index}>
                                                      {plan.detNombre}
                                                  </MenuItem>   
                                              )}
                          
                                          </Select>
                                          {/* <Input
                    name="planContratado"
                    value={this.state.planContratado}
                    onChange={this.handleChange}                
                  /> */}
                                      </FormControl>:<CircularProgress/>}
                              </Grid>
                          </CardContent>
                      </Card>
                      {/*Beneficiarios  */}

                      <div >       
     
                          <Card className="cardSpace">
                              <CardHeader 
                                  title="Beneficiarios:"
                              />
                              <Divider/>
                              <CardContent>
                                  <PerfectScrollbar>
                                      <div>                  
                                          {
                                              this.state.verTabla && <Table>
                                                  <TableHead>
                                                      <TableRow>
                                                          <TableCell>
                      CI:
                                                          </TableCell>
                                                          <TableCell>
                      Nombres
                                                          </TableCell>
                                                          <TableCell>
                      Apellidos
                                                          </TableCell>
                                                          <TableCell>
                      Edad
                                                          </TableCell>
                                                          <TableCell>
                      Parentesco
                                                          </TableCell>
                                                          <TableCell>
                                                              <Button variant="contained" color="primary"
                                                                  onClick={this.handleApplicationOpen}
                                                              ><Add />Agregar</Button>
                                                          </TableCell>
                                                      </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                      {this.state.dataBeneficiarios ?
                                                          this.state.dataBeneficiarios.map((beneficiarios,index)=>

                                                              beneficiarios.nemonico !== 'PTITU' && <TableRow key={index}>
                                                                  <TableCell>
                                                                      {beneficiarios.perIdentificacion}
                                                                  </TableCell>
                                                                  <TableCell>
                                                                      {beneficiarios.perNombres}
                                                                  </TableCell>
                                                                  <TableCell>
                                                                      {beneficiarios.perApellidos}
                                                                  </TableCell>
                                                                  <TableCell>
                                                                      {beneficiarios.edadActual}
                                                                  </TableCell>
                                                                  <TableCell>
                                                                      {beneficiarios.nombre2}
                                                                  </TableCell>
                                                              </TableRow>
                                                          ): <TableRow><TableCell><CircularProgress /></TableCell></TableRow>}
                              
                                                  </TableBody>
                                              </Table>
                                          }
             
                                      </div>
                                  </PerfectScrollbar>
                              </CardContent>
                          </Card>
                          {/* <div className="boton">
          <Button 
          color ="primary" 
          variant="contained"
          onClick={this.GuardarBeneficiarios}
          >Guardar
          </Button>
          </div> */}

                          <ModalAddBeneficiary
                              onApply={this.handleApplicationClose}
                              close={this.handleApplicationClose}
                              open={this.state.setOpenApplication}
                              callback={this.getRespons.bind(this)}
                          />

                      </div>

                  </Card> 
                  <div className="boton">
                      <Button 
                          disabled={this.isFormInvalid()}
                          color ="primary" 
                          variant="contained"
                          onClick={this.handleGuardar}
                      >Guardar
                      </Button>
                  </div>
              </form>
              {/* <Application
        onApply={this.handleApplicationClose}
        close={this.handleApplicationClose}
        open={this.state.setOpenApplication}
      /> */}
          </div>
      );
  }
}
