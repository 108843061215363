/* eslint no-eval: 0 */
import React, { useEffect } from 'react';
import {Grid,  TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { CompanyProvider } from 'providers/company.service';
import { CatalogProvider } from 'providers/catalog.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CardContent, IconButton ,CardHeader,  Card, Button, Divider, Dialog,DialogActions } from '@material-ui/core';
import {Cancel} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme)=> ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    }
}))

const Form = props => {

    const classes = useStyles();
  
    const initialState = {
        company: null,
        nemonic: '',
        group: '',
        name: '',
        description: '',
        errors:{ 
            company: false,
            nemonic: false,
            group: false,
            name: false,
            description: false,
        },
        messages: {
            company: '',
            nemonic: '',
            group: '',
            name: '',
            description: '',
        },
        allCompanies: []
    }
    const [stateSelect, setStateSelect] = React.useState(initialState);
    const [disabledButton, setDisabledButton] = React.useState(false);

  
    const catalogProvider = new CatalogProvider();
    const companyProvider = new CompanyProvider();
    const displayMessage = new DisplayMessage();

    const getAllCompanies = () => {
        companyProvider.findAllCompanies().then(
            response => {
                setStateSelect({ ...stateSelect, allCompanies: response });
            }
        )
    }
  
    const componentDidMount = () => {
        getAllCompanies();
  
    }
  
    useEffect(
        componentDidMount
        ,[])



    const handleClose = () => {
        props.close();
        restartFields();
        setDisabledButton(false);

    }
  

    /** -------------------------------------------------------Notificar Todosp */
    const showEmpties = () => {
        const { nemonic, group, name } = stateSelect;

        notifyEmptyNulls('nemonic', nemonic);
        notifyEmptyNulls('group', group);
        notifyEmptyNulls('name', name);

    }

    /**  ------------------------------------------------------NOtificar NUlos*/

    const notifyEmptyNulls = (nameElement, value) => {
        if(typeof(value)==='object' ){
            if(value === null){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');
            }

        } else if(typeof(value)==='string'){
            if(value.trim()===''){
                setErrorsMessages(nameElement, true, 'Este campo no puede estar vacio');
            }else{
                setErrorsMessages(nameElement, false, '');

            }
        }  
    } 

    const setErrorsMessages = (nameElement, stateElement, message) => {
            
        const errors = stateSelect.errors;

        const messages = stateSelect.messages;
        eval(`errors.${nameElement}  = ${stateElement}`);
        eval(`messages.${nameElement} = '${message}'`) ;

        setStateSelect({ ...stateSelect, errors: errors, messages: messages });

  
    }


    const restartFields = () => {
        setStateSelect({ ...stateSelect, company: null,
            nemonic: '',
            group: '',
            name: '',
            description: '',
            errors:{ 
                company: false,
                nemonic: false,
                group: false,
                name: false,
                description: false,
            },
            messages: {
                company: '',
                nemonic: '',
                group: '',
                name: '',
                description: '',
            } });
    }

    const onChangeCompany = (event, values) => {
        setStateSelect({ ...stateSelect, company: values });

    }


    const handleSave = () => {
        setDisabledButton(true);
        const { company, nemonic, group, name, description } = stateSelect;
    
        if(nemonic.trim() !=='' && group.trim() !== '' && name.trim() !== '' ){
            const trama = {
                empCodigo: company ? company.empCodigo: null,
                catNemonico: nemonic,
                catGrupo: group,
                catNombre: name,
                catDescripcion: description,
                catEstado: 1
            }
            catalogProvider.createCatalog([trama]).then(
                async response => {
                    if(response.error.codigo === '0'){
                        
                        await displayMessage.success(`Categoría ${name} agregada con éxito`);
                        await handleClose();
                        await props.getAllCatalogs();

                        await getCatalogByCatNemonico(trama.catNemonico);

                    }
                    setDisabledButton(false);

                }
            )
        }else {
            setDisabledButton(false);
            displayMessage.warn('Por favor rellene todos los campos correctamente')
            showEmpties();
        }

    

    }
    const SetCatCode = (name, catCode) => {
        props.setStateSelect({ ...props.stateSelect, [name]: catCode });

    }
    const getCatalogByCatNemonico = catNemonico => {
        catalogProvider.findCatalogByCatNemonico(catNemonico).then(
            response => {
                if(response.length > 0){
                    SetCatCode('catCode', response[0])
                }
            }
        );
    }
    const handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'nemonic':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase()});
                break;
            case 'group':
                setStateSelect({ ...stateSelect, [name]: value.toUpperCase()});
                break;
            default:
                setStateSelect({ ...stateSelect, [name]: value });

                break;
        }

    }
     
    return ( 
        <Dialog
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader 
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                    ><Cancel/></IconButton>}
                    title = "Categoría"
                />
                <Divider/>
                <CardContent>
                    <div>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
          
                                <Grid
                                    container
                                    spacing={3}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}

                                    >
                                        <Autocomplete
                                            id="company"
                                            options={stateSelect.allCompanies}
                                            getOptionLabel={(option) => option.empNombreComercial}
                                            onChange={onChangeCompany}
                                            value={stateSelect.company}
                                            renderInput={(params) => <TextField {...params} error={stateSelect.errors.company} helperText={stateSelect.messages.company} label="Empresa" variant="outlined" fullWidth />}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        xs={6}
                                    >
                                        <TextField
                                            id="name"
                                            name='name'
                                            label="Nombre"
                                            inputProps={{maxLength: 50}}
                                            value = {stateSelect.name}
                                            onChange={handleInputChange}
                                            error={stateSelect.errors.name}
                                            helperText={stateSelect.messages.name}
                                            variant="outlined" fullWidth 
                                        />
                                    </Grid>
                  
                                    <Grid
                                        item
                                        sm={6}
                                        xs={6}
                                    >
                                        <TextField
                                            id="nemonic"
                                            name="nemonic"
                                            label="Código"
                                            inputProps={{maxLength: 7}}
                                            value={stateSelect.nemonic}
                                            onChange={handleInputChange}
                                            error={stateSelect.errors.nemonic}
                                            helperText={stateSelect.messages.nemonic}
                                            variant="outlined" fullWidth 
                                        />
                                    </Grid>
                     
                                    <Grid
                                        item
                                        sm={6}
                                        xs={6}
                                    >
                                        <TextField
                                            id="group"
                                            name="group"
                                            label="Grupo"
                                            inputProps={{maxLength: 20}}
                                            error={stateSelect.errors.group}
                                            value={stateSelect.group}
                                            onChange={handleInputChange}
                                            helperText={stateSelect.messages.group}
                                            variant="outlined" fullWidth 
                                        />
                                    </Grid>
                   
                                    <Grid
                                        item
                                        sm={12}
                                        xs={12}
                                    >
                                        <TextField
                                            id="description"
                                            name="description"
                                            label="Descripción"
                                            inputProps={{maxLength: 255}}
                                            error={stateSelect.errors.description}
                                            value={stateSelect.description}
                                            onChange={handleInputChange}
                                            helperText={stateSelect.messages.description}
                                            variant="outlined" fullWidth 
                                        />
                                    </Grid>
            
                                </Grid>   
            
                            </Grid>
                        </Grid> 
      
           
                    </div>
                </CardContent>

            </Card>

            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                        Cancelar
                </Button>
                <div className={classes.wrapper}>

                    <Button 
                        autoFocus 
                        color="primary" 
                        disabled = {disabledButton}
                        onClick={handleSave}
                    >
                            Guardar
                    </Button>
                    {
                        disabledButton === true &&
          <CircularProgress size={24} className={classes.buttonProgress} /> 

                    }
                </div>

            </DialogActions>
        </Dialog>
    );
}
 
export default Form;