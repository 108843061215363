import { DataService } from 'providers/dataService'

export class PlanProvider {

    constructor() {
        this.dataService = new DataService();
        

    }
    findAllPlanes = ()=>{
        const dS = new DataService();
        
        const body = {
            tipoConsulta: 1,
            entidad: 'Plan',
            parametros: {
            }
        };

        //const Post = dS.post('/consulta', body, null, "james");

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            return response.bodyOut.data;
        } 
        
        );
        return response;
    }

    findAllPlanesByName = (name)=>{
        
        const body = {
            tipoConsulta: 1,
            entidad: 'Plan',
            parametros: {
                plaNombre: name,
                plaEstado: 1
            }
        };
        const response =  this.dataService.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;

        }  
        );


        return response;
    }


    findCategories = (empCodigo)=>{
        
        let body = {
            tipoConsulta: 1,
            entidad: 'TIPLA',
            parametros: {
                0: empCodigo

            }
        };
        return this.dataService.post('/consulta', body, '0101010').then( response => {

            return response.bodyOut.data;
        }  
        );

    }

    createPlan = entities => {
        
        const body = {
            'tipoAccion': 1,
            'entidad': 'Plan',
            'entidades': entities
        }

        return this.dataService.post('/accion',body,'0101115').then(
            response => {
                return response;
            }
        )
    }

    updatePlan = entities => {
        
        const body = {
            'tipoAccion': 2,
            'entidad': 'Plan',
            'entidades': entities
        }

        return this.dataService.post('/accion',body,'0101115').then(
            response => {
                return response;
            }
        )
    }

    findPlanByPlaCodigo = plaCodigo => this.genericConsultPlan({ 'plaCodigo': plaCodigo });

    

    genericConsultPlan = (parameters) =>{
        return this.genericConsult(1, 'Plan', parameters, '0101000');
    }

    
    genericConsult = (typeConsult, entitye, parameters,typeTransaction) => {
        const body = {
            tipoConsulta: typeConsult,
            entidad: entitye,
            parametros: parameters
        };
        return this.dataService.post('/consulta', body, typeTransaction).then( response => {    
            return response.bodyOut.data;
        }  
        );
    }

    planes(plaNombre) {
        let body = {
            tipoConsulta: 1,
            entidad: 'PlanBroker',
            parametros: {
                plaNombre:plaNombre,
                plbEstado:1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    createPolicyMasi( files, plaCodigo,empCodigo) {
        let filesNames = '';

        files.map(file => {
            console.log(file.name)
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            return 0;
        });

        let body = {
            tipoAccion: 1,
            entidad: 'Documento',
            entidades: [
                {
                    docDescripcion: `${plaCodigo}`,    
                    docObservacion: `${empCodigo}`,
                    docNombre: filesNames
                }
            ]
        };

        let endpoint = '/multipart/documento';
        return this.dataService.postMultipart(endpoint, body, '0101123', files);
    }

    empresa() {
        let body = {
            tipoConsulta: 1,
            entidad: 'EMPPLB',
            parametros: {
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    typePlan(empCodigo) {
        let body = {
            tipoConsulta: 1,
            entidad: 'TIPLA',
            parametros: {
                0:empCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

}
