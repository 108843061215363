import { DataService } from 'providers/dataService';

export class ProductionReportProvider {
    constructor() {
        this.dataService = new DataService();
    }

    datosVista() {
        let body = {
            tipoConsulta: 1,
            entidad: 'VwReporteProduccion',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    dataReportsExport(
        typeExport,
        value,
        anio,
        mes,
        empresa,
        filtro,
        order,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWRPR',
            parametros: {
                '0': anio + '%',
                '1': mes + '%',
                '2': empresa + '%',
                '3': value,
                '4': value,
                '5': value,
                '6': value,
                '7': value,
                '8': anio + '%',
                '9': mes + '%',
                '10': empresa + '%',
                '11': value,
                '12': value,
                '13': value,
                '14': value,
                '15': value,
                '16': filtro,
                '17': order,
                '18': numero,
                '19': inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    reportProduccion(value, anio, mes, empresa, filtro, order, numero, inicio) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWRPR',
            parametros: {
                '0': anio,
                '1': mes,
                '2': empresa,
                '3': value,
                '4': value,
                '5': value,
                '6': value,
                '7': value,
                '8': anio,
                '9': mes,
                '10': empresa,
                '11': value,
                '12': value,
                '13': value,
                '14': value,
                '15': value,
                '16': filtro,
                '17': order,
                '18': numero,
                '19': inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    datosEmpresa() {
        let body = {
            tipoConsulta: 1,
            entidad: 'EMPPLB',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    HistorialPagos = identification => this.GenericOr(1, 'HISPAG',{0: identification}, '0101010');
}
