 /*eslint-disable no-eval */ 

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { DisplayMessage } from 'notifications/displayMessage';


import "./styles.css";


export default class GenericBasicDialog extends Component {
    constructor(props){
        super(props);
        // eval(props.modalInputs[0])
        //m1=eval(props.modalInputs[0]);
        this.state = {
            selectedElement: '',
            CANTIDAD: '',
            UNIDAD: '',
            VALOR: '', 
            CANTIDAD_ERROR:false,
            UNIDAD_ERROR:false,
            VALOR_ERROR:false,
            optionAux: ''

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.hasError = this.hasError.bind(this);
    }
    

  
    componentDidMount=()=>{
       
    }

    blurInput = (evt)=> {
       
    }

    handleInputChange=(evt)=>{
        //const element = document.getElementById(evt.target.id);

        if(evt.target.value===''){
            this.setState({
                [`${evt.target.name}_ERROR`]: true,

            });
        }else{
            this.setState({
                [`${evt.target.name}_ERROR`]: false
            });        
        }

       // const ERROR = validate(this.state, schema);
       ;

        this.setState({
            [evt.target.name] : evt.target.value,
        });
        this.setState({
            VALOR: this.state.CANTIDAD>0 && this.state.UNIDAD>0?
             (this.state.CANTIDAD * this.state.UNIDAD):0

        })

      
        
    }
    addData = () => {

        const displayMessage = new DisplayMessage()

        if((this.state.selectedElement !=='' || this.state.optionAux !=='')  && this.state.CANTIDAD !=='' && this.state.UNIDAD !=='' && this.state.VALOR!=='' ){
            const {selectedElement, CANTIDAD, UNIDAD, VALOR, optionAux} = this.state;
            console.log("ADD DATA METHOD");
    
            console.log(selectedElement +' - '+optionAux+ " - "+ CANTIDAD+" - "+ UNIDAD+" - "+ VALOR);
           
            this.props.addData([
                     selectedElement ? selectedElement: optionAux
                      , CANTIDAD, UNIDAD,
                      (CANTIDAD * UNIDAD).toFixed(2)
                    ]);

                    this.setState({
                        selectedElement: '',
                        CANTIDAD: '',
                        UNIDAD: '',
                        VALOR: '', 
                        optionAux: ''
                    }
                    )
    
                    this.props.calcTotalValue();
                
    
            this.props.handleClose();
        }else{
            displayMessage.error('Se han detectado campos vacios');
        }



       
    }

    onTagsChange = (event, values) => {
        console.log("event.target")

        console.log(event.target)
        console.log("event.values")

        console.log(event.values)

        this.setState({
          selectedElement: values
        }, () => {
          console.log(this.state.selectedElement);
        });
      }
     
      hasError = (evt) =>{
        
       if( evt.target.value ==='' ){
           return true;
       }
       else{
            return false;
        }
      }

      setVALOR = (val) =>{
          this.setState({
                VALOR: val 
          });
      }

    render(){
        const { show, handleClose, modalTitle, dataSelect, modalInputs, SelectDatafield } = this.props;

        return(
            <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                
                </DialogContentText>
                <Autocomplete
                    freeSolo

                    id="selectedElement"
                    options={dataSelect}
                    getOptionLabel={ dataSelect => eval(SelectDatafield) }
                    //name=
                    componentName="selectedElement"
                    value={this.state.selectedElement}
                    onChange={this.onTagsChange}
                    //inputValue={this.state.selectedElement}
                    renderInput={params => (
                        <TextField {...params} 
                            id='optionAux'
                            name = 'optionAux'
                            label={modalTitle} 
                            onChange={ this.handleInputChange }

                            variant="outlined" fullWidth 

                           />
                    )}

                />

                {
                    modalInputs.map((input, index) =>
                        <div key={index} className='medium-margin-top'>
                            
                            <TextField 
                                type='number'
                                autoFocus
                                margin="dense"
                                id={input}
                                name={input}
                                label={input}
                                fullWidth
                                value={input!=='VALOR'? 
                                eval(`this.state.${input}`):
                                //Math.round( ((CANTIDAD * UNIDAD)*100)/100 ).toFixed(2)

                                (
                                 (this.state.CANTIDAD*this.state.UNIDAD).toFixed(2)
                                )
                            }
                                variant="outlined"
                                className={this.state.inputClass}
                               error={eval(`this.state.${input}_ERROR`)}
                               //helperText= {()=>this.hasError(input) ?  eval(`this.state.ERROR.${input}`) : null}
                               onChange={this.handleInputChange}

                                
                            />
                        </div>
                    )
                    }
                
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={this.addData.bind()} color="primary">
                    Guardar
                </Button>
                </DialogActions>
            </Dialog>
        );

    }
}