import React from 'react';
import { 
    Grid,
    TextField,
    Card, 
    CardHeader, 
    CardContent,
    Divider,
    IconButton,
    CardActions,
    Button
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons'
import { AtentionFormProviders } from "providers/attentionForm.service";
import { DisplayMessage } from 'notifications/displayMessage';


export default class PhysicalTherapies extends React.Component{

    constructor(props){
        super(props);
        this.state={
            preCodigo:'',
            buscar:'',
            rucDoctor:'',
            fecha:'',
            motivo:'',
            establecimiento:'',
            monto:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.enviaDatos = this.enviaDatos.bind(this);
    }

    handleClose=()=>{
        this.props.close(false);
    }

    handleChange=(e)=>{
       
        this.setState({
            [e.target.name]:e.target.value
        });
        const arregloDatos={
            [e.target.name]:e.target.value
            
        }

        this.enviaDatos(arregloDatos)
    }

    enviaDatos(arregloDatos){
        this.props.datos(arregloDatos,this.state.preCodigo,this.state.establecimiento)
    }

    handleChangeBuscar=(e)=>{
        this.setState({
            buscar:e.target.value
        })
    }

    handleClickRuc=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscar === ''){
            displayMessage.error("Ingrese el RUC para buscar")
        }else{
    
        atention.consultarPresador(this.state.buscar)
          .then(
            user => {  
                if(user.bodyOut.data.length === 0){
                    displayMessage.error('RUC no encontrado.');
                }else{
                    displayMessage.success("RUC encontrado")

                    this.setState({
                        rucDoctor:user.bodyOut.data[0].preRuc,
                        establecimiento:user.bodyOut.data[0].preNombre,
                        preCodigo:user.bodyOut.data[0].preCodigo
                    }) 
                }      
           
             
            },
            () => {          
              displayMessage.error('Error de comunicación.');
              
            }
          );
        }
    }

    render(){
        return(
            <Grid>
            <Card>
                <CardHeader title="Terapias físicas" action={<IconButton color="secondary" onClick={this.handleClose}><Cancel/></IconButton>}/>
                <Divider/>
                <CardActions>
                <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Buscar ruc"
                            placeholder="Buscar por RUC"
                            name="buscar"
                            value={this.state.buscar}
                            onChange={this.handleChangeBuscar}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                           <Button variant="contained" color="primary" onClick={this.handleClickRuc}>
                               Buscar
                           </Button>
                        </Grid>  
                </CardActions>
                <CardContent>
                    <Grid
                    container
                    spacing={4}
                    >
                         <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Ruc"
                            name="rucDoctor"
                            value={this.state.rucDoctor}
                            onChange={this.handleChange}  
                            InputProps={{
                                readOnly: true,
                              }}                            
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Fecha"
                            name="fecha"
                            type="date"
                            value={this.state.fecha}
                            onChange={this.handleChange}
                            InputLabelProps={{
                                shrink: true,
                              }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Motivo"
                            name="motivo"
                            value={this.state.motivo}
                            onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Establecimiento"
                            name="establecimiento"
                            value={this.state.establecimiento}
                            onChange={this.handleChange}
                            InputProps={{
                                readOnly: true,
                              }}   
                            />
                        </Grid>
                       
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Monto utilizado"
                            name="monto"
                            value={this.state.monto}
                            onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
            </CardContent>
            </Card><br/>
            </Grid>
        );
    }
}