/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    CircularProgress,
    Card,
    CardActions,
    Grid,
    Button,
    TextField,
    Paper,
    Input,
    InputBase,
    Tooltip,
    IconButton,
    CardContent,
    Typography,
    CardHeader,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import {
    DatePicker,
    KeyboardTimePicker,
    TimePicker
} from '@material-ui/pickers';
import { Link as RouterLink } from 'react-router-dom';
import 'moment/locale/es';
import moment from 'moment';
import { order } from 'mock/orderMock';
import { makeStyles } from '@material-ui/styles';
import {
    Search,
    Clear,
    KeyboardReturn,
    Add,
    Schedule,
    Save,
    CloudUpload
} from '@material-ui/icons';
import ModalSearchAfiliate from './../ModlalSeartchAffiliate/ModlalSeartchAffiliate';
import ModalPrestador from './../ModlalSeartchLeader/ModlalSeartchLeader';
import { AppointmentProvider } from 'providers/appointment.service';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import NumberFormatMoney from 'components/NumberFormatMoney';

import ModalUpload from './../ModalUploadFiles/ModalUploadFiles';

moment.locale('es');

export default function SearchAffiliate() {
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();
    const [data, setData] = useState([]);
    const session = useSelector(state => state.session);

    const [fechaAprovacion, setFechaAprovacion] = useState(moment());

    const [dataPaciente, setDataPaciente] = useState([]);
    const [activarComboPaciente, setActivarComboPaciente] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [cedulaTitular, setCedulaTitular] = useState('');
    const [nameTitular, setNameTitular] = useState('');
    const [lastNameTitular, setLastNameTitular] = useState('');
    const [polCodigo, setPolCodigo] = useState(null);
    const [preexistencia, setPreexistencia] = useState('');

    const [cedulaPaciente, setCedulaPaciente] = useState('');
    const [namePaciente, setNamePaciente] = useState('');
    const [lastNamePaciente, setLastNamePaciente] = useState('');
    const [edadPaciente, setEdadPaciente] = useState('');
    const [emailPaciente, setEmailPaciente] = useState('');
    const [telefono, setTelefono] = useState(null);
    const [plan, setPlan] = useState('');
    const [perCodigo, setPerCodigo] = useState(null);
    const [errorCiudad, setErrorCiudad] = useState(false);
    const [errorSintomas, setErrorSintomas] = useState(false);
    const [errorEstatus, setErrorEstatus] = useState(false);

    const [errorPaciente, setErrorPaciente] = useState(false);

    const [openModalPrestador, setOpenPrestador] = useState(false);
    const [nombrePrestador, setNombrePrestador] = useState('');
    const [preCodigo, setPreCodigo] = useState(null);

    const [formState, setFormState] = useState({
        observacionesAsistencia:
            '• Importante acudir 20 minutos antes a la atención, llevar a la cita formulario de reembolso impreso para que sea llenado y firmado por el Dr. (esto solo aplica en caso de recetar medicamentos ya que al momento la cobertura es vía reembolso), en caso del Dr. solicitar exámenes médicos debe solicitar: informe médico donde indique motivo de consulta, más el pedido de exámenes, esto se debe enviar al correo: autorizaciones@segurosmedi.com.\n\n• PACIENTE CANCELA COPAGO'
    });
    const [selectedDate, handleDateChange] = useState(moment());
    const [fechaCita, setFechaCita] = useState();

    const [tipoAgendamiento, setTipoAgendamiento] = useState([]);
    const [agendamiento, setAgendamiento] = useState('');
    const [codAgendamiento, setCodAgendamiento] = useState('');
    const [activarTitular, setActivarTitular] = useState(false);

    const [ciudades, setCiudades] = useState([]);
    const [ciudad, setCiudad] = useState('');

    const [activarCosto, setActivarCosto] = useState(false);

    const [estados, setEstados] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [nombreEspecialidad, setNombreEspecialidad] = useState('');

    const [costo, setCosto] = useState(0);

    const [desactivar, setDesactivar] = useState(false);

    const [typeUser, setTypeUser] = useState(true);

    const [montoCobertura, setMontoCobertura] = useState(null);

    const [openUpload, setOpenUpload] = useState(false);
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState('');

    const [tipoDoc, setTipoDoc] = useState(0);
    const [openLoading, setOpenLoading] = useState(false);

    const [montoFacturado, setMontoFacturado] = useState(0);
    const [montoNoCubierto, setMontoNoCubierto] = useState(0);
    const [montoCubierto, setMontoCubierto] = useState(0);
    const [copago, setCopago] = useState(0);
    const [deducible, setDeducible] = useState(0);

    const [descripcionDocumento, setDescripcionDocumento] = useState('');

    const [montoOcupado, setMontoOcupado] = useState(0);

    const [perTitular, setPerTitular] = useState(0);

    const [monstoDisponible, setMontoDisponible] = useState(0);

    const [openPermitido, setOpenPermitido] = useState(false);

    const [eventSelected, setEventSelected] = React.useState(true);

    const [errorMontoDisponible, setErrorMontoDisponible] = useState(false);

    useEffect(() => {
        if (session.user.role === 'CLIENTE') {
            
        }

      
    }, []);


    useEffect(() => {
        if (session.user.role === 'AUDITOR') {
            setTypeUser(false);
        }

        appoinment.constultaCatalogo('TIPAGE', '').then(
            data => {
                if (data.error.codigo !== '0') {
                    setTipoAgendamiento([]);
                } else {
                   
                    if(session.user.role !== 'CLIENTE'){
                        setTipoAgendamiento(data.bodyOut.data);
                    }else{
                        const dataFilter = data.bodyOut.data.filter(item => item.detNemonico !== 'HOSPIT' && item.detNemonico !== 'LABORA')
                        setTipoAgendamiento(dataFilter);
                    }
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );

        appoinment.constultaCatalogoDocumento('HABIL').then(
            data => {
                if (data.error.codigo !== '0') {
                    setTipoDoc(0);
                } else {
                    setTipoDoc(data.bodyOut.data[0].detCodigo);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );

        //citys()
    }, []);

    useEffect(() => {
        if (agendamiento === 'ODONTO' && perCodigo !== 0) {
            let total = 0;
            let monto = 0;
            appoinment
                .constultaMonto(codAgendamiento, perCodigo, polCodigo)
                .then(
                    response => {
                        if (response.error.codigo === '0') {
                            response.bodyOut.data.map(item => {
                                monto =
                                    item.topeCobertura -
                                    (monto + item.ageValor + item.liqFacturado);
                            });
                            setMontoOcupado(monto.toFixed(2));
                            setMontoDisponible(monto.toFixed(2));
                        } else {
                            //setDataDocumento([]);
                        }
                    },
                    () => {
                        displayMessage.error('Error de conexión');
                    }
                );
        }
    }, [perCodigo]);

    useEffect(() => {
        if (agendamiento === 'ODONTO') {
            let cos = 0;
            if (costo !== '') {
                cos = parseFloat(costo);
            } else {
                cos = 0;
            }
            let resta = parseFloat(montoOcupado) - cos;
            if (parseFloat(resta) >= 0) {
                setErrorMontoDisponible(false);
                setMontoDisponible(resta);
                setDesactivar(false);
            } else {
                setErrorMontoDisponible(true);
                setMontoDisponible(resta);
                setDesactivar(true);
            }
        }
    }, [costo, montoOcupado]);

    const citys = ciudad => {
        appoinment.comboCiudades(ciudad).then(
            data => {
                if (data.error.codigo !== '0') {
                    setCiudades([]);
                } else {
                    //setActivarComboPaciente(false)
                    setCiudades(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const status = agen => {
        appoinment.constultaCatalogo('ESTAGE', '').then(
            data => {
                if (data.error.codigo !== '0') {
                    setEstados([]);
                } else {
                    //setActivarComboPaciente(false)
                    if(session.user.role === 'CLIENTE'){
                        if (
                            agen === 'CIRPRO' ||
                            agen === 'LABORA' ||
                            agen === 'HOSPIT' 
                        ) {
                            const estadoFiltro = data.bodyOut.data.filter(
                                e => e.detNemonico === 'AUDIT'
                            );
                            setEstados(estadoFiltro);
                            setFormState({
                                ...formState,
                                estatus: estadoFiltro[0].detCodigo
                            });
                        } else {
                            const estadoFiltro = data.bodyOut.data.filter(
                                e => e.detNemonico === 'PREAGE'
                            );
                            setFormState({
                                ...formState,
                                estatus: estadoFiltro[0].detCodigo
                            });
                            setEstados(estadoFiltro);
                            //setEstados(data.bodyOut.data)
                        }
                    }else{
                    if (
                        agen === 'CIRPRO' ||
                        agen === 'LABORA' ||
                        agen === 'HOSPIT' 
                    ) {
                        const estadoFiltro = data.bodyOut.data.filter(
                            e => e.detNemonico === 'AUDIT'
                        );
                        setEstados(estadoFiltro);
                        setFormState({
                            ...formState,
                            estatus: estadoFiltro[0].detCodigo
                        });
                    } else {
                        const estadoFiltro = data.bodyOut.data.filter(
                            e => e.detNemonico !== 'AUDIT'
                        );
                        setEstados(estadoFiltro);
                        //setEstados(data.bodyOut.data)
                    }
                }
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const especiali = age => {
        if (age === 'ODONTO') {
            appoinment.constultaCatalogo('ESPAGE', 'ODONTOLOGIA').then(
                data => {
                    if (data.error.codigo !== '0') {
                        setEspecialidades([]);
                    } else {
                        setEspecialidades(data.bodyOut.data);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación');
                }
            );
        } else {
            if (age === 'AMBULA') {
                appoinment.constultaCatalogo('ESPAGE', '').then(
                    data => {
                        if (data.error.codigo !== '0') {
                            setEspecialidades([]);
                        } else {
                            const estadoFiltro = data.bodyOut.data.filter(
                                e =>
                                    e.detNombre !== 'ODONTOLOGIA ENDODONCIA ' &&
                                    e.detNombre !==
                                        'ODONTOLOGIA (PROFILAXIS)' &&
                                    e.detNombre !==
                                        'ODONTOLOGIA (RESINA CARIES SIMPLE)'
                            );
                            setEspecialidades(estadoFiltro);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación');
                    }
                );
            } else {
                appoinment.constultaCatalogo('ESPAGE', '').then(
                    data => {
                        if (data.error.codigo !== '0') {
                            setEspecialidades([]);
                        } else {
                            setEspecialidades(data.bodyOut.data);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación');
                    }
                );
            }
        }
    };

    const searchBenfi = polCodigo => {
        appoinment.datosPaciente(polCodigo).then(
            data => {
                if (data.bodyOut.data.length === 0) {
                    setDataPaciente([]);
                } else {
                    setActivarComboPaciente(false);
                    setDataPaciente(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const handleOnChangePaciente = value => {
        if (value) {
            setErrorPaciente(false);
            setPerCodigo(value.perCodigo);
            setNamePaciente(value.perNombres);
            setLastNamePaciente(value.perApellidos);
            setCedulaPaciente(value.perIdentificacion);
            setEdadPaciente(value.edadActual);
            setEmailPaciente(value.perMail);
        } else {
            setErrorPaciente(true);
            setPerCodigo('');
            setNamePaciente('');
            setLastNamePaciente('');
            setCedulaPaciente('');
            setEdadPaciente('');
            setEmailPaciente('');
        }
    };

    const handleOnChangeEspecialidad = value => {
        if (value) {
            setNombreEspecialidad(value.detNombre);
        } else {
            setNombreEspecialidad('');
        }
    };

    const handleCloseEspecialidad = () => {
        // setNombreEspecialidad('')
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
        setOpenPrestador(false);
    };

    const handleClosePermitido = () => {
        setOpenPermitido(false);
    };
    ////CIUDADES
    const handleChangeText = event => {
        let search = event.target.value;
        if (search.length >= 3) {
            citys(search);
        } else {
            setCiudades([]);
        }
    };

    const handleBlur = event => {
        if (event.target.value === '') {
            setCiudad('');
        }
    };

    const handleChangeCiudad = (event, values) => {
        if (values) {
            setErrorCiudad(false);
            setCiudad(values.locCodigo);
        } else {
            setCiudad('');
        }
    };

    const handleCloseCiudades = () => {
        setCiudades([]);
    };

    const handleOpenModalPrestador = () => {
        setOpenPrestador(true);
    };

    const openAux = b => {
        setOpenPermitido(b);
    };
    /////
    const getDataAfiliate = data => {
        if (
            data.PLA_NOMBRE === 'MASIVOS BASICOS CPN' &&
            agendamiento === 'ODONTO'
        ) {
            openAux(true);

            //alert('ERROR');
        } else {
            setPerTitular(data.perCodigo);
            setCedulaTitular(data.PER_IDENTIFICACION);
            setNameTitular(data.PER_NOMBRES);
            setLastNameTitular(data.PER_APELLIDOS);
            searchBenfi(data.POL_CODIGO);
            setPlan(data.PLA_NOMBRE);
            setPolCodigo(data.POL_CODIGO);
            if (data.POL_PREEXISTENCIAS === 0) {
                setPreexistencia('SI');
            }
            if (data.POL_PREEXISTENCIAS === 1) {
                setPreexistencia('NO');
            }
        }
        setEventSelected(!eventSelected);
    };

    const getDataLeader = data => {
        setPreCodigo(data.PRE_CODIGO);
        setNombrePrestador(data.PRE_NOMBRE);
    };

    const handleChange = e => {
        e.persist();
        if (e.target.name === 'sintomas') {
            setErrorSintomas(false);
        }
        if (e.target.name === 'estatus') {
            setErrorEstatus(false);
        }
        setFormState(formState => ({
            ...formState,
            [e.target.name]: e.target.value
        }));
    };

    const handleChangeTipoAgenda = e => {
        if(session.user.role === 'CLIENTE'){
            setEspecialidades([]);
            let nem = e.target.value;
            status(e.target.value);
            especiali(e.target.value);
            const found = tipoAgendamiento.find(e => e.detNemonico === nem);
            setAgendamiento(e.target.value);
            setCodAgendamiento(found.detCodigo);
            setActivarTitular(true);
            if (e.target.value === 'Selec') {
                setActivarTitular(false);
            }

            appoinment.searchTitular(session.user.perIdentificacion, "PER_APELLIDOS", 'asc', 10, 0)
                .then(
                    data => {
                        if (data.error.codigo !== '0') {
                            displayMessage.error('Error de comunicación');
                            setData([])
                        } else {
                            //displayMessage.success('Titular encontrado')
                            //setData(data.bodyOut.data)
                            getDataAfiliate(data.bodyOut.data[0])
                            
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación')
                    }

                );

        }else{
        setEspecialidades([]);
        let nem = e.target.value;
        status(e.target.value);
        especiali(e.target.value);
        const found = tipoAgendamiento.find(e => e.detNemonico === nem);
        setAgendamiento(e.target.value);
        setCodAgendamiento(found.detCodigo);
        setActivarTitular(true);
        if (e.target.value === 'Selec') {
            setActivarTitular(false);
        }
    }
    };

    const handleChangeCosto = e => {
        setCosto(e.target.value);
    };

    const handleChangeTelefono = e => {
        setTelefono(e.target.value);
    };

    const handleClosePaciente = () => {};

    const handleChangeMonto = e => {
        setMontoCobertura(e.target.value);
    };

    const handleChangeMontoFacturado = e => {
        setMontoFacturado(e.target.value);
    };

    const handleChangeMontoNoCubierto = e => {
        setMontoNoCubierto(e.target.value);
    };

    const handleChangeMontoCubierto = e => {
        setMontoCubierto(e.target.value);
    };

    const handleChangeCopago = e => {
        setCopago(e.target.value);
    };

    const handleChangeDeducible = e => {
        setDeducible(e.target.value);
    };

    const handleSave = () => {
        setDesactivar(true);
        if (
            perCodigo === null ||
            perCodigo === undefined ||
            perCodigo === '' ||
            ciudad === undefined ||
            ciudad === '' ||
            ciudad === null ||
            formState.estatus === undefined ||
            formState.estatus === ''
        ) {
            setDesactivar(false);
            displayMessage.error('Complete los campos');
            if (
                perCodigo === null ||
                perCodigo === undefined ||
                perCodigo === ''
            ) {
                setErrorPaciente(true);
            }
            if (ciudad === undefined || ciudad === '' || ciudad === null) {
                setErrorCiudad(true);
            }
            if (formState.estatus === undefined || formState.estatus === '') {
                setErrorEstatus(true);
            }
        } else {
            //AMBULATORIO y HOSPITALARIO
            if (agendamiento === 'AMBULA') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: moment(moment()).format(
                                'DD/MM/YYYY'
                            ),
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: data.POL_PREEXISTENCIAS,
                            ageObservaMedico: formState.observacionesMedico,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    ingresoAgendamiento(trama);
                }
            }

            if (agendamiento === 'HOSPIT') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: moment(moment()).format(
                                'DD/MM/YYYY'
                            ),
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: data.POL_PREEXISTENCIAS,
                            ageObservaMedico: formState.observacionesMedico,
                            ageFacturado: montoFacturado,
                            ageNoCubierto: montoNoCubierto,
                            ageCubierto: montoCubierto,
                            ageCopago: copago,
                            ageValor: costo,
                            ageDeducible: deducible,
                            ageCobertura: montoCobertura,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    ingresoAgendamiento(trama);
                }
            }
            //Odontologia
            if (agendamiento === 'ODONTO') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: moment(moment()).format(
                                'DD/MM/YYYY'
                            ),
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            ageValor: costo,
                            agePreexistencia: data.POL_PREEXISTENCIAS,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];

                    ingresoAgendamiento(trama);
                }
            }
            //Laboratorio
            if (agendamiento === 'LABORA') {
                const fecha = `${moment(fechaCita).format(
                    'DD/MM/YYYY'
                )} ${moment(selectedDate).format('HH:mm:ss')}`;
                const trama = [
                    {
                        polCodigo: polCodigo,
                        perBeneficiario: perCodigo,
                        locCodigo: ciudad,
                        preCodigo: preCodigo,
                        usuCodigo: session.user.usuCodigo,
                        detTipo: codAgendamiento,
                        ageFechaRegistro: moment(moment()).format(
                            'DD/MM/YYYY'
                        ),
                        ageFechaConfirma: moment(fechaAprovacion).format(
                            'DD/MM/YYYY'
                        ),
                        ageFechaCita: fecha,
                        //ageSintomas: formState.sintomas,
                        ageTelefono: telefono,
                        ageObservaciones: formState.observacionesAsistencia,
                        ageNotificado: formState.notificacion,
                        //ageEspecialidad: nombreEspecialidad,
                        ageMedico: formState.nombreMedico,
                        //ageValor: costo,
                        ageDocumentos: formState.documentos,
                        ageExamenes: formState.examenes,
                        ageDiagnostico: formState.diagnostico,
                        agePreexistencia: data.POL_PREEXISTENCIAS,
                        ageIndicaciones: formState.indicaciones,
                        ageMailPaciente: emailPaciente,
                        estado: [
                            {
                                usuCodigo: session.user.usuCodigo,
                                detEstado: formState.estatus,
                                esaObservacion:
                                    formState.observacionesAgendamiento
                            }
                        ]
                    }
                ];

                if (file === null || file.length === 0) {
                    ingresoAgendamiento(trama);
                } else {
                    ingresoAgendamientoConArchivos(trama);
                }
            }
            //CIRUJIA PROGRAMADA
            if (agendamiento === 'CIRPRO') {
                if (
                    formState.sintomas === undefined ||
                    formState.sintomas === ''
                ) {
                    setDesactivar(false);
                    setErrorSintomas(true);
                } else {
                    const fecha = `${moment(fechaCita).format(
                        'DD/MM/YYYY'
                    )} ${moment(selectedDate).format('HH:mm:ss')}`;
                    const trama = [
                        {
                            polCodigo: polCodigo,
                            perBeneficiario: perCodigo,
                            locCodigo: ciudad,
                            preCodigo: preCodigo,
                            usuCodigo: session.user.usuCodigo,
                            detTipo: codAgendamiento,
                            ageFechaRegistro: moment(moment()).format(
                                'DD/MM/YYYY'
                            ),
                            ageFechaCita: fecha,
                            ageSintomas: formState.sintomas,
                            ageTelefono: telefono,
                            ageObservaciones: formState.observacionesAsistencia,
                            ageNotificado: formState.notificacion,
                            ageEspecialidad: nombreEspecialidad,
                            ageMedico: formState.nombreMedico,
                            agePreexistencia: data.POL_PREEXISTENCIAS,
                            ageCobertura: montoCobertura,
                            ageObservaMedico: formState.observacionesMedico,
                            ageFacturado: montoFacturado,
                            ageNoCubierto: montoNoCubierto,
                            ageCubierto: montoCubierto,
                            ageCopago: copago,
                            ageValor: costo,
                            ageDeducible: deducible,
                            ageMailPaciente: emailPaciente,
                            estado: [
                                {
                                    usuCodigo: session.user.usuCodigo,
                                    detEstado: formState.estatus,
                                    esaObservacion:
                                        formState.observacionesAgendamiento
                                }
                            ]
                        }
                    ];
                    if (file === null || file.length === 0) {
                        ingresoAgendamiento(trama);
                    } else {
                        ingresoAgendamientoConArchivos(trama);
                    }
                }
            }
        }
    };
    const ingresoAgendamiento = trama => {
        appoinment.ingresoNuevaCita(1, trama).then(
            data => {
                if (data.error.codigo !== '0') {
                    setDesactivar(false);
                    displayMessage.error('No se pudo agendar la cita');
                } else {
                    displayMessage.success('Se agendo la cita.');
                    window.location.href = `/agendarCitas`;
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    const ingresoAgendamientoConArchivos = async trama => {
        //setOpenConfirmation(false);
        setOpenLoading(true);
        let ageCodigo = '';
        await appoinment.ingresoNuevaCita(1, trama).then(
            data => {
                if (data.error.codigo !== '0') {
                    displayMessage.error('No se pudo agendar la cita');
                } else {
                    ageCodigo = data.bodyOut.resultados[0].ageCodigo;
                    displayMessage.success('Se agendo la cita.');
                    //
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
        for (let index = 0; index < file.length; index++) {
            await appoinment
                .agedamientoDocuentos(
                    ageCodigo,
                    tipoDoc,
                    [file[index]],
                    descripcionDocumento
                )
                .then(
                    async response => {
                        if (response.error.codigo === '0') {
                            //setDesactivar(false);

                            if (index === file.length - 1) {
                                displayMessage.success(
                                    'Archivo subido correctamente'
                                );
                                window.location.href = `/agendarCitas`;
                            }
                        } else {
                            setDesactivar(false);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                        setDesactivar(false);
                    }
                );
        }
    };

    //SUBIR ARCHIVOS
    const handleOpenSubir = () => {
        setOpenUpload(true);
    };

    const handleCloseSubir = () => {
        setOpenUpload(false);
    };

    const handleFiles = file => {
        setFile(file);
        file.map(item => {
            setNameFile(item.name);
        });
        setOpenUpload(false);
    };

    const handleFilesDescripcion = descripcion => {
        setDescripcionDocumento(descripcion);
    };

    const handleEmail = e => {
        setEmailPaciente(e.target.value);
    };

    //

    return (
        <div>
            <ModalSearchAfiliate
                open={openModal}
                close={handleClose}
                datos={getDataAfiliate}
            />

            <ModalPrestador
                open={openModalPrestador}
                close={handleClose}
                datos={getDataLeader}
            />
            <ModalUpload
                open={openUpload}
                close={handleCloseSubir}
                dataFiles={data => handleFiles(data)}
                dataDescripcion={data => handleFilesDescripcion(data)}
            />
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                open={openPermitido}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <br />
                        No se puede agendar este tipo de cita para este
                        afiliado, su plan no cubre ODONTOLOGIA
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color="primary"
                        onClick={handleClosePermitido}>
                        cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Button
                        component={RouterLink}
                        to={'/agendarCitas'}
                        color="primary"
                        variant="contained"
                        size="small">
                        <KeyboardReturn />
                        REgresar
                    </Button>
                </Grid>
            </Grid>

            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                //fullWidth
                                style={{ width: 375 }}
                                select
                                variant="outlined"
                                label="Tipo de agendamiento"
                                name="agendamiento"
                                value={agendamiento}
                                onChange={handleChangeTipoAgenda}
                                SelectProps={{
                                    native: true
                                }}
                                //helperText="Please select your currency"
                            >
                                <option value="Selec">Seleccione..... </option>
                                {tipoAgendamiento.length > 0
                                    ? tipoAgendamiento.map(option => (
                                          <option
                                              key={option.detNemonico}
                                              value={option.detNemonico}>
                                              {option.detNombre}
                                          </option>
                                      ))
                                    : ''}
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
                {session.user.role === 'CLIENTE' ?
                <>
               {activarTitular && (
                    <>
                        <CardHeader title="Datos titular" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>

                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaTitular}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        variant="outlined"
                                        value={`${nameTitular} ${lastNameTitular}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Plan"
                                        variant="outlined"
                                        value={plan}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Preexistencia"
                                        variant="outlined"
                                        value={preexistencia}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardHeader title="Datos paciente" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={6} xm={12}>
                                    <Autocomplete
                                        disabled={activarComboPaciente}
                                        getOptionLabel={option =>
                                            option.perIdentificacion +
                                            ' - ' +
                                            option.perNombres +
                                            '  ' +
                                            option.perApellidos +
                                            ' - ' +
                                            option.nombre2
                                        }
                                        onChange={(e, value) =>
                                            handleOnChangePaciente(value)
                                        }
                                        onClose={handleClosePaciente}
                                        options={dataPaciente}
                                        //value={null}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorPaciente}
                                                fullWidth
                                                helperText={
                                                    errorPaciente &&
                                                    'Seleccione un paciente'
                                                }
                                                label="Buscar Paciente"
                                                margin="none"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                        //style={{ width: 300, paddingBlockEnd: 20 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} />
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombres"
                                        variant="outlined"
                                        value={`${namePaciente} ${lastNamePaciente}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                {/*  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Apellidos"
                                        variant="outlined"
                                        value={lastNamePaciente}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                </Grid> */}
                                <Grid item md={1} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Edad"
                                        variant="outlined"
                                        value={edadPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Correo"
                                        variant="outlined"
                                        value={emailPaciente}
                                        InputProps={
                                            {
                                                //readOnly: true
                                            }
                                        }
                                        onChange={handleEmail}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        name="telefono"
                                        variant="outlined"
                                        InputProps={{
                                            inputComponent: NumberFormatMoney,
                                            inputProps: {
                                                style: { textAlign: 'right' },
                                                decimalScale: 0
                                                //allowLeadingZeros: true
                                            }
                                        }}
                                        value={telefono}
                                        onChange={handleChangeTelefono}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        getOptionLabel={option =>
                                            option.locNombre
                                        }
                                        noOptionsText=""
                                        onBlur={handleBlur}
                                        onChange={handleChangeCiudad}
                                        onClose={handleCloseCiudades}
                                        options={ciudades}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorCiudad}
                                                helperText={
                                                    errorCiudad
                                                        ? 'Seleccione ciudad'
                                                        : ''
                                                }
                                                label="Ciudad"
                                                margin="normal"
                                                onChange={handleChangeText}
                                                placeholder="Buscar..."
                                                style={{ marginTop: '0px' }}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            error={errorSintomas}
                                            helperText={
                                                errorSintomas &&
                                                'Ingrese sintomas'
                                            }
                                            label={'Sintomas*'
                                            }
                                            variant="outlined"
                                            name="sintomas"
                                            value={formState.sintomas || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {(agendamiento === 'LABORA' ||
                                    agendamiento === 'CIRPRO') && (
                                    <Grid item md={10} xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: 10 }}
                                            onClick={handleOpenSubir}
                                            size="small">
                                            <CloudUpload /> Subir archivos
                                        </Button>
                                        {file !== null
                                            ? file.map((item, index) => {
                                                  return (
                                                      <Typography key={index}>
                                                          {item.name}
                                                      </Typography>
                                                  );
                                              })
                                            : ''}
                                    </Grid>
                                )}
                                </Grid>
                                </CardContent>
                                <CardHeader title="Estado agendamiento" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    //className={classes.item}
                                    item
                                    md={4}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        error={errorEstatus}
                                        helperText={
                                            errorEstatus && 'Seleccione estatus'
                                        }
                                        variant="outlined"
                                        label="Estatus"
                                        name="estatus"
                                        value={formState.estatus}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true
                                        }}>
                                        <option value="sel">
                                            Seleccione...
                                        </option>
                                        {estados.map(option => (
                                            <option
                                                key={option.detCodigo}
                                                value={option.detCodigo}>
                                                {option.detNombre}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label={
                                            'Observaciones(Paciente)'
                                        }
                                        variant="outlined"
                                        name="observacionesAgendamiento"
                                        value={
                                            formState.observacionesAgendamiento ||
                                            ''
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>


                                <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        disabled={desactivar}
                                        color="primary"
                                        style={{ marginRight: 10 }}
                                        onClick={handleSave}
                                        size="small">
                                        <Save /> Guardar
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <Schedule /> Agendar
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </CardActions>

                    </>
                )}
                </>
            :<>

            {//parte de no cliente
            }
             {activarTitular && (
                    <>
                        <CardHeader title="Datos titular" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        onClick={handleOpenModal}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        <Search /> Buscar afiliado
                                    </Button>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaTitular}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        variant="outlined"
                                        value={`${nameTitular} ${lastNameTitular}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Plan"
                                        variant="outlined"
                                        value={plan}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Preexistencia"
                                        variant="outlined"
                                        value={preexistencia}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </>
                )}
                 {nameTitular.length > 0 ? (
                    <>
                        <CardHeader title="Datos paciente" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={6} xm={12}>
                                    <Autocomplete
                                        disabled={activarComboPaciente}
                                        getOptionLabel={option =>
                                            option.perIdentificacion +
                                            ' - ' +
                                            option.perNombres +
                                            '  ' +
                                            option.perApellidos +
                                            ' - ' +
                                            option.nombre2
                                        }
                                        onChange={(e, value) =>
                                            handleOnChangePaciente(value)
                                        }
                                        onClose={handleClosePaciente}
                                        options={dataPaciente}
                                        //value={null}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorPaciente}
                                                fullWidth
                                                helperText={
                                                    errorPaciente &&
                                                    'Seleccione un paciente'
                                                }
                                                label="Buscar Paciente"
                                                margin="none"
                                                variant="outlined"
                                                required
                                            />
                                        )}
                                        //style={{ width: 300, paddingBlockEnd: 20 }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} />
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Cédula"
                                        variant="outlined"
                                        value={cedulaPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombres"
                                        variant="outlined"
                                        value={`${namePaciente} ${lastNamePaciente}`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                {/*  <Grid
                                    item
                                    md={3}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        label="Apellidos"
                                        variant="outlined"
                                        value={lastNamePaciente}
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                    />
                                </Grid> */}
                                <Grid item md={1} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Edad"
                                        variant="outlined"
                                        value={edadPaciente}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Correo"
                                        variant="outlined"
                                        value={emailPaciente}
                                        InputProps={
                                            {
                                                //readOnly: true
                                            }
                                        }
                                        onChange={handleEmail}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Teléfono"
                                        name="telefono"
                                        variant="outlined"
                                        InputProps={{
                                            inputComponent: NumberFormatMoney,
                                            inputProps: {
                                                style: { textAlign: 'right' },
                                                decimalScale: 0
                                                //allowLeadingZeros: true
                                            }
                                        }}
                                        value={telefono}
                                        onChange={handleChangeTelefono}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        getOptionLabel={option =>
                                            option.locNombre
                                        }
                                        noOptionsText=""
                                        onBlur={handleBlur}
                                        onChange={handleChangeCiudad}
                                        onClose={handleCloseCiudades}
                                        options={ciudades}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                error={errorCiudad}
                                                helperText={
                                                    errorCiudad
                                                        ? 'Seleccione ciudad'
                                                        : ''
                                                }
                                                label="Ciudad"
                                                margin="normal"
                                                onChange={handleChangeText}
                                                placeholder="Buscar..."
                                                style={{ marginTop: '0px' }}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                {agendamiento !== 'LABORA' ? (
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            error={errorSintomas}
                                            helperText={
                                                errorSintomas &&
                                                'Ingrese sintomas'
                                            }
                                            label={
                                                agendamiento === 'CIRPRO' ||
                                                agendamiento === 'LABORA'
                                                    ? 'Diagnostico'
                                                    : 'Sintomas'
                                            }
                                            variant="outlined"
                                            name="sintomas"
                                            value={formState.sintomas || ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                ) : null}
                                {/* <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Observaciones paciente"
                                        variant="outlined"
                                        name="observacionesPaciente"
                                        value={formState.observacionesPaciente || ''}
                                        onChange={handleChange}


                                    />
                                </Grid> */}
                            </Grid>
                        </CardContent>

                        {agendamiento === 'LABORA' ? (
                            <>
                                <CardHeader title="Datos Auditoria" />
                                <Divider />

                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Examenes autorizados"
                                                variant="outlined"
                                                name="examenes"
                                                value={formState.examenes || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Diagnostico"
                                                variant="outlined"
                                                name="diagnostico"
                                                value={
                                                    formState.diagnostico || ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Indicaciones adicionales"
                                                variant="outlined"
                                                name="indicaciones"
                                                value={
                                                    formState.indicaciones || ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DatePicker
                                                disabled={typeUser}
                                                format="DD/MM/YYYY"
                                                inputVariant="outlined"
                                                label="Fecha de aprobación"
                                                onChange={setFechaAprovacion}
                                                value={fechaAprovacion}
                                                //views={['month']}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        ) : (
                            ''
                        )}
                        {agendamiento === 'CIRPRO' && (
                            <>
                                <CardHeader title="Observaciones médico" />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Observaciones medico"
                                                variant="outlined"
                                                name="observacionesMedico"
                                                value={
                                                    formState.observacionesMedico ||
                                                    ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {/* <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto cobertura"
                                                variant="outlined"
                                                name="montoCobertura"
                                                value={montoCobertura}
                                                onChange={handleChangeMonto}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </CardContent>
                            </>
                        )}
                        {agendamiento === 'HOSPIT' && (
                            <>
                                <CardHeader title="Observaciones médico" />
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser
                                                }}
                                                label="Observaciones medico"
                                                variant="outlined"
                                                name="observacionesMedico"
                                                value={
                                                    formState.observacionesMedico ||
                                                    ''
                                                }
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </>
                        )}
                        <CardHeader title="Datos prestador" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        onClick={handleOpenModalPrestador}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        <Search /> Buscar prestador
                                    </Button>
                                </Grid>
                                <Grid item md={10} xs={12}></Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Prestador"
                                        variant="outlined"
                                        value={nombrePrestador}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nombre médico"
                                        variant="outlined"
                                        name="nombreMedico"
                                        value={formState.nombreMedico || ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {agendamiento !== 'LABORA' ? (
                                    <Grid item md={4} xs={12}>
                                        <Autocomplete
                                            getOptionLabel={option =>
                                                option.detNombre
                                            }
                                            onChange={(e, value) =>
                                                handleOnChangeEspecialidad(
                                                    value
                                                )
                                            }
                                            onClose={handleCloseEspecialidad}
                                            options={especialidades}
                                            //value={null}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    //error={this.state.errorPaciente}
                                                    fullWidth
                                                    //helperText={this.state.errorPaciente && 'Campo obligatorio'}
                                                    label="Buscar especialidad"
                                                    margin="none"
                                                    variant="outlined"
                                                    required
                                                />
                                            )}
                                            //style={{ width: 300, paddingBlockEnd: 20 }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </CardContent>

                        <CardHeader title="Detalle cita" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        inputVariant="outlined"
                                        label="Fecha de la cita"
                                        onChange={setFechaCita}
                                        value={fechaCita}
                                        //views={['month']}
                                    />
                                </Grid>

                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    {agendamiento === 'LABORA' ? (
                                        <KeyboardTimePicker
                                            margin="dense"
                                            ampm={false}
                                            invalidDateMessage="Hora inválida"
                                            id="time-picker"
                                            label="Hora"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time'
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                        />
                                    ) : (
                                        <KeyboardTimePicker
                                            margin="dense"
                                            ampm={false}
                                            invalidDateMessage="Hora inválida"
                                            id="time-picker"
                                            label="Hora"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time'
                                            }}
                                            variant="inline"
                                            inputVariant="outlined"
                                        />
                                    )}
                                </Grid>

                                <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Notificación"
                                        name="notificacion"
                                        value={formState.notificacion}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true
                                        }}
                                        //helperText="Please select your currency"
                                    >
                                        <option value="sel">
                                            Seleccione...{' '}
                                        </option>
                                        <option value="1">SI </option>
                                        <option value="0">NO </option>
                                    </TextField>
                                </Grid>

                                {/*  <Grid
                                    //className={classes.item}
                                    item
                                    md={2}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        variant="outlined"
                                        label="Asistencia"
                                        name="asistencia"
                                        value={formState.asistencia}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    //helperText="Please select your currency"
                                    >
                                        <option value="SI">SI  </option>
                                        <option value="NO">NO  </option>
                                       
                                    </TextField>

                                </Grid> */}

                                <Grid item md={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label={
                                            agendamiento === 'LABORA'
                                                ? 'Observaciones'
                                                : 'Observaciones asistencia'
                                        }
                                        variant="outlined"
                                        name="observacionesAsistencia"
                                        value={
                                            formState.observacionesAsistencia ||
                                            ''
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {(agendamiento === 'CIRPRO' ||
                                    agendamiento === 'HOSPIT') && (
                                    <>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto facturado"
                                                variant="outlined"
                                                name="montoFacturado"
                                                value={montoFacturado || ''}
                                                onChange={
                                                    handleChangeMontoFacturado
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto no cubierto"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoNoCubierto || ''}
                                                onChange={
                                                    handleChangeMontoNoCubierto
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto cubierto"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoCubierto || ''}
                                                onChange={
                                                    handleChangeMontoCubierto
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Copago"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={copago || ''}
                                                onChange={handleChangeCopago}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Valor copago"
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                name="costo"
                                                value={costo || ''}
                                                onChange={handleChangeCosto}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Deducible"
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                variant="outlined"
                                                //name="costo"
                                                value={deducible || ''}
                                                onChange={handleChangeDeducible}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                InputProps={{
                                                    readOnly: typeUser,
                                                    inputComponent: NumberFormatMoney,
                                                    inputProps: {
                                                        style: {
                                                            textAlign: 'right'
                                                        },
                                                        decimalScale: 2
                                                    }
                                                }}
                                                label="Monto a pagar MEDI"
                                                variant="outlined"
                                                //name="montoFacturado"
                                                value={montoCobertura || ''}
                                                onChange={handleChangeMonto}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {agendamiento === 'ODONTO' ? (
                                    <Grid item md={2} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Costo"
                                            variant="outlined"
                                            name="costo"
                                            InputProps={{
                                                inputComponent: NumberFormatMoney,
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'right'
                                                    },
                                                    decimalScale: 2
                                                }
                                            }}
                                            value={costo}
                                            onChange={handleChangeCosto}
                                        />
                                    </Grid>
                                ) : (
                                    ''
                                )}
                                {agendamiento === 'ODONTO' && (
                                    <Grid item md={2} xs={12}>
                                        <TextField
                                            error={errorMontoDisponible}
                                            helperText={
                                                errorMontoDisponible &&
                                                'Excedió el monto disponible'
                                            }
                                            fullWidth
                                            label="Monto disponible"
                                            InputProps={{
                                                inputComponent: NumberFormatMoney,
                                                readOnly: true,
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'right'
                                                    },
                                                    allowLeadingZeros: true,
                                                    decimalScale: 2
                                                }
                                            }}
                                            variant="outlined"
                                            name="monto"
                                            value={monstoDisponible || 0}
                                            //onChange={handleChangeCosto}
                                        />
                                    </Grid>
                                )}
                                <Grid item md={10} xs={12}></Grid>
                                {(agendamiento === 'LABORA' ||
                                    agendamiento === 'CIRPRO') && (
                                    <Grid item md={10} xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: 10 }}
                                            onClick={handleOpenSubir}
                                            size="small">
                                            <CloudUpload /> Subir archivos
                                        </Button>
                                        {file !== null
                                            ? file.map((item, index) => {
                                                  return (
                                                      <Typography key={index}>
                                                          {item.name}
                                                      </Typography>
                                                  );
                                              })
                                            : ''}
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                        <CardHeader title="Estado agendamiento" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid
                                    //className={classes.item}
                                    item
                                    md={4}
                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        error={errorEstatus}
                                        helperText={
                                            errorEstatus && 'Seleccione estatus'
                                        }
                                        variant="outlined"
                                        label="Estatus"
                                        name="estatus"
                                        value={formState.estatus}
                                        onChange={handleChange}
                                        SelectProps={{
                                            native: true
                                        }}>
                                        <option value="sel">
                                            Seleccione...
                                        </option>
                                        {estados.map(option => (
                                            <option
                                                key={option.detCodigo}
                                                value={option.detCodigo}>
                                                {option.detNombre}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label={
                                            agendamiento === 'LABORA'
                                                ? 'Observaciones agendador'
                                                : 'Observaciones(Paciente)'
                                        }
                                        variant="outlined"
                                        name="observacionesAgendamiento"
                                        value={
                                            formState.observacionesAgendamiento ||
                                            ''
                                        }
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        disabled={desactivar}
                                        color="primary"
                                        style={{ marginRight: 10 }}
                                        onClick={handleSave}
                                        size="small">
                                        <Save /> Guardar
                                    </Button>
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <Schedule /> Agendar
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </CardActions>
                    </>
                ) : (
                    ''
                )}
            </>}
               
               
            </Card>
        </div>
    );
}
