/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    Switch,
    Typography
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    AccountCircle,
    BorderColor,
    Email,
    Face,
    KeyboardBackspace,
    Visibility,
    VpnKey
} from '@material-ui/icons';
import UserContext from 'context/Users/UserContext';
import { makeStyles } from '@material-ui/styles';
import LoadingComponent from 'components/LoadingComponent';

const useStyles = makeStyles(theme => ({
    errorTab: {
        color: '#ff0000'
    }
}));
const Results = ({ setView }) => {
    const classes = useStyles();
    const {
        roles,
        selectuser,
        rolesByUser,
        loading,
        saveUser,
        upadateUser,
        exitEdit
    } = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [rolesCheck, setRolesCheck] = useState([]);
    const [rolesEditNew, setRolesEditNew] = useState([]);
    const formik = useFormik({
        initialValues: {
            usuNombre: '',
            usuUsuario: '',
            usuDescripcion: '',
            usuEmail: '',
            usuPassword: '',
            confirm: '',
            rol: [],
            usuEstado: ''
        },
        validationSchema: Yup.object({
            usuNombre: Yup.string().required('Campo obligatorio*'),
            usuUsuario: Yup.string().required('Campo obligatorio*'),
            usuDescripcion: Yup.string().required('Campo obligatorio*'),
            usuEmail: Yup.string()
                .required('Campo obligatorio*')
                .email('Ingrese un email válido*'),
            usuPassword: Yup.string().required('Campo obligatorio*'),
            confirm: Yup.string()
                .required('Campo obligatorio*')
                .oneOf(
                    [Yup.ref('usuPassword'), null],
                    'Las contraseñas no coinciden*'
                ),
            rol: Yup.array().required(
                'Seleccione uno o mas roles para continuar'
            )
        }),
        onSubmit: values => {
            console.log(values);
            if (selectuser) {
                values.usuCodigo = selectuser.usuCodigo;
                values.createRol = rolesEditNew;
                upadateUser(values);
                const rolesNew = roles.map(e => ({ ...e, checked: false }));
                setRolesCheck(rolesNew);
                formik.resetForm();
                setView(1);
            } else {
                values.usuEstado = 1;
                saveUser(values);
                const rolesNew = roles.map(e => ({ ...e, checked: false }));
                setRolesCheck(rolesNew);
                formik.resetForm();
                setView(1);
            }
        }
    });

    const submitFomr = () => {
        console.log(rolesEditNew);
        console.log('PRIMER', formik.values.rol.length);
        if (formik.values.rol.length === 0) {
            formik.setFieldValue('rol', rolesEditNew);
        }
        return formik.submitForm();
    };

    const backButton = () => {
        exitEdit();
        setView(1);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const rolChange = (e, value) => {
        console.log(e, value);
        if (e.target.checked) {
            if (selectuser) {
                console.log('aki');
                let arrayValues = formik.values.rol;

                const existRol = arrayValues.filter(
                    e => e.rolCodigo === value.rolCodigo
                );
                if (existRol.length === 0) {
                    setRolesCheck(
                        rolesCheck.map(e =>
                            e.rolCodigo === value.rolCodigo
                                ? { ...e, checked: true }
                                : e
                        )
                    );
                    console.log(value);
                    return setRolesEditNew([...rolesEditNew, value]);
                }
                arrayValues = arrayValues.map(e =>
                    e.rolCodigo === value.rolCodigo
                        ? { ...e, rouEstado: 1, checked: true }
                        : e
                );
                formik.setFieldValue('rol', arrayValues);
                return setRolesCheck(
                    rolesCheck.map(e =>
                        e.rolCodigo === value.rolCodigo
                            ? { ...e, checked: true }
                            : e
                    )
                );
            }
            formik.setFieldValue('rol', [...formik.values.rol, value]);
            setRolesCheck(
                rolesCheck.map(e =>
                    e.rolCodigo === value.rolCodigo
                        ? { ...e, checked: true }
                        : e
                )
            );
        } else {
            if (selectuser) {
                let arrayValues = formik.values.rol;
                const existRol = arrayValues.filter(
                    e => e.rolCodigo === value.rolCodigo
                );
                if (existRol.length === 0) {
                    setRolesCheck(
                        rolesCheck.map(e =>
                            e.rolCodigo === value.rolCodigo
                                ? { ...e, checked: false }
                                : e
                        )
                    );
                    return setRolesEditNew(
                        rolesEditNew.filter(
                            e => e.rolCodigo !== value.rolCodigo
                        )
                    );
                }
                arrayValues = arrayValues.map(e =>
                    e.rolCodigo === value.rolCodigo
                        ? { ...e, rouEstado: 0, checked: false }
                        : e
                );
                formik.setFieldValue('rol', arrayValues);
                return setRolesCheck(
                    rolesCheck.map(e =>
                        e.rolCodigo === value.rolCodigo
                            ? { ...e, checked: false }
                            : e
                    )
                );
            }
            const filter = formik.values.rol.filter(
                e => e.rolCodigo !== value.rolCodigo
            );
            formik.setFieldValue('rol', filter);
            setRolesCheck(
                rolesCheck.map(e =>
                    e.rolCodigo === value.rolCodigo
                        ? { ...e, checked: false }
                        : e
                )
            );
        }
    };
    useEffect(() => {
        if (selectuser) {
            formik.setFieldValue('usuUsuario', selectuser.usuUsuario);
            formik.setFieldValue('usuNombre', selectuser.usuNombre);
            formik.setFieldValue('usuDescripcion', selectuser.usuDescripcion);
            formik.setFieldValue('usuEmail', selectuser.usuEmail);
            formik.setFieldValue('usuEstado', selectuser.usuEstado);
            formik.setFieldValue('usuPassword', 'No se puede editar');
            formik.setFieldValue('confirm', 'No se puede editar');
        }
        if (roles.length > 0) {
            const rolesNew = roles.map(e => ({ ...e, checked: false }));
            setRolesCheck(rolesNew);
        }
    }, [selectuser]);
    useEffect(() => {
        if (rolesByUser.length > 0) {
            formik.setFieldValue(
                'rol',
                rolesByUser.filter(e => e.rouEstado)
            );
            setRolesCheck(rolesByUser);
        }
    }, [rolesByUser]);

    return (
        <>
            <Card elevation={3} style={{ padding: 10 }}>
                <Button
                    color="primary"
                    onClick={() => backButton()}
                    size="small"
                    startIcon={<KeyboardBackspace />}
                    style={{ marginRight: 5 }}
                    variant="contained">
                    Regresar
                </Button>
            </Card>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ maxWidth: 400, marginTop: 10, padding: 10 }}>
                    <Tabs
                        indicatorColor="primary"
                        onChange={handleChange}
                        textColor="primary"
                        value={value}>
                        <Tab label="Datos de usuario" />
                        <Tab
                            className={
                                formik.errors.rol && formik.touched.rol
                                    ? classes.errorTab
                                    : null
                            }
                            label="Roles de usuario"
                        />
                    </Tabs>
                    <CardContent>
                        {value === 0 ? (
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <Grid
                                        item
                                        xs={6}
                                        container
                                        alignItems="center">
                                        <Typography>
                                            Activar/Desctivar Usuario
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        container
                                        justify="flex-end">
                                        <Switch
                                            checked={
                                                formik.values.usuEstado === 1
                                                    ? true
                                                    : false
                                            }
                                            inputProps={{
                                                'aria-label':
                                                    'secondary checkbox'
                                            }}
                                            name="checkedA"
                                            onChange={e =>
                                                formik.setFieldValue(
                                                    'usuEstado',
                                                    e.target.checked ? 1 : 0
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        paddingRight: 10
                                                    }}>
                                                    <AccountCircle />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            formik.errors.usuUsuario &&
                                            formik.touched.usuUsuario
                                                ? true
                                                : false
                                        }
                                        fullWidth
                                        helperText={
                                            formik.errors.usuUsuario &&
                                            formik.touched.usuUsuario
                                                ? formik.errors.usuUsuario
                                                : null
                                        }
                                        label="Usuario"
                                        name="usuUsuario"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        size="small"
                                        value={formik.values.usuUsuario}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        paddingRight: 10
                                                    }}>
                                                    <Face />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            formik.errors.usuNombre &&
                                            formik.touched.usuNombre
                                                ? true
                                                : false
                                        }
                                        fullWidth
                                        helperText={
                                            formik.errors.usuNombre &&
                                            formik.touched.usuNombre
                                                ? formik.errors.usuNombre
                                                : null
                                        }
                                        label="Nombre del usuario"
                                        name="usuNombre"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        size="small"
                                        value={formik.values.usuNombre}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        paddingRight: 10
                                                    }}>
                                                    <BorderColor />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            formik.errors.usuDescripcion &&
                                            formik.touched.usuDescripcion
                                                ? true
                                                : false
                                        }
                                        fullWidth
                                        helperText={
                                            formik.errors.usuDescripcion &&
                                            formik.touched.usuDescripcion
                                                ? formik.errors.usuDescripcion
                                                : null
                                        }
                                        label="Descripción"
                                        name="usuDescripcion"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        size="small"
                                        value={formik.values.usuDescripcion}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={{
                                                        paddingRight: 10
                                                    }}>
                                                    <Email />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={
                                            formik.errors.usuEmail &&
                                            formik.touched.usuEmail
                                                ? true
                                                : false
                                        }
                                        fullWidth
                                        helperText={
                                            formik.errors.usuEmail &&
                                            formik.touched.usuEmail
                                                ? formik.errors.usuEmail
                                                : null
                                        }
                                        label="Correo Electrónico"
                                        name="usuEmail"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        size="small"
                                        value={formik.values.usuEmail}
                                        variant="outlined"
                                    />
                                </Grid>
                                {!selectuser && (
                                    <Grid item xs={12}>
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        style={{
                                                            paddingRight: 10
                                                        }}>
                                                        <VpnKey />
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled={selectuser ? true : false}
                                            error={
                                                formik.errors.usuPassword &&
                                                formik.touched.usuPassword
                                                    ? true
                                                    : false
                                            }
                                            fullWidth
                                            helperText={
                                                formik.errors.usuPassword &&
                                                formik.touched.usuPassword
                                                    ? formik.errors.usuPassword
                                                    : null
                                            }
                                            label="Contraseña"
                                            name="usuPassword"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            size="small"
                                            type="password"
                                            value={formik.values.usuPassword}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                                {!selectuser && (
                                    <Grid item xs={12}>
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        style={{
                                                            paddingRight: 10
                                                        }}>
                                                        <VpnKey />
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled={selectuser ? true : false}
                                            error={
                                                formik.errors.confirm &&
                                                formik.touched.confirm
                                                    ? true
                                                    : false
                                            }
                                            fullWidth
                                            helperText={
                                                formik.errors.confirm &&
                                                formik.touched.confirm
                                                    ? formik.errors.confirm
                                                    : null
                                            }
                                            label="Confirmar contraseña"
                                            name="confirm"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            size="small"
                                            type="password"
                                            value={formik.values.confirm}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        component="fieldset"
                                        error={
                                            formik.errors.rol &&
                                            formik.touched.rol
                                                ? true
                                                : false
                                        }>
                                        <FormLabel component="legend">
                                            Selecciones los roles para el
                                            usuario...
                                        </FormLabel>

                                        <FormGroup>
                                            {rolesCheck.map(e => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={e.checked}
                                                            onChange={event =>
                                                                rolChange(
                                                                    event,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    }
                                                    key={e.rolCodigo}
                                                    label={e.rolNombre}
                                                />
                                            ))}
                                        </FormGroup>

                                        {formik.errors.rol &&
                                        formik.touched.rol ? (
                                            <FormHelperText>
                                                {formik.errors.rol}
                                            </FormHelperText>
                                        ) : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                    <CardActions>
                        {selectuser ? (
                            <Button
                                color="primary"
                                fullWidth
                                onClick={submitFomr}
                                variant="contained">
                                Actualizar
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                fullWidth
                                onClick={formik.submitForm}
                                variant="contained">
                                Guardar
                            </Button>
                        )}
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default Results;
