/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Label, SearchBasic } from 'components';
import { QueriesProvider } from 'providers/queries.service';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import  ModalDetalleHonorarios from '../ModalDetalleHonorarios';
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    colors,   
    CircularProgress,
    CardHeader,
    IconButton
} from '@material-ui/core';
import validate from 'validate.js';
import { DisplayMessage } from 'notifications/displayMessage';
import { Cancel } from '@material-ui/icons';



const schema = {
    talObservacion: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },    
};


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 1000,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        margin:[15,15,15,15]
    },
    container: {
        marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));




const ModalAddHonorarios = props => {
    const { enviarCodigo, open, dataHonorariosEnviar,  resetData, onClose, className, filaActiva, filaActivaObs, ...rest } = props;
    const classes = useStyles();
    const [eventSelected, setEventSelected] = React.useState(true);    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const displayMessage = new DisplayMessage();
    const [data, setData] = useState([]);
    const [obtenerCantidad, setObtenerCantidad] = useState(0);
    const queries = new QueriesProvider();
    //const [cargando, setCargando] = useState(false);
    const [buscarTitular, setBuscarTitular] = useState('');
    const [prueba, setPrueba] = useState([]);
    const [elementosHonorario,setElementosHonorario]=useState([]);
    const [ModalHonorarios, setModalHonorarios] = React.useState(false);
    const [codigo,setCodigo]=useState('');
    const [cantidad,setCantidad]=useState(0);
    useEffect(() => {
        setFormState({
            
            isValid: false,
            values: {talObservacion: filaActivaObs},
            touched: {},
            errors: {}
        });

    }, [resetData]);

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}            
        }));        
    }, [formState.values]);
    
    const handleSaveObservacion = () => {
        //setModalHonorarios(true)
        onClose(formState.values.talObservacion, filaActiva);
        
    }

    
    const handleBuscarTitular = () => {
        setData(0); 
        if (buscarTitular === '') {
            displayMessage.error('Ingrese un valor a buscar.');
            setData([]);
        } else {
            queries.buscarHonorarioMed(enviarCodigo,buscarTitular)
                .then(
                    user => {
                        if (user.error.codigo === '0') {
                            setData(user.bodyOut.data);
                        } else {
                            setData([]);
                            displayMessage.error('La consulta no arrojo resultados')
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');

                    }
                );
        }
    }

 



const handleClose = () => {
    /* props.close();
    setBuscarTitular('');
    setBuscarApellido('');
    setBuscarNombre('');
    setData([]); */
}


    const  honorarios = (datos) =>{
       
            dataHonorariosEnviar(datos)
            onClose()
            setData([])
            setBuscarTitular('')
 
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setData([]);
    }
    const handleChange2 = (e) => {
        setBuscarTitular(e.target.value);
    } 

    return (
        
      

        <Modal 
            onClose={() => {
                onClose(filaActivaObs, filaActiva);
            }}
            open={open}
            
        >
             
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                 <CardHeader
                 title='Agregar Honorarios Medicos'
                    action={<IconButton
                        color="secondary"
                        onClick={() => {
                            onClose(filaActivaObs, filaActiva);
                        }}
                    ><Cancel /></IconButton>}
                   
                />
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item                                
                                xs={12}
                            >
                            <SearchBasic
                                                searchBar={buscarTitular}
                                                cleanSearchBar={handleLimpiar}
                                                handleInputChange={handleChange2}
                                                complementaryMessage='Buscar'
                                                handleSearch={handleBuscarTitular}
                                            /> 
                                
                            </Grid>  
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actions}>
                       
                    </CardActions>
                
                            <MaterialTable
                            style={{margin:[15,50,15,15]}}
                                actions={[
                                    {
                                        icon: 'check',
                                        tooltip: 'Seleccionar',
                                        onClick: (event, rowData) => {honorarios(rowData)}
                                        //onClick: (event, rowData) => this.honorarios(true, rowData)
                                        
                                    }
                                ]}
                                columns={[
                                    { title: 'Nombre', field: 'detDescripcion' , width: '5%'},
                                    { title: 'Código', field: 'honCodHon' , width: '5%'},
                                    { title: 'Descripcion', field: 'honDescripcion' },
                                    { title: 'UVR', field: 'honUnidad' , width: '5%'},
                                    { title: 'FCN', field: 'hopValor' , width: '5%'},
                                     ]}
                                data={data}
                                
                                localization={{
                                    body:{
                                        emptyDataSourceMessage:'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        search: false,
                                        exportName: 'Exportar a CSV',
                                        exportAriaLabel: 'Exportar',
                                        exportTitle: 'Exportar'
                                    },
                                    pagination:{
                                        labelRowsSelect:'Filas',
                                        labelDisplayedRows:'{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'
          

                                    }
                                }}
                                options={{exportButton: false, search:false, toolbar: false}}
                                title="CIE10"
                            />
                        
                       {/*  <ModalDetalleHonorarios
                            open={ModalHonorarios}     
                            elementosHonorario={elementosHonorario} 
                            obtenerCantidad={document.getElementById('Cantidad')}
                    />   */}

            </Card>
            
        </Modal>
    );
};

export default ModalAddHonorarios;