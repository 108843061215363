import React, {useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Checkbox,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service'
import  ModalAddObservacion from '../ModalAddObservacion';
import  ModalDetalleHonorarios from '../ModalDetalleHonorarios';
import useRouter from 'utils/useRouter';
import { PolizaProvider  } from 'providers/poliza.service';
import NumberFormatMoney from 'components/NumberFormatMoney';


const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    wrapper: {
        textAlign:'right',
        position: 'relative',
        marginRight: 20,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -4,
        marginLeft: -12,
    },
}));

const PrestacionesItems = props => {
    const { enviarCodigo, data, liqCode, session, totalHonorario, className, dataHonorariosEnviar, ...rest } = props;
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();
    const [dataCombo, setDataCombo] = React.useState([]);
    const [eventSelected, setEventSelected] = React.useState(true);
    const [eventCheck, setEventCheck] = React.useState(true);
    const prestaciones = new DocumentDigitalizeProvider();
    const [guardando, setGuardando] = React.useState(false);
    const router = useRouter();
    const polizaProvider = new PolizaProvider();
    const [openConfirmation ,setOpenConfirmation] = React.useState(false)
    const [disableSave, setDisableSave] = React.useState(false);
    const [openObs, setOpenObs] = React.useState(false);
    const [resetData, setResetData] = React.useState(true);
    const [resetData2, setResetData2] = React.useState(true);
    const [filaActiva, setFilaActiva] = React.useState(true);
    const [filaActiva2, setFilaActiva2] = React.useState(true);
    const [filaActivaObs, setFilaActivaObs] = React.useState(true);
    const [filaActivaObs2, setFilaActivaObs2] = React.useState(true);
    const [enviarContabilidad, setEnviarContabilidad] = React.useState(false);
    const detalleCatalogo = new DetalleCatalogoProvider();
    const [refresh, setRefresh] = React.useState(false);  
    const [cargaRefresh, setCargaRefresh] = React.useState(false);  
    const [ModalHonorarios, setModalHonorarios] = React.useState(false);
    const [poliza, setPoliza] = useState(null);
    const [totalEnvio, setTotalEnvio] = React.useState(0);
    const [llegavalor, setLlegavalor] = React.useState(0);
    const [sumaHonorario, setSumaHonorario] = useState(0); 
    const [cantidadFCM, setCantidadFCM] = useState(0); 
    const [verTotalAudit, setVerTotalAudit] = useState(false);
 
    const classes = useStyles();

    const handleChange = (event, values) => {  
                    
        if(event.target.id ){
            
            var res = event.target.id.split('-');
            let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            let valorTarifado = data[parseInt(res[1])].detalle[parseInt(res[2])].talCantidadAutorizada * (values != null ? values.tarAuxiliar : 0);
            let valorRegistrado = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorRegistrado;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = valorTarifado;
            if (valorRegistrado <= valorTarifado) {
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorRegistrado; 
            } else {
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorTarifado; 
            }            
            data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = (values != null ? values.tarCodigo : null); 
            data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = values;
            data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux;
            data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux

            data[parseInt(res[1])].detalle[parseInt(res[2])].error = values != null ? false : true; 
          
            
            }
        
    }

    const handleSinTarifario=(index,index1)=>{
        let valorAux = data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado;
        data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado=data[parseInt(index)].detalle[parseInt(index1)].talValorRegistrado
        data[parseInt(index)].delValorObjetado=data[parseInt(index)].delValorObjetado-valorAux+data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado
        //data[parseInt(index)].delValorObjetado += data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado + valorAux;
        data.totalObjetado += data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado- valorAux
        data[parseInt(index)].detalle[parseInt(index1)].talValorTarifado=0.00;
        data[parseInt(index)].detalle[parseInt(index1)].dataTarifario=null
        data[parseInt(index)].detalle[parseInt(index1)].tarCodigo = null;
        
        setEventSelected(!eventSelected);
    }
    
    const handleClose = () => {
        setDataCombo([]); 
        
    }

    const handleCloseConfirmation = () => setOpenConfirmation(false);

    const handleBlur = (event) => {
        if (event.target.value === ''){
            var res = event.target.id.split('-');
                
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 100; 

            
            let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = 0; 
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 0; 
            data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = null;
            data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = null
            data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux;
            data[parseInt(res[1])].detalle[parseInt(res[2])].error = true;
            data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux
              
            setEventSelected(!eventSelected);
        }
    }
    

    const handleChangeText = (event) => {                
        let search = event.target.value;
        if (search.length >= 3) {
            var res = event.target.id.split('-');
            getTarifarioItems(search, res[3]);
        } else {
            setDataCombo([]);
        }
       /*  if(search.length = 0){
            var res = event.target.id.split('-');
            getTarifarioItems(search, res[3]);
        }else {
            setDataCombo([]);
        } */
    }

    const handleChangeCheck = (event, values) => {        
        if(event.target.id){
            
            var res = event.target.id.split('-');
   
         

            if(data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado===0.00){
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado=data[parseInt(res[1])].detalle[parseInt(res[2])].talValorRegistrado 
                }
            data[parseInt(res[1])].detalle[parseInt(res[2])].estado = values;
            data[parseInt(res[1])].detalle[parseInt(res[2])].talEstado = values ? 2 : 3;
            if (!data[parseInt(res[1])].detalle[parseInt(res[2])].talEstado){
                data[parseInt(res[1])].detalle[parseInt(res[2])].error = false
                data[parseInt(res[1])].detalle[parseInt(res[2])].errorCantAut = false
            }

            if (values){
                data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                //setVerTotalAudit(!verTotalAudit);
                
            } else {
                
                data[parseInt(res[1])].delValorObjetado -= data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data.totalObjetado -= data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                data.totalHonorarios -= data[parseInt(res[1])].detalle[parseInt(res[2])].talValorHonorarios;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorHonorarios=0.00;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talDetalleHonorario=''
               
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 0.00;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = 0.00;
                data[parseInt(res[1])].detalle[parseInt(res[2])].tarCodigo = null;
                data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario = null;
                
                //setVerTotalAudit(!verTotalAudit);
                
                
                
            }
            setEventSelected(!eventSelected); 
           
        }
    }   
    
    const handleChangeObs = (fila, valor) => {                
        var res = fila.split('-');
        data[parseInt(res[1])].detalle[parseInt(res[2])].tarObservacion = valor            
        setEventSelected(!eventSelected);

    }

    const handleChangeObs2 = (fila, valor) => {                
        var res = fila.split('-');
        //data[parseInt(res[1])].detalle[parseInt(res[2])].talValorHonorarios = valor            
        setEventSelected(!eventSelected);

    }

    
    const handleSave= async ( confirmar = false, auditar )=> {
        let dataEnviar = [];        
        let banError = false;
        data.map((item, index ) => {
            dataEnviar[index]={
                delCodigo: item.delCodigo, 
                delValorObjetado: parseFloat(parseFloat(item.delValorObjetado).toFixed(2)),
                detalle: [],

            };
            item.detalle.map((item1, index1) => {
                if (item1.tarCodigo == null && item1.estado ){
                    data[index].detalle[index1].error = true;
                    banError = true;
                } else {
                    data[index].detalle[index1].error = false;
                }
                
                if (!parseFloat(item1.talCantidadAutorizada) && item1.estado ) {
                    data[index].detalle[index1].errorCantAut = true;
                    banError = true;
                } else {
                    data[index].detalle[index1].errorCantAut = false;
                }

                if ((parseFloat(item1.talCantidadAutorizada) > parseFloat(item1.talCantidad)) && item1.estado ) {
                    data[index].detalle[index1].errorCantAutMayor = true;
                    banError = true;
                } else {
                    data[index].detalle[index1].errorCantAutMayor = false;
                }

                if ((parseFloat(item1.talValorObjetado) > parseFloat(item1.talValorRegistrado)) && item1.estado ) {
                    data[index].detalle[index1].errorValorObjetado = true;
                    banError = true;
                } else {
                    data[index].detalle[index1].errorValorObjetado = false;
                }

                dataEnviar[index].detalle[index1] = { 
                    talCodigo: item1.talCodigo,
                    talCantidadAutorizada: parseFloat(item1.talCantidadAutorizada),
                    talEstado: item1.talEstado,
                    talValorObjetado: parseFloat(parseFloat(item1.talValorObjetado).toFixed(2)),
                    talValorTarifado: parseFloat(parseFloat(item1.talValorTarifado).toFixed(2)), 
                    tarCodigo: item1.tarCodigo,
                    tarObservacion: item1.tarObservacion || '',

                    talDetalleHonorario: item1.talDetalleHonorario,
                    codigoHonorario: data[index].detalle[index1].honCodigo ? data[index].detalle[index1].honCodigo : [],
                    talValorHonorarios: parseFloat(parseFloat(data[index].detalle[index1].talValorHonorarios) ? parseFloat(data[index].detalle[index1].talValorHonorarios).toFixed(2) : 0) ,
                    /* valorFCM: parseFloat(parseFloat(data[index].detalle[index1].talValorHonorario) ? cantidadFCM : 0), */
                    valorFCM: data[index].detalle[index1].hopValor ? data[index].detalle[index1].hopValor : [],
                    valoresPorcentaje: data[index].detalle[index1].porcentaje ? data[index].detalle[index1].porcentaje : []
                };
                return [];
            })
            return [];
        });     
                



        /* if (banError){
            displayMessage.error('Complete la información.');
            setEventSelected(!eventSelected); 
        } else { */
            
            if (!confirmar) {
                setOpenConfirmation(true);
                setEnviarContabilidad(auditar);
            } else {
                setDisableSave(true);
                setGuardando(true);
                prestaciones.ingresoDetalleLiquidacion(2, dataEnviar).then(response => {            
                    if(response.error.codigo === '0'){
                        if (enviarContabilidad) {
                            liquidationProvider.requestMove(liqCode, 1, session.user.usuCodigo).then(response2 => {
                                if(response2.error.codigo === '0'){
                                    displayMessage.success('Datos actualizados correctamente.');
                                    router.history.push('/auditor/liquidaciones/auditoria');
                                }
                            },() => {          
                                displayMessage.error('Error de comunicación.');       
                                setGuardando(false); 
                                setDisableSave(false);
                                setOpenConfirmation(false);
                            }); 
                        } else {
                            displayMessage.success('Datos actualizados correctamente.');
                            setGuardando(false);
                            setDisableSave(false);
                            setOpenConfirmation(false);
                            router.history.push(`/auditor/auditoria/${btoa(liqCode) }`);
                        }
                    } else {
                        setGuardando(false);
                        setDisableSave(false);
                        setOpenConfirmation(false);
                    }                  
                },
                () => {          
                    displayMessage.error('Error de comunicación.');       
                    setGuardando(false); 
                    setDisableSave(false);
                    setOpenConfirmation(false);
                }); 
                    
       /*  }     */    
        }
    }

    const getTarifarioItems= ( search, tipo ) => {
        const parametros = {
            0: parseInt(tipo) === 1 ? "'TARNA', 'TARNAC'" : "'TARMED', 'TARMEDC'",
            1: '%' + search + '%',
            2: '%' + search + '%'
        };
        detalleCatalogo.getParamGeneric('CONTAR', parametros).then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setDataCombo(response.bodyOut.data);
            }              
        },
        () => {          
            displayMessage.error('Error de comunicación.');        
        }); 
    };

    const handleChangeCantAut = (event) => {
        
        if(event.target.id){
            var res = event.target.id.split('-');
            var dataTarifario = data[parseInt(res[1])].detalle[parseInt(res[2])].dataTarifario;    
            data[parseInt(res[1])].detalle[parseInt(res[2])].talCantidadAutorizada = event.target.value; 
            if ( dataTarifario) {
                let cantidad = event.target.value !== '' ? event.target.value : 0;
                let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
                let valorTarifado = parseFloat(cantidad) * dataTarifario.tarAuxiliar;
                let valorRegistrado = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorRegistrado;
                data[parseInt(res[1])].detalle[parseInt(res[2])].talValorTarifado = valorTarifado;
                if (valorRegistrado <= valorTarifado) {
                    data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorRegistrado; 
                } else {
                    data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorTarifado; 
                }                                        
                data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux;
                data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado - valorAux   
               
                data[parseInt(res[1])].detalle[parseInt(res[2])].errorCantAut = parseFloat(cantidad) ? false : true;                            
            }        
            setEventSelected(!eventSelected);
        }

    }

    
    const handleBlurCantAut = (event) => {
        if(event.target.value === ''){
            var res = event.target.id.split('-');
            data[parseInt(res[1])].detalle[parseInt(res[2])].talCantidadAutorizada = 0; 
            data[parseInt(res[1])].detalle[parseInt(res[2])].errorCantAut = true;
            //data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            //data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            setEventSelected(!eventSelected);
        } else {
            let res1 = event.target.id.split('-');
            let cantidadAutorizada = parseFloat(data[parseInt(res1[1])].detalle[parseInt(res1[2])].talCantidadAutorizada); 
            let cantidadIngresada = parseFloat(data[parseInt(res1[1])].detalle[parseInt(res1[2])].talCantidad); 
            if (cantidadAutorizada > cantidadIngresada) {
                data[parseInt(res1[1])].detalle[parseInt(res1[2])].errorCantAutMayor = true;
            } else {
                data[parseInt(res1[1])].detalle[parseInt(res1[2])].errorCantAutMayor = false;
            }
            setEventSelected(!eventSelected);
        }
    }

    const handleChangeValAut = (event) => {    
        if(event.target.id){
            var res = event.target.id.split('-');        
            let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            let valorObjetado = event.target.value !== '' ? event.target.value : 0
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = event.target.value;
            data[parseInt(res[1])].delValorObjetado += valorObjetado - valorAux;
            data.totalObjetado += valorObjetado - valorAux;
            
            setEventSelected(!eventSelected);
        }
    }

    
    const handleBlurValAut = (event) => {
        if(event.target.value === ''){
            var res = event.target.id.split('-');
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = 0; 
            //data[parseInt(res[1])].delValorObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            //data.totalObjetado += data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado;
            setEventSelected(!eventSelected);
        } else {
            let res1 = event.target.id.split('-');
            let autorizada = parseFloat(data[parseInt(res1[1])].detalle[parseInt(res1[2])].talValorObjetado); 
            let ingresada = parseFloat(data[parseInt(res1[1])].detalle[parseInt(res1[2])].talValorRegistrado); 
            if (autorizada > ingresada) {
                data[parseInt(res1[1])].detalle[parseInt(res1[2])].errorValorObjetado = true;
            } else {
                data[parseInt(res1[1])].detalle[parseInt(res1[2])].errorValorObjetado = false;
            }
            setEventSelected(!eventSelected);
        }
    }

    const handleOpenHonorarios = () =>{
        setModalHonorarios(true)
    }

    const getDataHonorarios = (data) =>{
        
    }

    const honorarios = (datos) =>{
        dataHonorariosEnviar(datos)
     
    }
  
    //llegar el index
    const getTotalHonorario = (total) =>{
       /*  let arregloAux = totalEnvio;
        var res = filaActiva2.split('-');
        setTotalEnvio(arregloAux);
        data[parseInt(res[1])].detalle[parseInt(res[2])].talValorHonorario=total;
        setCargaRefresh(!cargaRefresh) */
    }
    const getValorPorcentaje = (valor) =>{
        var res = filaActiva2.split('-');
        data[parseInt(res[1])].detalle[parseInt(res[2])].porcentaje=valor;
        setCargaRefresh(!cargaRefresh)
    }
    const getFCM = (valor) =>{
        /* setCantidadFCM(valor)
        setCargaRefresh(!cargaRefresh) */
        var res = filaActiva2.split('-');
        data[parseInt(res[1])].detalle[parseInt(res[2])].hopValor=valor;
        setCargaRefresh(!cargaRefresh)
    }
    const getCodigoHonorario = (valor) =>{
        var res = filaActiva2.split('-');
        data[parseInt(res[1])].detalle[parseInt(res[2])].honCodigo=valor;
        setCargaRefresh(!cargaRefresh)
    }
    const getDetalleHonorariosLlegada = (valor) =>{
        var res = filaActiva2.split('-');
        let sumaHonorario=parseFloat(0);
        valor.map(item => {
            sumaHonorario=parseFloat(sumaHonorario)+parseFloat(item.subtotalFinal);
        })
        
        let totalHonorario = 0
        data[parseInt(res[1])].detalle[parseInt(res[2])].talValorHonorarios = parseFloat(sumaHonorario).toFixed(2)
        data.map(item =>{
            item.detalle.map(item1 =>{
              totalHonorario=parseFloat(totalHonorario)+parseFloat(item1.talValorHonorarios)
            })
        })     
        let valorAux = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado
        let valorRegistrado = data[parseInt(res[1])].detalle[parseInt(res[2])].talValorRegistrado
        if(valorRegistrado >= parseFloat(sumaHonorario)){
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = parseFloat(sumaHonorario).toFixed(2);
        }else{
            data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado = valorRegistrado
        }
        
        data[parseInt(res[1])].delValorObjetado +=  data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado-data[parseInt(res[1])].delValorObjetado ;
        data.totalObjetado +=  data[parseInt(res[1])].detalle[parseInt(res[2])].talValorObjetado -valorAux;
        
        setEventSelected(!eventSelected);
        data.totalHonorarios=totalHonorario
        const dinamico = JSON.stringify(
            valor
        )
         
        data[parseInt(res[1])].detalle[parseInt(res[2])].talDetalleHonorario=dinamico; 
        setCargaRefresh(!cargaRefresh)
    }
 

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader title="Prestaciones" />
            <Divider />
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Sel.</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '30%'}}><b>Prestación</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '8%'}}><b>Cant.</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '8%'}}><b>Cant. Aut</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '10%'}}><b>Valor registrado</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '30%'}}><b>Tarifario</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Valor tarifario</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Valor auditado</b></TableCell>
                                    {/* <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Valor honorario</b></TableCell> */}
                                    <TableCell style={{ borderRight: '1px solid #eeeeee'}}><b>Total</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {data.map((item, index) => (
                                    <>
                                        <TableRow 
                                            key={'det'+item.delCodigo+index} 
                                            style={{ borderTop: '1px solid black'}}
                                        >
                                            <TableCell colSpan={9}>
                                                <b><i>{item.delServicio}-({item.delFactura})</i></b>
                                            </TableCell>

                                            
                                            
                                        </TableRow>
                                        {item.detalle.map((item1, index1) => (
                                            <TableRow 
                                                key={'tar'+item.delCodigo+item1.talCodigo} 
                                                style={{    backgroundColor: 'rgba(0, 0, 0, 0.02)'}}
                                            > 
                                                <TableCell 
                                                    padding="checkbox" 
                                                    style={{ textAlign: 'center', borderRight: '1px solid #eeeeee'}}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        //defaultChecked
                                                        id={'check-'+index+'-'+index1}
                                                        checked={data[index].detalle[index1].estado}
                                                        onChange={handleChangeCheck}
                                                    />
                                                   
                                                </TableCell>
                                                
                                                <TableCell style={{ borderRight: '1px solid #eeeeee'}}>
                                                {item1.talDescripcion}
                                                <p></p>
                                                    {(item1.talDescripcion.indexOf('HONORARIO')!==-1 ||
                                                    item.delServicio === 'CONSULTAS MEDICINA GENERAL Y EN LAS DIVERSAS ESPECIALIDADES Y SUB ESPECIALIDADES DERIVACIONES E INTERCONSULTAS' ||
                                                     item.delServicio === 'ATENCIÓN MÉDICA EN EL HOGAR'  ||
                                                     item.delServicio === 'CONSULTA Y MEDICAMENTOS DE MEDICINA ANCESTRAL Y ALTERNATIVA' ||
                                                     item.delServicio === 'VISITA MEDICA, INTERCONSULTA A PROFESIONALES')
                                                     &&
                                                    <Button
                                                        color="primary"
                                                        key={'tar1'+item.delCodigo+item1.talCodigo} 
                                                        onClick={() => {setModalHonorarios(true);setResetData2(!resetData2);setFilaActivaObs2(data[index].detalle[index1].talDetalleHonorario!==undefined?data[index].detalle[index1].talDetalleHonorario:''); setFilaActiva2('dethon-'+index+'-'+index1);}}
                                                        //onClick={handleOpenHonorarios}
                                                        
                                                        style={{ marginTop: 10}}
                                                        variant="contained"
                                                    >
                                                        Detalle Honorarios
                                                    </Button>}
                                                    &nbsp;&nbsp;    
                                                    {/* ***************************** */}
                                                    <Button
                                                        color="primary"
                                                        key={'tar2'+item.delCodigo+item1.talCodigo} 
                                                        onClick={() => {setOpenObs(true); setResetData(!resetData); setFilaActivaObs(data[index].detalle[index1].tarObservacion); setFilaActiva('obs-'+index+'-'+index1);}}
                                                        style={{ marginTop: 10}}
                                                        variant="contained"
                                                    >
                                                        Agregar observación
                                                    </Button>
                                                    {/* )} */}
                                                </TableCell>
                                                {/* cant */}
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>                                                    
                                                    {parseFloat(item1.talCantidad).toFixed(2)}
                                                </TableCell>
                                                {/* cant aut */}                                       
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    { (!data[index].detalle[index1].estado) && '0.00'}
                                                    {data[index].detalle[index1].estado  && 
                                                        <TextField
                                                            id={'cantidadaut-'+index+'-'+index1}
                                                            value={parseFloat(item1.talCantidadAutorizada).toFixed(2)}
                                                            error={item1.errorCantAut || item1.errorCantAutMayor}
                                                            helperText={item1.errorCantAut ? 'Requerido' : item1.errorCantAutMayor ? 'Es mayor': ''}
                                                            InputProps={{
                                                                inputComponent: NumberFormatMoney,
                                                                inputProps: { 
                                                                    style: { textAlign: 'right'},
                                                                    decimalScale: 2
                                                                }
                                                            }}
                                                            onChange={handleChangeCantAut}
                                                            onBlur={handleBlurCantAut}
                                                        />
                                                    }                                                    
                                                </TableCell>
                                                {/* VALOR REGISTRADO */}
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    {parseFloat(item1.talValorRegistrado).toFixed(2)}
                                                </TableCell>
                                                {/* TARIFARIO */}
                                                <TableCell style={{ borderRight: '1px solid #eeeeee'}}>
                                                    <div style={{ width: '100%' }}>
                                                        <Autocomplete
                                                            disabled={!data[index].detalle[index1].estado}
                                                            getOptionLabel={(option) => data[index].detalle[index1].estado ? (option.tarNombre ?option.tarNombre:'')+' '+(option.tarFormaFarma ? option.tarFormaFarma : '')+' '+(option.tarConcentracion ? option.tarConcentracion : '' )+'-'+(option.tarCodigoWmp ? option.tarCodigoWmp : ''):''}
                                                            id={'tarifa-'+index+'-'+index1+'-'+item1.copTipoTarifario}
                                                            noOptionsText=""
                                                            value={data[index].detalle[index1].dataTarifario}
                                                           /*  onBlur={(e, values)=>values ?
                                                                handleBlur(e,values):
                                                                data[parseInt(index)].detalle[parseInt(index1)].talValorObjetado=data[parseInt(index)].detalle[parseInt(index1)].talValorRegistrado}
                                                             */
                                                            onChange={(e, values)=>values ? handleChange(e,values):handleSinTarifario(index,index1)}
                                                            onClose={handleClose}  
                                                            options={dataCombo.length ? dataCombo : data[index].detalle[index1].dataTarifario ? [data[index].detalle[index1].dataTarifario] : []}                                                                                                                   
                                                            renderInput={(params) => (
                                                                <TextField 
                                                                    {...params} /* 
                                                                    error={item1.error}
                                                                    helperText={item1.error ? 'No requiere' : ''}  */
                                                                    id={'inputtarifa-'+index+'-'+index1}                                                                    
                                                                    margin="none" 
                                                                    onChange={handleChangeText}
                                                                    placeholder="Buscar..."                                                                      
                                                                />
                                                            )}                                                            
                                                        />
                                                    </div>
                                                </TableCell>
                                                {/* VALOR TARIFARIO */}
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    { parseFloat(data[index].detalle[index1].talValorTarifado).toFixed(2) }
                                                </TableCell>
                                                {/* VALOR AUDITADO */}
                                                <TableCell style={{ textAlign: 'right', borderRight: '1px solid #eeeeee'}}>
                                                    { /* (!data[index].detalle[index1].estado || !data[index].detalle[index1].dataTarifario) && parseFloat(data[index].detalle[index1].talValorObjetado).toFixed(2) */}
                                                    {data[index].detalle[index1].estado   && 
                                                        <TextField
                                                            id={'talValorObjetado-'+index+'-'+index1}
                                                            value={parseFloat(item1.talValorObjetado).toFixed(2)}           
                                                            error={item1.errorValorObjetado}
                                                            helperText={item1.errorValorObjetado ? 'Es mayor' : ''}                                                 
                                                            InputProps={{
                                                                inputComponent: NumberFormatMoney,
                                                                inputProps: { 
                                                                    style: { textAlign: 'right'},
                                                                    decimalScale: 2
                                                                }
                                                            }}
                                                            onChange={handleChangeValAut}
                                                            onBlur={handleBlurValAut}
                                                        />
                                                    }                                                    
                                                </TableCell>
                                               {/*  VALOR HONORARIO */}
                                               {/*  <TableCell 
                                                    style={{ textAlign: 'right', borderLeft: '1px solid #eeeeee',borderRight: '1px solid #eeeeee'}}
                                                        
                                                    >
                                                        {parseFloat(data[index].detalle[index1].talValorHonorarios ? data[index].detalle[index1].talValorHonorarios : 0).toFixed(2)}
                                                        
                                                </TableCell>
                                                 */}
                                                {/* TOTAL */}
                                                    {(index1 === 0) && 
                                                    <TableCell 
                                                        rowSpan={item.detalle.length} 
                                                        style={{textAlign: 'right'}}
                                                    > 
                                                        {/* {data[index].detalle[index1].talValorTarifado===0||data[index].detalle[index1].talValorTarifado>data[index].detalle[index1].talValorObjetado? parseFloat(item1.talValorObjetado).toFixed(2):parseFloat(data[index].detalle[index1].talValorTarifado).toFixed(2)} */}
                                                        {/* {parseFloat(data[index].detalle[index1].talValorObjetado).toFixed(2)} */}
                                                        {parseFloat(data[index].delValorObjetado).toFixed(2)}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                            
                                    
                                <TableRow 
                                    key={'totalRegistrado'} 
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total registrado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                        <b>{parseFloat(data.totalRegistrado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow 
                                    key={'totalObjetado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total objetado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                    <b>{(parseFloat(data.totalObjetado*-1).toFixed(2))}</b>
                                    </TableCell>
                                </TableRow> 
                                <TableRow 
                                    key={'totalAuditado'}
                                    style={{ borderTop: '1px solid black'}}
                                >
                                    <TableCell 
                                        colSpan={8} 
                                        style={{textAlign: 'right'}}
                                    >
                                        <b><i>Total auditado</i></b>
                                    </TableCell>
                                    <TableCell style={{textAlign: 'right'}}>
                                    <b>{parseFloat(data.totalRegistrado + data.totalObjetado).toFixed(2)}</b>
                                    </TableCell>
                                </TableRow>

                                
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
                <br />  
                <div className={classes.wrapper}>
                    <Button
                        className={classes.submitButton}
                        color="primary"
                        onClick={() => handleSave(false, false)}
                        variant="contained"
                    >
                    Guardar

                    </Button>   
                    &nbsp;&nbsp;
                    <Button
                        className={classes.submitButton}
                        color="primary"
                        onClick={() => {handleSave(false, true)}}
                        variant="contained"
                    >
                    Enviar a verificación
                    </Button>   
                    {guardando && 
                        <CircularProgress 
                            className={classes.buttonProgress} 
                            size={24} 
                        />
                    }
                </div>
                <Dialog
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="alert-dialog-title"
                    onClose={handleCloseConfirmation}
                    open={openConfirmation}
                >
                    <DialogTitle id="alert-dialog-title">{'Enviar'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <b>¿Está seguro de guardar la liquidación?</b>    
                            <br />
                            Por favor validar que la información se encuentre completa.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            color="primary"
                            onClick={handleCloseConfirmation}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            autoFocus
                            color="primary"
                            disabled={disableSave}
                            onClick={()=>handleSave(true, true)}
                        >
                            Sí
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
            <ModalAddObservacion
                filaActiva={filaActiva}
                filaActivaObs={filaActivaObs}
                onClose={(tarObservacion, filaActiva) => {setOpenObs(false); setResetData(!resetData); handleChangeObs(filaActiva, tarObservacion)}}
                open={openObs}     
                resetData={resetData}               
            />
            {ModalHonorarios &&
            <ModalDetalleHonorarios
                filaActiva={filaActiva2}
                filaActivaObs={filaActivaObs2}
                onClose={(talValorHonorarios, filaActiva) => {setModalHonorarios(false); setResetData2(!resetData2); handleChangeObs2(filaActiva, talValorHonorarios)}}
                open={ModalHonorarios}     
                enviarCodigo={enviarCodigo} 
                resetData2={resetData2}     
                enviarTotal={getTotalHonorario}  
                enviarCodigoHonorario={getCodigoHonorario}
                valorFCM={getFCM}  
                valorPorcentaje={getValorPorcentaje}  
                enviarDetalleHonorario={getDetalleHonorariosLlegada}
            />
        }
        </Card>
    );
};


export default PrestacionesItems;
/* nuevo2.0*/
