import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Typography,Grid } from '@material-ui/core';
import {FaBriefcaseMedical  } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    root: {
    },
    espacio: {
        paddingTop: theme.spacing(3),
        paddingBlockEnd: theme.spacing(20),
        float: 'left'
    },
    containerMedical: {
        float: 'right',
        width: 'auto',
    },
    containerNumber: {
        width: 'auto',
        float: 'right',
        textAlign: 'center'
    },
    containerIcon: {
        width: 'auto',
        float: 'left',
        textAlign:'right',
        paddingRight:20
    }
}));

const Header = props => {
    const { className, total, ...rest } = props;  

    const classes = useStyles();

    const sizeIcon = 50;

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Grid
                alignItems="flex-end"
                className={classes.color}
                container
                justify="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline"
                    >
            Sistema de medicina prepagada MEDICOMPANIES C.A. 
                    </Typography>
                    <Typography
                        component="h1"
                        variant="h3"
                    >
            Reporte liquidaciones
                    </Typography>
                </Grid>
                <Grid className={classes.containerMedical}>
                    <Grid
                        className={classes.containerIcon}
                        item
                    >
                        <FaBriefcaseMedical size = {sizeIcon}/>
                    </Grid>
                    <Grid
                        className={classes.containerNumber}
                        item
                    >
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="h3"
                        >
                            {total}
                            <br/>
              Liquidaciones
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    total: PropTypes.number
};

export default Header;
