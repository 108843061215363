import { DataService } from 'providers/dataService';
import { number } from 'prop-types';

export class PendingPayViewProvider {
    constructor() {
        this.dataService = new DataService();
    }

    /* datosPendientePagar( ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'VwPagosCpn',
            parametros: {					
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    } */

    datosPendientePagarCount() {
        let body = {
            tipoConsulta: 2,
            entidad: 'VwPagosCpn',
            parametros: {}
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
    datosFormaPago() {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: 38
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosPendientePagar(
        cedula,
        anio,
        mes,
        filtro,
        orden,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPCPN',
            parametros: {
                0: cedula,
                1: anio,
                2: mes,
                3: cedula,
                4: anio,
                5: mes,
                6: filtro,
                7: orden,
                8: numero,
                9: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExport(
        typeExport,
        cedula,
        anio,
        mes,
        filtro,
        orden,
        numero,
        inicio
    ) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWPCPN',
            parametros: {
                0: cedula,
                1: anio,
                2: mes,
                3: cedula,
                4: anio,
                5: mes,
                6: filtro,
                7: orden,
                8: numero,
                9: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    datosPendientePagarTodos(
        anio,
        mes,
        filtro, orden, numero, inicio
    ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'VWPAT',
            parametros: {
                0: anio,
                1: mes,
                2: anio,
                3: mes,
                4: filtro,
                5: orden,
                6: numero,
                7: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }

    dataReportsExportTodos(
        typeExport,
        anio,
        mes,
        filtro, orden, numero, inicio
    ) {
        let body = {
            tipoConsulta: typeExport,
            entidad: 'VWPAT',
            parametros: {
                0: anio,
                1: mes,
                2: anio,
                3: mes,
                4: filtro,
                5: orden,
                6: numero,
                7: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    }
}
