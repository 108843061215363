import React, { Component } from 'react';
import { Page } from 'components';

import { GenericList } from 'components';
import { FaRegFolder } from 'react-icons/fa';

import { forms } from './forms';

import CircularProgress from '@material-ui/core/CircularProgress';


import './../styles.css';

export default class NewDigitizeView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRequirement: false,
            formSelected: null,
            messageList: [],
            allForms: null,
            searchWord: ''
        }
    }

    componentDidMount = () => {
        this.findAllForms(this.state.searchWord);
    }

    handleSearchChange = (evt) => {
        this.setState({
            searchWord: evt.target.value
        });
        this.findAllForms(evt.target.value);


    }
    findAllForms = (v) => {

        const ArrayAux = [];
        new Promise((resolve, reject) => {
            forms.map((element) => {
                if (element.detNombre.includes(v.toUpperCase()) === true) {

                    ArrayAux.push(element);
                }

                return null;
            });

            setTimeout(
                () => ArrayAux.length > 0
                    ? resolve(ArrayAux)
                    : reject(new Error('No se encuentran datos')),
                100
            )
        }).then((response) => {
            this.setState({
                allForms: response
            });
        }).catch((error) => {
            console.error(error)
            this.setState({
                allForms: forms
            });
        });

    }


    handleOpen = () => {
        this.setState({
            showRequirement: true
        });
    }

    handleClose = () => {
        this.setState({
            showRequirement: false
        });
    }

    setFormSelected = (id) => {
        this.setState({
            formSelected: id

        });
        this.handleOpen();

    }

    _onMessageWasSent(message) {
        this.setState({
            messageList: [...this.state.messageList, message]
        })
    }

   

    render() {


        const titles = [{
            id: 1,
            name: 'Nombre',


        },
        /**  {
              id: 2,
              name: "Descargar",
              
              
          },*/
        {
            id: 3,
            name: 'Digitalizar',


        }
        ]
        return (
            <Page
                title="Digitalizar">

                {
                    this.state.allForms ?
                        <GenericList
                            elements={this.state.allForms}
                            titles={titles}
                            formTitle='Generar nueva Digitalizacion'
                            formIcon={<FaRegFolder />}
                            elementIcon={<FaRegFolder />}
                            handleOpen={this.handleOpen}
                            setFormSelected={this.setFormSelected.bind()}
                            valSearch={this.state.searchWord}
                            handleSearchChange={this.handleSearchChange}
                            idRequirement={0}

                        />
                        :<CircularProgress />


                }
                




            </Page>

        );
    }
}