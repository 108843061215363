import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import { Cancel, Search, Clear } from '@material-ui/icons'
import { CircularProgress, Card, CardHeader, CardActions, Grid, TextField, Button, IconButton, Dialog, CardContent, Divider } from '@material-ui/core';
import { AppointmentProvider } from 'providers/appointment.service';
import { SearchBasic } from 'components';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchAffiliate(props) {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Cedula', field: 'PER_IDENTIFICACION' },
        { title: 'Nombre', field: 'PER_NOMBRES' },
        { title: 'Apellidos', field: 'PER_APELLIDOS' },
        { title: 'Correo', field: 'PER_MAIL' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');
    const [buscarApellido, setBuscarApellido] = useState('');
    const [buscarNombre, setBuscarNombre] = useState('');

    const handleBuscarTitular = () => {
        setData(0);

        if (buscarTitular === '') {
            displayMessage.error('Ingrese un dato a buscar')
            setData([])
        } else {
            appoinment.searchTitular(buscarTitular, "PER_APELLIDOS", 'asc', 10, 0)
                .then(
                    data => {
                        if (data.error.codigo !== '0') {
                            //displayMessage.error('Titular no encontrado.');
                            setData([])
                        } else {
                            displayMessage.success('Titular encontrado')
                            setData(data.bodyOut.data)
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación')
                    }

                );
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleChangeNombre = (e) => {
        setBuscarNombre(e.target.value);
    }

    const handleChangeApellido = (e) => {
        setBuscarApellido(e.target.value);
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    const handleData = (e) => {
        props.datos(e)
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');
        setBuscarApellido('');
        setBuscarNombre('');
        setData([]);
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                    ><Cancel /></IconButton>}
                    title='Buscar titular'
                />
                <Divider />
                <CardContent>

                    <SearchBasic
                        searchBar={buscarTitular}
                        cleanSearchBar={handleLimpiar}
                        handleInputChange={handleChange}
                        complementaryMessage='Buscar'
                        handleSearch={handleBuscarTitular}
                    />
                    <br />

                </CardContent>

                {data ?
                    data.length ?
                        <MaterialTable
                            actions={[
                                {
                                    icon: 'check',
                                    tooltip: 'Seleccionar',
                                    onClick: (event, rowData) => {
                                        handleData(rowData)
                                        handleClose()
                                    }
                                }
                            ]}
                            columns={columns}
                            data={data}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination: {
                                    labelRowsSelect: 'Filas',
                                    labelDisplayedRows: '{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'


                                },
                                actions: {
                                    actions: "Accion"
                                }
                            }}
                            options={{ exportButton: false, search: false, toolbar: false }}
                            title="CIE10"
                        /> : ''
                    : <CircularProgress />}
            </Card><br />
        </Dialog>
    );
}