/*eslint-disable no-eval */ 

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { DisplayMessage } from 'notifications/displayMessage';
import NumberFormatMoney from 'components/NumberFormatMoney';


import './styles.css';


export default class    GenericBasicDialog extends Component {
    constructor(props){
        super(props);
        // eval(props.modalInputs[0])
        //m1=eval(props.modalInputs[0]);
        this.state = {
            descripcion: '',
            cantidad: '',
            VALOR: '', 
            descripcion_ERROR:false,
            cantidad_ERROR:false,
            VALOR_ERROR:false,
            optionAux: ''

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.hasError = this.hasError.bind(this);
    }
    

    handleInputChange=(evt)=>{        
        if(evt.target.value===''){
            this.setState({
                [`${evt.target.name}_ERROR`]: true,

            });
        }else{
            this.setState({
                [`${evt.target.name}_ERROR`]: false
            });        
        } 
       

        this.setState({
            [evt.target.name] : evt.target.value,
        });
      
        
    }
    addData = () => {

        const displayMessage = new DisplayMessage()

        if(this.state.descripcion !==''  && this.state.VALOR!=='' && this.state.cantidad!=='' ){
            const {descripcion, cantidad ,VALOR} = this.state;
           
            this.props.addData({
                talEstado:1,talCodigo:0,talDescripcion:descripcion, talCantidad:cantidad ,talValorRegistrado:VALOR
            });

            this.setState({
                descripcion: '',
                cantidad: '',
                VALOR: '', 
            }
            )
    
            this.props.calcTotalValue();
                
    
            this.props.handleClose();
        }else{
            displayMessage.error('Se han detectado campos vacios');
        }

       
    }

      hasError = (evt) =>{
        
          if( evt.target.value ==='' ){
              return true;
          }
          else{
              return false;
          }
      }

      render(){
          const { show, handleClose, modalTitle } = this.props;

          return(
              <Dialog
                  aria-labelledby="form-dialog-title"
                  onClose={handleClose}
                  open={show}
                  fullWidth
                  maxWidth="sm"
                  disableBackdropClick 
                  disableEscapeKeyDown
              >
                  <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
                  <DialogContent>
                      <DialogContentText />
                      <TextField
                          fullWidth
                          autoFocus
                          label="Descripción"
                          name="descripcion"
                          onChange={this.handleInputChange}
                          value={this.state.descripcion}
                          variant="outlined"
                      />
                      <div
                          className="medium-margin-top"                           
                      >
                          <TextField
                              InputProps={{
                                  inputComponent: NumberFormatMoney,
                                  inputProps: { 
                                      style: { textAlign: 'right'},
                                      decimalScale: 2, 
                                                                     
                                  }
                              }}
                              label="Cantidad"
                              margin="dense"                       
                              name="cantidad"                                 
                              onChange={this.handleInputChange}
                              value={this.state.cantidad}
                              variant="outlined"
                          />
                      </div>
                      <div
                          className="medium-margin-top"                           
                      >
                          <TextField
                              InputProps={{
                                  inputComponent: NumberFormatMoney,
                                  inputProps: { 
                                      style: { textAlign: 'right'},
                                      decimalScale: 2, 
                                                                     
                                  }
                              }}                              
                              label="Valor"
                              margin="dense"
                              name="VALOR"                          
                              onChange={this.handleInputChange}
                              value={this.state.VALOR}
                              variant="outlined"
                          />
                      </div>
                                            
                  </DialogContent>
                  <DialogActions>
                      <Button
                          color="primary"
                          onClick={handleClose}
                      >
                    Cancelar
                      </Button>
                      <Button
                          color="primary"
                          onClick={this.addData.bind()}
                      >
                    Guardar
                      </Button>
                  </DialogActions>
              </Dialog>
          );

      }
}