/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { PendingPayViewProvider } from 'providers/pendingPay.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, Grid, Button, Tooltip, TextField } from '@material-ui/core';
import 'moment/locale/es';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import theme from 'theme';
import { Clear, Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';

moment.locale('es');
const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    }
}));

export default function MaterialTableDemo(props) {
    const tableRef = React.useRef();
    const classes = useStyles();
    const session = useSelector(state => state.session);
    const [selectedDate, handleDateChange] = useState(moment());
    const [buscarMes, setBuscarMes] = useState(moment());
    const [field, setField] = useState('');
    const [ord, setOrd] = useState('');
    const [total, setTotal] = useState(null);
    const displayMessage = new DisplayMessage();
    const pendingPay = new PendingPayViewProvider();
    //const [formPago, setFormPago] = useState([]);
    const [selecFormPago, setSelecFormPago] = useState('');

    const [columns] = useState([
        { title: 'Fecha', field: 'FECHA' },
        { title: 'Cédula titular', field: 'CEDULA_TITULAR' },
        { title: 'Cédula', field: 'IDENTIFICACION' },
        { title: 'Nombres', field: 'NOMBRES' },
        { title: 'Tipo Cuenta', field: 'TIPOCUENTA' },
        { title: 'N° Cuenta', field: 'NUMEROCUENTA' },
        { title: 'Valor', field: 'VALOR', width: 50 },
        { title: 'Banco', field: 'BANCO' },
        { title: 'Ruc Cobra', field: 'RUC_COBRA' },
        {
            title: 'Fecha Vencimiento',
            field: 'PAG_FECHA_VENCIMIENTO'
        }
        /* {
            title: 'Código pago',
            field: 'COD_PAGO'
        },
        {
            title: 'Base imponible imprenta',
            field: 'BASEIMPONIBLE_IMPRENTA'
        },
        {
            title: 'Base imponible iva',
            field: 'BASEIMPONIBLE_IVA'
        },
        { title: 'Fecha registro', field: 'FECHA_REGISTRO' },

        { title: 'Observación', field: 'OBSERVACIONES' },
        { title: 'Código poliza', field: 'POL_CODIGO' } */
    ]);
    const [booleano, setBooleano] = useState(false);

    const loadData = (query, resolve) => {
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'IDENTIFICACION'
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        setField(orderBy.field);
        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        setOrd(direction.orderDirection);

        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }
        pendingPay
            .datosPendientePagar(
                session.user.perIdentificacion,
                selectedDate.format('YYYY'),
                subCadena,
                orderBy.field,
                direction.orderDirection,
                query.pageSize,
                query.pageSize * query.page
            )
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setTotal(response.bodyOut.data[0].TOTAL);
                        props.onTotal(response.bodyOut.data[0].TOTAL);
                        setBooleano(false);
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false);
                        props.onTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        displayMessage.error('La consulta no arrojo resultados')
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    /*     useEffect(() => {
        formasPagos()
    }, [])

    const formasPagos = () => {
        pendingPay.datosFormaPago()
        .then(response => {
            if(response.error.codigo === '0'){
                const arr = response.bodyOut.data.filter((value) => value.detCodigo !== 23517)
                setFormPago(arr)
                
            }else{
                displayMessage.error("Error de comunicación")
            }
        })
    } */

    /*  const handleFormaPago = e => {
        setSelecFormPago(e.target.value);
    }; */

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        handleDateChange(moment());
        setBuscarMes(moment());
        setSelecFormPago('');
    };

    const handleBuscarTitular = () => {
        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }

        if (selectedDate === '' && buscarMes === '') {
            displayMessage.error('Seleccione un año o mes.');
        } else {
            tableRef.current.onSearchChange(subCadena);
            tableRef.current.onSearchChange(selectedDate.format('YYYY'));
        }
        tableRef.current.onSearchChange(selecFormPago);
    };

    const dataReportsExport = typeResult => {
        let subCadena = '';
        if (buscarMes.format('MM').substring(0, 1) === '0') {
            subCadena = buscarMes.format('MM').substring(1, 2);
        } else {
            subCadena = buscarMes.format('MM');
        }
        if (selectedDate === '' && buscarMes === '') {
            displayMessage.error('Seleccione un año o mes.');
        } else {
            pendingPay
                .dataReportsExport(
                    typeResult,
                    session.user.perIdentificacion,
                    selectedDate.format('YYYY'),
                    `${subCadena}`,
                    field,
                    ord,
                    total,
                    0
                )
                .then(
                    response => {
                        if (
                            response.error.codigo === '0' &&
                            response.bodyOut.data.length
                        ) {
                            downloadFile(response.bodyOut.data, typeResult);
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                    }
                );
        }
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].VWPCPN}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte_Pagos.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid
                        //className={classes.item}
                        item
                        md={2}
                        sm={2}
                        xs={6}>
                        <DatePicker
                            format="MMMM"
                            inputVariant="outlined"
                            label="Seleccione mes"
                            onChange={setBuscarMes}
                            value={buscarMes}
                            views={['month']}
                        />
                    </Grid>
                    <Grid
                        //className={classes.item}
                        item
                        md={2}
                        sm={2}
                        xs={6}>
                        <DatePicker
                            inputVariant="outlined"
                            label="Seleccione año"
                            onChange={handleDateChange}
                            value={selectedDate}
                            variant="inline"
                            views={['year']}
                        />
                    </Grid>
                    {/* <Grid
                        //className={classes.item}
                        item
                        md={2}
                        sm={2}
                        xs={6}
                    >
                         <TextField
                                //fullWidth
                                style={{ width: 375 }}
                                select
                                variant="outlined"
                                label="Forma de pago"
                                name="agendamiento"
                                value={selecFormPago}
                                onChange={handleFormaPago}
                                SelectProps={{
                                    native: true,
                                }}
                            //helperText="Please select your currency"
                            >
                                <option value="Selec">Seleccione.....  </option>
                                {formPago.map((option) => (
                                    <option key={option.detCodigo} value={option.detCodigo}>
                                        {option.detNombre}
                                    </option>
                                ))}
                            </TextField>


                    </Grid> */}
                    <Grid
                        //className={classes.item}
                        item
                        md={6}
                        xs={6}
                    />
                    <Grid item style={{ padding: 0 }} sm={4} xs={12}>
                        <Button
                            className={classes.searchButton}
                            //color="primary"
                            onClick={handleBuscarTitular}
                            variant="contained"
                            size="small">
                            <Search />
                            Buscar
                        </Button>
                        <Button
                            className={classes.searchButton}
                            color="primary"
                            onClick={handleLimpiar}
                            variant="contained"
                            size="small">
                            <Clear />
                            Limpiar
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <br />

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{
                                borderRadius: 5,
                                marginRight: 10,
                                width: 100
                            }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(2);
                            }}>
                            <FaFileCsv size={20} style={{ marginRight: 10 }} />{' '}
                            CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(3);
                            }}>
                            <FaFileExcel
                                size={20}
                                style={{ marginRight: 10 }}
                            />{' '}
                            EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <Card>
                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={
                        booleano === false
                            ? query =>
                                  new Promise(resolve => {
                                      loadData(query, resolve);
                                  })
                            : []
                    }
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            searchPlaceholder: 'Buscar',
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'
                        }
                    }}
                    options={{
                        search: false,
                        toolbar: false,
                        actionsColumnIndex: -1,
                        actionsCellStyle: {
                            width: 100,
                            textAlign: 'center'
                        },
                        headerStyle: {
                            fontWeight: 'bold'
                        },
                        rowStyle: rowData => ({
                            backgroundColor:
                                rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
                        })
                    }}
                    title=""
                />
            </Card>
        </div>
    );
}
