import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { TableGenericforForm } from 'components';
import { Button } from '@material-ui/core';
import './styles.css';
import { GenericHeader } from 'components';
import GenericBasicDialog from './GenericBasicDialog';

import { DeleteDialog } from "./DeleteDialog";

import { UpdateDialog } from './UpdateDialog';

export default class GenericBasicForm extends Component {


    constructor(props) {
        super(props);


        this.state = {
            show: false,
            selectedDate: this.ActualDate(),
            showRemove: false,
            selectedElement: null,
            showUpdate: false,
            total: 0
        }

    }

    
    ActualDate = () => {
        const now = new Date();

        const day = ("0" + now.getDate()).slice(-2);
        const month = ("0" + (now.getMonth() + 1)).slice(-2);

        const today = now.getFullYear() + "-" + (month) + "-" + (day);
        return today;
    }

    calcTotalValue = () => {
        var acum = 0;
        this.props.data.map((val) => {
            acum = parseFloat(acum) + parseFloat(val[3])
            return null;
        });

        this.props.setTotalValue(acum);
    }
    /** Para el Date Picker */

    removeElement = (element) => {
        //dataArray.
        var i = this.props.data.indexOf(element);

        if (i !== -1) {
            this.props.data.splice(i, 1);
        }
        this.handleCloseRemove()
        this.calcTotalValue();
        // this.props.enDisButton();

        // this.handleCloseRemove();
    }

    /** */
    updateElement = (element, change) => {
        var i = this.props.data.indexOf(element);
        if (i !== -1) {
            this.props.data[i] = change;
        }
        this.handleCloseUpdate();
        this.calcTotalValue();
        // this.props.enDisButton();

    }
    handleDateChange = (e) => {
        // this.state.selectedDate = date;
        this.setState({
            selectedDate: e.target.value
        });
    }


    handleClickOpen = () => {
        this.setState({
            show: true
        });
    }


    handleClose = () => {
        this.setState({
            show: false
        })
    }


    handleOpenRemove = (element) => {
        this.setState({
            showRemove: true,
            selectedElement: element

        });
    }

    handleCloseRemove = () => {
        this.setState({
            showRemove: false
        })
    }

    handleOpenUpdate = (element) => {
        this.setState({
            showUpdate: true,
            selectedElement: element


        });
    }

    handleCloseUpdate = () => {
        this.setState({
            showUpdate: false
        })
    }

    actions = [

        <Button key={1} variant="contained" color="primary"> Editar </Button>,
        <Button key={2} variant="contained" color="secondary"> Eliminar </Button>
    ];

    isRequired = value => {
        return (!value && 'Este campo es requerido')
    }

    render() {
        //                                                
        /**name={this.state.name}
        totalValue={this.state.totalValue}
        ruc={this.state.ruc}
        date={this.state.date} */
        const { advice, labels, btnAgregarText, data, columna,
            dataSelect, modalInputs, valShow, SelectDatafield,
            addData, addService, dataOfObject, idOfObject,
            name, totalValue, ruc, date,
            errorsText,

        } = this.props;
        return (
            <div className='medium-margin'>
                <GenericHeader
                    title={advice}
                ></GenericHeader>

                <form>

                    {console.log("Error name")}
                    {console.log(this.props.hasError('name'))}

                    <Grid container spacing={3}>
                        <Grid item xs>
                            <TextField id="name" label={labels[0]}
                                value={name}
                                onChange={this.props.handleInputChange}
                                error={this.props.hasError('name')} 
                            
                                name="name" variant="outlined"
                                helperText={this.props.hasError('name') ? errorsText.name:'' }
                            />
                        </Grid>
                        
                        <Grid item xs>
                            <TextField id="ruc" label={labels[1]} 
                                value={ruc} 
                                onChange={this.props.handleInputChange} 
                                error={this.props.hasError('ruc')} 
                                helperText={this.props.hasError('ruc') ? errorsText.ruc:'' }
                                name="ruc" variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <TextField id="totalValue" 
                                label={labels[2]} value={totalValue} 
                                onChange={this.props.handleInputChange} 
                                name="totalValue" variant="outlined" 
                                //error={()=>hasError('totalValue')} 
                                //helperText={()=>hasError.bind('totalValue') ? errorsText.totalValue:'' }

                            />
                        </Grid>
                        <Grid item xs>
                            <TextField id="date" 
                                //error={()=>hasError.bind('date')} 
                                //helperText={()=>hasError.bind('date') ? errorsText.date:'' }

                                onChange={this.props.handleInputChange} type="date" value={date} name="date" label={labels[3]} variant="outlined"
                            />


                        </Grid>
                    </Grid>
                    <div className='right'>
                        <Button variant="contained" onClick={this.handleClickOpen}>{`Agregar ${btnAgregarText}`}</Button>
                    </div>

                    {//--------------- Agregar dialog -----------
                    }
                    <GenericBasicDialog
                        show={this.state.show}
                        handleClose={this.handleClose}
                        modalTitle={btnAgregarText}
                        dataSelect={dataSelect}
                        modalInputs={modalInputs}
                        SelectDatafield={SelectDatafield}
                        addData={addData}
                        addService={addService}
                        calcTotalValue={this.calcTotalValue}
                    />
                    {//show, handleClose, element, removeElement
                        this.state.selectedElement ?
                            <DeleteDialog

                                show={this.state.showRemove}
                                handleClose={this.handleCloseRemove}
                                element={this.state.selectedElement}
                                removeElement={this.removeElement}

                            >

                            </DeleteDialog> : ""
                    }
                    {
                        this.state.selectedElement ?
                            <UpdateDialog
                                show={this.state.showUpdate}
                                handleClose={this.handleCloseUpdate}
                                element={this.state.selectedElement}
                                updateElement={this.updateElement}
                                modalInputs={modalInputs}
                                valShow={valShow}

                            >

                            </UpdateDialog> : ""
                    }




                    <TableGenericforForm
                        data={data}
                        columna={columna}
                        showMedicines={this.state.show}
                        handleClose={this.handleClose}
                        dataOfObject={dataOfObject}
                        idOfObject={idOfObject}
                        actions={this.actions}
                        dataSelect={dataSelect}
                        handleOpenRemove={this.handleOpenRemove}
                        handleOpenUpdate={this.handleOpenUpdate}


                    />

                </form>
            </div>
        );
    }
} 
