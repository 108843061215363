export const forms = [

    {   detCodigo: 1,
        detNombre: 'FORMULARIO DE REEMBOLSO',
        digitalizar: 1,
        detDescripcion: 'LIQUIDACION REEMBOLSO'
    },
    {   detCodigo: 2,
        detNombre: 'DIAGNOSTICO',
        digitalizar: 0
    },
    {   detCodigo: 3,
        detNombre: 'EXÁMENES',
        digitalizar:0
    },
    {   detCodigo: 4,
        detNombre: 'FARMACIA',
        digitalizar: 1
    },
    {   detCodigo: 5,
        detNombre: 'SERVICIOS MEDICOS',
        digitalizar: 1
    },
  /**  
   * {   detCodigo: 6,
        detNombre: "FORMULARIO DE PREAUTORIZACION/ AUTORIZACION HOSPITALARIA",
        digitalizar: 1
    },
    {   detCodigo: 7,
        detNombre: "FORMULARIO DE ACTUALIZACION DE DATOS",
        digitalizar: 1
    },
    {   detCodigo: 8,
        detNombre: "FORMULARIO DE REMBOLSO DE ATENCION MEDICA",
        digitalizar: 1
    },
    {   detCodigo: 9,
        detNombre: "FORMULARIO DE DESAFILIACION",
        digitalizar: 1
    },
    
   */ 

];