import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const toolbar = '#5cb24c';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: toolbar,
    main: toolbar,
    light: toolbar
  },
  secondary: {
    contrastText: white,
    dark: toolbar,
    main: toolbar,
    light: toolbar
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: white
  },
  link: toolbar,
  icon: toolbar,
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
};
