/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Grid,
    TextField,
    Button,
    colors,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper
} from '@material-ui/core';
import NumberFormatPhone from 'components/NumberFormatPhone';
import NumberFormatRuc from 'components/NumberFormatRuc';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { LiquidationProvider } from 'providers/liquidation.service';
import { DisplayMessage } from 'notifications/displayMessage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatMoney from 'components/NumberFormatMoney';
import { Add, Check, DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    formControl: {
        width: '100%'
    },
    container: {
        marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const CustomerEdit = props => {
    const { className, datosPrestador, ...rest } = props;

    const classes = useStyles();

    const [formState, setFormState] = useState({});

    const prestaciones = new DocumentDigitalizeProvider();
    const liquidationProvider = new LiquidationProvider();
    const displayMessage = new DisplayMessage();
    const [ruc, setRuc] = useState('');
    const [telefono1, setTelefono1] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [rucError, setRucError] = useState(false);
    const [nombreError, setNombreError] = useState(false);
    const [direccionError, setDireccionError] = useState(false);
    const [telefonoError, setTelefonoError] = useState(false);
    const [ciudadError, setCiudadError] = useState(false);
    const [dataCombo, setDataCombo] = useState([]);
    const [dataComboPrestador, setDataComboPrestador] = useState([]);
    const [tipoPrestador, setTipoPrestador] = useState('');
    const [tipoPrestadorError, setTipoPrestadorError] = useState(false);
    const [institucionFinanciera, setInstitucionFinanciera] = useState('');
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    const [dataInstitucion, setDataInstitucion] = useState([]);
    const [dataTipoCuenta, setDataTipoCuenta] = useState([]);
    const [txtCiudad, setTxtCiudad] = useState('');
    const [detIfi, setDetIi] = useState('');
    const [correoError, setCorreoError] = useState(false);
    const [desactivarBtn, setDesactivarBtn] = useState(false);
    const [correo, setCorreo] = useState('');

    const [eventSelected, setEventSelected] = React.useState(true);

    const [telefonos, setTelefonos] = useState([]);
    const [viewPhone, setViewPhone] = useState(false);

    const [correos, setCorreos] = useState([]);
    const [viewEmail, setViewEmail] = useState(false);

    useEffect(() => {
        setRuc(datosPrestador.preRuc);
        setTipoPrestador(datosPrestador.detTipo);

        setTipoCuenta(datosPrestador.detTipoCuenta);
        setNumeroCuenta(datosPrestador.preCuenta);
        if (datosPrestador.preDinamico !== undefined) {
            setFormState({
                ...formState,
                nombres: datosPrestador.preNombre,
                direcciones: datosPrestador.preDireccion
            });
            if (datosPrestador.preDinamico !== null) {
                setTelefonos(JSON.parse(datosPrestador.preDinamico).telefono);

                setCorreos(JSON.parse(datosPrestador.preDinamico).email);
            }
            setCiudad(datosPrestador.locCodigo);
            setTxtCiudad(datosPrestador.locNombre);
        }
        if (datosPrestador.ifiNombre !== null) {
            setDetIi(datosPrestador.detIfi);
            setInstitucionFinanciera(datosPrestador.ifiNombre);
        }
    }, [datosPrestador]);

    useEffect(() => {
        getInstitucionFinanciera();
        getTipoCuenta();
        liquidationProvider.findDetalleCatalogo('TIPPRES').then(
            response => {
                if (response.error.codigo === '0') {
                    setDataComboPrestador(response.bodyOut.data);
                    /*  if (props.detTipoLiq === 'TIPLQRE') {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico === 'TEXTER'));
                 } else {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico !== 'TEXTER'));
                 } */
                }
            },
            () => {
                displayMessage.error('Error de conexión');
            }
        );
    }, []);

    const getInstitucionFinanciera = () => {
        liquidationProvider.findDetalleCatalogo('IFI').then(
            response => {
                if (response.error.codigo === '0') {
                    setDataInstitucion(response.bodyOut.data);
                    /*  if (props.detTipoLiq === 'TIPLQRE') {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico === 'TEXTER'));
                 } else {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico !== 'TEXTER'));
                 } */
                }
            },
            () => {
                //displayMessage.error('Error de conexión')
            }
        );
    };

    const getTipoCuenta = () => {
        liquidationProvider.findDetalleCatalogo('TIPCT').then(
            response => {
                if (response.error.codigo === '0') {
                    setDataTipoCuenta(response.bodyOut.data);
                    /*  if (props.detTipoLiq === 'TIPLQRE') {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico === 'TEXTER'));
                 } else {
                     setDataComboPrestador(response.bodyOut.data.filter(data => data.detNemonico !== 'TEXTER'));
                 } */
                }
            },
            () => {
                //displayMessage.error('Error de conexión')
            }
        );
    };

    const getCiudades = search => {
        prestaciones.datosCiudadesBusqueda(search).then(
            response => {
                if (
                    response.error.codigo === '0' &&
                    response.bodyOut.data.length
                ) {
                    setDataCombo(response.bodyOut.data);
                }
            },
            () => {
                // displayMessage.error('Datos no encontrados')
            }
        );
    };

    const handleFieldChange = event => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            [event.target.name]: event.target.value
        }));
        setNombreError(false);
        setDireccionError(false);
        setCiudadError(false);
    };

    const handleRucChange = e => {
        setRuc(e.target.value);
        setRucError(false);
    };

    const handleTipoCuentaChange = e => {
        setTipoCuenta(e.target.value);
        //setRucError(false)
    };

    const handleNumeroCuentaChange = e => {
        setNumeroCuenta(e.target.value);
        //setRucError(false)
    };

    const handlePhone1Change = e => {
        setTelefono1(e.target.value);
        setTelefonoError(false);
    };

    const handleChangeText = event => {
        let search = event.target.value;
        if (search.length >= 3) {
            getCiudades(search);
        } else {
            setDataCombo([]);
        }
    };

    const handleClose = () => {
        setDataCombo([]);
    };

    const handleChangeTipoPrestador = event => {
        setTipoPrestador(event.target.value);
        if (event.target.value === '') {
            setTipoPrestadorError(true);
        }
    };

    const handleBlur = event => {
        if (event.target.value === '') {
            setCiudad('');
        }
    };

    const handleChange = (event, values) => {
        if (values) {
            setTxtCiudad('');
            setCiudad(values.locCodigo);
        } else {
            setCiudad('');
        }
    };

    const onChangeIfi = (event, values) => {
        if (values) {
            setInstitucionFinanciera('');
            setDetIi(values.detCodigo);
        } else {
            setDetIi('');
        }
    };

    const handleSaveLender = () => {
        setDesactivarBtn(true);
        if (
            tipoPrestador === '' ||
            ciudad === '' ||
            ruc === '' ||
            formState.nombres === undefined ||
            formState.nombres === '' ||
            formState.direcciones === undefined ||
            formState.direcciones === ''
        ) {
            setDesactivarBtn(false);
            if (tipoPrestador === '') {
                setTipoPrestadorError(true);
            }

            if (ruc === '' || ruc.length < 13) {
                setRucError(true);
            }
            if (formState.nombres === undefined || formState.nombres === '') {
                setNombreError(true);
            }
            if (
                formState.direcciones === undefined ||
                formState.direcciones === ''
            ) {
                setDireccionError(true);
            }
            if (ciudad === '') {
                setCiudadError(true);
            }
            displayMessage.error('Complete los campos');
        } else {
            const tramaPrestador = [
                {
                    preCodigo: datosPrestador.preCodigo,
                    detTipo: tipoPrestador,
                    locCodigo: ciudad,
                    preRuc: ruc,
                    preNombre: formState.nombres,
                    preDireccion: formState.direcciones,
                    preDinamico: JSON.stringify({
                        contacto: [],
                        contactoContable: [],
                        emailContable: [],
                        teléfonoContable: [],
                        telefono: telefonos,
                        telefonoContacto: [],
                        email: correos
                    }),
                    detIfi: detIfi,
                    detTipoCuenta: tipoCuenta,
                    preCuenta: numeroCuenta
                }
            ];
            prestaciones.ingresoPrestadorAdmin(2, tramaPrestador).then(
                data => {
                    if (data.error.codigo === '0') {
                        setDesactivarBtn(true);
                        displayMessage.success('Prestador editado.');
                        window.location.href = `/administrador/prestador`;
                    } else {
                        setDesactivarBtn(false);
                    }
                },
                () => {
                    setDesactivarBtn(false);
                    displayMessage.error('Error de comunicación.');
                }
            );
        }
    };

    /* const handleChangeCorreo = e => {
        setCorreo(e.target.value);
        setCorreoError(false);
    }; */

    const handleNewPhone = () => {
        setViewPhone(true);
    };

    const handleCancel = () => {
        setViewPhone(false);
        setTelefono1('');
    };

    const handleAddPhone = () => {
        if (telefono1 === '' || telefono1.length < 10) {
            setTelefonoError(true);
        } else {
            let copy = telefonos;
            copy.push(telefono1);
            setViewPhone(false);
            setTelefono1('');
            setTelefonos(copy);
            setEventSelected(!eventSelected);
        }
    };

    const handleDeletePhone = index => {
        console.log(index);
        telefonos.splice(index, 1);
        setEventSelected(!eventSelected);
    };
    //email
    const handleChangeEmail = e => {
        setCorreo(e.target.value);
        setCorreoError(false);
    };

    const handleNewEmail = () => {
        setViewEmail(true);
    };

    const handleCancelEmail = () => {
        setViewEmail(false);
        setCorreo('');
    };

    const handleAddEmail = () => {
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(correo)) {
            setCorreoError(true);
        } else {
            let copy = correos;
            copy.push(correo);
            setViewEmail(false);
            setCorreo('');
            setCorreos(copy);
            setEventSelected(!eventSelected);
        }
    };

    const handleDeleteEmail = index => {
        correos.splice(index, 1);
        setEventSelected(!eventSelected);
    };

    return (
        <Card>
            <CardContent>
                <Grid className={classes.container} container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            autoFocus
                            error={rucError}
                            fullWidth
                            helperText={rucError && 'Ingrese Ruc'}
                            InputProps={{
                                inputComponent: NumberFormatRuc
                            }}
                            label="Ingrese Ruc"
                            name="ruc"
                            onChange={handleRucChange}
                            required
                            value={ruc}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            error={nombreError}
                            fullWidth
                            helperText={nombreError && 'Ingrese Nombre'}
                            label="Nombre"
                            name="nombres"
                            onChange={handleFieldChange}
                            required
                            value={formState.nombres || ''}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            error={tipoPrestadorError}
                            helperText={
                                tipoPrestador && 'Seleccione tipo de prestador'
                            }
                            select
                            variant="outlined"
                            label="Tipo prestador"
                            name="tipoPrestador"
                            value={tipoPrestador}
                            onChange={handleChangeTipoPrestador}
                            SelectProps={{
                                native: true
                            }}
                            //helperText="Please select your currency"
                        >
                            <option value="Selec">Seleccione..... </option>
                            {dataComboPrestador.map(option => (
                                <option
                                    key={option.detCodigo}
                                    value={option.detCodigo}>
                                    {option.detNombre}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {/*  <TextField
                            fullWidth
                            //error={tipoPrestadorError}
                            //helperText={tipoPrestador && "Seleccione tipo de prestador"}
                            select
                            variant="outlined"
                            label="Institución financiera"
                            name="institucionFinanciera"
                            value={institucionFinanciera}
                            onChange={handleInstitucionFinancieraChange}
                            SelectProps={{
                                native: true,
                            }}
                        //helperText="Please select your currency"
                        >
                            <option value="Selec">Seleccione.....  </option>
                            {dataInstitucion.map((option) => (
                                <option key={option.detCodigo} value={option.detCodigo}>
                                    {option.detNombre}
                                </option>
                            ))}
                        </TextField> */}
                        <Autocomplete
                            id="detIfi"
                            options={dataInstitucion}
                            onChange={onChangeIfi}
                            getOptionLabel={option => option.detNombre}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={
                                        institucionFinanciera.length > 0
                                            ? institucionFinanciera
                                            : 'Institución financiera'
                                    }
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            //error={tipoPrestadorError}
                            //helperText={tipoPrestador && "Seleccione tipo de prestador"}
                            select
                            variant="outlined"
                            label="Tipo de cuenta"
                            name="tipoCuenta"
                            value={tipoCuenta || ''}
                            onChange={handleTipoCuentaChange}
                            SelectProps={{
                                native: true
                            }}
                            //helperText="Please select your currency"
                        >
                            <option value="Selec">Seleccione..... </option>
                            {dataTipoCuenta.map(option => (
                                <option
                                    key={option.detCodigo}
                                    value={option.detCodigo}>
                                    {option.detNombre}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            //error={direccionError}
                            fullWidth
                            //helperText={direccionError && 'Ingrese Dirección'}
                            label="Número de cuenta"
                            name="numeroCuenta"
                            InputProps={{
                                inputComponent: NumberFormatMoney,
                                inputProps: {
                                    decimalScale: 0,
                                    allowLeadingZeros: true
                                }
                            }}
                            onChange={handleNumeroCuentaChange}
                            value={numeroCuenta || ''}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            error={direccionError}
                            fullWidth
                            helperText={direccionError && 'Ingrese Dirección'}
                            label="Dirección"
                            name="direcciones"
                            onChange={handleFieldChange}
                            required
                            value={formState.direcciones || ''}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            getOptionLabel={option => option.locNombre}
                            noOptionsText=""
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onClose={handleClose}
                            options={dataCombo}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    error={ciudadError}
                                    helperText={
                                        ciudadError ? 'Seleccione ciudad' : ''
                                    }
                                    label={
                                        txtCiudad.length > 0
                                            ? txtCiudad
                                            : 'Ciudad'
                                    }
                                    margin="normal"
                                    onChange={handleChangeText}
                                    placeholder={'Buscar...'}
                                    style={{ marginTop: '0px' }}
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Teléfonos</TableCell>
                                        <TableCell align="right">
                                            <Button
                                                onClick={handleNewPhone}
                                                size="small">
                                                <Add />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {telefonos>0&&telefonos.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    borderRight:
                                                        '1px solid #eeeeee',
                                                    width: '70%'
                                                }}>
                                                {item}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                style={{
                                                    width: '30%'
                                                }}>
                                                <Button
                                                    size="small"
                                                    onClick={() =>
                                                        handleDeletePhone(index)
                                                    }>
                                                    <DeleteForever />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {viewPhone && (
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    borderRight:
                                                        '1px solid #eeeeee',
                                                    width: '70%'
                                                }}>
                                                <TextField
                                                    fullWidth
                                                    error={telefonoError}
                                                    helperText={
                                                        telefonoError &&
                                                        'Ingrese téléfono válido'
                                                    }
                                                    InputProps={{
                                                        inputComponent: NumberFormatPhone
                                                    }}
                                                    label="Teléfono"
                                                    name="telefono11"
                                                    onChange={
                                                        handlePhone1Change
                                                    }
                                                    required
                                                    value={telefono1}
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                style={{
                                                    width: '30%'
                                                }}>
                                                <Button
                                                    size="small"
                                                    onClick={handleAddPhone}>
                                                    <Check />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onClick={handleCancel}>
                                                    <DeleteForever />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Correo electrónico
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button
                                                onClick={handleNewEmail}
                                                size="small">
                                                <Add />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {correos>0&&correos.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    borderRight:
                                                        '1px solid #eeeeee',
                                                    width: '70%'
                                                }}>
                                                {item}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                style={{
                                                    width: '30%'
                                                }}>
                                                <Button
                                                    size="small"
                                                    onClick={() =>
                                                        handleDeleteEmail(index)
                                                    }>
                                                    <DeleteForever />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {viewEmail && (
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    borderRight:
                                                        '1px solid #eeeeee',
                                                    width: '70%'
                                                }}>
                                                <TextField
                                                    error={correoError}
                                                    helperText={
                                                        correoError &&
                                                        'Correo no válido'
                                                    }
                                                    fullWidth
                                                    label="Correo electrónico"
                                                    name="correo"
                                                    onChange={handleChangeEmail}
                                                    value={correo || ''}
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                style={{
                                                    width: '30%'
                                                }}>
                                                <Button
                                                    size="small"
                                                    onClick={handleAddEmail}>
                                                    <Check />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onClick={handleCancelEmail}>
                                                    <DeleteForever />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/* <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            error={telefonoError}
                            helperText={telefonoError && 'Ingrese télefono'}
                            InputProps={{
                                inputComponent: NumberFormatPhone
                            }}
                            label="Teléfono"
                            name="telefono11"
                            onChange={handlePhone1Change}
                            required
                            value={telefono1}
                            variant="outlined"
                        />
                    </Grid> */}
                    {/* <Grid item md={6} xs={12}>
                        <TextField
                            error={correoError}
                            helperText={correoError && 'Correo no válido'}
                            fullWidth
                            label="Correo electrónico"
                            name="correo"
                            onChange={handleChangeCorreo}
                            value={correo || ''}
                            variant="outlined"
                        />
                    </Grid> */}
                </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
                <Button
                    color="primary"
                    onClick={handleSaveLender}
                    variant="contained"
                    disabled={desactivarBtn}>
                    Guardar
                </Button>
            </CardActions>
        </Card>
    );
};

CustomerEdit.displayName = 'CustomerEdit';

CustomerEdit.propTypes = {
    className: PropTypes.string,
    customer: PropTypes.any
};

export default CustomerEdit;
