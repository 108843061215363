/* eslint no-eval: 0 */

import React, { Component } from 'react';

import { Page, GenericSearch } from 'components';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { ActualDate } from './../../../../../services/ActualDate';

import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    FormControlLabel,
    Button,
    Typography
} from '@material-ui/core';


import { PersonAutocomplete } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DetalleCatalogoProvider } from './../../../../../providers/detalleCatalogo.service';
import { EmpresaProvider } from './../../../../../providers/empresa.service';

import RadioGroup from '@material-ui/core/RadioGroup';

import Radio from '@material-ui/core/Radio';

import { DisplayMessage } from 'notifications/displayMessage';

import { PersonalProvider } from 'providers/persona.service';
import { GenericHeaderFoms } from 'components';
import { FormularioProvider } from 'providers/formularios.service';


import './styles.css';

export default class HospitalPreauthorizationView extends Component {
    constructor(props) {
        super(props);
        this.decryptedData = typeof (props.match.params.id) === 'undefined' ? 0 : atob(props.match.params.id);



        this.state = {
            idRequirement: this.decryptedData,
            city: '',
            titular: null,
            AllTitulars: [],

            dateSelected: ActualDate(),
            AllReasons: null,
            reason: null,
            AllCompanies: null,

            AllPatient: null,
            patient: null,
            polCodigo: 0,

            option: 'hDia',
            countCIE10: 0,
            AllCIE10: null,
            reasonMedicalConsultation: '',
            doctorLastName: '',
            doctorName: '',
            doctorIdentification: '',
            doctorPhone: '',
            diseaseOnset: '', //inicio de la enfermedad
            diseaseEvolution: '', // Evolucion de la enfermedad
            treatment: '',//tratamiento
            diagnosis: null,//diagnostico, con el select de CIE10
            chiefPhysician: '',//Medico Principal
            Assistant: '',//Asistente
            Anesthesiologist: '',//Anestesiologo,
            tentativeDate: ActualDate(),
            searchTitularWord: '',
            errorText: {
                titular: '',
                patient: '',
                option: '',
                reasonMedicalConsultation: '',
                doctorLastName: '',
                doctorName: '',
                doctorIdentification: '',
                doctorPhone: '',
                diseaseOnset: '',
                diseaseEvolution: '',
                treatment: '',
                chiefPhysician: '',
                Assistant: '',
                Anesthesiologist: '',
            },
            errors: {
                valid: {
                    titular: false,
                    patient: false,
                    option: false,
                    reasonMedicalConsultation: false,
                    doctorLastName: false,
                    doctorName: false,
                    doctorIdentification: false,
                    doctorPhone: false,
                    diseaseOnset: false,
                    diseaseEvolution: false,
                    treatment: false,
                    chiefPhysician: false,
                    Assistant: false,
                    Anesthesiologist: false,
                },
            },

            touched: {
                titular: false,
                patient: false,
                option: false,
                reasonMedicalConsultation: false,
                doctorLastName: false,
                doctorName: false,
                doctorIdentification: false,
                doctorPhone: false,
                diseaseOnset: false,
                diseaseEvolution: false,
                treatment: false,
                Assistant: false,
                chiefPhysician: false,
                Anesthesiologist: false,
            },



        }



        this.displayMessage = new DisplayMessage();
        this.pP = new PersonalProvider();

        this.fP = new FormularioProvider();
        //        const dCP= new DetalleCatalogoProvider();
        this.dCP = new DetalleCatalogoProvider();
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchPersonChange = this.handleSearchPersonChange.bind(this);
    }
    componentDidMount = () => {
        //this.findAllTitulares();
        this.findAllReasons();
        this.findAllCompanies();
        this.findAllCIE10();
        this.getUserRequirement();
    }

    resetFields = () => {
        this.setState({
            idRequirement: 0,
            city: '',
            titular: null,
            lastname: '',
            direction: '',
            mail: '',
            dateSelected: ActualDate(),
            reason: null,
            company: null,
            account: '',
            typeAccount: '',
            patient: null,
            polCodigo: 0,
            bank: '',
            option: 'hDia',
            countCIE10: 0,
            reasonMedicalConsultation: '',
            doctorLastName: '',
            doctorName: '',
            doctorIdentification: '',
            doctorPhone: '',
            diseaseOnset: '', //inicio de la enfermedad
            diseaseEvolution: '', // Evolucion de la enfermedad
            treatment: '',//tratamiento
            diagnosis: null,//diagnostico, con el select de CIE10
            chiefPhysician: '',//Medico Principal
            Assistant: '',//Asistente
            Anesthesiologist: '',//Anestesiologo,
            tentativeDate: ActualDate(),
            cityError: false,
            companyError: false,
            userRequirement: null,
        });
    }

    getUserRequirement = () => {
        if (this.state.idRequirement > 0) {
            this.pP.LiquidationByliqCodigo(this.state.idRequirement).then(
                (response) => {
                    try {
                        this.setState({
                            userRequirement: response[0]
                        });
                    } catch (error) {
                        console.error(error)

                    }
                }

            );

        }

    }

    validateNull = (element) => {
        
        if (element.value.length === 0 || element.value === 0) {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: 'Este campo es requerido'
            }
            const errors = {
                valid: { ...this.state.errors.valid, [element.name]: true },
            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        } else {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: ''
            }
            const errors = {
                valid: { ...this.state.errors.valid, [element.name]: false },


            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        }

    }
    isTouched = (name) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true }
        });
    }

    handleInputChange = async (event) => {
        //btnDigitalize
        const name = event.target.name;
        const value = event.target.value;
        await this.setState({
            [name]: value
        });

        await this.isTouched(name);
        await this.validateNull({ name: name, value: value })

        //  await this.enDisButton();

    }
    /** *
    enDisButton = () => {
        if (this.state.titular !== null
            && this.state.patient !== null
            && this.state.reasonMedicalConsultation !== ''
            && this.state.doctorLastName !== ''
            && this.state.doctorName !== ''
            && this.state.doctorIdentification !== ''
            && this.state.diseaseOnset !== ''
            && this.state.diseaseEvolution !== ''
            && this.state.treatment !== ''
            && this.state.diagnosis !== null
            && this.state.chiefPhysician !== ''
            && this.state.Assistant !== ''
            && this.state.Anesthesiologist !== ''
        ) {

            this.setState({
                disabled: false
            }
            );

        } else {

            this.setState(
                {
                    disabled: true
                }
            );


        }
    }
    */
    hasError = (field) => {
        return (
            (this.state.errors.valid[field]) &&
            this.state.touched[field]);
    }

    operationButton = (identificacion, state) => {
        const element = document.getElementById(identificacion);
        element.disabled = state;


    }
    setPatient = (value) => {
        this.setState({
            patient: value
        });

    }

    setDiagnosisis = (value) => {
        this.setState({
            diagnosis: value
        })
    }

    setCompany = (value) => {
        this.setState({
            company: value
        });

    }
    findAllCIE10 = () => {
        this.dCP.countByCodigo(6).then((response) => {
            try {
                return response[0].existencias;

            } catch (error) {
                console.error(error)
            }

        }).then(response => {
            this.dCP.findMaxResults(6, (parseInt(response) + 5)).then(
                (resp) => {
                    this.setState(
                        {
                            AllCIE10: resp
                        }
                    );

                }
            );
        })

    }

    visualValidation = () => {
        var Elements = document.forms['autorizacionForm'].elements;
        for (var i = 0; i < Elements.length; i++) {
            if ((Elements[i].type === 'text' ||
                Elements[i].type === 'number' ||
                Elements[i].type === 'date')

            ) {
                if (Elements[i].value === '') {
                    this.setState({
                        [`${Elements[i].name}Error`]: true
                    });
                } else {
                    this.setState({
                        [`${Elements[i].name}Error`]: false
                    });
                }

            }
        }
    }

    Digitalize = () => {
        if (this.state.titular !== null && this.state.patient !== null && this.state.reasonMedicalConsultation !== ''
            && this.state.doctorLastName !== '' && this.state.doctorName !== ''
            && this.state.doctorIdentification !== ''
            && this.state.diseaseOnset !== ''
            && this.state.diseaseEvolution !== ''
            && this.state.treatment !== ''
            && this.state.diagnosis !== null
            && this.state.chiefPhysician !== ''
            && this.state.Assistant !== ''
            && this.state.Anesthesiologist !== ''
        ) {

            const toSave = {
                //"forCodigo":1,
                'empCodigo': this.state.company.empCodigo,
                'detTipo': 17173,
                'perTitular': this.state.titular.perCodigo,
                'perPaciente': this.state.patient.perCodigo,
                'polCodigo': this.state.titular.polCodigo,
                'cueCodigo': this.state.titular.detTipoCuenta,
                'detProcedimiento': this.state.reason.detCodigo,
                'detCie10': this.state.diagnosis.detCodigo,
                //"plaCodigo":null,
                //"preCodigo": null,
                'forFecha': this.formatDate(this.state.dateSelected),
                'forDetalle': `{city: ${this.state.city}, motivoConsulta: ${this.state.reasonMedicalConsultation}, nombreMedico: ${this.state.doctorName},` +
                    `apellidoMedico: ${this.state.doctorLastName}, identificacionMedico: ${this.state.doctorIdentification}, telefonoMedico: ${this.state.doctorPhone},` +
                    `inicioEnfermedad: ${this.state.diseaseOnset}, evolucionEnfermedad: ${this.state.diseaseEvolution}, tratamiento: ${this.state.treatment},` +
                    `honorarioPrincipa: ${this.state.chiefPhysician}, honorarioAyudante: ${this.state.Assistant}, honorarioAnestesiologo: ${this.state.Anesthesiologist},` +
                    `fechaTentativaIngreso: ${this.state.tentativeDate} }`

            }
            this.fP.createLiquidation(toSave).then(
                response => {
                    
                    if (response.error.codigo === '0') {
                        this.displayMessage.success('El elemento ha sido almacenado con exito');
                        window.location.reload()
                    } else {
                        this.displayMessage.error('Algo ha salido mal, verifica si los datos enviados son correctos, contactate con soporte de ser necesario');
                    }
                }
            ).catch(
                (error) => {
                    this.displayMessage.error('Ha ocurrido un error' + error);

                }
            );


        } else {
            
            this.showEmpty();
            this.displayMessage.error('Se han detectado campos vacios');
        }
    }

    showEmpty=async()=>{
        
        await this.isTouched('reasonMedicalConsultation');
        await this.isTouched('doctorLastName');
        await this.isTouched('doctorName');
        await this.isTouched('doctorIdentification');
        await this.isTouched('doctorPhone');
        await this.isTouched('diseaseOnset');
        await this.isTouched('diseaseEvolution');
        await this.isTouched('treatment');
        await this.isTouched('chiefPhysician');
        await this.isTouched('Assistant');

        await this.isTouched('Anesthesiologist');


        await this.validateNull({ name: 'reasonMedicalConsultation', value: this.state.reasonMedicalConsultation });
        await this.validateNull({ name: 'doctorLastName', value: this.state.doctorLastName });
        await this.validateNull({ name: 'doctorName', value: this.state.doctorName });
        await this.validateNull({ name: 'doctorIdentification', value: this.state.doctorIdentification });
        await this.validateNull({ name: 'doctorPhone', value: this.state.doctorPhone });
        await this.validateNull({ name: 'diseaseOnset', value: this.state.diseaseOnset });
        await this.validateNull({ name: 'diseaseEvolution', value: this.state.diseaseEvolution });
        await this.validateNull({ name: 'treatment', value: this.state.treatment });
        await this.validateNull({ name: 'chiefPhysician', value: this.state.chiefPhysician });
        await this.validateNull({ name: 'Assistant', value: this.state.Assistant });

        await this.validateNull({ name: 'Anesthesiologist', value: this.state.Anesthesiologist });


    }

    formatDate = (dateString) => {

        //dd/MM/yyyy HH:mm:ss
        var dateCad = dateString.split('-')


        const dateConverted = `${dateCad[2]}/${dateCad[1]}/${dateCad[0]}`;

        return dateConverted;
    }
    setTitular = (value) => {
        try {
            this.setState({
                titular: value,
                patient: null

            });
            this.findAllPatient(value.polCodigo);
        } catch (error) {
            this.setState({
                titular: null,
                patient: null

            });

        }

    }

    setReason = (value) => {
        this.setState({
            reason: value
        });
    }
    findAllReasons = () => {
        const dCS = new DetalleCatalogoProvider();
        dCS.findDetalleCatalogo(24).then(
            response => {
                this.setState({
                    AllReasons: response
                });
            }
        )
    }


    findAllPatient = (polCode) => {
        const dP = new PersonalProvider();
        dP.allPatientByPolCodigo(polCode).then((response) => {
            try {
                this.setState(
                    {
                        AllPatient: response
                    }
                );
            } catch (error) {

            }

        })
    }

    findAllTitulares = (value) => {
        const dP = new PersonalProvider();//perIdentificacion
        dP.allPersonsByNombre2('TITULAR',value).then((response) => {
            
            try {
                this.setState(
                    {
                        AllTitulars: response
                    }
                );
            } catch (error) {

            }

        })
    }

    findAllCompanies = () => {
        const eP = new EmpresaProvider();
        eP.allCompanies().then(response => {
            this.setState({
                AllCompanies: response
            })
        })
    }

    handleDateChange = async (e) => {
        // this.state.selectedDate = date;
        const name = e.target.name;
        const value = e.target.value;
        await this.setState({
            [name]: value
        });

        // await this.enDisButton();

    }


    findByIdentification = (iden) => {

        const dP = new PersonalProvider();

        dP.findPerson(iden).then((response) => {

            try {
                this.setState({
                    name: response[0].perNombres,
                    lastname: response[0].perApellidos,
                    mail: response[0].perMail
                });
            } catch (error) {
                this.setState({
                    name: '',
                    lastname: '',
                    mail: ''
                });

            }
        });


    }

    handleChange = event => {
        this.setState({
            option: event.target.value
        });
        //setValue(event.target.value);
    };
    handleSearchPersonChange = (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;
        this.setState({
            [name]:value
        });
  
        if(value.length >=5){
            this.findAllTitulares(value);
        }
      
  
    }
    render() {
        return (
            <Page className='absolute-container' >
                {
                    this.state.userRequirement ?
                        <GenericHeaderFoms
                            name={this.state.userRequirement.usuNombre}
                            requirement={`REQ-${this.state.userRequirement.liqCodigo}`}
                        /> : ''
                }
                <form id='autorizacionForm'>
                    <div className='spacing-bottom'>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <TextField error={this.state.cityError} id="outlined-basic" label="Ciudad"
                                    name='city'

                                    onChange={this.handleInputChange}
                                    value={this.state.city}

                                    variant="outlined"
                                />

                            </Grid>
                            <Grid className='right' item xs>
                                <TextField id="outlined-basic" label="Fecha" name='dateSelected' onChange={this.handleDateChange} type="date" value={this.state.dateSelected} variant="outlined" />

                            </Grid>




                        </Grid>
                    </div>

                    <Card className='spacing-bottom'>
                        <Card >
                            <CardHeader
                                color='red'
                                title="1. Datos Afiliados"
                            />
                            <Divider />
                        </Card>

                        <Card >
                            <CardContent>
                                {this.state.AllCompanies ?
                                    <div >
                                        <PersonAutocomplete
                                            data={this.state.AllCompanies}
                                            fieldName="option.empNombreComercial"
                                            label="Contratante Principal Empresa"
                                            selectedElement={this.state.company}
                                            setPerson={this.setCompany}
                                        />
                                        
                                    </div>
                                    : <CircularProgress />

                                }


                            </CardContent>
                        </Card>




                        <Card >
                            <CardHeader
                                title="Titular"
                            />
                            <Divider />
                            <CardContent>

                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <GenericSearch
                                            setValue={this.setTitular}
                                            selectedElement={this.state.titular}
                                            data={this.state.AllTitulars}
                                            fieldName="option.perIdentificacion"
                                            fieldName2="option.perNombres"
                                            label="Cedula"
                                            searchWord={this.state.searchTitularWord}
                                            nameInput='SearchTitularWord'
                                            handleInputChange={this.handleSearchPersonChange}
                                            methodSearchPerson={this.findAllTitulares}
                                        />

                                    </Grid>

                                    <Grid item xs>
                                        <TextField
                                            error={this.state.showErrorFiled}
                                            fullWidth id="standard-basic"
                                            label="Nombre" name='name'
                                            value={this.state.titular ?
                                                this.state.titular.perNombres
                                                : ''} />

                                    </Grid>



                                </Grid>


                            </CardContent>
                        </Card>

                        <Card >
                            <CardHeader
                                title="Paciente"
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        {
                                            this.state.titular && this.state.AllPatient ?
                                                <PersonAutocomplete
                                                    data={this.state.AllPatient}
                                                    fieldName="option.perIdentificacion"
                                                    fieldName2="option.perNombres"
                                                    label="Cedula"
                                                    selectedElement={this.state.patient}
                                                    setPerson={this.setPatient}
                                                /> :
                                                <Typography display="block" gutterBottom variant="overline">
                                                    <div className=""> Seleccione un Titular</div>
                                                </Typography>}
                                        {//                       <TextField  name='patientIdentification' id="standard-basic" label="Identificacion" 
                                            //   fullWidth />     
                                        }


                                    </Grid>


                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Nombres"
                                            name='patientName' value={this.state.patient ? this.state.patient.perNombres : ''} />

                                    </Grid>

                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Edad"
                                            name='patientAge'
                                            value={this.state.patient ? this.state.patient.edadActual : ''} />

                                    </Grid>


                                </Grid>
                                <Grid container spacing={3}>

                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Parentesco"
                                            name='patientRelationShip'

                                            value={this.state.patient ? this.state.patient.nombre2 : ''} />

                                    </Grid>

                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Direccion"
                                            name='patientDirection'
                                            value={this.state.patient ? this.state.patient.perDireccion : ''} />

                                    </Grid>
                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Telefono"

                                            name='patientPhone' />

                                    </Grid>


                                </Grid>
                                <Grid container spacing={3}>


                                    <Grid item xs>
                                        <TextField fullWidth id="standard-basic" label="Correo Electronico"
                                            name='patientMail'
                                            value={this.state.patient ? this.state.patient.perMail : ''} />

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>


                        <Card >
                            <CardHeader
                                title="Transferencia a reembolso"
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            id="standard-basic"
                                            label="Numero de Cuenta"
                                            name='account' value={this.state.titular ?
                                                this.state.titular.detNemonico
                                                : ''} />

                                    </Grid>

                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            id="standard-basic"
                                            label="Tipo de Cuenta"
                                            name='typeAccount' value={this.state.titular ?
                                                this.state.titular.detTipoCuenta :
                                                ''} />

                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField fullWidth id="standard-basic"
                                            label="Banco"
                                            name='bank'
                                            value={this.state.titular ?
                                                this.state.titular.nombre1 :
                                                ''} />

                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>





                    </Card>




                    <Card>
                        <Card >
                            <CardHeader
                                title="2. Antecedentes médicos e informacién del médico"
                            />
                            <Divider />
                        </Card>
                        <CardHeader
                            title="Motivo de Procedimiento"
                        />
                        <Divider />
                        <Card >
                            <center>
                                <CardContent>
                                    {
                                        this.state.AllReasons ?
                                            <PersonAutocomplete
                                                data={this.state.AllReasons}
                                                fieldName="option.detNombre"
                                                label="Motivo del Procedimiento"
                                                selectedElement={this.state.reason}
                                                setPerson={this.setReason}

                                            /> : <CircularProgress />
                                    }


                                </CardContent>
                            </center>
                        </Card>
                        <Card
                        //className={classes.cardSpace}
                        >
                            <Divider />
                            <CardContent>
                                <TextField error={this.hasError('reasonMedicalConsultation')} fullWidth helperText={this.hasError('reasonMedicalConsultation') ? this.state.errorText.reasonMedicalConsultation: '' } label='Motivo de la Consulta' name='reasonMedicalConsultation' onChange={this.handleInputChange} value={this.state.reasonMedicalConsultation} />

                            </CardContent>
                        </Card>

                        <Card >
                            <CardHeader
                                title="Médico"
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <TextField error={this.hasError('doctorLastName')} fullWidth helperText={this.hasError('doctorLastName') ? this.state.errorText.doctorLastName: ''} label='Apellidos' name='doctorLastName' onChange={this.handleInputChange} value={this.state.doctorLastName}  />


                                    </Grid>
                                    <Grid item xs>
                                        <TextField error={this.hasError('doctorName')} fullWidth helperText={this.hasError('doctorName') ? this.state.errorText.doctorName: ''} label='Nombres' name='doctorName' onChange={this.handleInputChange} value={this.state.doctorName} />


                                    </Grid>
                                    <Grid item xs>
                                        <TextField error={this.hasError('doctorIdentification')} fullWidth helperText={this.hasError('doctorIdentification') ? this.state.errorText.doctorIdentification: ''} label='Cedula' name='doctorIdentification' onChange={this.handleInputChange} required='true' value={this.state.doctorIdentification} />


                                    </Grid>
                                    <Grid item xs>
                                        <TextField error={this.hasError('doctorPhone')} fullWidth helperText={this.hasError('doctorPhone') ? this.state.errorText.doctorPhone: ''} label='Telefono' name='doctorPhone' onChange={this.handleInputChange} required='true' value={this.state.doctorPhone} />


                                    </Grid>



                                </Grid>




                            </CardContent>
                        </Card>


                        <Card>
                            {/** diseaseOnset:'', //inicio de la enfermedad
                diseaseEvolution: '', // Evolucion de la enfermedad
                treatment:'',//tratamiento
                diagnosis:''//diagnostico, con el select de CIE10
                */   }
                            <CardContent>
                                <TextField error={this.hasError('diseaseOnset')} fullWidth helperText={this.hasError('diseaseOnset') ? this.state.errorText.diseaseOnset: ''} label='Inicio de la Enfermedad' name='diseaseOnset' onChange={this.handleInputChange} value={this.state.diseaseOnset} />

                                <br />
                                <br />

                                <TextField error={this.hasError('diseaseEvolution')} fullWidth helperText={this.hasError('diseaseEvolution') ? this.state.errorText.diseaseEvolution: ''} label='Evolucion de la enfermedad' name='diseaseEvolution' onChange={this.handleInputChange} value={this.state.diseaseEvolution} />

                                <br />
                                <br />
                                <TextField error={this.hasError('treatment')} fullWidth helperText={this.hasError('treatment') ? this.state.errorText.treatment: ''} label='Tratamiento y/o procedimientos recibidos' name='treatment' onChange={this.handleInputChange} value={this.state.treatment} />


                                <br />
                                <br />
                                {
                                    this.state.AllCIE10 ?
                                        <PersonAutocomplete
                                            data={this.state.AllCIE10}
                                            fieldName="option.detDescripcion"
                                            label="Diagnostico (Segun CIE10)"
                                            selectedElement={this.state.diagnosis}
                                            setPerson={this.setDiagnosisis}

                                        /> : <CircularProgress />
                                }




                                <br />
                                <br />




                            </CardContent>
                        </Card>
                    </Card>


                    {/** '''''''''''''''''''''''''''''''''''''''''''''''''''''''*/}



                    {/***----------------------------------------------Honorarios Medicos      */}

                    <Card >
                        <CardHeader
                            title="Honorarios Medicos"
                        />
                        <Divider />
                        <center>
                            <div className='middle-page'>
                                <CardContent>

                                    <TextField error={this.hasError('chiefPhysician')} fullWidth helperText={this.hasError('chiefPhysician') ? this.state.errorText.chiefPhysician: ''} label='Medico Principal' name='chiefPhysician' onChange={this.handleInputChange} type='number' value={this.state.chiefPhysician} />

                                    <TextField error={this.hasError('Assistant')} fullWidth helperText={this.hasError('Assistant') ? this.state.errorText.Assistant: ''} label='Ayudante' name='Assistant' onChange={this.handleInputChange} type='number' value={this.state.Assistant} />

                                    <TextField error={this.hasError('Anesthesiologist')} fullWidth helperText={this.hasError('Anesthesiologist') ? this.state.errorText.Anesthesiologist: ''} label='Anestesiologo' name='Anesthesiologist' onChange={this.handleInputChange} type='number' value={this.state.Anesthesiologist} />



                                </CardContent>
                            </div>
                        </center>
                    </Card>




                    {/**----------------------------------------------------------------------- */}


                    <Card >
                        <CardContent>
                            <Grid container spacing={5}>
                                <Grid item xs>
                                    <RadioGroup onChange={this.handleChange} row value={this.state.option} >

                                        <Grid container spacing={5}>
                                            <Grid item xs>

                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label="Hospital del Dia"
                                                    labelPlacement="end"
                                                    value="hDia"
                                                />
                                            </Grid>

                                            <Grid item xs>

                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label="Hospitalizacion"
                                                    labelPlacement="end"
                                                    value="Hospitalizacion"
                                                />
                                            </Grid>
                                            <Grid item xs>

                                                <FormControlLabel
                                                    control={<Radio color="primary" />}
                                                    label={<TextField label='Nombre del hospital o clinica' fullWidth />}
                                                    labelPlacement="end"
                                                    value="alternative"
                                                />
                                            </Grid>


                                        </Grid>

                                    </RadioGroup>



                                </Grid>

                            </Grid>

                            <div className='absolute-container'>
                                <center>
                                    <TextField id="outlined-basic" label="Fecha tentantiva de ingreso" name='tentativeDate' onChange={this.handleDateChange} type="date" value={this.state.tentativeDate} variant="outlined" />

                                </center>
                            </div>




                        </CardContent>
                    </Card>


                    <div class='right'>
                        <Button
                            color="primary"
                            disabled={this.state.disabled}
                            id='btnDigitalize'
                            onClick={this.Digitalize}
                            variant="contained"
                        >
                            DIGITALIZAR
                        </Button>
                    </div>


                </form>

            </Page>



        );
    }

}