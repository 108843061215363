import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import {
    Header
} from './components';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}));

const Presentation = () => {
    const session = useSelector(state => state.session);  
    const classes = useStyles();  
  
    return (
        <Page
            className={classes.root}
            title="Bienvenid@"
        >      
            <Header session={session} />      
        </Page>
    );
};

export default Presentation;
