import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Typography,
    CircularProgress,
    Grid,
    Button, Tooltip
} from '@material-ui/core';
import { colors } from '@material-ui/core';
import { Page } from 'components';
import { Chart } from './components';
import { TablePlan } from './components';
import { GraficProvider } from 'providers/graficData.service';
import { DisplayMessage } from 'notifications/displayMessage';
import MaterialTable from 'material-table';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { SearchBasic } from 'components';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0
        }
    },
    chartContainer: {
        padding: theme.spacing(3)
    },
    chart: {
        height: 281
    },
    space: {
        marginBlockEnd: 10
    },
    statsContainer: {
        display: 'flex'
    },
    statsItem: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(3, 2),
        '&:not(:last-of-type)': {
            borderRight: `1px solid ${theme.palette.divider}`
        }
    },
    item: {
        padding: theme.spacing(3),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            '&:not(:last-of-type)': {
                borderRight: `1px solid ${theme.palette.divider}`
            }
        },
        [theme.breakpoints.down('sm')]: {
            '&:not(:last-of-type)': {
                borderBottom: `1px solid ${theme.palette.divider}`
            }
        }
    },
}));

const EarningsSegmentation = props => {

    const classes = useStyles();
    const [basic, setBasic] = useState('');
    const [premiun, setPremiun] = useState('');
    const [platinum, setPlatinum] = useState('');
    const [suma, setSuma] = useState(0);
    const [planBasic, setPlanBasic] = useState(0);
    const [titulo, setTitulo] = useState('');
    const [prima, setPrima] = useState(0);
    const [changeValue, setChangeValue] = useState(false);
    const graficProvider = new GraficProvider();
    const displayMessage = new DisplayMessage();



    useEffect(() => {
        const graficProvider = new GraficProvider();
        const displayMessage = new DisplayMessage();
        graficProvider.consultaPlanBasico()
            .then(
                basic => {
                    setBasic(basic.bodyOut.data[0].existencias)

                },
                () => {
                    displayMessage.error('Error de comunicación.');

                }
            );
        graficProvider.consultaPlanPremiun()
            .then(
                basic => {
                    setPremiun(basic.bodyOut.data[0].existencias)

                },
                () => {
                    return false;

                }
            );
        graficProvider.consultaPlanPlatinum()
            .then(
                basic => {
                    setPlatinum(basic.bodyOut.data[0].existencias)
                },
                () => {
                    return false;

                }
            );

        graficProvider.consultaMontoCobertura()
            .then(
                monto => {
                    const d = [];
                    for (const mont of monto.bodyOut.data) {
                        const datos = mont.pepMontoCobertura

                        if (datos === null) {

                        } else {

                            d.push(datos)
                        }

                    }
                    let total = 0;
                    d.forEach(function (a) { total += a; });

                    setSuma(total)
                },
                () => {
                    return false;

                }
            );

        graficProvider.consultaValorPrimas()
            .then(
                monto => {
                    const d1 = [];
                    for (const mont of monto.bodyOut.data) {
                        const datos1 = mont.valorMensual

                        if (datos1 === null) {

                        } else {

                            d1.push(datos1)
                        }

                    }
                    let total1 = 0;
                    d1.forEach(function (a1) { total1 += a1; });

                    setPrima(total1.toFixed(2))
                },
                () => {
                    return false;

                }
            );



        return () => {
        };

    }, []);



    const data = [
        {

            label: 'MASIVOS BASICOS CPN',
            value: basic,
            color: colors.indigo[500]
        },
        {
            label: 'MASIVOS PREMIUN CPN',
            value: premiun,
            color: colors.indigo[300]
        },
        {
            label: 'MASIVOS PLATINUM CPN',
            value: platinum,
            color: colors.indigo[100]
        }
    ];



    const onClickBasic = (data) => {
        setTitulo(data.label)
        setChangeValue(true)

    }


    const dataReportsExport = (typeResult) => {

        graficProvider.dataReportsExport(titulo, typeResult).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };


    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_GRAFICO}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    return (

        <Page className={classes.root}>
            <Card className={classes.space}>
                <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                >
                    <Grid
                        className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                    >
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="overline"
                        >
                            Total Monto de Cobertura
                        </Typography>
                        <div className={classes.valueContainer}>
                            <Typography variant="h3">${suma}</Typography>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                    >
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="overline"
                        >
                            Total de polizas contratadas
                        </Typography>
                        <div className={classes.valueContainer}>
                            <Typography variant="h3">{basic + premiun + platinum}</Typography>
                        </div>
                    </Grid>
                    <Grid
                        className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                    >
                        <Typography
                            component="h2"
                            gutterBottom
                            variant="overline"
                        >
                            Valor Primas
                        </Typography>
                        <div className={classes.valueContainer}>
                            <Typography variant="h3">${prima}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Card>
            <br />
            <Card>
                <CardHeader
                    //action={<GenericMoreButton />}
                    title="Estadística Planes"
                />
                <Divider />
                {suma ?
                    <CardContent className={classes.content}>
                        <div className={classes.chartContainer}>
                            <Chart
                                className={classes.chart}
                                data={data}
                            />
                        </div>
                        <Divider />
                        <div className={classes.statsContainer}>
                            {data.map((data, index) =>
                                <div
                                    className={classes.statsItem}
                                    key={index}
                                >
                                    <Typography
                                        align="center"
                                        component="h6"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        {data.label}
                                    </Typography>
                                    <Typography
                                        align="center"
                                        variant="h4"
                                    >
                                        {data.value}
                                    </Typography>
                                    <Button
                                        color="primary"
                                        onClick={(e) => onClickBasic(data)}
                                    >Ver</Button>
                                </div>
                            )}
                        </div>
                    </CardContent> : <CircularProgress />}
            </Card>
            <br />
            {changeValue &&


                <TablePlan
                    dataPlan={planBasic}
                    dataReportsExport={dataReportsExport}
                    titulo={titulo}
                />
            }
        </Page >
    );
};

EarningsSegmentation.propTypes = {
    className: PropTypes.string
};

export default EarningsSegmentation;
