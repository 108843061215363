import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Toolbar,
    IconButton,
    Tooltip,
    Divider,
    List,
    TextField,
    Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import useRouter from 'utils/useRouter';
import { ConversationListItem } from './components';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.white,
       
    },
    scroll:{
        position: 'relative',
        overflow: 'auto',
        maxHeight: '100%'
    },
    searchInput: {
        flexGrow: 1
    }
}));

const ConversationList = props => {

    const { conversations, className, valSearch, handleSearchChange, ...rest } = props;

    const classes = useStyles();
    const router = useRouter();
    const selectedConversation = router.match.params.id;

    
   
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Toolbar>
                <TextField
                    className={classes.searchInput}
                    onChange={handleSearchChange}
                    placeholder="BUSCAR LIQUIDACIONES"
                    value={valSearch}
                />
                <Tooltip title="Search">
                    <IconButton edge="end">
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
            <Divider />
           
            <List
                className={classes.scroll}
                disablePadding
            >
                {conversations ? 
                    conversations.length>0 ? 
                        conversations.map((conversation, i) => 
                            <ConversationListItem
                                active={parseInt(conversation.liqCodigo) === parseInt(selectedConversation)}
                                conversation={conversation}
                                divider={i < conversations.length - 1}
                                key={i}
                            />
                        ) : 
                        <center>
                            <Typography 
                                display="block"
                                gutterBottom
                                style={{ padding: '15px'}}
                                variant="overline"
                            >
                                    No hay mensajes para mostrar
                            </Typography>
                        </center>
                    :
                    <center>
                        <br/>
                        <CircularProgress disableShrink />
                        <br/>
                        <br/>

                    </center>
                }
            </List>  
            
        </div>
    );
};

ConversationList.propTypes = {
    className: PropTypes.string,
    conversations: PropTypes.array
};

export default ConversationList;
