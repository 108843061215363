import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
//import FilterListIcon from '@material-ui/icons/FilterList';

import { TableForm } from './components';

const TableGeneric = props => {
  const { columna, data, actions} = props;

  return (
    <Grid>
 
        <TableForm
          data={data}
          columna={columna}
          actions={actions}
        />
        </Grid>
  );
};

TableGeneric.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default TableGeneric;