import { userConstants } from "_constants";
import { UserProvider } from 'providers/user.service';
import { StorageService } from 'utils/storage';

function login() {  
  return dispatch => {
    let userService = new UserProvider()
    if (userService.isAuthenticated()) {
      let user = userService.getUser();    
      dispatch({
        type: userConstants.SESSION_LOGIN,
        user
      });      
    } else {
      dispatch({
        type: userConstants.SESSION_FAILURE
      });      
    }  
  };  
}

function logout() {
  let userService = new UserProvider()
  userService.logout();
  return {
    type: userConstants.SESSION_LOGOUT
  }
}

function changeSession(role) {
  let userService = new UserProvider()
  let user = userService.getUser();    
  let userRoles = user.roles;  
  userRoles.map(item => {
    if(item[0] === role ){
      user.role = item[0];
      user.bio = item[1];
      user.token = item[2];    
    }
    return 0;
  })
  let _storageService = new StorageService()
  _storageService.saveSession(user);  
  return {
    type: userConstants.SESSION_CHANGE,
    user
  }
}

function activatePoliza(estado){
  let userService = new UserProvider()
  let user = userService.getUser();      
  user.polEstado = estado  
  let _storageService = new StorageService()
  _storageService.saveSession(user);  
  return {
    type: userConstants.SESSION_CHANGE,
    user
  }
}

const sessionActions = {
    login,
    logout,
    changeSession,
    activatePoliza
}

export default sessionActions;

