export const GET_CAT_BOXES = 'GET_CAT_BOXES';
export const GET_BOXES = 'GET_BOXES';
export const GET_TRAMITS_OPEN = 'GET_TRAMITS_OPEN';
export const SAVE_BOXES = 'SAVE_BOXES';
export const SEND_BOXES = 'SEND_BOXES';
export const LOADING = 'LOADING';
export const CHANGE_CHECKED = 'CHANGE_CHECKED';
export const SEND_OPERATOR = 'SEND_OPERATOR';
export const GET_SEND_BOXES = 'GET_SEND_BOXES';
export const GET_TRAMITS_PEND = 'GET_TRAMITS_PEND';


export const TRAMITE_SAVE = 'TRAMITE_SAVE';
export const GET_OBSERVATION = 'GET_OBSERVATION';
export const STATE_MODAL = 'STATE_MODAL';
export const STATE_MODAL_DOCUMENT = 'STATE_MODAL_DOCUMENT';
export const UPLOAD_FILES = 'UPLOAD_FILES';
export const GET_QA = 'GET_QA';
export const TRAMIT_INVOICE = 'TRAMIT_INVOICE';
export const REMESA_TRAMIT = 'REMESA_TRAMIT';



export const GET_CAT_TICKET = 'GET_CAT_TICKET';
export const GET_TICKES_EMAIL = 'GET_TICKES_EMAIL';

export const GET_USER = 'GET_USER';
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_USER = 'GET_ROLES_USER';
export const SELECT_USER = 'SELECT_USER';
export const EDIT_USER = 'EDIT_USER';
export const EXIT_EDIT_USER = 'EXIT_EDIT_USER';


