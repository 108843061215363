import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Search } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    search: {
        flexGrow: 1,
        maxWidth: 480,
        flexBasis: 480
    },
    filterButton: {
        marginLeft: 'auto'
    },
    filterIcon: {
        marginRight: theme.spacing(1)
    }
}));

const SearchBar = props => {
    const { valSearch, handleSearchChange,  className, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
        >
      
            <Grid item>
                <Search
                    className={classes.search}
                    handleSearchChange={handleSearchChange}
                    valSearch={valSearch}
                />
            </Grid>
        </Grid>
    );
};

SearchBar.propTypes = {
    className: PropTypes.string,
    onFilter: PropTypes.func,
    onSearch: PropTypes.func
};

export default SearchBar;
