import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
//import './common.css';
import { PaymentProvider } from 'providers/payment.service';

import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import { PolizaProvider } from 'providers/poliza.service';
import { makeStyles } from '@material-ui/core/styles';
import { red, orange } from '@material-ui/core/colors';
import { QueriesProvider } from 'providers/queries.service';

import { SearchBasic } from 'components';
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: orange[400],
    },
    redChip: {
        backgroundColor: red[400],

    }
}));


const PayList = ({ session }) => {
    const classes = useStyles();

    const displayMessage = new DisplayMessage;
    const paymentProvider = new PaymentProvider();
    const polizaProvider = new PolizaProvider();
    const queriesProvider = new QueriesProvider();
    const [payments,setPayments] = useState([]);
    const [polizaUser,setPolizaUser] = useState(null);
    const [searchBar, setSearchBar] = useState('');
    //consulta
    /*const [codigo,setCodigo]=useState('');
    useEffect(() => {
        paymentProvider.findDetNemonico('PAG').then(
            data=>{
                
                if(data.error.codigo==='0'){
                    setCodigo(data.bodyOut.data[0].detCodigo)
                }else{
                    displayMessage.error('Error de comunicación')
                }
            }
        )
       
    }, [])*/

    let recorrer = 0;

    /** ----------------------------------- Limpiar el buscador */
    const cleanSearchBar = () => {
        setSearchBar('');
    }
    

    const getPolizaByPolCodigo = () => {
        polizaProvider.findByPolCodigo(session.user.polCodigo).then(
            response => {
                setPolizaUser(response[0]);
            }
        )
    }

    const columns = [
        { title: 'Monto', field: 'pagMonto' },
        { title: 'Fecha de vencimiento', field: 'pagFechaVencimiento' },
        { title: 'Fecha de pago', field: 'pagFechaPago' },
        { title: 'Estado', field: 'detNombre' },
        { title: 'Observación', field: 'pagObservacion' },
       
    ];

    const componentDidMount = () => {
         setSearchBar(session.user.perIdentificacion);
        getPolizaByPolCodigo();
        handleSearch();
    }

    useEffect(componentDidMount, []);
    /** ------------------------------------Para dialogo de pagos ---------- */
   
  

    const handleInputChange = event => {
        const {value}= event.target;
        setSearchBar(value);
    }

    const handleSearch = () => {
        const search = searchBar === '' ? session.user.perIdentificacion: searchBar;
        queriesProvider.searchPaymentsByIdentification2(search).then(
            response => {
                if(response.error.codigo === '0'){
                    setPayments(response.bodyOut.data);
                }
            }
        )
        
    }

    return (
        <div>

            <SearchBasic
                searchBar={searchBar}
                cleanSearchBar={cleanSearchBar}
                handleInputChange={handleInputChange}
                complementaryMessage='Cédula'
                handleSearch={handleSearch}
            />

           {/* <Grid container
                justify="flex-end"
                spacing={2}>
                <Button color="secondary" startIcon={<BorderColorIcon/>} variant="contained" onClick={OpenChange}>
                Cargar Datos
                </Button>
            </Grid> */}
            <br/>
            {
                payments.length > 0 && <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Nombres:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perNombres}
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Apellido:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perApellidos}
                            </Grid>
                        </Grid>
                        <br/><br/>
                        <Grid container spacing={2}>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Identificación:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].perIdentificacion}
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                <b>Póliza:</b>
                            </Grid>
                            <Grid item
                                md={3}
                                xs={6}>
                                {payments[0].polContrato}
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            }
            <br/>
            <MaterialTable

                columns={columns}
                /**
                 * actions={[
                    {
                        icon: 'create',
                        tooltip: 'Actualizar',
                        onClick: (event, rowData) => this.setOpenPersonUpdateModal(true, rowData)
                    }
                ]}
                components={{
                    Action: props => (
                        props.data.detNemonico === 'PAG1' &&
                        <FormControlLabel
                            control={
                            }
                            label=""
                        />

                    ),
                }}
                */

                data={payments}

                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsPerPage: 'filas'

                    },
                    toolbar: {
                        nRowsSelected: '{0} Fila(s) Seleccionada(s)',
                        searchPlaceholder: 'Buscar'
                    },
                    header: {
                        actions: 'Acción'
                    },
                    body: {

                        emptyDataSourceMessage: 'No hay registros para mostrar',
                        filterRow: {
                            filterTooltip: 'Filter',

                        },

                    }
                }}

                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                        width: 100,
                        textAlign: 'center'
                    },
                    headerStyle: {
                        fontWeight: 'bold'
                    },
                    toolbar: false,
                    pageSize: 12,
                    showTitle: false,
                    rowStyle: () => {
                        recorrer = recorrer + 1;
                        return ({
                            backgroundColor: recorrer % 2 ? '#FFF' : '#EEE',
                        })
                    },
                }}


                title={'Persona'}
            />
           
            <br/>
            {/* <Grid container
                justify="flex-end"
                spacing={2}>
                <Button color="secondary" startIcon={<BorderColorIcon/>} variant="contained" onClick={handleOpen2}>
                Registrar Reversas
                </Button>                
            </Grid> */}
            
        </div>
    );
};


export default PayList;
