import React from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const Reports = () =>{

    const classes = useStyles();
    const session = useSelector(state => state.session);  

    return (
        <Page
            className={classes.root}
            title="Cancelar polizas"
        >
            <Header />
            <br></br>
      
            <Results
                className={classes.results}
            />
        </Page>
    )

    

};

export default Reports;
