/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import { ReportsProvider } from 'providers/reports.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const PendienteAuditoria = () => {
    const classes = useStyles();
    const [total, setTotal] = useState(0);
    const [liquidation, setLiquidation] = useState(null);
    const displayMessage = new DisplayMessage();
    const reportsProvider = new ReportsProvider();


    useEffect(() => {
        fetchLiquidations();
    }, []);
    /*
        useEffect(() => {
            // pagination(numero, desde);
        }, [numero, desde]); */

    const fetchLiquidations = (
        valueSearch = '',
        valueSearch1 = '',
        valueSearch2 = ''
    ) => {
        reportsProvider
            .dataTotal(
                '%',
                valueSearch,
                valueSearch1,
                valueSearch2
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        setLiquidation(response.bodyOut.data);
                        setTotal(response.bodyOut.data[0].count);
                    } else {
                        setLiquidation([]);
                        setTotal(0);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };


    return (
        <Page className={classes.root} title="Liquidaciones">
            <Header className={classes.color} total={total} />

            {liquidation ? (
                <Results
                    className={classes.results}
                />
            ) : (
                    <CircularProgress className={classes.results} />
                )}
        </Page>
    );
};

export default PendienteAuditoria;
