/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Modal,
    Card,
    CardContent,
    CardActions,
    Grid,
    Typography,
    TextField,
    Button,
    colors,   
} from '@material-ui/core';
import validate from 'validate.js';
import { DisplayMessage } from 'notifications/displayMessage';


const schema = {
    talObservacion: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },    
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    container: {
        marginTop: theme.spacing(3)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));

const ModalAddObservacion = props => {
    const { open, resetData, onClose, className, filaActiva, filaActivaObs, ...rest } = props;
    const classes = useStyles();
    const [eventSelected, setEventSelected] = React.useState(true);    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const displayMessage = new DisplayMessage();

    useEffect(() => {
        setFormState({
            isValid: false,
            values: {talObservacion: filaActivaObs},
            touched: {},
            errors: {}
        });

    }, [resetData]);

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}            
        }));        
    }, [formState.values]);

    const handleChange = event => {        
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    if (!open) {
        return null;
    }        
            
    const handleSaveObservacion = () => {
        if(!formState.isValid){        
            const errors = validate(formState.values, schema);
            setFormState(formState => ({
                ...formState,
                isValid: errors ? false : true,
                errors: errors || {},
                touched: errors || {}
            }));  
            setEventSelected(!eventSelected);     
            displayMessage.error('Complete los campos');
        }else{
            onClose(formState.values.talObservacion, filaActiva);
        }
    }

    return (
        <Modal
            onClose={() => {
                onClose(filaActivaObs, filaActiva);
            }}
            open={open}
        >
            <Card
                {...rest}
                className={clsx(classes.root, className)}
            >
                <form>
                    <CardContent>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                        >
                            Agregar observación
                        </Typography>
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid
                                item                                
                                xs={12}
                            >
                                <TextField
                                    error={hasError('talObservacion')}
                                    fullWidth    
                                    multiline
                                    rows={5}               
                                    helperText={hasError('talObservacion') ? formState.errors.talObservacion[0] : null}                 
                                    name="talObservacion"
                                    onChange={handleChange}                                    
                                    value={formState.values.talObservacion || ''}
                                    variant="outlined"
                                />
                            </Grid>                                                                                                                                        
                        </Grid>
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            onClick={() => {
                                onClose(filaActivaObs, filaActiva);
                            }}
                            variant="contained"
                        >
                            Cerrar
                        </Button>
                        <Button
                            className={classes.saveButton}
                            onClick={() => handleSaveObservacion()}
                            variant="contained"
                        >
                            Guardar
                        </Button>
                    </CardActions>
                </form>
            </Card>
        </Modal>
    );
};

export default ModalAddObservacion;
