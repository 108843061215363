import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Typography } from '@material-ui/core';

export default class DialogComponent extends Component {

    render() {

        return (
            <Dialog
                onClose={this.props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.props.open}
                maxWidth='lg'
                fullWidth

            >

                <DialogTitle>
                    <Grid container spacing={2}>
                        <Grid item xs className='vertical-center'>
                            <Typography variant="h4" gutterBottom>
                                CONTRATO

                            </Typography>

                        </Grid>
                        <Grid className='right-inline' item xs>

                            <IconButton aria-label="close" onClick={this.props.handleClose}  >

                                <CloseIcon fontSize='small' />

                            </IconButton>


                        </Grid>


                    </Grid>


                </DialogTitle>


                <DialogContent dividers>
                    <embed type="application/pdf" src={"data:application/pdf;base64," + this.props.detOrigen} width="100%" height="600px" />


                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.handleClose} color="primary">
                        Cerrar
            </Button>
                </DialogActions>
            </Dialog>
        );
    }
}