import { DataService } from 'providers/dataService'


export class FormularioProvider {

    constructor() {
        this.dataService = new DataService();


    }

    findForm = (liqCodigo, detTipo) => {
        
        const body = {
            'tipoConsulta': 1,
            'entidad': 'Formulario',
            'parametros': {
                'liqCodigo': liqCodigo,
                'detTipo': detTipo
            }
        }

        const response =  this.dataService.post('/consulta', body, '0101000').then( response => {
            
            return response.bodyOut.data;
         
 
        }  
            
        
        );

        return response;
    }

    createLiquidation = ( forms )=>{
        const dS = new DataService();
        console.log('Formularios service');
        console.log(forms);

        const body = {
            'tipoAccion': 1,
            'entidad': 'Formulario',
            'entidades': [forms]
        }
        console.log(body)

        const response =  dS.post('/accion', body, '0101100').then( response => {
            
            return response;

        } 
            
        
        );

        return response;
    }
    findDetalleCatalogo = ( catCodigo ) => {
        const dS = new DataService();

        const body = {
            'tipoConsulta': 1,
            'entidad': 'DetalleCatalogo',
            'parametros': {
                'catCodigo': catCodigo
    
            }
        }

        const response =  dS.post('/consulta', body, '0101000', 'james').then( response => {
            
            return response.bodyOut.data;
         
 
        } 
            
        
        );

        return response;
        
    }

    

   

}
