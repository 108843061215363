import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';
import { AppointmentProvider } from 'providers/appointment.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    }
}));

const SearchAfiliado = props => {
    const displayMessage = new DisplayMessage();
    const appoinment = new AppointmentProvider();
    const classes = useStyles();
    const [ageCodigo] = useState(atob(props.match.params.id));
    const [datosAgendamiento, setDatosAgendamiento] = useState([]);
    useEffect(() => {
        agendamiento();
    }, [ageCodigo]);

    const agendamiento = () => {
        appoinment.datosAgendamientoEspecifico(ageCodigo).then(
            data => {
                if (data.error.codigo !== '0') {
                    setDatosAgendamiento([]);
                } else {
                    setDatosAgendamiento(data.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación');
            }
        );
    };

    return (
        <Page className={classes.root} title="Editar cita">
            <Header />
            <br />
            {datosAgendamiento.length !== 0 ? (
                <Results
                    className={classes.results}
                    datos={datosAgendamiento}
                />
            ) : (
                <CircularProgress />
            )}
        </Page>
    );
};

export default SearchAfiliado;
