import { DataService } from 'providers/dataService'

export class CatalogProvider {

    constructor() {
        this.dataService = new DataService();
    }

    findCatalogByCatNemonico = catNemonico => {
        return this.genericConsult('Catalogo', {'catNemonico': catNemonico}, '0101000', 1);
    }

  findAllCatalogs = () => {
      return this.genericConsult('Catalogo', {}, '0101000', 1);
  }
  createCatalog(catalogs) {
      let body = {       
          'tipoAccion': 1,
          'entidad': 'Catalogo',
          'entidades': catalogs
      };
      let endpoint = '/accion';
      return this.dataService.post(endpoint, body, '0101100');
  }
  genericConsultMaxReg = (entitie, parameters, typeTransaction, typeConsult, maxRegistros) => {
      let body = {
          maximoRegistros: maxRegistros,
          tipoConsulta: typeConsult,
          entidad: entitie,
          parametros: parameters
      };
      let endpoint = '/consulta';
      return this.dataService.post(endpoint, body, typeTransaction).then(
          response => {
              return response.bodyOut.data;
          }
      );
  }

  genericConsult = (entitie, parameters, typeTransaction, typeConsult) => {
      let body = {       
          tipoConsulta: typeConsult,
          entidad: entitie,
          parametros: parameters
      };
      let endpoint = '/consulta';
      return this.dataService.post(endpoint, body, typeTransaction).then(
          response => {
              return response.bodyOut.data;
          }
      );
  }
}



