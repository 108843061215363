import React, { Component } from 'react';
import  {liquidationsOptions, notificationsOptions, liquidatorOptions } from "./options";
import { Page } from 'components';
import Badge from '@material-ui/core/Badge';
import { connect } from "react-redux";

import './styles.css';

import Button from '@material-ui/core/Button';



class LiquidationsViews extends Component {
    constructor(props){
        super(props);

        const {session} = props;        
        console.log("Sesion informa en notifyes ");
        console.log(session);

        this.state ={
            session: session
        }

    }
     
    render(){
        
        let Options =[];

        const { session } = this.state
        if( session.user.role ==="CONTADOR"){
                      
            Options= notificationsOptions;
           
        }
        if( session.user.role ==="AUDITOR"){
                       
            Options=liquidationsOptions;
        }
        if( session.user.role ==="LIQUIDADOR"){
                       
            Options=liquidatorOptions;
        }
         

        return(
            <Page
            title="Liquidaciones"
            className='margin-medium'
            >
                
                <div className='row'  >


                {

                    Options.map( (opt)=>
                    <div className='col' >
                        {//http://localhost:3000/contador/undefined/liquidador/pendientePagar//
                        }

                    <Button  className='button-3' variant="outlined" color="primary" href={`${opt.link}`} >
                        <div className='row'  >
                           
                            <div className='col ' >

                                <div className='left' key={opt.id}>
                                    <Badge 
                                        overlap="circle"
                                        anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                        }}
                                    >
                                        <div >
                                            <opt.icon fontSize="large"></opt.icon>
                                        </div>
                                    
                                </Badge> 
                                <p></p>
                                </div>
                            </div>
                            <div className='col left'>
                                {opt.name}
                            </div>
                            <div className='col center'>
                                <div className="red-notify " >{`${opt.Pending}`}</div>                            
                            </div>

                        </div>
                    </Button>
                    </div>

                     ) 

                }
                </div>

        </Page>
        
           
            
        );
    }


}

const mapStateToProps= state => ({ session: state.session});

//export default LiquidationsViews;

export default connect(mapStateToProps, null )(LiquidationsViews);