/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, Grid, Tooltip, Button } from '@material-ui/core';
import { SearchBasic } from 'components';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    actions: {
        padding: theme.spacing(1),
    },
}));



export default function Results() {
    const tableRef = React.useRef();
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();
    const [booleano, setBooleano] = useState(false);

    const [columns] = useState([
        { title: 'Póliza', field: 'POL_CONTRATO' },
        { title: 'Cédula', field: 'PER_IDENTIFICACION' },
        { title: 'Nombre', field: 'PER_NOMBRES' },
        { title: 'Apellidos', field: 'PER_APELLIDOS' },
        { title: 'Fecha Registro', field: 'PER_FECHA_REGISTRO' },
        { title: 'Estado póliza', field: 'POL_DESCRIPCION' },
        { title: 'Monto', field: 'PAG_MONTO' },
        { title: 'Estado de pago', field: 'DET_DESCRIPCION' },
        { title: 'Fecha de pago', field: 'PAG_FECHA_PAGO' },
        { title: 'Fecha de vencimiento pago', field: 'PAG_FECHA_VENCIMIENTO' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');
    const [total, setTotal] = useState(0);

    const loadData = (query, resolve) => {
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PAG_FECHA_VENCIMIENTO',
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }

        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }

        queries.reportePagosAfiliados(buscarTitular, orderBy.field, direction.orderDirection, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setBooleano(false)
                        setTotal(response.bodyOut.data[0].TOTAL)
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false)
                        setTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        displayMessage.error('No se encontró registros')
                    }

                },
                () => {
                    setBooleano(false);
                    setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );
    }


    const handleBuscarTitular = () => {
        if (buscarTitular === '') {
            displayMessage.error('Ingrese un dato a buscar.');
        } else {
            tableRef.current.onSearchChange(buscarTitular);
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false)
        setBuscarTitular('');
    }


    const dataReportsExport = (typeResult,) => {
        queries.dataReportsExportPagos(typeResult, buscarTitular, 'PER_IDENTIFICACION', 'asc', total, 0).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };

    const downloadFile = (data, typeFile) => {

        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].PAGOS_AFILIADOS}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    return (
        <div> <br />
            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />

            <Grid
                container
                spacing={2}
            >                
                <Grid
                    item             
                    xs={12}
                    style={{ paddingLeft: 1, textAlign: 'right', marginBottom:10}}
                >
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(2); }}
                        >

                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5,  width: 100}}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(3); }}
                        >
                            <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
                        </Button>
                    </Tooltip>

                </Grid>                
            </Grid>


            <Card>
                <MaterialTable
                    tableRef={tableRef}

                    columns={columns}
                    data={booleano === false ? (query =>
                        new Promise((resolve) => {
                            loadData(query, resolve)

                        })) : []}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            search: false,
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'


                        }
                    }}
                    options={{
                        exportButton: false,
                        search: false,
                        toolbar: false,
                        headerStyle: {
                            fontWeight: 'bold'
                        },
                        rowStyle: rowData => ({
                            backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                        }),
                    }}
                    title=""
                />
            </Card>
        </div>
    );
}