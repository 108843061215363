import { DataService } from 'providers/dataService'

export class ConsultViewProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosVista( perCodigo) {
        let body = {       
            tipoConsulta: 1,
            entidad: "VwUsuarioPersonaPolizaPlan",
            parametros: {
                perCodigo: perCodigo,					
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }


}
