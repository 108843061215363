import React from 'react';
import { 
    Grid,
    TextField,
    Card, 
    CardHeader, 
    CardContent,
    Divider,
    IconButton,
    Button,
    CardActions
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons'
import { AtentionFormProviders } from "providers/attentionForm.service";
import { DisplayMessage } from 'notifications/displayMessage';

export default class HospitalCare extends React.Component{
    constructor(props){
        super(props);
        this.state={
            buscar:'',
            buscarcie10:'',
            rucDoctor:'',
            fecha:'',
            procedimiento:'',
            diagnostico:'',
            establecimiento:'',
            copago:'',
            deducible:'',
            monto:'',
            proCodigo:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.enviaDatos = this.enviaDatos.bind(this);
    }

    handleClose=()=>{
        this.props.close(false);
    }

    handleChange=(e)=>{
       
        this.setState({
            [e.target.name]:e.target.value
        });
        const arregloDatos={
            [e.target.name]:e.target.value
            
        }

        this.enviaDatos(arregloDatos)
    }

    enviaDatos(arregloDatos){
        this.props.datos(arregloDatos,this.state.preCodigo,this.state.establecimiento,this.state.diagnostico)
    }

    handleChangeBuscar=(e)=>{
        this.setState({
            buscar:e.target.value
        })
    }

    handleClickRuc=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscar === ''){
            displayMessage.error("Ingrese el RUC para buscar")
        }else{
    
        atention.consultarPresador(this.state.buscar)
          .then(
            user => {  
                if(user.bodyOut.data.length === 0){
                    displayMessage.error('RUC no encontrado.');
                }else{
                    displayMessage.success("RUC encontrado")

                    this.setState({
                        rucDoctor:user.bodyOut.data[0].preRuc,
                        establecimiento:user.bodyOut.data[0].preNombre,
                        preCodigo:user.bodyOut.data[0].preCodigo
                    }) 
                }      
           
             
            },
            () => {          
              displayMessage.error('Error de comunicación.');
              
            }
          );
        }
    }

    handleChangeBuscarCie10=(e)=>{
        this.setState({
            buscarcie10:e.target.value
        })
    }
    
    handleClickCie10=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscarcie10 === ''){
            displayMessage.error("Ingrese CIE10")
        }else{
    
        atention.comboEnfermedades(this.state.buscarcie10)
          .then(
            user => {  
                if(user.bodyOut.data.length === 0){
                    displayMessage.error('CIE10 no encontrado.');
                }else{
                    displayMessage.success("CIE10 encontrado")

                    this.setState({
                        diagnostico:user.bodyOut.data[0].detNombre
                    }) 
                }      
           
             
            },
            () => {          
              displayMessage.error('Error de comunicación.');
              
            }
          );
        }
    }


    render(){
        return(
            <Grid>
            <Card>
                <CardHeader title="Atención hospitalaria" action={<IconButton color="secondary" onClick={this.handleClose}><Cancel/></IconButton>}/>
                <Divider/>
                <CardActions>
                    <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Buscar ruc"
                            placeholder="Buscar por RUC"
                            name="buscar"
                            value={this.state.buscar}
                            onChange={this.handleChangeBuscar}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                           <Button variant="contained" color="primary" onClick={this.handleClickRuc}>
                               Buscar
                           </Button>
                        </Grid> 
                        <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Buscar CIE10"
                            placeholder="Buscar CIE10"
                            name="buscarcie10"
                            value={this.state.buscarcie10}
                            onChange={this.handleChangeBuscarCie10}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                           <Button variant="contained" color="primary" onClick={this.handleClickCie10}>
                               Buscar
                           </Button>
                        </Grid>  
                    </CardActions>
                <CardContent>
               
                <Grid
                    container
                    spacing={4}
                    >
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Ruc Doctor"
                            name="rucDoctor"
                            value={this.state.rucDoctor}
                            onChange={this.handleChange}
                            InputProps={{
                                readOnly: true,
                              }}                            
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Fecha"
                            name="fecha"
                            value={this.state.fecha}
                            onChange={this.handleChange}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                              }}
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                            fullWidth
                            multiline
                            label="Procedimiento quirúrgico realizado"
                            name="procedimiento"
                            value={this.state.procedimiento}
                            onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                            fullWidth
                            multiline
                            label="Diagnositco"
                            name="diagnostico"
                            value={this.state.diagnostico}
                            onChange={this.handleChange}
                            InputProps={{
                                readOnly: true,
                              }} 
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Establecimiento"
                            name="establecimiento"
                            value={this.state.establecimiento}
                            onChange={this.handleChange}
                            InputProps={{
                                readOnly: true,
                              }} 
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Copago"
                            name="copago"
                            value={this.state.copago}
                            onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Deducible"
                            name="deducible"
                            value={this.state.deducible}
                            onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid
                        item
                        md={3}
                        sm={6}
                        xs={12}
                        >
                            <TextField
                            fullWidth
                            label="Monto utilizado"
                            name="monto"
                            value={this.state.monto}
                            onChange={this.handleChange}
                            />
                        </Grid>
                    </Grid>
            </CardContent>
            </Card><br/>
            </Grid>
        );
    }
}