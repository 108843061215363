import React from 'react';
import { 
    Grid,
    TextField,
    Card, 
    CardHeader, 
    CardContent,
    Divider,
    IconButton,
    CardActions,
    Button
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons'
import { AtentionFormProviders } from 'providers/attentionForm.service';
import { DisplayMessage } from 'notifications/displayMessage';


export default class Medicines extends React.Component{
    constructor(props){
        super(props);
        this.state={
            preCodico:'',
            buscar:'',
            rucDoctor:'',
            fecha:'',
            establecimiento:'',
            doctorResponsable:'',
            copago:'',
            monto:''
        }
    }

    handleClose=()=>{
        this.props.close(false);
    }
    
    handleChange=(e)=>{
       
        this.setState({
            [e.target.name]:e.target.value
        });
        const arregloDatos={
            [e.target.name]:e.target.value
            
        }

        this.enviaDatos(arregloDatos)
    }

    enviaDatos(arregloDatos){
        this.props.datos(arregloDatos,this.state.preCodico,this.state.establecimiento)
    }

    handleChangeBuscar=(e)=>{
        this.setState({
            buscar:e.target.value
        })
    }

    handleClickRuc=()=>{
        const atention = new AtentionFormProviders();
        const displayMessage = new DisplayMessage();
        if(this.state.buscar === ''){
            displayMessage.error('Ingrese el RUC para buscar')
        }else{
    
            atention.consultarPresador(this.state.buscar)
                .then(
                    user => {  
                        if(user.bodyOut.data.length === 0){
                            displayMessage.error('RUC no encontrado.');
                        }else{
                            displayMessage.success('RUC encontrado')

                            this.setState({
                                rucDoctor:user.bodyOut.data[0].preRuc,
                                establecimiento:user.bodyOut.data[0].preNombre,
                                preCodico:user.bodyOut.data[0].preCodigo
                            }) 
                        }      
           
             
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
              
                    }
                );
        }
    }

    render(){
        return(
            <Grid>
                <Card>
                    <CardHeader
                        action={<IconButton
                            color="secondary"
                            onClick={this.handleClose}
                                ><Cancel/></IconButton>}
                        title="Medicinas"
                    />
                    <Divider/>
                    <CardActions>
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <TextField
                                fullWidth
                                label="Buscar ruc"
                                name="buscar"
                                onChange={this.handleChangeBuscar}
                                placeholder="Buscar por RUC"
                                value={this.state.buscar}                           
                            />
                        </Grid>
                        <Grid
                        //className={classes.item}
                            item
                            md={3}
                            sm={6}
                            xs={12}
                        >
                            <Button
                                color="primary"
                                onClick={this.handleClickRuc}
                                variant="contained"
                            >
                               Buscar
                            </Button>
                        </Grid>  
                    </CardActions>
                    <CardContent>
                        <Grid
                            container
                            spacing={4}
                        >
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Ruc"
                                    name="rucDoctor"
                                    onChange={this.handleChange}    
                                    value={this.state.rucDoctor}                        
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Fecha"
                                    name="fecha"
                                    onChange={this.handleChange}
                                    type="date"
                                    value={this.state.fecha}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    label="Derivado de que establecimiento"
                                    name="establecimiento"
                                    onChange={this.handleChange}
                                    value={this.state.establecimiento} 
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Doctor Responsable"
                                    name="doctorResponsable"
                                    onChange={this.handleChange}
                                    value={this.state.doctorResponsable}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Copago"
                                    name="copago"
                                    onChange={this.handleChange}
                                    value={this.state.copago}
                                />
                            </Grid>
                            <Grid
                                item
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Monto utilizado"
                                    name="monto"
                                    onChange={this.handleChange}
                                    value={this.state.monto}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card><br/>
            </Grid>
        );
    }
}