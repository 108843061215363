import React from 'react';
import { Page, GenericHeader } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Result } from './components';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    results: {
        marginTop: theme.spacing(3)
    },
    color:{
        color: 'white'
    }
}));

const CatalogDetail = () => {
    const classes = useStyles();

    return (  
        <Page 
            title = 'Catálogo'>
            <div className={classes.root}>

                <GenericHeader title='Catálogos' />
                <br/>
                <Result/>
                <br/>
            </div>
        </Page>
    );
}
 
 
export default CatalogDetail;