import { DataService } from 'providers/dataService'


export class StripeServicesProvider {

    constructor() {
        this.dataService = new DataService();

    }

    createPay = Pays => {
        
        const body = {
            'tipoAccion': 1,
            'entidad': 'Persona',
            'entidades': Pays
        }
        
        const resp = this.dataService.post('/accion',body,'0101150').then(
            response => {
                return response;
            }
        )

        return resp;

    }

}