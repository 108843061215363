import React from 'react';
import { Form } from './Components';
const CatalogAdd = (props) => {
  
  
    return (
        <Form close={props.close}
            open={props.open} setCatalog={props.setCatalog} getAllCatalogs={props.getAllCatalogs} setStateSelect={props.setStateSelect} stateSelect={props.stateSelect} />
    
    );
}

export default CatalogAdd