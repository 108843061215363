import { DataService } from 'providers/dataService'

export class ReportLenderProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosPrestadores(  ) {
        let body = {  
            tipoConsulta: 1,
            entidad: "VwPeliqpre",
            parametros: {
                						
            }
        };
        let endpoint = "/consulta";
        return this.dataService.post(endpoint, body, "0101000");
    }
}