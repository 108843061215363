import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Button, Card, Grid, Tooltip } from '@material-ui/core';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { GraficProvider } from 'providers/graficData.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { SearchBasic } from 'components';

export default function SearchAffiliate(props) {
    const { titulo } = props;
    const graficProvider = new GraficProvider();
    const displayMessage = new DisplayMessage();
    const tableRef = React.useRef();

    const [columns] = useState([
        { title: 'Cédula', field: 'PER_IDENTIFICACION' },
        { title: 'Nombre', field: 'PER_NOMBRES' },
        { title: 'Apellido', field: 'PER_APELLIDOS' },
        { title: 'Plan', field: 'PLA_NOMBRE' },
        { title: 'Monto Cobertura', field: 'PEP_MONTO_COBERTURA' }
    ]);
    const [booleano, setBooleano] = useState(false);
    const [buscarTitular, setBuscarTitular] = useState('');
    const [total, setTotal] = useState(0);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [titulo])


    const cambio = () => {
        if (total > 0) {
            tableRef.current.onQueryChange();
            setBooleano(false)
        }
    }

    const loadData = (query, resolve) => {
        let t = titulo

        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PER_APELLIDOS',
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }

        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }
        graficProvider.consultaTipoPlan(t, buscarTitular, orderBy.field, direction.orderDirection, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setBooleano(false)
                        setTotal(response.bodyOut.data[0].TOTAL)
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false)
                        setTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                    }

                },
                () => {
                    setBooleano(false);
                    setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );

    }


    const handleBuscarTitular = () => {
        if (buscarTitular === '') {
            displayMessage.error('Ingrese un dato a buscar.');
        } else {
            tableRef.current.onSearchChange(buscarTitular);
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false)
        setBuscarTitular('');
    }


    const dataReportsExport = (typeResult,) => {
        graficProvider.dataReportsExport(typeResult, titulo, buscarTitular, 'PER_APELLIDOS', 'asc', total, 0).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };

    const downloadFile = (data, typeFile) => {

        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].REPORTE_GRAFICO}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }


    return (
        <div>

            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />

            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: 1, textAlign: 'right', marginBottom: 10 }}
                >
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{ borderRadius: 5, marginRight: 10, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(2); }}
                        >

                            <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => { dataReportsExport(3); }}
                        >
                            <FaFileExcel size={20} style={{ marginRight: 10 }} />EXCEL
        </Button>
                    </Tooltip>

                </Grid>
            </Grid>

            <Card>

                <MaterialTable
                    tableRef={tableRef}
                    /* actions={[
                        {
                            icon: () => <FaFileCsv />,
                            tooltip: 'Exportar a CSV',
                            isFreeAction: true,
                            onClick: () => { dataReportsExport(2); }
                        },
                        {
                            icon: () => <FaFileExcel />,
                            tooltip: 'Exportar a XLS',
                            isFreeAction: true,
                            onClick: () => { dataReportsExport(3); }
                        }
                    ]} */
                    columns={columns}
                    data={booleano === false ? (query =>
                        new Promise((resolve) => {
                            loadData(query, resolve)

                        })) : []}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            searchPlaceholder: 'Buscar',
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'


                        }
                    }}
                    options={{
                        search: false,
                        exportButton: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        emptyRowsWhenPaging: false,
                        rowStyle: rowData => ({
                            backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                        }),
                    }}
                    title={titulo}
                />
            </Card>
        </div>
    );
}

