import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';


export default class HelpContent extends Component {


    render() {

        return (
            //        const { elements, titles, formTitle, formIcon, elementIcon} = this.props

            <div>
                <Typography>
                    Click en
                </Typography>
                <img
                    style={{ height: "200" }}
                    src="/images/help/buttonDescargar.png"
                    alt='boton descargar'
                />
                <br />
                <br />
                <Typography>
                    Y revise la parte inferior izquierda de su navegador web

                </Typography>
                <img
                    style={{ height: "200" }}
                    src="/images/help/mostrarDescarga.png"
                    alt='mostrar descarga'

                />

            </div>

        );
    }


}