import { DataService } from 'providers/dataService'

export class AtentionFormProviders {

    constructor() {
        this.dataService = new DataService();
    }

    datosTitular( cedula) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'VwPolPerBan',
            parametros: {
                perIdentificacion:cedula
                
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosTipoAtencion( ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo:24
                
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    consultarPresador( ruc,tipoPrestador,preNombre ) {
        let body = {    
            maximoRegistros:5,   
            tipoConsulta: 1,
            entidad: 'Prestador',
            parametros: {
                preRuc:ruc+'%',
                detTipo:tipoPrestador,
                preNombre:preNombre+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    consultarPresadorGuardado(codigo ) {
        let body = {    
            maximoRegistros:5,   
            tipoConsulta: 1,
            entidad: 'Prestador',
            parametros: {
                preCodigo:codigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    comboEnfermedades( detNemonico) {
        let body = {
            maximoRegistros:15000,
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: 6,
                detNemonico: detNemonico
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    comboEspecialidad( ) {
        let body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo: 39
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    crearLiquidacion1(files, polCodigo) {

        const filesNames = [];

        files.map(file => {
            filesNames.push({docNombre: file.name});
            return 0;
        });        

        let body = {        
            tipoConsulta: 1,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: {
                    detTipo: 23520,
                    polCodigo: polCodigo,
                    documentos: []
                }                
            }
        };        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101111', files);
    }   

    crearDetalleLiquidacion( entidad ) {
        let body = {       
            tipoAccion: 1,
            entidad: 'DetalleLiquidacion',
            entidades: entidad
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

}