/*eslint-disable no-eval */ 

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Button } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Divider,
    CardContent,
    CardActions,
    CircularProgress
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import './styles.css';



const useStyles = makeStyles(theme => ({
    root: {
    },
    content: {
        padding: 0
    },

    inner: {
        minWidth: 700
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },

}));


const TableForm = props => {
    const { dataOfObject, actions ,idOfObject,className,dataSelect,columna, handleOpenRemove, handleOpenUpdate, data,dataPrevia, ...rest } = props;
    
    const classes = useStyles();

    return (

        <div
            {...rest}
            className={clsx(classes.root, className)}
        >      
            <Card>
                <Divider />
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columna.map((nombre,index)=>
              
                                            <TableCell key={index}>
                                                {nombre}
                                            </TableCell>
              
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>            
                                    {data ? data.map((datos,index) =>
                                   datos.talEstado === 1 &&
                                        <TableRow key={index}>
                                            <TableCell>
                                                {datos.talDescripcion}
                                            </TableCell>
                                            <TableCell>
                                                {datos.talCantidad}
                                            </TableCell>
                                            <TableCell style={{textAlign: 'right'}}>
                                                {parseFloat(datos.talValorRegistrado).toFixed(2)}
                                            </TableCell>
                                            {
            
                                                <TableCell>                  
                                                    <div className="margin-right">
                                                        { datos ?  <Button
                                                            color="secondary"
                                                            onClick={()=>handleOpenRemove(datos)}
                                                            variant="contained"
                                                                   >
                      Eliminar
                                                        </Button>: 
                    
                                                            <CircularProgress color="secondary" />

                                                        }
                   
                                                    </div>

                                                </TableCell>
                                            }
              
                                        </TableRow>
                                      

                                    ):''
                                    }
                                </TableBody>
                            </Table>
                        </div>
        

                    </PerfectScrollbar>
                </CardContent>



                <CardActions className={classes.actions}>
                    {/*  <TablePagination
           component="div"
           count={data.length}
           onChangePage={handleChangePage}
           onChangeRowsPerPage={handleChangeRowsPerPage}
           page={page}
           rowsPerPage={rowsPerPage}
           rowsPerPageOptions={[5, 10, 25]}
         /> */}
                </CardActions>
            </Card>
            {//<EditModal dataSelect={dataSelect} element={selectedElement}></EditModal>
            }
                          

       
        </div>
    );
};

TableForm.propTypes = {
    className: PropTypes.string
};

export default TableForm;