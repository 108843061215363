/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    Tooltip,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import MaterialTable from 'material-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link as RouterLink } from 'react-router-dom';
import { ReportsProvider } from 'providers/reports.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
//import { SearchBarCustom } from 'components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Chat from '@material-ui/icons/Chat';
import { LiquidationProvider } from 'providers/liquidation.service';
import { useSelector } from 'react-redux';
import { SearchBasic } from 'components';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    root: {},
    content: {
        padding: 0
    },
    inner: {
        minWidth: 700
    },
    nameCell: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 42,
        width: 42,
        marginRight: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(1),
        justifyContent: 'flex-end'
    },
    getAppIcon: {
        marginRight: theme.spacing(1)
    }
}));

const columsReport = [
    { title: 'Código', field: 'LIQ_NEMONICO', width: 100 },
    { title: 'Apellidos', field: 'PER_APELLIDOS' },
    { title: 'Nombres', field: 'PER_NOMBRES' },
    { title: 'Identificación', field: 'PER_IDENTIFICACION', width: 100 },
    { title: 'Estado', field: 'DET_NOMBRE', width: 100 },
    { title: 'Preexistencia', field: 'POL_PREEXISTENCIAS', width: 50 },
    { title: 'Fecha de registro', field: 'LIQ_FECHA_REGISTRO', width: 200 },
    { title: 'Fecha último mensaje', field: 'ESL_FECHA_INICIO', width: 200 }
];

const columsNormal = [
    { title: 'Código', field: 'LIQ_NEMONICO', width: 100, defaultSort: 'asc' },
    { title: 'Apellidos', field: 'PER_APELLIDOS' },
    { title: 'Nombres', field: 'PER_NOMBRES' },
    { title: 'Identificación', field: 'PER_IDENTIFICACION', width: 100 },
    { title: 'Fecha de registro', field: 'LIQ_FECHA_REGISTRO', width: 200 },
    { title: 'Fecha modificación', field: 'ESL_FECHA_INICIO', width: 200 }
];

const columsNormalTotal = [
    { title: 'Código', field: 'LIQ_NEMONICO', width: 100, defaultSort: 'asc' },
    { title: 'Apellidos', field: 'PER_APELLIDOS' },
    { title: 'Nombres', field: 'PER_NOMBRES' },
    { title: 'Identificación', field: 'PER_IDENTIFICACION', width: 100 },
    { title: 'Fecha de registro', field: 'LIQ_FECHA_REGISTRO', width: 200 },
    { title: 'Fecha modificación', field: 'ESL_FECHA_INICIO', width: 200 },
    { title: 'Total deducible', field: 'LIQ_DEDUCIBLE' },
    { title: 'Total copago', field: 'LIQ_COPAGO' },
    { title: 'Total a pagar', field: 'LIQ_TOTAL_LIQUIDADO' }
];

const Results = props => {
    const tableRef = React.useRef();
    const { onCount, className, params, ...rest } = props;
    const [valueSearch, setvalueSearch] = useState('');
    const classes = useStyles();
    const displayMessage = new DisplayMessage();
    const reportsProvider = new ReportsProvider();
    const [booleano, setBooleano] = useState(false);
    const liquidationProvider = new LiquidationProvider();
    const session = useSelector(state => state.session);
    const [total, setTotal] = useState(0);
    const [empresa, setEmpresa] = useState([]);
    const [empCodigo, setEmpCodigo] = useState('2');

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [params.status]);

    useEffect(() => {
        reportsProvider.datosEmpresa().then(
            response => {
                if (response.error.codigo === '0') {
                    setEmpresa(response.bodyOut.data);
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
            }
        );
    }, []);

    const loadData = (query, resolve) => {
        let filters = [];
        let entidad = 'LIQPAG';
        if (params.tipo === 'reporte') {
            filters = [
                empCodigo,
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%'
            ];
            entidad = 'LIQPAT';
        } else {
            filters = [
                params.status,
                empCodigo,
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%'
            ];
        }

        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'LIQ_NEMONICO',
            orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };

        reportsProvider
            .paginacion2(
                filters,
                orderBy,
                query.pageSize * query.page,
                query.pageSize,
                entidad
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        setBooleano(false);
                        onCount(response.bodyOut.data[0].TOTAL);
                        setTotal(response.bodyOut.data[0].TOTAL);
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });
                    } else {
                        setBooleano(false);
                        onCount(0);
                        setTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                    }
                },
                () => {
                    setBooleano(false);
                    onCount(0);
                    setTotal(0);
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const handleSearch = () => {
        if (valueSearch !== '') {
            tableRef.current.onSearchChange(valueSearch);
        } else {
            displayMessage.error('Ingrese el texto para buscar');
        }
    };

    const handleClean = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        setvalueSearch('');
        setEmpCodigo('2');
    };

    const getColums = tipo => {
        if (tipo === 'digitalizar' || tipo === 'auditoria') {
            return columsNormal;
        } else if (
            tipo === 'verificar' ||
            tipo === 'porpagar' ||
            tipo === 'pagadas'
        ) {
            return columsNormalTotal;
        } else if (tipo === 'reporte') {
            return columsReport;
        }
    };

    const handlePay = liqCodigo => {
        liquidationProvider
            .requestMove(liqCodigo, 1, session.user.usuCodigo)
            .then(
                response2 => {
                    if (response2.error.codigo === '0') {
                        displayMessage.success(
                            'Datos actualizados correctamente.'
                        );
                        handleClean();
                    }
                },
                () => {
                    this.displayMessage.error('Error de comunicación.');
                }
            );
    };

    const handleInputChange = event => {
        setvalueSearch(event.target.value);
    };

    const dataReportsExport = typeResult => {
        let filters = [];
        let entidad = 'LIQPAG';
        if (params.tipo === 'reporte') {
            filters = [
                empCodigo,
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%'
            ];
            entidad = 'LIQPAT';
        } else {
            filters = [
                params.status,
                empCodigo,
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%',
                '%' + valueSearch + '%'
            ];
        }

        const orderBy = {
            field: 'LIQ_NEMONICO',
            orderDirection: 'asc'
        };

        reportsProvider
            .dataReportsExportLiquidacion(
                typeResult,
                filters,
                orderBy,
                0,
                total,
                entidad
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        downloadFile(response.bodyOut.data, typeResult);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        if (params.tipo === 'reporte') {
            var path = `data:application/octet-stream;base64,${data[0].LIQUIDACION_PAGINADA_TOTAL}`;
        } else {
            var path = `data:application/octet-stream;base64,${data[0].LIQUIDACION_PAGINADA}`;
        }
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChangeEmpresa = e => {
        tableRef.current.onQueryChange();
        setEmpCodigo(e.target.value);
    };

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            {/* <SearchBarCustom
                onClean={handleClean}
                onSearch={handleSearch}
            /> */}

            <Grid container spacing={2}>
                <Grid item md={4} sm={12} xs={12} style={{ marginRight: 100 }}>
                    <SearchBasic
                        cleanSearchBar={handleClean}
                        complementaryMessage="Buscar"
                        handleInputChange={handleInputChange}
                        handleSearch={handleSearch}
                        searchBar={valueSearch}
                    />
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <FormControl
                        style={{
                            width: '100%'
                        }}
                        variant="outlined"
                        fullWidth>
                        <InputLabel htmlFor="age-native-simple" shrink>
                            Empresa
                        </InputLabel>
                        <Select
                            displayEmpty
                            name="empresa"
                            onChange={e => handleChangeEmpresa(e)}
                            value={empCodigo}>
                            <MenuItem value={'%'}>
                                <em>Seleccione...</em>
                            </MenuItem>
                            {empresa.map(item => (
                                <MenuItem
                                    key={'empresa' + item.empCodigo}
                                    value={item.empCodigo}>
                                    {item.empRazonSocial}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {params.tipo === 'digitalizar' && (
                    <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
                        <Button
                            color="primary"
                            component={RouterLink}
                            to={'/liquidador/nueva-liquidacion'}
                            variant="contained">
                            <AddCircleOutlineIcon
                                className={classes.getAppIcon}
                            />
                            Nueva liquidación
                        </Button>
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: 1,
                        textAlign: 'right',
                        marginBottom: 10
                    }}>
                    <Tooltip title="Exportar a CSV">
                        <Button
                            style={{
                                borderRadius: 5,
                                marginRight: 10,
                                width: 100
                            }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(2);
                            }}>
                            <FaFileCsv size={20} style={{ marginRight: 10 }} />{' '}
                            CSV
                        </Button>
                    </Tooltip>
                    <Tooltip title="Exportar a EXCEL">
                        <Button
                            style={{ borderRadius: 5, width: 100 }}
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                dataReportsExport(3);
                            }}>
                            <FaFileExcel
                                size={20}
                                style={{ marginRight: 10 }}
                            />
                            EXCEL
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Card>
                <CardHeader title="Liquidaciones" />
                <Divider />
                <CardContent className={classes.content}>
                    <PerfectScrollbar>
                        <div className={classes.inner}>
                            <MaterialTable
                                actions={[
                                    {
                                        icon: 'save',
                                        tooltip: 'Save User',
                                        onClick: () => {}
                                    }
                                ]}
                                columns={getColums(params.tipo)}
                                components={{
                                    Action: props => (
                                        <>
                                            {params.tipo === 'digitalizar' && (
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    style={{
                                                        textTransform: 'none'
                                                    }}
                                                    to={`/liquidador/digitalizar/${btoa(
                                                        props.data.LIQ_CODIGO
                                                    )}`}
                                                    variant="contained">
                                                    <VisibilityIcon
                                                        className={
                                                            classes.getAppIcon
                                                        }
                                                    />
                                                    Ver
                                                </Button>
                                            )}
                                            {params.tipo === 'verificar' && (
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    style={{
                                                        textTransform: 'none',
                                                        marginRight: 10
                                                    }}
                                                    to={`/liquidador/liquidadiones/verificacion/${btoa(
                                                        props.data.LIQ_CODIGO
                                                    )}`}
                                                    variant="contained">
                                                    <VisibilityIcon
                                                        className={
                                                            classes.getAppIcon
                                                        }
                                                    />
                                                    Ver
                                                </Button>
                                            )}
                                            {params.tipo === 'reporte' && (
                                                <>
                                                    <Button
                                                        color="primary"
                                                        component={RouterLink}
                                                        size="small"
                                                        style={{
                                                            textTransform:
                                                                'none',
                                                            marginRight: 10
                                                        }}
                                                        to={`/liquidador/digitalizarVer/${btoa(
                                                            props.data
                                                                .LIQ_CODIGO
                                                        )}`}
                                                        variant="contained">
                                                        <VisibilityIcon
                                                            className={
                                                                classes.getAppIcon
                                                            }
                                                        />
                                                        Ver
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        component={RouterLink}
                                                        size="small"
                                                        style={{
                                                            textTransform:
                                                                'none'
                                                        }}
                                                        to={`/liquidador/historial/${btoa(
                                                            props.data
                                                                .LIQ_CODIGO
                                                        )}`}
                                                        variant="contained">
                                                        <Chat
                                                            className={
                                                                classes.getAppIcon
                                                            }
                                                        />
                                                        Mensajes
                                                    </Button>
                                                </>
                                            )}
                                            {params.tipo === 'auditoria' && (
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    style={{
                                                        textTransform: 'none'
                                                    }}
                                                    to={`/auditor/auditoria/${btoa(
                                                        props.data.LIQ_CODIGO
                                                    )}`}
                                                    variant="contained">
                                                    <VisibilityIcon
                                                        className={
                                                            classes.getAppIcon
                                                        }
                                                    />
                                                    Ver
                                                </Button>
                                            )}
                                            {(params.tipo === 'porpagar' ||
                                                params.tipo === 'pagadas') && (
                                                <Button
                                                    color="primary"
                                                    component={RouterLink}
                                                    size="small"
                                                    style={{
                                                        textTransform: 'none',
                                                        marginRight: 10
                                                    }}
                                                    to={`/contador/liquidadiones/${
                                                        params.tipo
                                                    }/${btoa(
                                                        props.data.LIQ_CODIGO
                                                    )}`}
                                                    variant="contained">
                                                    <VisibilityIcon
                                                        className={
                                                            classes.getAppIcon
                                                        }
                                                    />
                                                    Ver
                                                </Button>
                                            )}
                                        </>
                                    )
                                }}
                                data={
                                    booleano === false
                                        ? query =>
                                              new Promise(resolve => {
                                                  loadData(query, resolve);
                                              })
                                        : []
                                }
                                localization={{
                                    header: {
                                        actions: 'Acción'
                                    },
                                    body: {
                                        emptyDataSourceMessage:
                                            'No hay datos que mostrar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'Filas',
                                        labelDisplayedRows:
                                            '{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'
                                    },
                                    toolbar: {
                                        searchPlaceholder: 'Buscar...'
                                    }
                                }}
                                options={{
                                    actionsColumnIndex: -1,
                                    actionsCellStyle: {
                                        width: 100,
                                        textAlign: 'center'
                                    },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    search: false,
                                    toolbar: false,
                                    pageSize: 10,
                                    showTitle: false,
                                    rowStyle: rowData => ({
                                        backgroundColor:
                                            rowData.tableData.id % 2 === 0
                                                ? '#FFF'
                                                : '#EEE'
                                    })
                                }}
                                tableRef={tableRef}
                            />
                        </div>
                    </PerfectScrollbar>
                </CardContent>
            </Card>
        </div>
    );
};

Results.propTypes = {
    className: PropTypes.string
};

export default Results;
