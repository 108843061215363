import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider  } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card, CardActions, Grid, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));



export default function SearchAffiliate() {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Cédula', field: 'perIdentificacion' },
        { title: 'Nombre', field: 'usuNombre' },
        { title: 'Mensaje', field: 'eslMensaje' },
        { title: 'Liquidacion', field: 'liqNemonico' }
    ]);

    const [buscarCedula, setBuscarCedula] = useState('')
    const [buscarTitular, setBuscarTitular] = useState('');

    const handleBuscarTitular= ()=>{
        setData(0);
        if(buscarTitular === '' && buscarCedula === ''){
            displayMessage.error('Ingrese un campo a buscar.');
        }else{
            queries.reportesLiqAud(buscarCedula,buscarTitular)
                .then(
                    user => {
                        if(user.bodyOut.data.length !== 0){

                       
                            setData(user.bodyOut.data);
                        }else{
                            displayMessage.error('Sin resultados')
                            setData([])
                        }
          
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
          
                    }
                );
        }
    }
    const handleChange = (e)=>{
        setBuscarTitular(e.target.value);
    }

    const handleChangeCedula = (e)=>{
        setBuscarCedula(e.target.value);
    }

    const handleLimpiar= ()=>{
        setBuscarTitular('');
        setBuscarCedula('');
        setData([]);
    }
    return (
        <div>
            <Card>
                <CardActions>
                    <div className={classes.root}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                //className={classes.item}
                                item
                                md={3}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    fullWidth
                                    name="buscarCedula"
                                    onChange={handleChangeCedula}
                                    placeholder="Buscar por cédula"
                                    value={buscarCedula}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                //className={classes.item}
                                item
                                md={3}
                                sm={4}
                                xs={12}
                            >
            
                                <TextField
                                    fullWidth
                                    name="buscarTitular"
                                    onChange={handleChange}
                                    placeholder="Buscar por liquidación"
                                    value={buscarTitular}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleBuscarTitular}
                                    size="small"
                                    variant="contained"
                                >Buscar</Button>
                
                  
                            </Grid>
                            <Grid
                 
                                item
                                sm={2}
                                xs={6}
                            >
               
                                <Button
                                    color="primary"
                                    onClick={handleLimpiar}
                                    size="small"
                                    variant="contained"
                                >Limpiar</Button>
                
                  
                            </Grid>
                        </Grid>
                    </div>
          
                </CardActions>
            </Card><br/>
            {data ?
                data.length ?
                    <Card>
                        <MaterialTable
                            columns={columns}
                            data={data}
                            localization={{
                                body:{
                                    emptyDataSourceMessage:'No hay datos que mostrar'
                                },
                                toolbar: {
                                    search: false,
                                    exportName: 'Exportar a CSV',
                                    exportAriaLabel: 'Exportar',
                                    exportTitle: 'Exportar'
                                },
                                pagination:{
                                    labelRowsSelect:'Filas',
                                    labelDisplayedRows:'{from}-{to} de {count}',
                                    labelRowsPerPage: 'filas'
          

                                }
                            }}
                            options={{exportButton: false, search:false, toolbar: false}}
                            title="Reporte"
                        />
                    </Card>: ''
                :<CircularProgress/>}
        </div>
    );
}