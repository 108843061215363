import { DataService } from 'providers/dataService'

export class DocumentDigitalizeProvider {

    constructor() {
        this.dataService = new DataService();
    }

    datosPresta( plaCodigo,modalidad ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'CoberturasPlan',
            parametros: {
                plaCodigo:plaCodigo,
                detTipoModalidad:modalidad
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosPolLiquidacion( liqCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                liqCodigo:liqCodigo,
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    ingresoDetalleLiquidacion(tipoAccion, liquidacion ) {
        let body = {       
            tipoAccion: tipoAccion,
            entidad: 'DetalleLiquidacion',
            entidades: liquidacion
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101102');
    }

    ingresoTalonario(tipoAccion, entidad ) {
        let body = {       
            tipoAccion: tipoAccion,
            entidad: 'TarifaLiquidacion',
            entidades: entidad
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    updateLiquidacion(liquidacion) {
        let body = {       
            tipoAccion: 2,
            entidad: 'Liquidacion',
            entidades: liquidacion
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    datosCoberturaPlan( plaCodigo,detTipo,detTipoModalidad ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'CoberturasPlan',
            parametros: {
                plaCodigo:plaCodigo,
                detTipo:detTipo,
                detTipoModalidad:detTipoModalidad

            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosFormularioReembolso( liqCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'Formulario',
            parametros: {
                liqCodigo:liqCodigo

            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    ingresoPrestador( prestador ) {
        let body = {       
            tipoAccion: 1,
            entidad: 'Prestador',
            entidades: prestador
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    ingresoPrestadorAdmin( tipo ,prestador ) {
        let body = {      
            tipoAccion: tipo,
            entidad: 'Prestador',
            entidades: prestador
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    ingresoTarifario( tarifario ) {
        let body = {       
            tipoAccion: 1,
            entidad: 'Tarifario',
            entidades: [tarifario]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    datosCiudades(  ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catCodigo:35

            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    datosCiudadesBusqueda(search) {
        let body = {
            tipoConsulta: 1,
            entidad: 'Localizacion',
            maximoRegistros: 10,
            parametros: {
                
                locNombre: `%${search}%`
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    requestUpdate(files, tipo, liqCodigo,polCodigo, estados) {
       
        const filesNames = [];
        
        files.map(file => {
            filesNames.push({docNombre: file.name});
            return 0;
        });        

        const liquidation = files.length>0? {
            liqCodigo: liqCodigo,
            detTipo: tipo,
            polCodigo: polCodigo,
            estados: estados,
            documentos: filesNames
        } : {
            liqCodigo: liqCodigo,
            estados: estados,
        }; 
        let body = {        
            tipoConsulta: 1,
            entidad: 'Liquidacion',
             
            parametros: {  
                Liquidacion: liquidation             
            }
        };   
        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', files);
    
    }   


    

}