import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { ReportLenderProvider  } from "providers/reportsLender.service";
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card } from '@material-ui/core';

export default function SearchAffiliate() {

    const [data, setData] = useState(0);

    useEffect(() => {
        const report = new ReportLenderProvider();
        const displayMessage = new DisplayMessage();
    
        report.datosPrestadores()
            .then(
                user => {
                    setData(user.bodyOut.data);
          
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
          
                }
            );
    }, []);

    const [columns] = useState([
        { title: 'Ruc', field: 'preRuc' },
        { title: 'Establecimiento', field: 'preNombre' },
        { title: 'Tipo Atención', field: 'delDescripcion'},
        { title: 'Medico', field: 'delMedico' },
        { title: 'Monto utilizado', field: 'delValor' },
        { title: 'Nombres Paciente', field: 'perNombres' },
        { title: 'Apellidos Paciente', field: 'perApellidos' },
        { title: 'Cédula', field: 'perIdentificacion' }
    ]);

    return (
        <div>
            {data ?
                <Card>
                    <MaterialTable
                        title="Reporte"
                        columns={columns}
                        data={data}
                        //options={{exportButton: true}}
                        localization={{
                            body:{
                                emptyDataSourceMessage:'No hay datos que mostrar'
                            },
                            toolbar: {
                                searchPlaceholder: 'Buscar',
                                exportName: 'Exportar a CSV',
                                exportAriaLabel: 'Exportar',
                                exportTitle: 'Exportar'
                            },
                            pagination:{
                                labelRowsSelect:'Filas',
                                labelDisplayedRows:'{from}-{to} de {count}',
                                labelRowsPerPage: 'filas'
          

                            }
                        }}
                    />
                </Card>
                :<CircularProgress/>}
        </div>
    );
}

