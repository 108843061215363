/* eslint no-eval: 0 */
import React, { Component } from 'react';
import { Page } from 'components';
import { GenericBasicFomDetaill } from 'components';
//import { DetalleCatalogoProvider } from 'providers/detalleCatalogo.service';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ActualDate } from 'services/ActualDate';
import { PersonalProvider } from 'providers/persona.service';
import './styles.css';

export default class PharmacyView extends Component {
    data = ['PARACETAMOL', 10, 20, '$20'];
    columna = ['MEDICAMENTO', 'CANTIDAD', 'UNIDAD', 'VALOR']

    constructor(props) {
        super(props);
        //this.decryptedData = typeof (props.match.params.requerimiento) === 'undefined' ? 0 : atob(props.match.params.requerimiento);

        this.state = {
            //idRequirement: this.decryptedData,
            showMedicines: false,
            AllMedicines: null,
            AddedMedicines: [],
            name: '',
            ruc: '',
            totalValue: 0,
            date: ActualDate(),
            toSave: [],
            liqCodigo: this.decryptedData,
            disabled: false,
            delCodigo:0,
            errorText: {
                name: '',
                ruc: '',
                date: '',
                totalValue: ''
            },
            touched: {
                name: false,
                ruc: false,
                date: false,
                totalValue: false
            },
            errors: {

                required: {
                    name: false,
                    ruc: false,
                    date: false,
                    totalValue: false
                },
                valid: {
                    name: true,
                    ruc: true,
                    date: true,
                    totalValue: true
                }
            },




        }




        this.pP = new PersonalProvider();


        this.handleInputChange = this.handleInputChange.bind(this);


    }

    hasError = (field) => {
        return (
            (this.state.errors.required[field]) &&
            this.state.touched[field]
        );
    }

    componentDidMount = () => {
        /*  this.findAllMedicines();
        this.getUserRequirement(); */
        this.transformMedicine(this.props.dataTalonario)
    }

    transformMedicine = (data) => {
        let total =0;
        let codigo = 0
        data.map ((item) => {{
            if( item.talEstado === 1) {
                codigo= item.delCodigo;
                total = item.talValorRegistrado+total;
                
            }else{
                codigo=item.delCodigo
            }
            return 0; 
        }
        })
        console.log(codigo)
        this.setState({AddedMedicines: data,
            totalValue:total,
        delCodigo:codigo})
    }

    AddMedicine = (medicine) => {
        
        const salvedArray = this.state.AddedMedicines;
        salvedArray.push(medicine);
        const total = 0;
        salvedArray.map((med) => {
            this.total = total + med.talValorRegistrado;

            return null
        })
        this.setState({
            AddedMedicines: salvedArray,
            totalValue: total
        });


        this.enDisButton();

    }

    handleClickOpen = () => {
        this.setState({
            showMedicines: true
        });
    }

    handleClose = () => {
        this.setState({
            showMedicines: false
        })
    }

    //17206

    isTouched = (name) => {
        this.setState({
            touched: { ...this.state.touched, [name]: true }
        });

    }

    validateNull = (element) => {

        if (element.value.length === 0 || element.value === 0) {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: 'Este Campo es requerido'
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: true },
                valid: { ...this.state.errors.valid }
            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        } else {
            const ErrorsText = {
                ...this.state.errorText, [element.name]: ''
            }
            const errors = {
                required: { ...this.state.errors.required, [element.name]: false },
                valid: { ...this.state.errors.valid }


            }
            this.setState({
                errorText: ErrorsText,
                errors: errors
            });
        }

    }
    handleInputChange = async (evt) => {
        const name = evt.target.name;
        const value = evt.target.value;

        await this.setState({
            [name]: value
        });


        await this.isTouched(name);

        await this.validateNull({ name: name, value: value });

    }

    enDisButton = () => {
        const {totalValue, AddedMedicines } = this.state;
        if (totalValue !== '' && AddedMedicines.length > 0) {
            this.setState({
                disabled: false
            })
        } else {
            this.setState({
                disabled: true

            });
        }
    }

    showEmpty = async () => {
       
        await this.isTouched('totalValue');
        await this.validateNull({ name: 'totalValue', value: this.state.totalValue })

    }


    isEmpty = async (field, state) => {
        const errors = {
            ...this.state.errors, [field]: state
        }
        await this.setState({
            errors: errors
        });

    }

    digitalize =  () => {
        let auxiliar = [];
        console.log(this.state.delCodigo)
        console.log(this.state.AddedMedicines)
        this.state.AddedMedicines.map((value)=>
        {if(value.delCodigo === undefined){
        
            { 
                const datosTalonario = {
                    delCodigo:this.state.delCodigo,
                    talEstado:1,
                    talDescripcion:value.talDescripcion,
                    talCantidad:value.talCantidad,
                    talValorRegistrado:parseFloat(value.talValorRegistrado)
                }
                auxiliar.push(datosTalonario)
                return 0;   
            }
        }else{
            const datosTalonario = {
                delCodigo:value.delCodigo,
                talCodigo:value.talCodigo,
                talEstado:value.talEstado,
                talDescripcion:value.talDescripcion,
                talCantidad:value.talCantidad,
                talValorRegistrado:parseFloat(value.talValorRegistrado)
            }
            auxiliar.push(datosTalonario)
            return 0;   
        }
        }
        )
        this.props.close && this.props.close();
       

        this.props.datosDetalles(auxiliar)

    }



    resetValues = () => {
        this.setState({
            ruc: '',
            name: '',
            date: ActualDate(),
            totalValue: 0,
            AddedMedicines: [],
            disabled: true
        });
    }
    setTotalValue = (val) => {
        this.setState({
            totalValue: val.toFixed(2)
        });
        this.props.totalValue(val.toFixed(2))
    }
    convertToMySQLDate = (dateString) => {

        //dd/MM/yyyy HH:mm:ss
        var dateCad = dateString.split('-')


        const dateConverted = `${dateCad[2]}/${dateCad[1]}/${dateCad[0]}`;

        return dateConverted;
    }

   handleClose= () => {
       this.props.close && this.props.close();
   }



   render() {




       const columna = ['DESCRIPCIÓN', 'CANTIDAD', 'VALOR TOTAL']


       const Labels = [ 'VALOR TOTAL'];
       //        const { advice, labels, btnAgregarText,data,columna } = this.props;

       const modalInputs = [ 'VALOR']

       //const {userRequirement, AllMedicines, AddedMedicines, totalValue, name, ruc, date} =this.state;
       return (
           <Dialog
               fullWidth
               maxWidth="md"
               open={this.props.open}
               scroll={'body'}
           >
               <Page
                   title="Detalle"
               >
                   

                   <GenericBasicFomDetaill
                       addData={this.AddMedicine}
                       advice="INGRESO DE DETALLES"
                       btnAgregarText="Descripción"
                       columna={columna}
                       data={this.state.AddedMedicines}
                       dataOfObject="campo.detDescripcion"
                       dataSelect={this.state.AllMedicines}
                       date={this.state.date}
                       dateError={this.state.dateError}
                       errors={this.state.errors}
                       errorsText={this.state.errorText}
                       handleInputChange={this.handleInputChange}
                       hasError={this.hasError}
                       idOfObject="campo.detCodigo"
                       labels={Labels}
                       modalInputs={modalInputs}
                       name={this.state.name}
                       nameError={this.state.nameError}
                       ruc={this.state.ruc}
                       rucError={this.state.rucError}
                       SelectDatafield="dataSelect.detDescripcion"
                       setTotalValue={this.setTotalValue}
                       totalValue={this.state.totalValue}
                       totalValueError={this.state.totalValueError}
                       valShow="element[0].detDescripcion"
                   /> 

                   <div className="right">
                       <div className="botones">
                           <Button
                               color="primary"
                               disabled={this.state.disabled}
                               onClick={this.digitalize}
                               variant="contained"
                           >Agregar</Button>
                       </div>
                       <div className="botones">
                           <Button
                               color="primary"
                               onClick={this.handleClose}
                               variant="contained"
                           >Cerrar</Button>
                       </div>
                   </div>

               </Page>
           </Dialog>

       );
   }

}
