import React, { useState,useEffect } from 'react';
import { QueriesProvider  } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { Card, Table,TableCell,TableRow, TableHead, TableBody, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function SearchAffiliate(props) {
    const [data, setData] = useState([]);
    

    useEffect(() => {     
        const queries = new QueriesProvider();
        const displayMessage = new DisplayMessage();
        queries.message(props.liqCode)
            .then(
                user => {
                    setData(user.bodyOut.data);
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
    
                }
            );
    }, [props.liqCode]);

    return (
        <div>
            <Button
                color="primary"                                            
                component={RouterLink}
                //onClick = {handleOpen(props.data.liqCodigo)}
                size="small"
                style={{textTransform: 'none'}}
                to={'/liquidador/liquidaciones/reporte'}                                                         
                variant="contained"
            >
            REGRESAR
            </Button>
            <br/><br/> 
            <Card >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                      Para:
                            </TableCell>
                            <TableCell>
                      Fecha
                            </TableCell>
                            <TableCell>
                      Mensaje
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data ? data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>
                                    { item.usuNombre}
                                </TableCell>
                                <TableCell>
                                    { item.eslFechaInicio}
                                </TableCell>
                                <TableCell>
                                    { item.eslMensaje}
                                </TableCell>
                           
                            </TableRow>
                       
                    
                        ):'No hay mensajes a mostar'}
                    </TableBody>
                </Table>
            </Card>
           
        </div>
    );
}