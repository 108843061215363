import { DataService } from 'providers/dataService';

export class StayProvider {
    constructor() {
        this.dataService = new DataService();
    }

    estancias = (estCodigo, value) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'ESTAN',
            parametros: {
                0: estCodigo,
                1: value,
                2: value,
                3: estCodigo,
                4: value,
                5: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };

    reporteUsuarios = (empCodigo, value, field, order, inicio, numero) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'PERREP',
            parametros: {
                0: empCodigo,
                1: value,
                2: value,
                3: value,
                4: empCodigo,
                5: value,
                6: value,
                7: value,
                8: field,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };

    reporteExport = (type, empCodigo, value, field, order, inicio, numero) => {
        const body = {
            tipoConsulta: type,
            entidad: 'PERREP',
            parametros: {
                0: empCodigo,
                1: value,
                2: value,
                3: value,
                4: empCodigo,
                5: value,
                6: value,
                7: value,
                8: field,
                9: order,
                10: numero,
                11: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };

    detalleCatalogo = catNemonico => {
        const body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catNemonico: catNemonico,
                detEstado: 1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    };

    guardarActualizarEstancia(tipoAccion, data) {
        let body = {
            tipoAccion: tipoAccion,
            entidad: 'Estancia',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    guardarActualizarConjunto(tipoAccion, data) {
        let body = {
            tipoAccion: tipoAccion,
            entidad: 'Empresa',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101115');
    }

    consultarConjuntos = empCodigo => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Empresa',
            parametros: {
                empCodigo: empCodigo,
                empEstado: 1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    };

    consultarPersonas = () => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Persona',
            parametros: {
                perEstado: 1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    };

    guardarActualizarPersona(tipoAccion, data) {
        let body = {
            tipoAccion: tipoAccion,
            entidad: 'Persona',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    consultarQr = uuid => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Invitacion',
            parametros: {
                uuid: uuid
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101007');
    };

    consultaEst = empCodigo => {
        const body = {
            tipoConsulta: 1,
            entidad: 'Estancia',
            parametros: {
                empCodigo: empCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    };

    guardarActualizarPersonaEstancia(tipoAccion, data) {
        let body = {
            tipoAccion: tipoAccion,
            entidad: 'PersonaEstancia',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101100');
    }

    consultaPersonaEstancia = perCodigo => {
        const body = {
            tipoConsulta: 1,
            entidad: 'PersonaEstancia',
            parametros: {
                perCodigo: perCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    };

    guardarInvitacion(data) {
        let body = {
            tipoAccion: 1,
            entidad: 'Usuario',
            entidades: [data]
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101105');
    }

    requestAddFile(files) {
        let filesNames = '';

        files.map(file => {
            //filesNames.push({docNombre: file.name});
            filesNames = file.name;
            return 0;
        });

        let body = {
            tipoAccion: 1,
            entidad: 'PersonaEstancia',
            entidades: [{}]
        };

        let endpoint = '/multipart';
        return this.dataService.postMultipart(endpoint, body, '0101009', files);
    }

    crearUsuarios(data) {
        let body = {
            tipoAccion: 1,
            entidad: 'Usuario',
            entidades: data
        };
        let endpoint = '/accion';
        return this.dataService.post(endpoint, body, '0101118');
    }

    listPerson = (value, field, order, inicio, numero) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'PERSON',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: value,
                7: value,
                8: value,
                9: value,
                10: field,
                11: order,
                12: numero,
                13: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };

    listUsuarios = (value, field, order, inicio, numero) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'USUA',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: field,
                7: order,
                8: numero,
                9: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };

    /*  INICIO DATOS VISTA FNC*/
    listFnc = (value) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'VWPROD',
            parametros: {
                0: value,
                1: value,
                2: value
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };
    /* FIN DATOS VISTA FNC */

    listEmpresa = (value, field, order, inicio, numero) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'EMPRE',
            parametros: {
                0: value,
                1: value,
                2: value,
                3: value,
                4: value,
                5: value,
                6: value,
                7: value,
                8: value,
                9: value,
                10: field,
                11: order,
                12: numero,
                13: inicio
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101010');
    };
}
