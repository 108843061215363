import { Backdrop, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, Divider } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { FilesDropzoneAgended } from 'components'
import React, { useState, useEffect } from 'react'
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';


export default function Results() {

    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();

  const [errorTipo, setErrorTipo] = useState(false);
    const [errorArchivos, setErrorArchivos] = useState(false);
    const [errorArchivosSize, setErrorArchivosSize] = useState(false);
    const [files, setFiles] = useState([]);
    const [detCodigo, setDetCodigo] = useState(null);

    const [openBack, setOpenBack] = useState(false)

    useEffect(() => {
        queries.detalleCatalogo('URLFCC')
        .then(
            async response => {
                if (response.error.codigo === '0') {
                    setDetCodigo(response.bodyOut.data[0].detCodigo)
                } else {
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
                //setDesactivar(false);
            }
        );
    }, [])
    
    

  const handleClickOpen = files => {
    let error = false;
    setErrorTipo(false);
    setErrorArchivos(false);

    if (!files.length) {
        setErrorArchivos(true);
        error = true;
    }
    let totalSize = 0;
    files.map(file => {
        totalSize += file.size;
        return 0;
    });

    const fileSize = totalSize / 1024 / 1024;

    if (fileSize > 100) {
        setErrorArchivosSize(true);
        error = true;
    }

    if (error) {
        return;
    }
    setFiles(files);
    handleSave(files)
    //console.log(files);
    /* props.dataFiles(files);
    props.dataDescripcion(descripcion); */
};

const handleSave =(file) => {
  setOpenBack(true)
  queries.agedamientoDocuentos(
                    detCodigo,
                    file,
                    'Cancelación Masiva'
                )
                .then(
                    async response => {
                        if (response.error.codigo === '0') {
                            //setDesactivar(false);

                                displayMessage.success(
                                    'Archivo subido correctamente'
                                );
                                window.location.reload()
                        } else {
                            setOpenBack(false)
                            displayMessage.error(
                                'Error al subir el archivo'
                            );
                        }
                    },
                    () => {
                        displayMessage.error('Error de comunicación.');
                        //setDesactivar(false);
                        setOpenBack(false)
                    }
                );
   }


  return (
    <div>
      <Card>
        <CardHeader title='Subir documento de cancelación'/>
        <Divider/>
        <CardContent>
        <FilesDropzoneAgended callback={handleClickOpen.bind(this)}></FilesDropzoneAgended> 
        {errorArchivos && (
                        <Alert severity="error">
                            Seleccione archivos para la solicitud!
                        </Alert>
                    )}<Dialog>
                        <DialogContent>

                        </DialogContent>
                    </Dialog>
                    {errorArchivosSize && (
                        <Alert severity="error">
                            Los archivos superan los 100 MB permitidos
                        </Alert>
                    )} 
        </CardContent>
      </Card>
      <Dialog
       aria-describedby="alert-dialog-description"
       aria-labelledby="alert-dialog-title"
       open={openBack}
       style={{
           opacity: 1,
           transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
       }}
      >
          <DialogContent>
        <CircularProgress color="inherit" />
          </DialogContent>
      </Dialog>
     
    </div>
  )
}
