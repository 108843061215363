import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { DisplayMessage } from 'notifications/displayMessage';
import { Cancel, Search, Clear } from '@material-ui/icons'
import { CircularProgress, Card, CardHeader, CardActions, Grid, TextField, Button, IconButton, Dialog, CardContent, Divider } from '@material-ui/core';
import { AppointmentProvider } from 'providers/appointment.service';
import { SearchBasic } from 'components';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function SearchLeader(props) {
    const classes = useStyles();
    const tableRef = React.useRef();
    const [booleano, setBooleano] = useState(false);
    const [data, setData] = useState([]);
    const appoinment = new AppointmentProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Ruc', field: 'PRE_RUC' },
        { title: 'Razon Social', field: 'PRE_RAZON_SOCIAL' },
        { title: 'Nombre', field: 'PRE_NOMBRE' },
        { title: 'Direccion', field: 'PRE_DIRECCION' },
        { title: 'Ciudad', field: 'LOC_NOMBRE' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');
    const [total, setTotal] = useState(0)

    const loadData = (query, resolve) => {

        let filt = ''
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'PRE_CODIGO',
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        }
        filt = orderBy.field

        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'desc'
        }

        appoinment.buscarPrestador(buscarTitular, orderBy.field, direction.orderDirection, query.pageSize, query.pageSize * (query.page))
            .then(
                response => {
                    if (response.bodyOut.data !== null) {
                        setBooleano(false)
                        setTotal(response.bodyOut.data[0].TOTAL)
                        resolve({
                            data: response.bodyOut.data,
                            page: query.page,
                            totalCount: response.bodyOut.data[0].TOTAL
                        });

                    } else {
                        setBooleano(false)
                        setTotal(0)
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                    }

                },
                () => {
                    setBooleano(false);
                    setTotal(0)
                    resolve({
                        data: [],
                        page: query.page,
                        totalCount: 0
                    });
                    displayMessage.error(
                        'Error de comunicación.'
                    );
                }
            );

    }

    const handleBuscarTitular = () => {
        tableRef.current.onSearchChange(buscarTitular);
    }

    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false)
        setBuscarTitular('')
    }

    const handleData = (e) => {
        props.datos(e)
    }

    const handleClose = () => {
        props.close();
        setBuscarTitular('');
        setData([]);
    }

    //Tipo,nombre,provincia,ciudad,direccion y telefono
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={props.open}
            scroll={'body'}
        >
            <Card>
                <CardHeader
                    action={<IconButton
                        color="secondary"
                        onClick={handleClose}
                    ><Cancel /></IconButton>}
                    title='Buscar prestador'
                />
                <Divider />
                <CardContent>

                    <SearchBasic
                        searchBar={buscarTitular}
                        cleanSearchBar={handleLimpiar}
                        handleInputChange={handleChange}
                        complementaryMessage='Buscar'
                        handleSearch={handleBuscarTitular}
                    />
                </CardContent>


                <MaterialTable
                    tableRef={tableRef}
                    actions={[
                        {
                            icon: 'check',
                            tooltip: 'Seleccionar',
                            onClick: (event, rowData) => {
                                handleData(rowData)
                                handleClose()
                            }
                        }
                    ]}
                    columns={columns}
                    data={booleano === false ? (query =>
                        new Promise((resolve) => {
                            loadData(query, resolve)

                        })) : []}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            search: false,
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'


                        },
                        actions: {
                            actions: "Accion"
                        }
                    }}
                    options={{
                        exportButton: false, search: false, toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                        }),
                    }}
                    title="Prestadores"
                />

            </Card><br />
        </Dialog>
    );
}