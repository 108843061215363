/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Button, Typography } from '@material-ui/core';

import { Page } from 'components';
import { Header, LiquidationInfo, PrestacionesItems, LiquidationDocuments, ModalAddTarifario } from './components';
import { DisplayMessage } from 'notifications/displayMessage';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { PersonalProvider  } from 'providers/persona.service';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    TextField
} from '@material-ui/core';
import validate from 'validate.js';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    container: {
        marginTop: theme.spacing(3)
    },
    deleteIcon: {
        marginRight: theme.spacing(1)
    },
}));

const schema = {
    talObservacion: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },    
};


const AuditDetails = (props) => {
    const classes = useStyles();
    const [liquidation, setLiquidation] = useState(null);
    const [open, setOpen] = useState(false);    
    const [loading, setLoading] = useState(false);
    const [loadingReturn, setLoadingReturn] = useState(false);
    const liquidationProvider = new LiquidationProvider();
    const personaProvider = new PersonalProvider();
    const displayMessage = new DisplayMessage();
    const [liqCode] = useState(atob(props.match.params.id));
    const [beneficiary, setBeneficiary] = useState([]);
    const [coberturasPlan, setCoberturasPlan] = useState([]);
    const [details, setDetails] = useState([]);
    const [documents, setDocuments] = useState([]);    
    const [resetData, setResetData] = useState(true);
    const session = useSelector(state => state.session);
    const [openReturn, setOpenReturn] = useState(false); 
    const [enviarCodigo, setEnviarCodigo] = useState('');
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}            
        }));        
    }, [formState.values]);

    const handleChange = event => {        
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    useEffect(() => {
        fetchLiquidation();  
    },[]);

    useEffect(() => {    
       
        if (liquidation !== null){
            getPersonByPerCodigo(liquidation.perBeneficiario);
            getCoberturasPlan(liquidation.plaCodigo);
            //getDocuments();
        }
    }, [liquidation]);

    useEffect(() => {
        if (Object.keys(coberturasPlan).length) {
            getDetailsLiquidator();
        }
    }, [coberturasPlan]);


    const fetchLiquidation = () => {
        setLoading(true);
        liquidationProvider.liquidacionInfo(liqCode).then(response => {   
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setLiquidation(response.bodyOut.data[0]);
                setLoading(false);
                setEnviarCodigo(response.bodyOut.data[0].plaCodigo);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            displayMessage.error('Error de comunicación.');  
        });   
        
    };

    

    const getDetailsLiquidator = () => {
        setLoading(true);
        liquidationProvider.liquidacionDetails(liqCode).then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setDetails(transformDetalleLiquidacion(response.bodyOut.data));
                setLoading(false);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            this.displayMessage.error('Error de comunicación.');        
        }); 
    };

    const getPersonByPerCodigo = (perCodigo) => {
        setLoading(true);
        personaProvider.personByPerCodigo(perCodigo).then((res)=>{
            setBeneficiary(res[0]);
        })
    }

    const getCoberturasPlan = (plaCodigo) => {
        setLoading(true);
        liquidationProvider.coberturasPlan(plaCodigo).then(response => {            
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                setCoberturasPlan(transformCoberturas(response.bodyOut.data));
                setLoading(false);
            } else {
                setLoading(false);
            }                  
        },
        () => {          
            this.displayMessage.error('Error de comunicación.');        
        }); 
    };


    // const getDocuments = () => {
    //     setLoading(true);
    //     liquidationProvider.liquidacionDocuments(liqCode).then(response => {            
    //         if(response.error.codigo === '0' && response.bodyOut.data.length){
    //             setDocuments(response.bodyOut.data);
    //             setLoading(false);
    //         } else {
    //             setLoading(false);
    //         }                  
    //     },
    //     () => {          
    //         this.displayMessage.error('Error de comunicación.');        
    //     }); 
    // };

    const transformCoberturas = (data) => {
        let dataTransform = [];
        data.map(item => {
            if (dataTransform[item.modalidadNemonico]===undefined){
                dataTransform[item.modalidadNemonico]={[item.detTipo]: item};
            } else {
                Object.assign(dataTransform[item.modalidadNemonico], {[item.detTipo]: item})
            }
            return [];
        });        
        return dataTransform;
    }

    const transformDetalleLiquidacion = (data) => {
        let dataCoberturas = coberturasPlan
        let tipoLiq = liquidation.detNemonicoTipo
        let tipoCobertura = (tipoLiq === 'TIPLQRE') ? 'TMA' : 'TMC';
        let totalRegistrado = 0;
        let totalObjetado = 0;
        let totalHonorarios = 0;
        let dataAux = [...data];

        data.map((item, index) => {            
            item.detalle.map((item1, index1) => {      
                if (data[index].detalle[index1].talEstado === 0){
                    dataAux[index].detalle.splice(index1, 1);
                }
                return [];
            });
            return [];
        });

        dataAux.map((item, index) => {            
            item.detalle.map((item1, index1) => {      
                if (dataAux[index].detalle[index1].talEstado !== 0){
                    dataAux[index].detalle[index1].copTipoTarifario = dataCoberturas[tipoCobertura][item.detTipo].copTipoTarifario;
                    dataAux[index].detalle[index1].estado = dataAux[index].detalle[index1].talEstado === 1 || dataAux[index].detalle[index1].talEstado === 2 ? true : false;
                    dataAux[index].detalle[index1].talEstado = dataAux[index].detalle[index1].talEstado;
                    dataAux[index].detalle[index1].talCantidadAutorizada = dataAux[index].detalle[index1].talCantidadAutorizada ? dataAux[index].detalle[index1].talCantidadAutorizada : dataAux[index].detalle[index1].talCantidad;              
                    let tarifado = dataAux[index].detalle[index1].talValorTarifado ? dataAux[index].detalle[index1].talValorTarifado : 0;
                    dataAux[index].detalle[index1].talValorObjetado =  dataAux[index].detalle[index1].talValorObjetado !== null ? dataAux[index].detalle[index1].talValorObjetado  : dataAux[index].detalle[index1].talValorRegistrado;
                    dataAux[index].detalle[index1].talValorTarifado = tarifado;
                    dataAux[index].detalle[index1].error = false;
                    dataAux[index].detalle[index1].talValorHonorarios = dataAux[index].detalle[index1].talValorHonorarios !== null ? dataAux[index].detalle[index1].talValorHonorarios : 0.00
                   
                    if (dataAux[index].detalle[index1].tarCodigo){
                        
                        if (dataAux[index].detalle[index1].estado){
                            let dataTarifario = {
                                tarCodigo: dataAux[index].detalle[index1].tarCodigo,
                                tarNombre: dataAux[index].detalle[index1].tarNombre ? dataAux[index].detalle[index1].tarNombre : '',
                                tarConcentracion: dataAux[index].detalle[index1].tarConcentracion ? dataAux[index].detalle[index1].tarConcentracion : '',
                                tarFormaFarma: dataAux[index].detalle[index1].tarFormaFarma ? dataAux[index].detalle[index1].tarFormaFarma : '',
                                  tarAuxiliar: parseFloat(parseFloat(dataAux[index].detalle[index1].talValorTarifado / dataAux[index].detalle[index1].talCantidadAutorizada).toFixed(2))
                            }
                            dataAux[index].detalle[index1].dataTarifario = dataTarifario
                        } else {
                            dataAux[index].detalle[index1].tarCodigo = null;
                        }

                    }
                    totalObjetado += dataAux[index].detalle[index1].talValorObjetado-dataAux[index].detalle[index1].talValorRegistrado
                   
                    totalRegistrado += item1.talValorRegistrado;
                    
                    if(dataAux[index].detalle[index1].talValorHonorarios){
                    totalHonorarios += dataAux[index].detalle[index1].talValorHonorarios;
                }
                } else {
                    dataAux[index].detalle.splice(index1, 1);
                }
                return [];
            });
            
            if (dataCoberturas[tipoCobertura][item.detTipo] !== undefined){                
                dataAux[index].delValorObjetado = dataAux[index].delValorObjetado === null ? dataAux[index].delValorRegistrado : dataAux[index].delValorObjetado;
            } else {
                dataAux[index].delValorObjetado = dataAux[index].delValorObjetado === null ? dataAux[index].delValorRegistrado : dataAux[index].delValorObjetado;
            }
            
            return [];
        });
        data.totalRegistrado = totalRegistrado;
        data.totalObjetado = totalObjetado;
        data.totalHonorarios = totalHonorarios
        return data;
    }

    const handleAceptReturn = () => {        
        setLoadingReturn(true);
        liquidationProvider.requestReturn(liqCode, formState.values.talObservacion).then(response => {
            if(response.error.codigo === '0'){
                //setLoadingReturn(false);                
                //setOpenReturn(false);
                props.history.push('/auditor/liquidaciones/auditoria');
            } else {
                setLoadingReturn(false);
                setOpenReturn(false);
            }
        },() => {          
            this.displayMessage.error('Error de comunicación.');                   
            setLoadingReturn(false);
        });
    };

    return (
        <Page
            className={classes.root}
            title="Liquidación pendiente"
        >
            {liquidation ? (
                <>
                    <div className="right" >
                        <Typography
                            display="block"
                            gutterBottom
                            variant="overline"
                        >
                            <div className="red-text">                            
                                <Button
                                    color="primary"
                                    onClick={() => { setOpenReturn(true); }}
                                    variant="contained"
                                >
                                    <ArrowBackIcon className={classes.deleteIcon} />
                                    Devolver Liquidación
                                </Button>
                            </div>
                        </Typography>
                    </div>
                    <Header 
                        liquidation={liquidation}
                    /> 
                </>                    
            )
                : <CircularProgress />
            }
            <Grid
                className={classes.container}
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    {liquidation ? 
                        <LiquidationInfo 
                            beneficiary={beneficiary} 
                            liquidation={liquidation} 
                            mostrarObs
                        /> 
                        : <CircularProgress />}
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    {liquidation ? 
                        <LiquidationDocuments 
                            documents={liquidation.documentos} 
                            liqCode={liqCode} 
                        /> 
                        : <CircularProgress />}
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Button
                        color="primary"
                        onClick={() => {setOpen(true); setResetData(!resetData);}}
                        variant="contained"
                    >
                                Agregar Tarifario
                    </Button>                    
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    {!loading ? 
                        <PrestacionesItems 
                            data={details} 
                            liqCode={liqCode}
                            session={session}
                            enviarCodigo={enviarCodigo}                            
                        />: <CircularProgress /> }
                </Grid>
                
            </Grid> 
            <ModalAddTarifario
                onClose={() => {setOpen(false); setResetData(!resetData);}}
                open={open}        
                resetData={resetData}                    
            />
            <Dialog 
                aria-labelledby="form-dialog-title" 
                disableBackdropClick 
                disableEscapeKeyDown
                onClose={()=> setOpenReturn(false)}
                open={openReturn}
            >
                <DialogTitle id="form-dialog-title">Devolver liquidación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro de devolver la liquidación?
                    </DialogContentText>                    
                    <TextField
                        autoFocus
                        error={hasError('talObservacion')}
                        fullWidth    
                        helperText={hasError('talObservacion') ? formState.errors.talObservacion[0] : null}
                        label="Escriba observación"   
                        multiline
                        name="talObservacion"                 
                        onChange={handleChange}
                        rows={5}                                    
                        value={formState.values.talObservacion || ''}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary"
                        onClick={()=> setOpenReturn(false)} 
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        disabled={!formState.isValid}
                        onClick={()=> handleAceptReturn()} 
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-describedby="alert-dialog-description"              
                aria-labelledby="alert-dialog-title"
                open={loadingReturn}
                style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
            > 
                <DialogContent >  
                    <CircularProgress/>
                </DialogContent>                       
            </Dialog>  
        </Page> 
    );
};

export default AuditDetails;
