import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
//import FilterListIcon from '@material-ui/icons/FilterList';

import {TableForm} from './components';

const TableGenericforForm = props => {
    const { columna,data, dataOfObject, idOfObject, actions, dataSelect, handleOpenRemove, handleOpenUpdate,dataPrevia } = props;

    return (
        <Grid>
 
            <TableForm
                data={data}
                dataPrevia={dataPrevia}
                columna={columna}
                dataOfObject = {dataOfObject}
                idOfObject = {idOfObject}
                actions = { actions }
                dataSelect={dataSelect} 
                handleOpenRemove = {handleOpenRemove}
                handleOpenUpdate = {handleOpenUpdate}
            />
        </Grid>
    );
};

TableGenericforForm.propTypes = {
    className: PropTypes.string,
    onFilter: PropTypes.func,
    onSearch: PropTypes.func
};

export default TableGenericforForm;