/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Label, SearchBasic } from 'components';
import { QueriesProvider } from 'providers/queries.service';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Cancel, Delete, Send } from '@material-ui/icons';
import MaterialTable from 'material-table';
import NumericInput from 'react-numeric-input';
import ModalAddHonorarios from '../ModalAddHonorarios';
import {
    Modal,
    CardHeader,
    Card,
    CardContent,
    CardActions,
    Grid,
    AddBoxIcon,
    Box,
    FormControlLabel,
    Typography,
    TextField,
    Button,
    colors,   
    TableCell,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Checkbox,
    paymentsCart,
    CircularProgress,
    Input,
    IconButton
} from '@material-ui/core';
import validate from 'validate.js';
import { DisplayMessage } from 'notifications/displayMessage';
import { PrestacionesItems } from 'views/AuditDetailsRead/components';
import { replace } from 'formik';
import Add from '@material-ui/icons/Add';
import NumberFormatMoney from 'components/NumberFormatMoney';



const schema = {
    talObservacion: {
        presence: { allowEmpty: false, message: '^Requerido' }
    },    
};


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 1250,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
    },
    container: {
        marginTop: theme.spacing(3),
    },
    actions: {
        justifyContent: 'flex-start'
    },
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.blue[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    }
}));




const ModalDetalleHonorarios = props => {
    const { data, enviarCodigo, elementosHonorario, enviarTotal,enviarCodigoHonorario,enviarDetalleHonorario,valorFCM,valorPorcentaje,cantidad, open, resetData2, onClose, className, filaActiva, filaActivaObs, ...rest } = props;
    const classes = useStyles();
    const [ModalHonorarios, setModalHonorarios] = React.useState(false);
    const [eventSelected, setEventSelected] = React.useState(true);  
    const [reloadData, setReloadData] = React.useState(true);   
    const [cargarDatos, setCargarDatos] = React.useState([]);   
    const [calculoCantidad, setCalculoCantidad] = React.useState(0);  
    const [refresh, setRefresh] = React.useState(false);  
    const [totalHonorario, setTotalHonorario] = useState(0); 
    const [detalleHonorario, setDetalleHonorario] = useState([]); 
    const [enviarFCM, setEnviarFCM] = useState([]);
    const [enviarPorcentaje, setEnviarPorcentaje] = useState([]);
    const [aux1, setAux1] = useState([]); 
    const [arregloPorcentajes, setArregloPorcentajes] = useState([]); 
    const [arregloFCM, setArregloFCM] = useState([]);
    const [checked, setChecked] = React.useState(true);
    const [errorPorcentaje, setErrorPorcentaje] = useState(false);
    const [errorCantidad, setErrorCantidad] = useState(false);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const displayMessage = new DisplayMessage();
    //const [data, setData] = useState([]);
    const queries = new QueriesProvider();
    const [dataHonorarios,setDataHonorarios]=useState([]);
    const [codigo,setCodigo]=useState('');
    const [obtenerCantidad,setObtenerCantidad]=useState(5);

    useEffect(() => {
        for (let index = 0; index < cargarDatos.length; index++) {
        
            
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
        
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
          
            
        }
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
           
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
          
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
         
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1 ){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
        
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
           
           
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0 ){
            cargarDatos[parseInt(index)].totalPorcentaje=parseFloat(0).toFixed(0)
        
            cargarDatos[parseInt(index)].totalHonor=parseFloat(0).toFixed(0)
            
            cargarDatos[parseInt(index)].totaltotal=parseFloat(0).toFixed(0)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
       
           
        }
    }
        
    }, [refresh])

    const dataEnvioDetalleHonorario = (data) =>{
        const copy = detalleHonorario
        let auxDetalleHonorario = {
            honCodigo: data.honCodigo,
            descripcion: data.honDescripcion, 
            porcentaje: data.porcentaje,
            hopValor: data.hopValor,
            cantidad: data.calculoBN,
            ayudante: data.checkAyudante,
            cirujano: data.checkCirujano,
            anestesia: data.checkAnestesia,
            codigo: data.honCodHon,
            honUnidad: data.honUnidad,
            subtotalFinal: data.totaltotal
        }
        copy.push(auxDetalleHonorario)
        setDetalleHonorario(copy)
    }

    useEffect(() => {
        setFormState({
            
            isValid: false,
            values: {talDetalleHonorario: filaActivaObs}, 
            touched: {},
            errors: {}
        });
             if(filaActivaObs!==undefined && filaActivaObs!==null && filaActivaObs!=='' && filaActivaObs!==0){

                const honorarios=JSON.parse(filaActivaObs)
                let data={}

                let arregloAux =[]
               if(detalleHonorario.length===0){ 
                   
                for (let index = 0; index < honorarios.length; index++) {
                    const element = honorarios[index];
                    data={
                        calculoBN:element.cantidad,
                        calculoCirujano:parseFloat(element.honUnidad*element.hopValor*element.cantidad).toFixed(2),
                        calculoAyudante:parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.30).toFixed(2),
                        calculoAnestesia:parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.35).toFixed(2),
                        totalHonor:parseFloat(parseFloat(element.honUnidad*element.hopValor*element.cantidad).toFixed(2))+parseFloat(parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.30).toFixed(2))+parseFloat(parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.35).toFixed(2)),
                        totalPorcentaje:parseFloat(((parseFloat(element.honUnidad*element.hopValor*element.cantidad).toFixed(2)+parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.30).toFixed(2)+parseFloat((element.honUnidad*element.hopValor*element.cantidad)*0.35).toFixed(2))*element.porcentaje)/100).toFixed(2),
                        totaltotal:element.subtotalFinal,
                        honCodigo:element.honCodigo,
                        honCodHon:element.codigo,
                        honDescripcion:element.descripcion,
                        porcentaje:element.porcentaje,
                        honUnidad:element.honUnidad,
                        hopValor:element.hopValor,
                        checkAnestesia:element.anestesia,
                        checkAyudante:element.ayudante,
                        checkCirujano:element.cirujano,
                    }
                   /*  arregloAux = cargarDatos */
                    arregloAux.push(data)

                    dataEnvioDetalleHonorario(data)  
                } 
                
                setCargarDatos(arregloAux)
                
                 
            }

         } 

            
    }, [resetData2]);

    
    
    useEffect(() => {
        let suma=null
        let auxPOR=arregloPorcentajes
        let auxFCM=arregloFCM
        for (let index = 0; index < cargarDatos.length; index++) {
            auxPOR[index]=[]
            auxFCM[index]=[]
            suma = (suma+parseFloat(cargarDatos[index].totalHonor)-parseFloat(cargarDatos[index].totalPorcentaje))
            
            
            
            auxFCM[index]=cargarDatos[parseInt([index])].hopValor
            auxPOR[index]=cargarDatos[parseInt([index])].porcentaje
            
        }
        setArregloPorcentajes([auxPOR])
        setArregloFCM([auxFCM])
        setTotalHonorario(parseFloat(suma).toFixed(2))  
        setEnviarFCM([auxFCM])
        setEnviarPorcentaje([auxPOR])

    }, [refresh]);

    

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}            
        }));        
    }, [formState.values]);

   
    const getDataHonorariosEnviar = (data) =>{
        data.calculoBN=0
        data.calculoCirujano=0
        data.calculoAyudante=0
        data.calculoAnestesia=0
        data.totalHonor=0
        data.totalPorcentaje=0
        data.totaltotal=0
        data.porcentaje=0
        data.checkCirujano=1
        data.checkAyudante=1
        data.checkAnestesia=1
        let arregloAux = cargarDatos
        arregloAux.push(data)
        setCargarDatos(arregloAux)
        dataEnvioDetalleHonorario(data)
    }


const handleOpenHonorarios = () =>{
    setModalHonorarios(true)
}
const handleDeleteAllHonorarios = () =>{
    
    setArregloPorcentajes([])
    setArregloFCM([])
    setTotalHonorario(0)
    setEnviarFCM([])
    setEnviarPorcentaje([])
    setCargarDatos([])
    setDetalleHonorario([])  
    setModalHonorarios(false)
    setRefresh(!resetData2) 
}
const handleCleanHonorarios = () =>{
    
    setArregloPorcentajes([])
    setArregloFCM([])
    setTotalHonorario(0)
    setEnviarFCM([])
    setEnviarPorcentaje([])
    setCargarDatos([])
    setDetalleHonorario([])  
    setModalHonorarios(false)
    onClose(formState.values.talDetalleHonorario, filaActiva);
    
    setRefresh(!resetData2) 
}

const handleCalcularHonorarios = () =>{
  
    
    enviarTotal(parseFloat(totalHonorario))
    valorFCM(enviarFCM)
    valorPorcentaje(enviarPorcentaje)
    enviarDetalleHonorario(detalleHonorario)
    setArregloPorcentajes([])
    setArregloFCM([])
    setTotalHonorario(0)
    setEnviarFCM([])
    setEnviarPorcentaje([])
    setCargarDatos([])
    setDetalleHonorario([])  
    setModalHonorarios(false)
    onClose(formState.values.talDetalleHonorario, filaActiva);
    
}
const onChangeCantidad = (e,index)=>{
    if(e < 0 || e > 100){
        setErrorCantidad(true)
        displayMessage.error("Cantidad no valida")
    }else{
        if(e!==undefined){
    //setTotalHonorario += cargarDatos[parseInt(index)].totalHonor+cargarDatos[parseInt(index)].totalHonor

 
    cargarDatos[parseInt(index)].calculoBN=e
    detalleHonorario[parseInt(index)].cantidad=e
    cargarDatos[parseInt(index)].calculoCirujano=(parseFloat(cargarDatos[parseInt(index)].calculoBN) * parseFloat(cargarDatos[parseInt(index)].honUnidad) * parseFloat(cargarDatos[parseInt(index)].hopValor)).toFixed(2)
    cargarDatos[parseInt(index)].calculoAyudante=(parseFloat(cargarDatos[parseInt(index)].calculoCirujano) *0.30).toFixed(2)
    cargarDatos[parseInt(index)].calculoAnestesia=(parseFloat(cargarDatos[parseInt(index)].calculoCirujano) *0.35).toFixed(2)
    cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
    cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
    detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
    setRefresh(!refresh)  
}
setErrorCantidad(false)
}




}

/* const onChangeayudante = (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkAyudante=1
        detalleHonorario[parseInt(index)].ayudante=1
        
        
        }else{
        cargarDatos[parseInt(index)].checkAyudante=0
        detalleHonorario[parseInt(index)].ayudante=0
        
        } 
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1 ){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
        
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
           
            setRefresh(!refresh)  
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0 ){
            cargarDatos[parseInt(index)].totalPorcentaje=parseFloat(0).toFixed(0)
        
            cargarDatos[parseInt(index)].totalHonor=parseFloat(0).toFixed(0)
            
            cargarDatos[parseInt(index)].totaltotal=parseFloat(0).toFixed(0)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
       
            setRefresh(!refresh)  
        }
        
}



const onChangeCirujano= (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkCirujano=1
        detalleHonorario[parseInt(index)].cirujano=1
        
        
        }else{
        cargarDatos[parseInt(index)].checkCirujano=0
        detalleHonorario[parseInt(index)].cirujano=0
        
        } 

        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1 ){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
        
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
           
            setRefresh(!refresh)  
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0 ){
            cargarDatos[parseInt(index)].totalPorcentaje=parseFloat(0).toFixed(0)
        
            cargarDatos[parseInt(index)].totalHonor=parseFloat(0).toFixed(0)
            
            cargarDatos[parseInt(index)].totaltotal=parseFloat(0).toFixed(0)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
       
            setRefresh(!refresh)  
        }
}



const onChangeanestesia = (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkAnestesia=1
        detalleHonorario[parseInt(index)].anestesia=1
        
        
        }else{
        cargarDatos[parseInt(index)].checkAnestesia=0
        detalleHonorario[parseInt(index)].anestesia=0
         
        } 
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
    
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
            setRefresh(!refresh)  
            
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1 ){
            cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
        
            cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))).toFixed(2)
            
            cargarDatos[parseInt(index)].totaltotal=((parseFloat(cargarDatos[parseInt(index)].totalHonor)-parseFloat(cargarDatos[parseInt(index)].totalPorcentaje))).toFixed(2)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
           
            setRefresh(!refresh)  
        }
        if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0 ){
            cargarDatos[parseInt(index)].totalPorcentaje=parseFloat(0).toFixed(0)
        
            cargarDatos[parseInt(index)].totalHonor=parseFloat(0).toFixed(0)
            
            cargarDatos[parseInt(index)].totaltotal=parseFloat(0).toFixed(0)
            detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
       
            setRefresh(!refresh)  
        }
}
 */

const onChangeayudante = (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkAyudante=1
        detalleHonorario[parseInt(index)].ayudante=1
        setRefresh(!refresh)
        
        }else{
        cargarDatos[parseInt(index)].checkAyudante=0
        detalleHonorario[parseInt(index)].ayudante=0
        setRefresh(!refresh)
        } 
        
        
}



const onChangeCirujano= (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkCirujano=1
        detalleHonorario[parseInt(index)].cirujano=1
        setRefresh(!refresh)
        
        }else{
        cargarDatos[parseInt(index)].checkCirujano=0
        detalleHonorario[parseInt(index)].cirujano=0
        setRefresh(!refresh)
        } 

        
}



const onChangeanestesia = (e,index)=>{
    const isChecked = e.target.checked;
    if(isChecked){
        cargarDatos[parseInt(index)].checkAnestesia=1
        detalleHonorario[parseInt(index)].anestesia=1
        setRefresh(!refresh)
        
        }else{
        cargarDatos[parseInt(index)].checkAnestesia=0
        detalleHonorario[parseInt(index)].anestesia=0
        setRefresh(!refresh)
        } 
        
}

const onChangePorcentaje = (e,index)=>{
      
      if(e < 0 || e > 100){
          setErrorPorcentaje(true)
          displayMessage.error("Porcentaje no valido")
      }else{
          if(e!==undefined){
              if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)
                  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)
                  
              }
              
              if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)
                  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===1 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAyudante))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)
                  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===1){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)  
                  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===1 && cargarDatos[parseInt(index)].checkCirujano===0){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
          
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(0)+parseFloat(cargarDatos[parseInt(index)].calculoAnestesia))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                  setRefresh(!refresh)  
                  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===1 ){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=(((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))*parseFloat(cargarDatos[parseInt(index)].porcentaje))/100).toFixed(2)
              
                  cargarDatos[parseInt(index)].totalHonor=((parseFloat(cargarDatos[parseInt(index)].calculoCirujano))).toFixed(2)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(parseFloat(cargarDatos[parseInt(index)].totalPorcentaje)).toFixed(2)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
                 
                  setRefresh(!refresh)  
              }
              if(cargarDatos[parseInt(index)].checkAyudante===0 && cargarDatos[parseInt(index)].checkAnestesia===0 && cargarDatos[parseInt(index)].checkCirujano===0 ){
                  cargarDatos[parseInt(index)].porcentaje=parseFloat(e)
                  detalleHonorario[parseInt(index)].porcentaje=parseFloat(e)
                  cargarDatos[parseInt(index)].totalPorcentaje=parseFloat(0).toFixed(0)
              
                  cargarDatos[parseInt(index)].totalHonor=parseFloat(0).toFixed(0)
                  
                  cargarDatos[parseInt(index)].totaltotal=parseFloat(0).toFixed(0)
                  detalleHonorario[parseInt(index)].subtotalFinal=cargarDatos[parseInt(index)].totaltotal
             
                  setRefresh(!refresh)  
              }
              }
          setErrorPorcentaje(false)
      }
  
  }

const onChangeUVR = (e,index)=>{
    if(e!==undefined){
        cargarDatos[parseInt(index)].honUnidad=parseFloat(e) 
        detalleHonorario[parseInt(index)].honUnidad=parseFloat(e) 
    }
    }


    const handleDelete = (index) => {
        cargarDatos.splice(index,1)
        detalleHonorario.splice(index,1)
        setRefresh(!refresh)  
    }
 
    return (
        
       

        <Modal 
            
            open={open}
            
        >
          
            <Card
                
                {...rest}
                className={clsx(classes.root, className)}
            >
                <CardHeader
                title='Detalle Honorarios Medicos'
                    action={<IconButton
                        color="secondary"
                        onClick={()=>handleCleanHonorarios()}
                    ><Cancel /></IconButton>}
                   
                />
                    
                    <CardActions className={classes.actions}>
                    <Button
                        color="primary"
                        key={'tar'} 
                        onClick={() =>handleOpenHonorarios()}
                        style={{ marginTop: 10}}
                        startIcon={<Add/>}
                        size='small'
                        variant="contained"
                    >
                    Agregar Honorarios
                    </Button> 

                      {/*    */}
                         
                    
                    </CardActions>
                   {/*  <Button
                        color="primary"
                        //key={'tar'+item.delCodigo+item1.talCodigo} 
                        onClick={() =>handleDeleteAllHonorarios()}
                        style={{ marginTop: -100,marginLeft:10}}
                        variant="contained"
                    >
                    Limpiar
                    </Button> */}

                     <Table id='tablaHonorarios'style={{margin:[10,10,10,10]}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Codigo</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '30%'}}><b>Descripcion</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Porcentaje</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>UVR</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>FCM</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Cantidad</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '15%'}}><b>Cirujano</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '15%'}}><b>Ayudante</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '15%'}}><b>Anestesia</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Total</b></TableCell>
                                    <TableCell style={{ borderRight: '1px solid #eeeeee', width: '5%'}}><b>Acciones</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {cargarDatos.map((item, index) => (
                               
                            
                            <TableRow 
                                key={'honCodHon'+item.honCodHon} 
                                style={{ borderTop: '1px solid black'}}
                            >
                                <TableCell>
                                   {item.honCodHon}
                                </TableCell>
                                <TableCell>
                                    {item.honDescripcion}
                                </TableCell>
                                <TableCell>
                                <Input 
                                    type='number'
                                    size="small"
                                    id='porcentaje'
                                    value={item.porcentaje}
                                    variant="outlined"   
                                    rowsMax={2}                                 
                                    error={errorPorcentaje ? true : false}
                                    onChange={(e) => onChangePorcentaje(e.target.value,index)} 
                                    required
                                />
                                 {/* <TextField
                                error={errorPorcentaje ? true : false}
                                fullWidth
                                helperText={errorPorcentaje && 'Ingrese un porcentaje válido'}
                                InputProps={{
                                    inputComponent: NumberFormatMoney
                                }}
                                name="porcentaje"
                                onChange={(e) => {onChangePorcentaje(e.target.value,index)}}    
                                value={item.porcentaje} 
                            /> */}
                                </TableCell>
                                <TableCell>
                                    {item.honUnidad===-1 ?  <Input 
                                    type='number'
                                    size="small"
                                    id='uvr'
                                    variant="outlined"
                                    //rowsMax={4}
                                    onChange={(e) => onChangeUVR(e.target.value,index)} 
                                />:item.honUnidad}
                                </TableCell>
                                <TableCell>
                                    {item.hopValor}
                                </TableCell>
                                <TableCell>
                                <Input 
                                        type='number'
                                        size="small"
                                        id='cantida'
                                        variant="outlined"
                                        value={item.calculoBN}
                                        //rowsMax={4}
                                        error={errorCantidad ? true : false}
                                        onChange={(e) => onChangeCantidad(e.target.value,index)}
                                />
                                
                                {/* ssss */}
                                </TableCell>
                                
                                <TableCell id='cirujano'>
                                <FormControlLabel  
                                 control={  
                                <Checkbox 
                                        size="small"
                                        id='cirujano'
                                        variant="cirujano"
                                        checked={item.checkCirujano===1?true:false} 
                                        //rowsMax={4}
                                        onChange={(e) => onChangeCirujano(e,index)}  
                                />  
                            }
                                />
                                <>

                                {item.calculoBN===0 || item.totaltotal==='NaN'? 0 :
                                item.calculoCirujano}
                                </>
                              
                                
                                </TableCell>
                                <TableCell id='ayudante'>
                                <FormControlLabel  
                                 control={  
                                <Checkbox 
                                        size="small"
                                        id='ayudante'
                                        variant="ayudante"
                                        checked={item.checkAyudante===1?true:false} 
                                        //rowsMax={4}
                                        onChange={(e) => onChangeayudante(e,index)} 
                                />  
                            }
                                />
                                <>

                                {item.calculoBN===0 || item.totaltotal==='NaN'? 0 :
                                   item.calculoAyudante}
                                </>
                                </TableCell>
                                
                                <TableCell id='anestesia'>
                                <FormControlLabel  
                                 control={  
                                <Checkbox 
                                        size="small"
                                        id='anestesia'
                                        variant="anestesia"
                                        checked={item.checkAnestesia===1?true:false} 
                                        //rowsMax={4}
                                        onChange={(e) => onChangeanestesia(e,index)} 
                                />  
                            }
                                />
                                {item.calculoBN===0 || item.totaltotal==='NaN'? parseFloat(0).toFixed(2) :
                                item.calculoAnestesia}
                                </TableCell>
                                <TableCell>

                                {item.calculoBN===0 || item.totaltotal==='NaN'? parseFloat(0).toFixed(2)  :
                                
                                item.totaltotal
                                 
                                

                                }
                                </TableCell>
                                
                                    <TableCell style={{alignSelf:'center'}}>
                                        <Button
                                        onClick={()=>{index !== undefined && handleDelete(index)}}
                                        variant='text'
                                        >
                                            <Delete/>
                                            </Button>
                                        
                                    </TableCell>
                                </TableRow>

                                 
                            ))}
                            
                            </TableBody>
                            
                    </Table> 
               
                <CardActions style={{justifyContent:'flex-end'}}>
                <Button
                            color="primary"
                            style={{ marginTop: 10}}
                            //className={classes.saveButton}
                            onClick={() => handleCalcularHonorarios()}
                            variant="contained"
                            startIcon={<Send/>}
                            size='small'
                        >
                            Enviar
                            </Button>
                </CardActions>
                <ModalAddHonorarios
                        onClose={() => {setModalHonorarios(false); setReloadData(!resetData2)}}
                        open={ModalHonorarios}     
                        enviarCodigo={enviarCodigo}             
                        dataHonorariosEnviar={getDataHonorariosEnviar} 
                    />
            </Card>
            
        </Modal>
    );
};

export default ModalDetalleHonorarios;
