/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import {  
    Divider,  
    Typography,
    Card,
    CardHeader
} from '@material-ui/core';
import { MessagesToolbar, MessagesForm } from './components';
import { LiquidationProvider } from "./../../providers/liquidation.service";
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        backgroundColor: theme.palette.white,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },

    header: {
        padding: theme.spacing(3, 3),
        display: 'flex',
        justifyContent: 'space-between'
    },
    receiver: {
        display: 'flex',
        alignItems: 'center'
    },
    avatar: {
        height: 56,
        width: 56,
        marginRight: theme.spacing(2)
    },
    actions: {
        display: 'flex',
        alignItems: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(6, 3)
    },
    messageBlue:{
        width:'80%',
        alignSelf:'right',
        backgroundColor: '#5B6DA5',
        color: 'black',
        float: 'right'
    },
    messageWhite:{
        width:'80%',
        alignSelf:'left',
        backgroundColor: 'white',
        color: 'black',
        float: 'left'
    },
    message: {
        marginTop: theme.spacing(2),
        '& > p': {
            ...theme.typography.subtitle1
        }
    }
}));

const MessagesDetails = props => {

    //    const [orders, setOrders] = useState([]);
    const [liqCode]= useState(props.match.params.id);
    const [messages, setMessages] = useState([]);
    const [liquidation, setLiquidation] = useState([]);

    //    const [orders, setOrders] = useState([]);
    const session = useSelector(state => state.session);  


    const classes = useStyles();
    useEffect( ()=>{ 

        const lP = new LiquidationProvider();
        lP.dataByPolCodigo(liqCode).then(
            (response)=> {
                setLiquidation(response);
                setMessages(response[0].estados);
            }
        ).catch(
            error=> {
                console.error(error)
            }
        )

    },[])

    return (
        
        <div      
        >
            <MessagesToolbar />
            <Divider />
            <div className={classes.header}>
                <div className={classes.receiver}>          
                    <div>
            
                        <Typography
                            display="inline"
                            variant="h5"
                        >
                            
                            {liquidation ? `Liquidacion # ${liquidation[0].liqNemonico}` : ''}
                        </Typography>
                    </div>
                </div>
            </div>
            <Divider />
            <div className={classes.content}>
                {messages ? 
                    messages.map(
                        (message) => 
                            <div key={message.eslCodigo} className='total-right' >
                                <Card
                                    className={
                                        session.user.usuCodigo === message.usuCodigo ?
                                            classes.messageBlue
                                            : classes.messageWhite 
                                    } 
                                >
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" >
                                                {session.user.usuCodigo === message.usuCodigo ?   'U' : 'L'}
                                            </Avatar>
                                        }
                                        title={<Typography variant="h5" gutterBottom>{`${session.user.usuCodigo === message.usuUsuario? session.user.usuNombre.toUpperCase() : message.usuUsuario.toUpperCase()}`}</Typography>}
                                        subheader={<Typography>{message.eslFechaInicio}</Typography>}
                                    />
                                        
                                    <Divider />   

                                    <div className='medium-space'>
                                        {message.eslMensaje}
                                    </div>

                                </Card> 
                            </div>
                    ):''
              
                }
                
            </div>
            <Divider />
            {
                messages ?             
                    <MessagesForm 
                        detEstado={messages[messages.length-1]} 
                        liqCodigo={liqCode} 
                        usuCodigo={session.user.usuCodigo} 
                        detTipo={liquidation.detTipo} 
                        usuNombre={session.user.usuNombre} 
                        usuUsuario={session.user.usuUsuario} 
                        polCodigo={session.user.polCodigo} />
                    : ''

            }
        </div>
    );
};

MessagesDetails.propTypes = {
    className: PropTypes.string,
    onEmailClose: PropTypes.func
};

export default MessagesDetails;
