/* eslint-disable react/no-multi-comp */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Card, Button, Grid,Typography, CardHeader, Divider, CardContent, TextField, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, Table, TableRow,TableCell } from '@material-ui/core';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { FormGenericDigitalize } from 'components';
import  ModalAddLender  from './ModalAddLender';
import moment from 'moment';


const BenefitsView = (props) => {
    
    const [data, setData] =  useState([]);
    const [datosPrestaciones,setDatosPrestaciones] = useState(0);
    const [datosFormulario,setDatosFormularios] = useState([]);
    const [plaCodigo, setPlaCodigo] = useState()
    const prestaciones = new DocumentDigitalizeProvider();
    const displayMessage = new DisplayMessage();
    const [crearPrestador,setCrearPrestador] = useState(false);
    const [modalidad,setModalidad] = useState();
    const [open,setOpen] = useState(false);
    const [errorState,setErrorState] = useState([])
    const [indexPrestacion, setIndexPrestacion] = useState(0)
    const [observacion, setObservacion ] = useState(props.observacionLiq);
    //const [openConfirmation ,setOpenConfirmation] = useState(false);
    const [disableSave ,setDisableSave] = useState(false);
    //const [openConfirmationSave, setOpenConfirmationSave] = useState(false);
    const [guardar,setGuardar] = useState(false);
    const [enviar,setEnviar] = useState(false);
    const [boll,setBoll] = useState(true);
    const [contador, setContador] = useState(0);
    const [tramaFinal,setTramaFinal] = useState([]);
    const [menss, setMenss] = useState(true)
    const [send1, setSend1] = useState(false);
    const [send2, setSend2] = useState(false);


  
    useEffect(()=>{
        console.log(props.liquidacion);
        console.log(props.data);
        setData(props.data);
        if(props.detTipoLiq === 'TIPLQRE'){            
            setCrearPrestador(true);
            setModalidad(26);            
        }else{
            setCrearPrestador(true);
            setModalidad(27);                 
        }
        setPlaCodigo(props.plaCodigo);
    }, [])

    useEffect(()=>{
        if (plaCodigo){
            getPrestaciones()
        }
    }, [plaCodigo])


    const getPrestaciones = () => {
        console.log(modalidad);
        console.log(plaCodigo);
        prestaciones.datosPresta(plaCodigo,modalidad)
            .then(
                data => {
                    setDatosPrestaciones(data.bodyOut.data);
                },
                () => {          
                    displayMessage.error('Error de comunicación.');

                }

            );
    }

    // useEffect(()=>{
    //     prestaciones.datosPolLiquidacion(props.liquidacion)
    //         .then(
    //             data => {
    //                 setPlaCodigo(data.bodyOut.data[0].plaCodigo);
    //             },
    //             () => {          
    //                 displayMessage.error('Error de comunicación.');

    //             }

    //         );
    // }, [])

    

    

    /* const handleOnChangePrestacion=(value)=>{  
        let newData = [
            ...data,
            {
                name  : value.tipoDescripcion,
                detTipo : value.detTipo,
                copCodigo: value.copCodigo

            }
        ]
       
        setData(newData)
    } */

    const handleCloseConfirmation = () => setGuardar(false);

    const handleCloseConfirmation1 = () => setEnviar(false);

    const addPrestacion=()=>{  
        setMenss(true)
        let newData = [
            ...data,
            {
                delCodigo: 0,
                preCodigo: null,
                liqCodigo:parseInt(props.liquidacion),
                copCodigo: null,
                detTipo: null,
                detCie10: props.detCie10,
                delMedico: '',
                delFacClaveAcceso: '',
                delFacAutorizacion: '',
                delServicio: '',
                delFecha: '',
                delValorRegistrado: 0,
                //delCopago:parseFloat(trama.copago),
                delFactura: '',
                detalle: [],
                delEstado: 1
            }
        ];
        console.log(newData);
        setData(newData);
    }


    const _remove=(position)=>{

        let dataAux = [...data];
    
        if (data[position].delCodigo === 0){            
            dataAux.splice(position, 1)
            console.log(dataAux);
            setData(dataAux);
            setContador(contador + 1);
        } else {
            dataAux[position].delEstado = 0;
            console.log(dataAux);
            setData(dataAux);
            setContador(contador + 1);
        }

        // let data1 = data;
        // let newData = [
        //     ...data1.slice(0, position),
        //     ...data1.slice(position + 1),
        // ]
        // let newDatos = {}
        
        // setData(newData)

        // for (let [key, value] of Object.entries(datosFormulario)) {
        //     if(parseInt(key) !== data[position].name) {
        //         newDatos[key] = value 
        //     }
        //     else{
        //         const a= [value]
        //         a.map((item) => {
        //             if(item.delCodigo !==0){
        //             //console.log(item)
        //                 const aux = {
        //                     copCodigo: item.copCodigo,
        //                     delCodigo: item.delCodigo,
        //                     delEstado:0,
        //                     delFactura: item.delFactura,
        //                     delFecha: item.delFecha,
        //                     delMedico: item.delMedico,
        //                     delValorRegistrado: item.delValorRegistrado,
        //                     detTipo: item.detTipo,
        //                     detalle: item.detalle,
        //                     name: item.name,
        //                     preCodigo: item.preCodigo,
        //                 }
        //                 newDatos[key] = aux
        //             }
        //         })
        //     }
            
        //     setDatosFormularios(newDatos);
    
        // }
    }
    const getDatos=(indice, callbackData,preCodigo,talonario,fecha,monto,factura,delCodigo,estado)=>{
        console.log(fecha);
        let nuevaPrestacion = {
            preCodigo: preCodigo,
            detalle: talonario,
            delFecha: fecha,
            delValorRegistrado: monto,
            delFactura: factura,
            delCodigo: delCodigo,
            delEstado: estado
        };

        console.log(nuevaPrestacion);

        let dataAux = {...data[indice], ...callbackData};

        dataAux = {
            ...dataAux, 
            ...nuevaPrestacion
        }

        let dataFinal = [...data];

        dataFinal[indice] = dataAux;
        console.log(dataFinal);
        setData(dataFinal);
    
    }
    const handleSave= ()=> {
        const arregloTrama = Object.values(data);
        if (props.detCie10===null && props.detTipoLiq !== 'TIPLQRE') {
            displayMessage.error('Seleccione un CIE10');
        } else if(data.length === 0 || arregloTrama.length === 0 ){
            displayMessage.error('Seleccione alguna prestación');
        } else {
            const t1=[]
            const t2=[]
            //const tramaFinal1 = []

            arregloTrama.map((trama)=>{
                if(trama.delEstado === 0){
                    const tramaEnviar=
                    {
                        delCodigo:trama.delCodigo,
                        preCodigo:parseInt(trama.preCodigo),
                        liqCodigo:parseInt(props.liquidacion),
                        copCodigo:trama.copCodigo,
                        detTipo:trama.detTipo,
                        detCie10: props.detCie10,
                        delMedico:trama.delMedico,
                        delFacClaveAcceso: trama.delFacClaveAcceso,
                        delFacAutorizacion:trama.delFacAutorizacion,
                        delServicio:trama.delServicio,
                        delFecha:trama.delFecha,
                        delValorRegistrado:parseFloat(trama.delValorRegistrado),
                        //delCopago:parseFloat(trama.copago),
                        delFactura:trama.delFactura,
                        detalle:trama.detalle,
                        delEstado:trama.delEstado
                    }
                    t1.push(tramaEnviar)
                }else{
                    const tramaEnviar=
                {
                    delCodigo:trama.delCodigo,
                    preCodigo:parseInt(trama.preCodigo),
                    liqCodigo:parseInt(props.liquidacion),
                    copCodigo:trama.copCodigo,
                    detTipo:trama.detTipo,
                    detCie10: props.detCie10,
                    delMedico:trama.delMedico,
                    delFacClaveAcceso: trama.delFacClaveAcceso,
                    delFacAutorizacion:trama.delFacAutorizacion,
                    delServicio:trama.delServicio,
                    delFecha:trama.delFecha,
                    delValorRegistrado:parseFloat(trama.delValorRegistrado),
                    //delCopago:parseFloat(trama.copago),
                    delFactura:trama.delFactura,
                    detalle:trama.detalle,
                    delEstado:trama.delEstado
                }
                    t2.push(tramaEnviar)
                    return 0;
                }
            })
            const tramaFinal1 = t1.concat(t2)
            console.log(tramaFinal1)
            if(t2.length === 0){
                displayMessage.error('Seleccione una prestacion')
                setMenss(false);
            }else{

                setMenss(true)
                let bool = true;
                let errorArray = [];

                for (let i = 0 ; i < tramaFinal1.length; i++ ){
                    if(tramaFinal1[i].delEstado !==0){
                        const errorObject = {
                            preCodigo : tramaFinal1[i].preCodigo === 0 || tramaFinal1[i].preCodigo === undefined || isNaN(tramaFinal1[i].preCodigo),
                            detalle : tramaFinal1[i].detalle.length === 0,
                            delMedico : tramaFinal1[i].delMedico === undefined || tramaFinal1[i].delMedico === '',
                            delValorRegistrado :  tramaFinal1[i].delValorRegistrado === 0,
                            detTipo : tramaFinal1[i].detTipo === null,
                            copCodigo : tramaFinal1[i].copCodigo === null,
                            delFactura : tramaFinal1[i].delFactura === ''
                        }
                        errorArray.push(errorObject);
                    }else{
                        const errorObject = {
                            preCodigo : false,
                            detalle :false,
                            delMedico :false ,
                            delValorRegistrado :false ,
                            detTipo : false,
                            copCodigo : false,
                            delFactura : false
                        }
                        errorArray.push(errorObject);
                    }
                }
                setErrorState(errorArray);
        
                bool = errorArray.map((value) =>{
                    return Object.values(value).some((even) => even)
                });
                if(!bool.some((even) => even)){       
                    setDisableSave(false); 
                    setTramaFinal(tramaFinal1)          
                    setGuardar(true);
                }else{
                    displayMessage.error('Complete los campos');
                    setGuardar(false)
                }
            }
        }

    }

    const handleSend= ()=> {
        
        const arregloTrama = Object.values(data);
        if (props.detCie10===null && props.detTipoLiq !== 'TIPLQRE') {
            displayMessage.error('Seleccione un CIE10');
        } else if(data.length === 0 || arregloTrama.length === 0 ){
            displayMessage.error('Seleccione alguna prestación');
        } else {
            const t1=[]
            const t2=[]
            //const tramaFinal1 = []

            arregloTrama.map((trama)=>{
                if(trama.delEstado === 0){
                    const tramaEnviar=
                    {
                        delCodigo:trama.delCodigo,
                        preCodigo:parseInt(trama.preCodigo),
                        liqCodigo:parseInt(props.liquidacion),
                        copCodigo:trama.copCodigo,
                        detTipo:trama.detTipo,
                        detCie10: props.detCie10,
                        delMedico:trama.delMedico,
                        delFacClaveAcceso: trama.delFacClaveAcceso,
                        delFacAutorizacion:trama.delFacAutorizacion,
                        delServicio:trama.delServicio,
                        delFecha:trama.delFecha,
                        delValorRegistrado:parseFloat(trama.delValorRegistrado),
                        //delCopago:parseFloat(trama.copago),
                        delFactura:trama.delFactura,
                        detalle:trama.detalle,
                        delEstado:trama.delEstado
                    }
                    t1.push(tramaEnviar)
                }else{
                    const tramaEnviar=
                {
                    delCodigo:trama.delCodigo,
                    preCodigo:parseInt(trama.preCodigo),
                    liqCodigo:parseInt(props.liquidacion),
                    copCodigo:trama.copCodigo,
                    detTipo:trama.detTipo,
                    detCie10: props.detCie10,
                    delMedico:trama.delMedico,
                    delFacClaveAcceso: trama.delFacClaveAcceso,
                    delFacAutorizacion:trama.delFacAutorizacion,
                    delServicio:trama.delServicio,
                    delFecha:trama.delFecha,
                    delValorRegistrado:parseFloat(trama.delValorRegistrado),
                    //delCopago:parseFloat(trama.copago),
                    delFactura:trama.delFactura,
                    detalle:trama.detalle,
                    delEstado:trama.delEstado
                }
                    t2.push(tramaEnviar)
                    return 0;
                }
            })
            const tramaFinal1 = t1.concat(t2)
            console.log(tramaFinal1)
            if(t2.length === 0){
                displayMessage.error('Seleccione una prestacion')
                setMenss(false);
            }else{
                setMenss(true);
                let bool = true;
                let errorArray = [];

                for (let i = 0 ; i < tramaFinal1.length; i++ ){
                    if(tramaFinal1[i].delEstado !==0){
                        const errorObject = {
                            preCodigo : tramaFinal1[i].preCodigo === 0 || tramaFinal1[i].preCodigo === undefined || isNaN(tramaFinal1[i].preCodigo),
                            detalle : tramaFinal1[i].detalle.length === 0,
                            delMedico : tramaFinal1[i].delMedico === undefined || tramaFinal1[i].delMedico === '',
                            delValorRegistrado :  tramaFinal1[i].delValorRegistrado === 0,
                            detTipo : tramaFinal1[i].detTipo === null,
                            copCodigo : tramaFinal1[i].copCodigo === null,
                            delFactura : tramaFinal1[i].delFactura === ''
                        }
                        errorArray.push(errorObject);
                    }else{
                        const errorObject = {
                            preCodigo : false,
                            detalle :false,
                            delMedico :false ,
                            delValorRegistrado :false ,
                            detTipo : false,
                            copCodigo : false,
                            delFactura : false
                        }
                        errorArray.push(errorObject);
                    }
                }
                setErrorState(errorArray);
        
                bool = errorArray.map((value) =>{
                    return Object.values(value).some((even) => even)
                });
                if(!bool.some((even) => even)){       
                    setDisableSave(false); 
                    setTramaFinal(tramaFinal1)          
                    setEnviar(true);
                }else{
                    displayMessage.error('Complete los campos');
                    setEnviar(false)
                }
            }
        }

    }

    const saveData = () => {
        setDisableSave(true);
        let tramaLiquidacion = [{
            liqCodigo:parseInt(props.liquidacion),
            liqObservacion: observacion,
            liqFechaIncurrencia: props.liqFechaIncurrencia
        }];
        const tramaEditar=[]  
        const tramaNueva = []
                
        tramaFinal.map((datos) =>{
                   
            if(datos.delCodigo !== 0){
                const nueva = []
                const detalleViejo = [];
                //const detalleNuevo = [];
                datos.detalle.map((value) =>{ 
                    if(value.talCodigo !== 0 && value.talCodigo !== undefined){

                        const datosTalonario = {
                            delCodigo:value.delCodigo,
                            talCodigo:value.talCodigo,
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        detalleViejo.push(datosTalonario)
                        console.log(datosTalonario)
                    }else{
                               
                        const datosTalonario = {
                            delCodigo:value.delCodigo,
                            //talCodigo:value.talCodigo,
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        nueva.push(datosTalonario)
                        console.log(datosTalonario)

                    }
                    return 0;
                })
                if(nueva.length !== 0){
                    setDisableSave(true);
                    prestaciones.ingresoTalonario(1,nueva)
                        .then(
                            data => {
                                if(data.error.codigo==='0'){
                                    console.log(data)
                                    setDisableSave(false);
                                } else {
                                    setDisableSave(false);
                                }                                                                                            
                            },
                            () => {          
                                displayMessage.error('Error de comunicación.');
                                setDisableSave(false);
                            }

                        );  
                    console.log(nueva)
                }
                const tramaEnviar= {
                    copCodigo:datos.copCodigo,
                    delCodigo:datos.delCodigo,
                    delEstado:datos.delEstado,
                    delFactura:datos.delFactura,
                    delFecha:datos.delFecha,
                    delMedico:datos.delMedico,
                    delFacClaveAcceso: datos.delFacClaveAcceso,
                    delFacAutorizacion:datos.delFacAutorizacion,
                    delServicio:datos.delServicio,
                    delValorRegistrado:parseFloat(datos.delValorRegistrado),
                    detCie10: props.detCie10,
                    detTipo:datos.detTipo,
                    detalle:detalleViejo,
                    liqCodigo:parseInt(props.liquidacion),
                    preCodigo:parseInt(datos.preCodigo),                           
                    //delCopago:parseFloat(trama.copago),

                           
                            
                           
                }
                tramaEditar.push(tramaEnviar)
                return 0;
            }else{
                const detalleNuevo = []; 
                console.log(datos)
                datos.detalle.map((value) =>{
                            
                    if(value.delCodigo === 0 ){
                        const datosNew = {
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        detalleNuevo.push(datosNew)
                        console.log(datosNew)
                    }
                    return 0;
                })
                const tramaEnviar={
                   
                    preCodigo:parseInt(datos.preCodigo),
                    liqCodigo:parseInt(props.liquidacion),
                    copCodigo:datos.copCodigo,
                    detTipo:datos.detTipo,
                    detCie10: props.detCie10,
                    delMedico:datos.delMedico,
                    delFacClaveAcceso: datos.delFacClaveAcceso,
                    delFacAutorizacion:datos.delFacAutorizacion,
                    delServicio:datos.delServicio,
                    delFecha:datos.delFecha,
                    delValorRegistrado:parseFloat(datos.delValorRegistrado),
                    //delCopago:parseFloat(trama.copago),
                    delFactura:datos.delFactura,
                    detalle:detalleNuevo,
                    delEstado:datos.delEstado
                }
                tramaNueva.push(tramaEnviar)
                return 0;
            }
        })      
        if(tramaEditar.length !== 0){
            const dataAntigua = []
            props.data.map((item)=>{
                const aux ={
                    copCodigo: item.copCodigo,
                    delCodigo: item.delCodigo,
                    delEstado: item.delEstado,
                    delFactura: item.delFactura,
                    delFecha: (item.delFecha.substring(0,10)),
                    delMedico: item.delMedico,
                    delFacClaveAcceso: item.delFacClaveAcceso,
                    delFacAutorizacion:item.delFacAutorizacion,
                    delServicio: item.delServicio,
                    delValorRegistrado: item.delValorRegistrado,
                    detCie10: item.detCie10,
                    detTipo: item.detTipo,
                    detalle: item.detalle,
                    liqCodigo: item.liqCodigo,
                    preCodigo: item.preCodigo}
                dataAntigua.push(aux)
            })
            setDisableSave(true);                   
            prestaciones.updateLiquidacion(tramaLiquidacion)
                .then(
                    data => {
                        if(data.error.codigo==='0'){                            
                            edit(2,tramaEditar)
                        }                        
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
                        setDisableSave(false);
                    }

                );     
            console.log('Editar',tramaEditar)
        }    
        if(tramaNueva.length !== 0){
            setDisableSave(true);
            prestaciones.updateLiquidacion(tramaLiquidacion)
                .then(
                    data => {
                        if(data.error.codigo==='0'){                            
                            edit(1,tramaNueva)
                        }                        
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
                        setDisableSave(false);
                    }

                );     
            console.log('Nueva',tramaNueva)
        }              
               
        setGuardar(false)
                               
        console.log(data)
    }

    const sendData = () => {
        setDisableSave(true);             
        let tramaLiquidacion = [{
            liqCodigo:parseInt(props.liquidacion),
            liqObservacion: observacion,
            liqFechaIncurrencia: props.liqFechaIncurrencia
        }];
        const tramaEditar=[]  
        const tramaNueva = []
                
        tramaFinal.map((datos) =>{
                   
            if(datos.delCodigo !== 0){
                const nueva = []
                const detalleViejo = [];
                //const detalleNuevo = [];
                datos.detalle.map((value) =>{ 
                    if(value.talCodigo !== 0 && value.talCodigo !== undefined){

                        const datosTalonario = {
                            delCodigo:value.delCodigo,
                            talCodigo:value.talCodigo,
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        detalleViejo.push(datosTalonario)
                    }else{
                               
                        const datosTalonario = {
                            delCodigo:value.delCodigo,
                            //talCodigo:value.talCodigo,
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        nueva.push(datosTalonario)

                    }
                    return 0;
                })
                if(nueva.length !== 0){
                    setDisableSave(true);
                    prestaciones.ingresoTalonario(1,nueva)
                        .then(
                            data => {
                                if(data.error.codigo==='0'){
                                    console.log(data)
                                    setDisableSave(false);
                                } else {
                                    setDisableSave(false);
                                }                                                                                            
                            },
                            () => {          
                                displayMessage.error('Error de comunicación.');
                                setDisableSave(false);
                            }

                        );  
                }
                const tramaEnviar= {
                    delCodigo:datos.delCodigo,
                    preCodigo:parseInt(datos.preCodigo),
                    liqCodigo:parseInt(props.liquidacion),
                    copCodigo:datos.copCodigo,
                    detTipo:datos.detTipo,
                    detCie10: props.detCie10,
                    delMedico:datos.delMedico,
                    delFacClaveAcceso: datos.delFacClaveAcceso,
                    delFacAutorizacion:datos.delFacAutorizacion,
                    delServicio:datos.delServicio,
                    delFecha:datos.delFecha,
                    delValorRegistrado:parseFloat(datos.delValorRegistrado),
                    //delCopago:parseFloat(trama.copago),
                    delFactura:datos.delFactura,
                    detalle:detalleViejo,
                    delEstado:datos.delEstado
                }
                tramaEditar.push(tramaEnviar)
                return 0;
            }else{
                const detalleNuevo = []; 
                datos.detalle.map((value) =>{
                            
                    if(value.delCodigo === 0){
                        const datosNew = {
                            talEstado:value.talEstado,
                            talDescripcion:value.talDescripcion,
                            talCantidad:value.talCantidad,
                            talValorRegistrado:parseFloat(value.talValorRegistrado)
                        }
                        detalleNuevo.push(datosNew)
                    }
                    return 0;
                })
                const tramaEnviar={
                   
                    preCodigo:parseInt(datos.preCodigo),
                    liqCodigo:parseInt(props.liquidacion),
                    copCodigo:datos.copCodigo,
                    detTipo:datos.detTipo,
                    detCie10: props.detCie10,
                    delMedico:datos.delMedico,
                    delFacClaveAcceso: datos.delFacClaveAcceso,
                    delFacAutorizacion: datos.delFacAutorizacion,
                    delServicio:datos.delServicio,
                    delFecha:datos.delFecha,
                    delValorRegistrado:parseFloat(datos.delValorRegistrado),
                    //delCopago:parseFloat(trama.copago),
                    delFactura:datos.delFactura,
                    detalle:detalleNuevo,
                    delEstado:datos.delEstado
                }
                tramaNueva.push(tramaEnviar)
                return 0;
            }
        })      
        setSend1(false);
        setSend2(false);
        if(tramaEditar.length !== 0){
            console.log(tramaEditar)
            setDisableSave(true);
            prestaciones.updateLiquidacion(tramaLiquidacion)
                .then(
                    data => {
                        if(data.error.codigo==='0'){     
                                                           
                            sendTrama1(2,tramaEditar)
                        }                        
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
                        setDisableSave(false);
                    }

                );     
        } else {
            setSend1(true);
        }
        if(tramaNueva.length !== 0){
            console.log(tramaNueva)
            setDisableSave(true);
            prestaciones.updateLiquidacion(tramaLiquidacion)
                .then(
                    data => {
                        if(data.error.codigo==='0'){                            
                            sendTrama2(1,tramaNueva)
                        }                        
                    },
                    () => {          
                        displayMessage.error('Error de comunicación.');
                        setDisableSave(false);
                    }

                );     
        } else {
            setSend2(true);
        }
        
        setGuardar(false)
        
    }

    useEffect(() => {
        console.log('LLEGP')
        if (send1 && send2) {
            changeState();
        }
    },[send1, send2])

    const edit = (tipo,datos) => {
        setDisableSave(true);
        prestaciones.ingresoDetalleLiquidacion(tipo, datos)
            .then(
                data => {
                    if(data.error.codigo==='0'){
                    //displayMessage.success('Datos Ingresados correctamente')
                        //cleanState();
                        //changeState();
                        window.location.reload();
                        setDisableSave(false);                        
                        displayMessage.success('Liquidación guardada')
                    } else {
                        setDisableSave(false);
                    }                                                                                            
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
                    setDisableSave(false);
                }

            );      
    }

    const send = (tipo,datos) => {
        setDisableSave(true);
        prestaciones.ingresoDetalleLiquidacion(tipo, datos)
            .then(
                data => {
                    if(data.error.codigo==='0'){
                    //displayMessage.success('Datos Ingresados correctamente')
                       
                        //changeState();
                        //window.location.href('/home')
                        setDisableSave(false);
                    } else {
                        setDisableSave(false);
                    }                                                                                            
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
                    setDisableSave(false);
                }

            );      
    }

    const sendTrama1 = (tipo,datos) => {
        setDisableSave(true);
        prestaciones.ingresoDetalleLiquidacion(tipo, datos)
            .then(
                data => {
                    if(data.error.codigo==='0'){
                    //displayMessage.success('Datos Ingresados correctamente')
                       
                        //changeState();
                        //window.location.href('/home')
                        setSend1(true);
                        setDisableSave(false);
                    } else {
                        setDisableSave(false);
                    }                                                                                            
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
                    setDisableSave(false);
                }

            );      
    }

    const sendTrama2 = (tipo,datos) => {
        setDisableSave(true);
        prestaciones.ingresoDetalleLiquidacion(tipo, datos)
            .then(
                data => {
                    if(data.error.codigo==='0'){
                    //displayMessage.success('Datos Ingresados correctamente')
                       
                        //changeState();
                        //window.location.href('/home')
                        setSend2(true);
                        setDisableSave(false);
                    } else {
                        setDisableSave(false);
                    }                                                                                            
                },
                () => {          
                    displayMessage.error('Error de comunicación.');
                    setDisableSave(false);
                }

            );      
    }

    const changeState = () => {
        const estadoss = [
            {
                'eslMensaje': 'Tú liquidación fue enviada hacia Auditoría Médica para la respectiva revisión.'
            }];
        prestaciones.requestUpdate([], '',parseInt(props.liquidacion),'',estadoss)
            .then(
                data => {
                    if(data.error.codigo==='0'){
                        displayMessage.success('Datos Ingresados correctamente')
                        window.location.href ='/liquidador/liquidaciones/digitalizar'
                        cleanState();
                    }
                },
                () => {          
                    displayMessage.error('Error de comunicación.');

                }

            ); 
    }

    const cleanState = () => {
        setDatosFormularios({});
        setData([]);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleEditClose = () => {
        setOpen(false);
    };

    const prestacionesAct = () => {        
        if (data.length === 0 || menss ===false) {
            return true;
        }

        let dataEnabled = data.filter((item) => item.delEstado === 1 );

        if (dataEnabled.length === 0) {
            return true;
        }
        
        return false;
    }
  


    return(<div className="margenes">
        <br/>                
        <Grid            
            container
            spacing={2}
        >
            {crearPrestador &&
            <Grid
                //className={classes.item}
                item
                md={3}
                sm={6}
                xs={12}
            >
                
                <Button
                    color="primary"
                    onClick={handleOpen}
                    variant="contained"
                >
                            Agregar Prestador
                </Button>
            </Grid> 
            }
            <Grid
                //className={classes.item}
                item
                md={3}
                sm={6}
                xs={12}
            >            
                <Button
                    color="primary"                
                    onClick={addPrestacion}
                    variant="contained"
                >
                            Agregar prestación 
                </Button>
            </Grid>
        </Grid>
        <br/>
        <Card  style={{ padding: '1em' }}>       
            {data.map((item,index) =>
            {
                if (item.delEstado) {
                    return (
                        <FormGenericDigitalize
                            data={item}
                            dataPrevia={item}
                            datos={getDatos.bind(this)}
                            datosPrestaciones={datosPrestaciones}
                            //errorCopago = {errorState[index] !== undefined ?errorState[index].delCopago :  false}
                            detTipoLiq={props.detTipoLiq}
                            errorFactura = {errorState[index] !== undefined ?errorState[index].delFactura : false}
                            errorMedico = {errorState[index] !== undefined ?errorState[index].delMedico : false}
                            errorMonto = {errorState[index] !== undefined ?errorState[index].detalle : false}
                            errorPrestacion = {errorState[index] !== undefined ? errorState[index].copCodigo : false}
                            errorRuc = {errorState[index] !== undefined ?errorState[index].preCodigo : false}
                            errorValor = {errorState[index] !== undefined ?errorState[index].delValorRegistrado : false}
                            indice={index}
                            key={'INDICE_' + index + '_' + contador}
                            liquidacion={props.liquidacion}
                            onRemove ={() => _remove(index)}
                            plaCodigo={plaCodigo}
                            test={index}
                            indice={index}
                        />
                    )
                } else {
                    return null;
                }
            })
            }
            {prestacionesAct() ? 
                <Typography
                    display="block"
                    gutterBottom
                    variant="h5"
                >
                Agregar prestaciones...
                </Typography>:'' }

        </Card>
        <br/>
        <Grid
            item
            xs={12}
        > 
            <Card>
                
                <CardHeader title="Observación" />
                <Divider />
                <CardContent>
                    <Grid
                        container
                        spacing={6}                            
                    >                                
                        <Grid
                            container
                            item
                            xs={12}                                                
                        >
                            <TextField
                                fullWidth                                        
                                id="outlined-multiline-static"
                                multiline                
                                onChange={(e) => {setObservacion(e.target.value);}}
                                rows={5}
                                value={observacion}
                                variant="outlined"
                            />                                    
                        </Grid>  
                    </Grid>
                </CardContent>                                    
            </Card>
        </Grid>
        <br/>

        <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
        >
            Guardar
        </Button>    
        <Button
            color="primary"
            onClick={handleSend}
            style={{marginLeft:10}}
            variant="contained"
        >
            Enviar Auditoría
        </Button>    
            
        <ModalAddLender
            detTipoLiq={props.detTipoLiq}
            onClose={handleEditClose}            
            open={open}
        />

        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleCloseConfirmation}
            open={guardar}
        >
            <DialogTitle id="alert-dialog-title">{'Guardar'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>¿Está seguro de guardar la liquidación?</b>    
                    <br />
                    Por favor validar que la información se encuentre completa.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="primary"
                    onClick={handleCloseConfirmation}
                >
                    Cancelar
                </Button>
                <Button 
                    autoFocus
                    color="primary"
                    disabled={disableSave}
                    onClick={()=>saveData()}
                >
                    Sí
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={handleCloseConfirmation1}
            open={enviar}
        >
            <DialogTitle id="alert-dialog-title">{'Enviar'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <b>¿Está seguro de enviar la liquidación para auditoría?</b>    
                    <br />
                    Por favor validar que la información se encuentre completa.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleCloseConfirmation1}
                >
                Cancelar
                </Button>
                <Button
                    autoFocus
                    color="primary"
                    disabled={disableSave}
                    onClick={()=>sendData()}                   
                >
                Si, Enviar
                </Button>
            </DialogActions>
        </Dialog>      
    </div>        
    )
}



export default BenefitsView;