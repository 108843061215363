/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { QueriesProvider } from 'providers/queries.service';
import { DisplayMessage } from 'notifications/displayMessage';
import { CircularProgress, Card, Grid, Tooltip, Button } from '@material-ui/core';
import { SearchBasic } from 'components';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    actions: {
        padding: theme.spacing(1),
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    }
}));



export default function SearchAffiliate() {

    const [data, setData] = useState([]);
    const queries = new QueriesProvider();
    const displayMessage = new DisplayMessage();

    const [columns] = useState([
        { title: 'Cédula', field: 'PER_IDENTIFICACION' },
        { title: 'Nombre', field: 'PER_NOMBRES' },
        { title: 'Apellidos', field: 'PER_APELLIDOS' },
        { title: 'Fecha Registro', field: 'PER_FECHA_REGISTRO' },
        { title: 'Monto', field: 'PEP_MONTO_COBERTURA' },
        { title: 'Parentesco', field: 'DET_NOMBRE' },
        { title: 'Motivo cancelación', field: 'POL_DESCRIPCION' },
        { title: 'Fecha cancela', field: 'POL_FECHA_CANCELA' }
    ]);

    const [buscarTitular, setBuscarTitular] = useState('');

    const handleBuscarTitular = () => {
        setData(0);
        if (buscarTitular === '') {
            displayMessage.error('Ingrese un valor a buscar.');
            setData([]);
        } else {
            queries.reporteTitularDependientesCancelados(buscarTitular)
                .then(
                    user => {
                        if (user.error.codigo === '0') {
                            setData(user.bodyOut.data);
                        } else {
                            setData([]);
                        }


                    },
                    () => {
                        displayMessage.error('Error de comunicación.');

                    }
                );
        }
    }
    const handleChange = (e) => {
        setBuscarTitular(e.target.value);
    }

    const handleLimpiar = () => {
        setBuscarTitular('');
        setData([]);
    }


    const dataReportsExport = (typeResult,) => {
        queries.dataReportsExportTitularDependientesCancelados(typeResult, buscarTitular).then(response => {
            if (response.error.codigo === '0' && response.bodyOut.data.length) {
                downloadFile(response.bodyOut.data, typeResult);
            }
        },
            () => {
                displayMessage.error('Error de comunicación.');
            });
    };

    const downloadFile = (data, typeFile) => {

        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].TITULAR_DEPENDIENTE_CANCELADOS}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link)
        link.click();
        document.body.removeChild(link)
    }

    return (
        <div>
            <br />
            <SearchBasic
                searchBar={buscarTitular}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChange}
                complementaryMessage='Buscar'
                handleSearch={handleBuscarTitular}
            />
            <br />


            {data ?
                data.length ?
                    <>
                        <Grid
                            container
                            spacing={2}
                        >                            
                            <Grid
                                item                            
                                xs={12}
                                style={{ paddingLeft: 1, textAlign: 'right', marginBottom:10}}
                            >
                                <Tooltip title="Exportar a CSV">
                                    <Button
                                        style={{ borderRadius: 5, marginRight: 10,  width: 100 }}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => { dataReportsExport(2); }}
                                    >

                                        <FaFileCsv size={20} style={{ marginRight: 10 }} /> CSV
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Exportar a EXCEL">
                                    <Button
                                        style={{ borderRadius: 5,  width: 100}}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={() => { dataReportsExport(3); }}
                                    >
                                        <FaFileExcel size={20} style={{ marginRight: 10 }} /> EXCEL
                                    </Button>
                                </Tooltip>

                            </Grid>                            
                        </Grid>
                        <Card>
                            <MaterialTable
                                columns={columns}

                                data={data}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        search: false,
                                        exportName: 'Exportar a CSV',
                                        exportAriaLabel: 'Exportar',
                                        exportTitle: 'Exportar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'Filas',
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsPerPage: 'filas'


                                    }
                                }}
                                options={{
                                    exportButton: false,
                                    search: false,
                                    toolbar: false,
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE',
                                    }),
                                }}
                                title=""
                            />
                        </Card> </> : ''
                : <CircularProgress />}
        </div>
    );
}