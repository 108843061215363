import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import UserState from 'context/Users/userState';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import { ScrollReset } from './components';
import './assets/scss/index.scss';

const history = createBrowserHistory();
const store = configureStore();

const App = () => {
    return (
        <StoreProvider store={store}>
            <UserState>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Router history={history}>
                            <ScrollReset />
                            {renderRoutes(routes)}
                        </Router>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </UserState>
        </StoreProvider>
    );
};

export default App;
