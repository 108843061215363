import React, { Component } from 'react';
import { Page } from 'components';
import { forms } from './forms';
import MessagesForm from './../../views/MessagesDetails/components/MessagesForm';
import {Typography, Grid, Card, CardContent, CardHeader, Divider} from '@material-ui/core';
import { DetalleCatalogoProvider } from './../../providers/detalleCatalogo.service';
import './styles.css';
import { PersonalProvider } from './../../providers/persona.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import BenefitsView from './BenefitsView';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { withStyles } from '@material-ui/styles';
import { LiquidationProvider  } from 'providers/liquidation.service';
import { DocumentDigitalizeProvider } from 'providers/documentsDigitalize.service';
import { Header, LiquidationInfo, LiquidationDocuments } from 'views/AuditDetails/components';
import ModallSearchCie10  from 'views/RefundForm/components/Results/ModlalSeartchCie10/ModallSearchCie10';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import { DisplayMessage } from 'notifications/displayMessage';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

import { 
    Button, 
    colors, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    TextField
} from '@material-ui/core';

const styles = theme => ({        
    deleteButton: {
        color: theme.palette.white,
        backgroundColor: colors.red[600],
        '&:hover': {
            backgroundColor: colors.red[900]
        }
    },
    deleteIcon: {
        marginRight: theme.spacing(1)
    },
    root: {
        padding: theme.spacing(3)
    },

});

class DigitizeView extends Component {
    constructor(props) {
        super(props);
        var decryptedData = atob(props.match.params.id);
        
        const  { session } = props;
        this.handleSubmit = this.handleSubmit.bind(this);
        
        this.state = {
            forms: null,
            idRequirement: decryptedData,
            showRequirement: false,
            formSelected: null,
            messageList: [],
            data:[],
            allForms: null,
            searchWord: '',
            userRequirement: null,
            documents: [],
            session: session,
            liqPerPol:null,
            beneficiary: null,
            datosComboPrestaciones:0,
            prestaciones:'',
            mostrarForm:false,
            count:0,
            arrayDatos: [],
            detTipoLiq:'',
            open: false,
            loading: false,
            motivo: '',          
            loadingDoc: true,
            existenciaReembolso: 0,
            dataFormulario: null,
            detCie10: null,
            openCie10:false,
            buscarCie10: '',
            errorCie10: false,
            detalleLiquidacion: null,
            calendarTrigger: false,
            values: {startDate: moment()}   
        }

        this.dCP = new DetalleCatalogoProvider();
        this.pP = new PersonalProvider();
        this.liquidationProvider = new LiquidationProvider();  
        this.prestaciones = new DocumentDigitalizeProvider();
        this.displayMessage = new DisplayMessage();
        this.archivo = null;
    }

    componentDidMount = () => {
        this.getLiqperpol(this.state.idRequirement);
        //this.getDocuments(this.state.idRequirement);  
    }    

    handleCalendarOpen = trigger => {
        this.setState({calendarTrigger: true});
    };

    handleCalendarChange = () => {
        //setValues()
    };

    handleCalendarAccept = date => {
        this.setState({values:{startDate: date}});
    };
  
    handleCalendarClose = () => {
        this.setState({calendarTrigger: false});
    };
    

    /* const calendarOpen = Boolean(calendarTrigger);
    calendarTrigger === 'startDate'
        ? moment()
        : moment(values.startDate).add(1, 'day'); */

    ////////VER DETALLELIQUIDACION
    getDetalleLiquidacion = () =>{
        console.log(this.state.liqPerPol.detNemonicoTipo);
        this.liquidationProvider.datosDetalleLiquidacion(this.state.idRequirement)
            .then(
                data => {
                    this.setState({detalleLiquidacion:data.bodyOut.data});
                    if(this.state.liqPerPol.detNemonicoTipo !== 'TIPLQRE' && data.bodyOut.data.length){
                        this.getCie10Datos(data.bodyOut.data[0].detCie10);
                    }
                },
                () => {          
                //displayMessage.error('Error de comunicación.');

                }

            );
    }

    getCie10Datos = (codigo) =>{
        this.liquidationProvider.getDetalleCatalogo(codigo)
            .then(
                data => {
                    //this.setState({detalleLiquidacion:data.bodyOut.data});       
                    this.setState({buscarCie10:data.bodyOut.data[0].detDescripcion, detCie10:data.bodyOut.data[0].detCodigo, errorCie10:false})                             
                },
                () => {          

                }
            );
    }
    /*  useEffect(()=>{
        prestaciones.datosDetalleLiquidacion(this.state.)
            .then(
                data => {
                    setPlaCodigo(data.bodyOut.data[0].plaCodigo);
                },
                () => {          
                    displayMessage.error('Error de comunicación.');

                }

            );
    }, []) */
    //////

    // getDocuments = (liqCode) => {
    //     this.liquidationProvider.liquidacionDocuments(liqCode).then(response => {            
    //         if(response.error.codigo === '0' && response.bodyOut.data.length){
    //             this.setState({
    //                 documents: response.bodyOut.data,
    //                 loadingDoc: false
    //             });            
                
    //         } else {
    //             this.setState({
    //                 documents: [],
    //                 loadingDoc: false
    //             }); 
    //         }                  
    //     },
    //     () => {          
    //         this.displayMessage.error('Error de comunicación.');
    //         this.setState({
    //             documents: [],
    //             loadingDoc: false
    //         }); 
    //     }); 
    // };

    getFormulario = (liqCode) => {
        this.prestaciones.datosFormularioReembolso(liqCode).then(response => {
            if(response.error.codigo === '0' && response.bodyOut.data.length){
                this.setState({
                    dataFormulario: response.bodyOut.data[0],
                    detCie10: response.bodyOut.data[0].detCie10,
                    existenciaReembolso: response.bodyOut.data[0].forCodigo
                });                            
            } else {
                this.setState({
                    dataFormulario: null,
                    detCie10: null,
                    existenciaReembolso: 0
                }); 
            }               
        });
    }

    /* 
    arrayDocuments = (docs) => {
        const arr = [];
        return new Promise((resolve, reject) => {
            
            docs.map(doc => {                
                arr.push({
                    detNombre: doc.docNombre.toUpperCase(),
                    detDestino: doc.docNombre,
                    archivo: doc.documento

                })
                return [];
            });

            setTimeout(
                () => arr.length > 0
                    ? resolve(arr)
                    : reject(new Error('No se encuentran datos')),
                2000
            );

        }).then((res) => {
            const toReturn = res;
            return toReturn;
        });        
    } */

    handleSubmit(event) {
        event.preventDefault();
        console.log(event.target.id);
        
        const file = this.archivo.files[0];
    
        const reader = new FileReader();
    
        reader.readAsText(file);
    
        reader.onloadend = evt => {
            const readerData = evt.target.result;
        
            const parser = new DOMParser();
            const xml = parser.parseFromString(readerData, "text/xml");
            const data = new XMLSerializer().serializeToString(xml.documentElement);
            let posicion = data.indexOf('<claveAcceso>');
            let posicion1 = data.indexOf('</claveAcceso>');
            if (posicion > 0){
                let claveAcceso = data.substring(posicion+13, posicion1 );
                console.log(data);
                console.log(claveAcceso);            
                console.log(posicion);
                console.log(posicion1);
            } else {
                alert('Archivo no válido');
            }
        };
    }

    getLiqperpol = (liqCodigo) => {
        this.pP.LiqperpolByliqCodigo(liqCodigo).then((res)=>{
            this.setState({
                liqPerPol: res[0]
            });
            this.getRequirement();
            this.getDetalleLiquidacion();
            if(res[0].detNemonicoTipo === 'TIPLQRE'){
                this.getFormulario(liqCodigo);
            } else {
                this.setState({
                    existenciaReembolso: 1
                })
            }           
            return res[0].perBeneficiario;
        }).then((response)=>{
            this.getPersonByPerCodigo(response);
        });
    }

    getPersonByPerCodigo = (perCodigo) => {
        this.pP.personByPerCodigo(perCodigo).then((res)=>{
            this.setState({
                beneficiary: res[0]
            });
        });
    }
    
    getRequirement = () => {
        this.pP.LiquidationByliqCodigo(this.state.idRequirement).then((resp) => {
            this.setState({                
                userRequirement: resp[0],
                detTipoLiq:resp[0].tipoNemonico,
                values: { startDate: moment(resp[0].liqFechaIncurrencia ? resp[0].liqFechaIncurrencia : moment() )}
            })
            return resp[0].detTipo
        })
        /* .then((res) => {this.pP.documentsByLiqCodigo(this.state.idRequirement).then(
            documents => {
                this.arrayDocuments(documents).then(async(setDocuments) => {
                    this.setState({
                        forms: await forms(res, setDocuments, parseInt(this.state.idRequirement), 17173),
                    })
                    return '';
                }).then(() => {
                    this.findAllForms(this.state.searchWord);
                })
            });
        }); */
    }

    handleSearchChange = (evt) => {
        this.setState({
            searchWord: evt.target.value
        });
        this.findAllForms(evt.target.value);
    }

    findAllForms = (v) => {        
        const ArrayAux = [];
        new Promise((resolve, reject) => {
            this.state.forms.map((element) => {                
                if (element.detNombre.includes(v.toUpperCase()) === true) {
                    ArrayAux.push(element);
                }
                return null;
            });

            setTimeout(
                () => ArrayAux.length > 0
                    ? resolve(ArrayAux)
                    : reject(new Error('No se encuentran datos')),
                100
            )
        }).then((response) => {
            this.setState({
                allForms: response
            });
        }).catch(() => {            
            this.setState({
                allForms: typeof(forms) !== 'function'? forms:[]
            });                      
        });
    }

    handleClickOpenCancel = () => {
        this.setState({ open: true });
    };

    handleCloseCancel = () => {
        this.setState({ open: false });
    };

    handleAceptCancel = () => {        
        this.setState({ loading: true });
        this.liquidationProvider.requestCancel(this.state.idRequirement, this.state.motivo).then(response => {
            if(response.error.codigo === '0'){
                this.setState({ open: false, loading: false });
                this.props.history.push('/liquidador/liquidaciones/digitalizar');
            } else {
                this.setState({ open: false, loading: false });
            }
        },() => {          
            this.displayMessage.error('Error de comunicación.');                   
            this.setState({ loading: false });
        });
    };

    handleOpen = () => {
        this.setState({
            showRequirement: true
        });
    }

    handleClose = () => {
        this.setState({
            showRequirement: false
        });
    }


    handleOpenCie10 = () => {
        this.setState({
            openCie10: true
        });
    }

    handleCloseCie10 = () => {
        this.setState({
            openCie10: false
        });
    }

    setFormSelected = (id) => {
        this.setState({
            formSelected: id

        });
        this.handleOpen();

    }

    _onMessageWasSent(message) {
        this.setState({
            messageList: [...this.state.messageList, message]
        })
    }


    handleChangeMotivo = (event) => {
        this.setState({ motivo: event.target.value });
    }

    datosModal = (dato1,dato2) => {
        this.setState({buscarCie10:dato2,detCie10:dato1,errorCie10:false})
    }

    render() {
       
        const { classes } = this.props;

        return (
            <Page
                className={classes.root}
                title="Digitalizar"                
            >
                <ModallSearchCie10
                    close={this.handleCloseCie10}
                    datos={this.datosModal.bind(this)}
                    open={this.state.openCie10}
                /> 
                { this.state.userRequirement ?
                    <>
                        <div className="right" >
                            <Typography
                                display="block"
                                gutterBottom
                                variant="overline"
                            >
                                <div className="red-text">                            
                                    <Button
                                        className={classes.deleteButton}
                                        onClick={this.handleClickOpenCancel}
                                        variant="contained"
                                    >
                                        <HighlightOffIcon className={classes.deleteIcon} />
                                        Cancelar liquidación
                                    </Button>
                                </div>
                            </Typography>
                        </div>
                        <Header 
                            liquidation={this.state.userRequirement}
                        /> 
                        <Grid
                            className={classes.container}
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                            >                               
                                {this.state.beneficiary && this.state.liqPerPol && 
                                    <LiquidationInfo 
                                        beneficiary={this.state.beneficiary} 
                                        liquidation={this.state.liqPerPol} 
                                    />
                                }
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >                                
                                {this.state.liqPerPol ? 
                                    <LiquidationDocuments 
                                        documents={this.state.liqPerPol.documentos} 
                                        liqCode={this.state.userRequirement.liqCodigo}
                                    /> 
                                    : <CircularProgress />}                   
                            </Grid>
                            {  this.state.detTipoLiq !== '' && this.state.detTipoLiq === 'TIPLQRE'?
                                <Grid
                                    item
                                    xs={12}
                                > 
                                    <Card>
                                        
                                        <CardHeader title="Digitalizar" />
                                        <Divider />
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={6}                            
                                            >
                                                <Grid                   
                                                    container
                                                    item
                                                    md={3}                                                
                                                >
                                                    <Typography
                                                        display="block"
                                                        gutterBottom
                                                        variant="h5"
                                                    >
                                                        FORMULARIO DE REEMBOLSO
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={3}                                                
                                                >
                                                    { !this.state.existenciaReembolso ? 
                                                        <Button
                                                            color="primary"
                                                            component={RouterLink}
                                                            to={`/liquidador/digitalizar/${btoa(this.state.idRequirement) }/rembolso`}
                                                            variant="contained"                                                                                        
                                                        >

                                                            <KeyboardIcon />&nbsp;
                                                            Digitalizar
                                                        </Button>: <CheckCircleOutlineIcon style={{ color: green[500] }} />
                                                    }
                                                </Grid>  
                                            </Grid>
                                        </CardContent>                                    
                                    </Card>
                                </Grid> : '' 
                            }
                            {this.state.existenciaReembolso && this.state.detTipoLiq !== '' && this.state.detalleLiquidacion ? (
                                <Grid
                                    item
                                    xs={12}
                                > 
                                    <Card>
                                        
                                        <CardHeader title="Fecha de incurrencia" />
                                        <Divider />
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={6}                            
                                            >
                                                <Grid                   
                                                    container
                                                    item
                                                    md={3}                                                
                                                >
                                                    <TextField                                                                                                        
                                                        name="startDate"
                                                        onClick={() => this.handleCalendarOpen(true)}
                                                        value={moment(this.state.values.startDate).format('DD/MM/YYYY')}
                                                        variant="outlined"
                                                    />

                                                    <DatePicker
                                                        onAccept={this.handleCalendarAccept}                                                    
                                                        onChange={this.handleCalendarChange}
                                                        onClose={this.handleCalendarClose}
                                                        open={this.state.calendarTrigger}
                                                        style={{ display: 'none' }} // Temporal fix to hide the input element
                                                        value={this.state.values.startDate}
                                                        variant="dialog"
                                                    />
                                                </Grid>
                                                
                                            </Grid>
                                        </CardContent>                                    
                                    </Card>
                                </Grid>
                            ) : ''
                            }
                            { this.state.detTipoLiq !== '' && this.state.detTipoLiq !== 'TIPLQRE'?
                                <Grid
                                    item
                                    xs={12}
                                >                                
                                    <Card>
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={6}                            
                                            >
                                                <Grid                   
                                                    container
                                                    item
                                                    md={6}
                                                    sm={12}
                                                    xs={12}                                
                                                >
                                                    <TextField
                                                        error={this.state.errorCie10}       
                                                        fullWidth  
                                                        helperText = {this.state.errorCie10 && 'Busque CIE10'}                                               
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label="CIE10"
                                                        name="buscarCie10"                            
                                                        placeholder="CIE10"
                                                        required 
                                                        value={this.state.buscarCie10}                           
                                                        variant="outlined"                           
                                                    />
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    md={3}
                                                    sm={6}       
                                                    xs={12}                         
                                                >
                                                    <Button
                                                        color="primary"
                                                        onClick={this.handleOpenCie10}  
                                                        variant="contained"                                  
                                                    >
                                                        Buscar cie10
                                                    </Button>
                                                </Grid>  
                                            </Grid>
                                        </CardContent>                                    
                                    </Card>
                                </Grid> : '' 
                            }                                                        
                        </Grid> 
                    </>
                    : <CircularProgress />
                }                
                
                {
                    /* { this.state.allForms ? 
                        <GenericList
                            elementIcon={<FaRegFolder />}
                            elements={this.state.allForms}
                            formIcon={<FaRegFolder />}
                            formTitle="Documentos"
                            handleOpen={this.handleOpen}
                            handleSearchChange={this.handleSearchChange}
                            idRequirement={this.state.idRequirement}
                            setFormSelected={this.setFormSelected.bind()}
                            titles={titles}
                            valSearch={this.state.searchWord}
                        /> : <CircularProgress /> 
                    } */
                }                              
                
                { this.state.existenciaReembolso && this.state.detTipoLiq !== '' && this.state.detalleLiquidacion ?
                    <BenefitsView
                        data={this.state.detalleLiquidacion}
                        detCie10={this.state.detCie10}
                        detTipoLiq={this.state.detTipoLiq}
                        liquidacion={this.state.idRequirement}
                        observacionLiq={this.state.liqPerPol.liqObservacion}
                        plaCodigo={this.state.liqPerPol.plaCodigo}         
                        usuUsuario={this.state.session ? this.state.session.user.usuCodigo:''}
                        liqFechaIncurrencia={moment(this.state.values.startDate).format('DD/MM/YYYY')}
                    /> : '' 
                }

                { this.state.userRequirement ?  
                    <MessagesForm 
                        detTipo={this.state.userRequirement.detTipo} 
                        getMessage={()=>{ window.location.href = '/liquidador/liquidaciones/digitalizar' }} 
                        liqCodigo={this.state.userRequirement.liqCodigo} 
                        placeholdermessage={'Escriba mensaje al cliente'} 
                        polCodigo={this.state.userRequirement.polCodigo} 
                        tipoConsulta={2}
                        usuCodigo={this.state.session.user.usuCodigo}
                        usuNombre={this.state.session.user.usuNombre}
                        usuUsuario={this.state.session.user.usuUsuario}
                    /> : ''
                }            
                <Dialog 
                    aria-labelledby="form-dialog-title" 
                    disableBackdropClick 
                    disableEscapeKeyDown
                    onClose={this.handleCloseCancel}
                    open={this.state.open}
                >
                    <DialogTitle id="form-dialog-title">Cancelar liquidación</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            ¿Está seguro de cancelar la liquidación?
                        </DialogContentText>
                        <TextField
                            autoFocus
                            fullWidth
                            id="name"
                            label="Escriba el motivo"                            
                            margin="dense"
                            onChange={this.handleChangeMotivo}
                            value={this.state.motivo}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            color="primary"
                            onClick={this.handleCloseCancel} 
                        >
                            Cancelar
                        </Button>
                        <Button
                            color="primary"
                            onClick={this.handleAceptCancel} 
                        >
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    aria-describedby="alert-dialog-description"              
                    aria-labelledby="alert-dialog-title"
                    open={this.state.loading}
                    style={{opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
                > 
                    <DialogContent >  
                        <CircularProgress/>
                    </DialogContent>                       
                </Dialog>                
            </Page>
        );
    }
}

const mapStateToProps = state =>({ session: state.session});
export default connect(mapStateToProps,null)(withStyles(styles)(DigitizeView));