import { DataService } from 'providers/dataService'
import { StorageService } from 'utils/storage';

export class LiquidationProvider {
    constructor() {
        this.dataService = new DataService();
        this.storageService = new StorageService()
        this.person = null
    }
    
    LiquidationByLiqCodigo = (liqCodigo)=>{
        const dS = new DataService();
      
        const body={
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                'liqCodigo': liqCodigo
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( response => {
            return response.bodyOut.data;
        } 
          
      
        );

        return response;
    }

    liquidationByLiqCodigoTotal = (liqCodigo)=>{    
        const body={
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                'liqCodigo': liqCodigo
            }
        }
        return this.dataService.post('/consulta', body, '0101000');
    }

    dataByPolCodigo = (polCodigo)=>{
        const dS = new DataService();
      
        const body={
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                'polCodigo': polCodigo
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( res => {
            
            return res.bodyOut.data;
        } 
      

        );

      
        return response;
    }

    dataByLiqCodigo = (liqCodigo)=>{
        const dS = new DataService();
      
        const body={
            'tipoConsulta': 1,
            'entidad': 'Liquidacion',
            'parametros': {
                'liqCodigo': liqCodigo
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( res => {
            
            return res.bodyOut.data;
        } 
      

        );

      
        return response;
    }

    LiquidationByPolCodigo = (polCodigo)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwLiqperpol',
            'parametros': {
                'polCodigo': polCodigo
            }
        }

        

        const response =  dS.post('/consulta', body, '0101000').then( res => {
            
            return res.bodyOut.data;
        } 
        
    
        );

        
        return response;
    }

    LiquidationByPolCodigoLiqNemonico = (polCodigo,liqCodigo,isLiquidador)=>{
        const dS = new DataService();
        //1 si es liquidador
        //0 si no es liquidador
        const parameters=isLiquidador === 0? {
            'liqNemonico': `%${liqCodigo}%`,
            'polCodigo': polCodigo,
        }:{
            'liqNemonico': `%${liqCodigo}%`,
            //'polCodigo': polCodigo,
            'detNemonico': 'ESTLI'
        }
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwLiqperpol',
            'parametros': parameters
        }
        const response =  dS.post('/consulta', body, '0101000').then( res => {
            
            return res.bodyOut.data;
        } 
        );

        
        return response;
    }

    liquidacionCountByEstado(status) {
        let body = {        
            tipoConsulta: 2,
            entidad: 'VwLiqperpol',
            parametros: {
                detNemonico: status         
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    liquidacionInfo(liqCodigo) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'VwLiqperpol',
            parametros: {
                liqCodigo: liqCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    liquidacionDetails(id) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'DetalleLiquidacion',
            parametros: {
                liqCodigo: id,
                delEstado: 1 
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    coberturasPlan(plaCodigo) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'CoberturasPlan',
            parametros: {
                plaCodigo: plaCodigo
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }


    tarifarioItems(search, tipo) {
        tipo = (parseInt(tipo) === 1 ? 31 : 23541);
        let body = {        
            tipoConsulta: 1,
            maximoRegistros: 20,
            entidad: 'Tarifario',
            parametros: {
                detTipo: tipo,
                tarNombre: '%'+search+'%'
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    LiquidationByLiqCodigoVw = (liqCodigo)=>{
        const dS = new DataService();
        
        const body={
            'tipoConsulta': 1,
            'entidad': 'VwLiqperpol',
            'parametros': {
                'liqCodigo': liqCodigo
            }
        }

        const response =  dS.post('/consulta', body, '0101000').then( res => {
            return res.bodyOut.data;
        } 
        );

        
        return response;
    }

    liquidacionDocuments(id) {
        let body = {        
            tipoConsulta: 1,
            entidad: 'Documento',
            parametros: {
                liqCodigo: id 
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    requestMove(liqCodigo, tipoConsulta, usuario) {

        const estadoss = [
            {
                'usuCodigo': usuario
            }];
       
        const liquidation =  {
            liqCodigo: liqCodigo,
            estados: estadoss
        }; 
        let body = {        
            tipoConsulta: tipoConsulta,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: liquidation             
            }
        };   
        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', []);
    
    }

    requestReturn(liqCodigo, message) {

        const estadoss = [{
            eslMensaje: message
        }];
       
        const liquidation =  {
            liqCodigo: liqCodigo,
            estados: estadoss
        }; 

        let body = {        
            tipoConsulta: 2,
            entidad: 'Liquidacion',
            parametros: {  
                Liquidacion: liquidation             
            }
        };   
        
        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', []);
    
    }
    
    requestCancel(liqCodigo, message) {
        const estadoss = [{
            eslMensaje: message
        }];
       
        const liquidation =  {
            liqCodigo: liqCodigo,
            estados: estadoss
        };

        let body = {        
            tipoConsulta: 5,
            entidad: 'Liquidacion',
            parametros: {
                Liquidacion: liquidation             
            }
        };

        let endpoint = '/multipart';        
        return this.dataService.postMultipart(endpoint, body, '0101110', []);
    }

    datosDetalleLiquidacion( liqCodigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'DetalleLiquidacion',
            parametros: {
                liqCodigo:liqCodigo,
                delEstado:1
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    getDetalleCatalogo( codigo ) {
        let body = {       
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                detCodigo: codigo,
            }
        };
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }

    findDetalleCatalogo = ( catNemonico ) => {
        const body = {
            tipoConsulta: 1,
            entidad: 'DetalleCatalogo',
            parametros: {
                catNemonico: catNemonico
            }
        }        
        let endpoint = '/consulta';
        return this.dataService.post(endpoint, body, '0101000');
    }
}
