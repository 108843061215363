import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Input,
  FormControl,
  Divider,
  Button,
  Typography,
  MenuItem,
  InputLabel,
  Select
} from '@material-ui/core';
import './styles.css';

import { RelationShipProvider } from "providers/relationship.service";
import { DisplayMessage } from "notifications/displayMessage";

class Application extends React.Component {
  constructor(props){
    super(props);
    this.state={
      open: false,
      onClose: false,
      ci:'',
      apellidos:'',
      nombres:'',
      edad:'',
      fecha:'',
      genero:'',
      parentesco:'',
      dataParentesco: [],
      
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    
  }



  handleClose = () => {
    this.setState({open:false});
  };

  handleOpen = () => {
    this.setState({open:true});
  };

  componentDidMount(){
    const relationship = new RelationShipProvider();
    
    const displayMessage = new DisplayMessage();

    relationship.datosParentesco()
      .then(
        data => {
          this.setState({dataParentesco:data.bodyOut.data});
        },
        () => {          
          displayMessage.error('Error de comunicación.');

        }

      );

  }


  onClose=()=>{
    this.props.close && this.props.close();
  }

 

  handleChange(e){
    e.persist();
    this.setState({[e.target.name] : e.target.value})
  
  }

  handleChangeSelect=(e)=>{
    this.setState({parentesco: e.target.value})
  }

  
 
  render(){
    
     return (

    <Dialog
      maxWidth="md"
      open={this.props.open}
    >
   
        <div className="header">
        <div className="closeButton">
        {this.onClose ? (
          
        <IconButton aria-label="close" className="" onClick={this.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      </div>
          <div className="closeButton1">
          <Typography> Agreagar Beneficiario </Typography>
          </div>
          
        </div>
        <Divider/>
        <div className="">
        <CardContent className="content">
          <PerfectScrollbar>
            <div className="">
              <form>
              <Table className="">
                <TableBody>
                  <TableRow>
                    <TableCell>CI:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="ci"
                          value={this.state.ci}
                          onChange={this.handleChangeSelect}                
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Apellidos:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="apellidos"
                          value={this.state.apellidos}
                          onChange={this.handleChange}                
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Nombres:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="nombres"
                          value={this.state.nombres}
                          onChange={this.handleChange}                
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Edad:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="edad"
                          value={this.state.edad}
                          onChange={this.handleChange}                
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fecha Nacimiento:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="fecha"
                          value={this.state.fecha}
                          onChange={this.handleChange}                
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Género:</TableCell>
                    <TableCell>
                      <FormControl>
                        <Input
                          name="genero"
                          value={this.state.genero}
                          onChange={this.handleChange}
                        
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Parentesco:</TableCell>
                    <TableCell>
                      <FormControl>
                      <InputLabel id="demo-controlled-open-select-label"></InputLabel>
                        <Select 
                          className="select" 
                          value={this.state.parentesco}               
                          onChange={this.handleChangeSelect}
                        >
                          <MenuItem value="">Seleccione</MenuItem>
                          {this.state.dataParentesco.map((parentesco) =>
                          <MenuItem value={parentesco.detCodigo} key={parentesco.detCodigo}>
                            {parentesco.detNombre}
                          </MenuItem>
                          
                          )}
                          
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" >Guardar</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </form>
            </div>
          </PerfectScrollbar>
          
        </CardContent>
        </div>
    </Dialog>
  )
};
}

Application.propTypes = {
  //author: PropTypes.object.isRequired,
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Application;
