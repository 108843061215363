/*eslint-disable no-eval */ 
import React, { Component} from 'react';
import { TextField,  IconButton, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
export default class GenericSearch extends Component {
    
    onChangeElement = (event, values) => {
       
        const { setValue } = this.props;

        setValue(values);
    }

    searchPerson = ( searchWord ) => {
        const { methodSearchPerson } = this.props;
        if(searchWord.length >=3 ){
            methodSearchPerson(searchWord);
        }
    }

  

    render(){
        const { selectedElement,  data,fieldName, label, searchWord, handleInputChange, nameInput } = this.props;

        return(
            <div>
                <Autocomplete
                    id="combo-box-demo"
                    options={ data }
                    getOptionLabel={option => `${eval(fieldName)}${eval(this.props.fieldName2)? ` - ${eval(this.props.fieldName2)} ${eval(this.props.fieldName3)}`:''}`}
                    value={selectedElement}
                    onChange={this.onChangeElement}
        
                    renderInput={params => (
                        <Grid container>
                           

                            <Grid item xs={11}>
                                <TextField 
                                    value={searchWord}
                                    name={nameInput}
                                    onChange={handleInputChange}
                                    error={this.props.autoError}
                                    helperText={this.props.error ? 'Seleccione una opcion': ''}
                                    {...params} 
                                    label={label} 
                                    variant={this.props.variant}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <div className='left'>
                                    <IconButton variant="outlined" onClick={()=>this.searchPerson(searchWord)}><SearchIcon/></IconButton>
                                </div>
                            </Grid>
                            
                        </Grid>
                           
                    )}
                /> 


            </div>
        );
    }


}   