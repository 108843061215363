/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { ProductionReportProvider } from 'providers/productionReport.service';
import { DisplayMessage } from 'notifications/displayMessage';
import {
    CircularProgress,
    Card,
    CardActions,
    Grid,
    Button,
    TextField,
    Paper,
    Input,
    InputBase,
    Tooltip,
    IconButton,
    Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { DatePicker } from '@material-ui/pickers';
import 'moment/locale/es';
import moment from 'moment';
import { order } from 'mock/orderMock';
import { makeStyles } from '@material-ui/styles';
import { Search, Clear, KeyboardReturn, Add } from '@material-ui/icons';
import { AppointmentProvider } from 'providers/appointment.service';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SearchBasic } from 'components';
import { useSelector } from 'react-redux';
moment.locale('es');

const useStyles = makeStyles(theme => ({
    root: {
        /* display: 'flex',
        alignItems: 'center' */
        adding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    search: {
        flexGrow: 1,
        height: 52,
        padding: theme.spacing(0, 2),
        display: 'flex',
        alignItems: 'center'
    },
    searchButton: {
        marginLeft: theme.spacing(1)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    }
}));

export default function SearchAffiliate() {
    const classes = useStyles();
    const session = useSelector(state => state.session);
    const tableRef = React.useRef();
    const [data, setData] = useState([]);
    const [selectedDate, handleDateChange] = useState(moment());
    const displayMessage = new DisplayMessage();
    const appoinment = new AppointmentProvider();
    const [cedula, setCedula] = useState('');
    const [buscarMes, setBuscarMes] = useState(moment());
    const [booleano, setBooleano] = useState(false);
    const [columns] = useState([
        { title: 'Codigo', field: 'AGE_NEMONICO', filtering: false },
        { title: 'Fecha registro', field: 'AGE_FECHA_REGISTRO' },
        { title: 'Cedula titular', field: 'TITULAR' },
        { title: 'Cédula', field: 'PER_IDENTIFICACION', filtering: false },
        { title: 'Nombre', field: 'PER_NOMBRES', filtering: false },
        { title: 'Apellido', field: 'PER_APELLIDOS', filtering: false },
        { title: 'Ciudad', field: 'LOC_NOMBRE' },
        { title: 'Prestador', field: 'PRE_NOMBRE', filtering: false },
        { title: 'Especialidad', field: 'AGE_ESPECIALIDAD', filtering: false },
        {
            title: 'Fecha y hora agendamiento',
            field: 'AGE_FECHA_CITA'
        },
        { title: 'Tipo de agendamiento', field: 'd.DET_NOMBRE' },
        { title: 'Estatus', field: 'de.DET_NOMBRE' }
    ]);

    const [columnsCli] = useState([
        { title: 'Codigo', field: 'AGE_NEMONICO', filtering: false },
        {
            title: 'Fecha registro',
            field: 'AGE_FECHA_REGISTRO',
            filtering: false
        },
        { title: 'Cedula titular', field: 'TITULAR', filtering: false },
        { title: 'Cédula', field: 'PER_IDENTIFICACION', filtering: false },
        { title: 'Nombre', field: 'PER_NOMBRES', filtering: false },
        { title: 'Apellido', field: 'PER_APELLIDOS', filtering: false },
        { title: 'Ciudad', field: 'LOC_NOMBRE', filtering: false },
        { title: 'Prestador', field: 'PRE_NOMBRE', filtering: false },
        { title: 'Especialidad', field: 'AGE_ESPECIALIDAD', filtering: false },
        {
            title: 'Fecha y hora agendamiento',
            field: 'AGE_FECHA_CITA',
            filtering: false
        },
        {
            title: 'Tipo de agendamiento',
            field: 'd.DET_NOMBRE',
            filtering: false
        },
        { title: 'Estatus', field: 'de.DET_NOMBRE', filtering: false }
    ]);

    const [total, setTotal] = useState(0);

    const [fechaRegistro, setFechaRegistro] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [agendamiento, setAgendamiento] = useState('');
    const [estatus, setEstatus] = useState('');
    const [cedulaTitular, setCedulaTitular] = useState('');
    const [fechaCita, setFechaCita] = useState('');
    const [polCod, setPolCod] = useState('');

    useEffect(() => {
        if (session.user.role === 'CLIENTE') {
            appoinment.datosT(session.user.perCodigo).then(
                response => {
                    if (response.error.codigo === '0') {
                        setPolCod(response.bodyOut.data[0].polCodigo);
                        tableRef.current.onQueryChange();
                    } else {
                        setPolCod('');
                    }
                },
                () => {
                    displayMessage.error('Error de conexión');
                }
            );
        } else {
            setPolCod('');
        }
    }, [session]);

    const loadData = (query, resolve) => {
        let fechaReg = '';
        let ciudad = '';
        let prestador = '';
        let agendamiento = '';
        let estatus = '';
        let cedulaTi = '';
        let fechaC = '';
        query.filters.map(item => {
            if (item.column.field === 'AGE_FECHA_REGISTRO') {
                fechaReg = item.value;
                setFechaRegistro(item.value);
            }
            if (item.column.field === 'LOC_NOMBRE') {
                ciudad = item.value;
                setCiudad(item.value);
            }
            /*  if (item.column.field === "PRE_NOMBRE") {
                 prestador = item.value
             } */
            if (item.column.field === 'd.DET_NOMBRE') {
                agendamiento = item.value;
                setAgendamiento(item.value);
            }
            if (item.column.field === 'de.DET_NOMBRE') {
                estatus = item.value;
                setEstatus(item.value);
            }
            if (item.column.field === 'TITULAR') {
                cedulaTi = item.value;
                setCedulaTitular(item.value);
            }
            if (item.column.field === 'AGE_FECHA_CITA') {
                fechaC = item.value;
                setFechaCita(item.value);
            }
        });
        let filt = '';
        const orderBy = {
            field: query.orderBy ? query.orderBy.field : 'AGE_FECHA_REGISTRO'
            //orderDirection: query.orderBy ? query.orderDirection : 'asc'
        };
        filt = orderBy.field;

        const direction = {
            orderDirection: query.orderBy ? query.orderDirection : 'desc'
        };
        if (session.user.role !== 'AUDITOR') {
            if (session.user.role === 'CLIENTE') {
                appoinment
                    .datosAgendamientoCliente(
                        fechaReg,
                        ciudad,
                        agendamiento,
                        estatus,
                        cedulaTi,
                        fechaC,
                        polCod,
                        cedula,
                        orderBy.field,
                        direction.orderDirection,
                        query.pageSize,
                        query.pageSize * query.page
                    )
                    .then(
                        response => {
                            if (response.bodyOut.data !== null) {
                                setBooleano(false);
                                setTotal(response.bodyOut.data[0].TOTAL);
                                resolve({
                                    data: response.bodyOut.data,
                                    page: query.page,
                                    totalCount: response.bodyOut.data[0].TOTAL
                                });
                            } else {
                                setBooleano(false);
                                setTotal(0);
                                resolve({
                                    data: [],
                                    page: query.page,
                                    totalCount: 0
                                });
                            }
                        },
                        () => {
                            setBooleano(false);
                            setTotal(0);
                            resolve({
                                data: [],
                                page: query.page,
                                totalCount: 0
                            });
                            displayMessage.error('Error de comunicación.');
                        }
                    );
            } else {
                appoinment
                    .datosAgendamiento(
                        fechaReg,
                        ciudad,
                        agendamiento,
                        estatus,
                        cedulaTi,
                        fechaC,
                        cedula,
                        orderBy.field,
                        direction.orderDirection,
                        query.pageSize,
                        query.pageSize * query.page
                    )
                    .then(
                        response => {
                            if (response.bodyOut.data !== null) {
                                setBooleano(false);
                                setTotal(response.bodyOut.data[0].TOTAL);
                                resolve({
                                    data: response.bodyOut.data,
                                    page: query.page,
                                    totalCount: response.bodyOut.data[0].TOTAL
                                });
                            } else {
                                setBooleano(false);
                                setTotal(0);
                                resolve({
                                    data: [],
                                    page: query.page,
                                    totalCount: 0
                                });
                            }
                        },
                        () => {
                            setBooleano(false);
                            setTotal(0);
                            resolve({
                                data: [],
                                page: query.page,
                                totalCount: 0
                            });
                            displayMessage.error('Error de comunicación.');
                        }
                    );
            }
        } else {
            appoinment
                .datosAgendamientoAuditor(
                    fechaReg,
                    ciudad,
                    agendamiento,
                    estatus,
                    cedulaTi,
                    fechaC,
                    cedula,
                    orderBy.field,
                    direction.orderDirection,
                    query.pageSize,
                    query.pageSize * query.page
                )
                .then(
                    response => {
                        if (response.bodyOut.data !== null) {
                            setBooleano(false);
                            setTotal(response.bodyOut.data[0].TOTAL);
                            resolve({
                                data: response.bodyOut.data,
                                page: query.page,
                                totalCount: response.bodyOut.data[0].TOTAL
                            });
                        } else {
                            setBooleano(false);
                            setTotal(0);
                            resolve({
                                data: [],
                                page: query.page,
                                totalCount: 0
                            });
                        }
                    },
                    () => {
                        setBooleano(false);
                        setTotal(0);
                        resolve({
                            data: [],
                            page: query.page,
                            totalCount: 0
                        });
                        displayMessage.error('Error de comunicación.');
                    }
                );
        }
    };

    const handleBuscarTitular = () => {
        tableRef.current.onSearchChange(cedula);
    };

    const handleLimpiar = () => {
        tableRef.current.onQueryChange();
        setBooleano(false);
        setCedula('');
    };

    const dataReportsExport = typeResult => {
        let parametro = '';
        if (session.user.role !== 'AUDITOR') {
            parametro = 'AGEND';
        } else {
            parametro = 'AGEAU';
        }

        appoinment
            .datosAgendamientoExport(
                typeResult,
                parametro,
                fechaRegistro,
                ciudad,
                agendamiento,
                estatus,
                cedulaTitular,
                fechaCita,
                cedula,
                'AGE_FECHA_REGISTRO',
                'asc',
                total,
                0
            )
            .then(
                response => {
                    if (
                        response.error.codigo === '0' &&
                        response.bodyOut.data.length
                    ) {
                        downloadFile(response.bodyOut.data, typeResult);
                    }
                },
                () => {
                    displayMessage.error('Error de comunicación.');
                }
            );
    };

    const downloadFile = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        if (session.user.role !== 'AUDITOR') {
            var path = `data:application/octet-stream;base64,${data[0].AGENDAMIENTO}`;
        } else {
            var path = `data:application/octet-stream;base64,${data[0].AGENDAMIENTO_AUDITORIA}`;
        }

        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Reporte.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const dataReportsExportEncuesta = typeResult => {
        let parametro = '';
        if (session.user.role !== 'AUDITOR') {
            parametro = 'AGEND';
        } else {
            parametro = 'AGEAU';
        }

        appoinment.datosAgendamientoExportEcuesta(typeResult).then(
            response => {
                if (
                    response.error.codigo === '0' &&
                    response.bodyOut.data.length
                ) {
                    downloadFileEncuesta(response.bodyOut.data, typeResult);
                }
            },
            () => {
                displayMessage.error('Error de comunicación.');
            }
        );
    };

    const downloadFileEncuesta = (data, typeFile) => {
        typeFile = typeFile === 2 ? 'csv' : 'xls';
        var path = `data:application/octet-stream;base64,${data[0].AGENDAMIENTO_ENCUESTA}`;
        var link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('download', `Encuesta.${typeFile}`);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleChangeCedula = e => {
        setCedula(e.target.value);
    };

    const onPressEnter = event => {
        if (event.key === 'Enter') {
            handleBuscarTitular();
        }
    };

    return (
        <div>
            <SearchBasic
                searchBar={cedula}
                cleanSearchBar={handleLimpiar}
                handleInputChange={handleChangeCedula}
                complementaryMessage="Buscar"
                handleSearch={handleBuscarTitular}
            />
            <br />

            <Grid container spacing={2}>
                
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingLeft: 1,
                                textAlign: 'right',
                                marginBottom: 10
                            }}>
                            {session.user.role !== 'AUDITOR' && (
                                <Button
                                    component={RouterLink}
                                    to={'/nuevaCita'}
                                    color="primary"
                                    variant="contained"
                                    size="small">
                                    <Add /> nueva cita
                                </Button>
                            )
                        }
                        </Grid>
                        {session.user.role !== 'CLIENTE' && (
                    <>
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingLeft: 1,
                                textAlign: 'right',
                                marginBottom: 10
                            }}>
                            <Tooltip title="Exportar a CSV">
                                <Button
                                    style={{
                                        borderRadius: 5,
                                        marginRight: 10,
                                        width: 100
                                    }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        dataReportsExport(2);
                                    }}>
                                    <FaFileCsv
                                        size={20}
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    CSV
                                </Button>
                            </Tooltip>
                            <Tooltip title="Exportar a EXCEL">
                                <Button
                                    style={{ borderRadius: 5, width: 100 }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        dataReportsExport(3);
                                    }}>
                                    <FaFileExcel
                                        size={20}
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    EXCEL
                                </Button>
                            </Tooltip>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingLeft: 1,
                                textAlign: 'right',
                                marginBottom: 10
                            }}>
                            <b>Encuesta</b>

                            <Tooltip title="Exportar a CSV">
                                <Button
                                    style={{
                                        borderRadius: 5,
                                        marginRight: 10,
                                        marginLeft: 10,
                                        width: 100
                                    }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        dataReportsExportEncuesta(2);
                                    }}>
                                    <FaFileCsv
                                        size={20}
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    CSV
                                </Button>
                            </Tooltip>
                            <Tooltip title="Exportar a EXCEL">
                                <Button
                                    style={{ borderRadius: 5, width: 100 }}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        dataReportsExportEncuesta(3);
                                    }}>
                                    <FaFileExcel
                                        size={20}
                                        style={{ marginRight: 10 }}
                                    />{' '}
                                    EXCEL
                                </Button>
                            </Tooltip>
                        </Grid>
                    </>
                )}
            </Grid>

            <Card>
                <MaterialTable
                    //icons={{ Filter: () => <div /> }}
                    tableRef={tableRef}
                    actions={[
                        {
                            icon: 'save',
                            tooltip: 'Save User',
                            onClick: () => {}
                        }
                    ]}
                    components={{
                        Action: props => (
                            <>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    size="small"
                                    style={{
                                        textTransform: 'none',
                                        marginRight: 10
                                    }}
                                    to={`/editarCita/${btoa(
                                        props.data.AGE_CODIGO
                                    )}`}
                                    variant="contained">
                                    <VisibilityIcon
                                        className={classes.getAppIcon}
                                    />
                                    Ver
                                </Button>
                            </>
                        )
                    }}
                    columns={
                        session.user.role === 'CLIENTE' ? columnsCli : columns
                    }
                    //data={data}
                    data={
                        booleano === false
                            ? query =>
                                  new Promise(resolve => {
                                      loadData(query, resolve);
                                  })
                            : []
                    }
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'No hay datos que mostrar'
                        },
                        toolbar: {
                            searchPlaceholder: 'Buscar',
                            exportName: 'Exportar a CSV',
                            exportAriaLabel: 'Exportar',
                            exportTitle: 'Exportar'
                        },
                        pagination: {
                            labelRowsSelect: 'Filas',
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsPerPage: 'filas'
                        },
                        header: {
                            actions: 'Acción'
                        }
                    }}
                    options={{
                        actionsColumnIndex: 0,
                        filtering:
                            session.user.role === 'CLIENTE' ? false : true,

                        exportButton: false,
                        paging: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100],
                        emptyRowsWhenPaging: false,
                        search: false,
                        toolbar: false,
                        rowStyle: rowData => ({
                            backgroundColor:
                                rowData.tableData.id % 2 === 0 ? '#FFF' : '#EEE'
                        })
                    }}
                    title="Afiliados"
                />
            </Card>
        </div>
    );
}
