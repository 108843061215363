import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
    retText: {
        font: red
    },
}));

export default function ConfirmDialog(props) {
    
    const classes = useStyles();
    
    const handleAcept = () => {
        const { executeUpdate } = props;
        executeUpdate();
        handleClose();
    };

    const handleClose = () => {
        const  {handleClose} = props;
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><span className={classes.retText}>¿Está seguro que desea cancelar la póliza?</span></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Una vez cancelada la póliza no se podrá volver a modificar la misma
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
            Cancelar
                    </Button>
                    <Button onClick={handleAcept} color="primary" autoFocus>
            Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}