import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Header, Results } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const PendienteAuditoria = () => {
  const classes = useStyles();

  const [customers] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    //let mounted = true;

    /*const fetchCustomers = () => {
      axios.get('/api/management/customers').then(response => {
        if (mouqueries
    fetchCustomers();*/

    return () => {
      //mounted = false;
    };
  }, []);

  const handleTotal = (total) => {
    setTotal(total);
  };

  return (
    <Page
      className={classes.root}
      title="Pendientes Cobrar"
    >
      <Header total={total} /><br />
      <br />

      <Results
        className={classes.results}
        customers={customers}
        onTotal={handleTotal}
      />
    </Page>
  );
};

export default PendienteAuditoria;